/**
 * Displays a summary of the product selection made within
 * an offer.
 * 
 * @param {Object}
 */
const displayProducts = ({ products }) => {
    if (products.includeAll) {
        return `Applies to ALL products`;
    }

    if (products.includeSome) {
        const count = products.includeProducts.length;
        let message = `Applies to ${count} product`;
        if(count !== 1) message += 's'
        return message;
    }

    if (products.excludeSome) {
        const count = products.excludeProducts.length;
        return `Excludes ${count} products`;
    }
}

export default displayProducts;