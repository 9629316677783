import React from 'react';
import { TextField, Button, Spinner } from '@shopify/polaris';

import './settings-terms.css';

/**
 * @function SettingsTerms
 * Accepts the following params, returns SettingsTerms Component.
 * Displays Merchant's terms and conditions document.
 * Merchant can edit and update their terms.
 * 
 * @param {String} terms 
 * @param {Boolean} loading
 * @param {Function} handleChange
 * @param {Function} handleSubmit
 * @param {Boolean} changesMade
 * @param {Boolean} recievedInitalData
 * 
 * @returns {FunctionComponent}
 */
const SettingsTerms = ({ terms, loading, handleChange, handleSubmit, changesMade, recievedInitalData }) => {

    return (
        <div className="settings-terms">
            <div className="settings-terms__section-wrapper">
                {/* <Heading>UPLOAD YOUR TERMS &amp; CONDITIONS</Heading> */}
                <h1 className="settings-terms__h1">
                    Upload your terms &amp; conditions
                </h1>

                <p className="settings-terms__p">The terms and conditions provided below will be agreed upon by any affiliate who wishes to run traffic via the Llama.app platform prior to sending traffic.  These terms will apply to all affiliates and all offers.</p>  
                {recievedInitalData
                    ? <div className="settings-terms__textfield-wrapper">
                        <TextField 
                            label="Terms & Conditions"
                            multiline
                            value={terms}
                            onChange={handleChange}
                            placeholder="..."
                        />
                    </div>
                    : <div style={{ width: '100%', textAlign: 'center', margin: '2rem 0 2rem' }}><Spinner /></div>
                }
                <Button
                    onClick={handleSubmit}
                    disabled={!changesMade}
                    loading={loading}
                    primary
                >
                    Save
                </Button>
            </div>
        </div>
    )

}

export default SettingsTerms;