import React from 'react';
import { Badge, Tooltip } from '@shopify/polaris';

const formatSocialScore = (data) => {
    let facebookScore = 0;
    let instagramScore = 0;
    let youtubeScore = 0;
    let twitterScore = 0;
    let totalSocials = 0;
    let noneConnected;

    if (!data) {
        return 0;
    }

    if (data.facebookData) {
        data.facebookData.forEach((fbScore) => {
            if (fbScore.score) {
                facebookScore += fbScore.score;
                totalSocials++;
            }
        })
    }

    if (data.instagramData) {
        data.instagramData.forEach((instaScore) => {
            if (instaScore.score) {
                instagramScore += instaScore.score;
                totalSocials++;
            }
        })
    }

    if (data.twitterData) {
        data.twitterData.forEach((twitScore) => {
            if (twitScore.score) {
                twitterScore += twitScore.score;
                totalSocials++;
            }
        })
    }

    if (data.youTubeData) {
        data.youTubeData.forEach((tubeScore) => {
            if (tubeScore.score) {
                youtubeScore += tubeScore.score;
                totalSocials++;
            }
        })
    }

    let affiliateSocialScoreAvg = 0;
    if (totalSocials !== 0) {
        affiliateSocialScoreAvg = ((facebookScore + instagramScore + youtubeScore + twitterScore) / totalSocials);
    } else {
        affiliateSocialScoreAvg = noneConnected;
    }

    let scoreAsGrade = <Badge> N/A </Badge>;
    if (affiliateSocialScoreAvg <= 100 && affiliateSocialScoreAvg >= 81) {
        scoreAsGrade = <Badge status="success"> A </Badge>;
    } else if (affiliateSocialScoreAvg <= 80 && affiliateSocialScoreAvg >= 61) {
        scoreAsGrade = <Badge status="info"> B </Badge>;
    } else if (affiliateSocialScoreAvg <= 60 && affiliateSocialScoreAvg >= 41) {
        scoreAsGrade = <Badge status="attention"> C </Badge>;
    } else if (affiliateSocialScoreAvg <= 40 && affiliateSocialScoreAvg >= 21) {
        scoreAsGrade = <Badge status="warning"> D </Badge>;
    } else if (affiliateSocialScoreAvg <= 20) {
        scoreAsGrade = <Badge status="warning"> F </Badge>;
    } else if (affiliateSocialScoreAvg === noneConnected) {
        scoreAsGrade = <Tooltip content="This ambassador hasn't connected their social media"><Badge progress="incomplete"> N/A </Badge></Tooltip>;
    }

    return scoreAsGrade;
};

export default formatSocialScore;