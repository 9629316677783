import { sendGraphQL } from '../sendRequest';

const getChatAffiliates = (advertiser_id) => {
    const query = `
        query getChatAffiliates($advertiser_id: String!){
            getChatAffiliates(advertiser_id: $advertiser_id){
                id
                unread
                name
                photo
                is_online
                latest_message{
                    text
                    date
                }
            }
        }
    `;

    const variables = {
        advertiser_id
    };

    const payload = {
        query,
        variables
    };

    return sendGraphQL(payload)
        .then((response) => { return response.data; })
        .catch((err) => { console.error(err); });
};

export default getChatAffiliates;
