const formatCountries = (account, limit = 3) => {
    let { audience_country: audienceCountries } = account;

    if (!audienceCountries) {
        return 'Global';
    }

    if (!Array.isArray(audienceCountries)) {
        audienceCountries = [audienceCountries]
    }

    if (Array.length === 0) {
        return 'Global'
    }

    audienceCountries.slice(0, limit);
    return audienceCountries.join(', ');;
}

export default formatCountries;