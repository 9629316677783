import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { isEqual } from 'lodash';
import {
    Page
} from '@shopify/polaris';
import { saveImage } from '../components/llama/image-drop';

import SettingsNavigation from '../components/settings-navigation/settings-navigation';
import { getAdvertiser } from '../actions/advertiser';
import { updateAdvertiser } from '../actions/update-advertiser';

export class Settings extends Component {
    constructor(props) {
        super(props);
        let logoModal = null;
        if (props.history.location.pathname === '/settings/logo') {
            logoModal = true;
        }
        this.state = {
            savedAdvertiser: props.advertiser,
            uploading: false,
            showSideDrawer: logoModal
        };
    }

    componentDidMount() {
        if (this.props.advertiser.myshopify_domain) {
            this.props.getAdvertiser(this.props.advertiser.myshopify_domain);
        }
    }

    /**
     * @author Harshal Mahajan <Harshal.Mahajan@harbingergroup.com>
     * @function getDerivedStateFromProps
     * @description Check if new coming props have different advertiser compared to the previous state then update it with new one 
     * @param {Object} nextProps new props receving by component
     * @param {Object} prevState previous state of component
     * @returns {Object} null or updated state 
     */
    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState && !isEqual(nextProps.advertiser, prevState.savedAdvertiser)) {
            console.log('getDerivedStateFromProps called');
            // Update Form Data.
            return {
                savedAdvertiser: nextProps.advertiser
            };
        }
        return null;
    }

    componentDidUpdate(prevProps) {
        if (!isEqual(prevProps.advertiser, this.props.advertiser)) {
            console.log('componentDidUpdate called');
            let {
                shop,
                address1,
                address2,
                city,
                region,
                zipcode,
                country,
                phone,
                email,
                shop_owner,
                name,
                avatar_image,
                description,
                tags,
                domain
            } = this.props.advertiser;

            let newData = {
                shop_owner,
                email,
                address1,
                address2,
                city,
                region,
                zipcode,
                country,
                avatar_image,
                phone: phone,
                shop,
                name,
                description,
                domain,
                tags: tags
            };

            this.props.setValues(newData);
        }
    }

    setShowSideDrawer = (showSideDrawer) => {
        this.setState({
            showSideDrawer: showSideDrawer
        });
    }

    setUploading = (uploading) => {
        this.setState({
            uploading
        });
    }

    saveFile = (file) => {
        return saveImage(file, this.props.advertiser.advertiser_id, 'profile', this.setUploading)
            .then(result => {
                const payload = {
                    avatar_image: result.url
                };
                this.props.updateAdvertiser(payload, this.props.advertiser);
                this.props.setFieldValue("avatar_image", result.url, true);
                this.setShowSideDrawer(null);
            });

    };

    render() {
        return (
            <Page
                title="Settings"
                separator
            >
                <SettingsNavigation
                    saveFile={this.saveFile}
                    setShowSideDrawer={this.setShowSideDrawer}
                    showSideDrawer={this.state.showSideDrawer}
                    uploading={this.state.uploading}
                    {...this.props}
                />
            </Page>
        );
    }
}

const initialValues = {
    name: '',
    owner: '',
    email: '',
    address1: '',
    address2: '',
    city: '',
    region: '',
    zipcode: '',
    country: '',
    phone: '',
    shop: '',
    description: '',
    domain: '',
    avatar_image: '',
    tags: [],
    new_product_status: 'active'
};

const submitUpdateAdvertiser = (values, { props }) => {
    props.updateAdvertiser(values, props.advertiser);
};

const MyForm = withFormik({
    mapPropsToValues: (props) => {
        return { ...initialValues, ...props.advertiser };
    },
    handleSubmit: submitUpdateAdvertiser
})(Settings);

const mapStateToProps = (state) => {
    return {
        advertiser: state.advertiser,
        ...state
    };
};

const mapDispatchtoProps = (dispatch) => {
    return {
        getAdvertiser: (shop) => dispatch(getAdvertiser(shop)),
        updateAdvertiser: (data, advertiser) => dispatch(updateAdvertiser(data, advertiser))
    };
};

export default connect(mapStateToProps, mapDispatchtoProps)(MyForm);
