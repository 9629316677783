import { sendGraphQL } from './sendRequest';

const GET_PROMOTIONS_DATA = 'GET_PROMOTIONS_DATA';
export const GET_PROMOTIONS_DATA_FULFILLED = 'GET_PROMOTIONS_DATA_FULFILLED';

export default function getPromotions(term = '', options = {}) {
    const query = `
    query getPromotions($term: String!, $options: queryOptions) {
        getPromotions(term: $term, options: $options) {
          promotions {
            _id
            code
            desc
            component
            creation_date
            start_date
            end_date
            value
            unit
            total_count
            used_count
            available_count
            is_active
          }
          pageInfo {
            count
          }
          errors
        }
    }
    `;

    const variables = {
        term,
        options
    };

    const payload = {
        query,
        variables
    };

    return {
        type: GET_PROMOTIONS_DATA,
        payload: sendGraphQL(payload)
            .then((response) => {
                return response.data.data.getPromotions;
            })
    };
}
