import React, { Component } from 'react';
import {
    Page,
    Layout,
} from '@shopify/polaris';

import './llama-tos.css';

/**
 * @function LlamaTos
 * Returns LlamaTos Component. 
 * Displays the LLama Terms of Service Document.
 * 
 * @returns {FunctionComponent}
 */
const LlamaTos = () => {
    return (
        <div className="llama-tos__wrapper">
            <div className="llama-tos__content">
                <h1 className="llama-tos__header">TERMS OF SERVICE</h1>
                <h1 className="llama-tos__header">Llama LLC </h1>

                <p className="llama-tos__body">
                    Last Updated on April 5, 2018
                </p>

                <p className="llama-tos__body">
                    THESE TERMS OF SERVICE (THE <span className="llama-tos__body b">"AGREEMENT"</span>) ARE A LEGALLY BINDING AGREEMENT 
                    BETWEEN YOU (<span className="llama-tos__body b">“YOU,” “YOUR,” </span> OR <span className="llama-tos__body b">“AFFILIATE”</span>) 
                    AND LLAMA.APP LLC (“WE”, “US” OR “COMPANY”), THE OWNER AND OPERATOR OF THE LLAMA.APP WEBSITE (THE “SITE”). THIS AGREEMENT STATES 
                    THE TERMS AND CONDITIONS THAT GOVERN YOUR USE OF THE SITE AND ACCOUNT REGISTRATION. BY ACCESSING AND USING THE SITE, YOU ARE 
                    INDICATING THAT YOU ACCEPT, AND AGREE TO COMPLY WITH, THIS AGREEMENT. IF YOU DO NOT ACCEPT THIS AGREEMENT, YOU ARE NOT PERMITTED 
                    TO, AND YOU MUST NOT, ACCESS OR USE THE SITE OR REGISTER AN ACCOUNT ON THE SITE. 
                </p>

                <h1 className="llama-tos__section-header">Personal Information and Privacy.</h1> 
                <p className="llama-tos__body">
                    You agree to provide accurate, current and complete information as required for registering an account on the Site. We reserve the 
                    right to block further participation on the Site by you if you provide false, inaccurate or incomplete data. Our Privacy Policy 
                    explains how your personally identifiable information is collected, used and disclosed. We respect your privacy and will use the 
                    information provided by you only in accordance with this Agreement and the Privacy Policy. 
                </p>

                <h1 className="llama-tos__section-header">Account Registration and Cancellation.</h1>
                <p className="llama-tos__body">
                    You may choose to create a login and password on the Site and thereby create an account on the Site. You are responsible for maintaining 
                    the confidentiality of your passwords and you are responsible for all activities that occur in using your passwords. You agree not to 
                    share your passwords, let anyone else access your passwords or do anything else that might jeopardize the security of your passwords. 
                    You agree to notify us if there is any unauthorized use of your password on this Site or you know of any other breach of security in 
                    relation to the Site. 
                </p>

                <p className="llama-tos__body">
                    <span className="llama-tos__body b">Account Cancellation</span> You may cancel your account at any time by going into the settings on 
                    your account and disabling your account. If you have a balance due under your account upon cancellation, such amount will be paid to 
                    you within thirty (30) days from the date of cancellation to the Paypal account that you used when you registered your account. If 
                    you have a negative balance in your account, meaning you owe the Merchant sums due (e.g., in the event of an advertiser clawback), 
                    your account shall remain active until such balance is paid in full. In the event of account termination or cancellation, we shall 
                    retain the details of your account for audit purposes for up to five (5) years. 
                </p>

                <h1 className="llama-tos__section-header">Use of Site; Intellectual Property Rights; Prohibitions.</h1>
                <p className="llama-tos__body">
                    Subject to your compliance with this Agreement, we hereby grant you a limited, personal, revocable, non-transferable, non-sublicensable, 
                    and non-exclusive license to access the Site and use the content, information, text, images, logos, icons, graphics, interfaces, site 
                    design, audio, and video clips and any other materials displayed on the Site (collectively, the “Content”), solely for your personal, 
                    non-commercial use. You may not copy, modify, reproduce, retransmit, publicly display or perform, distribute or otherwise use the 
                    Content except as expressly set forth in this Agreement. You may not remove any copyright or other proprietary notices from any 
                    Content. If you breach any term of this Agreement, your authorization to use the Site and Content automatically terminates without notice to you. 
                </p>
                <p className="llama-tos__body">
                    You hereby acknowledge and agree that we or our licensors own all legal right, title and interest in and to the Content and the Site, 
                    including but not limited to any copyrights, trademark rights, patent rights, moral rights, and other intellectual property and proprietary 
                    rights therein, whether such rights are registered or unregistered, and wherever in the world those rights may exist. Your use of the Site 
                    does not grant you ownership of any kind in any Content you may access on or through this Site. Unauthorized use is strictly prohibited. 
                </p>
                <p className="llama-tos__body">
                    You hereby represent and warrant that you will not, and will not induce any third party to: (a) attempt to disable or circumvent any 
                    security mechanisms used by the Site or Content or otherwise attempt to gain unauthorized access to any portion of the Site or Content 
                    or any other systems or networks connected to the Site, or to any of our servers or our third party service providers, by hacking, 
                    password “mining,” or any other illegal means; (b) use any “deep-link,” “page scrape,” “robot,” “spider,” or other automatic device, 
                    program, algorithm or methodology, or any comparable manual process, to access, acquire, copy or monitor any portion of the Site or 
                    Content; (c) use any device, software or routine to interrupt or interfere with, or attempt to interrupt or interfere with, the proper 
                    operation and working of the Site or with any other person’s use of the Site; (d) track or seek to trace any information on any other 
                    person who visits the Site; use the Site or Content for, or in connection with, any illegal purpose, to solicit, facilitate, encourage, 
                    condone, or induce any illegal activity, or as otherwise prohibited by this Agreement or applicable laws, rules or regulations; or (f) 
                    copy, modify, create a derivative work of, reverse engineer, decompile or otherwise attempt to extract the source code of any proprietary 
                    software used to provide or maintain the Site or Content or that is otherwise applicable to the Site or the Content. 
                </p>

                <h1 className="llama-tos__section-header">User Submissions.</h1>
                <p className="llama-tos__body">
                    The Site may offer you the opportunity to post articles, comments, photographs, videos, artwork and other content on the Site 
                    (collectively the “User Submissions”). By making a User Submission, you grant to us an unrestricted, royalty-free, perpetual, 
                    irrevocable and fully sub-licensable and transferable right to use, reproduce, modify, adapt, publish, translate, create 
                    derivative works from, distribute, perform, exhibit and display such User Submissions throughout the world in any and all 
                    media and formats, whether now known or hereafter developed, for any purpose whatsoever, without compensation or acknowledgement 
                    of its source. You further agree that we are free to use any ideas, concepts, or know-how that you or individuals acting on your 
                    behalf provide to us without any monetary or other obligation to you. To the maximum extent permitted by law, you waive any and 
                    all “moral rights” you may have in User Submissions. 
                </p>
                <p className="llama-tos__body">
                    You agree not to post on or transmit to the Site or otherwise provide to us any User Submission or other material that: 
                </p>
                <p className="llama-tos__body">
                    -  is unlawful; libelous; harmful; threatening; abusive; harassing; defamatory; vulgar; obscene; 
                </p>
                <p className="llama-tos__body">
                    -  sexually explicit; profane; hateful; racially, ethnically or otherwise objectionable in any manner; 
                </p>
                <p className="llama-tos__body">
                    - is an advertisement or promotion for any product or service that is not an approved product of the Company; 
                </p>
                <p className="llama-tos__body">
                    - is false, misleading, or constitutes an unfair or deceptive trade practice; 
                </p>
                <p className="llama-tos__body">
                    - promotes the use of alcohol, tobacco, or any illegal substance; 
                </p>
                <p className="llama-tos__body">
                    - constitutes a breach of your contractual and/or fiduciary obligations or an invasion of privacy; 
                </p>
                <p className="llama-tos__body">
                    - infringes on any third party patent, trademark, trade name, corporate name, trade secret, copyright, publicity, or other proprietary or property rights; 
                </p>
                <p className="llama-tos__body">
                    - is false or misleading; or
                </p>
                <p className="llama-tos__body">
                    - contains viruses, Trojan horses, worms or other code, scripts, routines, files or programs designed to alter, interrupt, impede, limit or destroy the performance and/or functioning of any software, hardware or other equipment. 
                </p>
                <p className="llama-tos__body">
                    You are solely responsible for your User Submissions and you agree to defend, indemnify and hold harmless the Company and 
                    its members, employees and agents from and against any losses, expenses, liabilities, claims, costs and damages (“Claims”) 
                    arising from your User Submission, including but not limited to, any Claim arising out of breach of this Agreement or for 
                    infringement of copyright, trademark, patent or other proprietary rights. You understand and acknowledge that User 
                    Submissions may be published and that we have no obligation to keep such User Submissions confidential. You further agree 
                    that any User Submission you provide does not contain any confidential, proprietary or trade secret information of any 
                    third party. We have no obligation to store, keep copies or return any User Submission. 
                </p>
                <p className="llama-tos__body">
                    We reserve the right to take any action with respect to User Submissions that we deem necessary or appropriate in our sole discretion if we believe any User Submission violates this Agreement, is inappropriate on the Site, may create liability for us, or may cause us to lose (in whole or in part) the services of its internet service provider, advertisers, or other suppliers. Such action may include, but is not limited to, removing, deleting, modifying or altering User Submissions, either with or without notice to you. 
                </p>
                <h1 className="llama-tos__section-header">Third Party Sites.</h1>
                <p className="llama-tos__body">
                    The Site may provide links to third party websites that are not owned or controlled by us and you may link your registered 
                    account with us to certain third party websites, including, without limitation, Facebook, Twitter, and Instagram (the 
                    “Third Party Sites”). We do not review, approve, endorse or make any representations about such Third Party Sites, the 
                    companies or persons who own and/or operate them, or any information, software or other products and services made 
                    available through such Third Party Sites, or any results that may be obtained from using them. If you decide to access 
                    any such Third Party Sites linked to the Site, you are solely responsible for your activities conducted in connection 
                    with such Third Party Sites. Your use of Third Party Sites is subject to the terms of use and privacy policies located 
                    on the Third Party Sites which may be different from this Agreement or our Privacy Policy and, therefore, we recommend 
                    that you review such Third Party Site privacy policies. You further allow us to access any Third Party Site account 
                    that you link to your account on our Site and you grant to us an unrestricted, royalty-free, perpetual, irrevocable and 
                    fully sub-licensable and transferable right to review, analyze, use, reproduce, share, modify, adapt, publish, translate, 
                    create derivative works from, distribute, perform, exhibit and display such Third Party Site content throughout the world 
                    in any and all media and formats, whether now known or hereafter developed, for any purpose whatsoever, without 
                    compensation or acknowledgement of its source.
                </p>

                <h1 className="llama-tos__header">Merchant and Affiliate Relationship.</h1>
                <p className="llama-tos__body">
                    <span className="llama-tos__body b mr">Structure</span>
                    The Company acts as a broker or intermediary between stores listed on the www.shopify.com website (the “Merchants”) 
                    and you as the Affiliate (the “Services”). After you have signed up for an account with the Company, the Merchants 
                    shall have access to review any Third Party Sites that you have registered on the Site and shall determine, in their 
                    sole and absolute discretion, whether to approve of you as a referral source for their products. 
                </p>
                <p className="llama-tos__body">
                    <span className="llama-tos__body b mr">Commission</span>
                    The Merchants shall be solely responsible for payment of any commission, referral fee or similar compensation 
                    (the “Commission”) earned by an Affiliate for qualified sales, leads, clicks or other measurable action by a 
                    visitor that is generated or referred by such Affiliate to the Merchant’s website. The Company does not pay any 
                    Commissions and is not liable for the payment of Commissions by Merchants or any nonpayment by Merchants. 
                    Such Commission shall be determined solely between the Merchant and Affiliate. Notwithstanding the foregoing, 
                    any payment of a Commission shall only be made to Affiliate once at least Fifty Dollars ($50.00) in Commissions 
                    has been earned. Such payment shall be made by Merchant to a Paypal account of Affiliate that has been registered 
                    on the Site. 
                </p>
                <p className="llama-tos__body">
                    <span className="llama-tos__body b mr">Indemnification.</span>
                    You hereby agree to defend, indemnify, and hold the Company, its officers, directors, shareholders, members, 
                    managers, parent, subsidiaries, employees, agents, representatives, successors and assigns (collectively, the 
                    “Indemnified Parties”) harmless from and against, any and all losses, liabilities, damages, and/or claims 
                    (including, without limitation, attorney’s fees and costs) arising from your breach of this Agreement, or 
                    otherwise arising from your use or misuse of the Site, Content or Services. 
                </p>

                <h1 className="llama-tos__header">Disclaimer of Warranties.</h1>
                <p className="llama-tos__body">
                    THE SITE, CONTENT, AND SERVICES ARE FURNISHED TO YOU “AS IS” AND WITHOUT WARRANTIES, REPRESENTATIONS OR CONDITIONS, STATUTORY OR OTHERWISE, OF ANY KIND. THE COMPANY, ON BEHALF OF ITSELF AND ITS AFFILIATES, LICENSORS, SUPPLIERS AND THIRD PARTY SERVICE PROVIDERS, AND EACH OF THEIR RESPECTIVE DIRECTORS, OFFICERS, MEMBERS, EMPLOYEES, CONTRACTORS, AGENTS, SUCCESSORS, AND ASSIGNS (COLLECTIVELY THE "<b> COMPANY PARTIES</b>”): (A) EXPRESSLY DISCLAIMS ALL REPRESENTATIONS, WARRANTIES AND CONDITIONS, WHETHER EXPRESS, IMPLIED OR STATUTORY, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF NON-INFRINGEMENT, TITLE, MERCHANTABILITY, AND FITNESS FOR A PARTICULAR PURPOSE; (B) DOES NOT REPRESENT OR WARRANT THAT THE SITE, CONTENT OR SERVICES WILL MEET YOUR REQUIREMENTS, OR THAT THE OPERATION OF THE SITE OR CONTENT WILL BE TIMELY, UNINTERRUPTED, STABLE OR SECURE; (C) DOES NOT REPRESENT OR WARRANT THAT THE SITE, CONTENT, OR SERVICES WILL BE ERROR-FREE OR THAT ANY DEFECTS WILL BE CORRECTED; AND (D) DOES NOT MAKE ANY REPRESENTATIONS, WARRANTIES OR CONDITIONS REGARDING THE USE OF THE SITE, CONTENT OR SERVICES IN TERMS OF THEIR ACCURACY, RELIABILITY, TIMELINESS, COMPLETENESS OR OTHERWISE. YOUR USE OF THE SITE, CONTENT OR SERVICES IS ENTIRELY AT YOUR OWN DISCRETION AND RISK AND YOU ASSUME TOTAL RESPONSIBILITY FOR YOUR USE OF THE SITE, CONTENT OR SERVICES. 
                </p>

                <h1 className="llama-tos__header">Limitation of Liability</h1>
                <p className="llama-tos__body">
                    IN NO EVENT SHALL ANY COMPANY PARTY BE LIABLE TO YOU, OR ANY THIRD PARTY, FOR ANY SPECIAL, INDIRECT, INCIDENTAL, PUNITIVE, EXEMPLARY, RELIANCE OR CONSEQUENTIAL DAMAGES OF ANY KIND, INCLUDING, BUT NOT LIMITED TO, PROPERTY DAMAGE, LOSS OF VALUE OF THE PRODUCTS OR LOSS OF THE USE OF THE PRODUCTS, WHETHER BASED ON BREACH OF ANY EXPRESS OR IMPLIED WARRANTY OR CONDITION, BREACH OF CONTRACT OR TORT (INCLUDING, WITHOUT LIMITATION, NEGLIGENCE OR STRICT LIABILITY), EVEN IF A COMPANY PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. 
                    IF, NOTWITHSTANDING THE FOREGOING, A COMPANY PARTY IS FOUND TO BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DAMAGE OR LOSS WHICH ARISES UNDER OR IN CONNECTION WITH YOUR USE OF THE SITE, CONTENT OR PRODUCTS, THE RELEVANT COMPANY PARTY’S TOTAL CUMULATIVE LIABILITY SHALL IN NO EVENT EXCEED THE GREATER OF: (A) THE AMOUNT YOU PAID THE COMPANY FOR THE PRODUCTS, AS APPLICABLE OR (B) THE SUM OF ONE HUNDRED US DOLLARS (US$100). 
                    NOTHING IN THIS SECTION 7 SHALL EXCLUDE OR LIMIT ANY COMPANY PARTY’S LIABILITY FOR LOSSES WHICH MAY NOT BE LAWFULLY EXCLUDED OR LIMITED BY APPLICABLE LAW, IN WHICH CASE, SUCH COMPANY PARTY’S LIABILITY WILL BE LIMITED TO THE GREATEST EXTENT PERMITTED BY APPLICABLE LAW. 
                </p>

                <h1 className="llama-tos__header">Choice of Law; Dispute Resolution; Class Action Waiver.</h1>
                <p className="llama-tos__body">
                    <span className="llama-tos__body b">Choice of Law</span>
                    This Agreement is governed by the laws of the State of Florida without regards to its conflict of laws principles. 
                </p>
                <p className="llama-tos__body">
                    <span className="llama-tos__body b mr">Dispute Resolution</span>
                    This Agreement is governed by the laws of the State of Florida without regards to its conflict of laws principles. 
                    In the event that our Affiliate service department is unable to resolve a complaint to your satisfaction, we agree to resolve such disputes arising out of this Agreement (except as to those related to the Company’s enforcement and protection of its intellectual property rights) through binding arbitration or small claims court instead of in courts of general jurisdiction. 

                    All disputes, controversies or claims relating in any way, directly or indirectly, to the Company will be administered by the Arbitration Association of America (“AAA”) in Orange County, Florida, before one arbitrator and in accordance with the provisions of its then current Arbitration Rules, except as may be modified herein or by mutual agreement of the parties. The seat of the arbitration shall be Ocoee, Florida.  In the event a dispute is submitted to arbitration, the arbitrator may award costs and reasonable attorney’s fees to the prevailing party.
                    The arbitration award shall be in writing and shall be final and binding on the parties. The award may include an award of costs, including reasonable attorney’s fees and disbursements. Judgment upon award may be entered by any court having jurisdiction thereof or having jurisdiction over the parties or their assets. 
                    Notwithstanding the foregoing, nothing shall prevent the Company from seeking immediate injunctive relief from the AAA or a court of competent jurisdiction in the event of breach of this Agreement.  In the event that injunctive relief is sought through the AAA, the Company shall bear all costs of filing and initiating the arbitration; however, in the event the arbitrator finds in favor of the Company, you shall reimburse the Company for these costs.  
                </p>
                <p className="llama-tos__body">
                    <span className="llama-tos__body b mr">Class Action Waiver</span>
                    Any arbitration under this Agreement will take place on an individual basis; class arbitrations and class actions are not permitted. Neither you nor the Company has the right to act as a class representative or participate as a member of a class of claimants with respect to any claim. 
                </p>
                <p className="llama-tos__body">
                    <span className="llama-tos__body b mr">Electronic Communications.</span>
                    Whenever you visit our Site or send emails to us, you are communicating with us electronically. For that reason, you also consent to receive communications from us electronically. We will communicate with you by email (if you have provided your email address to us), by posting notices on our Site or by such other means as may determine from time-to-time.  You agree that all agreements, notices, disclosures, and other communications that provide to you electronically satisfy any legal requirement that such communications be in writing to the extent permitted by applicable law. 
                </p>

                <h1 className="llama-tos__header">General Terms.</h1>
                <p className="llama-tos__body">
                    We may add to, change or remove any part of the Site, including, without limitation, any Content (as defined below) therein, at any time without prior notice to you. We also reserve the right to modify this Agreement at any time. When  make changes to the Agreement, we will revise the “Last updated” date at the top of the Agreement and we will notify you of the changes by prominently posting a notice of such changes on the Site and/or be sending you an email. We encourage you to review this Agreement whenever you visit the Site. By continuing to access and use the Site after any such changes have been posted, you are indicating your acceptance of such changes, even if you have not reviewed the changes.
                </p>
                <p className="llama-tos__body">
                    We may issue a warning, temporarily suspend, or terminate your right to use or access any or all part of the Site, without notice and for any reason. 
                </p>
                <p className="llama-tos__body">
                    No failure to exercise, partially exercise or delay in exercising any right or remedy under this Agreement shall operate as a waiver or estoppel of any right, remedy or condition. 
                </p>
                <p className="llama-tos__body">
                    If any provision of this Agreement is held invalid, illegal or unenforceable, the validity, legality and enforceability of the remaining provisions will not be affected or impaired. 
                </p>
                <p className="llama-tos__body">
                    We will not be responsible for failures to fulfill any of its obligations due to causes beyond its control.  
                </p>
                <p className="llama-tos__body">
                    You may not assign or otherwise transfer your rights, or delegate your performance, under this Agreement to a third party without the Company’s prior written consent.  The Company may assign or transfer its rights or delegate any performance under this Agreement to any third party in its sole discretion. 
                </p>

            </div>
        </div>
    )
}

export default LlamaTos;