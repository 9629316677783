/* import axios from 'axios';
import { NEXT_APP_API_URL } from '../config'; */
import {sendGraphQL} from './sendRequest'

const getInvoices = (advertiser_id) => {

    const query = `
        query getInvoices ($advertiser_id: String) {
            advertisers(advertiser_id: $advertiser_id) {
                invoices {
                    affiliate_id
                    affiliate_name
                    affiliate {
                        name
                    }
                    create_time
                    invoice {
                        id
                        number
                        status
                        invoice_date
                        payment_term {
                            term_type
                            due_date
                        }
                        total_amount {
                            currency
                            value
                        }
                        metadata {
                            created_date
                            last_updated_date
                        }
                        items {
                            name
                        }
                        links {
                            payerView {
                                rel
                                href
                                method
                            }
                        }
                    }
                }
            }
        }
    `

    let variables = {
        advertiser_id
    }

    let payload = {
        query,
        variables
    }

    return sendGraphQL(payload)
        .then(response => response.data)

    /* let headers = {
        "Content-Type": 'application/json'
    }

    return axios.post(NEXT_APP_API_URL, payload, { headers })
        .then(response => response.data) */

}

export {
    getInvoices
}