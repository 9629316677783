
/**
 * @function checkAbbreviation
 * 
 * Checks if the number is already in abbreviated format,
 * returns false or undefined for numbers that are not already
 * abbreviated.  Returns true for any number that has been
 * abbreviated already.
 * 
 * @param {String} metric Number represented as a string
 * @return {Boolean}
 */
const checkAbbreviation = (metric) => {

    if (typeof metric !== 'string') {
        return false;
    }

    const abbreviations = [
        'k',
        'K',
        'M',
        'B',
    ]

    return abbreviations
        .map((item) => metric.includes(item))
        .find((item) => item === true);
}

const formatMetrics = (metric) => {

    if (!metric) {
        return 0;
    }

    if (typeof metric === 'string' && checkAbbreviation(metric)) {
        return metric;
    }

    let toString = metric.toString();
    let strLength = toString.length;

    switch(strLength) {
        case 4:
            toString = `${toString.substring(0,1)}K`;
            break;
        case 5:
            toString = `${toString.substring(0,2)}K`;
            break;
        case 6:
            toString = `${toString.substring(0,3)}K`;
            break;
        case 7:
            toString = `${toString.substring(0,1)}M`;
            break;
        case 8: 
            toString = `${toString.substring(0,2)}M`;
            break;
        case 9: 
            toString = `${toString.substring(0,3)}M`;
            break;
        default:
            toString = toString;
    }

    return toString;
}

export default formatMetrics;