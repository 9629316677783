const findTopTick = (value) => {

    if (value < 20) {
        return value;
    }

    if (value < 150) {
        return Math.ceil(value / 10) * 10;
    }

    if (value < 1500) {
        return Math.ceil(value / 100) * 100;
    }

    if (value < 7500) {
        return Math.ceil(value / 500) * 500;
    }

    if (value < 15000) {
        return Math.ceil(value / 1000) * 1000;
    }

    if (value < 150000) {
        return Math.ceil(value / 10000) * 10000;
    }

    if (value < 1500000) {
        return Math.ceil(value / 100000) * 100000;
    }

}

export default findTopTick;