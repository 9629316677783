import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Spinner,
} from '@shopify/polaris';

import SettingsLTV from '../components/settings-ltv/settings-ltv';
import { getAdvertiser } from '../actions/advertiser';
import { updateAdvertiser } from '../actions/update-advertiser';
import './styles/settings-ltv.css';

class LTVSettings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            savedAdvertiser: props.advertiser
        }
    }

    componentDidMount() {
        if (this.props.advertiser.myshopify_domain) {
            this.props.getAdvertiser(this.props.advertiser.myshopify_domain)
        }
    }

    render() {
        return (
            <React.Fragment>
                { this.props.advertiser.advertiser_id ? <SettingsLTV {...this.props} {...this.state}/> : 
                    <div className="ltv-settings-loading">
                        <Spinner size="large" color="inkLightest"/>
                    </div>  
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {

    return {
        advertiser: state.advertiser
    }

}

const mapDispatchtoProps = (dispatch) => {

    return {
        getAdvertiser: (shop) => dispatch(getAdvertiser(shop)),
        updateAdvertiser: (data, advertiser) => dispatch(updateAdvertiser(data, advertiser))
    }

}


export default connect(mapStateToProps, mapDispatchtoProps)(LTVSettings);

