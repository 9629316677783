const discountFragment = `
    fragment DiscountData on PriceRule {
        advertiser_id
        offer_id
        created_at
        starts_at
        ends_at
        entitled_product_ids
        price_rule_id
        discount_id
        code
        value
        value_type
        target_type
        allocation_method
        once_per_customer
        affiliate_id
        affiliate {
            name
            cognito_id
        }
        error_message
    }
`;

export default discountFragment;
