/* import axios from 'axios';
import { NEXT_APP_API_URL } from '../config'; */
import {sendGraphQL} from './sendRequest'

export const UPDATE_EMAIL_PREF_PENDING = 'UPDATE_EMAIL_PREF_PENDING';
export const UPDATE_EMAIL_PREF_FULFILLED = 'UPDATE_EMAIL_PREF_FULFILLED';
export const UPDATE_EMAIL_PREF_REJECTED = 'UPDATE_EMAIL_PREF_REJECTED';


export function updateEmailPref(emailInfo, advertiser_id) {


    const query = `
    mutation updateAdvertiser($advertiser_id: String, $data: UpdateAdvertiserInput!) {
        updateAdvertiser(advertiser_id: $advertiser_id, data: $data){
            email_preference{
                application_general
                application_alerts
                application_affiliate_messages
                application_weekly_summary
                newsletter_general
                newsletter_marketing
                newsletter_updates
                email_unsubscribe
            }
        }
    }
    `

    const variables = {
        advertiser_id: advertiser_id,
        data: {
            "email_preference": emailInfo
        }
    }

    let payload = {
        query,
        variables
    }

    return {
        type: "UPDATE_ADVERTISER",
        payload: sendGraphQL(payload)
            .then(response => response.data)
    };

    /* let headers = {
        "Content-Type": 'application/json'
    }

    return {
        type: "UPDATE_ADVERTISER",
        payload: axios.post(NEXT_APP_API_URL, payload, { headers })
        .then(response => response.data)
    }; */
}