/* import axios from 'axios';
import { NEXT_APP_API_URL } from '../config'; */
import {sendGraphQL} from './sendRequest'

const GET_APP_DATA = 'GET_APP_DATA';
export const GET_APP_DATA_PENDING = 'GET_APP_DATA_PENDING';
export const GET_APP_DATA_FULFILLED = 'GET_APP_DATA_FULFILLED';
export const GET_APP_DATA_REJECTED = 'GET_APP_DATA_REJECTED';

const getAppData = () => {

    const query = `
        query {
            app {
                categories {
                    name
                    value
                }
                countries {
                    name
                    country_code
                }
            }
        }
    `

    const payload = {
        query
    }

    return {
        type: GET_APP_DATA,
        payload: sendGraphQL(payload)
            .then(response => response.data)
    }

    /* let headers = {
        "Content-Type": 'application/json'
    }

    return {
        type: GET_APP_DATA,
        payload: axios.post(NEXT_APP_API_URL, payload, { headers })
            .then(response => response.data)
    } */

}

export {
    getAppData
}