/* import axios from 'axios';
import { NEXT_APP_API_URL } from '../config'; */
import { sendGraphQL } from './sendRequest';

export const getApplicationsSummary = (advertiser_id) => {
    const query = `
        query applicationsForAdvertiser($advertiser_id: String!) {
            advertisers(advertiser_id: $advertiser_id) {
                applications {
                    application_id
                    affiliate_status
                    advertiser_status
                    advertiser_id
                    offer {
                        offer_id
                        name
                    }
                    affiliate_id
                    affiliate {
                        _id
                        cognito_id
                        name
                        photo
                    }
                    advertiser_favorite
                    created_at
                }
            }
        }
    `;

    const variables = {
        advertiser_id
    };

    const payload = {
        query,
        variables
    };

    return sendGraphQL(payload)
        .then((response) => { return response.data.data.advertisers[0]; });
};

const getApplicationsForAdvertiser = (advertiser_id) => { 
    
    if (!advertiser_id) return Promise.resolve(null);

    const query = `
        query applicationsForAdvertiser($advertiser_id: String!) {
            advertisers(advertiser_id: $advertiser_id) {
                applications {
                    application_id
                    affiliate_status
                    advertiser_status
                    advertiser_id
                    advertiser {
                        name
                        email
                        company
                    }
                    target_id
                    target_type
                    offer {
                        offer_id
                        name
                        offer_type
                        category
                        private_offer
                    }
                    affiliate_id
                    affiliate {
                        _id
                        cognito_id
                        name
                        photo
                        account {
                            bio
                            categories
                            signUpDate
                            audience_gender
                            audience_country
                            audience_age_range
                        }
                        youTubeData {
                            channelStats {
                                subscribers
                            }
                        }
                        twitterData {
                            user {
                                followers
                            }
                        }
                        instagramDataV2 {
                            followers_count
                            account_type
                            account_verified
                        }
                        facebookData {
                            pageStats {
                                fan_count
                            }
                        }
                        tikTokData {
                            aqs
                            account_type
                            account_verified
                            basic {
                              id
                              username
                              title
                              avatar_url
                              description
                            }                        
                        }
                        tracking {
                            Stat {
                                revenue
                                epc
                                clicks
                                offer_id
                                conversions
                            }
                        }
                        tracking_id
                    }
                    advertiser_favorite
                    created_at
                }
                offers {
                    offer_id
                    name
                }
            }
        }
    `

    const variables = {
        advertiser_id
    }

    const payload = {
        query,
        variables
    }

    return sendGraphQL(payload)
        .then(response => response.data.data.advertisers[0])

    /* let headers = {
        "Content-Type": 'application/json'
    }

    return axios.post(NEXT_APP_API_URL, payload, { headers })
        .then(response => response.data.data.advertisers[0]) */

}

const changeApplicationStatus = (application_ids, advertiser_status, affiliate_status, tracking_link) => {

    if (!application_ids || !advertiser_status || !tracking_link) return null;

    const query = `
        mutation changeApplicationStatus($application_ids: [String!]!, $advertiser_status: String!, $affiliate_status: String!, $tracking_link: String!) {
            changeApplicationStatus(
                application_ids: $application_ids, 
                advertiser_status: $advertiser_status, 
                affiliate_status: $affiliate_status
                tracking_link: $tracking_link
            ) {
                application_id
                advertiser_status
            }
        }
    `

    const variables = {
        application_ids,
        advertiser_status,
        affiliate_status,
        tracking_link
    }

    const payload = {
        query,
        variables
    }

    return sendGraphQL(payload)
        .then(response => response.data.data.changeApplicationStatus)

    /* let headers = {
        "Content-Type": 'application/json'
    }

    return axios.post(NEXT_APP_API_URL, payload, { headers })
        .then(response => response.data.data.changeApplicationStatus) */

}

const updateFavoriteStatus = (application_id, advertiser_id, favorite_status = true) => {

    if (!application_id || !advertiser_id) {
        return Promise.reject("Insufficent data provided.  Please include `application_id`, `advertiser_id`.")
    };

    const query = `
        mutation changeApplicationFavoriteStatus(
            $application_id: String!,
            $advertiser_id: String,
            $favorite_status: Boolean!
        ){
            changeApplicationFavoriteStatus(
                application_id: $application_id, 
                favorite_status: $favorite_status, 
                advertiser_id: $advertiser_id
            ) {
                application_id
                advertiser_favorite
            }
        }
    `

    const variables = {
        application_id,
        advertiser_id,
        favorite_status
    }

    const payload = {
        query,
        variables
    }

    return sendGraphQL(payload)
        .then(response => response.data.data.changeApplicationFavoriteStatus)

    /* let headers = {
        "Content-Type": 'application/json'
    }

    return axios.post(NEXT_APP_API_URL, payload, { headers })
        .then(response => response.data.data.changeApplicationFavoriteStatus) */

}

const getApplicationsForOffer = (offer_id, advertiser_id) => {

    const query = `
        query getApplicationsForOffer($offer_id: String!, $advertiser_id: String!) {
            advertisers(advertiser_id: $advertiser_id) {
                applications(offer_id: $offer_id) {
                    affiliate {
                        affiliate_id
                        tracking_id
                        name
                    }
                    advertiser_status
                }
            }
        }
    `

    const variables = {
        offer_id,
        advertiser_id
    }

    const payload = {
        query,
        variables
    }

    return sendGraphQL(payload)
        .then(response => response.data.data)

    /* let headers = {
        "Content-Type": 'application/json'
    }

    return axios.post(NEXT_APP_API_URL, { query, variables }, { headers })
        .then(response => response.data.data) */

}

export {
    getApplicationsForAdvertiser,
    changeApplicationStatus,
    updateFavoriteStatus,
    getApplicationsForOffer,
};