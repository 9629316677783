import React from 'react';
import { ResponsiveBar } from '@nivo/bar';

import { numberFormat, moneyFormat } from 'llama-library/utils';

import useResponsive from '../../hooks/use-responsive';

const LookForwardInsights = (props) => {
    const pageWidth = useResponsive();

    const renderBarGraph = (direction = 'vertical') => {
        if(pageWidth <= 450){
            direction = 'vertical';
        }
        let margin = {}
        let x = null;
        let y = null;
        if(direction === 'horizontal'){
            margin = {
                "top": 0,
                "right": 100,
                "bottom": 0,
                "left":10
            }
            x = '90%'
            y = null;
        }else{
            margin = {
                "top": 20,
                "right": 10,
                "bottom": 20,
                "left":10
            }
            y = -10
            x = null
        }

        //nivo reverses the order of the data in horizontal mode, so we need to reverse the external labels to match
        let orderedGraphData = [...props.ltvGraphData]
        if(direction === 'horizontal') orderedGraphData.reverse();

        return (
            <div className="ltv-graph">
                <h3>Estimated LTV</h3>
                <p>Total estimated revenue per customer.</p>
                <div className="settings-ltv__nivo-bgcolor">
                    <div className='nivo__ltv'>
                        <ResponsiveBar
                            data={props.ltvGraphData}
                            keys={[
                                "Estimated Lifetime Value"
                            ]}
                            indexBy="storeType"
                            margin={margin}
                            innerPadding={0}
                            padding={0.3}
                            colors={['#fc2c58', '#00a313']}
                            colorBy={function(e){var t=e.id;return e.data["".concat(t,"Color")]}}
                            layout={direction}
                            axisTop={null}
                            axisRight={null}
                            axisBottom={null}
                            axisLeft={null}
                            borderColor="inherit:darker(1.6)"
                            labelSkipWidth={12}
                            labelSkipHeight={12}
                            labelTextColor="#000000"
                            animate
                            motionStiffness={90}
                            motionDamping={15}
                            tooltip={({ id, value, color, indexValue }) => {
                                return (
                                    <div className="nivo-graph-tooltip-wrapper">
                                        <span className="nivo-graph-tooltip-date">{id}</span>
                                        <span className="nivo-graph-tooltip-item">
                                            <span className="nivo-graph-tooltip-color-block" style={{ background: color }} />
                                            {indexValue}: <span style={{ fontWeight: 'bold' }}>{moneyFormat(value)}</span>
                                        </span>
                                    </div>
                                );
                            }}
                        />
                    </div>
                    <p className="settings-ltv__graph-labels">
                        {orderedGraphData.map((data, key) => {
                            return <span className="settings-ltv__graph-label" key={key}>{data.storeType}</span>
                        })}
                    </p>
                </div>
            </div>
        )
    }

    return (
        <>
            <p className="breakdown-intro">Based on your store&rsquo;s past order history, we&rsquo;ve estimated what your average order value, long term value (LTV), and commission payments could be over the next {props.forwardSliderValue} month{props.forwardSliderValue !== 1 && 's'}.</p>

            <div className="info-blocks">
                <div>
                    <h3>Total Sales History</h3>
                    <p>Total revenue your store produced over the past {props.ltvMonths} month{props.ltvMonths !== 1 && 's'}.</p>
                    {props.salesData ?
                        <dl>
                            <dt className="all">All Customers</dt>
                            <dd>{'$'+numberFormat(props.salesData.totalSales, 2)}</dd>
                            <dt className="one-time">One-Time Customers</dt>
                            <dd>{'$'+numberFormat(props.salesData.totalNonRepeatSales, 2)}</dd>
                            <dt className="repeat">Repeat Customers</dt>
                            <dd>{'$'+numberFormat(props.salesData.totalRepeatSales, 2)}</dd>
                        </dl>
                    :
                        <dl>
                            <dt className="all">All Customers</dt>
                            <dd>$0.00</dd>
                            <dt className="one-time">One-Time Customers</dt>
                            <dd>$0.00</dd>
                            <dt className="repeat">Repeat Customers</dt>
                            <dd>$0.00</dd>
                        </dl>
                    }
                </div>
                <div>
                    <h3>Average Order Value</h3>
                    <p>Total estimated revenue per customer per order.</p>
                    {props.avgOrderValue ?
                        <dl>
                            <dt className="all">All Customers</dt>
                            <dd>{props.avgOrderValue.all}</dd>
                            <dt className="one-time">One-Time Customers</dt>
                            <dd>{props.avgOrderValue.one}</dd>
                            <dt className="repeat">Repeat Customers</dt>
                            <dd>{props.avgOrderValue.repeat}</dd>
                        </dl>
                    :
                        <dl>
                            <dt className="all">All Customers</dt>
                            <dd>$0.00</dd>
                            <dt className="one-time">One-Time Customers</dt>
                            <dd>$0.00</dd>
                            <dt className="repeat">Repeat Customers</dt>
                            <dd>$0.00</dd>
                        </dl>
                    }
                </div>
                {pageWidth <= 975 && renderBarGraph('horizontal')}
                <div className="commission">
                    <h3>Projected Commission Payments</h3>
                    <p>Estimate of what you&rsquo;ll pay ambassadors for initial and long term sales per order for 5 common structures.</p>
                    <table>
                        <thead>
                            <tr>
                                <th>Commission Structure</th>
                                <th>Rate</th>
                                <th>Initial Sale</th>
                                <th>Long Term Sales</th>
                            </tr>
                        </thead>
                        <tbody>
                        {props.commissions ?
                            props.commissions.map((commissionStruct, commissionPercent) => {
                                return (
                                    <tr key={commissionPercent}>
                                        <td>{commissionPercent}% Per Order</td>
                                        <td>{commissionStruct.rate}</td>
                                        <td>{commissionStruct.initialSale}</td>
                                        <td>{commissionStruct.longtermSale}</td>
                                    </tr>
                                )
                            })
                        :
                            [5, 10, 15, 20, 25].map((commissionPercent) => {
                                return (
                                    <tr key={commissionPercent}>
                                        <td>{commissionPercent}% Per Order</td>
                                        <td>$0.00</td>
                                        <td>$0.00</td>
                                        <td>$0.00</td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </table>
                </div>
            </div>
            {pageWidth > 975 && renderBarGraph()}
        </>
    )
}

export default LookForwardInsights;