/**
 * Page descriptions. Keys:
 * start with path url, if multiple paths refer to the same how-to, define a case in the switch in leo-help-widget.js
 *
 * name: The page name. Will show up as "What is {name}?"
 * plural: Boolean. Whether the name is plural. If true the button text will say "What are {name}?"
 * content: Written description. Keep it brief. HTML is accepted.  Wrap images in p tags if you need a line break after them. Images will be displayed at a max width of 100% and a max height of 170px, but the user can enlarge them by clicking.
 * kbLink: Link to corresponding knowledgebase page
 * kbText: Use if you want to be more specific than "Learn more"
 */
export const definitions = {
    '/': {
        name: 'Dashboard',
        content: '',
        kbLink: ''
    },
    '/products': {
        name: 'Products Manager',
        content: 'Products Manager lets you save time when creating and updating offers by defining <strong>which products should be excluded from all of your Llama offers</strong>.',
        kbLink: ''
    },
    '/offers': {
        name: 'Offers',
        plural: true,
        content: '<p>Offers are <strong>Ambassador programs that you create and run on Llama</strong>.</p><p>Need some pro tips? Check out our Knowledge Base guide about what makes an offer successful on our platform.</p>',
        kbLink: 'https://help.llama.app/article/8-anatomy-of-a-successful-offer',
        kbText: 'Learn more about offers'
    },
    '/offer-new': {
        name: 'Offers',
        plural: true,
        content: '<p>Offers are <strong>Ambassador programs that you create and run on Llama</strong>.</p><p>Need some pro tips? Check out our Knowledge Base guide about what makes an offer successful on our platform.</p>',
        kbLink: 'https://help.llama.app/article/6-how-do-i-create-my-first-offer',
        kbText: 'Help me create an offer'
    },
    '/offer/id/edit': {
        name: 'Edit Offer',
        content: '',
        kbLink: ''
    },
    '/offer/id': {
        name: 'Offer Dashboard',
        content: '',
        kbLink: ''
    },
    '/offer/id/reports': {
        name: 'Offer Reports',
        plural: true,
        content: '',
        kbLink: ''
    },
    '/offer/id/coupons': {
        name: 'Offer Coupons',
        plural: true,
        content: '',
        kbLink: ''
    },
    '/offer/id/creatives': {
        name: 'Offer Creatives',
        plural: true,
        content: '',
        kbLink: ''
    },
    '/offer/id/links': {
        name: 'Ambassador Links',
        plural: true,
        content: '',
        kbLink: ''
    },
    '/invoices': {
        name: 'Invoices',
        plural: true,
        content: '',
        kbLink: ''
    },
    '/performance': {
        name: 'Ambassador Performance',
        content: '',
        kbLink: ''
    },
    '/applications': {
        name: 'Ambassador Applications',
        plural: true,
        content: '',
        kbLink: ''
    },
    '/discover': {
        name: 'Ambassadors',
        plural: true,
        content: `
                <p>You may know them as influencers or affiliates, but <strong>Ambassadors</strong> are what we call the <strong>people who will promote your offers</strong> to their social media audiences. If they find your offer, they can apply to it but will be <strong>subject to your approval</strong>.</p>
                <p>You can also invite ambassadors directly and they will be <strong>automatically approved</strong> to promote your offers. Only your approved ambassadors can earn commissions or free product coupon codes.</p>
        `,
        kbLink: 'https://help.llama.app/article/11-how-do-i-get-ambassadors-influencers-to-promote-my-products'
    },
    '/affiliate/id': {
        name: 'Ambassador Profile',
        content: '',
        kbLink: ''
    },
    '/invite': {
        name: 'Ambassador Invite Page',
        content: 'Your Ambassador Invite Page is a <strong>customizable sign up page</strong> that lets Ambassadors <strong>join Llama through your primary Offer</strong>, auto-approved. Share it on your store, social media, in an email blast, or anywhere else you want.',
        kbLink: 'https://help.llama.app/article/13-can-i-invite-my-own-ambassadors-influencers-to-llama'
    },
    '/import-ambassadors': {
        name: 'Import Ambassadors',
        content: `
            <p>Import Ambassadors is where you can migrate your existing Ambassadors from other platforms.</p>
            <p>Once you&rsquo;ve <strong>set up your primary offer</strong>, feel free to import your current Ambassadors we&rsquo;ll send them an email inviting them to join Llama and run all of your offers. We&rsquo;ll even auto-approve them for you.</p>
        `,
        kbLink: 'https://help.llama.app/article/13-can-i-invite-my-own-ambassadors-influencers-to-llama'
    },
    '/settings': {
        name: 'General Settings',
        content: '',
        kbLink: ''
    },
    '/settings/plan': {
        name: 'Plans',
        plural: true,
        content: '',
        kbLink: ''
    },
    '/settings/payment': {
        name: 'Payment Settings',
        content: '',
        kbLink: ''
    },
    '/settings/notifications': {
        name: 'Notification Settings',
        content: '',
        kbLink: ''
    },
    '/settings/ltv': {
        name: 'LTV',
        content: '<p>LTV, or <strong>Long-Term Value</strong>, is the founding principle on which Llama is built. It encourages recurring commissions for Ambassadors, offers protection for your existing customers, and gives you complete control over how long Ambassadors can earn.</p>',
        kbLink: 'https://help.llama.app/article/14-what-is-ltv-and-why-does-it-matter'
    },
    '/settings/terms': {
        name: 'Terms & Conditions',
        content: '',
        kbLink: ''
    }
};

/**
 * How-to menu buttons. Keys:
 * start with path url, if multiple paths refer to the same how-to, define a case in the switch in leo-help-widget.js
 * each object in the path url array refers to a button grouping
 *
 * heading: Use only if you have multiple groupings of buttons, otherwise keep blank or omit
 * buttons: List of buttons in a grouping
 * buttons.label: The text inside the button
 * buttons.content: Written text instructions. HTML is accepted. Keep it brief. Use ordered lists when possible. Use strong tags for button names, page names, and other important words. Wrap images in p tags if you need a line break after them. Images will be displayed at a max width of 100% and a max height of 170px, but the user can enlarge them by clicking.
 * buttons.kbLink: Link to corresponding knowledgebase page
 * buttons.kbText: Use if you want to be more specific than "Learn more"
 * buttons.youtubeId: Use if there's a corresponding video. Fill with what comes after https://youtube.com/watch?v= To start at a specific timestamp, add "&t=" followed by the timestamp in seconds
 */
export const howtos = {
    '/': [
        {
            buttons: [
                {
                    label: 'Get started on Llama',
                    content: `
                        <p>Just getting started? No prob-llama. We&rsquo;ve put together a walkthrough of everything you&rsquo;ll need to complete to get your first ambassador program &#40;a.k.a. offer&#41; up and running and start earning more for your store.</p>
                    `,
                    kbLink: 'https://help.llama.app/article/5-where-should-i-start-after-i-sign-up'
                }
            ]
        }
    ],
    '/products': [],
    '/offers': [
        {
            buttons: [
                {
                    label: 'Create an offer',
                    content: `
                        <p>Simply click <strong>Create New Offer</strong> and fill out the form.</p>
                        <p>If you&rsquo;re not sure what to fill out, we have a guided walkthrough that will take you through the form step-by-step.</p>
                    `,
                    kbLink: 'https://help.llama.app/article/6-how-do-i-create-my-first-offer',
                    kbText: 'Help me create an offer'
                },
                {
                    label: 'Track conversions',
                    content: `
                        <p>To see how your offers and/or Ambassadors are performing simply <strong>Select</strong> the offer to navigate to the offer dashboard.</p>
                    `,
                    kbLink: 'https://help.llama.app/article/9-tracking-conversions-ambassador-links-coupons-repeat-orders'
                },
                {
                    label: 'Ad boost my offer',
                    content: `
                        <p>To bid on having your offer appear in the top 3 slots of every Ambassador’s Featured Offers list simply click <strong>Boost</strong></p>
                    `,
                    kbLink: 'https://help.llama.app/article/51-how-can-ambassadors-see-my-offers-first'
                },
                {
                    label: 'Create product giveaway offer',
                    content: `
                        <ol>
                            <li>Click <strong>Create New Offer</strong></li>
                            <li>Check the <strong>Product Giveaway</strong> box</li>
                            <li>Complete the rest of the New Offer form</li>
                        </ol>
                    `,
                    kbLink: 'https://help.llama.app/article/52-what-is-a-product-giveaway-offer'
                }
            ]
        }
    ],
    '/offer-new': [
        {
            buttons: [
                {
                    label: 'Create an offer',
                    content: `
                        <p>If you&rsquo;re not sure what to fill out, we have a guided walkthrough that will take you through the form step-by-step.</p>
                    `,
                    kbLink: 'https://help.llama.app/article/6-how-do-i-create-my-first-offer',
                    kbText: 'Help me create an offer'
                },
                {
                    label: 'Create product giveaway offer',
                    content: `
                        <ol>
                            <li>Click <strong>Create New Offer</strong></li>
                            <li>Check the <strong>Product Giveaway</strong> box</li>
                            <li>Complete the rest of the New Offer form</li>
                        </ol>
                    `,
                    kbLink: 'https://help.llama.app/article/52-what-is-a-product-giveaway-offer'
                }
            ]
        }
    ],
    '/offer/id/edit': [],
    '/offer/id': [
        {
            buttons: [
                {
                    label: 'Track conversions',
                    content: `
                        <p>To see revenue earned in the last month from your offer, check the sales graph here in the <strong>Dashboard</strong> tab.</p>
                        <p>For more detailed reports such as order number, order date, Ambassador etc. go to the <strong>Reports</strong> tab.</p>
                    `,
                    kbLink: 'https://help.llama.app/article/9-tracking-conversions-ambassador-links-coupons-repeat-orders'
                },
            ]
        }
    ],
    '/offer/id/reports': [],
    '/offer/id/coupons': [
        {
            buttons: [
                {
                    label: 'Create coupon codes',
                    content: `
                        <p>To create a custom coupon code <strong>you&rsquo;ll need to have approved at least one Ambassador</strong> to run your offer.</p>
                        <ol>
                            <li><strong>Select</strong> an Ambassador &#40;you can select multiple&#41; to assign the coupon to.</li>
                            <li>Choose if you&rsquo;re coupon includes all items or specific items. If you choose specific you must also select the specific items.</li>
                            <li><strong>Enter</strong> a name for the coupon code.</li>
                            <li><strong>Select</strong> the discount type</li>
                            <li>Use the date picker to choose a start date and time.</li>
                            <li>If you would like to set an expiration date, check <strong>Set end date</strong> and use the date picker to choose a date and time.</li>
                        </ol>
                    `,
                    kbLink: 'https://help.llama.app/article/9-tracking-conversions-ambassador-links-coupons-repeat-orders'
                }
            ]
        }
    ],
    '/offer/id/creatives': [],
    '/offer/id/links': [
        {
            buttons: [
                {
                    label: 'Manage Ambassador links',
                    content: `
                        <p>To create an Ambassador link simply <strong>Select</strong> an Ambassador from the dropdown.</p>
                        <p><strong>Note</strong>: You must <strong>approve an Ambassador to run your offer</strong> before a link can be created. You can go to <strong>Discover</strong> to invite Ambassadors.</p>
                    `,
                    kbLink: 'https://help.llama.app/article/9-tracking-conversions-ambassador-links-coupons-repeat-orders'
                }
            ]
        }
    ],
    '/offer/id/boost': [
        {
            buttons: [
                {
                    label: 'Ad boost my offer',
                    content: `
                        <p>To bid on having your offer appear in the top 3 slots of every Ambassador&rsquo;s Featured Offers list, simply click <strong>Boost Offer</strong>.</p>
                        <p><strong>Note</strong>: You will need to add a payment method to bid. You can do so in <strong>Settings</strong> under the <strong>Payment</strong> tab.</p>
                    `,
                    kbLink: 'https://help.llama.app/article/51-how-can-ambassadors-see-my-offers-first'
                }
            ]
        }
    ],
    '/invoices': [
        {
            buttons: [
                {
                    label: 'Pay ambassadors commission',
                    content: `
                        <p>To pay outstanding &#40;unpaid&#41;, Invoices, simply click the Invoice and we’ll redirect you to PayPal to complete the payment.</p>
                        <p><strong>Note:</strong> We recommend being extremely prompt with your payments to keep your Ambassadors happy and motivated.</p>
                    `,
                    kbLink: 'https://help.llama.app/article/18-paying-commissions'
                }
            ]
        }
    ],
    '/performance': [],
    '/applications': [],
    '/discover': [
        {
            buttons: [
                {
                    label: 'Find relevant ambassadors',
                    content: `
                        <ol>
                            <li>Search to find Ambassadors by name, or use the Smart Filters to find Ambassadors with an audience that&rsquo;s most relevant to your Offers.</li>
                            <li>Click <strong>Apply Filters</strong> to see your matches.</li>
                            <li>If you want to get a closer look at an Ambassador&rsquo;s social media presence, click <strong>View Details</strong>.</li>
                            <li>Once you’ve found a good fit, click <strong>Invite</strong>.</li>
                            <li>Select which Offer&#40;s&#41; you want the Ambassador to promote.</li>
                            <li>Click <strong>Send Invitation</strong>. They will be notified and instantly approved.</li>
                            <li>Repeat for any other Ambassadors you want to invite.</li>
                        </ol>
                    `,
                    kbLink: 'https://help.llama.app/article/11-how-do-i-get-ambassadors-influencers-to-promote-my-products'
                },
                {
                    label: 'Choose the right ambassadors',
                    content: `
                        <p>Not sure what you&rsquo;re looking for? Check out our Knowledge Base guide where we go over these metrics in detail and how to use them to <strong>choose the right Ambassadors to invite</strong>.</p>
                    `,
                    kbLink: 'https://help.llama.app/article/53-what-should-i-look-for-in-an-ambassador'
                }
            ]
        }
    ],
    '/affiliate/id': [],
    '/invite': [
        {
            buttons: [
                {
                    label: 'Invite ambassadors outside of Llama',
                    content: `
                        <ol>
                            <li>Upload a <strong>Program Banner</strong> and choose a Brand Color to personalize your page.</li>
                            <li>Select a <strong>Featured Offer</strong> to invite ambassadors to promote. We&rsquo;ll automatically populate your commission rates and performance stats.</li>
                            <li>Enter a <strong>Program Description</strong> to tell ambassadors more about your offer and what&rsquo;s in it for them.</li>
                            <li>Click <strong>Save</strong>.</li>
                            <li>Copy and Share your Invite Page Link and spread the word about your program from your website, social media, or anywhere really.</li>
                        </ol>
                    `,
                    kbLink: 'https://help.llama.app/article/13-can-i-invite-my-own-ambassadors-influencers-to-llama'
                },
                {
                    label: 'Turn my customers into ambassadors',
                    content: `
                        <p>Simply check the box <strong>Let customers join from my order confirmation page</strong> then click <strong>Save</strong> to give your customers the option to join your ambassador program at checkout.</p>
                        <p>To get an idea of what this might look like, click <strong>Learn more</strong>.</p>
                    `,
                    kbLink: 'https://help.llama.app/article/22-can-i-turn-my-customers-into-ambassadors',
                    kbText: 'Learn more about this'
                }
            ]
        }
    ],
    '/import-ambassadors': [
        {
            buttons: [
                {
                    label: 'Import my own ambassadors',
                    content: `
                        <p>If you&rsquo;re coming from another affiliate network, you can import your current Ambassadors here. Simply select your platform and follow our step-by-step instructions to import them into Llama.</p>
                    `,
                    kbLink: 'https://help.llama.app/article/13-can-i-invite-my-own-ambassadors-influencers-to-llama'
                }
            ]
        }
    ],
    '/settings': [],
    '/settings/plan': [],
    '/settings/payment': [],
    '/settings/notifications': [],
    '/settings/ltv': [
        {
            buttons: [
                {
                    label: 'Adjust my Look Forward/Look Back period',
                    content: `
                        <p>Use the sliders to adjust your <strong>Look Forward</strong> and <strong>Look Back</strong> periods and then click <strong>Save</strong> to lock in your settings.</p>
                        <p><strong>Note:</strong> These settings will be <strong>locked for 30 days and you will not be able to change them</strong> during that time.</p>
                    `,
                    kbLink: 'https://help.llama.app/article/17-look-back-and-look-forward-periods'
                }
            ]
        }
    ],
    '/settings/terms': []
};
