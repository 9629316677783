import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Heading, TextStyle, Select, TextField, Banner, DatePicker, Popover, Checkbox, Autocomplete, Tag } from '@shopify/polaris';
import { withFormik } from 'formik';
import moment from 'moment';
import { LlamaButton, LlamaToast } from 'llama-library/components';

import { moneyFormat } from '../../utils/number-format';
import formatPercent from '../../utils/format-percent';
import CenteredSpinner from '../centered-spinner';
import llamaImage from '../../assets/llama-head-01.png';

import './offer-coupons.css';

import { createPriceRule } from '../../actions/price-rule';

import CouponProductSelections from './coupon-product-selections';

/**
 * Returns the number of decimals on a number value
 * @param {Int || Float || String} value
 */

let now = new Date();
now.setHours(0, 0, 0);
now = new Date(now);

const countDecimals = (value) => {
    const string = value.toString();
    const [_, decimals] = string.split('.');

    if (decimals) {
        return decimals.length;
    }
    return 0;
};

/**
 * @enum Allcation Methods as defined by Shopify
 */
const allocationMethods = {
    EACH: 'EACH',
    ACROSS: 'ACROSS'
};

/**
 * @enum Value Types as defined by Shopify
 */
const valueTypes = {
    FIXED_AMOUNT: 'FIXED_AMOUNT',
    PERCENTAGE: 'PERCENTAGE'
};

/**
 * @enum Target Types as defined by Shopify
 */
const targetTypes = {
    LINE_ITEM: 'LINE_ITEM',
    SHIPPING_LINE: 'SHIPPING_LINE'
};

/**
 * @Array Discount Type
 *
 * Discount Type:
 * - value: unique value used by select input
 * - label: display value for user
 * - defaultPriceRule: values to add to form upon selection
 */
const discountTypes = [
    {
        value: `${targetTypes.SHIPPING_LINE}-${valueTypes.PERCENTAGE}`,
        label: 'Free Shipping',
        defaultPriceRule: {
            value: -100,
            value_type: valueTypes.PERCENTAGE,
            target_type: targetTypes.SHIPPING_LINE,
            allocation_method: allocationMethods.EACH
        }
    },
    {
        value: `${targetTypes.LINE_ITEM}-${valueTypes.FIXED_AMOUNT}`,
        label: 'Fixed Amount',
        defaultPriceRule: {
            value: 10.00,
            value_type: valueTypes.FIXED_AMOUNT,
            target_type: targetTypes.LINE_ITEM,
            allocation_method: allocationMethods.ACROSS
        }
    },
    {
        value: `${targetTypes.LINE_ITEM}-${valueTypes.PERCENTAGE}`,
        label: 'Percentage',
        defaultPriceRule: {
            value: 10,
            value_type: valueTypes.PERCENTAGE,
            target_type: targetTypes.LINE_ITEM,
            allocation_method: allocationMethods.ACROSS
        }
    }
];

const timeOptions = [
    { label: '12:00AM', value: '0' },
    { label: '1:00AM', value: '1' },
    { label: '2:00AM', value: '2' },
    { label: '3:00AM', value: '3' },
    { label: '4:00AM', value: '4' },
    { label: '5:00AM', value: '5' },
    { label: '6:00AM', value: '6' },
    { label: '7:00AM', value: '7' },
    { label: '8:00AM', value: '8' },
    { label: '9:00AM', value: '9' },
    { label: '10:00AM', value: '10' },
    { label: '11:00AM', value: '11' },
    { label: '12:00PM', value: '12' },
    { label: '1:00PM', value: '13' },
    { label: '2:00PM', value: '14' },
    { label: '3:00PM', value: '15' },
    { label: '4:00PM', value: '16' },
    { label: '5:00PM', value: '17' },
    { label: '6:00PM', value: '18' },
    { label: '7:00PM', value: '19' },
    { label: '8:00PM', value: '20' },
    { label: '9:00PM', value: '21' },
    { label: '10:00PM', value: '22' },
    { label: '11:00PM', value: '23' }
];

const CouponForm = (props) => {
    const {
        applications,
        handleSubmit,
        isSubmitting,
        values,
        setFieldValue,
        setValues,
        errors,
        discountError,
        touched,
        setFieldTouched,
        offer,
        history,
        addDiscount
    } = props;

    const [ambassadorInput, setAmbassadorInput] = useState('');
    const [initialAmbassadorOptions, setInitialAmbassadorOptions] = useState([]);
    const [ambassadorOptions, setAmbassadorOptions] = useState([]);
    const [selectedAmbassadors, setSelectedAmbassadors] = useState([]);

    const [discountType, setDiscountTypeSelection] = useState('');
    const [dateRange, setDateRange] = useState({
        endStatus: { checked: false },
        selected: {
            start: now,
            end: now
        }
    });
    const [startsPopOver, setStartsPopOver] = useState(false);
    const [endsPopOver, setEndsPopOver] = useState(false);
    const [displayDateRange, setDisplayDateRange] = useState({
        displayMonth: now.getMonth(),
        displayYear: now.getFullYear()
    });
    const [selectedTime, setSelectedTime] = useState({
        selected: {
            start: '0',
            end: '0'
        }
    });

    useEffect(() => {
        let options = [];
        if (applications) {
            options = applications.reduce((acc, application) => {
                if (application.affiliate.name && application.affiliate.tracking_id) {
                    acc.push({
                        value: application.affiliate.tracking_id,
                        label: application.affiliate.name
                    });
                }
                return acc;
            }, []);
        }
        setAmbassadorOptions(options);
        setInitialAmbassadorOptions(options);
    }, [applications]);

    // reset states after form submit
    useEffect(() => {
        // clear ambassador tags
        if (values.affiliate_id.length === 0) {
            setSelectedAmbassadors([]);
        }
        // reset discount type
        if (values.value_type === '') {
            setDiscountTypeSelection('');
        }
        if (!values.ends_at && dateRange.endStatus.checked) {
            setDateRange({
                endStatus: { checked: false },
                selected: {
                    start: now,
                    end: now
                }
            });
        }
    }, [values]);

    const handleAutocompleteTextChange = useCallback((value) => {
        setAmbassadorInput(value);

        if (value.trim() === '') {
            setAmbassadorOptions(initialAmbassadorOptions);
            return;
        }

        const filterRegex = new RegExp(value, 'i');
        const resultOptions = initialAmbassadorOptions.filter((option) => {
            return option.label.match(filterRegex);
        });
        setAmbassadorOptions(resultOptions);
    }, [ambassadorOptions]);

    const handleAmbassadorSelections = (selections) => {
        setFieldValue('affiliate_id', [...selections]);
        setFieldTouched('affiliate_id', true, false);
        setSelectedAmbassadors(selections);
    };

    const removeTag = useCallback((tag) => {
        return () => {
            const optionsTag = [...selectedAmbassadors];
            optionsTag.splice(optionsTag.indexOf(tag), 1);
            setFieldValue('affiliate_id', optionsTag);
            setSelectedAmbassadors(optionsTag);
        };
    }, [selectedAmbassadors]);

    const textField = (
        <Autocomplete.TextField
            id="assign-to"
            onChange={handleAutocompleteTextChange}
            value={ambassadorInput}
            placeholder="Select Ambassador(s)"
            error={touched.affiliate_id && errors.affiliate_id}
        />
    );

    // TODO: Perhaps display an invite button to invite exisiting affiliates.
    // Display message if the user has no affiliates added to this offer.
    if (!applications || applications.length === 0) {
        return (
            <div className="OfferCoupons__Form">
                <div className="OfferCoupons__Heading">
                    <Heading>CREATE COUPON CODE</Heading>
                    <TextStyle variation="subdued"><span className="subtitle">Create unique discounts for specific ambassadors to share with their audience when promoting this offer.</span></TextStyle>
                </div>
                {applications
                    ? (
                        <div className="OfferCoupons__NoAffiliates">
                            <img src={llamaImage} className="OfferCoupons__LlamaImage" alt="Leo the Llama" />
                            <div className="OfferCoupons__NoAffiliatesMessage">
                                <TextStyle variation="subdued">You&rsquo;ll need to invite an ambassador to this offer before creating a coupon code.</TextStyle>
                            </div>
                            <LlamaButton onClick={() => { history.push('/discover'); }}>Discover Ambassadors</LlamaButton>
                        </div>
                    )
                    : <CenteredSpinner />
                }
            </div>
        );
    }

    const handleDiscountTypeChange = (value) => {
        // The defaultPriceRule on each selection item is used to pass
        // into the form data to allow multiple valid changes to be made
        // at the same time.
        const selection = discountTypes.find((item) => {
            return item.value === value;
        });
        const { defaultPriceRule } = selection;

        if (defaultPriceRule) {
            // Set each field as touched.
            const fields = Object.keys(defaultPriceRule);
            fields.forEach((item) => {
                setFieldTouched(item, true, false);
            });

            // Set state of form and select box.
            setValues({ ...values, ...defaultPriceRule });
            setDiscountTypeSelection(value);
        }
    };

    // code and discount type fields
    const handleChange = (value, name) => {
        let setStates = true;
        if (values.value_type === valueTypes.FIXED_AMOUNT && name === 'value') {
            if (countDecimals(value) > 2) {
                setStates = false;
            }
        }

        if (setStates) {
            setFieldTouched(name, true, false);
            setFieldValue(name, value);
        }
    };

    const handleStartDateChange = (value) => {
        setDateRange({ ...dateRange, selected: { start: value.start, end: dateRange.selected.end } });
        setFieldValue('starts_at', value.start.toISOString());
    };

    const handleEndDateChange = (value) => {
        setDateRange({ ...dateRange, selected: { start: dateRange.selected.start, end: value.end } });
        setFieldValue('ends_at', value.end.toISOString());
    };

    const handleEndTimeChange = (value) => {
        const date = (dateRange.selected.end).setHours(value);
        setDateRange({ ...dateRange, selected: { start: dateRange.selected.start, end: (new Date(date)) } });
        setSelectedTime({ selected: { start: selectedTime.selected.start, end: value } });
        setFieldValue('ends_at', (new Date(date)).toISOString());
    };

    const handleStartTimeChange = (value) => {
        const date = (dateRange.selected.start).setHours(value);
        setDateRange({ ...dateRange, selected: { start: (new Date(date)), end: dateRange.selected.end } });
        setSelectedTime({ selected: { start: value, end: selectedTime.selected.end } });
        setFieldValue('starts_at', (new Date(date)).toISOString());
    };

    const handleShowEndsAt = () => {
        setDateRange({ ...dateRange, endStatus: { checked: !dateRange.endStatus.checked }, selected: dateRange.selected });
    };

    const handleMonthChange = (month, year) => {
        setDisplayDateRange({ displayMonth: month, displayYear: year });
    };

    const startsAt = (
        <div className="OfferCoupons__InputField">
            <TextField
                label="Valid From"
                value={moment(dateRange.selected.start).format('MMMM D, YYYY [at] hA')}
                onFocus={() => { return setStartsPopOver(true); }}
            />
        </div>
    );

    const endsAt = (
        <div className="OfferCoupons__InputField">
            <TextField
                label="Valid Through"
                value={moment(dateRange.selected.end).format('MMMM D, YYYY [at] hA')}
                onFocus={() => { return setEndsPopOver(true); }}
                error={errors.date}
            />
        </div>
    );

    const renderEnds = () => {
        if (dateRange.endStatus.checked) {
            return (
                <Popover
                    active={endsPopOver}
                    activator={endsAt}
                    onClose={() => { setEndsPopOver(false); }}
                    sectioned
                    fullHeight
                >
                    <DatePicker
                        month={displayDateRange.displayMonth}
                        year={displayDateRange.displayYear}
                        onChange={handleEndDateChange}
                        onMonthChange={handleMonthChange}
                        selected={dateRange.selected.end}
                    />
                    <div className="OfferCoupons__InputField OfferCoupons__InputTime">
                        <Select
                            label="End Time"
                            options={timeOptions}
                            onChange={handleEndTimeChange}
                            value={selectedTime.selected.end}
                        />
                    </div>
                </Popover>
            );
        }
        return null;
    };

    // Map discount types for type selection.
    const discountTypeOptions = discountTypes.map((item) => {
        const { value, label } = item;
        return { value, label };
    });

    const ends = renderEnds();

    return (
        <div className="OfferCoupons__Form">
            <form onSubmit={handleSubmit}>
                <div className="OfferCoupons__Heading">
                    <Heading>CREATE COUPON CODE</Heading>
                    <TextStyle variation="subdued"><span className="subtitle">Create unique discounts for specific ambassadors to share with their audience when promoting this offer.</span></TextStyle>
                </div>

                {/* assign to ambassadors */}
                <div className="OfferCoupons__InputField">
                    <label htmlFor="assign-to" className="Polaris-Label__Text">Assign to</label>
                    {selectedAmbassadors.length > 0
                        && (
                            <ul className="selected-ambassadors">
                                {selectedAmbassadors.map((ambassadorId) => {
                                    const ambassadorName = initialAmbassadorOptions.find((ambassadorOption) => {
                                        return ambassadorOption.value === ambassadorId;
                                    }).label;
                                    return (
                                        <li key={ambassadorId}>
                                            <Tag key={`option${ambassadorId}`} onRemove={removeTag(ambassadorId)}>
                                                {ambassadorName}
                                            </Tag>
                                        </li>
                                    );
                                })}
                            </ul>
                        )
                    }
                    <Autocomplete
                        allowMultiple
                        options={ambassadorOptions}
                        selected={selectedAmbassadors}
                        textField={textField}
                        onSelect={handleAmbassadorSelections}
                        listTitle="Suggested Ambassadors"
                    />
                </div>

                {/* included products */}
                {offer.product_info && Array.isArray(offer.product_info) && offer.product_info.length > 0
                    && (
                        <div className="OfferCoupons__Product">
                            <CouponProductSelections
                                products={offer.product_info}
                                setFieldValue={setFieldValue}
                                selectedProductValues={values.entitled_product_ids}
                            />
                        </div>
                    )
                }

                {/* coupon code name */}
                <div className="OfferCoupons__InputField">
                    <TextField
                        onChange={handleChange}
                        value={values.code}
                        id="code"
                        label="Coupon Code"
                        placeholder="e.g. LLAMASALE"
                        type="text"
                        error={touched.code && errors.code}
                        helpText="If selecting multiple ambassadors, each coupon code will automatically have 3 random characters appended to it to make it unique."
                    />
                </div>

                {/* coupon type */}
                {offer.offer_type !== 'product_giveaway'
                    && (
                        <div className="OfferCoupons__Value">
                            <div className="OfferCoupons__InputField OfferCoupons__ValueInput">
                                <Select
                                    onChange={handleDiscountTypeChange}
                                    options={discountTypeOptions}
                                    placeholder="Select"
                                    value={discountType}
                                    id="value_type"
                                    label="Discount Type"
                                    error={touched.value_type && errors.value_type}
                                />
                            </div>
                            {values.target_type === targetTypes.LINE_ITEM
                                && (
                                    <div className="OfferCoupons__InputField OfferCoupons__ValueInput">
                                        <TextField
                                            onChange={handleChange}
                                            value={Math.abs(values.value).toString()}
                                            id="value"
                                            label="Discount Value"
                                            type="number"
                                            suffix={values.value_type === valueTypes.PERCENTAGE ? '%' : null}
                                            prefix={values.value_type === valueTypes.FIXED_AMOUNT ? '$' : null}
                                            align={values.value_type === valueTypes.FIXED_AMOUNT ? 'left' : 'right'}
                                            error={touched.value && errors.value}
                                        />
                                    </div>
                                )
                            }
                        </div>
                    )
                }

                {/* coupon start datetime */}
                <div className="OfferCoupons__Date">
                    <>
                        <Popover
                            active={startsPopOver}
                            activator={startsAt}
                            onClose={() => { setStartsPopOver(false); }}
                            sectioned
                            fullHeight
                        >
                            <DatePicker
                                month={displayDateRange.displayMonth}
                                year={displayDateRange.displayYear}
                                onChange={handleStartDateChange}
                                onMonthChange={handleMonthChange}
                                selected={dateRange.selected.start}
                            />
                            <div className="OfferCoupons__InputField OfferCoupons__InputTime">
                                <Select
                                    label="Start Time"
                                    options={timeOptions}
                                    onChange={handleStartTimeChange}
                                    value={selectedTime.selected.start}
                                />
                            </div>
                        </Popover>

                        {/* coupon end datetime */}
                        <Checkbox
                            checked={dateRange.endStatus.checked}
                            label="Set end date"
                            onChange={handleShowEndsAt}
                        />
                        <div className="OfferCoupons__InputField OfferCoupons__InputTime">
                            {ends}
                        </div>
                    </>

                </div>

                {discountError && discountError !== ''
                    && (
                        <div className="OfferCoupons__ErrorBanner">
                            <Banner
                                status="critical"
                                onDismiss={() => { addDiscount({ error_message: '' }); }}
                            >
                                {discountError}
                            </Banner>
                        </div>
                    )
                }
                <div className="OfferCoupons__Submit">
                    <LlamaButton type="submit" loading={isSubmitting}>Create Coupon</LlamaButton>
                </div>
            </form>
        </div>
    );
};

/**
 * Creates the inital/default price_rule object.
 *
 * @param {Object} props Props from parent
 * @return {Object} price_rule object.
 */
const initalPriceRule = (props) => {
    return {
        offer_id: props.offer.offer_id,
        advertiser_id: props.advertiser.advertiser_id,
        affiliate_id: [],
        title: '',
        code: '',
        starts_at: now.toISOString(),
        value: 10,
        value_type: '',
        target_type: null,
        once_per_customer: true,
        allocation_method: allocationMethods.ACROSS,
        offer_type: null
    };
};

const formikConfig = {
    mapPropsToValues: initalPriceRule,
    validate: (values) => {
        const errors = {};

        // Affilaite errors
        if (!values.affiliate_id || values.affiliate_id.length === 0) {
            errors.affiliate_id = 'Required';
        }

        // Discount code errors
        if (!values.code || values.code === '') {
            errors.code = 'Required';
        } else if (!/^[a-zA-Z0-9]+$/i.test(values.code)) {
            errors.code = 'Only alphanumeric charcters are allowed.';
        }

        // Value Type errors.
        if (typeof (values.offer_type) !== 'undefined' && values.offer_type !== 'product_giveaway') {
            if (!values.value_type || !valueTypes[values.value_type]) {
                errors.value_type = 'Required';
            }
            // Target Type errors.
            if (values.target_type !== targetTypes.SHIPPING_LINE && (!values.value || values.value === 0)) {
                errors.value = 'Required';
            } else if (values.value_type === valueTypes.PERCENTAGE && values.value > 100) {
                errors.value = 'Please enter a number between 1 and 100.';
            } else if (values.value_type === valueTypes.FIXED_AMOUNT && values.value < 0) {
                errors.value = 'Please enter a amount greater than 0.';
            }
        }

        if (values.ends_at && (Date.parse(values.ends_at) < (Date.parse(values.starts_at)))) {
            errors.date = 'Ending date must be after the starting date';
        }

        return errors;
    },
    handleSubmit: (values, { setSubmitting, setValues, setErrors, setTouched, props }) => {
        if (props.offer.offer_type === 'product_giveaway') {
            values.offer_type = props.offer.offer_type;
            values.target_type = 'LINE_ITEM';

            // exclude the value and value type
            delete values.value;
            delete values.value_type;
        }

        if (values.entitled_product_ids && values.entitled_product_ids.length > 0
            && values.target_type !== targetTypes.SHIPPING_LINE) {
            values.target_selection = 'entitled';
        } else if (values.entitled_product_ids && values.entitled_product_ids.length === 0
            && values.target_type !== targetTypes.SHIPPING_LINE) {
            values.target_selection = 'all';
            values.target_type = 'LINE_ITEM';
        } else if (values.target_type === targetTypes.SHIPPING_LINE) {
            values.target_selection = 'all';
            delete values.entitled_product_ids;
        }

        // `title` is a required field, Shopify usually sets it as the
        // same as the coupon so that's what we're doing here.
        if (!values.title || values.title === '') {
            values.title = values.code;
        }

        // Add discount to current offer data.
        return createPriceRule(values)
            .then((result) => {
                if (result.error_message) {
                    // Error messages passed to addDiscount will display for the user.
                    props.addDiscount({ error_message: result.error_message });
                    setSubmitting(false);
                    return;
                }
                // Add discount to current offer data.
                props.addDiscount(result);
                setSubmitting(false);
                if (values.affiliate_id.length > 0) {
                    props.setToastVerbiage('Coupons created successfully');
                } else {
                    props.setToastVerbiage('Coupon created successfully');
                }
                // Reset values on successful discount creation.
                setValues(initalPriceRule(props));
                setErrors({});
                setTouched({});
            })
            .catch(() => {
                props.addDiscount({ error_message: 'Oops, something went wrong.' });
                setSubmitting(false);
            });
    }
};

const FormikCouponForm = withFormik(formikConfig)(CouponForm);

const formatRelativeDate = (date) => {
    const momentDate = moment(date);
    const dateDiff = momentDate.diff(moment().format('YYYY-MM-DD'), 'days');

    // use full date by default
    let formattedDate = momentDate.format('MMM D, YYYY');
    const formattedTime = momentDate.format('hA');

    // use relative if date is within a day
    if (dateDiff === 0) {
        formattedDate = 'today';
    } else if (dateDiff === 1) {
        formattedDate = 'tomorrow';
    } else if (dateDiff === -1) {
        formattedDate = 'yesterday';
    }

    return `${formattedDate} at ${formattedTime}`;
};

const formatValidityStatement = (active, item, expired, startDateFormatted, expirationDateFormatted) => {
    let validity = '';
    if (expired) {
        validity = `Expired ${expirationDateFormatted}`;
    } else {
        validity = 'Valid';
        if (active && item.ends_at) {
            validity += `, Expires: ${expirationDateFormatted}`;
        } else if (active && !item.ends_at) {
            validity += ', No expiration';
        } else if (!active && item.ends_at) {
            validity += ` between ${startDateFormatted} and ${expirationDateFormatted}`;
        } else if (!active && !item.ends_at) {
            validity += ` starting in ${startDateFormatted}`;
        }
    }

    return validity;
};

const OfferCoupons = (props) => {
    const { offer } = props;
    const [toastVerbiage, setToastVerbiage] = useState('');

    if (!offer || !offer.offer_id) {
        return <CenteredSpinner />;
    }

    const numCoupons = !offer.discounts ? 0 : offer.discounts.length;

    return (
        <>
            <div className="OfferCoupons__Wrapper">
                <div className="OfferCoupons__Data">
                    <div className="OfferCoupons__Heading" data-has-coupons={numCoupons > 0}>
                        <Heading>ACTIVE COUPON CODES</Heading>
                        {numCoupons > 0
                            && (
                                <TextStyle variation="subdued">
                                    <span className="subtitle">You have {numCoupons} coupon code{numCoupons !== 1 ? 's' : ''} assigned to this offer.</span>
                                </TextStyle>
                            )
                        }
                    </div>
                    {Array.isArray(offer.discounts) && numCoupons > 0
                        ? (
                            <table className="OfferCoupons__coupons-table">
                                <thead>
                                    <tr>
                                        <th className="code">Code</th>
                                        <th className="ambassador">Ambassador</th>
                                        <th className="details">Details</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {offer.discounts.map((item) => {
                                        const active = moment(item.starts_at).isSameOrBefore(moment());
                                        const expired = (item.ends_at) ? moment(item.ends_at).isSameOrBefore(moment()) : false;

                                        const startDateFormatted = formatRelativeDate(item.starts_at);
                                        const expirationDateFormatted = (item.ends_at) ? formatRelativeDate(item.ends_at) : '';

                                        // Format value for display.
                                        const value = item.value > 0 ? item.value : item.value * -1;
                                        let displayValue = item.value_type === valueTypes.PERCENTAGE
                                            ? formatPercent(value)
                                            : moneyFormat(value);

                                        displayValue = item.target_type === targetTypes.SHIPPING_LINE
                                            ? 'Free Shipping'
                                            : `${displayValue} off order total`;

                                        const includedProducts = (item.entitled_product_ids && item.entitled_product_ids.length > 0)
                                            ? item.entitled_product_ids.length
                                            : 'all';

                                        const validity = formatValidityStatement(active, item, expired, startDateFormatted, expirationDateFormatted);

                                        return (
                                            <tr key={item.discount_id} data-expired={expired}>
                                                <td className="code">
                                                    <p className="code">{item.code}</p>
                                                    <p className="validity">{validity}</p>
                                                </td>
                                                <td className="ambassador">{item.affiliate && item.affiliate.name}</td>
                                                <td className="details">
                                                    <p className="discount-value">{displayValue}</p>
                                                    <p className="products">Applies to {includedProducts} product{(includedProducts === 'all' || includedProducts !== 1) && 's'}</p>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        )
                        : <Banner status="info">You haven&rsquo;t added any coupon codes yet.</Banner>
                    }
                </div>
                <FormikCouponForm
                    {...props}
                    setToastVerbiage={setToastVerbiage}
                />
            </div>
            <LlamaToast toastVerbiage={toastVerbiage} setToastVerbiage={setToastVerbiage} />
        </>
    );
};

CouponForm.propTypes = {
    applications: PropTypes.arrayOf(PropTypes.shape({
        affiliate: PropTypes.shape({
            name: PropTypes.string.isRequired,
            tracking_id: PropTypes.string.isRequired
        }).isRequired
    })),
    handleSubmit: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    values: PropTypes.shape({
        affiliate_id: PropTypes.arrayOf(PropTypes.string).isRequired,
        value_type: PropTypes.string.isRequired,
        target_type: PropTypes.string,
        value: PropTypes.number.isRequired,
        ends_at: PropTypes.string,
        entitled_product_ids: PropTypes.arrayOf(PropTypes.string),
        code: PropTypes.string.isRequired
    }).isRequired,
    setFieldValue: PropTypes.func.isRequired,
    setValues: PropTypes.func.isRequired,
    errors: PropTypes.shape({
        affiliate_id: PropTypes.string,
        date: PropTypes.string,
        code: PropTypes.string,
        value: PropTypes.string,
        value_type: PropTypes.string
    }).isRequired,
    discountError: PropTypes.string,
    touched: PropTypes.shape({
        affiliate_id: PropTypes.bool,
        code: PropTypes.bool,
        value: PropTypes.bool,
        value_type: PropTypes.bool
    }).isRequired,
    setFieldTouched: PropTypes.func.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired,
    offer: PropTypes.shape({
        offer_type: PropTypes.string.isRequired,
        product_info: PropTypes.arrayOf(PropTypes.shape({
            product_id: PropTypes.string,
            image: PropTypes.shape({
                src: PropTypes.string.isRequired
            }).isRequired,
            title: PropTypes.string.isRequired,
            variants: PropTypes.arrayOf(PropTypes.shape({
                price: PropTypes.string.isRequired,
                inventory_quantity: PropTypes.number.isRequired
            })).isRequired
        }))
    }).isRequired,
    addDiscount: PropTypes.func.isRequired
};

CouponForm.defaultProps = {
    applications: [],
    discountError: ''
};

OfferCoupons.propTypes = {
    offer: PropTypes.shape({
        offer_id: PropTypes.string.isRequired,
        discounts: PropTypes.arrayOf(PropTypes.shape({
            starts_at: PropTypes.string.isRequired,
            ends_at: PropTypes.string,
            value: PropTypes.number.isRequired,
            value_type: PropTypes.string.isRequired,
            target_type: PropTypes.string.isRequired,
            entitled_product_ids: PropTypes.arrayOf(PropTypes.string),
            discount_id: PropTypes.string.isRequired,
            affiliate: PropTypes.shape({
                name: PropTypes.string.isRequired
            }).isRequired
        }))
    }).isRequired
};

export default OfferCoupons;
