import { GET_PRODUCTS_FULFILLED, UPDATE_PRODUCTS_FULFILLED } from '../actions/products';
const defaultState = [];

const productsReducer = (state = defaultState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GET_PRODUCTS_FULFILLED: {
            if (
                !Array.isArray(payload.data.advertisers)
                || !Array.isArray(payload.data.advertisers[0].products)
            ) {
                return state;
            }
            return { ...state, products: payload.data.advertisers[0].products };
        }

        case UPDATE_PRODUCTS_FULFILLED: {
            if (!Array.isArray(payload.data.updateAdvertiserProducts)) {
                return state;
            }
            return { ...state, products: payload.data.updateAdvertiserProducts };
        }
        default: {
            return state;
        }
    }
};

export default productsReducer;
