import React, {Component} from 'react';
import {connect} from 'react-redux';
import axios from 'axios';
import {withRouter} from "react-router";

import InitialPreloader from '../initial-preloader/initial-preloader';
import {createAuthToken} from '../../actions/payment-authToken';
import {updateAdvertiserPayments} from '../../actions/update-advertiser-payments';
import { PAYPAL_IDENTITY_URL } from '../../config'

/**
 * @class PaymentPaypal
 * It is the main handle file of paypal return URL
 * Once redirected from Paypal, It decodes the code from URL 
 * and process the auth token
 * If token generated successfully it updates the paypal payload into LLAMA .
 * and close the Paypal login window
 * @author Harshal Mahajan <Harshal.Mahajan@harbingergroup.com>
 * @returns {ClassComponent}
 */
const qs = require('querystring');

class PaymentPaypal extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (sessionStorage.getItem('username') && sessionStorage.getItem('store')) {
            this.paypalAuth();
        }
    }

    paypalAuth() {
        let queryString = qs.parse(this.props.location.search);
        let paypalPayload;
        
        if (queryString.code || queryString['?code']) {

            let code = "";
            if (queryString.code) {
                code = queryString.code
            } else {
                code = queryString['?code']
            }

            this.props.createAuthToken(code)
                .then((response) => {
                    paypalPayload = response;
                    return this.getAdvertiserPayPalEmail(response.value.tokeninfo.access_token);
                }).then((user) => {
                    this.updateAdvertiserPaypal(user, paypalPayload, code);
                })
                .catch(err => {
                    console.log("error from paypal", err);
                    // set the payment status flag - failed to show the error banner
                    localStorage.setItem(`payment-status-${this.props.authData.username}`, 'failed');
                    window.close();
                })
        } else {
            console.log("NO code parameter found in Query String")
        }
    }

    updateAdvertiserPaypal = (user, paypalPayload, code) => {
        if (user && paypalPayload.action.type === "PAYPAL_TOKEN_FULFILLED" && sessionStorage.getItem('username')) {

        let payload = {
            paypal: {
                    urlCode: code,
                    refresh: paypalPayload.value.tokeninfo.refresh_token,
                    access_token: paypalPayload.value.tokeninfo.access_token,
                    id_token: paypalPayload.value.tokeninfo.id_token,
                    user: user,
                    logout: paypalPayload.value.logout
            }
        };
            this.props.updateAdvertiserPayments(payload, sessionStorage.getItem('username'))
            .then((res) => {
                if (res.action.type === "UPDATE_ADVERTISER_FULFILLED") {
                    window.close();
                }
            })
        }
    }

    getAdvertiserPayPalEmail = async (access_token) => {
        return await axios({
            url: PAYPAL_IDENTITY_URL,
            headers: { 'Authorization': 'Bearer ' + access_token, 'Content-Type': 'application/json' }
        }).then((resp) => {
            return resp.data.email ? resp.data.email : null;
        }).catch((error) => {
            console.log('Error: ', error);
            return null;
        })
    }

    render() {
        return <InitialPreloader />;
    }
}

const mapStateToProps = (state) => {
    return {
        advertiser: state.advertiser,
        ...state
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateAdvertiserPayments: (payload, advertiser) => dispatch(updateAdvertiserPayments(payload, advertiser)),
        createAuthToken: (code) => dispatch(createAuthToken(code))
    }
}

export default withRouter(connect((mapStateToProps), mapDispatchToProps)(PaymentPaypal));