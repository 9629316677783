import React from 'react';
import {moneyFormat} from './number-format';
import { RateTypes } from '../utils/types';

/**
 * Displays the summary of the rate for a specific offer.
 * 
 * @param {Object} offer 
 */
const displayCampaignRate = (offer) => {
    let { type, amount } = offer.rate;

    switch (type) {
        case RateTypes.FLAT_ITEM:
            return (<React.Fragment>{moneyFormat(amount, true)} per <strong>item</strong> on initial purchase</React.Fragment>);
        case RateTypes.FLAT_ORDER:
            return (<React.Fragment>{moneyFormat(amount, true)} per <strong>order</strong> on initial purchase</React.Fragment>);
        case RateTypes.PERCENT_REVENUE:
        default:
            return `${amount}% of order total on initial purchase`;
    }
}

export default displayCampaignRate;