import React from 'react';
import Tour from 'reactour';

import './LlamaTourNavigation.css';

import { LlamaButton } from 'llama-library/components';

export class LlamaTourNavigation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
        // this.state = {
        //     isTourOpen: this.props.isTourOpen
        // }
    }

    render() {

        // const { history } = this.props;

        const accentColor = "#5D447C";

        const tourConfig = [
            {
                selector: '[data-tour="step-begin"]',
                content: ({ goTo }) => {
                    // Hide nav buttons and badge
                    const backArrow = document.querySelector('[data-tour-elem="left-arrow"]');
                    if(backArrow) backArrow.style.display = "none";
                    const nextArrow = document.querySelector('[data-tour-elem="right-arrow"]');
                    if(nextArrow) nextArrow.style.display = "none";
                    const badgeElem = document.querySelector('[data-tour-elem="badge"]');
                    if(badgeElem) badgeElem.style.display = "none";

                    return (
                        <div className="layout__tour-start">
                            <div className="layout__tour-header"></div>
                            <div className="layout__tour-start-content">
                                <h3>Hi, I’m Leo. Welcome to Llama.</h3>
                                <p>Getting started on a new platform can be a little overwhelming. Let me show you around.</p>
                                <div className="layout__tour-button-wrapper">
                                    <LlamaButton onClick={() => goTo(1)} style={{ width: '100%', fontWeight: 'bold' }}>Start Tour</LlamaButton>
                                </div>
                            </div>
                        </div>
                    )
                },
                position: "center"
            },
            {
                selector: '[data-tour="step-dashboard"]',
                content: () => {
                    // Show nav buttons, add routing functionality to buttons
                    const nextArrow = document.querySelector('[data-tour-elem="right-arrow"]');
                    if(nextArrow) {
                        nextArrow.style.display = "block";
                        nextArrow.onclick = () => this.props.history.push('/products');
                    }
                    const backArrow = document.querySelector('[data-tour-elem="left-arrow"]');
                    if(backArrow) {
                        backArrow.style.display = "block";
                    }

                    // Show badge element
                    const badgeElem = document.querySelector('[data-tour-elem="badge"]');
                    if(badgeElem) badgeElem.style.display = "block";

                    return (
                    <div className="layout__tour-step">
                        <div className="layout__tour-progress">
                            <div style={{ width: '4%' }} className="layout__tour-progress layout__progress-color"></div>
                        </div>
                        <h3>Check your stats</h3>
                        <p>Gauge your program's daily performance with a quick overview of your:</p>
                        <div className="layout__tour-list">
                            <div className="layout__tour-item-rev">
                                <p>Daily revenue for the past 30 days</p>
                            </div>
                            <div className="layout__tour-item-prod">
                                {/* <img className="layout__tour-list-img" /> */}
                                <p>Top 10 best selling products</p>
                            </div>
                            <div className="layout__tour-item-amb">
                                {/* <img className="layout__tour-list-img" /> */}
                                <p>Top 10 ambassadors</p>
                            </div>
                        </div>
                    </div>
        
                    )
                },
                style: {
                    maxWidth: "360px"
                }
            },
            {
                selector: '[data-tour="step-products"]',
                content: () => {
                    const backArrow = document.querySelector('[data-tour-elem="left-arrow"]');
                    if (backArrow) backArrow.onclick = () => this.props.history.push('/');
                    const nextArrow = document.querySelector('[data-tour-elem="right-arrow"]');
                    if (nextArrow) nextArrow.onclick = () => this.props.history.push('/offers');

                    return (
                        <div className="layout__tour-step">
                            <div className="layout__tour-progress">
                                <div style={{ width: '18%' }} className="layout__tour-progress layout__progress-color"></div>
                            </div>
                            <h3>Hide your private products</h3>
                            <p>You control which products your ambassadors promote. If you need to keep specific products private, deactivate them and they won’t appear in any of your offers.</p>
                        </div>
                    )
                },
                style: {
                    maxWidth: "360px"
                }
            },
            {
                selector: '[data-tour="step-tour-offers"]',
                content: () => {
                    const backArrow = document.querySelector('[data-tour-elem="left-arrow"]');
                    if (backArrow) backArrow.onclick = () => this.props.history.push('/products');
                    const nextArrow = document.querySelector('[data-tour-elem="right-arrow"]');
                    if (nextArrow) nextArrow.onclick = () => this.props.history.push('/invoices');

                    return (
                        <div className="layout__tour-step">
                            <div className="layout__tour-progress">
                                <div style={{ width: '27%' }} className="layout__tour-progress layout__progress-color"></div>
                            </div>
                            <h3>Create offers for your program</h3>
                            <p>Ambassadors find and promote your program through the offers you create. Target a specific audience, choose your products, set commissions, add coupons, and more.</p>
                            <div className="layout__tour-spacer">
                                <p><b>Important Note:</b></p>
                                <p>Once created, <b>you will not be able to delete an offer</b>, as it acts as a contract between you and your ambassadors.</p>
                            </div>
                        </div>
                    )
                },
                style: {
                    maxWidth: "360px"
                }
            },
            {
                selector: '[data-tour="step-tour-invoices"]',
                content: () => {
                    const backArrow = document.querySelector('[data-tour-elem="left-arrow"]');
                    if (backArrow) backArrow.onclick = () => this.props.history.push('/offers');
                    const nextArrow = document.querySelector('[data-tour-elem="right-arrow"]');
                    if (nextArrow) nextArrow.onclick = () => this.props.history.push('/performance');

                    return (
                        <div className="layout__tour-step">
                            <div className="layout__tour-progress">
                                <div style={{ width: '36%' }} className="layout__tour-progress layout__progress-color"></div>
                            </div>
                            <h3>Track your ambassador payouts</h3>
                            <p>When ambassadors earn commissions from your offers, we’ll create invoices in your PayPal account and display them here. You’ll still have to pay directly through PayPal, but this is an easy way to track who’s been paid and who hasn’t.</p>
                        </div>
                    )
                },
                style: {
                    maxWidth: "360px"
                }
            },
            {
                selector: '[data-tour="step-tour-performance"]',
                content: () => {
                    const backArrow = document.querySelector('[data-tour-elem="left-arrow"]');
                    if (backArrow) backArrow.onclick = () => this.props.history.push('/invoices');
                    const nextArrow = document.querySelector('[data-tour-elem="right-arrow"]');
                    if (nextArrow) nextArrow.onclick = () => this.props.history.push('/applications');

                    return (
                        <div className="layout__tour-step">
                            <div className="layout__tour-progress">
                                <div style={{ width: '45%' }} className="layout__tour-progress layout__progress-color"></div>
                            </div>
                            <h3>Visualize your ambassadors’ performance</h3>
                            <p>See how well each ambassador is promoting your offers. Rank them by revenue, conversions, EPC, or clicks and filter by specific dates and offers.</p>
                        </div>
                    )
                },
                style: {
                    maxWidth: "360px"
                }
            },
            {
                selector: '[data-tour="step-tour-applications"]',
                content: () => {
                    const backArrow = document.querySelector('[data-tour-elem="left-arrow"]');
                    if (backArrow) backArrow.onclick = () => this.props.history.push('/performance');
                    const nextArrow = document.querySelector('[data-tour-elem="right-arrow"]');
                    if (nextArrow) nextArrow.onclick = () => this.props.history.push('/discover');

                    return (
                        <div className="layout__tour-step">
                            <div className="layout__tour-progress">
                                <div style={{ width: '54%' }} className="layout__tour-progress layout__progress-color"></div>
                            </div>
                            <h3>Manage your ambassadors</h3>
                            <p>Ambassadors who are interested in running your offers are displayed here. We’ll show you their stats to help you quickly decide who to approve, shortlist, or deny.</p>
                        </div>
                    )
                },
                style: {
                    maxWidth: "360px"
                }
            },
            {
                selector: '[data-tour="step-tour-discover"]',
                content: () => {
                    const backArrow = document.querySelector('[data-tour-elem="left-arrow"]');
                    if (backArrow) backArrow.onclick = () => this.props.history.push('/applications');
                    const nextArrow = document.querySelector('[data-tour-elem="right-arrow"]');
                    if (nextArrow) nextArrow.onclick = () => this.props.history.push('/import-ambassadors');

                    return (
                        <div className="layout__tour-step">
                            <div className="layout__tour-progress">
                                <div style={{ width: '63%' }} className="layout__tour-progress layout__progress-color"></div>
                            </div>
                            <h3>Find new ambassadors</h3>
                            <p>Search our extensive network of ambassadors to find the ones most relevant to your brand. Filter by category, social media presence, and audience demographics.</p>
                        </div>
                    )
                },
                style: {
                    maxWidth: "360px"
                }
            },
            {
                selector: '[data-tour="step-tour-import"]',
                content: () => {
                    const backArrow = document.querySelector('[data-tour-elem="left-arrow"]');
                    if (backArrow) backArrow.onclick = () => this.props.history.push('/discover');
                    const nextArrow = document.querySelector('[data-tour-elem="right-arrow"]');
                    if (nextArrow) nextArrow.onclick = () => this.props.history.push('/invite');

                    return (
                        <div className="layout__tour-step">
                            <div className="layout__tour-progress">
                                <div style={{ width: '72%' }} className="layout__tour-progress layout__progress-color"></div>
                            </div>
                            <h3>Bring your current ambassadors to Llama</h3>
                            <p>Switching platforms? Use our migration tool to import your ambassadors from Refersion, LeadDyno, Affiliatly, or anywhere. We'll send them an email to join your ambassador program and auto-approve them to run all your offers.</p>
                        </div>
                    )
                },
                style: {
                    maxWidth: "360px"
                }
            },
            {
                selector: '[data-tour="step-tour-invite"]',
                content: () => {
                    const backArrow = document.querySelector('[data-tour-elem="left-arrow"]');
                    if (backArrow) backArrow.onclick = () => this.props.history.push('/import-ambassadors');
                    const nextArrow = document.querySelector('[data-tour-elem="right-arrow"]');
                    if (nextArrow) nextArrow.onclick = () => this.props.history.push('/settings');

                    return (
                        <div className="layout__tour-step">
                            <div className="layout__tour-progress">
                                <div style={{ width: '81%' }} className="layout__tour-progress layout__progress-color"></div>
                            </div>
                            <h3>Share your ambassador program anywhere</h3>
                            <p>Promote your program outside of Llama. Choose your featured offer, upload a banner, and we’ll give you a dedicated sign up page. Any ambassadors who join are auto-approved to run your featured offer.</p>
                        </div>
                    )
                },
                style: {
                    maxWidth: "360px"
                }
            },
            {
                selector: '[data-tour="step-tour-settings"]',
                content: () => {
                    const backArrow = document.querySelector('[data-tour-elem="left-arrow"]');
                    if (backArrow) backArrow.onclick = () => this.props.history.push('/invite');

                    return (
                        <div className="layout__tour-step">
                            <div className="layout__tour-progress">
                                <div style={{ width: '90%' }} className="layout__tour-progress layout__progress-color"></div>
                            </div>
                            <h3>Manage your account</h3>
                            <p>Upload your brand’s logo, connect your PayPal account, set commission earning timeframes, add your program’s terms and conditions, and more.</p>
                        </div>
                    )
                },
                style: {
                    maxWidth: "360px"
                }
            },
            {
                selector: '[data-tour="step-tour-help"]',
                content: () => {
                    const backArrow = document.querySelector('[data-tour-elem="left-arrow"]');
                    if(backArrow) backArrow.style.display = "none";
                    // const nextArrow = document.querySelector('[data-tour-elem="right-arrow"');
                    // if(nextArrow) nextArrow.onclick = () => this.props.history.push('/offers');

                    return (
                        <div className="layout__tour-step">
                            <div className="layout__tour-progress">
                                <div style={{ width: '100%' }} className="layout__tour-progress layout__progress-color"></div>
                            </div>
                            <h3>Get additional help</h3>
                            <p>Still stumped? Start an online chat with our expert support team and we’ll do our best to help you answer any questions you may have.</p>
                        </div>
                    )
                },
                style: {
                    maxWidth: "360px"
                }
            },
        ];

        return (
            <div>
                <Tour 
                    steps={tourConfig}
                    isOpen={this.props.isTourOpen}
                    onRequestClose={this.props.closeTour}
                    rounded={8}
                    accentColor={accentColor}
                    badgeContent={(curr, tot) => `${curr - 1} of ${tot - 1}`}
                    // showButtons={false}
                    prevButton={<LlamaButton inverted background={"#FD2856"}>Previous</LlamaButton>}
                    nextButton={<LlamaButton>Next</LlamaButton>}
                    showNavigation={false}
                    showNavigationNumber={false}
                    lastStepNextButton={<LlamaButton>End Tour</LlamaButton>}
                />
            </div>
        )
    }
}

export default LlamaTourNavigation;