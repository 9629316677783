import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { Page, Frame, Toast, Spinner } from '@shopify/polaris';
import { DuplicateMinor } from '@shopify/polaris-icons';
import { EmptyState } from 'llama-library/components';

import { invitePageOffers, updateBrandedSignup, checkBrandedSlug } from '../actions/branded-sign-up';
import getPromotions from '../actions/getPromotions';
import InviteSetup from '../components/invite-setup/invite-setup';

import { INVITE_PAGE_URL } from '../config';

const InviteSignupContainer = (props) => {
    // Get advertiser_id.
    const { 'custom:advertiser_id': advertiser_id } = props.authData && props.authData.attributes;

    const [toastVerbiage, setToastVerbiage] = useState(null);
    const [copyIsLoading, setCopyIsLoading] = useState(false);
    const [offersLoading, setOffersLoading] = useState(true);
    const [promoQuery, setPromoQuery] = useState('');

    useEffect(() => {
        if (!advertiser_id) {
            return;
        }
        props.invitePageOffers(advertiser_id)
            .then(() => {
                setOffersLoading(false);
            });
    }, [advertiser_id]);

    if (!props.authData || !props.authData.attributes) return null;
    if (!advertiser_id) return null;

    const { offers, advertiser = {}, updateBrandedSignupData, checkBrandedSlugValue, promotionsData } = props;
    const { name, branded_sign_up, terms, domain, timeline_settings, avatar_image } = advertiser;
    const advertiserData = {
        name,
        terms,
        domain,
        timeline_settings,
        avatar_image
    };

    const linkAvailable = branded_sign_up && branded_sign_up.slug;
    const copyLink = () => {
        if (linkAvailable) {
            let copyToClipboard;
            setCopyIsLoading(true);
            const link = `${INVITE_PAGE_URL}${branded_sign_up.slug}${promoQuery}`;

            if (navigator.clipboard) {
                copyToClipboard = navigator.clipboard.writeText(link);
            } else {
                // For versions of Safari older than 13
                const textArea = document.createElement('textarea');
                textArea.value = link;
                document.body.appendChild(textArea);
                textArea.focus();
                textArea.select();

                copyToClipboard = new Promise((resolve, reject) => {
                    const copySuccess = document.execCommand('copy');
                    if (copySuccess) {
                        resolve('Resolved');
                    } else {
                        reject('Unable to execute copy command');
                    }
                });

                document.body.removeChild(textArea);
            }

            copyToClipboard
                .then(() => {
                    setCopyIsLoading(false);
                    setToastVerbiage('Copied to clipboard');
                })
                .catch((err) => {
                    console.log('Error copying link:', err);
                    setCopyIsLoading(false);
                    setToastVerbiage('Oops, something went wrong. Unable to copy link.');
                });
        }
    };

    const toastMarkup = toastVerbiage
        ? <Toast content={toastVerbiage} onDismiss={() => { return setToastVerbiage(null); }} />
        : null;

    const renderPageContent = () => {
        if (offers && offers.length > 0) {
            return (
                <InviteSetup
                    history={props.history}
                    advertiser_id={advertiser_id}
                    advertiserData={advertiserData}
                    branded_sign_up={branded_sign_up}
                    promotionsData={promotionsData}
                    offers={offers}
                    updateBrandedSignup={updateBrandedSignupData}
                    checkBrandedSlug={checkBrandedSlugValue}
                    invitePageUrl={INVITE_PAGE_URL}
                    setToastVerbiage={setToastVerbiage}
                    promoQuery={promoQuery}
                    setPromoQuery={setPromoQuery}
                />
            );
        }

        return (
            <EmptyState
                message="You don’t have any active offers to feature here"
                paragraph="To customize your invite page, you need to have at least one active offer"
                ctaText="Create an Offer"
                ctaAction={() => { return props.history.push('/offer-new'); }}
            />
        );
    };

    return (
        <div style={{ maxWidth: '1440px', margin: 'auto' }}>
            <Page
                title="Ambassador Invite Page Settings"
                separator
                primaryAction={{
                    content: 'Copy & Share Link',
                    onAction: copyLink,
                    disabled: !linkAvailable,
                    loading: copyIsLoading,
                    icon: DuplicateMinor
                }}
            >
                {offersLoading // TODO: change spinner to skeleton component
                    ? <Spinner size="large" />
                    : renderPageContent()
                }
                <Frame>{toastMarkup}</Frame>
            </Page>
        </div>
    );
};

InviteSignupContainer.propTypes = {
    authData: PropTypes.shape({
        attributes: PropTypes.shape({
            'custom:advertiser_id': PropTypes.string
        })
    }).isRequired,
    invitePageOffers: PropTypes.func.isRequired,
    offers: PropTypes.arrayOf(PropTypes.shape({
        offer_id: PropTypes.string,
        name: PropTypes.string
    })).isRequired,
    advertiser: PropTypes.shape({
        name: PropTypes.string,
        branded_sign_up: PropTypes.shape({
            slug: PropTypes.string,
            body: PropTypes.string,
            offer_id: PropTypes.string,
            display_offer_data: PropTypes.bool,
            banner_image: PropTypes.string
        }),
        terms: PropTypes.string,
        domain: PropTypes.string,
        timeline_settings: PropTypes.shape({
            lookback: PropTypes.shape({
                time: PropTypes.string,
                edited: PropTypes.string
            }),
            lookforward: PropTypes.shape({
                time: PropTypes.string,
                edited: PropTypes.string
            })
        }),
        avatar_image: PropTypes.string
    }).isRequired,
    updateBrandedSignupData: PropTypes.func.isRequired,
    checkBrandedSlugValue: PropTypes.func.isRequired,
    history: PropTypes.shape({ push: PropTypes.func }).isRequired,
    getPromotionsData: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
    return {
        authData: state.authData,
        advertiser: state.advertiser || {},
        offers: state.offers,
        promotionsData: state.promotionsData.promotionsData.promotions
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        invitePageOffers: (advertiser_id) => { return dispatch(invitePageOffers(advertiser_id)); },
        updateBrandedSignupData: (advertiser_id, data) => { return dispatch(updateBrandedSignup(advertiser_id, data)); },
        checkBrandedSlugValue: (advertiser_id, slug) => { return dispatch(checkBrandedSlug(advertiser_id, slug)); },
        getPromotionsData: () => { return dispatch(getPromotions()); }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(InviteSignupContainer);
