import React, { useState } from 'react';
import { Spinner, TextField, Icon, ChoiceList } from '@shopify/polaris';
import { CancelSmallMinor, SearchMinor } from '@shopify/polaris-icons';

import { LlamaButton, EmptyState } from 'llama-library/components';

import { applyToAffiliate } from '../../actions/apply-to-affiliate';
import { generateTrackingLink } from '../../actions/generate-link';
import { addToAutoSmartLinks } from '../../actions/add-to-auto-smartlinks';

import formatMetrics from '../../utils/format-metrics';
import { formatAudience } from '../../utils/format-audience';
import formatCountries from '../../utils/format-countries';
import {moneyFormat} from '../../utils/number-format';
import formatPercent from '../../utils/format-percent';

import llamaPlaceholder from '../../assets/llama-user-icon.png';

/**
 * @function NoOffersNotification
 * Accepts props Object, returns NoOffersNotification Component
 * Displays a 'no active offers' message. Provides link to offers.
 * 
 * @param {Object} props
 * 
 * @returns {FunctionComponent} 
 */
const NoOffersNotification = (props) => {

    return (
        <div className="AffiliateInvite__CardSection">
            <div className="AffiliateInvite__CardTop">
                <h1>Choose Offer(s)</h1>
            </div>
            <EmptyState
                message="You don't have any active offers"
                paragraph="Before you can invite ambassadors you need to have at least one active offer"
                ctaText="Create an Offer"
                ctaAction={props.navigateToNewOffer}
            />
        </div>
    )

};

/**
 * @function InviteOffers
 * Accepts the following params, returns InviteOffers Component.
 * Displays a search bar for the user to search for offers
 * and allows the user to select which offers they wish to send
 * in the invitation. 
 * 
 * @param {Object} affiliate 
 * @param {Array} offers
 * @param {Array} selectedOffers - default param is an empty array
 * @param {Function} handleOfferSelection
 * 
 * @returns {FunctionComponent}
 */
const InviteOffers = ({ affiliate, offers, selectedOffers = [], handleOfferSelection, invitedOffers }) => {

    const [searchValue, setSearchValue] = useState('');

    if (!affiliate) return null;

    if (!offers) {
        return (<Spinner size="small" color="teal" />);
    }

    const offerList = offers
        .map((offer) => {
            return {
                label: offer.name,
                value: offer.offer_id,
                term: offer.name,
                disabled: Array.isArray(affiliate.invitedOffers) && affiliate.invitedOffers.length > 0 && affiliate.invitedOffers.includes(offer.offer_id) ? true : false
            }
        })
        .filter((offer) => {
            return offer.term.toLowerCase().includes(searchValue.toLowerCase());
        });

    return (
        <div className="AffiliateInvite__CardSection" data-test="component-inviteOffers">
            <div className="AffiliateInvite__CardTop">
                <h1>Choose Offer(s)</h1>
            </div>
            <div className="AffiliateInvite__OfferCard">
                <div className="AffiliateInvite__OfferSearch">
                    <TextField
                        type="search"
                        placeholder="Search"
                        prefix={<Icon color="skyDark" source={SearchMinor} />}
                        value={searchValue}
                        onChange={(value) => setSearchValue(value)}
                    />
                </div>

                {offerList.length > 0
                    ? <div className="AffiliateInvite__OfferChoices">
                        <ChoiceList
                            allowMultiple
                            choices={offerList}
                            selected={selectedOffers}
                            onChange={handleOfferSelection}
                        />
                    </div>
                    : <div>No offers match: {searchValue}</div>
                }
            </div>
        </div>
    )

};

/**
 * @function InviteSideDrawer
 * Accepts the following params, returns InviteSideDrawer Component.
 * Displays a card quickview of the affiliate to invite and the
 * offers that the merchant chose in InviteOffers Component.
 * 
 * @param {Boolean} showSideDrawer
 * @param {Object} affiliate 
 * @param {Function} setSideDrawerAffiliate 
 * @param {Array} offers 
 * @param {Integer} adverter_id 
 * @param {Object} history
 * @param {Function} setToastVerbiage
 * @param {Function} setIsInvitationSent
 * @param {Function} setInvitedOffers
 * 
 * @returns {FunctionComponent}
 */
const InviteSideDrawer = props => {
    let { showSideDrawer, affiliate, setSideDrawerAffiliate, offers, advertiser_id, history, setToastVerbiage, setIsInvitationSent, setInvitedOffers } = props;
    let attachedClasses = ["AffiliateInvite__SideDrawer", "AffiliateInvite__CloseMenu"];
    let backgroundClasses = ["AffiliateInvite__TransparentBg"];

    if (showSideDrawer) {
        attachedClasses = ["AffiliateInvite__SideDrawer", "AffiliateInvite__OpenMenu"];
        backgroundClasses = ["AffiliateInvite__TransparentBg", "AffiliateInvite__ShowBg"];
    }

    const [isLoading, setIsLoading] = useState(false);
    const [selectedOffers, setSelectedOffers] = useState([]);
    const [error, setError] = useState(null);

    const handleOfferSelection = (value) => {
        if (error !== null) {
            setError(null);
        }
        setSelectedOffers(value);
    };

    const navigateToNewOffer = () => history.push('/offer-new');

    const sideDrawerClosedHandler = () => setSideDrawerAffiliate(null);

    const submitAffiliateInvites = async() => {

        if (selectedOffers.length === 0) {
            setError('Please select an offer.')
            return null;
        }
        setIsLoading(true);

        //create the tracking link only for first offer and for rest just replace the offer_id 
        let trackingLinkForFirstOffer = await generateTrackingLink(selectedOffers[0], affiliate.tracking_id).then(result => result);

        const offerTrackingLinks = selectedOffers.map((offer_id) => {
            let clickURL = trackingLinkForFirstOffer.generateTrackingLink.click_url;
            clickURL = clickURL.replace(selectedOffers[0], offer_id);
            return clickURL;
        })
    
        // add name and category to selected offers
        const selectedOffersObj = offers.reduce((acc, offer) => {
            if (selectedOffers.indexOf(offer.offer_id) !== -1) {
                acc.push({
                    offer_id: offer.offer_id,
                    name: offer.name,
                    category: offer.category,
                    company: offer.company
                });
            }
            return acc;
        }, []);
        
        return applyToAffiliate(affiliate.cognito_id, advertiser_id, selectedOffers, offerTrackingLinks)
            .then((result) => {
                setIsLoading(false);

                if (!Array.isArray(result) || result.length === 0 || result[0] === null) {
                    setError("Uh oh, something went wrong.  Unable to send invitation.")
                    throw Error('Could not send invitation');
                }

                const offers = result.filter((item) => item.advertiser_status === 'APPROVED');
                if(setInvitedOffers) {
                    setInvitedOffers(selectedOffers);
                }
                sideDrawerClosedHandler();
                if(setIsInvitationSent) {
                    setIsInvitationSent(true);
                }
                
                setSelectedOffers([]);
                setToastVerbiage(`${affiliate.name} invited to ${offers.length} offer${offers.length > 1 ? 's' : ''}`)
            })
            .then(() => {
                return addToAutoSmartLinks(affiliate.cognito_id, selectedOffersObj, offerTrackingLinks)
                    .then(result => result)
            })
            .catch(error => console.log(error));
    };

    /**
     * @function renderAffiliateInviteCard
     * Accepts affiliate object, returns renderAffiliateInviteCard.
     * Displays photo, reach, audience country audience age/gender
     * and average conversion rate of the affiliate.
     * 
     * @param {Object} affiliate 
     * 
     * @returns {FunctionComponent}
     */
    const renderAffiliateInviteCard = (affiliate) => {

        if (!affiliate) {
            return null;
        }

        const { account, tracking } = affiliate;

        const clicks = tracking && tracking.Stat ? formatMetrics(tracking.Stat.clicks) : 0;
        const epc = tracking && tracking.Stat ? moneyFormat(tracking.Stat.epc, true) : '$0';
        const conversions = tracking && tracking.Stat && tracking.Stat.conversions
            ? tracking.Stat.conversions
            : 0;
        const conversionPercent = formatPercent(conversions / clicks, true);

        let photo = (affiliate.photo) ? affiliate.photo : llamaPlaceholder;

        return (

            <div className="AffiliateInvite__Metric-Card" data-test="component-affiliateInviteCard">
                <div className="AffiliateInvite__AffiliateImg">
                    <img src={photo} alt="Ambassador" className={!affiliate.photo ? 'placeholder' : ''} />
                </div>

                <div className="AffiliateInvite__Metric-Info">
                    <h2 className="AffiliateInvite__AffiliateName"> {affiliate.name} </h2>
                    <dl>
                        <dt>Reach</dt>
                        <dd>{formatMetrics(affiliate.reach)}</dd>
                        <dt>Avg. Conversion Rate</dt>
                        <dd>{conversionPercent}</dd>
                        <dt>Audience</dt>
                        <dd>{formatCountries(account)}, {formatAudience(account)}</dd>
                    </dl>
                </div>

                {/* <div className="AffiliateInvite__AffiliateImg">
                    <img src={photo} alt="Ambassador" className={!affiliate.photo ? 'placeholder' : ''} />
                </div>

                <div className="AffiliateInvite__Metric-Info">
                    <h1 className="AffiliateInvite__AffiliateName"> {affiliate.name} </h1>
                    <div className="AffiliateInvite__MetricRow">
                        <div className="AffiliateInvite__MetricItem">
                            <span className="AffiliateInvite__MetricMetric"> {formatMetrics(affiliate.reach)} </span>
                            <span className="AffiliateInvite__MetricLabel">Reach</span>
                        </div>
                        <div className="AffiliateInvite__MetricItem" >
                            <span className="AffiliateInvite__MetricMetric">{formatCountries(account)}</span>
                            <span className="AffiliateInvite__MetricLabel">Audience Country</span>
                        </div>
                    </div>
                    <div className="AffiliateInvite__MetricRow" >
                        <div className="AffiliateInvite__MetricItem" >
                            <span className="AffiliateInvite__MetricMetric">{formatAudience(account)}</span>
                            <span className="AffiliateInvite__MetricLabel">Audience</span>
                        </div>
                        <div className="AffiliateInvite__MetricItem" >
                            <span className="AffiliateInvite__MetricMetric">{conversionPercent}</span>
                            <span className="AffiliateInvite__MetricLabel">Avg. Conversion Rate</span>
                        </div>
                    </div>
                </div> */}

            </div>

        )

    };

    return (
        <div data-test="component-inviteSideDrawer">
            <div className={backgroundClasses.join(" ")} onClick={sideDrawerClosedHandler}>
            </div>
            <div className={attachedClasses.join(" ")}>
                <div className="AffiliateInvite__LayoutTop">
                    <span className="AffiliateInvite__CloseBtn" data-test="button-closeSideDrawer" onClick={sideDrawerClosedHandler}>
                        <Icon source={CancelSmallMinor} />
                    </span>
                    <h1>Invite Ambassador</h1>
                </div>

                <div className="AffiliateInvite__Layout">
                    {renderAffiliateInviteCard(affiliate)}
                </div>

                {Array.isArray(offers) && offers.length > 0
                    ? <InviteOffers
                        affiliate={affiliate}
                        offers={offers}
                        selectedOffers={selectedOffers}
                        handleOfferSelection={handleOfferSelection}
                    />
                    : <NoOffersNotification navigateToNewOffer={navigateToNewOffer} />
                }

                {error && <div className="AffiliateInvite__ErrorCard">Error: {error}</div>}

                {Array.isArray(offers) && offers.length > 0
                    && <div className="AffiliateInvite__ButtonGroup">
                        <LlamaButton
                            secondary
                            // classes={['invite_offer_button-secondary']}
                            onClick={navigateToNewOffer}
                            data-test="button-newOffers"
                        >Create New Offer</LlamaButton>
                        <LlamaButton
                            onClick={submitAffiliateInvites}
                            loading={isLoading}
                            data-test="button-submitInvites"
                        // classes={['invite_offer_button-primary']}
                        >Send Invitation</LlamaButton>
                    </div>
                }
            </div>
        </div>
    )

};

export default InviteSideDrawer;