import React, {useState} from 'react';
import {Spinner, TextStyle, ResourceList, Card, Pagination, Avatar, Toast, Frame, Page, Tooltip} from '@shopify/polaris';
import { LlamaButton, LlamaPagination } from 'llama-library/components';

import InviteSideDrawer from '../discover/side-drawer';
import './import-affiliates.css';

/**
 * @function ImportedAffiliatesList
 * Accepts the following params, returns ImportedAffiliatesList Component.
 * Displays a ResourceList of imported affiliates, gives user ability to 
 * view the affiliate and send an invitation.
 * 
 * @param {Array} importedAffiliates 
 * 
 * @returns {FunctionComponent}
 */
const ImportedAffiliatesList = ({importedAffiliates, loading, pagination, ...props}) => {
    const {
        hasNext,
        hasPrevious,
        nextPage,
        previousPage,
        currentPage,
        totalPages
    } = pagination;

    const [sideDrawerAffiliate, setSideDrawerAffiliate] = useState(null);
    const [toastVerbiage, setToastVerbiage] = useState(null);

    const viewAffiliate = (id) => {
        props.history.push(`/affiliate/${id}`)
    };

    /**
     * @function renderImportedAffiliates
     * Accepts an array of importedAffiliatesData, returns
     * renderImportedAffiliates Component.
     * Displays Polaris Resource List of each affiliate including
     * their name, a photo, and a view and invite affiliate
     * buttons.
     * 
     * @param {Array} importedAffiliatesData 
     * 
     * @returns {FunctionComponent}
     */
    let renderImportedAffiliates = (importedAffiliatesData) => {

        return (
            <ResourceList.Item key={importedAffiliatesData._id}>
                <div className="import__item">
                    <div className="import__info">
                        <div className="import__info_photo">
                            <Avatar customer name={importedAffiliatesData.name} source={importedAffiliatesData.photo} />
                        </div>
                        <TextStyle variation="subdued">{importedAffiliatesData.name}</TextStyle>
                    </div>
                    {(!(importedAffiliatesData.errors && importedAffiliatesData.errors.length > 0))
                        ? (
                    <div className="import__invite_button">
                        <LlamaButton secondary onClick={() => viewAffiliate(importedAffiliatesData._id)}>More Info</LlamaButton>
                    </div>
                        )
                        :
                        (
                            <div className="import__invite_button" style={{ fontSize :'1.4rem' , fontWeight: 'bold', justifyContent: 'space-between', cursor: 'default'}}>
                                <Tooltip content="Error while importing data" active={false} preferredPosition="mostSpace">
                                    <TextStyle variation="negative">Import Failed</TextStyle>
                                </Tooltip>
                </div>
                        )
                    }
                </div>

            </ResourceList.Item>
        )
    };

    const toastMarkup = toastVerbiage ? <Toast content={toastVerbiage} onDismiss={() => setToastVerbiage(null)}/> : null;

    return (

        <div className="imported-affiliates-list">
            <InviteSideDrawer
                advertiser_id={props.advertiser_id}
                showSideDrawer={sideDrawerAffiliate !== null}
                affiliate={sideDrawerAffiliate}
                offers={props.advertiser.offers}
                setSideDrawerAffiliate={setSideDrawerAffiliate}
                setToastVerbiage={setToastVerbiage}
                history={props.history}
            />
            <div className="import__item import__header">
                <div className="import__info">Imported Ambassadors</div>
            </div>

            {loading
                ? <div className="import__spinner-wrapper"><Spinner/></div>
                : (!importedAffiliates || importedAffiliates.length === 0 || !Array.isArray(importedAffiliates)) ? 
                <div className="import__empty-set">
                    <TextStyle variation="subdued">You haven&rsquo;t imported any ambassadors recently</TextStyle>
                </div> : (
                    <React.Fragment>
                        <ResourceList
                            resourceName={{singular: 'Affiliate', plural: 'Affiliates'}}
                            items={importedAffiliates}
                            renderItem={renderImportedAffiliates}
                        />
                        {totalPages > 1 &&
                        <div className="import__pagination">
                            <LlamaPagination
                                hasNext={hasNext}
                                hasPrevious={hasPrevious}
                                onNext={nextPage}
                                onPrevious={previousPage}
                                maxPages={totalPages - 1}
                                currentPage={currentPage}
                            />
                        </div>
                        }
                    </React.Fragment>
                )
            }
            <Frame>{toastMarkup}</Frame>
        </div>
    )

};

export default ImportedAffiliatesList;