import React, { useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ResponsiveBar } from '@nivo/bar';
import moment from 'moment';
import { DisplayText, TextStyle, Spinner } from '@shopify/polaris';

import { EmptyState } from 'llama-library/components';
import { moneyFormat } from 'llama-library/utils';

import { getLeftTicks, formatBottomTickValues } from '../../utils/graph-ticks';
import LlamaTabs from '../llama/tabs';

import CampaignLinks from '../campaign-links/campaign-links';
import CampaignSummary from '../campaign-summary/campaign-summary';
import OfferCoupons from '../offer-coupons';
import OfferReports from '../offer-reports';
import OfferCreatives from '../offer-creatives';
import OfferBoost from '../offer-boost/offer-boost';
import CenteredSpinner from '../centered-spinner';

import placeholderImage from '../../assets/placeholder-image.svg';

import './offer-data.css';

/**
 * @function OfferProducts
 * Accepts offer Object, returns OfferProducts Component.
 * Maps through offer.report.productData Array and returns
 * a div containing product images, title, volume and quantity.
 *  
 * @param {Object} offer
 * 
 * @returns {FunctionComponent}
 */
const OfferProducts = ({offer, ...props}) => {

    if (props.isLoading) {
        return <Spinner size="large" color="inkLightest"/>
    }

    if (!offer.reports
        || !Array.isArray(offer.reports.productData) || offer.reports.productData.length === 0) {
        return <div>No products sold for this offer yet.</div>
    }

    return offer.reports.productData
        .map((item) => {
            return (
                <div className="offer-data__product" key={item.product_id}>
                    {item.product
                    && Array.isArray(item.product.images)
                    && item.product.images.length > 0
                    && item.product.images[0].src
                        ? <div style={{backgroundImage: `url(${item.product.images[0].src})`}}
                               className="offer-data__product-image"/>
                        : <div style={{backgroundImage: `url(${placeholderImage})`}}
                               className="offer-data__product-image"/>
                    }
                    <p className="offer-data__product-title" variation="strong">{item.title}</p>
                    <TextStyle variation="subdued">Item Price: ${item.volume / 100}</TextStyle>
                    <TextStyle variation="subdued">Items Sold: {item.quantity}</TextStyle>
                </div>
            )
        })
}

/**
 * @function OfferDashboard
 * Accepts offer Object and returns OfferDashboard Component.
 * Displays nivo ResponsiveBar graph for salesData and wraps
 * OfferProducts Components.
 *
 * @param {Object} offer
 *
 * @returns {FunctionComponent}
 */
const OfferDashboard = ({ offer, history, ...props }) => {
    const { reports } = offer;

    let highestSalesValue = 0;
    let data;

    if (reports && reports.salesData && Array.isArray(reports.salesData) && reports.salesData.length > 0) {
        data = reports.salesData.map(({ date, volume }) => {
            return {
                date,
                value: volume
            };
        });

        highestSalesValue = data.reduce((acc, next) => {
            if (next.value > acc) {
                return next.value;
            }
            return acc;
        }, 0);
    }

    if (highestSalesValue === 0) {
        return (
            <EmptyState
                message="You haven’t had any recent sales"
                paragraph="Your ambassadors haven’t driven any sales to this offer in the past 30 days. To see all sales from this offer, go to Reports."
                ctaText="View Sales Report"
                ctaAction={() => { history.push(`/offer/${offer.offer_id}/reports`); }}
            />
        );
    }

    const leftTickValues = getLeftTicks(6, highestSalesValue);
    const leftMargin = 30 + (leftTickValues[leftTickValues.length - 1].toString().length * 10);

    const axisLeft = {
        tickValues: leftTickValues,
        legend: 'Revenue ($)',
        legendOffset: -70,
        legendPosition: 'middle',
        format: (x) => {
            return moneyFormat(x, true);
        }
    };

    const bottomTickValues = formatBottomTickValues(data.map((item) => {
        return item.date;
    }));

    const axisBottom = {
        tickValues: bottomTickValues,
        format: (date) => {
            return moment(date).format('MM/DD');
        }
    };

    return (
        <>
            <div className="offer-data__chart">
                <DisplayText size="small"><span style={{ fontWeight: 'bold' }}>RECENT SALES</span></DisplayText>
                {data.length > 0
                    && (
                        <ResponsiveBar
                            data={data}
                            indexBy="date"
                            colors="#EB4159"
                            enableLabel={false}
                            margin={{
                                top: 20,
                                right: 10,
                                bottom: 80,
                                left: leftMargin
                            }}
                            axisBottom={axisBottom}
                            axisLeft={axisLeft}
                            gridYValues={leftTickValues}
                            tooltip={({ data: itemData, value, color }) => {
                                return (
                                    <div className="nivo-graph-tooltip-wrapper">
                                        <span className="nivo-graph-tooltip-date">{moment(itemData.date).format('MMM D, YYYY')}</span>
                                        <span className="nivo-graph-tooltip-item">
                                            <span className="nivo-graph-tooltip-color-block" style={{ background: color }} />
                                            Revenue: <span style={{ fontWeight: 'bold' }}>{moneyFormat(value)}</span>
                                        </span>
                                    </div>
                                );
                            }}
                        />
                    )
                }
            </div>
            <div className="offer-data__product-data">
                <DisplayText size="small"><span style={{ fontWeight: 'bold' }}>TOP PRODUCTS</span></DisplayText>
                <div className="offer-data__products">
                    <OfferProducts {...props} offer={offer} />
                </div>
            </div>
        </>
    );
};

let offerTabs = [
    {
        key: 'dashboard',
        name: 'Dashboard',
        link: ''
    },
    {
        key: 'reports',
        name: 'Reports',
        link: '/reports'
    },
    {
        key: 'coupons',
        name: 'Coupons',
        link: '/coupons'
    },
    {
        key: 'creatives',
        name: 'Creatives',
        link: '/creatives'
    },
    {
        key: 'links',
        name: 'Ambassador links',
        link: '/links'
    },
    {
        key: 'boost',
        name: 'Ad Boost',
        link: '/boost'
    },
    {
        key: 'summary',
        name: 'Summary',
        link: '/summary',
        mobileOnly: true
    },
];

/**
 * @function OfferData
 * Accepts offer, history and ...props object returns OfferData Component.
 * Displays offer tabs, and container of the active tab.
 * Contains Routes for offers.
 * 
 * @param {Object} offer
 * @param {Object} history
 * @param {Object} props
 * 
 * @returns {FunctionComponent}
 */
const OfferData = ({offer, history, ...props}) => {
    if (!offer) {
        return null;
    }

    console.log('OFFER-DATA OFFER', offer);

    if (offer.offer_id && (!offer.active || offer.interim_status === 'ACTIVE')) {
        offerTabs = offerTabs.reduce((acc, tab) => {
            console.log(acc, tab);
            if (tab.key !== 'boost') {
                acc.push(tab);
            }
            return acc;
        }, []);
    }

    return (
        <div className="offer-data">
            {offer.offer_id ? (
                <>
                    <LlamaTabs tabs={offerTabs} location={props.location} linkPrefix={`/offer/${offer.offer_id}`} />
                    <Switch>
                        <Route
                            path="/offer/:id/summary"
                            render={(routeProps) => <CampaignSummary { ...routeProps } { ...props } values={offer}/>}
                        />
                        <Route
                            path="/offer/:id/links"
                            render={(routeProps) => <CampaignLinks {...routeProps} {...props} offer={offer}/>}
                        />
                        <Route
                            path="/offer/:id/coupons"
                            render={(routeProps) => <OfferCoupons {...routeProps} {...props} offer={offer}/>}
                        />
                        <Route
                            path="/offer/:id/reports"
                            render={(routeProps) => <OfferReports {...routeProps} {...props} offer={offer}/>}
                        />
                        <Route
                            path='/offer/:id/creatives'
                            render={(routeProps) => <OfferCreatives {...routeProps} {...props} offer={offer}/>}
                        />
                        {offer.active && offer.interim_status !== 'ACTIVE'
                            && (
                                <Route
                                    path='/offer/:id/boost'
                                    render={(routeProps) => <OfferBoost {...routeProps} {...props} offer={offer}/>}
                                />
                            )
                        }
                        <Route render={() => <OfferDashboard {...props} history={history} offer={offer}/>}/>
                    </Switch>
                </>
            ) : (
                <CenteredSpinner/>
            )}
        </div>
    )

};

export default OfferData;
