import { sendGraphQL } from './sendRequest';

import offerFragment from './fragments/offer.fragment';
import parseGraph from '../utils/parse-graph';

const CREATE_OFFER = 'CREATE_OFFER';
export const CREATE_OFFER_PENDING = 'CREATE_OFFER_PENDING';
export const CREATE_OFFER_FULFILLED = 'CREATE_OFFER_FULFILLED';
export const CREATE_OFFER_REJECTED = 'CREATE_OFFER_REJECTED';

const SAVE_OFFER_BANNER = 'SAVE_OFFER_BANNER';
export const SAVE_OFFER_BANNER_PENDING = 'SAVE_OFFER_BANNER_PENDING';
export const SAVE_OFFER_BANNER_FULFILLED = 'SAVE_OFFER_BANNER_FULFILLED';
export const SAVE_OFFER_BANNER_REJECTED = 'SAVE_OFFER_BANNER_REJECTED';

export const EDIT_OFFER = 'EDIT_OFFER';

export const UPDATE_OFFER_PENDING = 'UPDATE_OFFER_PENDING';
export const UPDATE_OFFER_FULFILLED = 'UPDATE_OFFER_FULFILLED';
export const UPDATE_OFFER_REJECTED = 'UPDATE_OFFER_REJECTED';

export const editOffer = (offer) => {
    return {
        type: EDIT_OFFER,
        payload: offer
    };
};

export const createOffer = ({ formData, advertiser }) => {
    if (formData.id) {
        delete formData.id;
    }

    const advertiser_id = advertiser.advertiser_id ? advertiser.advertiser_id : advertiser.id;
    const createFields = [
        'active',
        'advertiser_id',
        'category',
        'cookie_days',
        'description',
        'draft',
        'errors',
        'longterm_rate',
        'name',
        'offer_url',
        'preview_url',
        'products',
        'rate',
        'tags',
        'creatives',
        'target',
        'local_offer',
        'inactive_reason',
        'offer_type',
        'private_offer'
    ];

    if (formData.offer_type === 'product_giveaway' && createFields.includes('rate') && createFields.includes('longterm_rate')) {
        createFields.splice(createFields.indexOf('longterm_rate'), 1);
    }

    const data = Object.entries({ ...formData, advertiser_id }).reduce((acc, [key, value]) => {
        if (!createFields.includes(key)) {
            return acc;
        }
        acc[key] = value;
        return acc;
    }, {});

    const query = `
        mutation createOffer($data: CreateOfferInput) {
            createOffer(data: $data) {
                ...OfferData
            }
        }

        ${offerFragment}
    `;

    const payload = {
        query,
        variables: { data }
    };

    return {
        type: CREATE_OFFER,
        payload: sendGraphQL(payload)
            .then(parseGraph)
    };
};

export const saveOfferBanner = (offer_id, bannerImage) => {
    if (!offer_id || !bannerImage) {
        return false;
    }

    const query = `
        mutation saveOfferBanner($offer_id: String, $bannerImage: String) {
            saveOfferBanner(offer_id: $offer_id, bannerImage: $bannerImage) {
                success
            }
        }
    `;

    const payload = {
        query,
        variables: {
            offer_id,
            bannerImage
        }
    };

    return {
        type: SAVE_OFFER_BANNER,
        payload: sendGraphQL(payload)
            .then(parseGraph)
    };
};

export const updateOffer = ({ formData, advertiser }) => {
    // If no `id` is present, create the offer insead.
    if (!formData.offer_id) {
        return createOffer({ formData, advertiser });
    }

    const updateFields = [
        'active',
        'category',
        'cookie_days',
        'description',
        'draft',
        'errors',
        'longterm_rate',
        'name',
        'offer_url',
        'preview_url',
        'products',
        'rate',
        'tags',
        'creatives',
        'target',
        'bannerImage',
        'private_offer'
    ];

    // Map data to payload object.
    const data = Object.entries(formData).reduce((acc, [key, value]) => {
        if (!updateFields.includes(key)) {
            return acc;
        }
        acc[key] = value;
        return acc;
    }, {});

    // Add advertiser id to payload.
    data.advertiser_id = advertiser.advertiser_id;

    const query = `
        mutation updateOffer($data: UpdateOfferInput, $offer_id: String){
            updateOffer(data: $data, offer_id: $offer_id) {
                ...OfferData
            }
        }

        ${offerFragment}
    `;

    const payload = {
        query,
        variables: {
            data,
            offer_id: formData.offer_id
        }
    };

    return sendGraphQL(payload)
        .then(parseGraph);
};

export const updateOfferStatus = (updateOfferStatusInput) => {
    const updateFields = [
        'active',
        'interim_status',
        'branded_sign_up',
        'inactive_reason'
    ];

    // Map data to payload object.
    const data = Object.entries(updateOfferStatusInput).reduce((acc, [key, value]) => {
        if (!updateFields.includes(key)) {
            return acc;
        }

        acc[key] = value;
        return acc;
    }, {});

    const query = `
    mutation updateOfferStatus($data: UpdateOfferStatusInput, $offer_id: String) {
        updateOfferStatus(data: $data, offer_id: $offer_id) {
            success
        }
    }
    `;

    const payload = {
        query,
        variables: {
            data,
            offer_id: updateOfferStatusInput.offer_id
        }
    };

    return sendGraphQL(payload)
        .then(parseGraph);
};
