import React, { useState } from 'react';

import './featured-ambassador.css';

import PropTypes from 'prop-types';

import formatMetrics from '../../utils/format-metrics';
import formatSocialScore from '../../utils/format-social-score';
import { moneyFormat } from '../../utils/number-format';

import llamaPlaceholder from '../../assets/llama-user-icon.png';

const FeaturedAmbassador = ({ ambassador, history }) => {
    const {
        _id,
        name,
        account,
        reach,
        tracking,
        photo,
        facebookData,
        instagramData,
        twitterData,
        youTubeData
    } = ambassador;

    const { categories } = account;
    const [showAllCategories, setShowAllCategories] = useState(false);

    const epc = tracking && tracking.Stat && tracking.Stat.epc && tracking !== null ? moneyFormat(tracking.Stat.epc, true) : '$0';

    const socialData = {
        facebookData,
        instagramData,
        twitterData,
        youTubeData
    };

    // Get the letter grade badge
    const calculatedEngagement = formatSocialScore(socialData);
    // Grab letter grade only
    let letterGrade;
    letterGrade = calculatedEngagement.props.children;
    if (typeof letterGrade !== 'string') {
        letterGrade = calculatedEngagement.props.children.props.children;
    }

    // Object to pass engagement letter grade to
    // determine the Engagement Rate evaluation message
    // (e.g: A Great, C Average)
    const engagementMessages = {
        A: 'Great',
        B: 'Good',
        C: 'Average',
        D: 'Bad',
        F: 'Bad'
    };

    const ambassadorStats = [
        {
            label: 'Reach',
            value: formatMetrics(reach)
        },
        {
            label: 'Engagement',
            badge: calculatedEngagement,
            value: engagementMessages[letterGrade.trim()]
        },
        {
            label: 'EPC',
            value: epc
        }
    ];

    const goToAmbassador = () => history.push(`/affiliate/${_id}`);

    return (
        <div className="FeaturedAmbassador__Card">
            <img src={photo || llamaPlaceholder} alt={`${name}`} />
            <div className="FeaturedAmbassador__Content">
                <div className="FeaturedAmbassador__CatRow">
                    {/* Show all categories if user clicks show all,
                    otherwise just display the first category */}
                    {showAllCategories && categories.length > 1
                        ? (
                            <div className="FeaturedAmbassador__CatsList">
                                {categories.map((category) => {
                                    return <p key={category} className="FeaturedAmbassador__Category">{category}</p>
                                })}
                            </div>
                        )
                        : <p className="FeaturedAmbassador__Category">{categories[0]}</p>}
                    {/* Only show controls to see all categories if they have more than 1 */}
                    {categories.length > 1 && (
                        showAllCategories ? <p onClick={() => setShowAllCategories(!showAllCategories)} className="FeaturedAmbassador__SeeAllCats">- Less</p> : <p onClick={() => setShowAllCategories(!showAllCategories)} className="FeaturedAmbassador__SeeAllCats">{`+ ${categories.length - 1} More`}</p>
                    )}
                </div>
                <h1>{name}</h1>
                <div className="FeaturedAmbassador__Stats">
                    {ambassadorStats.map((stat) => {
                        const { label, value, badge } = stat;
                        return (
                            <div className="FeaturedAmbassador__Stat" key={label}>
                                <div className="label">
                                    {label}
                                </div>
                                <div className="value">
                                    {badge}
                                    {value}
                                </div>
                            </div>
                        );
                    })}
                </div>
                <p className="FeaturedAmbassador__ViewProfile" onClick={goToAmbassador}>View Profile</p>
            </div>
        </div>
    );
};

FeaturedAmbassador.propTypes = {
    ambassador: PropTypes.shape({
        _id: PropTypes.string,
        name: PropTypes.string,
        account: PropTypes.shape({ categories: PropTypes.arrayOf(PropTypes.string) }),
        reach: PropTypes.string,
        tracking: PropTypes.shape({ Stat: PropTypes.shape({ epc: PropTypes.string }) }),
        photo: PropTypes.string,
        facebookData: PropTypes.arrayOf(PropTypes.shape({ score: PropTypes.number })),
        instagramData: PropTypes.arrayOf(PropTypes.shape({ score: PropTypes.number })),
        twitterData: PropTypes.arrayOf(PropTypes.shape({ score: PropTypes.number })),
        youTubeData: PropTypes.arrayOf(PropTypes.shape({ score: PropTypes.number }))
    }).isRequired,
    history: PropTypes.shape({ push: PropTypes.func })
};

FeaturedAmbassador.defaultProps = { history: null };

export default FeaturedAmbassador;
