import { sendGraphQL } from './sendRequest';

export const GET_FEATURED_AMBASSADORS = 'GET_FEATURED_AMBASSADORS';
export const GET_FEATURED_AMBASSADORS_PENDING = 'GET_FEATURED_AMBASSADORS_PENDING';
export const GET_FEATURED_AMBASSADORS_FULFILLED = 'GET_FEATURED_AMBASSADORS_FULFILLED';
export const GET_FEATURED_AMBASSADORS_REJECTED = 'GET_FEATURED_AMBASSADORS_REJECTED';

const getFeaturedAmbassadors = (advertiser_id) => {
    const query = `
        query getFeaturedAmbassadors($advertiser_id: String) {
            getFeaturedAmbassadors(advertiser_id: $advertiser_id) {
                affiliates {
                    cognito_id
                    _id
                    name
                    photo
                    reach
                    account {
                        categories
                    }
                    tracking_id
                    tracking {
                        Stat {
                            epc
                        }
                    }
                    youTubeData(scoreOnly: true) {
                        score
                    }
                    twitterData(scoreOnly: true) {
                        score
                    }
                    instagramData {
                        score
                    }
                    instagramDataV2 {
                        account_verified
                        account_type
                    }
                    facebookData(scoreOnly: true) {
                        score
                    }
                    tikTokData{
                        aqs
                        account_verified
                        account_type
                    }
                }
            }
        }
    `;

    let variables = {
        advertiser_id
    };

    let payload = {
        query,
        variables
    };

    return {
        type: GET_FEATURED_AMBASSADORS,
        payload: sendGraphQL(payload)
            .then(response => response.data.data.getFeaturedAmbassadors)
    };
};

export default getFeaturedAmbassadors;
