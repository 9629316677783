/* import axios from 'axios';
import { NEXT_APP_API_URL } from '../config'; */
import {sendGraphQL} from './sendRequest'

export const APPLY_TO_AFFILIATE_PENDING = 'APPLY_TO_AFFILIATE_PENDING';
export const APPLY_TO_AFFILIATE_FULFILLED = 'APPLY_TO_AFFILIATE_FULFILLED';
export const APPLY_TO_AFFILIATE_REJECTED = 'APPLY_TO_AFFILIATE_REJECTED';

export const applyToAffiliate = (affiliate_id = null, advertiser_id = null, offers = null, tracking_links = null) => {
    if (!affiliate_id || !advertiser_id || !(offers.length > 0) || tracking_links.length === 0 ) return null;

    const query = `
        mutation applyToAffiliate($advertiser_id: String, $affiliate_id: String, $offers: [String!]!, $tracking_links: [String!]!) {
            applyToAffiliate(advertiser_id: $advertiser_id, affiliate_id: $affiliate_id, offers: $offers, tracking_links: $tracking_links) {
                advertiser_status
                advertiser_id
                affiliate_id
                affiliate_status
                offer_id
            }
        }
    `

    const variables = {
        affiliate_id,
        advertiser_id,
        offers,
        tracking_links
    }

    let payload = {
        query,
        variables
    }

    return sendGraphQL(payload)
        .then(response => response.data.data.applyToAffiliate)
        .catch((error) => console.log('Error at applyToAffiliate', error))

    /* let headers = {
        "Content-Type": 'application/json'
    }

    return axios.post(NEXT_APP_API_URL, payload, { headers })
        .then(response => response.data.data.applyToAffiliate)
        .catch((error) => console.log('Error at applyToAffiliate', error)) */

}
