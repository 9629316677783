import React, { Component } from 'react';
import { Card, DropZone, Stack, Thumbnail, Caption, Heading, TextStyle } from '@shopify/polaris';
import formatFileSize from '../../utils/format-file-size';

import './campaign-creative.css';
import closeIcon from '../../assets/x-black.svg';
import { uploadFileToS3 } from '../../actions/upload-s3-file';

/**
 * @class CampaignCreative
 * Handles state of openFileDialog and renders CampaignCreative Card.
 * Displays creatives and gives the ability to upload new creatives.
 * 
 * @returns {ComponentClass}
 */
class CampaignCreative extends Component {

    constructor(props) {
        super(props);

        this.state = {
            openFileDialog: false
        }
    }

    toggleFileDialog = (state) => {
        const openFileDialog = state ? state : !this.state.openFileDialog;
        this.setState({ openFileDialog })
    }
    
    render() {
        
        let { values: { creatives, offer_id }, advertiser } = this.props;
        if (!creatives) {
            creatives = [];
        }
        
        const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
        const empty = (!creatives || !creatives.length || (creatives.length === 1 && creatives[0] === ""));

        const fileUpload = empty && <DropZone.FileUpload actionTitle="Add a Promo Banner" actionHint="or drop image files to upload" />;

        const creativesInputFields = [
            'name',
            'size',
            'type',
            'url'
        ]

        /**
         * @function handleFileUpload
         * Accepts an array of files, maps through the array and passes uploaded file
         * to uploadFileTos3 method which uploads to the s3 bucket.
         * 
         * @param {Array} files - Array of files
         * @returns {Array} - uploadedFiles
         */
        const handleFileUpload = (files) => {
            const uploadedFiles = files.map((file) => {
                return uploadFileToS3(file, `advertisers/${advertiser.advertiser_id}/offers/${offer_id}`)
                    .then((result) => {
                        const url = new URL(result);
                        const fileURL = url.origin + url.pathname;
                        file.url = fileURL;
                        const newFile = creativesInputFields.reduce((acc, key) => {
                            if (creativesInputFields.includes(key)) {
                                acc[key] = file[key];
                            }
                            return acc;
                        }, {});
                        return newFile;
                    })
            })
            Promise.all(uploadedFiles).then((newFiles) => {
                const field = 'creatives';
                const value = [...creatives, ...newFiles];
                this.props.setFieldValue(field, value, true);
            })
        }

        /**
         * @function removeCreative
         * Accepts event and file, creates a newCreatives array, filters
         * through to remove the file and sets this new Array for 'creatives' field.
         * 
         * @param {event} event
         * @param {Object} file 
         */
        const removeCreative = (event, file) => {
            event.stopPropagation();
            const newCreatives = creatives.filter((item) => {
                return item.name !== file.name;
            })
            this.props.setFieldValue('creatives', newCreatives, true);
        }

        /**
         * @function renderUploadedFiles
         * Maps through creatives Array and returns a div element
         * for the creatives in the array.
         * 
         * @returns {HTMLDivElement}
         */
        const renderUploadedFiles = () => {
            if (!creatives || !(creatives.length > 0) || (creatives.length === 1 && creatives[0] === "")) return null;

            return (
                <Stack vertical>
                    {creatives.map((file) => {
                        if (file === "") return null;

                        return (
                            <div key={file.name || file} className="campaign-creatives__item">
                                <Stack alignment="center">
                                    <Thumbnail
                                        size="small"
                                        alt={file.name}
                                        source={
                                            validImageTypes.indexOf(file.type) > 0
                                                ? file.url
                                                : 'https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304'
                                        } />
                                    <div>
                                        {file.name} <Caption>{formatFileSize(file.size)}</Caption>
                                    </div>
                                </Stack>
                                <div>
                                    <img src={closeIcon} className="campaign-creatives__delete" onClick={(event) => removeCreative(event, file)}/>
                                </div>
                            </div>
                        )
                    })}
                </Stack>
            )
        }

        return (
            <Card
                title="EDIT CREATIVES"
                actions={[
                    {
                        content: "Upload Image",
                        onAction: () => {
                            this.toggleFileDialog(true);
                        }
                    }
                ]}
            >
                <Card.Section>
                    <div className="campaign-creatives__heading">
                        {/* <Heading>CREATIVES</Heading> */}
                        <TextStyle variation="subdued"><span className="subtitle" className="campagin-creatives__subtitle">Add visuals for your affiliates to utilize in their campaigns.</span></TextStyle>
                    </div>
                </Card.Section>
                <Card.Section>
                    <DropZone 
                        onDrop={(files) => handleFileUpload(files)}
                        openFileDialog={this.state.openFileDialog}
                        type="image"
                        onFileDialogClose={() => {
                            this.toggleFileDialog(false);
                        }}
                    >
                        {renderUploadedFiles()}
                        {fileUpload}
                    </DropZone>
                </Card.Section>
            </Card>
        )
    
    }


}

export default CampaignCreative;