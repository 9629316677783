const parseGraph = (response) => {

    if (!response) {
        return Promise.reject('No response from server');
    }

    if (!response.status === 200) {
        if (response.statusText) {
            return Promise.reject(response.statusText);
        }

        return Promise.reject(`An error has occured.  Status Code: ${response.status}`);
    }

    if (response.message) {
        return Promise.reject(response.message);
    }

    if (!response.data) {
        return Promise.reject('No data returned from server. Please try again.');
    }

    const { data } = response;

    if (data.errors && data.errors.length > 0) {
        if (!Array.isArray(data.errors)) {
            data.errors = [data.errors];
        }

        data.errors.forEach((error) => {
            console.error(error.message);
        });

        return Promise.reject('An error has occured.');
    }

    if (!data || !data.hasOwnProperty('data')) {
        return Promise.reject('No data returned from server. Please try again.');
    }

    return data.data;

}

export default parseGraph;