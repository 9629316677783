const formatCurrency = (number, dollarSign = true, keepZero = false) => {

    if (!number) {
        if(!keepZero || (keepZero && number !== 0)){
            return null;
        }
    }

    number = Number.parseFloat(number);

    if (Number.isNaN(number)) {
        return '';
    }

    number = number.toFixed(2);
    number = number.replace(/./g, (match, offset, fullString) => {
        return offset && match !== '.' && ((fullString.length - offset) % 3 === 0) ? `,${match}` : match
    })
    
    if (dollarSign) {
        number = String(number);
        number = `$ ${number}`;
    }

    return number;

}

module.exports = formatCurrency;