import React, { Component } from 'react';
import { Checkbox, TextField, Icon } from '@shopify/polaris';
import {
    SearchMinor
} from '@shopify/polaris-icons';

import { EmptyState, LlamaPagination } from 'llama-library/components';
import { moneyFormat, pluralize } from 'llama-library/utils';
import './search-bar.css';

const ITEMS_PER_PAGE = 10;
const defaultFilter = () => true;

/**
 * @class SearchBar
 * Holds state for input, searchFilter, matches, selectedTab,
 * tabFilter, disabled and currentPage.
 * Renders search bar and renders search selections.
 * 
 * @returns {ComponentClass}
 */
class SearchBar extends Component  {

    constructor(props) {
        super(props);

        this.noFilter = () => true;

        this.state = {
            input: '',
            searchFilter: defaultFilter,
            matches: this.props.data,
            selectedTab: 0,
            tabFilter: this.noFilter,
            disabled: this.props.disabled,
            currentPage: 0,
            pageWidth: window.innerWidth,
            isMobileLayout: false
        };

        this.tabs = [
            {
                id: 'all',
                content: 'View All',
                panelID: 'all'
            }, {
            //     id: 'collections',
            //     content: 'Collections',
            //     panelID: 'collection'
            // }, {
            //     id: 'products',
            //     content: 'Products',
            //     panelID: 'product'
            // }, {
                id: 'selected',
                content: 'Selected',
                panelID: 'selected'
            }, {
                id: 'unselected',
                content: 'Unselected',
                panelID: 'unselected'
            }
        ]

        this.selectItem = this.selectItem.bind(this);
        this.filterList = this.filterList.bind(this);
        this.selectTab = this.selectTab.bind(this);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.data && (JSON.stringify(nextProps.data) !== JSON.stringify(prevState.matches))) {
            return {
                matches: nextProps.data
            };
        }
        return null;
    }

    componentDidMount() {
        window.addEventListener('resize', () => {
            if (this.state.pageWidth !== window.innerWidth) {
                this.setState({ pageWidth: window.innerWidth });

                console.log(this.state, window.innerWidth);
                this.determineMobileLayout();
            }
        });
        this.determineMobileLayout();
    }

    determineMobileLayout = () => {
        if (!this.state.isMobileLayout
            && (
                (window.innerWidth <= 700 && window.innerWidth >= 600)
                || window.innerWidth <= 478
            )
        ) {
            this.setState({ isMobileLayout: true });
        } else if (this.state.isMobileLayout
            && (
                window.innerWidth > 700
                || (window.innerWidth > 478 && window.innerWidth < 600)
            )
        ) {
            this.setState({ isMobileLayout: false });
        }
    }

    filterList(input) {
        if (!input || input === "") {
            this.setState({ searchFilter: defaultFilter, input: "" })
            return;
        }

        const lowerCaseInput = input.toLowerCase();
        const newFilter = (item) => {
            return item.title.toLowerCase().search(lowerCaseInput) !== -1;
        }

        this.setState({ searchFilter: newFilter, input });
    }

    selectItem(event) {
        let id = event.currentTarget.id;
        this.props.toggleItem(id);
    }

    selectAll = (selected) => {
        let allProductIds = [];
        if (selected) {
            allProductIds = this.state.matches.map((product) => {
                return product.product_id;
            });
        }
        console.log(allProductIds);
        this.props.toggleAll(allProductIds);
    }

    selectTab(tabIndex) {
        let id = this.tabs[tabIndex].panelID;

        let newFilter = this.noFilter;
        switch(id) {
            case 'selected':
                newFilter = (item) => this.props.selected.includes(`${item.id}`);
                break;
            case 'unselected': 
                newFilter = (item) => !this.props.selected.includes(`${item.id}`);
                break;
            case 'collection':
            case 'product':
                newFilter = (item) => item.type === id;
                break;
            default:
                break;
        }

        this.setState({ ...this.state, selectedTab: tabIndex, tabFilter: newFilter, currentPage: 1 });
    }

    parseShopifyProduct({ variants }) {

        let quantity = variants.reduce((acc, curr) => {
            return acc + (curr.inventory_quantity || 0);
        }, 0)

        let price = variants.reduce((acc, curr) => {
            let newPrice = parseFloat(curr.price);
            if (!acc.lowest) {
                return {lowest: newPrice, highest: newPrice}
            }

            if (newPrice > acc.highest) {
                return {...acc, highest: newPrice}
            }

            if (newPrice < acc.lowest) {
                return {...acc, lowest: newPrice}
            }

            return acc;

        }, {lowest: null, highest: null})

        return { quantity, price }

    }

    formatItemPrice(price) {
        if (!price.hasOwnProperty('highest') || !price.hasOwnProperty('lowest')) {
            return '';
        }

        if (price.highest === price.lowest) {
            return moneyFormat(price.lowest);
        }

        return `${moneyFormat(price.lowest)}+`;
    }

    selectedItem(id) {
        if (this.state.disabled) {
            return true;
        }

        if (!this.props.selected) {
            return false;
        }

        return this.props.selected.includes(`${id}`);
    }

    allSelected = () => {
        if (this.props.selected.length === this.state.matches.length) {
            return true;
        }

        if (this.props.selected.length === 0) {
            return false;
        }

        return 'indeterminate';
    }

    handlePageChange = ({ currentPage }) => {
        this.setState({ currentPage: currentPage - 1 });
    }

    renderMatches() {
        const { currentPage } = this.state;

        let matches = this.state.matches
            .filter(this.state.searchFilter)
            // .filter(this.state.tabFilter);

        const totalMatches = matches.length;
        const totalPages = Math.ceil(totalMatches / ITEMS_PER_PAGE)

        if (matches.length > ITEMS_PER_PAGE) {
            const start = currentPage * ITEMS_PER_PAGE;
            const end = (currentPage + 1) * ITEMS_PER_PAGE;
            matches = matches.slice(start, end);
        }

        if (matches.length === 0) {
            return (
                <EmptyState
                    message="You don’t have any eligible products"
                    paragraph="Only products you’ve marked as active can be added to your offer."
                    ctaText="Manage Products"
                    ctaAction={() => { this.props.history.push('/products'); }}
                />
            );
        }

        return (
            <>
                <table className="search__matches">
                    <thead className="search__item-header">
                        <tr>
                            <th className="search__check">
                                <Checkbox
                                    checked={this.allSelected()}
                                    disabled={this.state.disabled}
                                    onChange={this.selectAll}
                                />
                            </th>
                            {this.state.isMobileLayout
                                ? <th>{pluralize(this.props.selected.length, 'product', 'products')} selected</th>
                                : (
                                    <>
                                        <th className="search__title">NAME</th>
                                        <th className="search__qty">QTY</th>
                                        <th className="search__price">PRICE</th>
                                    </>
                                )
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            matches.map((item) => {
                                if (item.product_id) {
                                    item.id = item.product_id;
                                }

                                const itemData = this.parseShopifyProduct(item);

                                return (
                                    <tr key={item.id} id={`${item.id}`} onClick={this.selectItem} className="search__item">
                                        <td className="search__check">
                                            <Checkbox
                                                checked={this.selectedItem(item.id)}
                                                onChange={() => this.props.toggleItem(item.id)}
                                                disabled={this.state.disabled}
                                            />
                                        </td>
                                        {this.state.isMobileLayout
                                            ? (
                                                <td className="search__product-info">
                                                    <div>
                                                        <img src={item.image.src} alt="" className="search__image" />
                                                        <p>
                                                            <span className="search__title">{item.title}</span>
                                                            <span className="search__qty">{itemData.quantity}</span>
                                                            <span className="search__price">{`${this.formatItemPrice(itemData.price)}`}</span>
                                                        </p>
                                                    </div>
                                                </td>
                                            )
                                            : (
                                                <>
                                                    <td className="search__title"><img src={item.image.src} alt="" /> {item.title}</td>
                                                    <td className="search__qty">{itemData.quantity}</td>
                                                    <td className="search__price">{`${this.formatItemPrice(itemData.price)}`}</td>
                                                </>
                                            )
                                        }
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </table>
                {totalPages > 1
                    && (
                        <div className="search__textfield-wrapper search__pagination-wrapper">
                            <LlamaPagination
                                useVersion2
                                itemsPerPage={ITEMS_PER_PAGE}
                                totalItems={this.state.matches.length}
                                onPageChange={this.handlePageChange}
                            />
                        </div>
                    )
                }
            </>
        );
    }

    render() {
        return (
            <div className="search">
                {this.state.matches.length > 0 &&
                    <div className="search__textfield-wrapper">
                        <TextField 
                            value={this.state.input} 
                            onChange={this.filterList} 
                            placeholder="Search"
                            prefix={<Icon source={SearchMinor} color="inkLighter" />}
                        />
                    </div>
                }
                {/* <Tabs selected={this.state.selectedTab} 
                    tabs={this.tabs}
                    onSelect={this.selectTab}/> */}
                {this.renderMatches()}
            </div>
        )
    }

}

export default SearchBar;