import { useState, useEffect } from 'react';

import { getAmbassadorReports } from '../actions/ambassador-reports';

const volumeReducer = (acc, next) => {
    acc = acc + next.volume;
    return acc;
}

const salesReducer = (acc, next) => {
    acc = acc + next.sales;
    return acc;
}

const parseAmbassadorReports = (data) => {

    if (!data) {
        return null;
    }

    const ambassadors = data.applications.reduce((acc, next) => {
        if (!next.affiliate_id) {
            return acc;
        }

        const { offer, affiliate } = next;
        const { tracking_id } = affiliate;

        if (!tracking_id) {
            return acc;
        }

        if (acc[tracking_id]) {
            acc[tracking_id].offers.push(offer);
            return acc;
        }

        acc[tracking_id] = {
            affiliate_id: tracking_id,
            offers: [offer],
            affiliate,
        }
        return acc;
    }, {})

    if (Array.isArray(data.reports.affiliateData)) {
        data.reports.affiliateData.forEach((item) => {
            ambassadors[item.affiliate_id] = { 
                ...ambassadors[item.affiliate_id],
                ...item,
            }
        })
    }

    if (Array.isArray(data.trackingByField)) {
        data.trackingByField.forEach(({ Stat }) => {
            ambassadors[Stat.affiliate_id] = { 
                ...ambassadors[Stat.affiliate_id],
                ...Stat,
            }
        })
    }

    return Object.values(ambassadors)
        .filter((item) => {
            return item.affiliate && item.affiliate.affiliate_id;
        })
        .map((item) => {
            item.totals = {
                volume: 0,
                sales: 0,
            }
            if (item.sales_data) {
                item.totals.volume = item.sales_data.reduce(volumeReducer, 0)
                item.totals.sales = item.sales_data.reduce(salesReducer, 0)
            }
            return item;
        });
}

const useAmbassadorReports = (advertiser_id, filter) => {

    const [ambassadors, setAmbassadors] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [offers, setOffers] = useState([]);

    useEffect(() => {
        let { startDate, endDate, ...ambassadorFilters } = filter;

        if (Object.prototype.toString.call(startDate) === '[object Date]') {
            startDate = startDate.toISOString();
        };

        if (Object.prototype.toString.call(endDate) === '[object Date]') {
            endDate = endDate.toISOString();
        };

        const dateRange = { startDate, endDate }

        if (advertiser_id && !isLoading && startDate !== endDate) {
            setIsLoading(true);
            getAmbassadorReports(advertiser_id, dateRange, ambassadorFilters)
                .then((data) => {
                    setIsLoading(false);
                    if (!data || !data.reports || !data.reports.affiliateData || !Array.isArray(data.reports.affiliateData) || data.reports.affiliateData.length === 0) {
                        console.log("no data");
                        return null;
                    }

                    const offers = data.applications.reduce((acc_offers, app) => {
                        if(Array.isArray(acc_offers) && acc_offers.length > 0 && app.offer && !acc_offers.find(offer => offer.offer_id === app.offer.offer_id)){
                            acc_offers.push(app.offer)
                        }
                        return acc_offers
                    }, [])
                    setOffers(offers);

                    return data;
                })
                .then(parseAmbassadorReports)
                .then(setAmbassadors)
                .catch((error) => {
                    console.log('error', error);
                    if (error.message === 'Network Error') {
                        setErrorMessage({
                            message: 'Connection Lost',
                            paragraph: 'Oops, looks like you might have gotten disconnected. Check your internet connection, then try again.'
                        })
                    }
                    setIsLoading(false)
                });
        }
    }, [advertiser_id, filter])
    
    return {
        ambassadors,
        isLoading,
        errorMessage,
        offers
    };

}

export default useAmbassadorReports;