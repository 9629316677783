import { sendGraphQL } from './sendRequest';

export const addToAutoSmartLinks = (affiliate_id, offers, tracking_links) => {
    if (!Array.isArray(offers)) {
        offers = [offers];
    }
    if (!Array.isArray(tracking_links)) {
        tracking_links = [tracking_links];
    }

    const query = `
        mutation addToAutoSmartLinks($affiliate_id: String!, $offers: [OfferInfo!]!, $tracking_links: [String!]!){
            addToAutoSmartLinks(affiliate_id: $affiliate_id, offers: $offers, tracking_links: $tracking_links){
                affiliate_id
            }
        }
    `;

    const variables = {
        affiliate_id,
        offers,
        tracking_links
    };

    const payload = {
        query,
        variables
    };

    return sendGraphQL(payload)
        .then((response) => { return response.data.data; });
};

export default addToAutoSmartLinks;
