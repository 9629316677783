/* import axios from 'axios';
import { NEXT_APP_API_URL } from '../config'; */
import { sendGraphQL } from './sendRequest';

// used for ambassador peformance page
export const getAmbassadorPerformanceReports = (advertiser_id, dateRanges, offer_id, searchValue, sortBy, page) => {
    if (!advertiser_id) {
        return Promise.reject(Error('advertiser id is required'));
    }

    const perPage = 6;
    const skip = (page - 1) * perPage;

    const query = `
        query getAmbassadorPerformance(
            $advertiser_id: String,
            $start_date: String,
            $end_date: String,
            $offer_id: String,
            $affiliate_name: String,
            $options: AmbassadorPerformanceOptions
        ){
            getAmbassadorPerformance(
                advertiser_id: $advertiser_id,
                start_date: $start_date,
                end_date: $end_date,
                offer_id: $offer_id,
                affiliate_name: $affiliate_name,
                options: $options
            ){
                reports{
                    ambassador{
                        _id
                        name
                        tracking_id
                        photo
                    }
                    offers{
                        name
                        offer_id
                        products{
                            includeSome
                            includeAll
                            includeProducts
                        }
                        discounts{
                            code
                            affiliate_id
                        }
                        orders{
                            order{
                                order_id
                                discount_codes{
                                    code
                                    amount
                                    type
                                }
                                created_at
                                subtotal_price
                                source_type
                            }
                            customer_info{
                                customer_id
                                order_id
                                initial_order
                            }
                        }
                        clicks
                    }
                }
                pageInfo{
                    hasNextPage
                    hasPreviousPage
                    pages
                    currentPage
                    count
                }
            }
        }
    `;

    const variables = {
        advertiser_id,
        start_date: dateRanges.startDate.replace(' ', 'T'),
        end_date: dateRanges.endDate.replace(' ', 'T'),
        offer_id,
        affiliate_name: searchValue,
        options: {
            sortBy,
            skip,
            limit: perPage
        }
    };

    const payload = {
        query,
        variables
    };

    return sendGraphQL(payload)
        .then((response) => {
            return response.data.data.getAmbassadorPerformance;
        });
};

export const getApprovedOffers = (advertiser_id) => {
    if (!advertiser_id) {
        return Promise.reject(Error('advertiser id is required'));
    }

    const query = `
        query getApprovedOffers($advertiser_id: String){
            getApprovedOffers(advertiser_id: $advertiser_id){
                name
                offer_id
            }
        }
    `;

    const variables = {
        advertiser_id
    };

    const payload = {
        query,
        variables
    };

    return sendGraphQL(payload)
        .then((response) => {
            return response.data.data.getApprovedOffers;
        });
};

// used for dashboard on ambassador profile page
export const getAmbassadorReports = (advertiser_id, dateRanges, filter) => {

    if (!advertiser_id) {
        return Promise.reject(`advertiser_id required for getAmbassadorReports.`);
    }

    const query = `
        query getAmbassadorReports(
            $advertiser_id: String, 
            $start_date: String, 
            $end_date: String, 
            $filter: ReportsFilter, 
            $offer_id: String,
            $affiliate_id: String
        ) {
            advertiserById(advertiser_id: $advertiser_id) {
                name
                applications(
                    filter: { affiliate_status: APPROVED, advertiser_status: APPROVED },
                ) {
                    affiliate_status
                    advertiser_status
                    offer {
                        offer_id
                        name
                    }
                    affiliate_id
                    affiliate {
                        _id
                        affiliate_id
                        name
                        tracking_id
                        cognito_id
                        photo
                    }
                }
                reports(start_date: $start_date, end_date: $end_date, filter: $filter) {
                    affiliateData {
                        affiliate_id
                        affiliate {
                            _id
                            affiliate_id
                            name
                            tracking_id
                            cognito_id
                        }
                        sales
                        products_sold
                        volume
                        sales_data {
                            date
                            sales
                            volume
                        }
                    }
                }
                trackingByField(
                    fields: [affiliate_id],
                    filters: { start_date: $start_date, end_date: $end_date, offer_id: $offer_id, affiliate_id: $affiliate_id }
                ) {
                    Stat {
                        affiliate_id
                        revenue
                        epc
                        clicks
                        conversions
                    }
                }
            }
        }
    `

    const variables = {
        advertiser_id,
        start_date: dateRanges.startDate,
        end_date: dateRanges.endDate,
    }

    const reportFilters = Object.entries(filter)
        .map(([key, value]) => {
            if (value) {
                return [key, value];
            }
            return null;
        })
        .filter((item) => item !== null)
        .reduce((acc, [key, value]) => {
            acc[key] = value;
            return acc;
        }, {})

    if (filter) {
        variables.filter = reportFilters;
    }

    if (filter && filter.offer_id) {
        variables.offer_id = filter.offer_id;
    }

    if (filter && filter.affilaite_id) {
        variables.affilaite_id = filter.affilaite_id;
    }

    const payload = {
        query,
        variables,
    }

    return sendGraphQL(payload)
        .then(response => response.data.data.advertiserById)

    /* const headers = {
        "Content-Type": 'application/json'
    }

    return axios.post(NEXT_APP_API_URL, payload, { headers })
        .then(response => response.data.data.advertiserById); */

}