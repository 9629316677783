import React from 'react';
import { Spinner } from '@shopify/polaris';

const CenteredSpinner = ({ style = {} }) => {

    const spinnerStyle = { 
        display: 'flex', 
        justifyContent: 'center', 
        padding: '2rem', 
        width: '100%', 
        flex: '1', 
        ...style 
    }

    return <div style={spinnerStyle}>
        <Spinner />
    </div> 
}

export default CenteredSpinner;