const formatBottomTickValues = (ticks, totalTicks = 4, maxFullLength = 10) => {

    if (!Array.isArray(ticks)) {
        return [];
    }

    if (ticks.length < maxFullLength) {
        return ticks;
    }

    const skipAxis = Math.round(parseInt((ticks.length) / (totalTicks - 1) ) );
    const lastIndex = ticks.length - 1;
    let tickValues = [];
    tickValues.push(ticks[0]);
    for (let i = 1; i < (totalTicks - 1); i++) {
        tickValues.push(ticks[(skipAxis * i)]);
    }
    tickValues.push(ticks[lastIndex]);

    return tickValues;

}

export default formatBottomTickValues;