import { GET_ADVERTISER_FULFILLED } from '../actions/advertiser';
import { UPDATE_ADVERTISER_FULFILLED } from '../actions/update-advertiser';
import { GET_NOTIFICATIONS_FULFILLED } from '../actions/notifications';
import { UPDATE_ADVERTISER_PAYMENT_FULFILLED } from '../actions/update-advertiser-payments';
import { GET_BRANDED_SIGNUP_FULFILLED } from '../actions/branded-sign-up';
import {UPDATE_NOTIFICATIONS_VIEW_STATUS} from '../actions/notifications';

import { INVITE_PAGE_OFFERS_FULFILLED } from '../actions/branded-sign-up';

const defaultState = {
    shop: '',
    status: null,
    id: '',
    address1: '',
    address2: '',
    city: '',
    region: '',
    zipcode: '',
    country: '',
    phone: '',
    notifications: null,
    avatar_image: ''
}

const advertiserReducer = (state = defaultState, action) => {

    let { type } = action;

    switch (type) {

        case GET_ADVERTISER_FULFILLED:
            if (!action || !action.payload || !action.payload.data || !action.payload.data.advertisers) {
                return state;
            }
            let advertiserData = action.payload.data.advertisers[0];

            // Add `id` to newState since that is used for some calls instead of advertiser_id.
            const newState = Object.assign({}, { id: advertiserData.advertiser_id }, advertiserData);

            return { ...state, ...newState }

        case UPDATE_ADVERTISER_FULFILLED: {
            return { ...state, ...action.payload.data.updateAdvertiser }
        }

        case UPDATE_ADVERTISER_PAYMENT_FULFILLED: {
            return { ...state, ...action.payload.data.updateAdvertiserPayments }
        }

        case GET_BRANDED_SIGNUP_FULFILLED: {
            if (!action || !action.payload || !action.payload.data || !action.payload.data.advertiserById) {
                return state;
            }

            let offers = [];
            if (
                Array.isArray(action.payload.data.advertiserById.offers)
                && action.payload.data.advertiserById.offers.length > 0
                && Array.isArray(state.offers)
            ) {
                offers = action.payload.data.advertiserById.offers.map((offer) => {
                    const stateOffer = state.offers.find((item) => {
                        return offer.offer_id === item.offer_id;
                    })

                    return { ...stateOffer, ...offer }
                })
            } else if (
                (!Array.isArray(action.payload.data.advertiserById.offers) || action.payload.data.advertiserById.offers.length === 0)
                && Array.isArray(state.offers)
            ) {
                offers = state.offers;
            }

            console.log(offers);

            // Don't replace the offer object in the advertiser.
            return { ...state, ...action.payload.data.advertiserById, offers }
        }

        case GET_NOTIFICATIONS_FULFILLED: {
            if (!action || !action.payload || !action.payload.data) {
                return state;
            }
            return { ...state, ...action.payload.data.advertiserById }
        }

        case UPDATE_NOTIFICATIONS_VIEW_STATUS: {
            return {
                ...state,
                notifications: action.payload
            }
        }

        case INVITE_PAGE_OFFERS_FULFILLED:{

            if (!action || !action.payload || !action.payload.data || !action.payload.data.advertiserById) {
                return state;
            }

            let offers = [];
            if (
                Array.isArray(action.payload.data.advertiserById.offersForBrandedSignup)
                && action.payload.data.advertiserById.offersForBrandedSignup.length > 0
                && Array.isArray(state.offers)
            ) {
                offers = action.payload.data.advertiserById.offersForBrandedSignup.map((offer) => {
                    const stateOffer = state.offers.find((item) => {
                        return offer.offer_id === item.offer_id;
                    })

                    return { ...stateOffer, ...offer  }
                })
            }
            console.log('offers in reducer ',offers);

            // Don't replace the offer object in the advertiser.
            return { ...state, ...action.payload.data.advertiserById, offers }
        }

        default:
            return state;

    }

}

export default advertiserReducer;