/* import axios from 'axios';
import { NEXT_APP_API_URL } from '../config'; */
import { sendGraphQL } from './sendRequest'

import discountFragment from './fragments/discount.fragment';

export const createPriceRule = (data) => {

    const query = `
        mutation createPriceRule($data: PriceRuleInput!) {
            createPriceRule(data: $data) {
                ...DiscountData
            }
        }

        ${discountFragment}
    `

    const payload = {
        query,
        variables: {
            data
        }
    }

    return sendGraphQL(payload)
        .then(response => response.data.data.createPriceRule)

    /* let headers = {
        "Content-Type": 'application/json'
    }

    return axios.post(NEXT_APP_API_URL, payload, { headers })
        .then(response => response.data.data.createPriceRule) */

}