import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getCategories } from '../actions/getCategories';
import { updateAdvertiser } from '../actions/update-advertiser';
import { getAdvertiser } from '../actions/advertiser';
import { uploadFileToS3 } from '../actions/upload-s3-file';
import { getLTVOrders, getLTVOrdersFromShopify } from '../actions/get-ltv-orders';

import { LlamaButton } from 'llama-library/components';
import ImageDrop, { saveImage } from '../components/llama/image-drop';
import LlamaTour from '../components/llama-tour/LlamaTour';

import LookForwardInsightsSkeleton from '../components/settings-ltv/ltv-skeleton';
import LookForwardInsights from '../components/settings-ltv/look-forward-insights';

// import Tour from 'reactour';

import {
    RangeSlider,
    Banner,
    Spinner,
    SkeletonBodyText
} from '@shopify/polaris';

import numberFormat from '../utils/number-format';

import Payment from '../components/settings-paypal/paypal-payment';
import './styles/onboarding.css';
import {
    Page,
    Layout,
    ProgressBar,
    FormLayout,
    ChoiceList,
    Frame,
    Toast,
    RadioButton,
    Icon
} from '@shopify/polaris';
import {
    CircleCancelMinor
  } from '@shopify/polaris-icons';

import arrow from '../assets/right-arrow.svg';
import aiIcon from '../assets/ai-icon.png';
import psIcon from '../assets/ps-icon.png';

export class Onboarding extends Component {

    constructor(props) {
        super(props);

        const exampleOrderDate = new Date();
        const exampleDateSub = exampleOrderDate.getDate() - (12 * 30);
        exampleOrderDate.setDate(exampleDateSub);

        this.state = {
            step: 1,
            choiceList: [],
            selectedCategories:[],
            logo: null,
            lookBackValue: 12,
            lookForwardValue: 12,

            forwardSliderValue: 12,
            backSliderValue: 12,
            LTVOrders: [],
            LTVOrdersLoaded: false,
            LTVOrdersCalled: false,
            lbExampleCustomer: {
                name: "John Doe",
                orderDate: exampleOrderDate.toISOString()
            },
            lockedOut: {
                oneTime: 0,
                repeat: 0
            },
            initialLockedOut: {
                oneTime: 0,
                repeat: 0
            },
            salesData: {
                totalSales: 0,
                totalOrders: 0,
                totalNonRepeatOrders: 0,
                totalNonRepeatSales: 0,
                totalRepeatSales: 0,
                totalRepeatOrders: 0,
                totalRepeatCustomers: 0,
                totalNonRepeatCustomers: 0
            },
            ltvMonths: 6,
            ltvGraphData: [
                {
                    "storeType": "One-Time Customers",
                    "Estimated Lifetime Value": 0,
                    "Estimated Lifetime ValueColor": "#fc2c58"
                },
                {
                    "storeType": "Repeat Customers",
                    "Estimated Lifetime Value": 0,
                    "Estimated Lifetime ValueColor": "#00a313"
                }
            ],

            timeFrames: [3,6,9,12,18,24,36,48],
            active: false,
            showToast: false,
            showErrorToast: false,
            displayLBDiv: "onboarding-display-none",
            displayLFDiv: "onboarding-display-none",
            displayHelpText: false,
            pageWidth: window.innerWidth,
            isTourOpen: false,
            paypalError: false,
        }

        this.trackStepInc = this.trackStepInc.bind(this);
        this.trackStepDec = this.trackStepDec.bind(this);
        this.navigateToOffers = this.navigateToOffers.bind(this);

        const pullSince = new Date();
        const dateSub = pullSince.getDate() - 180;
        pullSince.setDate(dateSub);
    }

    componentDidMount(){
        this.setState({
            step: parseInt(this.props.match.params.id)
        })
    }

    componentDidUpdate(){
        window.addEventListener('resize', () => {
            if(this.state.pageWidth != window.innerWidth){
                this.setState({
                    pageWidth: window.innerWidth
                })
            }
        })

        console.log('PROPS AND STATE', this.props, this.state)

        if(this.props.advertiser && this.props.advertiser.advertiser_id && !this.state.LTVOrdersCalled && (this.state.step === 3 || this.state.step === 4)){
            this.setState({
                LTVOrdersCalled: true
            })

            const pullSince = new Date();
            const dateSub = pullSince.getDate() - 180;
            pullSince.setDate(dateSub);

            console.log('PULL SINCE', pullSince);

            this.props.getLTVOrders(this.props.advertiser.advertiser_id, pullSince.toISOString(), true)
                .then((result) => {
                    console.log('GET LTV ORDERS RESULT', result);
                    if(result.value && result.value.data && result.value.data.orders && Array.isArray(result.value.data.orders)){
                        if(result.value.data.orders.length === 0){
                            //if there's no orders in the database yet, get them directly from shopify
                            return this.props.getLTVOrdersFromShopify(this.props.advertiser.myshopify_domain, pullSince.toISOString())
                                .then(result => {
                                    console.log('GET DIRECTLY FROM SHOPIFY', result);
                                    if(result.value && result.value.data && result.value.data.getLTVOrdersFromShopify && Array.isArray(result.value.data.getLTVOrdersFromShopify)){
                                        console.log('ORDERS RECEIVED FROM SHOPIFY', result);
                                        this.setState({
                                            LTVOrders: result.value.data.getLTVOrdersFromShopify,
                                            LTVOrdersLoaded: true
                                        }, () => {
                                            return 'done'
                                        });
                                    }else{
                                        console.log('ORDERS NOT RECEIVED FROM SHOPIFY', result);
                                        throw result;
                                    }
                                })
                        }else{
                            console.log('ORDERS FOUND', result)
                            this.setState({
                                LTVOrders: result.value.data.orders,
                                LTVOrdersLoaded: true
                            }, () => {
                                return 'done'
                            });
                        }
                    }
                })
                .then(() => {
                    console.log('GET LTV ORDERS DONE', this.state)
                    this.getExcludedCustomers(); //look back
                    this.getOrderDataSet(); //look forward
                    this.getExampleCustomer(); //pick out customer from data set to use as an example
                })
                .catch(err => {
                    console.log('ERROR', err);
                })
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps.step && (nextProps.step !== prevState.step)) {
            return {
                advertiser_id: parseInt(nextProps.match.params.id)
            };
        }

        return null;
    }

    toggleToast = () => {
        this.setState(({showToast}) => ({showToast: !showToast}));
    };

    toggleErrorToast = () => {
        this.setState(({showErrorToast}) => ({showErrorToast: !showErrorToast}));
    };

    togglePayPalError = (paypalErrorValue) => {
        this.setState({ paypalError: paypalErrorValue });
    };

    trackStepInc = (skipped) => {
        if( this.state.step == 1 ) {
            this.saveLogo(skipped);
        }
        if( this.state.step == 3 ) {
            this.saveLookBack(skipped);
        }

        if( this.state.step == 4 ) {
            this.saveLookForward(skipped);
        }

        if( this.state.step <= 5 ) {
            let step = this.props.match.params.id;
            step++;
            this.props.history.push('/signUp/' + step);
            this.setState({
                step: step
            })
        }

        if( this.state.step == 5 ) {
            let payload = {
                sign_up_flow: true
            }
            this.props.updateAdvertiser(payload, this.props.advertiser);
        }
    }

    trackStepDec = () => {
        if(this.state.step > 1) {
            let step = this.props.match.params.id;
            step--;
            this.props.history.push('/signUp/' + step);
            this.setState({
                step: step
            })
        }
    }

    setLogo = (logo) => {
        this.setState({
            logo
        })
    }

    saveLogo = async (skipped) => {
        if(!skipped && this.state.logo){
            await saveImage(this.state.logo, this.props.advertiser.advertiser_id, 'profile', null)
                .then(result => {
                    let payload = {
                        avatar_image: result.url
                    }
                    this.props.updateAdvertiser(payload, this.props.advertiser);
                })
                .catch((error) => {
                    console.log("ERROR UPLOADING FILE", error);
                });
        }
    }


    getExcludedCustomers = () => {
        console.log('GETTING EXCLUDED CUSTOMERS', this.state.LTVOrders);
        const now = new Date();

        let seenEmails = [];
        let oneTimeCustomers = [];
        let repeatCustomers = [];

        if(this.state.LTVOrders.length > 0){
            for(let i = 0; i < this.state.LTVOrders.length; i++){
                const order = this.state.LTVOrders[i];

                if(order.customer && order.customer.id){
                    //if we haven't seen the customer id, assume it's one-time
                    if(!seenEmails.includes(order.customer.id)){
                        oneTimeCustomers.push(order.customer.id)
                        seenEmails.push(order.customer.id);
                    //if we've seen the id, that means they are repeat, so add one instance to the repeat list and remove it from the one-time list
                    }else{
                        if(!repeatCustomers.includes(order.customer.id)){
                            repeatCustomers.push(order.customer.id)
                            oneTimeCustomers.splice(oneTimeCustomers.indexOf(order.customer.id), 1);
                        }
                    }
                }
            }

            const lockedOut = {
                repeat: repeatCustomers.length,
                oneTime: oneTimeCustomers.length
            }

            this.setState({
                initialLockedOut: lockedOut,
            }, () => this.calculateLookBackCustomers(this.state.backSliderValue))
        }
    }

    //prep data set for projected calculations
    getOrderDataSet = () => {
        console.log('GETTING ORDER DATA SET', this.state.LTVOrders);
        if(this.state.LTVOrders.length > 0){
            let dataSet = this.state.salesData
            dataSet.totalOrders = this.state.LTVOrders.length;

            let customers = {}

            let orders = this.state.LTVOrders;
            for(let i=0; i < orders.length; i++){
                dataSet.totalSales += parseFloat(orders[i].total_price);
                if(orders[i].customer && orders[i].customer.id){
                    if(!customers[orders[i].customer.id]) customers[orders[i].customer.id] = [];
                    customers[orders[i].customer.id].push(orders[i].total_price);
                }
            }
            for(let id in customers){
                let totals = customers[id];
                if(totals.length === 1){
                    dataSet.totalNonRepeatSales+= parseFloat(totals[0])
                    dataSet.totalNonRepeatOrders++;
                    dataSet.totalNonRepeatCustomers++;
                }else{
                    const totals_sum = totals.reduce((prevSale, nextSale) => {
                        return parseFloat(nextSale) + parseFloat(prevSale)
                    });
                    dataSet.totalRepeatSales+= totals_sum
                    dataSet.totalRepeatOrders+= totals.length;
                    dataSet.totalRepeatCustomers++;
                }
            }
            this.setState({
                salesData: dataSet
            }, () => this.calculateLookForwardLTV(this.state.forwardSliderValue))
        }
    }

    //get a random customer's name and order date to use in the lookback example
    getExampleCustomer = () => {
        console.log('GETTING EXAMPLE CUSTOMER', this.state.LTVOrders);
        const randCustomerIndex = Math.floor(Math.random() * ((this.state.LTVOrders.length - 1) - 0 + 1)) + 0;
        if (!this.state.LTVOrders[randCustomerIndex] || !this.state.LTVOrders[randCustomerIndex].customer){
            return;
        }
        const randCustomerId = this.state.LTVOrders[randCustomerIndex].customer.id;
        const randCustomerOrders = this.state.LTVOrders.filter((order) => {
            return order.customer.id == randCustomerId
        })
        this.setState({
            lbExampleCustomer: {
                firstName: randCustomerOrders[0].customer.first_name,
                lastName: randCustomerOrders[0].customer.last_name,
                orderDate: randCustomerOrders[0].created_at
            }
        })
    }

    calculateLookForwardLTV = (months) => {
        const ltvNonRepeat = (this.state.salesData.totalNonRepeatCustomers > 0) ? (parseFloat((this.state.salesData.totalNonRepeatSales / this.state.salesData.totalNonRepeatCustomers / this.state.ltvMonths * months).toFixed(2))) : 0;
        const ltvRepeat = (this.state.salesData.totalRepeatCustomers > 0) ? (parseFloat((this.state.salesData.totalRepeatSales / this.state.salesData.totalRepeatCustomers / this.state.ltvMonths * months).toFixed(2))) : 0;

        let graphData = this.state.ltvGraphData;
        graphData[0]['Estimated Lifetime Value'] = ltvNonRepeat;
        graphData[1]['Estimated Lifetime Value'] = ltvRepeat;

        const avgOrderValue = {}
        avgOrderValue.all = (this.state.salesData.totalOrders > 0) ? '$'+numberFormat(this.state.salesData.totalSales / this.state.salesData.totalOrders / this.state.ltvMonths * months, 2) : '$0.00';
        avgOrderValue.one = (this.state.salesData.totalNonRepeatOrders > 0) ? '$'+numberFormat(this.state.salesData.totalNonRepeatSales / this.state.salesData.totalNonRepeatOrders / this.state.ltvMonths * months, 2) : '$0.00';
        avgOrderValue.repeat = (this.state.salesData.totalRepeatOrders > 0) ? '$'+numberFormat(this.state.salesData.totalRepeatSales / this.state.salesData.totalRepeatOrders / this.state.ltvMonths * months, 2) : '$0.00';

        const commissionStructs = [5, 10, 15, 20, 25];
        let commissions = []
        for(let i = 0; i < commissionStructs.length; i++){
            const rate = this.state.salesData.totalNonRepeatSales > 0 && this.state.salesData.totalNonRepeatCustomers > 0 
                ? this.state.salesData.totalNonRepeatSales / this.state.salesData.totalNonRepeatCustomers / this.state.ltvMonths * months * (commissionStructs[i] / 100) 
                : 0;
            const percent = commissionStructs[i];

            if(!commissions[percent]) commissions[percent] = {}
            commissions[percent].rate = '$'+numberFormat(rate, 2);

            commissions[percent].initialSale = this.state.salesData.totalNonRepeatOrders > 0 ? '$'+numberFormat(this.state.salesData.totalNonRepeatOrders / this.state.ltvMonths * months * rate, 2) : '$0.00';
           
            commissions[percent].longtermSale = this.state.salesData.totalRepeatOrders > 0 ? '$'+numberFormat(this.state.salesData.totalRepeatOrders / this.state.ltvMonths * months * rate, 2) : '$0.00';
        }

        this.setState({
            ltvGraphData: graphData,
            avgOrderValue: avgOrderValue,
            commissions: commissions
        })
    }

    //calculates the number of one time customers and repeat customers that will be locked out
    calculateLookBackCustomers = (months) => {
        let initialLocked = JSON.parse(JSON.stringify(this.state.initialLockedOut));
        const ltvMonths = this.state.ltvMonths;

        initialLocked.repeat = parseFloat((initialLocked.repeat / ltvMonths * months).toFixed(1))
        initialLocked.oneTime = parseFloat((initialLocked.oneTime / ltvMonths * months).toFixed(1));

        this.setState({
            lockedOut: initialLocked
        })
    }

    //changes value for forward slider
    handleForwardChange = ( forwardSliderValue ) => {
        this.setState({ forwardSliderValue });
        this.calculateLookForwardLTV(forwardSliderValue);
    };

    //changes value for backward slider
    handleBackChange = ( backSliderValue ) => {
        this.setState({ backSliderValue });
        this.calculateLookBackCustomers( backSliderValue );
    };



    saveLookForward = (skipped) => {
        let payload = {
            timeline_settings:{
                lookforward: {
                    time: this.state.forwardSliderValue
                }
            }
        }
        if(!skipped){
            payload.timeline_settings.lookforward.edited = new Date()
        }

        this.props.updateAdvertiser(payload, this.props.advertiser);

    };

    saveLookBack = (skipped) => {
        let payload = {
            timeline_settings:{
                lookback: {
                    time: this.state.backSliderValue
                }
            }
        }
        if(!skipped){
            payload.timeline_settings.lookback.edited = new Date()
        }

        this.props.updateAdvertiser(payload, this.props.advertiser);

    };

    navigateToOffers = () => {
        this.props.history.push('/offer-new');
    }

    downloadPSTemplate = () => {
        console.log("PS TYPE");
    }

    downloadAITemplate = () => {
        console.log("AI TYPE");
    }

    toggleHelpText = () => {
        this.setState({ displayHelpText: !this.state.displayHelpText });
    }

    closeTour = () => {
        this.setState({ isTourOpen: false });
        this.props.history.push('/offer-new');
    }

    openTour = () => {
        this.setState({ isTourOpen: true });
    }

    renderCurrentStep = () => {

        let choiceList = [
            {
                label: 'loading...',
                value: 'loading...',
            }
        ]

        if(this.state.choiceList.length > 1){
            choiceList = this.state.choiceList;
        }

        if(this.props.match.params.id === "1") {

            return (
                <div className="Onboarding__Card" data-test="onboarding-step-1">
                    <img className="Onboarding__Llama" src="https://s3.amazonaws.com/llama.creatives/llama-cut-out.png"/>
                    <h1 className="Onboarding__Header">Hey there! I&rsquo;m Leo the LTV Llama</h1>
                    <p className="Onboarding__Paragraph">I&rsquo;ll help you set up your first ambassador program. Ready to get started?</p>
                    <a className="Onboarding__MainCTA" data-test="lets-do-this" onClick={this.trackStepInc}>Let&rsquo;s do this</a>
                </div>
            )

        } else if(this.props.match.params.id === "2") {

            return (
                <div className="Onboarding__Card" data-test="onboarding-step-2">
                    <div className="Onboarding__StepTracker">
                        <img className="Onboarding__BackButton" src={arrow} onClick={this.trackStepDec}/>
                        Step 1 of 4
                    </div>
                    <img className="Onboarding__Llama" src="https://s3.amazonaws.com/llama.creatives/llama-cut-out.png"/>
                    <h1 className="Onboarding__Header">Make your presence known</h1>
                    <div className="Onboarding__TextWrapper">
                        <p className="Onboarding__Paragraph">
                            Upload a <span className="Onboarding__Paragraph Onboarding__Paragraph--bold">200px x 200px </span>profile image for your potential ambassadors to see. This image will appear next to your store’s name in search results and on your ambassador invite page.
                        </p>
                    </div>
                    <div className="Onboarding__DropZone">
                        <ImageDrop
                            buttonText="Add a Logo"
                            setImage={this.setLogo}
                            crop="square"
                            data-test="dropzone-component"
                        />
                    </div>
                    <div className="Onboarding__SubParGender-Wrapper">
                        <p className="Onboarding__SubParagraph">
                            Keep in mind this image should represent your store or ambassador program. It could be the same profile image you use for your social media accounts. Your store logo with a plain background would work best.
                        </p>
                    </div>
                    <a className="Onboarding__MainCTA" onClick={() => this.trackStepInc(false)}>Next</a>
                    <a className="hello-llama-skip" onClick={() => this.trackStepInc(true)}>Skip for now, I'll set this up later</a>

                    {/* TODO: Default banners to select from or make downloading template functional  */}
                    {/* <div className={["hello-llama-text-chat-wrapper", "template-side-right"].join(' ')} >
                        <div className="hello-llama-text-chat-container" >
                            <img className="hello-llama-text-chat-icon" src="https://s3.amazonaws.com/llama.creatives/llama-cut-out.png" />
                            <div className="hello-llama-text-chat-background" >
                                <p className="hello-llama-text-chat-message" > Not sure what to upload? Don't worry! You can download our Free template to help you get started. </p>
                            </div>
                        </div>
                    </div>

                    <div className={["step2-details"].join( ' ' )} >
                        <div className="hello-llama-details-title" > Download our Free Template </div>
                        <img className="step2-example-image" src="https://s3.amazonaws.com/llama.creatives/example.png"/>
                        <div className="template-download">
                            <div className="template-download-item" onClick={this.downloadAITemplate}>
                                <img  className="template-download-icon" src={aiIcon}/>
                                <a> Download Illustrator File </a>
                            </div>
                            <div className="template-download-item" onClick={this.downloadPSTemplate}>
                                <img  className="template-download-icon" src={psIcon}/>
                                <a> Download Photoshop File </a>
                            </div>
                        </div>
                    </div> */}
                </div>
            )

        } else if (this.props.match.params.id === "3") {
            const now = new Date();
            const thisMonth = now.getMonth();
            const thisYear = now.getFullYear();
            let nextMonthYear = now.getFullYear();
            let nextMonth = thisMonth + 1;
            if(nextMonth == 13){
                nextMonth = 1;
                nextMonthYear++;
            }

            const customerOrderDate = new Date(this.state.lbExampleCustomer.orderDate)
            const customerOrderMonth = customerOrderDate.getMonth();
            const customerOrderYear = customerOrderDate.getFullYear();
            let customerOrderAge = 0;
            if(thisMonth > customerOrderMonth){
                customerOrderAge = thisMonth - customerOrderMonth
            }else{
                customerOrderAge = thisMonth + 12 - customerOrderMonth;
            }

            const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

            return (
                <div className="Onboarding__Card" data-test="onboarding-step-3">
                    <div className="Onboarding__StepTracker">
                        <img className="Onboarding__BackButton" src={arrow} onClick={this.trackStepDec}/>
                        Step 2 of 4
                    </div>
                    <img className="Onboarding__Llama" src="https://s3.amazonaws.com/llama.creatives/llama-cut-out.png"/>
                    <h1 className="Onboarding__Header">Protect your existing customers from being referred</h1>
                    <div className="Onboarding__TextWrapper">
                        <p className="Onboarding__Paragraph Onboarding__Paragraph--left">
                        By setting a Look Back period, you can prevent ambassadors from earning commissions from your existing customers who’ve ordered within a certain amount of time. {this.state.displayHelpText === true ? <span onClick={this.toggleHelpText} className="Onboarding__Paragraph Onboarding__Paragraph--link">Show Less</span> : <span onClick={this.toggleHelpText} className="Onboarding__Paragraph Onboarding__Paragraph--link">Learn More</span>}
                        </p>

                        {this.state.displayHelpText === true &&
                            <div className="hello-llama-lookback-example">
                                <p>
                                    Look Back works by comparing an existing customer&rsquo;s last purchase date to the Look Back period you set to determine whether that customer is eligible for an ambassador to refer and earn commissions from.
                                </p>
                                <p>
                                    If that&rsquo;s a little confusing, it&rsquo;s okay. Let&rsquo;s use one of your customers as an example. {this.state.lbExampleCustomer.firstName + ' ' + this.state.lbExampleCustomer.lastName}&rsquo;s last purchase from your store was {customerOrderAge} month{customerOrderAge !== 1 && 's'} ago in {months[customerOrderMonth]} {customerOrderYear}. By setting your Look Back period to {customerOrderAge} month{customerOrderAge !== 1 && 's'}, you can prevent ambassadors from referring {this.state.lbExampleCustomer.firstName} and anyone else who&rsquo;s ordered in the past {customerOrderAge} month{customerOrderAge !== 1 && 's'}. In {months[nextMonth]} {nextMonthYear}, however, anyone who ordered in {months[customerOrderMonth]} {customerOrderYear} will become &ldquo;fair game&rdquo; (since their last purchase will have been {customerOrderAge + 1} months ago) so be sure to choose your Look Back period wisely.
                                </p>
                            </div>
                        }
                    </div>

                    {this.state.LTVOrdersLoaded ?
                        <figure className="settings-ltv__slider-wrap">
                            <figcaption>Set your Look Back period to <span>{this.state.backSliderValue} month{this.state.backSliderValue !== 1 && 's'}</span></figcaption>
                            <RangeSlider
                                className="settings-ltv__slider"
                                step="1"
                                min="1"
                                max="24"
                                value={this.state.backSliderValue}
                                onChange={this.handleBackChange}
                            />
                            <p className="start-end-labels">
                                <span className="start">1 month</span>
                                <span className="end">24 months</span>
                            </p>
                            <Banner>Based your current order history trends, this period could lock out approximately <strong>{numberFormat(this.state.lockedOut.oneTime, 1, false)} one-time customer{this.state.lockedOut.oneTime !== 1 && 's'}</strong> and <strong>{numberFormat(this.state.lockedOut.repeat, 1, false)} repeat customer{this.state.lockedOut.repeat !== 1 && 's'}</strong>.</Banner>
                        </figure>
                        :
                        <Spinner />
                    }

                    <div>
                        <div className="Onboarding__ChatContainer">
                            <img className="Onboarding__ChatIcon" src="https://s3.amazonaws.com/llama.creatives/llama-cut-out.png" />
                            <div className="Onboarding__ChatBg">
                                <p className="Onboarding__ChatMessage">WARNING: Your selection will be locked in for the next 30 days. You won&rsquo;t be able to change it during that time. </p>
                            </div>
                        </div>
                    </div>

                    <a className="Onboarding__MainCTA" onClick={() => this.trackStepInc(false)}>Next</a>
                </div>
            )

        } else if (this.props.match.params.id === "4") {

            const {valueLFS} = this.state;

            return (
                <div className="Onboarding__Card" data-test="onboarding-step-4">
                    <div className="Onboarding__StepTracker">
                        <img className="Onboarding__BackButton" src={arrow} onClick={this.trackStepDec}/>
                        Step 3 of 4
                    </div>
                        <img className="Onboarding__Llama" src="https://s3.amazonaws.com/llama.creatives/llama-cut-out.png"/>
                        <div className="Onboarding__Header">Set your Look Forward period</div>
                        <div className="Onboarding__TextWrapper">
                        <div className="Onboarding__Paragraph Onboarding__Paragraph--left">
                            <p className="Onboarding__lookforward-first">By setting a Look Forward period, you can define how long an ambassador can continue earning commissions from their referred customer. You can think of it as a &ldquo;cutoff&rdquo; date.</p>
                            <p>For example, if your ambassador refers a customer who makes a purchase on January 5 and your Look Forward period is set to 3 months, the ambassador will stop receiving commissions from that customer on April 6. <strong>The longer the Look Forward period, the more incentivized ambassadors will be to recruit and nurture loyal customers</strong>.</p></div>
                        </div>

                        {this.state.LTVOrdersLoaded ?
                            <figure className="settings-ltv__slider-wrap">
                                <figcaption>Set your Look Forward period to <span>{this.state.forwardSliderValue} month{this.state.forwardSliderValue !== 1 && 's'}</span></figcaption>
                                <RangeSlider
                                    className="settings-ltv__slider"
                                    step="1"
                                    min="1"
                                    max="24"
                                    value={this.state.forwardSliderValue}
                                    onChange={this.handleForwardChange}
                                />
                                <p className="start-end-labels">
                                    <span className="start">1 month</span>
                                    <span className="end">24 months</span>
                                </p>
                            </figure>
                            :
                            <Spinner />
                        }

                        <div className="Onboarding__ChatContainer">
                            <img className="Onboarding__ChatIcon" src="https://s3.amazonaws.com/llama.creatives/llama-cut-out.png" />
                            <div className="Onboarding__ChatBg">
                                <p className="Onboarding__ChatMessage">WARNING: Your selection will be locked in for the next 30 days. You won't be able to change it during that time.</p>
                            </div>
                        </div>

                        <a className="Onboarding__MainCTA" onClick={() => this.trackStepInc(false)}>Next</a>

                        <aside className="breakdown">
                            <h3>Look Forward Insights</h3>
                            {!this.state.LTVOrdersLoaded 
                                ? <LookForwardInsightsSkeleton 
                                    ltvGraphData={this.state.ltvGraphData} 
                                    forwardSliderValue={this.state.forwardSliderValue}
                                    ltvMonths={this.state.ltvMonths}
                                />
                                : <LookForwardInsights 
                                    ltvGraphData={this.state.ltvGraphData} 
                                    forwardSliderValue={this.state.forwardSliderValue}
                                    ltvMonths={this.state.ltvMonths}
                                    salesData={this.state.salesData}
                                    avgOrderValue={this.state.avgOrderValue}
                                    commissions={this.state.commissions}
                                />
                            }
                        </aside>
                </div>
            )

        } else if (this.props.match.params.id === "5") {

            return (
                <div className="Onboarding__Card" data-test="onboarding-step-5">
                    <div className="Onboarding__StepTracker">
                        <img className="Onboarding__BackButton" src={arrow} onClick={this.trackStepDec}/>
                        Step 4 of 4
                    </div>
                    <img className="Onboarding__Llama" src="https://s3.amazonaws.com/llama.creatives/llama-cut-out.png"/>
                    <h1 className="Onboarding__Header">Commission Payment Method</h1>
                    <p className="Onboarding__Paragraph">Connect your paypal account. Paid ambassadors are happy ambassadors!</p>
                    {this.state.paypalError && 
                    <Banner title="Oops, something went wrong" status="critical">
                        <p>
                            We weren&rsquo;t able to connect your PayPal account. It could just be an issue with PayPal. If you&rsquo;re still having trouble, you can try connecting it later in Settings.
                        </p>
                    </Banner>}
                    <div className="Onboarding__PaypalWrapper">
                        <Payment fromOnboarding={true} togglePayPalError={this.togglePayPalError} />
                    </div>
                    <a className="Onboarding__MainCTA" onClick={() => this.trackStepInc(false)}>Finish</a>
                    <a className="hello-llama-skip" onClick={() => this.trackStepInc(true)}> Skip for now, I'll set this up later </a>
                </div>
            )

        } else if (this.props.match.params.id === "6") {

            return (
                <div className="Onboarding__Card" data-test="onboarding-step-6">
                    <div className="Onboarding__StepTracker">
                        <img className="Onboarding__BackButton" src={arrow} onClick={this.trackStepDec}/>
                    </div>
                    <img className="Onboarding__Llama" src="https://s3.amazonaws.com/llama.creatives/llama-cut-out.png"/>
                    <h1 className="Onboarding__Header">Great! You&rsquo;re now ready to start using Llama</h1>
                    <p className="Onboarding__Paragraph--Stacked">If you're ready to get started, feel free to set up your first offer.</p>
                    <p className="Onboarding__Paragraph">Or if you'd prefer to take a better look around then take the Tour.</p>
                    {/* <p className="Onboarding__Paragraph">Time to set up your first offer.</p> */}
                    <div className="Onboarding__ButtonGroup">
                        <a className="Onboarding__MainCTA" onClick={this.navigateToOffers}>Add an offer</a>
                        <a className="Onboarding__SecondaryCTA" onClick={this.openTour}>Start Tour</a>
                    </div>
                </div>
            )

        } else {
            return
        }
    }

    render() {

        let steps = this.renderCurrentStep();
        const {showToast, showErrorToast} = this.state;
        const toastMarkup = showToast ? (<Toast duration={3000} className="toasty" content="Categories Saved!" onDismiss={this.toggleToast} />) : null;
        const toastErrorMarkup = showErrorToast ? (<Toast duration={3000} className="toasty" content="Please select at least one category!" onDismiss={this.toggleErrorToast} />) : null;

        return (
            <div>
                <Frame data-test="frame-container">
                    <ProgressBar data-test="progressbar-container" progress={(this.props.match.params.id  * 14.285)} size="small" className='progress_bar'/>
                    <Page data-test="page-container" separator={true}>
                        <Layout>
                            {steps}
                        </Layout>
                        <LlamaTour 
                            {...this.state}
                            {...this.props}
                            isTourOpen={this.state.isTourOpen}
                            closeTour={this.closeTour}
                        />
                    </Page>
                    <div className="toasty">
                        {toastMarkup}
                        {toastErrorMarkup}
                    </div>
                </Frame>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        advertiser: state.advertiser,
        ...state
    };
}

const mapDispatchtoProps = (dispatch) => {
    return bindActionCreators({
        getAdvertiser: getAdvertiser,
        getCategories: getCategories,
        getLTVOrders: getLTVOrders,
        getLTVOrdersFromShopify: getLTVOrdersFromShopify,
        updateAdvertiser: updateAdvertiser,
        uploadFileToS3: uploadFileToS3
    }, dispatch);
}

export default connect (mapStateToProps, mapDispatchtoProps)(Onboarding);