import React, { Component } from 'react';
import PropTypes, { string } from 'prop-types';
import { connect } from 'react-redux';
import { Frame, Toast } from '@shopify/polaris';
import Pixel from '../components/pixels/pixels';
import { getGlobalPixel, saveGlobalPixel } from '../actions/action-pixels';

import './styles/pixel-settings.css';

class PixelSettings extends Component {
    constructor(props) {
        super(props);

        this.state = {
            pixel: '',
            showToast: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.parseGlobalPixel = this.parseGlobalPixel.bind(this);
    }

    componentDidMount() {
        const { getPixel, advertiser: { advertiser_id } } = this.props;

        if (advertiser_id) {
            getPixel(advertiser_id)
                .then((data) => { return this.parseGlobalPixel(data.value); });
        }
    }

    handleChange(value) {
        this.setState({ pixel: value, changesMade: true });
    }

    handleSubmit() {
        const { savePixel, advertiser: { advertiser_id } } = this.props;
        const { pixel } = this.state;

        savePixel(advertiser_id, pixel)
            .then((data) => { return this.parseGlobalPixel(data.value); });
        this.setState({ showToast: true });
    }

    toggleToast = () => {
        this.setState(({ showToast }) => { return { showToast: !showToast }; });
    };

    parseGlobalPixel(globalPixel) {
        if (!globalPixel) {
            return;
        }

        const pixel = globalPixel.html;

        this.setState({ pixel });
    }

    render() {
        const { pixel, showToast } = this.state;
        const toastMarkup = showToast ? (
            <Toast className="toasty" content="Pixel Saved" onDismiss={this.toggleToast} />) : null;

        return (
            <div className="pixel-settings__wrapper">
                <Frame>
                    <Pixel
                        pixel={pixel}
                        handleChange={this.handleChange}
                        handleSubmit={this.handleSubmit}
                    />
                    {toastMarkup}
                </Frame>
            </div>
        );
    }
}

PixelSettings.propTypes = {
    getPixel: PropTypes.func.isRequired,
    savePixel: PropTypes.func.isRequired,
    advertiser: PropTypes.objectOf(string).isRequired
};

const mapStateToProps = (state) => {
    return { advertiser: { ...state.advertiser } };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getPixel: (id) => { return dispatch(getGlobalPixel(id)); },
        savePixel: (advertiserId, html) => { return dispatch(saveGlobalPixel(advertiserId, html)); }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PixelSettings);
