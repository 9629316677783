import React, { useState } from 'react';

import './slider.css';

const LlamaSlider = ({ range = [], onMinChange, onMaxChange, customMinValue, customMaxValue }) => {
    
    let minTick = customMinValue ? customMinValue : 0;
    let maxTick = customMaxValue ? customMaxValue : range.length - 1;

    const onMinSliderChange = (event) => {
        const { value } = event.target;
        if (parseInt(value) >= parseInt(maxTick)) return null;

        if (typeof onMinChange !== 'function') {
            console.warn("onMinChange is not a function.");
            return null;
        }
        onMinChange(value, range[value].value);
    }

    const onMaxSliderChange = (event) => {
        const { value } = event.target;
        if (parseInt(value) <= parseInt(minTick)) return null;

        if (typeof onMaxChange !== 'function') {
            console.warn("onMaxChange is not a function.");
            return null;
        }
        onMaxChange(value, range[value].value);
    }

    const minPercent = parseInt((100 / (range.length - 1)) * minTick);
    const maxPercent = parseInt((100 / (range.length - 1)) * maxTick);

    const minValue = customMinValue ? range[customMinValue].name : range[minTick].name;
    const maxValue = customMaxValue ? range[customMaxValue].name : range[maxTick].name;

    return (
        <React.Fragment>
            <div className="llama-slider">
                <div className="llama-slider__fill-wrapper">
                    <div className="llama-slider__background" />
                    <div 
                        className="llama-slider__fill" 
                        style={{ left: `${minPercent}%`, width: `${maxPercent - minPercent}%` }}
                    />
                </div>
                <input 
                    type="range" 
                    className="llama-slider__slider" 
                    step="1" 
                    min="0" 
                    max={range.length - 1} 
                    value={minTick} 
                    onChange={onMinSliderChange} 
                    list="sliderrange"
                />
                <input 
                    type="range" 
                    className="llama-slider__slider" 
                    step="1" 
                    min="0" 
                    max={range.length - 1} 
                    value={maxTick} 
                    onChange={onMaxSliderChange} 
                    list="sliderrange"
                />
                <datalist id="sliderrange">
                    {range.map((item, index) => <option key={item.value || index}>{index}</option>)}
                </datalist>
            </div>
            <div className="llama-slider__value-display">{minValue} - {maxValue}</div>
        </React.Fragment>
    )

}

export default LlamaSlider;