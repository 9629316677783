import React, { Component } from 'react';
import { connect } from 'react-redux';
import Invoices from '../components/invoices/invoices';
import { getInvoices } from '../actions/invoices';

// Filters to apply based on tab selection.
const invoiceTabFilter = {
    0: () => true,
    1: (item) => item.invoice && item.invoice.status !== 'PAID',
    2: (item) => item.invoice && item.invoice.status === 'PAID'
}

const ITEMS_PRE_PAGE = 10;

export class InvoicesContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            advertiser_id: props.advertiser.advertiser_id,
            loading: true,
            invoices: [],
            selectedTab: 0,
            currentPage: 0
        }

        this.nextPage = this.nextPage.bind(this);
        this.previousPage = this.previousPage.bind(this);

    }

    /**
     * @author Harshal Mahajan <Harshal.Mahajan@harbingergroup.com>
     * @function getDerivedStateFromProps
     * @description Check if new coming props have different advertiser_id compared to the previous state then update it with new one 
     * @param {Object} nextProps new props receving by component
     * @param {Object} prevState previous state of component
     * @returns {Object} null or updated state 
     */
    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.advertiser.advertiser_id && prevState && (nextProps.advertiser.advertiser_id !== prevState.advertiser_id)) {
            return {
                advertiser_id: nextProps.advertiser.advertiser_id
            };
        }
        return null;
    }

    componentDidMount() {
        if (this.state.advertiser_id) {
            getInvoices(this.state.advertiser_id)
                .then(this.parseInvoices);
        }
    }

    /**
     * @author Harshal Mahajan <Harshal.Mahajan@harbingergroup.com>
     * @function componentDidUpdate
     * @description Compare the current props's advertiser_id with previous props advertiser_id and process the getInvoices if any differences found
     * @param {Object} prevState previous state of component 
     */
    componentDidUpdate(prevProps) {
        if(prevProps.advertiser.advertiser_id !== this.props.advertiser.advertiser_id) {
            getInvoices(this.props.advertiser.advertiser_id)
            .then(this.parseInvoices);
        }
    }

    nextPage() {
        const newPageNumber = this.state.currentPage + 1;
        this.setState({ currentPage: newPageNumber });
    }

    previousPage() {
        if (this.state.currentPage === 0) { return };
        const newPageNumber = this.state.currentPage - 1;
        this.setState({ currentPage: newPageNumber });
    }

    handleTabChange = (selectedTab) => {
        this.setState({ selectedTab })
    }

    parseInvoices = (result) => {
        if (
            !result.data
            || !Array.isArray(result.data.advertisers)
            || result.data.advertisers.length === 0
            || !Array.isArray(result.data.advertisers[0].invoices)    
        ) {
            return;
        }

        const { invoices } = result.data.advertisers[0];

        if (!invoices || invoices.length === 0) {
            this.setState({ invoices: [], loading: false });
            return;
        }

        this.setState({ invoices, loading: false })
    }

    render() {

        let { invoices } = this.state;

        // Check that all items in the array have an invoice attached.  Then apply
        // the filter based on tab selection.
        invoices = invoices
            .filter((item) => item.invoice)
            .filter(invoiceTabFilter[this.state.selectedTab]);

        let hasNext = true;
        let hasPrevious = true;
        const { currentPage } = this.state;
        const totalMatches = invoices.length;
        const totalPages = Math.ceil(totalMatches / ITEMS_PRE_PAGE);

        if (totalMatches > ITEMS_PRE_PAGE) {
            const start = currentPage * ITEMS_PRE_PAGE;
            const end = (currentPage + 1) * ITEMS_PRE_PAGE;
            invoices = invoices.slice(start, end);
        }

        if (invoices.length === 0) {
            hasNext = false;
            hasPrevious = true;
        }

        if (ITEMS_PRE_PAGE * (currentPage + 1) >= totalMatches) {
            hasNext = false;
        }
    
        if (currentPage === 0) {
            hasPrevious = false;
        }

        const pagination = {
            nextPage: this.nextPage,
            previousPage: this.previousPage,
            hasNext,
            hasPrevious,
            currentPage,
            totalPages
        }

        return (
            <div data-test="container-invoices">
                <Invoices
                    { ...this.state }
                    { ...this.props }
                    invoices={invoices}
                    handleTabChange={this.handleTabChange}
                    pagination={pagination}
                />
            </div>
        )
    }

}

const mapStateToProps = (state) => {

    return {
        advertiser: { ...state.advertiser }
    }

}

export default connect(mapStateToProps)(InvoicesContainer);