import { useState, useEffect } from 'react';

import { getTransactionReport } from '../actions/get-transaction-report';

// TODO: Turn this into a factory function where the API call is passed in
// as a variables instead of being imported in.  This could allow us to use
// this hook on both the merchant and ambassador sides.

/**
 * @hook useTransactionReport
 * 
 * Retrieves a transaction report to display detailed order information.  Manages
 * the loading state, error state, and pagination information.
 * 
 * @param {String} userID - Advertiser or Affilaite ID for user
 * @param {Object} filters - Query object for API Call
 * @param {Number} ordersPerPage - Number of orders to get from API Call
 * 
 * @example
 * ```javascript
 *  const userID = '123';
 *  const ordersPerPage = 15;
 * 
 *  const now = new Date();
 *  now.setHours(12, 0, 0);
 *  const startDate = new Date('2019-01-01T12:00:00');
 * 
 *  const filters = {
 *      offer_id: '456',
 *      start_date: startDate.toISOString(),
 *      end_date: now.toISOString(),
 *  }
 * 
 *  const {
 *      pageInfo,
 *      handleOnNext,
 *      handleOnPervious,
 *      orders,
 *      isLoading,
 *      errorMessage,
 *  } = useTransactionReport(userID, filters, ordersPerPage);
 * ```
 */
const useTransactionReport = (userID, filters, ordersPerPage = 10) => {

    const defaultPageOptions = {
        skip: 0,
        limit: ordersPerPage,
        sortBy: null,
    }

    const [orders, setOrders] = useState(null);
    const [pageInfo, setPageInfo] = useState({});
    const [errorMessage, setErrorMessage] = useState(null);
    const [pageOptions, setPageOptions] = useState(defaultPageOptions);
    const [isLoading, setIsLoading] = useState(false);
    const [hasLoaded, setHasLoaded] = useState(false);

    const makeAPICall = (userID, filters, pageOptions) => {
        if (!isLoading && userID) {
            setIsLoading(true);
            getTransactionReport(userID, filters, pageOptions)
                .then(({ reports }) => {
                    setIsLoading(false);
                    if (!reports || !reports.orders || !Array.isArray(reports.orders.edges)) {
                        return null;
                    }

                    setOrders(reports.orders.edges);
                    setPageInfo(reports.orders.pageInfo);
                    setErrorMessage(null);
                    setHasLoaded(true);
                })
                .catch((error) => {
                    setIsLoading(false);
                    if (error.message = 'Network Error') {
                        setErrorMessage("OH NO! It looks likes something is wrong with your conneciton.")
                        return;
                    }

                    if (error.message) {
                        setErrorMessage(error.message);
                        return;
                    }

                    setErrorMessage('OH NO! Something went wrong.');
                })
        }
    }

    // Reset the skip amount on a filters change, this will bring the user
    // back to page 1 to prevent a bug where the user would remain on whatever
    // page they were on at the time of changing the filters, even if there 
    // as no data there.
    useEffect(() => { 

        // If the inital API call has not been completed yet, hasLoaded will
        // be false.  This will prevent from both useEffects from executing
        // when component mounts.   
        if (hasLoaded) {
            setPageOptions((state) => {
                const newPageOptions = { ...state, skip: 0 };
                makeAPICall(userID, filters, newPageOptions);
                return newPageOptions;
            })
        }

    }, [filters])

    // Makes inital API call.
    // Refetches when userID or pageOptions are changed.
    useEffect(() => {
        makeAPICall(userID, filters, pageOptions)
    }, [userID, pageOptions])

    const { pages } = pageInfo;

    const handleOnNext = (currentPage) => {
        const newPage = currentPage + 1;
        const skip = newPage * ordersPerPage;
        if (newPage === pages) {
            return null;
        }

        setPageOptions({ ...pageOptions, skip });
        setPageInfo({ 
            ...pageInfo, 
            hasNextPage: false, 
            hasPreviousPage: false, 
            currentPage: pageInfo.currentPage + 1
        })
    }

    const handleOnPervious = (currentPage) => {
        if (currentPage === 0) {
            return null;
        }
        const newPage = currentPage - 1;
        const skip = newPage * ordersPerPage;

        setPageOptions({ ...pageOptions, skip });
        setPageInfo({ 
            ...pageInfo, 
            hasNextPage: false, 
            hasPreviousPage: false, 
            currentPage: pageInfo.currentPage - 1
        })
    }

    return {
        pageInfo: { ...pageInfo, ordersPerPage },
        handleOnNext,
        handleOnPervious,
        orders,
        isLoading,
        errorMessage,
    }

}

export default useTransactionReport;