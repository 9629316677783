const productFragment = `
    fragment ProductData on Product {
        product_id
        status
        title
        created_at
        variants {
            id
            price
            inventory_quantity
        }
        reports {
            quantity
            volume
        }
        image{
            src
        }
    }
`

export default productFragment;