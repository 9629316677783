/* import axios from 'axios';
import { NEXT_APP_PAYPAL_AUTH_URL } from '../config';
import { NEXT_APP_PAYPAL_AUTH_URL_LOCAL } from '../config'; */
import {sendBackendRequest} from './sendRequest'

export const PAYPAL_URL_PENDING = 'PAYPAL_URL_PENDING';
export const PAYPAL_URL_FULFILLED = 'PAYPAL_URL_FULFILLED';
export const PAYPAL_URL_REJECTED = 'PAYPAL_URL_REJECTED';

export const getPaypalAuthUrl = () => {

    let payload = {"side": "MERCHANT"}

    return {
        type: 'PAYPAL_URL',
        payload: sendBackendRequest('merchant', 'getUrl', payload)
            .then(response => response.data)
    }

    /* let headers = {
        "Content-Type": 'application/json'
    }

    return {
        type: 'PAYPAL_URL',
        payload:axios.post(NEXT_APP_PAYPAL_AUTH_URL , payload, { headers })
        .then(response => response.data)
    } */
    
}
