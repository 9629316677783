import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Page } from '@shopify/polaris';

import calculateAffiliateReach from '../utils/affiliate-reach';
import { getApplicationsForAdvertiser } from '../actions/applications';
import Ambassadors from '../components/ambassadors';

/**
 * @enum Application Status
 * The available options for adding a status to an application.
 */
export const ApplicationStatus = {
    PENDING: 'PENDING',
    APPROVED: 'APPROVED',
    PAUSED: 'PAUSED',
    DENIED: 'DENIED',
    BLOCKED: 'BLOCKED'
}

const formatApplications = (item) => {
    return {
        ...item,
        offer: {
            ...item.offer,
            company: item.advertiser.company
        },
        created_at: new Date(item.created_at),
        reach: calculateAffiliateReach(item.affiliate)
    };
};

const useApplications = (advertiser_id) => {
    const [applications, setApplications] = useState(null);
    const [loading, setLoading] = useState(false);
    const [attempts, setAttempts] = useState(0);
    const [offers, setOffers] = useState([]);

    useEffect(() => {
        if (!applications && !loading && attempts < 3) {
            setLoading(true);
            setAttempts(attempts + 1);
            getApplicationsForAdvertiser(advertiser_id)
                .then((result) => {
                    console.log(result);
                    if (result.applications && Array.isArray(result.applications)) {
                        const formattedApplications = result.applications.map(formatApplications).reduce((acc, application) => {
                            if (application.affiliate && application.affiliate.name) {
                                acc.push(application);
                            }
                            return acc;
                        }, []);
                        setApplications(formattedApplications);
                    }
                    setOffers(result.offers);
                    setLoading(false);
                })
                .catch((error) => {
                    console.error(error);
                    setLoading(false);
                });
        }
    }, []);

    return [applications, setApplications, offers];
};

export const ApplicationsContainer = (props) => {
    const { 'custom:advertiser_id': advertiser_id } = props.authData && props.authData.attributes;
    const [applications, setApplications, offers] = useApplications(advertiser_id);

    if (!props.authData || !props.authData.attributes) return null;
    if (!advertiser_id) return null;


    const updateApplication = (newApplication) => {
        if (!Array.isArray(applications)) {
            return null;
        }

        const newApplications = applications
            .map((item) => {
                if (item.application_id === newApplication.application_id) {
                    return { ...item, ...newApplication }
                }

                return item;
            })

        setApplications(newApplications);
    }

    const refreshApplications = () => {
        setApplications(null);
    }

    return (
        <div style={{ maxWidth: "1440px", margin: 'auto' }} data-test="container-applications">
            <Page
                title="Applications"
                separator={true}
                fullWidth
            >
                <Ambassadors
                    applications={applications}
                    offers={offers}
                    updateApplication={updateApplication}
                    refreshApplications={refreshApplications}
                    {...props}
                />
            </Page>
        </div>
    )

}

const mapStateToProps = (state) => {
    return {
        authData: state.authData, 
    }
}

export default connect(mapStateToProps)(ApplicationsContainer)