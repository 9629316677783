import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Auth } from 'aws-amplify';

import { AuthPiece, InputRow } from 'aws-amplify-react';

import { Modal, Frame, Toast, InlineError, Banner } from '@shopify/polaris';

import { LlamaButton, PasswordRequirements, PasswordMatch } from 'llama-library/components';

import {getBrowser, getOS} from '../utils/user-agent';
import {sendPasswordChangeConfirmationEmail} from '../actions/sendPasswordChangeConfirmationEmail';
import { updateAdvertiser } from '../actions/update-advertiser';
import jwt from 'jsonwebtoken';

export class LlamaChangePassword extends AuthPiece {
    constructor(props) {
        super(props);

        this.state = {
            values: {
                password: '',
                oldPassword: '',
                confirmPassword: ''
            },
            error: '',
            changePasswordLoading: false,
            successMessage: '',
            passwordModal: false,
            showToast: false,
            toastVerbiage: '',
            oldPasswordError: ''
        }

        this.renderPasswordModal = this.renderPasswordModal.bind(this);
        this.handlePasswordModal = this.handlePasswordModal.bind(this);
        this.handleFieldChange = this.handleFieldChange.bind(this);
        this.onSubmitForm = this.onSubmitForm.bind(this);
        this.handleToast = this.handleToast.bind(this);
    }

    handleFieldChange(event) {
        this.setState({ successMessage: '' });
        const { name, value } = event.target;
        const newValues = { ...this.state.values };
        newValues[name] = value;

        this.setState({ values: newValues});
        this.handleInputChange(event);
    }

    onSubmitForm(event) {
        event.preventDefault();

        this.setState({ error: '', oldPasswordError: ''}, async () => {
            const { password, oldPassword, confirmPassword } = this.state.values;

            if(!oldPassword){
                this.setState({oldPasswordError: 'Current password is required'})
                return;
            }

            if(password !== confirmPassword) {
                const errorMessage = { 
                    message: "Your passwords must match"
                }
                this.setState({ error: errorMessage })
                return;
            }

            if(oldPassword === password){
                const errorMessage = { 
                    message: "Your new password must be different than your current password"
                }
                this.setState({ error: errorMessage })
                return;
            }

            this.setState({ changePasswordLoading: true });
            const currentUser = await Auth.currentAuthenticatedUser();

            const browser = getBrowser();
            const os = getOS();

            await Auth.changePassword(currentUser, oldPassword, password)
                .then((data) => {
                    console.log("DATA", data);
                    // update the primary_user_token only if primary user changing the password
                    if (this.props.authData.username && this.props.authData.username.includes('myshopify.com') && this.props.authData.username.endsWith('myshopify.com')) {
                        const advertiserData = {
                            primary_user_token: jwt.sign(password, process.env.REACT_APP_JWT_SECRET_CODE)
                        }
                        updateAdvertiser(advertiserData, this.props.advertiser);
                    }
                    sendPasswordChangeConfirmationEmail(this.props.advertiser.advertiser_id, currentUser.attributes.email, browser, os)
                    this.setState({ 
                        changePasswordLoading: false, 
                        successMessage: 'Your password has been changed!', 
                        error: '',
                        values: {
                            password: '',
                            oldPassword: '',
                            confirmPassword: ''
                        },
                        showToast: true,
                        toastVerbiage: 'Password changed',
                        passwordModal: false
                    });
                })
                .catch((err) => {
                    this.setState({ changePasswordLoading: false });
                    if(err.code === "NotAuthorizedException"){
                        this.setState({oldPasswordError: 'Current password is incorrect'})
                    }else if(err.code === "InvalidParameterException"){
                        const errMessages = err.message.split(';');
                        for(let i = 0; i < errMessages.length; i++){
                            if(errMessages[i].indexOf('proposedPassword')){
                                this.setState({ 
                                    error: {
                                        message: 'Your new password must satisfy all requirements'
                                    }
                                });
                            }

                            //if the old password is not formatted correctly, there's no way it can match what's on file, so tell the user the old password is incorrect
                            if(errMessages[i].indexOf('previousPassword')){
                                this.setState({oldPasswordError: 'Current password is incorrect'})
                                continue;
                            }
                        }
                    }else{
                        this.setState({ error: err });
                    }
                    console.log("ERROR:", err);
                })
        })
    }

    handlePasswordModal() {
        this.setState({ passwordModal: !this.state.passwordModal });
    }

    handleToast() {
        this.setState({ showToast: !this.state.showToast });
    }

    renderPasswordModal() {
        return (
            <div data-test="modal-changePassword">
                <Modal
                    title="Change your password"
                    open={this.state.passwordModal}
                    onClose={this.handlePasswordModal}
                >
                    <Modal.Section>
                        <div className="settings-general__password-modal">
                            {this.state.error && this.state.error.message && 
                                <div data-test="change-password-errors">
                                <Banner status="critical">{this.state.error.message}</Banner>
                                </div>
                            }
                            <InputRow
                                type='password'
                                onChange={this.handleFieldChange}
                                value={this.state.values.oldPassword}
                                placeholder='Current Password'
                                name='oldPassword'
                                key='oldPassword'
                                id="old-password"
                            />
                            <InlineError message={this.state.oldPasswordError} fieldId="old-password" />
                            <InputRow
                                type='password'
                                onChange={this.handleFieldChange}
                                value={this.state.values.password}
                                placeholder='New Password'
                                name="password"
                                key='password'
                            />
                            <PasswordRequirements password={this.state.values.password} />
                            <InputRow
                                type='password'
                                onChange={this.handleFieldChange}
                                value={this.state.values.confirmPassword}
                                placeholder='Confirm Password'
                                name="confirmPassword"
                                key='confirmPassword'
                                id="confirm-password"
                            />
                            <PasswordMatch password={this.state.values.password} confirm={this.state.values.confirmPassword} />
                            <div className="change-password__button-wrapper">
                                <LlamaButton
                                    onClick={this.onSubmitForm}
                                    loading={this.state.changePasswordLoading}
                                    data-test="button-submit"
                                >
                                    Change Password
                                </LlamaButton>
                            </div>
                        </div>
                    </Modal.Section>
                </Modal>
            </div>
        )
    }

    render() {

        const { showToast, toastVerbiage } = this.state;

        const toastMarkup = showToast ? <Toast content={toastVerbiage} onDismiss={this.handleToast}/> : null;

        return (
            <div data-test="component-changePassword">
                <div className="settings-general__edit">
                    <div className="settings-general__edit-password" data-test="button-changePassword" onClick={this.handlePasswordModal}>
                        Change Password
                    </div>
                    {this.renderPasswordModal()}
                </div>
                <Frame>{toastMarkup}</Frame>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        authData: state.authData,
    }
}

export default connect(mapStateToProps)(LlamaChangePassword);