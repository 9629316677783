import React from 'react';
import { Route, Switch } from 'react-router-dom';

import SettingsGeneral from '../settings-general/settings-general';
import PlanSettingsContainer from '../../containers/settings-plan';
import PaymentSettings from '../../containers/settings-payment';
import NotificationSettings from '../../containers/notifications-settings';
import PixelSettings from '../../containers/pixels-settings';
import SettingsLTV from '../../containers/settings-ltv';
import Terms from '../../containers/terms';

import LlamaTabs from '../llama/tabs';

const tabs = [
    {
        key: 'general',
        name: 'General',
        link: '/'
    },
    {
        key: 'plan',
        name: 'Plan',
        link: '/plan'
    },
    {
        key: 'payment',
        name: 'Payment',
        link: '/payment'
    },
    {
        key: 'notifications',
        name: 'Notifications',
        link: '/notifications'
    },
    {
        key: 'pixels',
        name: 'Pixels',
        link: '/pixels'
    },
    {
        key: 'ltv',
        name: 'LTV',
        link: '/ltv'
    },
    {
        key: 'terms',
        name: 'Offer Terms',
        link: '/terms'
    }
];

/**
 * @function SettingsNavigation
 * Accepts props Object and returns SettingsNavigation Component.
 * Displays tabs for Settings Components.
 * Holds Switch Component for Routes.
 *
 * @param {Object} props
 *
 * @returns {FunctionComponent}
 */
const SettingsNavigation = (props) => {
    return (
        <>
            <LlamaTabs tabs={tabs} location={props.location} linkPrefix="/settings" />

            <Switch>
                <Route path="/settings/terms" render={(renderProps) => <Terms { ...renderProps } { ...props } />} />
                <Route path="/settings/ltv" render={(renderProps) => <SettingsLTV { ...renderProps } { ...props } />} />
                <Route path="/settings/notifications" render={(renderProps) => <NotificationSettings { ...renderProps } { ...props } />} />
                <Route path="/settings/pixels" render={(renderProps) => <PixelSettings { ...renderProps } { ...props } />} />
                <Route path="/settings/payment/auth/" render={(renderProps) => <PaymentSettings { ...renderProps } { ...props } />} />
                <Route path="/settings/payment" render={(renderProps) => <PaymentSettings { ...renderProps } { ...props } />} />
                <Route path="/settings/plan" render={(renderProps) => <PlanSettingsContainer { ...renderProps } { ...props } />} />
                <Route path="/settings" render={(renderProps) => <SettingsGeneral { ...renderProps } { ...props } />} />
            </Switch>
        </>
    );
};

export default SettingsNavigation;
