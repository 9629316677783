import React from 'react';

import x from '../assets/x.svg';

export const Tag = ({ children, removeAction, background = '#5c6ac4' }) => {

    let mainStyle = {
        background,
        color: "#fff",
        fontSize: '1.4rem',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        padding: '2px 6px',
        borderRadius: '3px',

        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        margin: '0px 4px 4px 0px'
    }

    let xStyle = {
        height: '8px',
        width: '8px',
        opacity: '.4',
        marginLeft: '4px',
        cursor: 'pointer'
    }

    return (
        <div style={mainStyle}>
            {children}
            {removeAction &&<img style={xStyle} src={x} alt={`Remove ${children}`} onClick={() => removeAction(children)} />}
        </div>
    )

}