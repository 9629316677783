import { sendGraphQL } from '../sendRequest';

const getChatAffiliateInfo = ({ advertiser_id, affiliate_id }) => {
    const query = `
        query getChatAffiliateInfo($advertiser_id: String!, $affiliate_id: String!){
            getChatAffiliateInfo(advertiser_id: $advertiser_id, affiliate_id: $affiliate_id){
                photo
                name
                description
                id
                invitedOfferDetails{
                    offer_id
                    bannerImage
                    name
                    category
                }
                hasSocialMedia{
                    twitter
                    facebook
                    youtube
                    instagram
                    tiktok
                }
            }
        }
    `;

    const variables = {
        advertiser_id,
        affiliate_id
    };

    const payload = {
        query,
        variables
    };

    return sendGraphQL(payload)
        .then((response) => { return response.data; })
        .catch((err) => { console.error(err); });
};

export default getChatAffiliateInfo;
