import React, {useState} from "react";
import { Caption, DropZone, Stack, Thumbnail, Modal, Banner } from "@shopify/polaris";
import ImageDrop from '../llama/image-drop';
import formatFileSize from '../../utils/format-file-size';

/**
 * @function GeneralSettingsSideDrawer
 * Accepts the following params, returns GeneralSettingsSideDrawer Component.
 * Displays Polaris Stack of uploaded files.
 * Holds Polaris Modal for uploading new files.
 *  
 * @param {Function} handleFileUpload 
 * @param {Boolean} showSideDrawer 
 * @param {Function} setShowSideDrawer
 * @param {Array} files 
 * 
 * @returns {FunctionComponent}
 */

const GeneralSettingsSideDrawer = ({saveFile, showSideDrawer, setShowSideDrawer, uploading}) => {
    const [uploadedImage, setImage] = useState(null);

    const sideDrawerClosedHandler = () => setShowSideDrawer(null);

    return (
        <div>
            <Modal
                large
                open={showSideDrawer}
                onClose={sideDrawerClosedHandler}
                title="Upload Your Logo"
                primaryAction={{
                    content: 'Upload Logo',
                    onAction: () => saveFile(uploadedImage),
                    loading: uploading,
                    disabled: !uploadedImage
                }}
                secondaryActions={[
                    {
                        content: 'Cancel',
                        onAction: sideDrawerClosedHandler,
                    },
                ]}
            >
                <Modal.Section>
                    <p className="InviteSidedrawer__ModalText">Your logo will be cropped to a square and should be at least 220px x 220px.</p>
                </Modal.Section>
                <Modal.Section>
                    <ImageDrop
                        buttonText="Add a Logo"
                        crop="square"
                        setImage={setImage}
                    />
                </Modal.Section>
            </Modal>
        </div>
    )
};

export default GeneralSettingsSideDrawer;