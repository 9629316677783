const loadingReducer = (state = {}, action) => {

    let { type } = action;
    let matches = /(.*)_(PENDING|FULFILLED|REJECTED)/.exec(type);

    if (!matches) return state;

    const [, requestName, requestState] = matches;
    return { ...state, [requestName]: requestState === 'PENDING' };

}

export default loadingReducer;