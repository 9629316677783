import React from 'react';

import {
    Card,
    Heading,
    TextStyle,
    InlineError,
    ChoiceList
} from '@shopify/polaris';

import './campaign-products.css';
import SearchBar from '../search-bar/search-bar';

/**
 * @function CampaignProducts
 * Accepts props Object, returns CampaignProducts Component
 * which displays input checkboxes for the user to include
 * all products or some and give them the ability to specify
 * which products they wish to include in the campaign
 * @param {Object} props
 * @returns {Component}
 */
const CampaignProducts = (props) => {
    const { values, errors } = props;

    if (!values.hasOwnProperty('products')) {
        return null;
    }

    const defaultProducts = {
        includeAll: true,
        includeSome: false,
        excludeSome: false,
        includeProducts: [],
        excludeProducts: []
    };

    const toggleProduct = (target) => {
        return (id) => {
            let newArray = values.products[target].slice(0);
            let newProducts = Object.assign({}, values.products);

            if (newArray.includes(id)) {
                newArray = newArray.filter((item) => item !== id);
            } else {
                newArray = newArray.concat(id);
            }

            newProducts = { ...newProducts, [target]: newArray };
            console.log(newProducts);
            props.setFieldValue('products', newProducts);
        };
    };

    const toggleInclude = toggleProduct('includeProducts');

    const toggleAll = (productIds) => {
        const newProducts = {
            ...values.products,
            includeProducts: productIds
        };
        console.log('TOGGLE ALL', newProducts);
        props.setFieldValue('products', newProducts);
    };

    function displayIncludeItems() {
        if (values.products.includeSome) {
            // let products = props.products.map((item) => ({...item, type: 'product'}));
            let products = props.products.filter(item => /^active$/gi.test(item.status))
                .map((item) => ({ ...item, type: 'product' }));

            return (
                <SearchBar
                    data={products}
                    selected={values.products.includeProducts}
                    toggleItem={toggleInclude}
                    toggleAll={toggleAll}
                />
            );
        }
        return '';
    }

    function showProductSelectionError() {
        if (values.products.includeSome && errors.length > 0) {
            const productSelectionError = errors.find(({ field }) => field == 'products');
            if (productSelectionError) {
                const errorIndex = errors.indexOf(productSelectionError);
                return <InlineError message={errors[errorIndex].message} />
            }
        } else {
            return ''
        }
    }

    const productIncludeChoice = () => {
        if (values.products.includeAll) {
            return ['all'];
        }
        if (values.products.includeSome) {
            return ['some'];
        }
        return [];
    };

    const onProductIncludeChange = (value) => {
        if (value[0] === 'all') {
            props.setFieldValue('products', defaultProducts);
        }

        if (value[0] === 'some') {
            props.setFieldValue('products', {
                ...defaultProducts,
                includeAll: false,
                includeSome: true
            });
        }
    };

    return (
        <Card>
            <Card.Section>
                <div className="campaign-products__heading">
                    <Heading>OFFER CRITERIA</Heading>
                    {/* <TextStyle variation="subdued"><span className="subtitle">Select which products to include</span></TextStyle> */}
                </div>
            </Card.Section>
            <Card.Section>
                <div className="campaign-products__selection">
                    <ChoiceList
                        title="My offer includes:"
                        choices={[
                            { value: 'all', label: 'ALL products' },
                            { value: 'some', label: 'Specific products' }
                        ]}
                        selected={productIncludeChoice()}
                        onChange={onProductIncludeChange}
                    />
                    {displayIncludeItems()}
                    {values.products.includeSome && errors.length > 0 && values.products.includeProducts.length == 0 ? showProductSelectionError() : null}
                </div>
            </Card.Section>
        </Card>
    );
};

export default CampaignProducts;
