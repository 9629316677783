import { sendGraphQL } from './sendRequest';

const validateAutoCode = (shop, code) => {
    const query = `
        query validateAutoCode($shop: String!, $code: String!){
            validateAutoCode(shop: $shop, code: $code)
        }
    `;

    const variables = {
        shop,
        code
    };

    const payload = {
        query,
        variables
    };

    return sendGraphQL(payload)
        .then((response) => { return response.data; })
        .catch((err) => { console.error(err); });
};

export default validateAutoCode;
