import React, { Component } from 'react';
import { connect } from 'react-redux';
/* import axios from 'axios';
import { NEXT_APP_API_URL } from '../config'; */
import {sendGraphQL} from '../actions/sendRequest'

import SettingsTerms from '../components/settings-terms/settings-terms';


const updateAdvertiserTerms = (advertiser_id, terms) => {

    const query = `
        mutation updateAdvertiserTerms(
            $advertiser_id: String, 
            $data: UpdateAdvertiserInput
        ) {
            updateAdvertiser(advertiser_id: $advertiser_id, data: $data) {
                terms
            }
        }
    `

    const variables = {
        advertiser_id,
        data: {
            terms
        }
    }

    const payload = {
        query,
        variables
    }

    return sendGraphQL(payload)
        .then((response) => response.data.data.updateAdvertiser);

    /* let headers = {
        "Content-Type": 'application/json'
    }

    return axios.post(NEXT_APP_API_URL, payload, { headers })
        .then((response) => response.data.data.updateAdvertiser); */

}

const getAdvertiserTerms = (advertiser_id) => {

    const query = `
        query getAdvertiserTerms($advertiser_id: String) {
            advertisers(advertiser_id: $advertiser_id) {
                terms
            }
        }
    `

    const variables = {
        advertiser_id
    }

    const payload = {
        query,
        variables
    }

    return sendGraphQL(payload)
        .then((response) => response.data.data.advertisers[0])

    /* let headers = {
        "Content-Type": 'application/json'
    }

    return axios.post(NEXT_APP_API_URL, payload, { headers })
        .then((response) => response.data.data.advertisers[0]) */

}


class Terms extends Component {

    constructor(props) {
        super(props);

        this.state = {
            savedTerms: '',
            cachedAdvertiserId: props.advertiser.advertiser_id, 
            terms: null,
            changesMade: false,
            loading: false,
            recievedInitalData: false,
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.parseTerms = this.parseTerms.bind(this);

    }

    parseTerms(globalTerms) {
        if (!globalTerms) {
            return;
        }

        const terms = globalTerms.terms;

        this.setState({ 
            terms,
            savedTerms: terms, 
            changesMade: false, 
            loading: false,
            recievedInitalData: true,
        })
    }

    /**
     * @author Harshal Mahajan <Harshal.Mahajan@harbingergroup.com>
     * @function getDerivedStateFromProps
     * @description Check if new coming props have different advertiser_id compared to the previous state then update it with new one 
     * @param {Object} nextProps new props receving by component
     * @param {Object} prevState previous state of component
     * @returns {Object} null or updated state 
     */
    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.advertiser.advertiser_id && prevState && (nextProps.advertiser.advertiser_id !== prevState.cachedAdvertiserId)) {
            return {
                cachedAdvertiserId: nextProps.advertiser.advertiser_id
            };
        }
        return null;
    }

    componentDidMount() {
        if (this.props.advertiser.advertiser_id) {
            getAdvertiserTerms(this.props.advertiser.advertiser_id)
                .then(this.parseTerms);
        }
    }

    /**
     * @author Harshal Mahajan <Harshal.Mahajan@harbingergroup.com>
     * @function componentDidUpdate
     * @description Compare the current props's advertiser_id with previous props advertiser_id and process the getAdvertiserTerms if any differences found
     * @param {Object} prevState previous state of component 
     */
    componentDidUpdate(prevProps) {
        if(prevProps.advertiser.advertiser_id !== this.props.advertiser.advertiser_id) {
            getAdvertiserTerms(this.props.advertiser.advertiser_id)
                    .then(this.parseTerms);
        }
    }

    handleChange(value) {
        this.setState({ terms: value, changesMade: true });
    }

    handleSubmit() {
        this.setState({ loading: true })
        updateAdvertiserTerms(this.props.advertiser.advertiser_id, this.state.terms)
            .then(this.parseTerms)
    }

    render() {
        return (
        <div style={{ marginTop: '6rem' }}>
            <SettingsTerms 
                { ...this.props }
                { ...this.state }
                handleChange={this.handleChange}
                handleSubmit={this.handleSubmit}
            />
        </div>

        )
    }

}

const mapStateToProps = (state) => {

    return {
        advertiser: state.advertiser
    }

}

export default connect(mapStateToProps)(Terms);