/* import axios from 'axios';
import { NEXT_APP_API_URL } from '../config'; */
import {sendGraphQL} from './sendRequest'

import affiliateFragment from './fragments/affiliate.fragment';

export const getAffiliateCognito = (_id) => {

    const query = `
        query affiliates($_id: String) {
            affiliateByMongoId(_id: $_id){
                cognito_id
            }
        }
    `
    let variables = {
        _id
    }

    let payload = {
        query,
        variables
    };

    return sendGraphQL(payload)
        .then(response => response.data)

    /* let headers = {
        "Content-Type": 'application/json'
    };

    return axios.post(NEXT_APP_API_URL, payload, { headers })
        .then(response => response.data) */
}

// TODO: Get tracking data.
export const getAffiliate = (cognito_id, advertiser_id) => {

    if (!cognito_id) {
        return null;
    }

    const query = `
        query affiliates($cognito_id: String, $advertiser_id: String, $pullLatest: Boolean) {
            affiliateById(cognito_id: $cognito_id, advertiser_id: $advertiser_id) {
                cognito_id
                tracking_id
                name
                tags
                description
                photo
                invitedOffers
                reach
                youTubeData(pullLatest: $pullLatest) {
                    channelStats{
                        id
                        name
                        channelPhoto
                        views
                        subscribers
                        videos
                        avgViews
                        avgLikes
                        avgComments
                        score
                    }
                    channels{
                        id
                        description
                        kidsOnly
                        keywords
                    }
                    videos{
                        id
                        channelId
                        publishedAt
                        title
                        description
                        thumbnail
                        duration
                        statistics{
                            viewCount
                            likeCount
                            dislikeCount
                            commentCount
                        }
                    }
                }

                twitterData(pullLatest: $pullLatest) {
                    user {
                        id
                        name
                        screenName
                        followers
                        profilePicture
                        postCount
                        verified
                    }
                    statistics {
                        avgRetweets
                        avgFavorites
                    }
                    posts {
                        id
                        createdDate
                        content
                        links {
                            text
                            replace
                            link
                        }
                        media {
                            photo
                            videoSource
                            type
                        }
                        isRetweet
                        replyTo
                        retweetCount
                        favoriteCount
                        user {
                            photo
                            name
                            handle
                            verified
                        }
                    }
                    score
                    fromCache
                }
                
                instagramData {
                    instagram_id
                    username
                    name
                    profilePicture
                    statistics {
                        avgLikes
                        avgComments
                        followers
                        mediaCount
                    }
                    posts {
                        image {
                            url
                        }
                        videos {
                            url
                        }
                        created_time
                        caption
                        likes
                        location
                        link
                        id
                        comments
                    }
                }

                instagramDataV2 {
                    username
                    cognito_id
                    full_name
                    is_private
                    about
                    photo_url
                    posts_count
                    followers_count
                    followings_count
                    avg_likes
                    avg_comments
                    global_rank
                    aqs
                    aqs_name
                    aqs_description
                    account_type
                    account_verified
                    er {
                        value
                        avg
                        title
                    }
                    audience_interests {
                        name
                        value
                    }
                    demography_by_age{
                        gender
                        value
                        by_age_group {
                            group
                            value
                        }
                    }
                    audience_geography {
                        countries {
                            name
                            code
                            value
                        }
                        cities {
                            name
                            value
                        }
                        states {
                            name
                            value
                        }
                    }
                    advertising_data {
                        avg_ad_er
                        avg_er
                        avg_er_display
                        ad_posts{
                        count
                        display
                    }
                    all_posts{
                        count
                        display
                    }
                    brands_mentions_count
                    brands_categories
                    brands_categories_html
                    regular_posts{
                        count
                        display
                    }
                    }
                    likes_spread {
                        value
                        avg
                        title
                    }
                    likes_comments_ratio {
                        value
                        avg
                        title
                    }
                    audience_reachability {
                        value
                        avg
                        title
                    }
                    audience_languages{
                        code
                        value
                    }
                    audience_authenticity {
                        value
                        avg
                        title
                    }
                    audience_type {
                        real
                        susp
                        infs
                        mass
                    }
                    audience_ethnicity {
                        name
                        value
                    }
                    likes_comments_ratio_chart {
                        x
                        y
                    }
                    followers_chart {
                        date
                        count
                    }
                    following_chart {
                        date
                        count
                    }
                    demography {
                        gender
                        value
                    }
                }
                facebook
                facebookData(pullLatest: $pullLatest) {
                    pages {
                        id
                        name
                        profilePhoto
                        verification_status
                        category
                    }
                    page_ids
                    pageStats {
                        fan_count
                        id
                        avgShares
                        avgComments
                        avgReactions
                        score
                    }
                    postsStats {
                        id
                        posts {
                            id
                            created_time
                            message
                            message_tags {
                                id
                                name
                                type
                            }
                            full_picture
                            permalink_url
                            status_type
                            reactions {
                                summary {
                                    total_count
                                }
                            }
                            shares {
                                count
                            }
                            comments {
                                summary {
                                    total_count
                                }
                            }
                            attachments {
                                data {
                                    description
                                    description_tags {
                                        id
                                        name
                                        type
                                    }
                                    media {
                                        image {
                                            src
                                        }
                                        source
                                    }
                                    type
                                    url
                                    title
                                    subattachments {
                                        data {
                                            description
                                            description_tags {
                                                id
                                                name
                                                type
                                            }
                                            media {
                                                image {
                                                    src
                                                }
                                                source
                                            }
                                            type
                                            url
                                            title
                                        }
                                    }
                                }
                            }
                        }
                    }
                    fromCache
                }

                tikTokData {
                    _id
                    cognito_id
                    username
                    account_type
                    trial_expiration
                    aqs
                    aqs_name
                    account_verified
                    basic {
                      id
                      username
                      title
                      avatar_url
                      description
                    }
                    metrics {
                      subscribers_count {
                        value
                        performance {
                          last_30d {
                            value
                          }
                          last_90d {
                            value
                          }
                          last_180d {
                            value
                          }
                        }
                      }
                      views_avg {
                        value
                        performance {
                          last_30d {
                            value
                            min
                            max
                          }
                          last_90d {
                            value
                            min
                            max
                          }
                          last_180d {
                            value
                            min
                            max
                          }
                        }
                      }
                      subscribers_growth_prc {
                        value
                        performance {
                          last_30d {
                            value
                            mark
                            similar
                          }
                          last_90d {
                            value
                            mark
                            similar
                          }
                          last_180d {
                            value
                            mark
                            similar
                          }
                        }
                      }
                      er {
                        value
                        performance {
                          last_30d {
                            value
                            mark
                            similar
                          }
                          last_90d {
                            value
                            mark
                            similar
                          }
                          last_180d {
                            value
                            mark
                            similar
                          }
                        }
                      }
                      alikes_avg {
                        value
                        performance {
                          last_30d {
                            value
                            min
                            max
                          }
                          last_90d {
                            value
                            min
                            max
                          }
                          last_180d {
                            value
                            min
                            max
                          }
                        }
                      }
                      comments_avg {
                        value
                        performance {
                          last_30d {
                            value
                            min
                            max
                          }
                          last_90d {
                            value
                            min
                            max
                          }
                          last_180d {
                            value
                            min
                            max
                          }
                        }
                      }
                      shares_avg {
                        value
                        performance {
                          last_30d {
                            value
                            min
                            max
                          }
                          last_90d {
                            value
                            min
                            max
                          }
                          last_180d {
                            value
                            min
                            max
                          }
                        }
                      }
                      comments_likes_ratio {
                        value
                        performance {
                          last_30d {
                            value
                            mark
                            similar
                          }
                          last_90d {
                            value
                            mark
                            similar
                          }
                          last_180d {
                            value
                            mark
                            similar
                          }
                        }
                      }
                      post_frequency {
                        value
                        performance {
                          last_30d {
                            value
                            mark
                            similar
                          }
                          last_90d {
                            value
                            mark
                            similar
                          }
                          last_180d {
                            value
                            mark
                            similar
                          }
                        }
                      }
                    }
                    features {
                      audience_age_gender {
                        data {
                          age13_17 {
                            male
                            female
                          }
                          age18_24 {
                            male
                            female
                          }
                          age25_34 {
                            male
                            female
                          }
                          age45_54 {
                            male
                            female
                          }
                          age55_64 {
                            male
                            female
                          }
                          age65 {
                            male
                            female
                          }
                        }
                      }
                      audience_geo {
                        data {
                          countries {
                            id
                            code
                            prc
                            name
                          }
                        }
                      }
                    }
                  }
                account {
                    bio
                    categories
                    signUpDate
                    audience_gender
                    audience_country
                    audience_age_range
                }

                tracking {
                    Stat {
                        revenue
                        epc
                        clicks
                        offer_id
                        conversions
                    }
                }
            }
            advertiserById(advertiser_id: $advertiser_id) {
                name
                applications(
                    filter: { affiliate_status: APPROVED, advertiser_status: APPROVED },
                    affiliate_id: $cognito_id
                ) {
                    affiliate_status
                    advertiser_status
                    offer {
                        offer_id
                        name
                    }
                    affiliate_id
                    affiliate {
                        affiliate_id
                        name
                        tracking_id
                        cognito_id
                    }
                }
            }
        }
    `;

    const variables = {
        cognito_id,
        advertiser_id,
        pullLatest: true
    };

    const payload = {
        query,
        variables
    };

    return sendGraphQL(payload)
        .then(response => response.data)

    /* let headers = {
        "Content-Type": 'application/json'
    }

    return axios.post(NEXT_APP_API_URL, payload, { headers })
        .then(response => response.data) */

}
