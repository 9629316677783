import React, { Component } from 'react';
import {
    Card,
    Select,
    TextField,
    Heading,
    TextStyle,
    InlineError,
    RadioButton
} from '@shopify/polaris';

import './campaign-rates.css';
import { RateTypes } from '../../utils/types';
import {moneyFormat} from '../../utils/number-format';

/**
 * @class RateSelection
 * Holds state of previousType, percentValue, flatRateValue, flatRateSelect and verbiage.
 * Gives user control of setting the rate type (flat rate or percentage) on a campaign.
 * 
 * @returns {ComponentClass}
 */
class RateSelection extends Component {

    constructor(props) {
        super(props);

        this.state = {
            previousType: props.values[this.props.field].type,
            percentValue: null,
            flatRateValue: null,
            flatRateSelect: null,
            verbiage: null,
        }

        this.changeAmount = this.changeRate('amount');
        this.changeType = this.changeRate('type');
    }

    getPreviousValue(value) {
        if (value === this.state.perviousType) {
            return '';
        }

        const previousRate = Object.assign({}, this.props.values[this.props.field]);

        if (value === RateTypes.PERCENT_REVENUE && this.state.percentValue !== null) {
            previousRate.amount = this.state.percentValue;
        }

        if (value === RateTypes.FLAT_ORDER || value === RateTypes.FLAT_ITEM) {
            if (this.state.previousType === RateTypes.FLAT_ORDER || this.state.previousType === RateTypes.FLAT_ITEM) {
                return previousRate;
            }

            if (this.state.flatRateSelect !== null) {
                previousRate.type = this.state.flatRateSelect;
            }

            if (this.state.flatRateValue !== null) {
                previousRate.amount = this.state.flatRateValue;
            }
        }

        return previousRate;
    }

    selectRate = (value) => {
        this.changeAmount(value);
    }

    changeCookieDays = (value) => {
        this.props.setFieldValue('cookie_days', value);
    }

    selectType = (value) => {
        let previousRate = this.getPreviousValue(value);
        let newRate = Object.assign({}, previousRate, { type: value })

        this.cacheCurrentValue(value);
        this.props.setFieldValue(this.props.field, newRate);
    }

    changeRate = (field) => {
        return (value) => {
            if (
                field === 'amount'
                && this.props.values[this.props.field].type === RateTypes.PERCENT_REVENUE
                && (value < 0 || value > 999)
            ) {
                return;
            }

            let fieldType = '';
            switch (this.props.values[this.props.field].type) {
                case RateTypes.PERCENT_REVENUE:
                    fieldType = 'percent';
                    break;
                case RateTypes.FLAT_ORDER:
                case RateTypes.FLAT_ITEM:
                    fieldType = 'flat';
                    break;
                default:
                    break;
            }

            const { errors } = this.props;

            if (errors.length > 0) {
                const fieldError = errors.find(({ field }) => {
                    return field === `${this.props.field}_${fieldType}`;
                });
                if (fieldError !== undefined) {
                    errors = errors.splice(errors.indexOf(fieldError), 1);
                }
            }

            const newRate = Object.assign({}, this.props.values[this.props.field], { [field]: value });
            this.props.setFieldValue(this.props.field, newRate);
        };
    }

    cacheCurrentValue = (value) => {
        let percentValue = document.querySelector('#percent-value');
        percentValue = percentValue ? percentValue.value : this.state.percentValue;

        let flatRateSelect = document.querySelector('#flat-rate-select');
        flatRateSelect = flatRateSelect ? flatRateSelect.value : this.state.flatRateSelect;

        let flatRateValue = document.querySelector('#flat-rate-value');
        flatRateValue = flatRateValue ? flatRateValue.value : this.state.flatRateValue;

        this.setState({
            ...this.state,
            previousType: value,
            flatRateSelect,
            flatRateValue,
            percentValue
        });
    }

    renderSummary = (longterm) => {
        const { type, amount } = this.props.values[this.props.field];
        let verbiage;
        let formattedAmount;

        const startOfVerbiage = 'Ambassadors will receive ';

        switch (type) {
            case RateTypes.FLAT_ITEM:
                formattedAmount = moneyFormat(amount, true);
                verbiage = <TextStyle> per item when their referred customer completes their</TextStyle>;
                break;
            case RateTypes.FLAT_ORDER:
                formattedAmount = moneyFormat(amount, true);
                verbiage = <TextStyle> when their referred customer completes their</TextStyle>;
                break;
            case RateTypes.PERCENT_REVENUE:
            default:
                formattedAmount = `${amount}%`
                verbiage = <TextStyle> commission from their referred customer&rsquo;s</TextStyle>;
                break;
        }

        const purchaseType = longterm
            ? <TextStyle variation="strong"> future purchases.</TextStyle>
            : <TextStyle variation="strong"> first purchase.</TextStyle>;

        return (
            <div className="head_container">
                <TextStyle>{startOfVerbiage}</TextStyle><TextStyle variation="strong">{formattedAmount}</TextStyle>{verbiage}{purchaseType}
            </div>
        );
    }

    setError = (fieldType) => {
        if (this.props.errors.length > 0) {
            const commissionError = this.props.errors.find(({ field }) => {
                return field === `${this.props.field}_${fieldType}`;
            });
            if (commissionError && commissionError.message) {
                return commissionError.message;
            }
        }
        return '';
    }

    renderFlatRate = () => {
        const { type, amount } = this.props.values[this.props.field];

        if (type !== RateTypes.FLAT_ITEM && type !== RateTypes.FLAT_ORDER) {
            return '';
        }

        return (
            <div className="selection_container flat">
                <TextField
                    type="number"
                    value={amount.toString()}
                    onChange={this.selectRate}
                    id="flat-rate-value"
                    prefix="$"
                    step=".01"
                    error={this.setError('flat')}
                />
                <span className="per">per</span>
                <Select
                    onChange={this.selectType}
                    options={[
                        {
                            label: 'Order',
                            value: RateTypes.FLAT_ORDER,
                        },
                        {
                            label: 'Item',
                            value: RateTypes.FLAT_ITEM,
                        }
                    ]}
                    placeholder="Select"
                    value={type}
                    id='flat-rate-select'
                />
                <InlineError message={this.setError('flat')} />
            </div>
        );
    }

    renderPercentRate = () => {
        const { type, amount } = this.props.values[this.props.field];

        if (type !== RateTypes.PERCENT_REVENUE) {
            return '';
        }

        return (
            <div className="selection_container percent">
                <TextField
                    type="number"
                    value={amount.toString()}
                    suffix="%"
                    max="999"
                    onChange={this.selectRate}
                    id="percent-value"
                    error={this.setError('percent')}
                />
            </div>
        );
    }

    render() {
        const { type } = this.props.values[this.props.field];

        return (
            <Card.Section>
                <Heading>{this.props.heading}</Heading>
                {this.renderSummary(this.props.field === 'longterm_rate')}

                <ul className="campaign-rates__selection">
                    <li>
                        <RadioButton
                            label="Percentage"
                            helpText="Pay ambassadors a percentage of their customer&rsquo;s order total."
                            value="percent"
                            checked={type === RateTypes.PERCENT_REVENUE}
                            onChange={() => { this.selectType(RateTypes.PERCENT_REVENUE); }}
                        />
                        {this.renderPercentRate()}
                    </li>
                    <li>
                        <RadioButton
                            label="Flat Rate"
                            helpText="Pay ambassadors a flat rate amount for their customer&rsquo;s order total or for each item."
                            value="order"
                            checked={type === RateTypes.FLAT_ORDER || type === RateTypes.FLAT_ITEM}
                            onChange={() => { this.selectType(this.state.flatRateSelect || RateTypes.FLAT_ORDER); }}
                        />
                        {this.renderFlatRate()}
                    </li>
                </ul>
            </Card.Section>
        );
    }
}

const CampaignRates = (props) => {
    return (
        <Card>
            <Card.Section>
                <div className="campaign-rates__heading">
                    <Heading>COMMISSION STRUCTURE</Heading>
                </div>
            </Card.Section>
            <RateSelection { ...props } field="rate" heading="INITIAL RATE" />
            <RateSelection { ...props } field="longterm_rate" heading="LONGTERM RATE" />
        </Card>
    );
};

export default CampaignRates;
