/* import axios from 'axios';
import {NEXT_APP_API_URL} from '../config'; */
import {sendGraphQL} from './sendRequest'

export const GET_CATEGORIES_PENDING = 'GET_CATEGORIES_PENDING';
export const GET_CATEGORIES_FULFILLED = 'GET_CATEGORIES_FULFILLED';
export const GET_CATEGORIES_REJECTED = 'GET_CATEGORIES_REJECTED';

export const getCategories = () => {
    const query = `
        query {
            app {
              categories {
                name
                value
              }
            }
          }
        `
        const variables = {

        }

        let payload = {
            query,
            variables
        }

        return {
          type: 'GET_CATEGORIES',
          payload: sendGraphQL(payload)
            .then(response => response.data)
      }

        /* let headers = {
            "Content-Type": 'application/json'
        }

        return {
            type: 'GET_CATEGORIES',
            payload:axios.post(NEXT_APP_API_URL, payload, { headers })
            .then(response => response.data)
        } */
}