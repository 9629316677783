import { sendGraphQL } from './sendRequest';

const GET_PIXEL = 'GET_PIXEL';
const SAVE_PIXEL = 'SAVE_PIXEL';

const getGlobalPixel = (advertiserId) => {
    const query = `
        query getGlobalPixel($advertiserId: String) {
            advertiserById(advertiser_id: $advertiserId) {
                globalPixel {
                    pixel_id
                    advertiser_id
                    html
                    global
                    offers
                    tracking_id
                }
            }
        }
    `;

    const variables = { advertiserId };

    const payload = {
        query,
        variables
    };

    return {
        type: GET_PIXEL,
        payload: sendGraphQL(payload)
            .then((response) => {
                return response.data.data.advertiserById.globalPixel;
            })
    };
};

const saveGlobalPixel = (advertiserId, html) => {
    const query = `
        mutation pixelCreate($data: AffiliatePixelInput) {
            createPixel(data: $data) {
                pixel_id
                html
                global
            }
        }
    `;

    const variables = {
        data: {
            advertiser_id: advertiserId,
            global: true,
            html
        }
    };

    const payload = {
        query,
        variables
    };

    return {
        type: SAVE_PIXEL,
        payload: sendGraphQL(payload)
            .then((response) => {
                return response.data.data.createPixel;
            })
    };
};

export {
    getGlobalPixel,
    saveGlobalPixel
};
