import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import './campaign-name.css';
import {
    TextField,
    Card,
    Select,
    Checkbox,
    ChoiceList
} from '@shopify/polaris';
import ImageDrop from '../llama/image-drop';

const MAX_DESCRIPTION_LENGTH = 1500;
const MAX_NAME_LENGTH = 100;

/**
 * @function CampaignName
 * Accepts props object, returns CampaignName Component.
 * Displays input fields for the user to create a new campaign (name, category,
 * description, tags and preview url).
 *
 * @param {Object} props
 *
 * @returns {FunctionComponent}
 */
const CampaignName = (props) => {
    const { values, categories, advertiser, edit } = props;
    let { errors } = props;

    const [descriptionLength, setDescriptionLength] = useState(0);
    const [nameLength, setNameLength] = useState(0);
    const [banner, setBanner] = useState(values.banner);

    // add the banner image to the formik values array when uploaded
    useEffect(() => {
        props.setFieldValue('banner', banner, true);
    }, [banner]);

    const handleChange = (value, id) => {
        const field = id.split('-').pop();
        props.setFieldValue(field, value, true);

        if (errors.length > 0) {
            const fieldError = errors.find((error) => {
                return error.field === field;
            });
            if (fieldError !== undefined) {
                errors = errors.splice(errors.indexOf(fieldError), 1);
            }
        }
    };

    const handleNameChange = (value, id) => {
        if (value.length > MAX_NAME_LENGTH) {
            return;
        }
        setNameLength(value.length);
        handleChange(value, id);
    };

    const handleDescriptionChange = (value, id) => {
        if (value.length > MAX_DESCRIPTION_LENGTH) {
            return;
        }
        setDescriptionLength(value.length);
        handleChange(value, id);
    };

    const handleUrlChange = (value, id) => {
        if (!value || value === '') {
            console.log('NONE');
            handleChange(value, id);
            return;
        }
        const url = value.replace(/https?:\/\//, '').replace(/\s/g, '');
        handleChange(`https://${url}`, id);
    };

    const handleTypeChange = (value, id) => {
        console.log(value, id)
        handleChange(value[0], id);
    };

    const handleTags = (value) => {
        const newArray = value
            .replace(/, /g, ',')
            .split(',');

        const lastCharacter = value.substr(value.length - 1);
        if (newArray.length > 10 && lastCharacter !== ',') {
            return;
        }

        props.setFieldValue('tags', newArray);
    };

    const tags = () => {
        return values.tags.join(', ').replace(/,\s$/, ',');
    };

    const setUrl = () => {
        if (values.preview_url.length > 0 && values.preview_url !== '...') {
            return values.preview_url.replace('https://', '');
        }
        if (values.preview_url === '...' && typeof (advertiser.domain) !== 'undefined') {
            return `${advertiser.domain}/`;
        }
        return '';
    };

    const setError = (fieldName) => {
        if (errors.length > 0) {
            const errorObj = errors.find((error) => {
                return error.field === fieldName;
            });
            return (errorObj !== undefined && errorObj.message !== undefined)
                ? errorObj.message
                : null;
        }
        return null;
    };

    const descriptionSuffix = descriptionLength > (MAX_DESCRIPTION_LENGTH - 100)
        ? MAX_DESCRIPTION_LENGTH - descriptionLength
        : null;

    const nameSuffix = nameLength > (MAX_NAME_LENGTH - 15)
        ? MAX_NAME_LENGTH - nameLength
        : null;

    const bannerImage = (values.banner && values.banner.url)
        ? values.banner.url
        : values.bannerImage;

    const urlHelpText = (values.offer_type === 'product_giveaway')
        ? 'This is where your ambassadors will go to choose their free products.'
        : <>This is where potential customers will land when they click your ambassadors&rsquo; referral links.</>;

    return (
        <>
            <Card>
                {console.log("propes::::", props)}
                <Card.Section>
                    <div className="form-header">
                        <div className="campaign-name__heading">
                            <h2 className="Polaris-Heading" data-test="offer-options-header">Offer Options</h2>
                        </div>
                    </div>
                </Card.Section>
                <Card.Section>
                    <div className="campaign-name__input-field">
                        <Checkbox
                            label="Private offer"
                            id="offer-private_offer"
                            checked={values.private_offer}
                            onChange={handleChange}
                            helpText="Private offers won&rsquo;t show up in search results. Ambassadors can only promote a private offer if you invite them yourself."
                        />
                    </div>
                </Card.Section>
            </Card>
            <Card data-test="offer-summary">
                <Card.Section>
                    <div className="form-header">
                        <div className="campaign-name__heading">
                            <h2 className="Polaris-Heading" data-test="offer-summary-header">Offer Summary</h2>
                        </div>
                    </div>
                </Card.Section>
                <Card.Section>
                    <div className="campaign-name__input-field">
                        <ChoiceList
                            title="Type"
                            id="offer-offer_type"
                            selected={[values.offer_type]}
                            choices={[
                                { label: 'Commission-Based', value: 'commission' },
                                { label: 'Product Giveaway', value: 'product_giveaway' }
                            ]}
                            onChange={(value) => { handleTypeChange(value, 'offer-offer_type'); }}
                            disabled={edit}
                        />
                    </div>
                    <div className="campaign-name__input-field">
                        <TextField
                            label="Name"
                            id="offer-name"
                            value={values.name}
                            onChange={handleNameChange}
                            // TextField.suffix as 0 would be read as false and would not display,
                            // so we force it to be a string here.
                            suffix={nameSuffix === 0 ? '0' : nameSuffix}
                            error={setError('name')}
                            helpText={`Ambassadors will see this name in their ${(!values.private_offer) ? 'search results and' : ''} invited offers list.`}
                        />
                    </div>
                    <div className="campaign-name__input-field">
                        <ImageDrop
                            setImage={setBanner}
                            buttonText="Add a Banner Image"
                            currentImage={bannerImage}
                            label="Banner"
                        />
                        <p className="Polaris-Labelled__HelpText">A banner is optional, but it can help pique an ambassador&rsquo;s interest in your offer. For best results, upload an image that&rsquo;s at least 800px by 400px.</p>
                    </div>
                    {categories && categories.length > 0 && (
                        <div className="campaign-name__input-field">
                            <Select
                                onChange={handleChange}
                                id="offer-category"
                                options={categories.map((item) => {
                                    return { label: item.name, value: item.name };
                                })}
                                placeholder="Select Category"
                                value={values.category}
                                label="Category"
                                error={setError('category')}
                                helpText="Categorizing your offer helps target more relevant ambassadors."
                            />
                        </div>
                    )}
                    <div className="campaign-name__input-field">
                        <TextField
                            label="Description"
                            id="offer-description"
                            value={values.description}
                            onChange={handleDescriptionChange}
                            multiline
                            placeholder={values.offer_type === 'product_giveaway'
                                ? 'Enter Description'
                                : 'Earn 10% commission from every customer you refer.'
                            }
                            // TextField.suffix as 0 would be read as false and would not display,
                            // so we force it to be a string here.
                            suffix={descriptionSuffix?.toString()}
                            helpText={`Explain your offer to ambassadors. You can include things like ${values.offer_type !== 'product_giveaway' ? 'commission rates,' : ''} product descriptions, coupon codes, incentives, etc.`}
                        />
                    </div>
                    <div className="campaign-name__input-field">
                        <TextField
                            label="Tags"
                            id="offer-tags"
                            value={tags()}
                            onChange={handleTags}
                            multiline
                            placeholder="Books, Music, etc."
                            helpText="Tags help ambassadors find your offer more easily when they search by keyword. Separate multiple tags with a comma. Limit 10."
                        />
                    </div>
                    <div className="campaign-name__input-field">
                        <TextField
                            label="Destination URL"
                            id="offer-preview_url"
                            value={setUrl()}
                            onChange={handleUrlChange}
                            prefix="https://"
                            error={setError('preview_url')}
                            helpText={urlHelpText}
                        />
                    </div>
                </Card.Section>
            </Card>
        </>
    );
};

CampaignName.propTypes = {
    values: PropTypes.shape({
        description: PropTypes.string.isRequired,
        tags: PropTypes.arrayOf(PropTypes.string).isRequired,
        name: PropTypes.string.isRequired,
        preview_url: PropTypes.string.isRequired,
        category: PropTypes.string.isRequired,
        banner: PropTypes.shape({
            url: PropTypes.string.isRequired
        }),
        bannerImage: PropTypes.string,
        private_offer: PropTypes.bool,
        offer_type: PropTypes.string
    }).isRequired,

    categories: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired
    })).isRequired,

    advertiser: PropTypes.shape({
        domain: PropTypes.string.isRequired
    }).isRequired,

    errors: PropTypes.arrayOf(PropTypes.shape({
        field: PropTypes.string.isRequired,
        message: PropTypes.string.isRequired
    })).isRequired,

    setFieldValue: PropTypes.func.isRequired,
    edit: PropTypes.bool // edit mode
};

CampaignName.defaultProps = {
    edit: false
};

export default CampaignName;
