/* import axios from 'axios';
import { NEXT_APP_API_URL } from '../config'; */
import {sendGraphQL} from './sendRequest'
import productFragment from './fragments/product.fragment';


export const updateAdvertiserProduct = (productIDs, status) => {

    if (!productIDs || !status) return null;

    const query = `
        mutation updateAdvertiserProduct($product_ids: [String]!, $data: UpdateProductInput!) {
            updateProducts(product_ids: $product_ids, data: $data) {
                ...ProductData
          } 
        }

        ${productFragment}
    `

    const variables = {
        product_ids: productIDs,
        data: {
            status
        }
    }

    const payload = {
        query,
        variables
    }

    return sendGraphQL(payload)
        .then(response => response.data)

    /* let headers = {
        "Content-Type": 'application/json'
    }

    return axios.post(NEXT_APP_API_URL, payload, { headers })
        .then(response => response.data) */
}
