/* import axios from 'axios';
import {NEXT_APP_API_URL} from '../config'; */
import {sendGraphQL} from './sendRequest'

export const GET_LTV_ORDERS_PENDING = 'GET_LTV_ORDERS_PENDING';
export const GET_LTV_ORDERS_FULFILLED = 'GET_LTV_ORDERS_FULFILLED';
export const GET_LTV_ORDERS_REJECTED = 'GET_LTV_ORDERS_REJECTED';

export const getLTVOrders = (adv_id, earliest_date, get_names = false) => {
    if(!adv_id) return null;

    let query = `
        query getLTVOrders($adv_id: String!, $start_date: String, $end_date: String, $get_names: Boolean, $options: QueryOptions){
            orders(advertiser_id: $adv_id, options: $options, filters: {
                startDate: $start_date,
                endDate: $end_date
                getNames: $get_names
            }){
                created_at
                customer{
                    id
        `
        if(get_names){
            query+= `
                        first_name
                        last_name
            `
        }
        query+= `
                }
                total_price
            }
        }
    `

    const variables = {
        adv_id: adv_id,
        options: {
            sortBy: {
                field: 'created_at',
                order: "DESC"
            }
        },
        start_date: new Date().toISOString(),
        end_date: earliest_date,
        get_names: get_names
    }

    let payload = {
        query,
        variables
    }

    return{
        type: 'GET_LTV_ORDERS',
        payload: sendGraphQL(payload)
            .then(response => response.data)
    }

    /* let headers = {
        "Content-Type": 'application/json'
    }

    return{
        type: 'GET_LTV_ORDERS',
        payload: axios.post(NEXT_APP_API_URL, payload, { headers })
            .then(response => response.data)
    } */
}

export const getLTVOrdersFromShopify = (domain, earliest_date) => {
    console.log('DOMAIN', domain)
    if(!domain) return null;

    const query = `
        query getLTVOrdersFromShopify($domain: String!, $start_date: String, $end_date: String){
            getLTVOrdersFromShopify(domain: $domain, startDate: $start_date, endDate: $end_date){
                created_at
                customer{
                    id
                    first_name
                    last_name
                }
                total_price
            }
        }
    `

    const variables = {
        domain,
        start_date: new Date().toISOString(),
        end_date: earliest_date
    }

    console.log('VARIABLES', variables);

    let payload = {
        query,
        variables
    }

    return{
        type: 'GET_LTV_ORDERS_FROM_SHOPIFY',
        payload: sendGraphQL(payload)
            .then(response => response.data)
    }

    /* let headers = {
        "Content-Type": 'application/json'
    }

    return{
        type: 'GET_LTV_ORDERS_FROM_SHOPIFY',
        payload: axios.post(NEXT_APP_API_URL, payload, { headers })
            .then(response => response.data)
    } */
}