/* import axios from 'axios';
import { NEXT_APP_API_URL } from '../config'; */
import {sendGraphQL} from './sendRequest'

export const GET_IMPORTED_AFFILIATES_INFO_FULFILLED = 'GET_AFFILIATE_INFO_FULFILLED';
export const GET_IMPORTED_AFFILIATES_INFO_REJECTED = 'GET_AFFILIATE_INFO_REJECTED';
export const GET_IMPORTED_AFFILIATES_INFO_PENDING = 'GET_AFFILIATE_INFO_PENDING';

export const searchImportedAffiliates = (advertiser_id, term, filters = {}, options = {} ) => {

    const query = `
         query searchAffiliates(
            $advertiser_id: String,
            $term: String!,
            $filters: AffiliateSearchFilterInput, 
            $options: AffiliateQueryOptions
        ) {
            searchAffiliates(advertiser_id: $advertiser_id, term: $term, filters: $filters, options: $options) {
                affiliates {
                    _id
                    cognito_id
                    name
                    email
                    photo
                    website
                    status
                    errors {
                        type
                        message
                    }
                    reference {
                      referrer
                      referrer_type
                      referrer_offer
                      referrer_payout
                    }
                      youTubeData {
                        channelStats {
                            subscribers
                        }
                    }
                    twitterData {
                        user {
                            followers
                        }
                    }
                    instagramDataV2 {
                        followers_count
                    }
                    facebookData {
                        pageStats {
                            fan_count
                        }
                    }
                    account {
                        bio
                        categories
                        signUpDate
                        audience_gender
                        audience_country
                        audience_age_range
                    }
                    }
                }
            }
    `

    let variables = {
        advertiser_id,
        term,
        filters: { status: 'IMPORTED' },
        options
    }

    let payload = {
        query,
        variables
    };

    return {
        type: "GET_IMPORTED_AFFILIATES",
        payload: sendGraphQL(payload)
            .then((response) => {
                console.log("RESPONSE::", response);
                return response.data.data.searchAffiliates
            })
    }

    /* let headers = {
        "Content-Type": 'application/json'
    };

    return {
        type: "GET_IMPORTED_AFFILIATES",
        payload: axios.post(NEXT_APP_API_URL, payload, {headers})
            .then(response => response.data.data.searchAffiliates)
    } */
};
