import { useState, useEffect } from 'react';

const usePages = (initalPage, postsPerPage, posts, currentActiveTab = false) => {

    let [ currentPage, setCurrentPage ] = useState(initalPage);
    let [ currentTab, setCurrentTab ]   = useState(currentActiveTab);

    const oldCurrPage = JSON.parse(sessionStorage.getItem('current_page'));
    useEffect(() => {
        if (currentTab != currentActiveTab) {
            setCurrentPage(initalPage);
            setCurrentTab(currentActiveTab);
        }

        if (oldCurrPage !== null) {
            setCurrentPage(oldCurrPage);
        }
    });

    if (!posts || !Array.isArray(posts)) {
        return { posts: null, currentPage: 0, maxPages: 0 };
    }

    let startNum = currentPage * postsPerPage;
    let endNum = startNum + postsPerPage;
    let hasNext = true;
    let hasPrevious = true;

    let maxPages = posts.length % postsPerPage === 0 
        ? (posts.length / postsPerPage) - 1 
        : Math.floor(posts.length / postsPerPage);

    posts = posts.slice(startNum, endNum);

    const previousPage = () => {
        if (currentPage <= 0) {
            setCurrentPage(0);
        }
        else {
            setCurrentPage(currentPage - 1);
            sessionStorage.setItem('current_page', currentPage - 1);
        }
        window.scrollTo(0, 0)
    }

    const nextPage = () => {
        if(currentPage >= maxPages) {
            setCurrentPage(maxPages);
        }
        else {
            setCurrentPage(currentPage + 1);
            sessionStorage.setItem('current_page', currentPage + 1);
        }
        window.scrollTo(0, 0)
    }

    const setPageTo = (page) => {
        if(page >= maxPages) {
            setCurrentPage(maxPages);
        }
        else {
            setCurrentPage(page);
            sessionStorage.setItem('current_page', page);
        }
        window.scrollTo(0, 0);
    }

    if (currentPage <= 0) {
        hasPrevious = false;
    }

    if (currentPage >= maxPages) {
        hasNext = false;
    }

    return {
        previousPage,
        nextPage,
        currentPage,
        hasNext,
        hasPrevious,
        maxPages,
        posts,
        setPageTo
    }

}

export default usePages;