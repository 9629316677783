import React from 'react';
import { EmptyState } from 'llama-library/components';

/**
 * @function EmptyAffiliateDetail
 * Accepts props, returns a Component. 
 * Displays an 'empty social accounts' message.
 * 
 * @param {Object} props
 * 
 * @returns {FunctionComponent}
 */
const EmptyAffiliateDetail = (props) => {
    return (
        <EmptyState message={`${props.affiliate.name}’s herd looks pretty empty`} paragraph="Either that or they haven’t connected their social media yet." ctaText="Find another ambassador" ctaAction={() => props.history.push('/discover')} />
    )
}

export default EmptyAffiliateDetail;