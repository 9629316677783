import React from 'react';
import { FormattedDate } from 'react-intl';
import { SkeletonBodyText, Banner } from '@shopify/polaris';
import { LlamaPagination, EmptyState } from 'llama-library/components';

import {moneyFormat} from '../../utils/number-format';

import './transaction-report.css';

/**
 * @Component LoadingRows
 * Displays a series of fake rows to simulate what the rows will look like once actual
 * data has loaded.
 * 
 * @prop {Int} rows - Number of rows to 'fake'
 * @prop {Array<String>} columns - Array of columns that are included
 * @prop {Object} cellData - Data with information on how to render each cell based on column 
 */
const LoadingRows = ({ rows, columns, cellData }) => {

    if (!cellData || !rows || !columns) {
        return null;
    }

    const loadingArray = new Array(rows);
    loadingArray.fill('');

    const emptyCell = (
        <div style={{ width: '80%' }}>
            <SkeletonBodyText lines={1} />
        </div>
    )

    return (
        <React.Fragment>
            {loadingArray.map((_, index) => {
                const style = index % 2 !== 0 ? { background: "#F4F6F8", height: '40px' } : { height: '40px' };
                return (
                    <div className="TransactionReports__Row" key={`${index}`} style={style}>
                        {columns.map((item) => {
                            const cellInfo = cellData[item];
                            return <div className={`TransactionReports__Cell ${cellInfo.class}`} key={`loading_${cellInfo.key}`}>{emptyCell}</div>;
                        })}
                    </div>
                )
            })}
        </React.Fragment>
    )

}

/**
 * @enum ReportColumns
 * Allowed columns within TransactionReports component.
 */
export const ReportColumns = {
    ORDER_NUMBER: 'ORDER_NUMBER',
    DATE: 'DATE',
    AFFILIATE: 'AFFILIATE',
    ADVERTISER: 'ADVERTISER',
    OFFER: 'OFFER',
    CUSTOMER: 'CUSTOMER',
    COMMISSION: 'COMMISSION',
    STATUS: 'STATUS',
    SUBTOTAL: 'SUBTOTAL',
    LOOK_FORWARD: 'LOOK_FORWARD',
}

/**
 * Specific cell information based on the column, including header title and onClick functions.
 */
const cellData = {

    [ReportColumns.ORDER_NUMBER]: {
        key: "order_number",
        headerTitle: "Order",
        class: "TransactionReports__DataNumber",
        value: ({ name }) => name,
    },

    [ReportColumns.DATE]: {
        key: "date",
        headerTitle: "Date",
        class: "TransactionReports__DataDate",
        value: ({ created_at }) => {
            const createDate = new Date(created_at);
            return <FormattedDate 
                value={createDate} 
                year="2-digit"
                month="2-digit"
                day="2-digit"
            />
        },
    },

    [ReportColumns.AFFILIATE]: {
        key: "affiliate",
        headerTitle: "Ambassador",
        class: "TransactionReports__DataName TransactionReports__Link",
        onClick: ({ connection }, history) => {
            return connection && connection.affiliate && connection.affiliate._id
                ? () => history.push(`/affiliate/${connection.affiliate._id}`)
                : null;
        },
        value: ({ connection }) => {
            if (connection && connection.affiliate) {
                return connection.affiliate.name
            }
        },
    },

    [ReportColumns.ADVERTISER]: {
        key: "advertiser",
        headerTitle: "Advertiser",
        class: "TransactionReports__DataName",
        value: ({ connection }) => {
            if (connection && connection.advertiser) {
                return connection.advertiser.name
            }
        },
    },

    [ReportColumns.OFFER]: {
        key: "offer",
        headerTitle: "Offer",
        class: "TransactionReports__DataName",
        value: ({ connection }) => {
            if (connection && connection.offer) {
                return connection.offer.name
            }
        },
    },

    [ReportColumns.CUSTOMER]: {
        key: "customer",
        headerTitle: "Customer Name",
        class: "TransactionReports__DataName",
        value: ({ customer }) => `${customer && customer.first_name} ${customer && customer.last_name}`,
    },

    [ReportColumns.COMMISSION]: {
        key: "commission",
        headerTitle: "Commission",
        class: "TransactionReports__DataCurrency",
        value: ({ connection }) => moneyFormat((connection && connection.commission_amount), true),
    },

    [ReportColumns.STATUS]: {
        key: "status",
        headerTitle: "Status",
        class: "TransactionReports__DataBadge",
        value: () => "Status",
    },

    [ReportColumns.SUBTOTAL]: {
        key: "subtotal",
        headerTitle: "Subtotal",
        class: "TransactionReports__DataCurrency",
        value: ({ subtotal_price }) => moneyFormat(subtotal_price),
    },

    [ReportColumns.LOOK_FORWARD]: {
        key: "look_forward",
        headerTitle: "Look Forward",
        class: "TransactionReports__DataLookForward",
        value: ({ connection }) => {
            if (connection.expired_timeline) {
                return <FormattedDate 
                    value={connection.expired_timeline} 
                    year="2-digit"
                    month="2-digit"
                    day="2-digit"
                />
            }
        },
    },

}

const defaultColumns = [
    ReportColumns.ORDER_NUMBER,
    ReportColumns.DATE,
    ReportColumns.AFFILIATE,
    ReportColumns.ADVERTISER,
    ReportColumns.CUSTOMER,
    ReportColumns.COMMISSION,
    ReportColumns.STATUS,
    ReportColumns.SUBTOTAL,
    ReportColumns.LOOK_FORWARD,
]

/**
 * @Component TransactionReports
 * 
 * Displays detailed order information.  Which columns are being displayed can be specified,
 * this allows us to use it in multiple parts of the application to display the information
 * that we need.
 * 
 * Should be combined with the `useTransactionReport` hook which gathers all the appropriate
 * data required.
 *  
 * @prop {Object} history
 * @prop {Object} orders - Data to display
 * @prop {Boolean} isLoading - If true, will display the loading state
 * @prop {String} errorMessage - If present, error message will be displayed instead of data
 * @prop {(currentPage) => null} onNext - Called when onNextPage is selected, passes in the 
 *      current page
 * @prop {(currentPage) => null} onPrevious - Called when onPreviousPage is selected, passes 
 *      in the current page
 * @prop {Object} pageInfo - Object containing `pages`, `currentPage`, `hasNextPage`, 
 *      `hasPreviousPage`, `ordersPerPage`.
 * @prop {Array<String>} columns - Which columns to display, use the ReportColumns object to get
 *      the exact names of the available columns.
 * 
 * @example
 * ```jsx
 * 
 *  const {
 *      pageInfo,
 *      handleOnNext,
 *      handleOnPervious,
 *      orders,
 *      isLoading,
 *      errorMessage,
 *  } = useTransactionReport(advertiser.advertiser_id, filters);
 *
 *  const reportColumns = [
 *      ReportColumns.ORDER_NUMBER,
 *      ReportColumns.DATE,
 *      ReportColumns.AFFILIATE,
 *      ReportColumns.CUSTOMER,
 *      ReportColumns.COMMISSION,
 *      ReportColumns.STATUS,
 *      ReportColumns.SUBTOTAL,
 *      ReportColumns.LOOK_FORWARD,
 *  ]
 * 
 *  // ...
 *
 *  <TransactionReports
 *      orders={orders}
 *      isLoading={isLoading}
 *      errorMessage={errorMessage}
 *      history={history}
 *      pageInfo={pageInfo}
 *      onNext={handleOnNext}
 *      onPrevious={handleOnPervious}
 *      columns={reportColumns}
 *  />
 * 
 * ```
 */
const TransactionReports = ({ 
    orders, 
    isLoading, 
    errorMessage, 
    history,
    columns = defaultColumns,
    onNext,
    onPrevious,
    pageInfo,
}) => {

    const { pages, hasNextPage, hasPreviousPage, currentPage, ordersPerPage = 10 } = pageInfo;

    return (
        <React.Fragment>

            <div className="TransactionReports__Table">

                {/* REPORT HEADER */}
                <div className="TransactionReports__Row TransactionReports__TableHeader">
                    {columns.map((column) => {
                        const cellInfo = cellData[column];
                        return (
                            <div 
                                key={`header__${cellInfo.key}`}
                                className={`TransactionReports__Cell ${cellInfo.class}`}
                            >
                                {cellInfo.headerTitle}
                            </div>
                        ) 
                    })}
                </div>

                {/* LOADING STATE */}
                {isLoading && <LoadingRows rows={ordersPerPage} columns={columns} cellData={cellData}/>}

                {/* ERROR STATE */}
                {!isLoading && errorMessage && (
                    <div className="TransactionReports__Row" style={{ padding: '2rem', display: 'flex', justifyContent: 'center', width: '100%' }}>
                        <Banner>{errorMessage}</Banner>
                    </div>
                )}

                {/* EMPTY STATE */}
                {Array.isArray(orders) && orders.length === 0 && !isLoading && !errorMessage && (
                    <EmptyState message="I couldn’t find any sales" paragraph="Looks like you didn’t have any sales during this time period. Try selecting a different ambassador and/or date range." />
                )}

                {/* DATA ROWS */}
                {Array.isArray(orders) && orders.length > 0 && !isLoading && !errorMessage && (
                    orders.map((order, index) => {

                        const { order_id } = order;
                        const style = index % 2 !== 0 ? { background: "#F4F6F8" } : {};
                        
                        return (
                            <div className="TransactionReports__Row" key={`${index}__${order_id}`} style={style}>
                                {columns.map((column) => {

                                    // Creates a cell for each column specified in the component using
                                    // the cellData object for each column.

                                    // Get cell data object for the column.
                                    const cellInfo = cellData[column];

                                    // If an onClick handler is on the object, then execute the function
                                    // to get the onClickHandler to pass to the cell.
                                    const action = cellInfo.onClick
                                        ? cellInfo.onClick(order, history)
                                        : null;

                                    return (
                                        <div key={`${order_id}__${cellInfo.key}`} className={`TransactionReports__Cell ${cellInfo.class}`} onClick={action} >
                                            {cellInfo.value(order)}
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    })
                )}
            </div>

            {/* PAGINATION */}
            {pageInfo.currentPage && pages > 1 && <LlamaPagination 
                hasNext={hasNextPage}
                hasPrevious={hasPreviousPage}
                onNext={onNext}
                onPrevious={onPrevious}
                currentPage={currentPage - 1}
                maxPages={pages - 1}
            />}

        </React.Fragment>
    )
        
}

export default TransactionReports;