import React, { Component } from 'react';
import './styles/fourOhfour.css';
class fourOhfour extends Component {

    constructor(props) {
        super(props);
    }

    navigateToNew() {
        this.props.history.push('/offers/new');
    }
   
    render() {
        return (
            <div>
                <div className="image-container">
                    <img src="https://s3.amazonaws.com/llama.creatives/image.png" />
                </div>
                <div className="message-container">
                    <h1 className="fourOhfour"> 404 </h1>
                    <h3 className="fourOhfour-message"> Oh no! We are sorry but you seemed to have made a wrong turn! </h3>
                    <h3 className="fourOhfour-message"> Pay no attention to Larry, he is not upset we swear! </h3>
                </div>
            </div>
        )
    }

}

export default fourOhfour;