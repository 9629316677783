import {
    GET_OFFERS_FULFILLED,
    GET_OFFERS_REJECTED,
    GET_OFFERS_PENDING
} from '../actions/offers';
import { GET_ADVERTISER_FULFILLED } from '../actions/advertiser';
import { GENERATE_PIXEL_FULFILLED } from '../actions/generate-pixel';
import { CREATE_OFFER_FULFILLED } from '../actions/create-offer';
import { UPDATE_OFFER_CREATIVES_FULFILLED } from '../actions/update-offer-creatives';
import { GET_BRANDED_SIGNUP_FULFILLED } from '../actions/branded-sign-up';
import { INVITE_PAGE_OFFERS_FULFILLED } from '../actions/branded-sign-up';

const defaultState = [];

const offersReducer = (state = defaultState, action) => {

    let { type } = action;

    switch (type) {

        case GENERATE_PIXEL_FULFILLED:
            let { offer_id, data } = action.payload;

            let newOffers = state.map((offer) => {
                if (offer.id === offer_id) {
                    offer.tracking_pixel = data
                }

                return offer;
            })

            return newOffers;

        case GET_ADVERTISER_FULFILLED:
            if (!action || !action.payload || !action.payload.data || !Array.isArray(action.payload.data)) {
                return state;
            }
            if (!Array.isArray(action.payload.data.advertisers[0].offers)) return state;

            return action.payload.data.advertisers[0].offers;

        case GET_OFFERS_FULFILLED:
            if (!Array.isArray(action.payload)) return state;

            return action.payload;

        case CREATE_OFFER_FULFILLED: {

            if (!action.payload.createOffer) {
                // TODO: Handle error.
                return state;
            };

            const newOffers = [
                ...state,
                action.payload.createOffer
            ]

            return newOffers;
        }

        case UPDATE_OFFER_CREATIVES_FULFILLED: {
            const newOffers = [
                ...state
            ]
            return newOffers;
        }

        case GET_BRANDED_SIGNUP_FULFILLED: {
            if (!action || !action.payload || !action.payload.data || !action.payload.data.advertiserById) {
                return state;
            }

            let offers = [];
            if (
                Array.isArray(action.payload.data.advertiserById.offers)
                && action.payload.data.advertiserById.offers.length > 0
            ) {
                offers = action.payload.data.advertiserById.offers.map((offer) => {
                    const stateOffer = state.find((item) => {
                        return offer.offer_id === item.offer_id;
                    })

                    return { ...stateOffer, ...offer }
                })
            }

            return offers
        }

        case INVITE_PAGE_OFFERS_FULFILLED: {
            if (!action || !action.payload || !action.payload.data || !action.payload.data.advertiserById) {
                return state;
            }

            let offers = [];
            if (
                Array.isArray(action.payload.data.advertiserById.offersForBrandedSignup)
                && action.payload.data.advertiserById.offersForBrandedSignup.length > 0
            ) {
                offers = action.payload.data.advertiserById.offersForBrandedSignup.map((offer) => {
                    const stateOffer = state.find((item) => {
                        return offer.offer_id === item.offer_id;
                    })

                    return { ...stateOffer, ...offer }
                })
            }

            return offers
        }

        default:
            return state;

    }

}

export default offersReducer;