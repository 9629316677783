import React, { useState } from 'react';
import { TextField, Icon, Checkbox, Select, RadioButton, Stack } from '@shopify/polaris';
import {
    SearchMinor,
    DropdownMinor
} from '@shopify/polaris-icons';
import { LlamaButton, LlamaPagination, EmptyState } from 'llama-library/components';

import { formatMetrics } from 'llama-library/utils';

import usePagesDiscover from '../../hooks/use-page-discover';
import { socialOptions } from '../../hooks/use-social-filter';

import SocialIcons from '../social-icons';
import llamaPlaceholder from '../../assets/llama-user-icon.png';

import platformIcon from '../../assets/platform-icon.svg';
import reachIcon from '../../assets/reach-icon.svg';
import engageIcon from '../../assets/engage-icon.svg';
import audienceIcon from '../../assets/audience-icon.svg';
import countryIcon from '../../assets/country-icon.svg';

import LlamaSlider from '../llama/slider';

import formatSocialScore from '../../utils/format-social-score';
import { formatAudience } from '../../utils/format-audience';
import formatCountries from '../../utils/format-countries';
import { getAffiliateInviteButtonMode } from '../../utils/affiliate-invite-mode';

import PlaceHolderAffiliate from './placeholder-affiliate';

import './discover.css';

const reachRange = [
    {
        name: '0',
        value: 0
    },
    {
        name: '1K',
        value: 1000
    },
    {
        name: '5K',
        value: 5000
    },
    {
        name: '10K',
        value: 10000
    },
    {
        name: '25K',
        value: 25000
    },
    {
        name: '50K',
        value: 50000
    },
    {
        name: '100K',
        value: 100000
    },
    {
        name: '250K',
        value: 250000
    },
    {
        name: '500K',
        value: 500000
    },
    {
        name: '1M',
        value: 1000000
    },
    {
        name: '5M',
        value: 5000000
    },
    {
        name: '10M',
        value: 10000000
    },
    {
        name: '25M',
        value: 25000000
    },
    {
        name: '100M+',
        value: 10000000000
    }
];

const ageRange = [
    '18-24',
    '25-34',
    '35-44',
    '45-54',
    '55-64',
    '65+',
    'All'
];

/**
 * @function DiscoverSidebar
 * Accepts the following params, returns DiscoverSidebar Component.
 * Displays and handles search, sort and filter of ambassadors.
 *
 * @param {String} searchTerm
 * @param {Function} setSearchTerm
 * @param {Boolean} isLoading
 * @param {Array} categories
 * @param {Array} selectedCategories
 * @param {Function} setSelectedCategories
 * @param {String} socialFilter
 * @param {Function} setSocialFilter
 * @param {Array} rangeValues
 * @param {Function} setRangeValues
 * @param {Function} refreshData
 * @param {String} selectedGender
 * @param {Function} setGenderValue
 * @param {Array} selectedAgeRange
 * @param {Function} setAgeRange
 *
 * @returns {FunctionComponent}
 */
const DiscoverSidebar = (props) => {

    const {
        searchTerm,
        setSearchTerm,
        categories,
        selectedCategories,
        setSelectedCategories,
        socialFilter,
        setSocialFilter,
        rangeValues,
        setRangeValues,
        selectedGender,
        setGenderValue,
        selectedAgeRange,
        setAgeRange,
        discoverFilters,
        setDiscoverFilters,
        applyDiscoverFilters,
        isResetFilter,
        setIsResetFilter,
        setSearchButton,
        isApplyFilterDisable,
        isResetFilterDisable,
        rangeNames,
        setRangeNames,
        allCategories,
        setAllCategories,
        openSection,
        toggleOpen
    } = props;

    const [searchMode, setSearchMode] = useState(false);

    const handleSearchTerm = (term) => {
        if (term.length > 0) {
            setSearchMode(true);
        } else {
            setSearchMode(false);
        }
        setSearchButton(true);
    };

    const handleSearchTermChange = (value) => {
        setSearchTerm(value);
        // if the user searched for something, then cleared the search, reset the offer results
        if (searchMode && value.length === 0 && searchTerm.length > 0) {
            handleSearchTerm('');
        }
        setDiscoverFilters({
            ...discoverFilters,
            searchTerm: value
        });
    };

    // if the user types enter at the end of their query, trigger the search to happen
    const handleSearchTermEnter = (e, term) => {
        e.preventDefault();
        handleSearchTerm(term);
    };

    const handleSocialChange = (value) => {
        setSocialFilter(value);
        setDiscoverFilters({
            ...discoverFilters,
            socialFilter: value
        });
    };

    const categorySelect = (name) => (selected) => {
        let newArray = selectedCategories.slice();
        if (selected) {
            newArray.push(name);
            setSelectedCategories(newArray);
            setDiscoverFilters({
                ...discoverFilters,
                selectedCategories: newArray
            });
        } else {
            newArray = newArray.filter((item) => item !== name);
            setSelectedCategories(newArray);
            setDiscoverFilters({
                ...discoverFilters,
                selectedCategories: newArray
            })
        }
        // toggle all categories box if it fits the selections
        if (allCategories) {
            setAllCategories(false);
        } else if (categories.length == newArray.length) {
            setAllCategories(true);
        }
    };

    const toggleAllCategories = () => (selected) => {
        setAllCategories(!allCategories);
        if (selected) {
            const categoryNames = categories.map(category => {
                return category;
            });
            setSelectedCategories(categoryNames);
            setDiscoverFilters({
                ...discoverFilters,
                selectedCategories: categoryNames
            });
        } else {
            setSelectedCategories([]);
            setDiscoverFilters({
                ...discoverFilters,
                selectedCategories: []
            });
        }
    };

    const onMinChange = (rangeName, value) => {
        const newArray = rangeValues.slice();
        newArray[0] = value;
        setRangeValues(newArray);

        const newRangeNames = rangeNames.slice();
        newRangeNames[0] = rangeName;
        setRangeNames(newRangeNames);

        setDiscoverFilters({
            ...discoverFilters,
            rangeValues: newArray,
            rangeNames: newRangeNames
        });
    };

    const onMaxChange = (rangeName, value) => {
        const newArray = rangeValues.slice();
        newArray[1] = value;
        setRangeValues(newArray);

        const newRangeNames = rangeNames.slice();
        newRangeNames[1] = rangeName;
        setRangeNames(newRangeNames);

        setDiscoverFilters({
            ...discoverFilters,
            rangeValues: newArray,
            rangeNames: newRangeNames
        });
    };

    const onGenderSelect = (checked, selectedGender) => {
        setGenderValue(selectedGender);
        setDiscoverFilters({
            ...discoverFilters,
            selectedGender
        });
    };

    const ageRangeSelect = (value) => (checked) => {
        let newAgeArray = selectedAgeRange.slice();

        if (value === 'All' && checked) {
            setAgeRange(ageRange);
            setDiscoverFilters({
                ...discoverFilters,
                selectedAgeRange: ageRange
            });
            return;
        }

        if (value === 'All' && !checked) {
            newAgeArray = [''];
            setAgeRange(newAgeArray);
            setDiscoverFilters({
                ...discoverFilters,
                selectedAgeRange: newAgeArray
            });
        }

        if (checked) {
            newAgeArray.push(value);

            const compareArray = ageRange.filter((item) => item !== 'All');
            const allValuesChecked = compareArray.reduce((acc, next) => {
                if (!acc) {
                    return acc;
                }
                return newAgeArray.includes(next);
            }, true);

            if (allValuesChecked) {
                newAgeArray.push('All');
            }

            if (newAgeArray.includes('')) {
                newAgeArray = newAgeArray.filter((item) => {
                    return item !== '';
                });
            }

            setAgeRange(newAgeArray);
            setDiscoverFilters({
                ...discoverFilters,
                selectedAgeRange: newAgeArray
            });
        } else {
            newAgeArray = newAgeArray
                .filter((item) => item !== value)
                .filter((item) => item !== 'All');
            setAgeRange(newAgeArray);
            setDiscoverFilters({
                ...discoverFilters,
                selectedAgeRange: newAgeArray
            });
        }
    };

    const handleResetFilter = () => {
        setIsResetFilter(true);
    };

    const renderFilterButtons = () => {
        return (
            <div className="discover-offers__filter-group">
                <LlamaButton
                    onClick={applyDiscoverFilters}
                    background="#341d5b"
                    color="#fcfcfc"
                    disabled={isApplyFilterDisable}
                >
                    Apply Filters
                </LlamaButton>
                <p className="discover-offers__reset-filters" onClick={handleResetFilter} data-enabled={!isResetFilterDisable}>Reset Filters</p>
            </div>
        );
    };

    return (
        <div className="discover-sidebar" data-test="component-sidebar">
            <div className="discover-sidebar__search">
                <form onSubmit={(e) => handleSearchTermEnter(e, searchTerm)}>
                    <TextField
                        value={searchTerm}
                        onChange={handleSearchTermChange}
                        placeholder="Search"
                        data-test='discover-search'
                        connectedRight={
                            <button className="search-button" onClick={handleSearchTerm}><Icon source={SearchMinor} color="inkLighter" accessibilityLabel="Search" /></button>
                        }
                    />
                </form>
            </div>
            {/* Only shows on mobile, allows user to display filters */}
            <div onClick={toggleOpen} className={openSection ? "discover-sidebar__mobile-open discover-sidebar__section--active": "discover-sidebar__mobile-open"} data-test="button-openMenu">
                <p className="discover-sidebar__display-text">Filter Results</p>
                <div className="discover-sidebar__dropdown-wrapper" style={{ width: 20 }}>
                    <Icon source={DropdownMinor} />
                </div>
            </div>
            <div className="discover-sidebar__section">
                <div className={openSection ? "discover-sidebar__section-content expanded" : "discover-sidebar__section-content"}>
                    {renderFilterButtons()}
                </div>
            </div>
            <div className="discover-sidebar__section">
                <div className={openSection ? "discover-sidebar__section-content expanded" : "discover-sidebar__section-content"}>
                    <label className="discover-sidebar__label">Social Media Channel</label>
                    <Select
                        options={socialOptions}
                        value={socialFilter}
                        onChange={handleSocialChange}
                    />
                </div>
            </div>
            <div className="discover-sidebar__section">
                <div className={openSection ? "discover-sidebar__section-content expanded" : "discover-sidebar__section-content"}>
                    <label className="discover-sidebar__label">Audience Gender</label>
                    <Stack horizontal>
                        <RadioButton
                            label="All"
                            id="ALL"
                            checked={selectedGender === 'ALL'}
                            onChange={onGenderSelect}
                        />
                        <RadioButton
                            label="Male"
                            checked={selectedGender === 'MALE'}
                            id="MALE"
                            onChange={onGenderSelect}
                        />
                        <RadioButton
                            label="Female"
                            checked={selectedGender === 'FEMALE'}
                            id="FEMALE"
                            onChange={onGenderSelect}
                        />
                    </Stack>
                </div>
            </div>
            <div className="discover-sidebar__section">
                <div className={openSection ? "discover-sidebar__section-content expanded" : "discover-sidebar__section-content"}>
                <label className="discover-sidebar__label">Audience Age Range</label>
                    <div className="discover-sidebar__age-wrapper">
                        {Array.isArray(ageRange) && ageRange.map((value, index) => {
                            return (
                                <div className="discover-sidebar__age-check" data-test="checkbox-ages" key={value + index}>
                                    <Checkbox
                                        label={value}
                                        checked={selectedAgeRange && selectedAgeRange.includes(value)}
                                        onChange={ageRangeSelect(value)}
                                    />
                                </div>
                            )
                        })
                        }
                    </div>
                </div>
            </div>
            <div className="discover-sidebar__section">
                <div className={openSection ? "discover-sidebar__section-content expanded" : "discover-sidebar__section-content"}>
                    <label className="discover-sidebar__label">Reach</label>
                    <LlamaSlider
                        range={reachRange}
                        onMinChange={onMinChange}
                        onMaxChange={onMaxChange}
                        customMinValue={rangeNames[0]}
                        customMaxValue={rangeNames[1]}
                        isResetFilter={isResetFilter}
                        data-test="component-llamaSlider"
                    />
                </div>
            </div>
            <div className="discover-sidebar__section">
                <div className={openSection ? "discover-sidebar__section-content expanded" : "discover-sidebar__section-content"}>
                    <label className="discover-sidebar__label">Categories</label>
                    <div className="discover-sidebar__category">
                        <Checkbox
                            checked={allCategories}
                            label="All Categories"
                            onChange={toggleAllCategories()}
                        />
                    </div>
                    {Array.isArray(categories)
                    && categories.length > 0
                    && categories.map((name) => {
                        return (
                            <div className="discover-sidebar__category" key={name.replace(' ', '_').toLowerCase()}>
                                <Checkbox
                                    checked={selectedCategories && selectedCategories.includes(name)}
                                    label={name}
                                    onChange={categorySelect(name)}
                                />
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className="discover-sidebar__section">
                <div className={openSection ? "discover-sidebar__section-content expanded" : "discover-sidebar__section-content"}>
                    {renderFilterButtons()}
                </div>
            </div>
        </div>
    );
};

/**
 * @function DiscoverAffiliate
 * Accepts the following params, returns DiscoverAffiliate Component.
 * Displays a quickview of the affiliate (marketing platforms,
 * Total Reach, Engagement Score, Audience: age range and gender,
 * and Audience Country).
 *
 * @param {Object} data
 * @param {Array} selectedCategories
 * @param {Object} history
 * @param {Function} setSideDrawerAffiliate
 *
 * @returns {FunctionComponent}
 */
const DiscoverAffiliate = props => {
    const { data, selectedCategories, history, setSideDrawerAffiliate, offers, affiliateOffers } = props;

    const [displayAll, setDisplayAll] = useState(false);

    const { inviteText, inviteColor, isInvitedisabled } = getAffiliateInviteButtonMode(affiliateOffers, offers);

    const affilaiteInfo = [
        {
            key: 'Marketing Platforms',
            value: <SocialIcons affiliate={data} />,
            image: platformIcon
        },
        {
            key: 'Total Reach',
            value: data.reach && formatMetrics(data.reach),
            image: reachIcon
        },
        {
            key: 'Engagement Score',
            value: formatSocialScore(data),
            image: engageIcon
        },
        {
            key: 'Audience',
            value: data.account && formatAudience(data.account),
            image: audienceIcon
        },
        {
            key: 'Audience Country',
            value: data.account && formatCountries(data.account, 3),
            image: countryIcon
        }
    ];

    const Categories = ({ categories, selectedCategories }) => {

        if (!Array.isArray(categories) || categories.length === 0) {
            return null;
        }

        categories = categories
            .sort((a, b) => {
                if (a > b) return 1;
                if (b > a) return -1;
                return 0;
            })
            .sort((a, b) => {
                if (!selectedCategories.includes(a) && selectedCategories.includes(b)) return 1;
                if (selectedCategories.includes(a) && !selectedCategories.includes(b)) return -1;
                return 0;
            });

        const categoryMap = (item) => {
            if (selectedCategories.includes(item)) {
                return <div className="discover-affiliate__category--selected" key={item}>{item}</div>
            } else {
                return <div key={item}>{item}</div>
            }
        };

        if (categories.length <= 3) {
            return <div className="discover-affiliate__categories">
                {categories.map(categoryMap)}
            </div>
        }

        const shortArray = categories.slice(0,2);

        return (
            <div className="discover-affiliate__categories">
                {displayAll ? categories.map(categoryMap) : shortArray.map(categoryMap)}
                {displayAll
                    ? <div className="discover-affiliate__categories-action" onClick={() => setDisplayAll(false)}>Show Less</div>
                    : <div className="discover-affiliate__categories-action" onClick={() => setDisplayAll(true)}>Show more categories...</div>
                }
            </div>
        )
    };

    const viewAffiliate = () => {
        history.push(`/affiliate/${data._id}`);
    };

    return (
        <div className="discover-affiliate" data-test="component-discover-affiliate">
            <div className="discover-affiliate__top">
                <img src={data.photo ? data.photo : llamaPlaceholder} alt={`${data.name}`} className="discover-affiliate__profile-image" onClick={viewAffiliate} />
                <div className="discover-affiliate__top-info">
                    <div className="discover-affiliate__name">
                        <span onClick={viewAffiliate}>{data.name}</span>
                    </div>
                    {data.account
                        && data.account.categories
                        && selectedCategories
                        && selectedCategories.length > 0
                        && <Categories categories={data.account.categories} selectedCategories={selectedCategories}/>}
                </div>
            </div>
            {affilaiteInfo.map((item) => {
                return (
                    <div className="discover-affiliate__section" key={item.key.replace(' ', '').toLowerCase()}>
                        <div className="discover-affiliate__key-icon">
                            <img src={item.image} alt={`${item.key}`} className="discover-affiliate__section-icon" />
                            <div className="discover-affiliate__info-key">{item.key}</div>
                        </div>
                        <div className="discover-affiliate__info-value">{item.value}</div>
                    </div>
                );
            })}
            <div className="discover-affiliate__section discover-affiliate__buttons">
                <LlamaButton inverted background={"#FD2856"} onClick={viewAffiliate} data-test="button-viewAffiliate">More Info</LlamaButton>
                <LlamaButton background={inviteColor} isDisabled={isInvitedisabled} data-test="button-inviteAffiliate" onClick={() => !isInvitedisabled && setSideDrawerAffiliate(data)}>{inviteText}</LlamaButton>
            </div>
        </div>
    );
};

/**
 * @function DiscoverMain
 * Accepts the following params, returns DiscoverMain Component.
 * Displays DiscoverAffiliate Components and pagination for
 * ambassadors.
 *
 * @param {Array} affiliates
 * @param {Boolean} initialLoad
 * @param {Array} selectedCategories
 * @param {Object} history
 * @param {Function} setSideDrawerAffiliate
 *
 * @returns {FunctionComponent}
 */
export const DiscoverMain = (props) => {
    const {
        initialLoad,
        selectedCategories,
        history,
        setSideDrawerAffiliate,
        isLoading,
        affiliates,
        pageContext,
        offers
    } = props;

    if (isLoading) {
        window.scrollTo(0, 0);
    }

    const renderPlaceholder = () => {
        return (
            <div className="discover-main__wrapper">
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((index) =>
                    <PlaceHolderAffiliate data-test="component-placeholder" key={`placeholder_${index}`} />
                )}
            </div>
        );
    };

    const renderSearchResults = () => {
        return (affiliates.length > 0)
            ? (
                <div className="discover-main__wrapper">
                    {affiliates.map((data) => {
                        return (
                            <DiscoverAffiliate
                                data={data}
                                key={data.cognito_id}
                                selectedCategories={selectedCategories}
                                history={history}
                                setSideDrawerAffiliate={setSideDrawerAffiliate}
                                offers={offers}
                                affiliateOffers={data.invitedOffers}
                            />
                        );
                    })}
                </div>
            )
            : <EmptyState message="Sorry, I couldn’t find any matches" paragraph="Try broadening your search filters to find more ambassadors" />;
    };

    return (
        <div className="discover-main" data-test="component-main">
            {initialLoad || isLoading
                ? renderPlaceholder()
                : renderSearchResults() }
            {pageContext.maxPages > 1 && (
                <div className="import__pagination">
                    <LlamaPagination
                        hasNext={pageContext.hasNext}
                        hasPrevious={pageContext.hasPrevious}
                        onNext={pageContext.nextPage}
                        onPrevious={pageContext.previousPage}
                        currentPage={pageContext.currentPage - 1}
                        maxPages={pageContext.maxPages - 1}
                        data-test="component-llamaPagination"
                    />
                </div>
            )}
        </div>
    );
};

/**
 * @function Discover
 * Accepts the following params, returns Discover Component.
 * @param {Object} props
 *
 * @returns {FunctionComponent}
 */
const Discover = (props) => {
    const pageContext = usePagesDiscover(props, 10);

    const filteredAffiliates = pageContext.posts;

    return (
        <div className="discover__wrapper" data-test="component-discover">
            <DiscoverSidebar {...props} />
            <DiscoverMain
                affiliates={filteredAffiliates}
                initialLoad={props.initialLoad}
                isLoading={props.isLoading}
                selectedCategories={props.selectedCategories}
                history={props.history}
                setSideDrawerAffiliate={props.setSideDrawerAffiliate}
                searchTerm={props.searchTerm}
                setAffiliates={props.setAffiliates}
                setIsLoading={props.setIsLoading}
                setInitalLoad={props.setInitalLoad}
                pageContext={pageContext}
                offers={props.offers}
            />
        </div>
    );
};

export default Discover;
