import axios from 'axios';
import { NEXT_APP_API_URL } from '../config';
import {sendGraphQL} from './sendRequest'

const UPDATE_ADVERTISER = 'UPDATE_ADVERTISER';
export const UPDATE_ADVERTISER_PENDING = 'UPDATE_ADVERTISER_PENDING';
export const UPDATE_ADVERTISER_FULFILLED = 'UPDATE_ADVERTISER_FULFILLED';
export const UPDATE_ADVERTISER_REJECTED = 'UPDATE_ADVERTISER_REJECTED';

export const updateAdvertiser = (formData, advertiser, isAxiosCall = false) => {

    const { ...data } = formData;

    const payload = {
        query: `mutation updateAdvertiser($advertiser_id: String, $data: UpdateAdvertiserInput){
            updateAdvertiser(advertiser_id: $advertiser_id, data: $data) {
                advertiser_id
                shop_owner
                email
                phone
                address1
                address2
                city
                zipcode
                region
                country
                name
                domain
                myshopify_domain
                avatar_image
                description
                tags
                new_product_status
                email_preference{
                    application_general
                    application_alerts
                    application_affiliate_messages
                    application_weekly_summary
                    newsletter_general
                    newsletter_marketing
                    newsletter_updates
                    email_unsubscribe
                }
                timeline_settings{
                    lookback{
                        time
                        edited
                    }
                    lookforward{
                        time
                        edited
                    }
                }
            }
        }`,
        variables: {
            advertiser_id: advertiser.advertiser_id,
            data
        }
    }

    if(!isAxiosCall) {
        return {
            type: UPDATE_ADVERTISER,
            payload: sendGraphQL(payload)
                .then(response => response.data)
        }
    }

    let headers = {
        "Content-Type": 'application/json'
    }

    return {
        type: UPDATE_ADVERTISER,
        payload: axios.post(NEXT_APP_API_URL, payload, { headers })
            .then(response => response.data)
    }
}
