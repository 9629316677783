import React from 'react';
import { I18n, ConsoleLogger as Logger } from '@aws-amplify/core';
import { Auth } from 'aws-amplify';
import {
    SignIn,
    SignUp,
    ForgotPassword,
    FederatedButtons,
    AuthPiece,
    SectionBody,
    InputRow,
    Link
} from 'aws-amplify-react';
import { LlamaButton } from 'llama-library/components';
import InitialPreloader from '../components/initial-preloader/initial-preloader';
import validateAutoCode from '../actions/validate-auto-code';
import jwt from 'jsonwebtoken';

import llamaHead from '../assets/llama-head-01.png';
import loginGraphic from '../assets/merchant-login-graphic.svg';
import llamaBg from '../assets/llama-bg-new.png';
import llamaBgMobile from '../assets/login-graphic-mobile.png';

const logger = {
    debug: (msg) => {
        if (process.env.NODE_ENV === "development") {
            console.log(msg)
        }
    }
};

export default class LlamaSignIn extends AuthPiece {
    constructor(props) {
        super(props);

        this.checkContact = this.checkContact.bind(this);
        this.signIn = this.signIn.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.processAutoLogin = this.processAutoLogin.bind(this);

        this._validAuthStates = ['signIn', 'signedOut'];
        this.state = {
            username: null,
            password: null,
            autoLogin: false,
        };
    }

    componentDidMount() {
        window.addEventListener('keydown', this.onKeyDown);
        if (
            this.props.params.auto && this.props.params.auto === 'true'
            && this.props.params.user_token && this.props.params.user_token.length > 0
            && this.props.params.auto_code && this.props.params.auto_code.length > 0
        ) {
            this.validateCode(this.props.params.shop, this.props.params.auto_code)
                .then(() => {
                    this.processAutoLogin();
                })
                .catch(() => {
                    window.location = '/';
                });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.onKeyDown);
    }

    onKeyDown(e) {
        if (e.keyCode !== 13) return;

        const { hide = [] } = this.props;
        if (this.props.authState === 'signIn' && hide.includes(SignIn)) {
            this.signIn();
        }
    }

    validateCode = async (shop, code) => {
        const result = await validateAutoCode(shop, code);
        if (result?.data?.validateAutoCode === 'success') {
            return Promise.resolve('success');
        }
        return Promise.reject(new Error('could not validate code'));
    }

    checkContact(user) {
        if (!Auth || typeof Auth.verifiedContact !== 'function') {
            throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
        }
        Auth.verifiedContact(user)
            .then(data => {
                if (!data.verified || data.verified === "") {
                    this.changeState('signedIn', user);
                } else {
                    user = Object.assign(user, data);
                    this.changeState('signedIn', user);
                    // this.changeState('verifyContact', user);
                }
            });
    }

    async signIn() {
        let username, password;
        if(!this.state.autoLogin) {
            if(!this.inputs.username && !this.inputs.password){
                return this.error('Email and Password are required.');
            }
            if(this.inputs.username && !this.inputs.password){
                return this.error('Password is required.');
            }
            if(!this.inputs.username && this.inputs.password){
                return this.error('Email is required.');
            }
            username = this.inputs.username;
            password = this.inputs.password;
        } else {
            username = this.state.username;
            password = this.state.password;
        }
        
        if (!Auth || typeof Auth.signIn !== 'function') {
            throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
        }
        this.setState({ loading: true });
        try {
            const user = await Auth.signIn(username.toLowerCase(), password);
            logger.debug(user);
            if (user.challengeName === 'SMS_MFA' || user.challengeName === 'SOFTWARE_TOKEN_MFA') {
                logger.debug('confirm user with ' + user.challengeName);
                this.changeState('confirmSignIn', user);
            } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                logger.debug('require new password', user.challengeParam);
                this.changeState('requireNewPassword', user);
            } else if (user.challengeName === 'MFA_SETUP') {
                logger.debug('TOTP setup', user.challengeParam);
                this.changeState('TOTPSetup', user);
            } else {
                this.checkContact(user);
            }
        } catch (err) {
            if(err.code === 'NotAuthorizedException'){
                return this.error('Incorrect email or password.')
            }else if(err.code === "InvalidParameterException" && err.message.indexOf('userName') !== -1){
                return this.error('Invalid email address format')
            }
            if (err.code === 'UserNotConfirmedException') {
                logger.debug('the user is not confirmed');
                this.changeState('confirmSignUp', { username });
            } else if (err.code === 'PasswordResetRequiredException') {
                logger.debug('the user requires a new password');
                this.changeState('forgotPassword', { username });
            } else {
                this.error(err);
            }
        } finally {
            this.setState({ loading: false });
        }
    }

    processAutoLogin() {
        // Validate Auth is present.
        if (!Auth || typeof Auth.signUp !== 'function') {
            console.log('No Auth module found');
            throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
        }

        try {
            // Recover the user_token and decode it.
            const userToken = jwt.verify(this.props.params.user_token, process.env.REACT_APP_JWT_SECRET_CODE);
            this.setState({ username: this.props.params.shop, password: userToken, autoLogin: true }, () => this.signIn());
        } catch(err) {
            throw new Error(err);
        }
    }

    showComponent(theme) {
        if (this.props.params.auto && this.props.params.auto === 'true' && this.props.params.user_token && this.props.params.user_token.length > 0) {
            return <InitialPreloader />;
        }

        const { authState, hide = [], federated, onStateChange, onAuthEvent, override = [] } = this.props;
        const hideForgotPassword = !override.includes('ForgotPassword') && hide.some(component => component === ForgotPassword);
        const applyButtonClass = ['amplify-button'];

        return (
            <div className="signin-amplify__wrapper">
                <div className="signin-amplify__form-wrapper">
                    <div className="signin-amplify__signin-form">
                        <div className="signin-amplify__mobile-wrapper">
                            <img src={llamaHead} alt="Leo the Llama" className="signin-amplify__llama-head" />
                            <div className="signin-amplify__mobile-text">
                                <h1 className="signin-amplify__header">Llama Merchant Sign In</h1>
                                <p className="signin-amplify__subheader">Sign in to your account.</p>
                            </div>
                        </div>
                        <SectionBody theme={theme}>
                            <FederatedButtons
                                federated={federated}
                                theme={theme}
                                authState={authState}
                                onStateChange={onStateChange}
                                onAuthEvent={onAuthEvent}
                            />
                            <div className="amplify-form-item">
                                <InputRow
                                    autoFocus
                                    placeholder={I18n.get('Email')}
                                    theme={theme}
                                    key="username"
                                    name="username"
                                    onChange={this.handleInputChange}
                                />
                            </div>
                            <div className="amplify-form-item">
                                <InputRow
                                    placeholder={I18n.get('Password')}
                                    theme={theme}
                                    key="password"
                                    type="password"
                                    name="password"
                                    onChange={this.handleInputChange}
                                />
                                {
                                    !hideForgotPassword && <div className="amplify-hint">
                                        {I18n.get('Forget your password? ')}
                                        <Link theme={theme} onClick={() => this.changeState('forgotPassword')}>
                                            {I18n.get('Reset password')}
                                        </Link>
                                    </div>
                                }
                            </div>
                        </SectionBody>
                        <div className="amplify__custom-footer">
                            <div className="amplify__button-wrapper">
                                <LlamaButton
                                    background="rgb(253, 42, 84)"
                                    classes={applyButtonClass}  
                                    theme={theme} 
                                    onClick={this.signIn} 
                                    loading={this.state.loading}
                                >
                                    {I18n.get('Sign In')}
                                </LlamaButton>
                            </div>
                            <div className="amplify-section-footer_links">
                                {I18n.get('Don\'t have an account? ')}
                                <div className="amplify__custom-link" data-test="link-signup">
                                    <Link theme={theme} onClick={() => this.changeState('signUp')}>
                                        {I18n.get('Create one')}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="signin-amplify__graphic-outer">
                    <img src={llamaBgMobile} className="signin-amplify__graphic-mobile" /> 
                    <img alt="Leo the Llama Merchant and his shop" src={loginGraphic} className="signin-amplify__login-graphic" />
                </div>
            </div>
        );
    }
}