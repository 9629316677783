import React, { useState } from 'react';
import { TextStyle } from '@shopify/polaris';
import { FormattedDate } from 'react-intl';

import { ResponsiveBar } from '@nivo/bar';
import findTopTick from '../../utils/find-top-tick';
import formatBottomTickValues from '../../utils/format-bottom-tick-values';
import {moneyFormat} from '../../utils/number-format';
import _ from 'lodash';

import './ambassador-performance.css';

const volumeReducer = (acc, next) => {
    acc = acc + next.volume;
    return acc;
}

const salesReducer = (acc, next) => {
    acc = acc + next.sales;
    return acc;
}

const graphTabs = {
    volume: {
        key: 'volume',
        name: 'Revenue',
        totalReducer: volumeReducer,
        verbiage: (value) => `${moneyFormat(value).replace(' ', '')} Made`,
    },
    sales: {
        key: 'sales',
        name: 'Conversions',
        totalReducer: salesReducer,
        verbiage: (value) => `${value} Conversions Made`,
    },
}

/**
 * @function PerformanceGraph
 * Accepts props Object, returns PerformanceGraph Component.
 * Displays nivo ResponsiveBar graph of ambassador performance data.
 * 
 * @param {Object} props 
 * 
 * @returns {FunctionComponent}
 */
const PerformanceGraph = (props) => {

    const { ambassador, presetTab } = props;

    const tab = presetTab && graphTabs[presetTab] 
        ? graphTabs[presetTab].key 
        : graphTabs.volume.key
    const [activeTab, setActiveTab] = useState(tab);

    let availableTabs = Object.values(graphTabs);
    if (presetTab) {
        availableTabs = availableTabs.filter((item) => item.key === presetTab);
    }

    if (_.isEmpty(ambassador) || !ambassador.sales_data) {
        return (
            <div className="PerformanceDetail__Graph">
                <div className="PerformanceDetail__GraphTabs">
                    {availableTabs.map((item) => {
                        return (
                            <div className="PerformanceDetail__GraphTab" onClick={() => setActiveTab(item.key)} key={item.key}>
                                {item.name}
                            </div>
                        )
                    })}
                </div>
                <div className="PerformanceDetail__NoGraph">
                    <TextStyle variation="subdued">No sales for the time period selected.</TextStyle>
                </div>
            </div>
        );
    }

    // TODO: Set up negative values in graph.
    const graphData = ambassador.sales_data.map((item, index) => {
        const volume = item.volume / 100;
        return { ...item, fullDate: item.date, volume }
    });

    const highestSalesValue = graphData.reduce((acc, next) => {
        if (next[activeTab] > acc) {
            return next[activeTab];
        }
        return acc;
    }, 0)

    const lowestSalesValue = graphData.reduce((acc, next) => {
        if (next[activeTab] < acc) {
            return next[activeTab];
        }
        return acc;
    }, 0)

    const topTick = findTopTick(highestSalesValue);
    const middleTick = topTick / 2;
    const bottomTick = Math.floor(lowestSalesValue / 10) * 10;

    const leftTickValues = [0, middleTick, topTick];
    if (bottomTick !== 0) {
        leftTickValues.unshift(bottomTick)
    }

    const bottomTickValues = formatBottomTickValues(graphData.map((item) => item.date));
    const axisBottom = { 
        tickValues: bottomTickValues,
        format: (date) => {
            const dateElements = date.split('-');
            return `${dateElements[1]}/${dateElements[2]}`;
        }
    }

    let axisLeft = { tickValues: leftTickValues };
    if (activeTab === graphTabs.volume.key) {
        axisLeft.format = (x) => `$${x}`
    }

    const { totalReducer } = graphTabs[activeTab];
    const totalAmount = graphData.reduce(totalReducer, 0);

    return (
        <div className="PerformanceDetail__Graph">
            <div className="PerformanceDetail__GraphTabs">
                {availableTabs.map((item) => {
                    const classes = ["PerformanceDetail__GraphTab"]
                    if (item.key === activeTab) {
                        classes.push("PerformanceDetail__GraphTab--active")
                    }

                    return (
                        <div 
                            className={classes.join(' ')} 
                            onClick={() => setActiveTab(item.key)} 
                            key={item.key}
                        >
                            {item.name}
                        </div>
                    )
                })}
            </div>

            <div className="PerformanceDetail__Summary">
                <TextStyle variation="strong">{graphTabs[activeTab].verbiage(totalAmount)}</TextStyle>
                <div className="PerformanceDetail__DateRange">
                    <FormattedDate 
                        value={new Date(`${graphData[0].fullDate}T12:00:00`)}
                        year='numeric'
                        month='long'
                        day='2-digit'
                    /> - <FormattedDate 
                        value={new Date(`${graphData[graphData.length - 1].fullDate}T12:00:00`)}
                        year='numeric'
                        month='long'
                        day='2-digit'
                    />
                </div>
            </div>

            <div className="PerforamnceDetail__GraphWrapper">
                <ResponsiveBar
                    data={graphData}
                    keys={[graphTabs[activeTab].key]}
                    indexBy="date"
                    colors="#EB4159"
                    enableLabel={false}
                    margin={{
                        "top": 10,
                        "right": 10,
                        "bottom": 30,
                        "left": 50
                    }}
                    padding={0.3}
                    maxValue={topTick}
                    minValue={bottomTick}
                    axisLeft={axisLeft}
                    axisBottom={axisBottom}
                    gridYValues={leftTickValues}
                />
            </div>
        </div>
    )

}

export default PerformanceGraph;