import React, { Component } from 'react';
import { connect } from 'react-redux';
import Tour from 'reactour';

import NavigationSidebar from '../components/navigation-sidebar/navigation-sidebar'
import Header from '../components/header/header'
import NotificationsMenu from '../components/notifications/menu/notifications-sideMenu'
import './styles/layout.css';

import { Icon } from '@shopify/polaris';
import { MobileCancelMajorMonotone } from '@shopify/polaris-icons';

import { LlamaButton } from 'llama-library/components';
import LlamaTourNavigation from '../components/llama-tour/LlamaTourNavigation';


export class Layout extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isMobile: false,
            isOpen: true,
            popoverActive: false,
            showSideDrawer: false,
            isTourOpen: false
        }
        
        this.toggleMobile = this.toggleMobile.bind(this);
        this.togglePopover = this.togglePopover.bind(this);
    }

    sideDrawerToggleHandler = (newState) => {
        if (newState !== undefined && newState !== null) {
            this.setState({ 
                showSideDrawer: newState,
                isMobile: false,
            });
        }
        this.setState({ 
            showSideDrawer: !this.state.showSideDrawer,
            isMobile: false, 
        });
    };

    toggleMobile(newState) {

        const isMobile = !newState
            ? false
            : !this.state.isMobile;

        this.setState({
            isMobile,
            showSideDrawer: false,
        });
    }

    togglePopover() {
        this.setState({
            popoverActive: !this.state.popoverActive
        });
    }

    closeTour = () => {
        this.setState({ isTourOpen: false })
    }

    openTour = () => {
        this.setState({ isTourOpen: true });
        this.props.history.push('/');
    }

    render() {

        return (
            <div className="layout__wrapper-banner">
                {/* Only show if user is coming from onboarding */}
                {/* <div className="layout__tour-start-banner">
                    <div style={{ opacity: '0' }}></div>
                    <p>Looks like you're new here. Would you like to look around? <span className="layout__tour-start-link">Start Tour</span></p>
                    <div className="layout__tour-banner-close">
                        <Icon source={MobileCancelMajorMonotone} />
                    </div>
                </div> */}
                <div className="layout">
                    <div className="layout__sidebar">
                        <NavigationSidebar
                            {...this.state}
                            {...this.props}
                            toggleMobile={this.toggleMobile}
                            togglePopover={this.togglePopover}
                            sideDrawerToggleHandler={this.sideDrawerToggleHandler}
                            signOut={this.props.signOut}
                            openTour={this.openTour}
                        />
                        <LlamaTourNavigation 
                            {...this.state}
                            {...this.props}
                            isTourOpen={this.state.isTourOpen}
                            closeTour={this.closeTour}
                        />
                    </div>
                    <div className="layout__main">
                        <div className="layout__header">
                            <Header
                                {...this.props}
                                {...this.state}
                                toggleSideDrawer={this.sideDrawerToggleHandler}
                            />
                        </div>
                        {this.props.children}
                    </div>
                    <div className="layout__notifications">
                        <NotificationsMenu
                            {...this.props}
                            {...this.state}
                            open={this.state.showSideDrawer}
                            closed={() => this.sideDrawerToggleHandler(false)}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ advertiser, authData }) => {
    return { advertiser, authData }
}

export default connect(mapStateToProps)(Layout);

