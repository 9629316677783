import { useState, useEffect } from 'react';
import moment from 'moment';

import { getAmbassadorPerformanceReports } from '../actions/ambassador-reports';

const addOrdersByDay = (offerReport, summary, subtotal, order) => {
    const orderDate = order.order.created_at.substr(0, 10);
    offerReport.ordersByDayObj[orderDate].revenue += subtotal;
    offerReport.ordersByDayObj[orderDate].orderCount += 1;
    summary.ordersByDayObj[orderDate].revenue += subtotal;
    summary.ordersByDayObj[orderDate].orderCount += 1;
};

const addDiscountOrders = (offerReport, summary, subtotal, order, qualifyingDiscountCodes) => {
    if (order.order.discount_codes) {
        order.order.discount_codes.forEach((discount) => {
            if (qualifyingDiscountCodes.indexOf(discount.code) !== -1) {
                const discountAmount = parseFloat(discount.amount);

                offerReport.discounts.totalDiscounts += discountAmount;
                offerReport.discounts.orderCount += 1;
                offerReport.discounts.totalRevenue += subtotal;

                summary.discounts.totalDiscounts += discountAmount;
                summary.discounts.orderCount += 1;
                summary.discounts.totalRevenue += subtotal;
            }
        });
    }
};

const addLinkOrders = (offerReport, summary, subtotal, order) => {
    if (order.order.source_type === 'link') {
        offerReport.linkOrders.orderCount += 1;
        offerReport.linkOrders.totalRevenue += subtotal;
        summary.linkOrders.orderCount += 1;
        summary.linkOrders.totalRevenue += subtotal;
    }
};

const addRepeatOrders = (offerReport, summary, subtotal, order, customers, repeatCustomers) => {
    const { customer_id } = order.customer_info;
    // all unique customers
    if (customers.indexOf(customer_id) === -1) {
        customers.push(customer_id);
    }

    // repeat orders
    if (order.customer_info.order_id !== order.customer_info.initial_order) {
        // store only first instances of a repeat customer
        if (repeatCustomers.indexOf(customer_id) === -1) {
            repeatCustomers.push(customer_id);
            offerReport.repeatOrders.customers += 1;
            summary.repeatOrders.customers += 1;
        }

        offerReport.repeatOrders.orderCount += 1;
        offerReport.repeatOrders.totalRevenue += subtotal;
        summary.repeatOrders.orderCount += 1;
        summary.repeatOrders.totalRevenue += subtotal;
    }
};

const parseReport = (report, reportSchema) => {
    const summary = JSON.parse(JSON.stringify(reportSchema));

    const offers = report.offers.map((offer) => {
        const offerReport = JSON.parse(JSON.stringify(reportSchema));

        const customers = [];
        const repeatCustomers = [];

        // get discount codes assigned to the ambassador
        const qualifyingDiscountCodes = offer.discounts.reduce((codes, discount) => {
            if (discount.affiliate_id === report.ambassador.tracking_id) {
                codes.push(discount.code);
            }
            return codes;
        }, []);

        summary.linkOrders.clicks += offer.clicks;

        // ignore orders that are null objects
        const orders = offer.orders.filter((order) => {
            return order.order.order_id !== null;
        });

        if (orders.length > 0) {
            orders.forEach((order) => {
                const subtotal = parseFloat(order.order.subtotal_price);

                // add up revenue and order count
                offerReport.revenue += subtotal;
                offerReport.orderCount += 1;
                summary.revenue += subtotal;
                summary.orderCount += 1;

                // add up revenue and order count per day
                addOrdersByDay(offerReport, summary, subtotal, order);

                // add up discounts
                addDiscountOrders(offerReport, summary, subtotal, order, qualifyingDiscountCodes);

                // add up link based orders
                addLinkOrders(offerReport, summary, subtotal, order);

                // add up repeat orders
                addRepeatOrders(offerReport, summary, subtotal, order, customers, repeatCustomers);
            });
        }

        // format orders by day for graph
        const ordersByDay = Object.keys(offerReport.ordersByDayObj).map((day) => {
            const orderData = offerReport.ordersByDayObj[day];
            return {
                ...orderData,
                avgOrderRevenue: (orderData.orderCount > 0) ? orderData.revenue / orderData.orderCount : 0,
                day
            };
        });
        delete offerReport.ordersByDayObj;

        summary.totalCustomers += customers.length;
        offerReport.totalCustomers = customers.length;

        return {
            ...offer,
            ...offerReport,
            avgOrderRevenue: (offerReport.orderCount > 0) ? offerReport.revenue / offerReport.orderCount : 0,
            ordersByDay,
            totalCustomers: customers.length
        };
    });

    summary.ordersByDay = Object.keys(summary.ordersByDayObj).map((day) => {
        const orderData = summary.ordersByDayObj[day];
        return {
            ...orderData,
            avgOrderRevenue: (orderData.orderCount > 0) ? orderData.revenue / orderData.orderCount : 0,
            day
        };
    });
    delete summary.ordersByDayObj;
    summary.avgOrderRevenue = (summary.orderCount > 0) ? summary.revenue / summary.orderCount : 0;

    return {
        ambassador: report.ambassador,
        offers,
        summary
    };
};

const useAmbassadorReports = (advertiser_id, filters) => {
    const [ambassadors, setAmbassadors] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [numPages, setNumPages] = useState(0);
    const [count, setCount] = useState(0);

    useEffect(() => {
        const { startDate, endDate, offer_id, searchValue, sortBy, page } = filters;
        const dateRange = { startDate, endDate };

        if (advertiser_id && !isLoading) {
            setIsLoading(true);
            getAmbassadorPerformanceReports(advertiser_id, dateRange, offer_id, searchValue, sortBy, page)
                .then((data) => {
                    const days = moment(endDate).diff(moment(startDate), 'days');
                    let nextDay = moment(startDate);
                    const dayRangeSchema = {};
                    for (let i = 0; i <= days; i++) {
                        dayRangeSchema[nextDay.format('YYYY-MM-DD')] = {
                            revenue: 0,
                            orderCount: 0,
                            avgOrderRevenue: 0
                        };
                        nextDay = nextDay.add(1, 'day');
                    }
                    const reportSchema = {
                        revenue: 0,
                        orderCount: 0,
                        ordersByDayObj: JSON.parse(JSON.stringify(dayRangeSchema)),
                        discounts: {
                            totalDiscounts: 0,
                            orderCount: 0,
                            totalRevenue: 0
                        },
                        repeatOrders: {
                            orderCount: 0,
                            totalRevenue: 0,
                            customers: 0
                        },
                        totalCustomers: 0,
                        linkOrders: {
                            clicks: 0,
                            orderCount: 0,
                            totalRevenue: 0
                        }
                    };

                    setNumPages(data.pageInfo.pages);
                    setCount(data.pageInfo.count);

                    const parsedReports = data.reports.map((report) => {
                        return parseReport(report, reportSchema);
                    });
                    setAmbassadors(parsedReports);
                    setIsLoading(false);

                    return data.reports;
                })
                .catch((error) => {
                    if (error.message === 'Network Error') {
                        setErrorMessage({
                            message: 'Connection Lost',
                            paragraph: 'Oops, looks like you might have gotten disconnected. Check your internet connection, then try again.'
                        });
                    }
                    setIsLoading(false);
                });
        }
    }, [advertiser_id, filters]);

    return {
        ambassadors,
        isLoading,
        errorMessage,
        numPages,
        count
    };

};

export default useAmbassadorReports;
