import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    Route,
    Switch,
    withRouter,
    Redirect
} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { IntlProvider } from 'react-intl';
import { AuthPiece } from 'aws-amplify-react';
import { Auth } from 'aws-amplify';
import { Banner } from '@shopify/polaris';
import { Elements } from 'react-stripe-elements';
import { STRIPE_KEY } from './config';

import './App.css';
import Offers from './containers/offers';
import Settings from './containers/settings';
import InviteSignupContainer from './containers/invite-signup';
//import NotificationsSettings from './containers/notifications-settings';
import DiscoverContainer from './containers/discover';
import Dashboard from './containers/dashboard';
import AmbassadorPerformance from './containers/ambassador-performance';
/* import AffiliateFilters from './containers/affiliate-filters'; */
import AffiliateInfo from './containers/affiliate-info';
import Applications from './containers/applications';
import Layout from './containers/layout';
import AffiliateImport from './containers/affiliate-import';
import Products from './containers/products';
import NewOfferContainer from './containers/offer-new';
import OfferViewContainer from './containers/offer-view';
import OfferEditContainer from './containers/offer-edit';
import OfferManageContainer from './containers/offer-manage';
import Onboarding from './containers/onboarding';
import InvoicesContainer from './containers/invoices';
import fourOhfour from './containers/fourOhfour';
import LlamaTOS from './components/llama-tos/llama-tos';
import LlamaPrivacy from './components/llama-privacy/llama-privacy';
import LlamaDmca from './components/llama-dmca/llama-dmca';
//import Notifications from './components/notifications/menu/notifications-sideMenu';
import InitialPreloader from './components/initial-preloader/initial-preloader';
import LlamaGDPR from './components/llama-gdpr/llama-gdpr';
import PaymentPaypal from './components/payment-paypal/payment-paypal';
import LeoHelpWidget from './components/help-widget/leo-help-widget';
import Chat from './containers/chat';

import { getOffers } from './actions/offers';
import { getAdvertiser } from './actions/advertiser';
import { updateAdvertiser } from './actions/update-advertiser';
import { getAuthData, updateAuthData } from './actions/auth-data';
import { cognitoUserValidation } from './actions/get-cognito-user-validation';
import { getNotifications } from './actions/notifications';
import { getAppSubscription } from './actions/app-subscription';
import { setOpenSocket } from './actions/websocket';
import { getUnreadChatMessages, addMessageIdToUnread } from './actions/chat/action_chatMessages';

const checkForExpiredTrial = (advertiser, history) => {
    if (!advertiser) {
        return null;
    }

    const { status } = advertiser;
    if (status === 'EXPIRED') {
        history.push('/settings/plan');
    }

    return status;
};

/**
 * @function ProtectedRoute
 * Takes in Component, advertiser and props,
 * returns Route to requested Component only if
 * user is not expired. Otherwise redirects
 * to Plan Settings.
 * 
 * @param {Component} Component - Component being passed to Route
 * @param {Object} advertiser - advertiser from the getAdvertiser call
 * @param {Object} rest - rest of props passed to Route
 */
// const ProtectedRoute = ({ component: Component, advertiser, ...rest }) => {
//     return (
//         <Route
//         {...rest}
//         render={(props) => {
//             if(advertiser && advertiser.status === "EXPIRED") {
//                 return (
//                     <Redirect 
//                         to={{ 
//                             pathname: "/settings/plan",
//                             state: {
//                                 from: props.location
//                             }
//                         }} 
//                     />
//                 )
//             } else {
//                 return (
//                     <Component {...props} />
//                 )
//             }
//         }}
//         />
//     )
// };

/**
 * @function ProtectedRoute
 * Wrapper function that returns a the Route if no error charges exist
 * and returns a Redirect to Plan Settings if error charge exists.
 * Service should continue to be provided to the merchant as long
 * as their recurring charge is in the active state.
 *
 * @param {Component} Component - what is wrapped in the route
 * @param {Array} charges - charges on merchant's plan subscription
 */
const ProtectedRoute = ({ component: Component, charges, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) => {
                // Check charges array for any status that is !active
                const errorChargeExists = charges && charges.find((charge) => {
                    return charge.status !== 'active';
                });
                if (errorChargeExists) {
                    return (
                        <Redirect
                            to={{
                                pathname: '/settings/plan',
                                state: {
                                    from: props.location
                                }
                            }}
                        />
                    );
                }
                return (
                    <Component {...props} />
                );
            }}
        />
    );
};

ProtectedRoute.propTypes = {
    component: PropTypes.elementType.isRequired,
    charges: PropTypes.arrayOf(PropTypes.shape({
        status: PropTypes.string
    })),
    location: PropTypes.shape({
        pathname: PropTypes.string
    })
};

ProtectedRoute.defaultProps = {
    charges: [],
    location: null
};

class App extends AuthPiece {
    constructor(props) {
        super(props);

        this._validAuthStates = ['signedIn'];
        this.state = {
            advertiserDataLoaded: null,
            getAdvertiserLoading: false,
            userAuthDataLoaded: false,
            userAuthDataLoading: true,
            authData: props.authData,
            showToast: false,
            loadedAdvertiser: null,
            charges: []
        };

        this.getAuthData = this.getAuthData.bind(this);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.authData !== prevState.authData) {
            return {
                authData: nextProps
            };
        }

        return null;
    }

    getNotifications(advertiser_id) {
        this.props.getNotifications(advertiser_id)
            .then(() => {
                setTimeout(() => {
                    this.getNotifications(advertiser_id);
                }, 900000); // 15 minutes.
            });
    }

    getAuthData() {
        this.setState({ userAuthDataLoading: true });

        this.props.getAuthData()
            .then(({ value: user }) => {
                if (user.signInUserSession.accessToken.payload['cognito:groups'][0] !== 'Advertisers') {
                    this.toggleToast();
                    return null;
                }

                this.setState({ userAuthDataLoaded: true, userAuthDataLoading: false, authData: user });
                return user;
            })
            .then((user) => {
                let myshopify_domain = user && user.attributes && user.attributes['custom:myshopify_domain'];

                if (myshopify_domain && !this.state.getAdvertiserLoading) {
                    this.setState({ getAdvertiserLoading: true });
                    this.props.getAdvertiser(myshopify_domain)
                        .then((result) => {
                            // Validate response.
                            if (
                                !result
                                || !result.value
                                || !result.value.data
                                || !Array.isArray(result.value.data.advertisers)
                                || !result.value.data.advertisers[0]
                            ) {
                                return null;
                            }

                            const advertiser = result.value.data.advertisers[0];
                            this.setState({ advertiserDataLoaded: true, getAdvertiserLoading: false });

                            // Check if trial has expired.
                            // checkForExpiredTrial(advertiser, this.props.history);

                            // Get Shopify Subscription
                            getAppSubscription(advertiser.advertiser_id)
                                .then((subscriptionResult) => {
                                    const accountCharges = subscriptionResult.data.advertisers[0].charges;
                                    this.setState({ charges: accountCharges });
                                });
                            this.setState({ loadedAdvertiser: advertiser });
                            return advertiser;
                        })
                        .then((advertiser) => {
                            if (!advertiser) {
                                return null;
                            }

                            // Check if we should redirect to signup flow.
                            if (
                                this.props.location.pathname !== '/payment/paypal/'
                                && (advertiser.sign_up_flow === false
                                || advertiser.sign_up_flow == null)
                            ) {
                                this.props.history.push('/signUp/1');
                            }

                            if (this.props.location.search.indexOf('user_token=') !== -1) {
                                this.props.history.replace({ search: '' });
                            }

                            return advertiser;
                        })
                        .then(async (advertiser) => {
                            if (!advertiser) {
                                return null;
                            }

                            // Update advertiser data with new jwtToken.
                            const data = {
                                account: {
                                    accessToken: user.signInUserSession.accessToken.jwtToken,
                                    idToken: user.signInUserSession.idToken.jwtToken,
                                    refreshToken: user.signInUserSession.refreshToken.token
                                }
                            };

                            // start a websocket connection for chat online status and in app notifications
                            console.log('COMPONENT DID MOUNT', this.state, this.props);
                            if (this.props.advertiser.advertiser_id && !this.props.openSocket) {
                                this.props.dispatchSetOpenSocket(this.props.advertiser.advertiser_id);

                                this.props.openSocket.addEventListener('message', (response) => {
                                    const reply = JSON.parse(response.data);
                                    if (reply.action === 'newMessage' && reply.sender === 'affiliate') {
                                        this.props.dispatchAddMessageIdToUnread(reply.affiliate_id, reply._id);

                                        // if not on chat page, spawn a notification toast
                                        /* TODO if (!this.props.history.location.pathname.includes('/chat')) {
                                            alert('new message');
                                        } */
                                    }
                                });
                            }
                            await this.props.dispatchGetUnreadChatMessages(this.props.advertiser.advertiser_id);

                            this.props.updateAdvertiser(data, advertiser);
                            return advertiser;
                        })
                        .then((advertiser) => {
                            if (!advertiser) {
                                return null;
                            }

                            setTimeout(() => {
                                this.getNotifications(advertiser.advertiser_id);
                            }, 900000);

                            return advertiser;
                        });
                } else {
                    this.setState({ getAdvertiserLoading: false });
                }
            })
            .catch(() => {
                this.setState({ userAuthDataLoading: false });
            });
    }

    componentDidMount() {
        this.getAuthData();
        this.checkAuthData(this.props);

        // Add function to execute on every route change.
        this.unlisten = this.props.history.listen(() => {
            this.onRouteChanged();
        });

        if (window.Stripe) {
            this.setState({ stripe: window.Stripe(`${STRIPE_KEY}`) });
        } else {
            document.querySelector('#stripe-js').addEventListener('load', () => {
                // Create Stripe instance once Stripe.js loads
                this.setState({ stripe: window.Stripe(`${STRIPE_KEY}`) });
            });
        }
    }

    componentWillUnmount() {
        this.unlisten();
    }

    checkAuthData(props) {
        if (!this.props.storedAuthData && props.authData) {
            this.props.updateAuthData(props.authData);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.history.action === 'PUSH' && this.props.location.pathname !== prevProps.location.pathname) {
            window.scrollTo(0, 0);
        }

        if (prevProps.authData !== this.props.authData) {
            console.log('GO for auth call');
            this.getAuthData();
            this.checkAuthData(this.props);
        }
    }

    onRouteChanged() {
        if (this.state.userAuthDataLoading || !this.state.userAuthDataLoaded) {
            return;
        }

        if (
            !this.props.storedAuthData
            || !this.props.storedAuthData.signInUserSession
            || !this.props.storedAuthData.signInUserSession.accessToken
            || !this.props.storedAuthData.signInUserSession.accessToken.jwtToken
        ) {
            this.signOut();
        }

        if (process.env.NODE_ENV === 'development') {
            return;
        }

        this.props.cognitoUserValidation(this.props.storedAuthData.signInUserSession.accessToken.jwtToken)
            .then((result) => {
                if (result.value.Username === this.props.storedAuthData.username) {
                    return;
                }

                this.signOut();
            });
    }

    toggleToast = () => {
        this.setState({ showToast: true }, () => {
            setInterval(this.signOut, 4000);
        });
    };

    signOut = () => {
        // disconnect websocket connection
        if (this.props.openSocket) {
            this.props.openSocket.close();
        }
        Auth.signOut();
        this.props.history.push('/'); // redirect to homepage post signout
    }

    showComponent() {
        if (this.props.location.pathname === '/payment/paypal/') {
            return <PaymentPaypal />;
        }

        if (!this.state.advertiserDataLoaded) {
            return <InitialPreloader />;
        }

        const { authData, showToast, loadedAdvertiser } = this.state;
        const email = authData && authData.attributes && authData.attributes.email;
        const advertiser_id = authData && authData.attributes && authData.attributes['custom:advertiser_id'];
        const toastMarkup = showToast ? (<Banner title="System Error" status="critical">You are not registered with this portal, Please signup to use it.</Banner>) : null;

        const advName = (this.props.advertiser && this.props.advertiser.name)
            ? this.props.advertiser.name
            : null;
        const advEmail = (this.props.advertiser && this.props.advertiser.email)
            ? this.props.advertiser.email
            : null;
        const myshopify_domain = (this.props.advertiser && this.props.advertiser.myshopify_domain)
            ? this.props.advertiser.myshopify_domain
            : null;

        return (
            <IntlProvider locale={navigator.language}>
                <Layout {...this.state} history={this.props.history} signOut={this.signOut}>
                    {toastMarkup}
                    <div className="App">
                        <Helmet>
                            <script>
                                {`
                                    var driftApi = null;
                                    !function () {
                                    var t;
                                    if (t = window.driftt = window.drift = window.driftt || [], !t.init) return t.invoked ? void (window.console && console.error && console.error("Drift snippet included twice.")) : (t.invoked = !0,
                                        t.methods = ["identify", "config", "track", "reset", "debug", "show", "ping", "page", "hide", "off", "on"],
                                        t.factory = function (e) {
                                        return function () {
                                            var n;
                                            return n = Array.prototype.slice.call(arguments), n.unshift(e), t.push(n), t;
                                        };
                                        }, t.methods.forEach(function (e) {
                                        t[e] = t.factory(e);
                                        }), t.load = function (t) {
                                        var e, n, o, i;
                                        e = 3e5, i = Math.ceil(new Date() / e) * e, o = document.createElement("script"),
                                            o.type = "text/javascript", o.async = !0, o.crossorigin = "anonymous", o.src = "https://js.driftt.com/include/" + i + "/" + t + ".js",
                                            n = document.getElementsByTagName("script")[0], n.parentNode.insertBefore(o, n);
                                        });
                                    }();
                                    drift.reset();
                                    drift.load('5uggc9iy5imi');
                                    drift.on('ready', () => {
                                        if('${advName}' !== 'null'){
                                            drift.identify('${myshopify_domain}', {
                                                email: '${advEmail}',
                                                name: '${advName}'
                                            })
                                        }
                                    })
                                `}
                            </script>
                        </Helmet>
                        <div className="App__Main">
                            <Switch>
                                <ProtectedRoute path="/" component={Dashboard} exact />
                                {/* not currently in use <ProtectedRoute path="/affiliate-filters" charges={this.state.charges} component={AffiliateFilters} exact /> */}
                                <ProtectedRoute path="/performance" charges={this.state.charges} component={AmbassadorPerformance} exact />
                                <ProtectedRoute path="/offers" charges={this.state.charges} component={Offers} />
                                <ProtectedRoute path="/offer-new" charges={this.state.charges} component={NewOfferContainer} exact />
                                <ProtectedRoute path="/offer/:id/edit" charges={this.state.charges} component={OfferEditContainer} />
                                <ProtectedRoute path="/offer/:id/manage" charges={this.state.charges} component={OfferManageContainer} exact />
                                <ProtectedRoute path="/offer/:id" charges={this.state.charges} component={OfferViewContainer} />
                                <ProtectedRoute path="/invite" charges={this.state.charges} component={InviteSignupContainer} />
                                {/* this route is in Settings <ProtectedRoute path="/notifications" charges={this.state.charges} component={NotificationsSettings} exact /> */}
                                <ProtectedRoute path="/applications" charges={this.state.charges} component={Applications} exact />
                                <ProtectedRoute path="/discover" charges={this.state.charges} component={DiscoverContainer} exact />
                                {/* duplicate of /affiliate/:id and not currently in use <ProtectedRoute path="/discover/affiliate/:id" charges={this.state.charges} component={AffiliateInfo} exact /> */}
                                <ProtectedRoute path="/affiliate/:id" charges={this.state.charges} component={AffiliateInfo} exact />
                                <ProtectedRoute path="/products" charges={this.state.charges} component={Products} exact />
                                <ProtectedRoute path="/import-ambassadors" charges={this.state.charges} component={AffiliateImport} exact />
                                <ProtectedRoute path="/invoices" charges={this.state.charges} component={InvoicesContainer} exact />
                                {/* <Route path="/alerts" render={(props) => <Notifications open={true} isMobile={true} {...props} />} /> */}
                                <ProtectedRoute path="/signUp/:id" exact charges={this.state.charges} component={Onboarding} />
                                <ProtectedRoute path="/chat" exact component={Chat} />
                                <ProtectedRoute path="/chat/:affiliate_id" exact component={Chat} />
                                {/* duplicate of /signUp/:id and not currently in use <ProtectedRoute path="/signUp/:id/auth/" charges={this.state.charges} component={Onboarding} exact /> */}
                                <Route path="/terms" component={LlamaTOS} exact />
                                <Route path="/privacy" component={LlamaPrivacy} exact />
                                <Route path="/dmca" component={LlamaDmca} exact />
                                <Route path="/gdpr" component={LlamaGDPR} exact />
                                <Route path="/payment/paypal" component={PaymentPaypal} exact />
                                <Elements stripe={this.state.stripe}>
                                    <Route path="/settings" component={Settings} />
                                </Elements>
                                <Route component={fourOhfour} />
                            </Switch>
                        </div>
                    </div>
                    <LeoHelpWidget
                        currentPath={this.props.location.pathname}
                        name={advName}
                    />
                </Layout>
            </IntlProvider>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        advertiser: state.advertiser,
        status: state.advertiser.status,
        storedAuthData: state.authData,
        openSocket: state.app.openSocket
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getOffers: (id) => { return dispatch(getOffers(id)); },
        getAdvertiser: (shop) => { return dispatch(getAdvertiser(shop)); },
        getAuthData: () => { return dispatch(getAuthData()); },
        updateAuthData: (authData) => { return dispatch(updateAuthData(authData)); },
        updateAdvertiser: (data, advertiser) => { return dispatch(updateAdvertiser(data, advertiser)); },
        cognitoUserValidation: (token) => { return dispatch(cognitoUserValidation(token)); },
        getNotifications: (advertiser_id) => { return dispatch(getNotifications(advertiser_id)); },
        dispatchSetOpenSocket: (affiliate_id) => { return dispatch(setOpenSocket(affiliate_id)); },
        dispatchGetUnreadChatMessages: (advertiser_id) => { return dispatch(getUnreadChatMessages(advertiser_id)); },
        dispatchAddMessageIdToUnread: (senderId, messageId) => { return dispatch(addMessageIdToUnread(senderId, messageId)); }
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
