import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import useResponsive from '../../hooks/use-responsive';

import './tabs.css';

/**
 * Responsive Link-Based Tabs that adjust to fit, scroll, or drop down based on the available space
 * @param {Array} tabs Each tab should be an object with a key, name, and link. To only show a tab on mobile, set mobileOnly to true
 * @param {Object} location The current page's window location object
 * @param {String} linkPrefix Optional. Set if your tab links don't contain the full path
 * @param {Enum} initialFit Optional. Auto will apply equal widths to each tab and center the text if enough room is available. Condensed will make each tab only as wide as its name.
 */
const LlamaTabs = ({ tabs, location, linkPrefix, initialFit = 'auto' }) => {
    const mobileWidth = 860;

    const [fitType, setFitType] = useState(initialFit);

    const [tabsOpen, setTabsOpen] = useState(false);
    const [currentTab, setCurrentTab] = useState(tabs[0]);

    const [tabMaxWidth, setTabMaxWidth] = useState(0);
    const [tabsTotalWidth, setTabsTotalWidth] = useState(0);

    const pageWidth = useResponsive();

    // update pageWidth when browser resizes, set the initial current tab based on the url
    useEffect(() => {
        const path = location && location.pathname && location.pathname.split('/');
        const tabKey = path.pop();
        const tab = tabs.find((tabDetails) => {
            return tabDetails.key === tabKey;
        });

        if (tab) {
            setCurrentTab(tab);
        }
    }, []);

    // show/hide dropdown nav for mobile
    useEffect(() => {
        if (window.innerWidth <= mobileWidth && tabsOpen) {
            setTabsOpen(false);
        } else if (window.innerWidth > mobileWidth && !tabsOpen) {
            setTabsOpen(true);
        }
    }, [pageWidth]);

    // dynamically change tab fit behavior based on available space
    useEffect(() => {
        if (window.innerWidth > mobileWidth && tabsOpen) {
            const containerWidth = document.querySelector('.llama-tabbed-nav').offsetWidth;
            const numTabs = tabs.length;

            // if available container width is shorter than all the tabs width + margin right, set to scrolling
            if (containerWidth + 30 <= tabsTotalWidth) {
                setFitType('scrolling');
            // if available space per tab exceeds the longest tab width and condensed was not passed in with props, set to fit
            } else if (initialFit === 'auto' && containerWidth / numTabs > tabMaxWidth && fitType !== 'auto') {
                setFitType('auto');
            // if available space per tab is shorter than the longest tab width, set to condensed
            } else if (containerWidth / numTabs < tabMaxWidth && fitType !== 'condensed') {
                setFitType('condensed');
            }
        }
    }, [pageWidth, tabMaxWidth]);

    // store the longest tab width and total tab width
    useEffect(() => {
        if (tabsOpen) {
            const tabTexts = document.querySelectorAll('.llama-tabbed-nav span');
            const tabWidths = [];
            let tabWidthTotal = 0;

            for (let i = 0; i < tabTexts.length; i++) {
                tabWidths.push(tabTexts[i].offsetWidth);
                tabWidthTotal += tabTexts[i].offsetWidth + 30; // 30 is the margin right
            }
            tabWidths.sort((a, b) => {
                if (a > b) {
                    return -1;
                }
                if (a < b) {
                    return 1;
                }
                return 0;
            });
            setTabMaxWidth(tabWidths[0]);
            setTabsTotalWidth(tabWidthTotal);
        }
    }, [tabsOpen]);

    useEffect(() => {
        // hide dropdown nav for mobile (on page switch)
        if (window.innerWidth <= mobileWidth) {
            setTabsOpen(false);
        }
    }, [currentTab]);

    return (
        <nav className="llama-tabbed-nav" data-type={fitType} data-open={tabsOpen}>
            {pageWidth <= mobileWidth
                && <p><button type="button" className="current-tab" onClick={() => { return setTabsOpen(!tabsOpen); }}>{currentTab.name}</button></p>
            }
            {tabsOpen
                && (
                    <ul>
                        {tabs.map((tab) => {
                            if (tab.mobileOnly && pageWidth > mobileWidth) {
                                return '';
                            }
                            return (
                                <li key={tab.key}>
                                    <NavLink
                                        exact
                                        to={`${linkPrefix}${tab.link}`}
                                        activeClassName="active"
                                        onClick={() => { return setCurrentTab(tab); }}
                                    >
                                        <span>{tab.name}</span>
                                    </NavLink>
                                </li>
                            );
                        })}
                    </ul>
                )
            }
        </nav>
    );
};

LlamaTabs.propTypes = {
    tabs: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        key: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired,
        mobileOnly: PropTypes.bool
    })).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired
    }).isRequired,
    linkPrefix: PropTypes.string,
    initialFit: PropTypes.oneOf(['auto', 'condensed'])
};

LlamaTabs.defaultProps = {
    linkPrefix: '',
    initialFit: 'auto'
};

export default LlamaTabs;
