import React from 'react';
import {
    Card, Button, Banner, List, DataTable, Layout, Badge, ResourceList
} from '@shopify/polaris';

import { format, isBefore, parseISO } from 'date-fns';

import CenteredSpinner from '../centered-spinner';
import { AppSubscriptionPlan } from '../../actions/app-subscription';
import './settings-plan.css';
import usePages from '../../hooks/use-pages';

import { LlamaPagination } from 'llama-library/components';

const banners = {
    TRIAL: ({ trial_length }) => (
        <Banner
            title="You are currently on a Trial Membership, please select a plan before your trial ends."
            status="warning"
        >
            {trial_length && <List>
                <List.Item>
                    {trial_length} days left on trial membership.
                </List.Item>
            </List>}
        </Banner>
    ),
    EXPIRED: () => (
        <Banner
            title="Your Trial Membership has expired.  Please select a plan option to continue with the program."
            status="critical"
        >
        </Banner>
    ),
    INACTIVE: () => (
        <Banner
            title="Your Membership is inactive.  Please select a plan option to continue with the program."
            status="critical"
        ></Banner>
    )
}

const errorBanners = {
    declined: <Banner
            title="Subscription charge was declined"
            status="critical"
        >
            Your recurring subscription charge has been declined.
        </Banner>,
    expired:
        <Banner
            title="Subscription charge has expired"
            status="critical"
        >
            Your recurring subscription charge was not accepted within 2 days of being created.
        </Banner>
    ,
    frozen:
        <Banner
            title="Subscription charge is frozen"
            status="warning"
        >
            The recurring subscription charge is on hold due to a shop subscription non-payment. The charge will re-activate once subscription payments resume.
        </Banner>
    ,
    cancelled:
        <Banner
            title="Subscription charge was cancelled"
            status="warning"
        >
            The recurring subscription charge was cancelled.
        </Banner>
}

/**
 * @function renderErrorBanner
 * Takes charges array, filters out any charges that are not active
 * Maps through filtered charges and returns the corresponding
 * error banner for the status
 * 
 * @param {Array} charges - charges Array on merchant
 */
const renderErrorBanner = (charges) => {
    const errorStatuses = charges.filter((charge) => {
        if(charge.status !== "active") {
            return true;
        }
    })
    return errorStatuses.map((charge, index) => {
        return (
            <div key={index} data-test="component-errorBanner">
                {errorBanners[charge.status]}
            </div>
        )
    })
}

const renderStatement = (item) => {

    // Determine status of recurring charge
    const statusBadges = {
       active: <Badge status="success">Active</Badge>,
       pending: <Badge status="attention">Pending</Badge>,
       accepted: <Badge status="success">Accepted</Badge>,
       declined: <Badge status="critical">Declined</Badge>,
       expired: <Badge status="critical">Expired</Badge>,
       frozen: <Badge status="warning">Frozen</Badge>,
       cancelled: <Badge status="attention">Cancelled</Badge>
    }

    const { price, billing_on, status } = item;
    // const statementStatus = status === "active" ? <Badge status="success">Active</Badge> : <Badge status="attention">{status}</Badge>
    const statementStatus = statusBadges[status];

    return (

        <ResourceList.Item>
            <div className="statements__item">
                <div className="statements__amount">${price}</div>
                <div className="statements__date">{format(parseISO(billing_on), 'LL-dd-yyyy')}</div>
                <div className="statements__due-date">{format(parseISO(billing_on), 'LL-dd-yyyy')}</div>
                <div className="statements__status">{statementStatus}</div>
            </div>
        </ResourceList.Item>

    )
}

/**
 * @function PlanSettings
 * Accepts props, returns PlanSettings Component.
 * Displays merchant's current Llama Plan (monthly or annual),
 * and banner based on the current status of their plan.
 * 
 * @param {Object} props 
 * 
 * @returns {FunctionComponent}
 */
const PlanSettings = (props) => {

    const { 
        dataIsLoading,
        selectionIsLoading, 
        plan, 
        advertiser, 
        displaySelection, 
        toggleDisplaySelection, 
        planSelected,
        starterMonthly,
        selectPlan,
        proMonthly,
        charges
    } = props;

    const sortedCharges = charges && charges
        .sort((a,b) => {
            if(a.billing_on < a.billing_on) return 1;
            if(b.billing_on < a.billing_on) return -1;
            return 0;
        });

    let {
        previousPage,
        nextPage,
        currentPage,
        hasNext,
        hasPrevious,
        maxPages,
        posts
    } = usePages(0, 4, sortedCharges);

    if(dataIsLoading) {
        return <CenteredSpinner/>
    }

    let trialEndDate, billingDate, isTrial;

    if (plan) {
        const now = new Date();
        trialEndDate = new Date(plan.trial_ends_on);
        billingDate = new Date(plan.billing_on);
        isTrial = isBefore(now, trialEndDate);
    }

    // Show Plan Selection UI if merchant is in the 30-Day Free trial
    // or if merchant selects Upgrade
    if(advertiser.status === "TRIAL" || displaySelection) {
        return (
            <>
            {banners[advertiser.status] && banners[advertiser.status](advertiser)}
            <div className="settings-plan__wrapper" data-test="component-planSelection">

                {/* Starter Plan */}
                <div className={planSelected === AppSubscriptionPlan.TIER_1 ? "settings-plan__plan settings-plan__starter--active" : "settings-plan__plan"}>
                    <div className="settings-plan__plan-top settings-plan__starter-top"></div>
                        <h2 className="settings-plan__plan-name">
                            Starter
                        </h2>
                        {starterMonthly === true ?
                        <div className="settings-plan__plan-price settings-plan__starter-price">
                            <span>$29</span> / mo*
                        </div>
                        :
                        <div className="settings-plan__plan-price settings-plan__starter-price">
                            <span>$24</span> / mo*
                        </div>
                        }

                        <ul className="settings-plan__feature-list">
                            <li className="settings-plan__feature">Unlimited Influencers</li>
                            <li className="settings-plan__feature">Unlimited Campaigns</li>
                            <li className="settings-plan__feature">300 Orders Per Month</li>
                            <li className="settings-plan__feature">10,000 Unique Visitors Per Month</li>
                            <li className="settings-plan__feature">Paypal Mass Payment</li>
                            <li className="settings-plan__feature">Live Chat</li>
                        </ul>

                        <p className={starterMonthly === true ? "settings-plan__plan-disclaimer--hidden" : "settings-plan__plan-disclaimer"}>
                            *Price reflects yearly plan
                        </p>
                        
                        {planSelected === AppSubscriptionPlan.TIER_1 //&& !selectionIsLoading 
                            ?
                                <a className="settings-plan__cta--selected">
                                    Selected
                                </a>
                            :
                                <Button 
                                    primary
                                    loading={selectionIsLoading}
                                    // loading={selectionIsLoading && planSelected === AppSubscriptionPlan.TIER_1}
                                    onClick={() => selectPlan(AppSubscriptionPlan.TIER_1)}
                                    data-test="button-selectStarter"
                                >
                                    Select
                                </Button>
                        }
                        
                    </div>
        
                    {/* Pro Plan */}
                    <div className={planSelected === AppSubscriptionPlan.TIER_2 ? "settings-plan__plan settings-plan__pro--active" : "settings-plan__plan"}>
                        <div className="settings-plan__plan-top settings-plan__pro-top"></div>
                        <h2 className="settings-plan__plan-name">
                            Pro
                        </h2>
                        {proMonthly === true ?
                        <div className="settings-plan__plan-price settings-plan__pro-price">
                            <span>$99</span> / mo*
                        </div>
                        :
                        <div className="settings-plan__plan-price settings-plan__pro-price">
                            <span>$82</span> / mo*
                        </div>
                        }

                        <p className="settings-plan__plan-caption">
                            Everything in Starter, Plus...
                        </p>

                        <ul className="settings-plan__feature-list">
                            <li className="settings-plan__feature">1,000 Orders Per Month</li>
                            <li className="settings-plan__feature">100,000 Unique Vistors Per Month</li>
                            <li className="settings-plan__feature">Featured Placement in Marketplace</li>
                            <li className="settings-plan__feature">Phone Support</li>
                            <li className="settings-plan__feature">Custom Reports</li>
                            <li className="settings-plan__feature">Custom Branding</li>
                            <li className="settings-plan__feature">Custom Domain</li>
                        </ul>

                        <p className={proMonthly === true ? "settings-plan__plan-disclaimer--hidden" : "settings-plan__plan-disclaimer"}>
                            *Price reflects yearly plan
                        </p>
                        {planSelected === AppSubscriptionPlan.TIER_2 //&& !loading 
                            ?
                            <a className="settings-plan__cta--selected">
                                Selected
                            </a>
                            :
                            <Button 
                                primary
                                // loading={selectionIsLoading && planSelected === AppSubscriptionPlan.TIER_2}
                                loading={selectionIsLoading}
                                onClick={() => selectPlan(AppSubscriptionPlan.TIER_2)}
                                data-test="button-selectPro"
                            >
                                Select
                            </Button>
                        }
                    </div>
                </div>
            </>
        )

    } else {
    // Show Billing UI if merchant is in active status
    // Show Error if merchant Shopify payment is expired/declined

        return (
            <div className="settings-plan" data-test="component-planAccount">
                {/* Error banner if Shopify payment is expired/declined */}
                {renderErrorBanner(charges)}
                {/* {banners[advertiser.status] && banners[advertiser.status](advertiser)} */}

                <div className="settings-plan__breakdown-wrapper"> 
                    <h2 className="settings-plan__account-name">
                        You are currently on the {plan && plan.name} Plan. <span data-test="span-upgradeText" onClick={() => toggleDisplaySelection(false)} className="settings-plan__upgrade-link">{plan.name === 'Starter' ? 'Upgrade at any time.' : 'Change your plan.'}</span>
                    </h2>
                    <div className="settings-plan__account-wrapper">
                        <div className="settings-plan__account-plan settings-plan__account-plan--left">
                            <h3 className="settings-plan__account-label">Payment due date</h3>
                            <p className="settings-plan__account-value">{format(billingDate, 'LLLL d, yyyy')}</p>
                            <h3 className="settings-plan__account-label">Payment due</h3>
                            <p className="settings-plan__account-value">${plan && plan.price}</p>
                        </div>
                            {charges && charges.length > 1 ?
                                <div className="settings-plan__account-plan">
                                    <h3 className="settings-plan__account-label">Last Payment Date</h3>
                                    <p className="settings-plan__account-value">{format(parseISO(sortedCharges[1].billing_on), 'LLLL d, yyyy')}</p>
                                    <h3 className="settings-plan__account-label">Last Payment Amount</h3>
                                    <p className="settings-plan__account-value">${sortedCharges[1].price}</p>
                                </div>
                            :
                                <div className="settings-plan__account-plan">
                                    <p className="settings-plan__trial-info">No charges have been made, trial ends on <span className="settings-plan__trial-date">{format(trialEndDate, 'LLLL d, yyyy')}.</span>
                                    </p>
                                    <p className="settings-plan__trial-info">
                                        Next bill date: <span className="settings-plan__billing-date">{format(billingDate, 'LLLL d, yyyy')}.</span>
                                    </p>
                                </div>
                            }
                    </div>
    
                    <div className="statements__list-wrapper">
                        <div className="statements__item statements__header">
                            <div className="statements__amount">Payment Due</div>
                            <div className="statements__date">Due Date</div>
                            <div className="statements__due-date">Date Paid</div>
                            <div className="statements__status">Status</div>
                        </div>
                        {charges && charges.length > 0 && 
                        <>
                            <ResourceList 
                                items={posts}
                                renderItem={renderStatement}
                            />
                            {maxPages > 0 &&
                                <LlamaPagination
                                    hasNext={hasNext}
                                    hasPrevious={hasPrevious}
                                    onNext={nextPage}
                                    onPrevious={previousPage}
                                    currentPage={currentPage}
                                    maxPages={maxPages}
                                />
                            }
                        </>
                        }
                    </div>
                </div>
            </div>
        )

    }
}

export default PlanSettings;