import React, { Component } from 'react';
import { connect } from 'react-redux';
import { unionBy, uniqBy } from 'lodash';
import PropTypes from 'prop-types';

import { Page } from '@shopify/polaris';
import { LlamaToast } from 'llama-library/components';

import ProductsList from '../components/products-list/products-list';

import { getProductsForAdvertiser, getProductListByCollection, updateAdvertiserProducts } from '../actions/products';
import { updateAdvertiserProduct } from '../actions/update-product';

export class Products extends Component {
    constructor(props) {
        super(props);

        this.state = {
            savedAdvertiser: props.advertiser,
            products: [],
            collections: [],
            selectedCollection: '',
            collectionsFilter: null,
            itemsPerPage: 20,
            updating: false,
            isLoading: true,
            toastVerbiage: '',
            syncIsLoading: false
        };

        this.handleStatusToggle = this.handleStatusToggle.bind(this);
        this.handleCollectionChange = this.handleCollectionChange.bind(this);
        this.setProducts = this.setProducts.bind(this);
        this.setToastVerbiage = this.setToastVerbiage.bind(this);
    }

    /**
     * @author Harshal Mahajan <Harshal.Mahajan@harbingergroup.com>
     * @function getDerivedStateFromProps
     * @description Check if new coming props have different myshopify_domain compared to the previous state then update it with new one
     * @param {Object} nextProps new props receving by component
     * @param {Object} prevState previous state of component
     * @returns {Object} null or updated state
     */
    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.advertiser.myshopify_domain && prevState.advertiser && (nextProps.advertiser.myshopify_domain !== prevState.savedAdvertiser.myshopify_domain)) {
            return {
                savedAdvertiser: nextProps.advertiser
            };
        }
        return null;
    }

    componentDidMount() {
        if (this.props.advertiser.myshopify_domain) {
            this.props.getProductsForAdvertiser(this.props.advertiser.myshopify_domain)
                .then(this.setProducts);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.advertiser.myshopify_domain !== this.props.advertiser.myshopify_domain) {
            this.props.getProductsForAdvertiser(this.props.advertiser.myshopify_domain)
                .then(this.setProducts);
        }
    }

    handleCollectionChange = (selectedCollection) => {
        this.setState({ selectedCollection });

        if (selectedCollection === '__NO_COLLECTION') {
            this.setState({ collectionsFilter: null });
            return;
        }

        getProductListByCollection(this.props.advertiser.advertiser_id, selectedCollection)
            .then(({ data }) => {
                if (
                    !data
                    || !Array.isArray(data.advertisers)
                    || !data.advertisers[0].store
                    || !Array.isArray(data.advertisers[0].store.productsForCollection)
                ) {
                    this.setState({ collectionsFilter: null });
                    return;
                }

                const collectionsFilter = data.advertisers[0].store.productsForCollection;
                this.setState({ collectionsFilter });
            });
    }

    handleStatusToggle(products, status) {
        this.setState({ updating: true });
        updateAdvertiserProduct(products, status)
            .then(({ data }) => {
                // If data is returned wrong, alert user.
                if (!data || !Array.isArray(data.updateProducts)) {
                    this.setToastVerbiage(`Oops, something went wrong. We weren’t able to ${(status === 'active') ? 'activate' : 'deactivate'} the selected products.`);
                    return;
                }

                const newProducts = unionBy(data.updateProducts, this.state.products, 'product_id');
                this.setState({
                    products: newProducts,
                    updating: false
                });

                this.setToastVerbiage(`Products ${(status === 'active') ? 'activated' : 'deactivated'} successfully`);
            });
    }

    setToastVerbiage(message) {
        this.setState({ toastVerbiage: message });
    }

    setProducts({ value }) {
        const { data } = value;
        if (!data
            || !Array.isArray(data.advertisers)
            || !data.advertisers[0]
            || !Array.isArray(data.advertisers[0].products)
        ) {
            this.setState({ isLoading: false });
            return;
        }

        const products = uniqBy(data.advertisers[0].products, 'product_id');

        this.setState({
            products,
            collections: data.advertisers[0].collections,
            isLoading: false
        });
    }

    handleProductSync = () => {
        this.setState({ syncIsLoading: true });
        this.props.updateAdvertiserProducts(this.props.advertiser.advertiser_id, this.props.advertiser.myshopify_domain)
            .then((result) => {
                if (result) {
                    this.setState({
                        products: result.value.data.updateAdvertiserProducts,
                        syncIsLoading: false
                    });
                } else {
                    this.setState({ syncIsLoading: false });
                    this.setToastVerbiage('Product sync was unsuccessful');
                }
            })
            .catch((err) => {
                this.setState({ syncIsLoading: false });
                this.setToastVerbiage('Product sync was unsuccessful');
            });
    }

    render() {
        return (
            <Page
                title="Products"
                separator
            >
                <ProductsList
                    advertiser={this.props.advertiser}
                    products={this.props.products}
                    collections={this.state.collections}
                    selectedCollection={this.state.selectedCollection}
                    collectionsFilter={this.state.collectionsFilter}
                    itemsPerPage={this.state.itemsPerPage}
                    updating={this.state.updating}
                    isLoading={this.state.isLoading}
                    handleStatusToggle={this.handleStatusToggle}
                    handleCollectionChange={this.handleCollectionChange}
                    handleProductSync={this.handleProductSync}
                    syncIsLoading={this.state.syncIsLoading}
                />
                <LlamaToast toastVerbiage={this.state.toastVerbiage} setToastVerbiage={this.setToastVerbiage} />
            </Page>
        );
    }
}

Products.propTypes = {
    advertiser: PropTypes.shape({
        advertiser_id: PropTypes.string.isRequired,
        myshopify_domain: PropTypes.string.isRequired,
        new_product_status: PropTypes.string
    }).isRequired
};

const mapStateToProps = ({ advertiser, products }) => {
    return {
        advertiser,
        products: products.products
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getProductsForAdvertiser: (shop) => dispatch(getProductsForAdvertiser(shop)),
        updateAdvertiserProducts: (advertiser_id, shop) => dispatch(updateAdvertiserProducts(advertiser_id, shop))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Products);
