import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Select, Badge, TextField, Icon, Spinner, ActionList, Popover, Button, Modal } from '@shopify/polaris';
import { SearchMinor } from '@shopify/polaris-icons';
import { LlamaPagination, EmptyState, LlamaButton } from 'llama-library/components';
import { defaultBanners, moneyFormat, pluralize } from 'llama-library/utils';

import { getOffers } from '../../actions/offers';
import { updateOfferStatus } from '../../actions/create-offer';
import { invitePageOffers, getBrandedSignUpCustomizations, updateBrandedSignup } from '../../actions/branded-sign-up';

import useResponsive from '../../hooks/use-responsive';
import usePages from '../../hooks/use-pages';

import { RateTypes } from '../../utils/types';
import formatOfferName from '../../utils/format-offer-name';

import editIcon from '../../assets/edit-thin.svg';
import couponsIcon from '../../assets/coupons-icon.svg';
import creativesIcon from '../../assets/image-placeholder-icon.svg';
import powerIcon from '../../assets/power-icon.svg';
import './offer-list.css';

const sortOptions = [
    { label: 'Name A-Z', value: 'name' },
    { label: 'Name Z-A', value: 'name-desc' },
    { label: 'Boosted', value: 'boost' },
    { label: 'Active', value: 'active' },
    { label: 'Most Revenue', value: 'revenue-desc' },
    { label: 'Least Revenue', value: 'revenue-asc' },
    { label: 'Most Active Ambassadors', value: 'ambassadors-desc' },
    { label: 'Least Active Ambassadors', value: 'ambassadors-asc' }
];

// sorting functions
const sortAscDesc = (a, b, field, dir) => {
    if (dir === 'desc' && a[field] > b[field]) return -1;
    if (dir === 'desc' && a[field] < b[field]) return 1;
    if (dir === 'asc' && a[field] > b[field]) return 1;
    if (dir === 'asc' && a[field] < b[field]) return -1;
    return 0;
};

const sortStringAscDesc = (a, b, field, dir) => {
    if (dir === 'desc' && a[field].toLowerCase() > b[field].toLowerCase()) return -1;
    if (dir === 'desc' && a[field].toLowerCase() < b[field].toLowerCase()) return 1;
    if (dir === 'asc' && a[field].toLowerCase() > b[field].toLowerCase()) return 1;
    if (dir === 'asc' && a[field].toLowerCase() < b[field].toLowerCase()) return -1;
    return 0;
};

const sortByBoosted = (a, b) => {
    const statusMap = { ACTIVE: 1, INACTIVE: 2, OFF: 3 };
    if (statusMap[a.sponsored] > statusMap[b.sponsored]) return -1;
    if (statusMap[a.sponsored] < statusMap[b.sponsored]) return 1;
    return 0;
};

const sortByActive = (a, b) => {
    if (a.status > b.status) return 1;
    if (a.status < b.status) return -1;
    return 0;
};

const OfferList = ({ advertiser, navigateToNew, history, offers: offersFromState, getInviteOffers, dispatchUpdateBrandedSignup, dispatchGetBrandedSignUpCustomizations }) => {
    const mobileWidth = 1180;

    const [loading, setLoading] = useState(true);
    const [offers, setOffers] = useState(offersFromState);
    const [filteredOffers, setFilteredOffers] = useState(offersFromState);

    const [searchTerm, setSearchTerm] = useState('');
    const [sortBy, setSortBy] = useState('name');
    const [actionsOpen, setActionsOpen] = useState(null);

    // deactivation states
    const [offerToDeactivate, setOfferToDeactivate] = useState({});
    const [statusLoading, setStatusLoading] = useState(false);
    const [currentBrandedSignUp, setCurrentBrandedSignUp] = useState({});
    const [ambassadorWarningModal, setAmbassadorWarningModal] = useState(false);
    const [inviteWarning, setInviteWarning] = useState(false);

    const [inviteOffers, setInviteOffers] = useState([]);
    const [inviteReplacementModal, setInviteReplacementModal] = useState(false);
    const [selectedInviteOffer, setSelectedInviteOffer] = useState('');

    const pageWidth = useResponsive();

    // load offers
    useEffect(() => {
        const end_date = new Date();
        end_date.setDate(end_date.getDate() + 1);
        const start_date = new Date();
        start_date.setDate(start_date.getDate() - 30);
        sessionStorage.removeItem('current_page');

        getOffers(advertiser.advertiser_id, start_date, end_date)
            .then((result) => {
                //console.log(JSON.stringify(result, null, 4));
                if (
                    result
                    && result.data
                    && Array.isArray(result.data.advertisers)
                    && Array.isArray(result.data.advertisers[0].offers)
                ) {
                    // format offers
                    const formattedOffers = result.data.advertisers[0].offers.map((offer) => {
                        let offerStatus = 'active';
                        if (!offer.active || offer.interim_status === 'ACTIVE') {
                            offerStatus = 'inactive';
                        }

                        let ambassadorsWithSales = 0;
                        if (offer.reports && offer.reports.affiliateData) {
                            ambassadorsWithSales = offer.reports.affiliateData.length;
                        }

                        let revenue = 0;
                        if (offer.reports && offer.reports.salesData) {
                            offer.reports.salesData.forEach((sale) => {
                                revenue += Math.round((sale.volume / 100) * 100) / 100;
                            });
                        }

                        return {
                            ...offer,
                            status: offerStatus,
                            ambassadorsWithSales,
                            revenue
                        };
                    });
                    setOffers(formattedOffers);
                    setLoading(false);
                }
            });
    }, []);

    // sort and filter when searchTerm or sortBy changes
    useEffect(() => {
        let sortFn;
        switch (sortBy) {
            case 'boost':
                sortFn = sortByBoosted;
                break;
            case 'active':
                sortFn = sortByActive;
                break;
            case 'revenue-desc':
                sortFn = (a, b) => sortAscDesc(a, b, 'revenue', 'asc');
                break;
            case 'revenue-asc':
                sortFn = (a, b) => sortAscDesc(a, b, 'revenue', 'desc');
                break;
            case 'ambassadors-desc':
                sortFn = (a, b) => sortAscDesc(a, b, 'ambassadorsWithSales', 'desc');
                break;
            case 'ambassadors-asc':
                sortFn = (a, b) => sortAscDesc(a, b, 'ambassadorsWithSales', 'asc');
                break;
            case 'name-desc':
                sortFn = (a, b) => sortStringAscDesc(a, b, 'name', 'desc');
                break;
            case 'name':
            default:
                sortFn = (a, b) => sortStringAscDesc(a, b, 'name', 'asc');
                break;
        }

        if (offers && Array.isArray(offers) && offers.length > 0) {
            setFilteredOffers(
                offers
                    .filter((offer) => {
                        if (!searchTerm) {
                            return true;
                        }

                        const offerNameLower = offer.name.toLowerCase();
                        const searchTermLower = searchTerm.toLowerCase();
                        if (offerNameLower.includes(searchTermLower)) {
                            return true;
                        }

                        return false;
                    })
                    .sort(sortFn)
            );
        }
    }, [searchTerm, sortBy, offers]);

    const {
        previousPage,
        nextPage,
        currentPage,
        hasNext,
        hasPrevious,
        maxPages,
        posts
    } = usePages(0, 10, filteredOffers);

    const deactivateOffer = async (offer, inviteWarningPreStateChange) => {
        setStatusLoading(true);
        const payload = { offer_id: offer.offer_id };
        if (offer.applicantCount.approved > 0) {
            payload.interim_status = 'ACTIVE';
        } else {
            payload.active = false;
            payload.interim_status = 'INACTIVE';
        }
        payload.inactive_reason = `Offer deactivated by the advertiser[${advertiser.advertiser_id}]`;

        // deactivate it and update the ui to reflect the status change
        console.log(payload);
        await updateOfferStatus(payload);

        const filteredOffersUpdated = filteredOffers.map((filteredOffer) => {
            if (filteredOffer.offer_id === offer.offer_id) {
                return {
                    ...filteredOffer,
                    status: 'inactive'
                };
            }
            return filteredOffer;
        });
        setFilteredOffers(filteredOffersUpdated);
        setActionsOpen(false);

        // if the offer was tied to invite page, show replacement offer form
        if (inviteWarning || inviteWarningPreStateChange) {
            let featuredOffers = [];
            featuredOffers = await getInviteOffers(advertiser.advertiser_id);
            featuredOffers = featuredOffers.value.data.advertiserById.offersForBrandedSignup;
            if (featuredOffers.length > 0) {
                featuredOffers = featuredOffers
                    .filter((featuredOffer) => {
                        return featuredOffer.offer_id !== offer.offer_id;
                    })
                    .map((featuredOffer) => {
                        return { label: featuredOffer.name, value: featuredOffer.offer_id };
                    });

                featuredOffers.unshift({
                    label: 'Select Offer',
                    value: ''
                });
            }

            setInviteOffers(featuredOffers);
            setInviteReplacementModal(true);
        }
        setStatusLoading(false);
        setAmbassadorWarningModal(false);
    };

    // determine which modal to show first (if any) based on approved ambassador count and branded sign up page details
    const attemptDeactivate = async (offer) => {
        setOfferToDeactivate(offer);

        // get branded sign up offer id
        const brandedSignUp = await dispatchGetBrandedSignUpCustomizations(advertiser.advertiser_id);
        let brandedSignUpOfferId = '';
        if (
            brandedSignUp.value
            && brandedSignUp.value.data
            && brandedSignUp.value.data.advertiserById
            && brandedSignUp.value.data.advertiserById.branded_sign_up
            && brandedSignUp.value.data.advertiserById.branded_sign_up.offer_id
        ) {
            brandedSignUpOfferId = brandedSignUp.value.data.advertiserById.branded_sign_up.offer_id;
            setCurrentBrandedSignUp(brandedSignUp.value.data.advertiserById.branded_sign_up);
        }

        if (offer.applicantCount.approved > 0) {
            setAmbassadorWarningModal(true);

            if (brandedSignUpOfferId === offer.offer_id) {
                setInviteWarning(true);
            }
        } else if (brandedSignUpOfferId === offer.offer_id) {
            setInviteWarning(true);
            deactivateOffer(offer, true);
            setInviteReplacementModal(true);
        } else {
            deactivateOffer(offer);
        }
    };

    const updateInviteOffer = async () => {
        setStatusLoading(true);
        const payload = {
            branded_sign_up: {
                ...currentBrandedSignUp,
                offer_id: selectedInviteOffer
            }
        };
        console.log(payload);
        await dispatchUpdateBrandedSignup(advertiser.advertiser_id, payload);
        setStatusLoading(false);
        setInviteReplacementModal(false);
    };

    const activateOffer = (offerId) => {
        setStatusLoading(true);
        updateOfferStatus({ interim_status: 'INACTIVE', active: true, offer_id: offerId })
            .then((result) => {
                if (result.updateOfferStatus && result.updateOfferStatus.success) {
                    // update the ui to reflect the status change
                    const filteredOffersUpdated = filteredOffers.map((filteredOffer) => {
                        if (filteredOffer.offer_id === offerId) {
                            return {
                                ...filteredOffer,
                                status: 'active'
                            };
                        }
                        return filteredOffer;
                    });

                    setFilteredOffers(filteredOffersUpdated);
                    setActionsOpen(false);
                    setStatusLoading(false);
                }
            });
    };

    const handleSortChange = (value) => {
        setSortBy(value);
    };

    const toggleActionsList = (offerId) => {
        if (actionsOpen === offerId) {
            setActionsOpen(null);
        } else {
            setActionsOpen(offerId);
        }
    };

    const goToOffer = (offer, mobileOnly = false, subPage = '') => {
        if (window.innerWidth > 925 || (window.innerWidth <= 925 && mobileOnly)) {
            history.push({ pathname: `/offer/${offer.offer_id}${subPage}`, state: { offer } });
        }
    };

    const displayCampaignRate = (rate) => {
        const { type, amount } = rate;

        switch (type) {
            case RateTypes.FLAT_ITEM:
                return `${moneyFormat(amount, true)} per item`;
            case RateTypes.FLAT_ORDER:
                return `${moneyFormat(amount, true)} per order`;
            case RateTypes.PERCENT_REVENUE:
            default:
                return `${amount}% of order total`;
        }
    };

    const sentenceCase = (str) => {
        const lowercaseStr = str.toLowerCase().substr(1);
        const capitalFirstLetter = str.toUpperCase().substr(0, 1);

        return `${capitalFirstLetter}${lowercaseStr}`;
    };

    if (loading) {
        return <div className="offers-loading" data-test="component-loadingOffers"><Spinner /></div>;
    }

    if (!offers || (Array.isArray(offers) && offers.length === 0)) {
        return (
            <EmptyState
                message="You haven’t created any offers yet"
                paragraph="Creating an offer helps potential ambassadors find your brand and incentivizes them to promote it."
                ctaText="Create an Offer"
                ctaAction={navigateToNew}
                data-test="component-emptyOffers"
            />
        );
    }

    return (
        <div className="offer-list__wrapper" data-test="component-offerList">
            <Modal
                open={inviteReplacementModal}
                title="Choose a new offer for your invite page"
                primaryAction={{
                    content: 'Save',
                    disabled: !selectedInviteOffer,
                    onAction: updateInviteOffer,
                    loading: statusLoading
                }}
                onClose={() => { return setInviteReplacementModal(false); }}
            >
                <Modal.Section>
                    <p>Your offer has been deactivated, but since it was previously tied to your ambassador invite page, you&rsquo;ll need to <strong>choose a replacement</strong>.</p>

                    <Select
                        onChange={(value) => { setSelectedInviteOffer(value); }}
                        options={inviteOffers}
                        value={selectedInviteOffer}
                    />
                </Modal.Section>
            </Modal>

            <Modal
                open={ambassadorWarningModal}
                title="Are you sure you want to deactivate this offer?"
                primaryAction={{
                    content: 'Deactivate',
                    onAction: () => { return deactivateOffer(offerToDeactivate); },
                    loading: statusLoading
                }}
                secondaryActions={{
                    content: 'Keep active',
                    onAction: () => { return setAmbassadorWarningModal(false); }
                }}
                onClose={() => { return setAmbassadorWarningModal(false); }}
            >
                <Modal.Section>
                    <p>Heads up! If you deactivate this offer, <strong>your current ambassadors won&rsquo;t be able to earn commissions from it until you reactivate it</strong>. Frequent offer status changes can be stressful for ambassadors and it could impact your reputation, so <strong>proceed with caution</strong>.</p>

                    <p>If your only option is to deactivate it, we&rsquo;ll notify your ambassadors right away. However, <strong>this offer won&rsquo;t be fully deactivated until {moment().add(2, 'days').format('dddd, MMMM d, YYYY')}</strong> because your ambassadors will need some time to adjust their current promotions.</p>

                    {inviteWarning && <p>You&rsquo;ll also need to choose a replacement offer for your invite page once this one is deactivated.</p>}
                </Modal.Section>
            </Modal>

            <div className="offer-list__search-and-sort">
                <div className="search">
                    <TextField
                        value={searchTerm}
                        onChange={(value) => { return setSearchTerm(value); }}
                        prefix={<Icon source={SearchMinor} color="inkLightest" />}
                        placeholder="Search"
                        data-test="search"
                    />
                </div>
                <div className="sort">
                    <p>Sort By:</p>
                    <Select
                        onChange={handleSortChange}
                        options={sortOptions}
                        value={sortBy}
                    />
                </div>
            </div>
            {filteredOffers.length === 0 && searchTerm
                ? <EmptyState message="No offers matched your search term" />
                : (
                    <table className="offer-list">
                        <thead>
                            <tr>
                                <th>Offer</th>
                                <th>Status</th>
                                <th className="number">Included Products</th>
                                <th className="number">Ambassadors w/ Sales</th>
                                <th className="money">Revenue</th>
                                <th>Ad Boost</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {posts.map((offer) => {
                                let bannerImage = (defaultBanners[offer.category]) ? defaultBanners[offer.category][0] : defaultBanners.default[0];
                                if (offer.bannerImage) {
                                    bannerImage = offer.bannerImage;
                                }

                                let status = <Badge status="success">Active</Badge>;
                                if (offer.status === 'inactive') {
                                    status = <Badge status="attention">Inactive</Badge>;
                                }

                                let products = 'All Products';
                                if (!offer.products.includeAll) {
                                    products = offer.products.includeProducts.length;
                                }

                                let boost = '';
                                if (offer.status === 'inactive') {
                                    boost = <span className="ineligible">Only active offers can be boosted.</span>;
                                } else if (offer.sponsored && offer.sponsored !== 'OFF') {
                                    boost = (
                                        <p>
                                            <span className="boost-status" data-status={offer.sponsored.toLowerCase()}>{pageWidth <= mobileWidth && 'Ad Boost: '} {sentenceCase(offer.sponsored)}</span>
                                            <Link to={`offer/${offer.offer_id}/boost`}>Manage/View Stats</Link>
                                        </p>
                                    );
                                } else {
                                    boost = <LlamaButton onClick={() => { return goToOffer(offer, true, '/boost'); }}>Boost</LlamaButton>;
                                }

                                const actions = [
                                    {
                                        content: 'Add/View Coupons',
                                        onAction: () => { return history.push(`offer/${offer.offer_id}/coupons`); },
                                        image: couponsIcon
                                    },
                                    {
                                        content: 'Upload Creatives',
                                        onAction: () => { return history.push(`offer/${offer.offer_id}/creatives`); },
                                        image: creativesIcon
                                    },
                                    {
                                        content: 'Edit',
                                        onAction: () => { return history.push(`offer/${offer.offer_id}/edit`); },
                                        image: editIcon
                                    }
                                ];

                                if (offer.status === 'active') {
                                    actions.push({
                                        destructive: true,
                                        content: (!statusLoading) ? 'Deactivate' : 'Saving...',
                                        onAction: () => { return attemptDeactivate(offer); },
                                        image: powerIcon
                                    });
                                } else {
                                    actions.push({
                                        content: (!statusLoading) ? 'Activate' : 'Saving...',
                                        onAction: () => { return activateOffer(offer.offer_id); },
                                        image: powerIcon
                                    });
                                }

                                return (
                                    <tr key={offer.offer_id} data-test="component-offerItem">
                                        <td className="name" onClick={() => { return goToOffer(offer, true); }}>
                                            <img src={bannerImage} alt="" />
                                            <div>
                                                <p>
                                                    {pageWidth <= mobileWidth && status}
                                                    <span>
                                                        {formatOfferName(offer)}
                                                    </span>
                                                </p>
                                                {offer.offer_type !== 'product_giveaway'
                                                    ? (
                                                        <ul className="commission-rates">
                                                            <li>Initial: {displayCampaignRate(offer.rate)}</li>
                                                            <li>Long-Term: {displayCampaignRate(offer.longterm_rate)}</li>
                                                        </ul>
                                                    )
                                                    : <p className="included-products">Includes {offer.products.includeAll ? 'all products' : pluralize(products, 'product', 'products')}</p>
                                                }
                                            </div>
                                        </td>
                                        {pageWidth > mobileWidth
                                            && <td className="status" onClick={() => { return goToOffer(offer, true); }}>{status}</td>
                                        }
                                        <td className="number products" onClick={() => { return goToOffer(offer, true); }}>{products}</td>
                                        <td className="number ambassadors" onClick={() => { return goToOffer(offer, true); }}>
                                            {offer.offer_type !== 'product_giveaway'
                                                ? `${offer.ambassadorsWithSales} of ${offer.applicantCount.approved}`
                                                : 'N/A'
                                            }
                                        </td>
                                        <td className="money revenue" onClick={() => { return goToOffer(offer, true); }}>{moneyFormat(offer.revenue)}</td>
                                        <td className="boost">{boost}</td>
                                        <td className="actions">
                                            <div className="actions-wrapper">
                                                <Link to={{ pathname: `offer/${offer.offer_id}`, state: { offer } }}>View</Link>

                                                <Popover
                                                    active={actionsOpen === offer.offer_id}
                                                    activator={<Button onClick={() => { return toggleActionsList(offer.offer_id); }} disclosure plain>More actions</Button>}
                                                    onClose={() => { return toggleActionsList(offer.offer_id); }}
                                                    preferredAlignment="right"
                                                >
                                                    <ActionList
                                                        items={actions}
                                                    />
                                                </Popover>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                )
            }
            {maxPages > 0
                && (
                    <div className="offers-main__pagination">
                        <LlamaPagination
                            hasNext={hasNext}
                            hasPrevious={hasPrevious}
                            onNext={nextPage}
                            onPrevious={previousPage}
                            currentPage={currentPage}
                            maxPages={maxPages}
                            data-test="component-pagination"
                        />
                    </div>
                )
            }
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        advertiser: state.advertiser,
        offers: state.offers
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getInviteOffers: (advertiser_id) => { return dispatch(invitePageOffers(advertiser_id)); },
        dispatchUpdateBrandedSignup: (advertiser_id, payload) => { return dispatch(updateBrandedSignup(advertiser_id, payload)); },
        dispatchGetBrandedSignUpCustomizations: (advertiser_id, payload) => { return dispatch(getBrandedSignUpCustomizations(advertiser_id, payload)); }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OfferList);
