import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import promise from 'redux-promise-middleware';
import enTranslations from '@shopify/polaris/locales/en.json';
import { AppProvider } from '@shopify/polaris';
import { StripeProvider } from 'react-stripe-elements';
import { unregister } from './registerServiceWorker';
import { STRIPE_KEY } from './config';

import Auth from './Auth';
import reducers from './reducers';
import '@shopify/polaris/styles.css';

let middleware = {};
const logger = createLogger({
    collapsed: true
});

if (process.env.NODE_ENV === 'development') {
    middleware = applyMiddleware(promise, logger);
} else {
    middleware = applyMiddleware(promise);
}

const store = createStore(reducers, middleware);

// TODO: we should probably move Amplify and Redux out
// of here into the App.js file.
ReactDOM.render(
    <Provider store={store}>
        <StripeProvider apiKey={`${STRIPE_KEY}`}>
            <Router>
                <AppProvider i18n={enTranslations}>
                    <Auth />
                </AppProvider>
            </Router>
        </StripeProvider>
    </Provider>,
    document.getElementById('root')
);
unregister();
