import React, { useState } from 'react';
import { Card } from '@shopify/polaris';
import { subDays } from 'date-fns';

import LlamaDatePicker from '../llama/date-picker';

import PerformanceGraph from '../ambassador-performance/performance-graph';
import TransactionReports, { ReportColumns } from '../transaction-report/transaction-report';

import useAmbassadorReports from '../../hooks/use-ambassador-reports';
import useTransactionReport from '../../hooks/use-transaction-report';

import { formatAudience, formatGender } from '../../utils/format-audience';
import formatCountries from '../../utils/format-countries';
import formatMetrics from '../../utils/format-metrics';
import {moneyFormat} from '../../utils/number-format';
import formatPercent from '../../utils/format-percent';

/**
 * @function DashboardAffiliateDetail
 * Accepts following params, returns DashboardAffiliateDetail Component.
 * Displays tracking data and PerformanceGraph and TransactionReports of the ambassador.
 * 
 * @param {Object} advertiser
 * @param {Object} affiliate
 * @param {Object} history
 * 
 * @returns {FunctionComponent}
 */
const DashboardAffiliateDetail = ({ advertiser, affiliate, history }) => {

    const { account, tracking } = affiliate;

    // Tracking Data.
    const clicks = tracking && tracking.Stat ? formatMetrics(tracking.Stat.clicks) : 0;
    const epc = tracking && tracking.Stat ? moneyFormat(tracking.Stat.epc, true) : 0;
    const conversions = tracking && tracking.Stat && tracking.Stat.conversions
        ? tracking.Stat.conversions 
        : 0;
    const conversionPercent = formatPercent(conversions / clicks, true);

    const now = new Date();
    now.setHours(12,0,0);
    const startDate = subDays(now, 30);
    startDate.setHours(12,0,0);

    const defaultDates = { startDate, endDate: now };
    
    const [filters, setFilters] = useState({ 
        affiliate_id: affiliate.tracking_id,
        startDate, 
        endDate: now,
    })

    const handleDateChange = (newDateRange) => {
        const { startDate, endDate } = newDateRange
        setFilters((state) => {
            return {
                ...state,
                startDate: startDate,
                endDate: endDate,
            }
        });
    }

    const { 
        ambassadors, 
        isLoading: isLoadingAmbassadors, 
        errorMessage: ambassadorsErrorMessage, 
    } = useAmbassadorReports(advertiser.advertiser_id, filters);

    const {
        pageInfo,
        handleOnNext,
        handleOnPervious,
        orders,
        isLoading: isLoadingTransactions,
        errorMessage: transactionsErrorMessage,
    } = useTransactionReport(advertiser.advertiser_id, filters);

    const reportData = Array.isArray(ambassadors) 
        ? ambassadors.find((item) => {
                return item.affiliate_id === affiliate.tracking_id;
            })
        : {};

    const reportColumns = [
        ReportColumns.ORDER_NUMBER,
        ReportColumns.DATE,
        ReportColumns.OFFER,
        ReportColumns.CUSTOMER,
        ReportColumns.COMMISSION,
        ReportColumns.STATUS,
        ReportColumns.SUBTOTAL,
        ReportColumns.LOOK_FORWARD,
    ]

    return (
        <div className="affiliate-dashboard" data-test="component-affiliateDashboard">
            <Card.Section>
                <div className="affiliate-dashboard__quicklook-section">
                    <div className="affiliate-sidebar__sidebar-section">
                    <div className="affiliate-sidebar__seciton-header">Audience Demographic</div>
                    <div className="affiliate-sidebar__sidebar-table">
                        <div className="affiliate-sidebar__table-header">
                            <div>Age Range</div>
                            <div>Gender</div>
                            <div>Country</div>
                        </div>
                        <div className="affiliate-sidebar__table-body">
                            <div>{account && formatAudience(account)}</div>
                            <div>{account && formatGender(account)}</div>
                            <div>{account && formatCountries(account)}</div>
                        </div>
                    </div>
                </div>
                <div className="affiliate-sidebar__sidebar-section">
                    <div className="affiliate-sidebar__seciton-header">Llama Stats</div>
                    <div className="affiliate-sidebar__sidebar-table">
                        <div className="affiliate-sidebar__table-header">
                            <div>Conversion</div>
                            <div>EPC</div>
                            <div>Clicks</div>
                        </div>
                        <div className="affiliate-sidebar__table-body">
                            <div>{conversionPercent}</div>
                            <div>{epc}</div>
                            <div>{clicks}</div>
                        </div>
                    </div>
                </div>
                </div>
                <div className="affilaite-dashboard__header">Reports</div>
                <div className="affilaite-dashboard__filters">
                    <LlamaDatePicker 
                        onDateChange={handleDateChange}
                        disabled={false}
                        defaultDates={defaultDates}
                        data-test="component-datePicker"
                    />
                </div>
                <div className="affiliate-dashboard__graphs">
                    <PerformanceGraph
                        ambassador={reportData}
                        presetTab='volume'
                        data-test="component-volumeGraph"
                    />
                    <PerformanceGraph
                        ambassador={reportData}
                        presetTab='sales'
                        data-test="component-salesGraph"
                    />
                </div>
                <div className="affiliate-dashboard__graphs--mobile">
                    <PerformanceGraph
                        ambassador={reportData}
                        data-test="component-mobileGraphs"
                    />
                </div>
                <TransactionReports
                    orders={orders}
                    isLoading={isLoadingTransactions}
                    errorMessage={transactionsErrorMessage}
                    history={history}
                    pageInfo={pageInfo}
                    onNext={handleOnNext}
                    onPrevious={handleOnPervious}
                    columns={reportColumns}
                    data-test="table-transactionReports"
                />
            </Card.Section>
        </div>
    )
}

export default DashboardAffiliateDetail;