import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getAdvertiser } from '../actions/advertiser';
import { updateAdvertiser } from '../actions/update-advertiser';

import Payment from '../components/settings-paypal/paypal-payment';
import OfferBoostBilling from '../components/settings-offer-boost/offer-boost-billing';

import './styles/settings-payment.css';

class PaymentSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            savedAdvertiser: props.advertiser
        };
    }

    componentDidMount() {
        if (this.props.advertiser.myshopify_domain) {
            this.props.getAdvertiser(this.props.advertiser.myshopify_domain);
        }
    }

    render() {
        return (
            <div className="settings-payment__wrapper">
                <section>
                    <h2>Paypal Connect</h2>
                    <aside>
                        <p>Currently we are only disbursing payments to ambassadors via PayPal. Please connect your PayPal account to set up commission payments.</p>
                    </aside>
                    <Payment />
                </section>

                <section>
                    <h2>Ad Boost Billing</h2>
                    <aside>
                        <p>The billing cycle for Ad Boost is 7 days and all charges are automatically and securely processed through Stripe every Monday.</p>

                        <p>Total charges are based on your bid amount(s) and number of impressions you receive during the current billing cycle for all your actively boosted offers. <strong>The minimum charge for each billing cycle is $0.50 USD</strong>.</p>

                        <p><strong>Please Note:</strong> Every time you change your payment method it will be authorized for $1.00 USD to ensure it&rsquo;s valid. This amount might show up on your bank/credit card statement as a pending charge, but it should drop off within 7 days.</p>
                    </aside>
                    <OfferBoostBilling history={this.props.history} />
                </section>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        advertiser: state.advertiser
    };
};

const mapDispatchtoProps = (dispatch) => {
    return {
        getAdvertiser: (shop) => dispatch(getAdvertiser(shop)),
        updateAdvertiser: (data, advertiser) => dispatch(updateAdvertiser(data, advertiser))
    };
};

export default connect(mapStateToProps, mapDispatchtoProps)(PaymentSettings);
