import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Page } from '@shopify/polaris';
import moment from 'moment';

import useAmbassadorReports from '../hooks/use-ambassador-reports-performance';
import { getApprovedOffers } from '../actions/ambassador-reports';
import AmbassadorPerformanceFilters from '../components/ambassador-performance/ambassador-performance-filters';
import AmbassadorPerformanceReports from '../components/ambassador-performance/ambassador-performance-reports';

export const AmbassadorPerformanceContainer = (props) => {
    const { advertiser, authData, history } = props;
    const { 'custom:advertiser_id': advertiser_id } = authData && authData.attributes;

    const dateFormatMidnight = 'YYYY-MM-DD 00:00:00';
    const dateFormatEOD = 'YYYY-MM-DD 23:59:59';
    const dateFormatDisplay = 'MMMM D, YYYY';

    // set past 7 days as the default date range
    const advertiserStartDate = (advertiser && advertiser.date_added)
        ? moment(advertiser.date_added).format(dateFormatMidnight)
        : '2019-01-01 00:00:00';

    let startDate = moment().subtract(6, 'days').format(dateFormatMidnight);
    // if 7 days ago is before the advertiser start date, set start date to advertiser start date
    if (moment(advertiserStartDate).isAfter(moment(startDate))) {
        startDate = advertiserStartDate;
    }
    const endDate = moment().format(dateFormatEOD);

    // filters to load new reports
    const [reportFilters, setReportFilters] = useState({
        startDate,
        endDate,
        offer_id: null,
        searchValue: '',
        sortBy: 'revenue',
        page: 1
    });

    // separate from reportFilters cause polaris is finicky with dates
    const [datePicker, setDatePicker] = useState({
        startDate: new Date(startDate),
        endDate: new Date(endDate),
        pickingStart: false
    });

    const [searchValue, setSearchValue] = useState('');
    const [selectedOfferName, setSelectedOfferName] = useState('');
    const [filtersOpen, setFiltersOpen] = useState(false);

    // get ambassadors and offers
    const [offers, setOffers] = useState([]);
    const [offersLoading, setOffersLoading] = useState(true);

    // get a list of all the advertiser's offers
    useEffect(() => {
        getApprovedOffers(advertiser_id)
            .then((data) => {
                setOffers(data);
                setOffersLoading(false);
            })
            .catch(() => {
                setOffersLoading(false);
            });
    }, []);

    // get ambassador reports and pagination details
    const { ambassadors, isLoading, errorMessage, numPages, count } = useAmbassadorReports(advertiser_id, reportFilters);

    if (!authData || !authData.attributes || !advertiser_id) {
        return null;
    }

    // handlers for filters
    const handleDateChange = (dateRange) => {
        // if user clicks the start day, pickingStart will currently be false, so read it as true
        // if user clicks the end day, pickingStart will currently be true, so read it as false
        const isSelecting = !datePicker.pickingStart;
        setDatePicker({
            startDate: dateRange.start,
            endDate: dateRange.end,
            pickingStart: isSelecting
        });

        if (dateRange.start !== dateRange.end) {
            setReportFilters({
                ...reportFilters,
                startDate: moment(dateRange.start).format(dateFormatMidnight),
                endDate: moment(dateRange.end).format(dateFormatEOD)
            });
            setFiltersOpen(false);
        }
    };

    const handleOfferChange = (offerId) => {
        if (offerId === '') {
            offerId = null;
            setSelectedOfferName('');
        } else {
            const offerName = offers.find((offer) => {
                return offer.offer_id === offerId;
            }).name;
            setSelectedOfferName(offerName);
        }

        setReportFilters({
            ...reportFilters,
            offer_id: offerId
        });
        setFiltersOpen(false);
    };

    const handleSearchChange = (term) => {
        setSearchValue(term);
    };

    const handleSearchEnter = (e) => {
        e.preventDefault();
        const sanitizedSearchValue = searchValue.replace(/[^\w\d$-]+/g, '').toLowerCase();
        setReportFilters({
            ...reportFilters,
            searchValue: sanitizedSearchValue
        });
        setFiltersOpen(false);
    };

    const handleSortChange = (sortBy) => {
        setReportFilters({
            ...reportFilters,
            sortBy: sortBy[0]
        });
        setFiltersOpen(false);
    };

    const changePage = (page) => {
        setReportFilters({
            ...reportFilters,
            page
        });
        setFiltersOpen(false);
    };

    return (
        <div data-test="container-ambassadorPerformance">
            <Page
                title="Ambassador Performance"
                separator
                fullWidth
            >
                <div className="Performance" data-test="component-ambassadorPerformance">
                    <AmbassadorPerformanceReports
                        ambassadors={ambassadors}
                        history={history}
                        isLoading={isLoading}
                        errorMessage={errorMessage}
                        selectedOfferName={selectedOfferName}
                        startDate={moment(reportFilters.startDate).format(dateFormatDisplay)}
                        endDate={moment(reportFilters.endDate).format(dateFormatDisplay)}
                        defaultStartDate={moment(startDate).format(dateFormatDisplay)}
                        defaultEndDate={moment(endDate).format(dateFormatDisplay)}
                        searchValue={reportFilters.searchValue}
                        sortBy={reportFilters.sortBy}
                        pagination={{
                            curPage: reportFilters.page,
                            changePage,
                            numPages,
                            count
                        }}
                    />
                    <AmbassadorPerformanceFilters
                        offersLoading={offersLoading}
                        offers={offers}
                        filters={reportFilters}
                        datePicker={datePicker}
                        searchValue={searchValue}
                        advertiserStartDate={advertiserStartDate}
                        handleDateChange={handleDateChange}
                        handleOfferChange={handleOfferChange}
                        handleSortChange={handleSortChange}
                        handleSearchChange={handleSearchChange}
                        handleSearchEnter={handleSearchEnter}
                        setFiltersOpen={setFiltersOpen}
                        filtersOpen={filtersOpen}
                    />
                </div>
            </Page>
        </div>
    );
};

AmbassadorPerformanceContainer.propTypes = {
    advertiser: PropTypes.shape({
        date_added: PropTypes.string.isRequired
    }).isRequired,
    authData: PropTypes.shape({
        attributes: PropTypes.shape({
            'custom:advertiser_id': PropTypes.string.isRequired
        }).isRequired
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired
}

const mapStateToProps = (state) => {
    return {
        authData: state.authData,
        offers: state.offers,
        advertiser: state.advertiser
    };
};

export default connect(mapStateToProps)(AmbassadorPerformanceContainer);
