/* import axios from 'axios';
import { NEXT_APP_API_URL } from '../config'; */
import {sendGraphQL} from './sendRequest'

export const UPDATE_OFFER_CREATIVES_PENDING = 'UPDATE_OFFER_CREATIVES_PENDING';
export const UPDATE_OFFER_CREATIVES_FULFILLED = 'UPDATE_OFFER_CREATIVES_FULFILLED';
export const UPDATE_OFFER_CREATIVES_REJECTED = 'UPDATE_OFFER_CREATIVES_REJECTED';

export const updateOfferCreatives = (data, offer_id) => {
    if(!data){
        return null
    }

    const query = `
        mutation updateOffer($data: UpdateOfferInput, $offer_id: String, $updateHasOffers: Boolean)  {
          updateOffer(data: $data, offer_id: $offer_id, updateHasOffers: $updateHasOffers) {
            offer_id
            active
            advertiser_id
            name
            creatives {
              name
              size
              type
              url
            }
          }
        }
    `;

    let payload = {
        query,
        variables: {
            "updateHasOffers":false,
            data,
            offer_id
        }
    };

    return {
        type: "UPDATE_OFFER_CREATIVES",
        payload: sendGraphQL(payload)
            .then(response => response)
    }

    /* let headers = {
        "Content-Type": 'application/json'
    };

    return {
        type: "UPDATE_OFFER_CREATIVES",
        payload: axios.post(NEXT_APP_API_URL, payload, { headers }).then(response => response)
    } */

};

