/**
 * @author Harshal Mahajan <Harshal.Mahajan@harbingergroup.com>
 * @function getAffiliateInviteButtonMode
 * @description  Gives the details of INVITE button mode
 * @param {Array} affiliateOffers Offers for which affiliate already opt out.
 * @param {Array} offers The offers of the advertiser.
 * @returns {Object} It is returning the details of text, color and button status
 */
export const getAffiliateInviteButtonMode = (affiliateOffers, offers) => {
    const availableOffers = affiliateOffers ? getAvailableOffersForAmbassador(affiliateOffers, offers) : [];
    let inviteText, inviteColor, isInvitedisabled;

    if (Array.isArray(affiliateOffers) && affiliateOffers.length === 0 || !Array.isArray(offers)) {
        // Check if Ambassador doesn't have any offer and there are active offers present into the system
        inviteText = 'Invite';
        inviteColor = '#FD2856';
        isInvitedisabled = false;
    } else if (Array.isArray(offers) && offers.length > 0
        && Array.isArray(affiliateOffers) && availableOffers.length === 0) {
        // Check if Ambassadors's offers are same as active offers present into the system.
        inviteText = 'Invited';
        inviteColor = '#bbe5b3';
        isInvitedisabled = true;
    } else if (Array.isArray(affiliateOffers) && affiliateOffers.length >= 1) {
        // If amabssadors have offers but some system offers are not yet applied.
        inviteText = 'Invited';
        inviteColor = '#ffc58b';
        isInvitedisabled = false;
    } else {
        inviteText = 'Invite';
        inviteColor = '#FD2856';
        isInvitedisabled = false;
    }

    return {
        inviteText,
        inviteColor,
        isInvitedisabled
    }
}

const getAvailableOffersForAmbassador = (affiliateOffers, offers) => {
    const systemOffers = offers.map((offer) => { return offer.offer_id; });
    return systemOffers.filter((offer) => { return affiliateOffers.length > 0 && affiliateOffers.indexOf(offer) === -1; });
};
