import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Page, Card, Thumbnail, Banner } from '@shopify/polaris';
import { LlamaButton } from 'llama-library/components';

import { getOfferById } from '../actions/offers';
import { updateOfferCreatives } from '../actions/update-offer-creatives';
import { getApplicationsForOffer } from '../actions/applications';
import { getAdvertiser } from '../actions/advertiser';

import OfferData from '../components/offer-data/offer-data';
import CampaignSummary from '../components/campaign-summary/campaign-summary';

import editIcon from '../assets/edit-white.svg';

import './styles/offer-view.css';

const iconStyle = {
    height: '1.6rem',
    width: '1.6rem',
    position: 'relative',
    top: '-1px'
};

export class ViewOfferContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            savedAdvertiserId: props.advertiser.advertiser_id,
            offer: {},
            applications: null,
            discountError: null,
            isLoading: true,
            ...props.location.state
        };

        this.editOffer = this.editOffer.bind(this);
        this.editCreatives = this.editCreatives.bind(this);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.advertiser.advertiser_id && prevState && (nextProps.advertiser.advertiser_id !== prevState.savedAdvertiserId)) {
            return {
                savedAdvertiserId: nextProps.advertiser.advertiser_id
            };
        }
        return null;
    }

    componentDidMount() {
        if (!this.props.advertiser.advertiser_id && !this.props.match.params.id) {
            return null;
        }

        // Update the redux store for offers if offerStateUpdate flag is true
        if (this.props.location.state && this.props.location.state.offerStateUpdate === true) {
            this.props.getAdvertiser(this.props.advertiser.myshopify_domain);
        }
        //this.setState({ isLoading: true });
        this.getOffer(this.props.match.params.id, this.props.advertiser.advertiser_id);
        this.getApplications(this.props.match.params.id, this.props.advertiser.advertiser_id);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.advertiser.advertiser_id !== this.props.advertiser.advertiser_id) {
            console.log('Getting the offer and applications');
            this.getOffer(this.props.match.params.id, this.props.advertiser.advertiser_id);
            this.getApplications(this.props.match.params.id, this.props.advertiser.advertiser_id);
        }
    }

    getOffer(offer_id, advertiser_id) {
        if (!offer_id || !advertiser_id) {
            return null;
        }

        const end_date = new Date();
        end_date.setDate(end_date.getDate() + 1);
        const start_date = new Date();
        start_date.setDate(start_date.getDate() - 29);

        this.props.getOffer(offer_id, advertiser_id, start_date.toISOString(), end_date.toISOString())
            .then(({ value: { data } }) => {
                if (
                    !Array.isArray(data.advertisers)
                    || Array.isArray(!data.advertisers[0].offers)
                ) {
                    this.setState({ isLoading: false });
                    return;
                }
                console.log('GET OFFER', data);
                this.setState({ offer: data.advertisers[0].offers[0], isLoading: false });
            })
            .then(() => {
                this.setState({ savedAdvertiserId: advertiser_id, isLoading: false });
            });
    }

    getApplications(offer_id, advertiser_id) {
        if (!offer_id || !advertiser_id) {
            return null;
        }

        return getApplicationsForOffer(offer_id, advertiser_id)
            .then(({ advertisers }) => {
                if (!Array.isArray(advertisers) || advertisers.length <= 0) {
                    return null;
                }

                if (!Array.isArray(advertisers[0].applications)) {
                    return null;
                }

                const applications = advertisers[0].applications.reduce((applicationsList, application) => {
                    if (application.advertiser_status === 'APPROVED') {
                        applicationsList.push(application)
                    }
                    return applicationsList;
                }, []);

                this.setState({ applications });
                return true;
            });
    }

    changeOfferCreative = (data) => {
        let existingOffer = this.state.offer;
        existingOffer.creatives = data;
        this.setState({ offer: existingOffer });
    }

    addDiscount = async (data) => {

        if (data.error_message || data.error_message === '') {
            this.setState({ discountError: data.error_message });
            return;
        }

        let newDiscounts = Array.isArray(this.state.offer.discounts)
            ? this.state.offer.discounts.slice()
            : [];

        if (Array.isArray(data)) {
            await new Promise(res => {
                data.forEach(obj => {
                    newDiscounts.push(obj);
                });
                res(newDiscounts);
            })
        } else {
            newDiscounts.push(data);
        }

        const newOfferData = { ...this.state.offer, discounts: newDiscounts }
        this.setState({ offer: newOfferData, discountError: null });
    }

    editOffer() {
        this.props.history.push({
            pathname: `/offer/${this.props.match.params.id}/edit`,
            state: { values: this.state.offer },
            from: 'offer-detail'
        });
    }

    goToBoost() {
        this.props.history.push({
            pathname: `/offer/${this.props.match.params.id}/boost`,
            state: { values: this.state.offer },
        });
    }

    editCreatives() {
        this.props.history.push({
            pathname: `/offer/${this.props.match.params.id}/edit/creatives`,
            state: { values: this.state.offer }
        });
    }

    renderCreatives = (offer) => {
        if (!offer || !offer.offer_id) {
            return null;
        }

        const hasCreatives = this.state.offer.creatives && this.state.offer.creatives.length > 0;

        if (!hasCreatives) {
            return (
                <Banner
                    title="You don't have any creatives uploaded for this offer."
                    action={{ content: 'Upload Images', onAction: this.editCreatives }}
                    status="warning"
                >
                    Add visual elements for your affiliates to utilize in their campaigns.
                </Banner>
            );
        }

        const actions = [{ content: 'Edit', onAction: this.editCreatives }];

        return (
            <Card
                title="CREATIVES"
                actions={actions}
                subdued
            >
                <Card.Section>
                    <>
                        {offer.creatives.map((creative, index) => {
                            return (
                                <Thumbnail
                                    key={`creatives_${index}`}
                                    source={creative.url}
                                    size="large"
                                />
                            );
                        })}
                    </>
                </Card.Section>
            </Card>

        );
    }

    render() {
        console.log('OFFER VIEW PROPS', this.props);
        console.log('OFFER VIEW STATE', this.state);
        const pageButtons = (
            <p className="offer-view__page-buttons">
                {(!this.state.offer.sponsored || this.state.offer.sponsored === 'OFF') && this.state.offer.active && this.state.offer.interim_status !== 'ACTIVE' && !this.props.history.location.pathname.includes('boost')
                    && <LlamaButton classes={['boost']} onClick={this.goToBoost}>Boost</LlamaButton>
                }
                <LlamaButton classes={['edit']} onClick={this.editOffer}>Edit</LlamaButton>
            </p>
        );

        return (
            <div className="offer-view" style={{ width: '100%', maxWidth: '1440px', margin: 'auto' }}>
                <Page
                    fullWidth
                    title={this.state.offer && this.state.offer.name}
                    separator
                    primaryAction={pageButtons}
                    breadcrumbs={[{ content: 'Back to Offers', onAction: () => this.props.history.push('/offers') }]}
                >
                    <div className="offer-view__layout">
                        <OfferData
                            {...this.props}
                            {...this.state}
                            addDiscount={this.addDiscount}
                            changeOfferCreative={this.changeOfferCreative}
                        />
                        <div className="offer-view__summary">
                            {this.state.offer && this.state.offer.offer_id && <CampaignSummary {...this.props} values={this.state.offer} />}
                        </div>
                    </div>
                </Page>
            </div>
        );
    }
}

const mapStateToProps = ({ advertiser }) => {
    return { advertiser };
};

const mapDispatchtoProps = (dispatch) => {
    return {
        updateOfferCreatives: (data, offer_id) => dispatch(updateOfferCreatives(data, offer_id)),
        getAdvertiser: (shop) => dispatch(getAdvertiser(shop)),
        getOffer: (offer_id, advertiser_id, start_date, end_date) => dispatch(getOfferById(offer_id, advertiser_id, start_date, end_date))
    };
};

export default connect(mapStateToProps, mapDispatchtoProps)(ViewOfferContainer);
