import React, { useState, useEffect, useRef, useCallback } from 'react';
import { FormattedDate } from 'react-intl';
import { DatePicker } from '@shopify/polaris';
import { subDays } from 'date-fns';

import './date-picker.css';

/**
 * @Component LlamaDatePicker
 * 
 * Date picker styled for Llama.  Displays the current state of start date and end date, clicking the
 * dates will toggle a display window that allows the users to select either named dates (i.e. 'Today')
 * or select dates from a date picker.  The Polaris.DatePicker is used.
 *
 * @example
 *  ```jsx
 *      const defaultDates = {
 *          startDate: new Date('2019-02-01'),
 *          endDate: new Date('2019-02-28')
 *      }
 * 
 *      const handleDateChange = ({ startDate, endDate }) => {
 *          // Handle date values changes.
 *      }
 *      
 *      // ...
 * 
 *      <LlamaDatePicker 
 *          defaultDates={defaultDates}
 *          disabled={false}
 *          onDateChange={handleDateChange}
 *      />
 *  ```
 *
 * @prop {Boolean} disabled - Determines if the date pickers should be disabled due to loading
 * @prop {Object} defaultDates - Object containing the default or initial `startDate` and `endDate`.
 * @prop {({ startDate: Date, endDate: Date }) => null} onDateChange - Function that passes in an object parameter
 *     containing the startDate and endDate.
 */
const LlamaDatePicker = ({ disabled, onDateChange, defaultDates = {} }) => {

    const now = new Date();
    now.setHours(12, 0, 0);

    if (!defaultDates.startDate) {
        const startDate = new Date('2019-01-01T12:00:00');
        defaultDates.startDate = startDate;
    }

    if (!defaultDates.endDate) {
        defaultDates.endDate = now;
    }

    // Manages the visual display of the component.
    const [displayDateRange, setDisplayDateRange] = useState({
        display: false, 
        displayMonth: now.getMonth(),
        displayYear: now.getFullYear(),
    })

    // Handles the date ranges that will be passed to the parent.
    const [dateRange, setDateRange] = useState({ 
        ...defaultDates,
        checkForSingleDate: false,
    });

    useEffect(() => {
        const { startDate, endDate, checkForSingleDate } = dateRange;

        // `checkForSingleDate` is checked to ensure that the first selection
        // of a date range is not passed up to the parent component.
        if (!checkForSingleDate) {
            onDateChange({ startDate, endDate });
            toggleDisplayDateRange(false);
        }

    }, [dateRange])


    const dateFilterElement = useRef(null);

    /**
     * @function toggleDisplayDateRange
     * 
     * Callback function to show or hide the display window. Also, manages then
     * click event handler for outside clicks.  useCallback is utilized to ensure
     * the same reference to `dateRangeClickHandler` is used.  Otherwise, you would
     * end up with multiple click handlers being added and listened to.
     * 
     * @param {Boolean} display
     */
    const toggleDisplayDateRange = useCallback((display) => {
        if (!display) {
            document.removeEventListener('click', dateRangeClickHandler, false);
            setDisplayDateRange((state) => {
                return { ...state, display: false }
            });
        } else {
            document.addEventListener('click', dateRangeClickHandler, false);
            setDisplayDateRange((state) => {
                return { ...state, display: true };
            })
        }
    }, [])

    // Check click to see if it is within the date range filter element,
    // if it is outside, then we will close the display window.
    const dateRangeClickHandler = (e) => {
        if (dateFilterElement.current.contains(e.target)) {
            return null;
        }
        toggleDisplayDateRange(false);
    }

    const handleDateChange = ({ start, end }, namedDate) => {

        // When a namedDate is selected, we do not need to check for single date
        // selections as the named date will contain both the start and end dates.
        if (namedDate) {
            setDateRange({ startDate: start, endDate: end, checkForSingleDate: false })
            return;
        }

        // If the start and end dates are the same, then we need to wait for the second
        // selection to determine if the user only wants to see that date or mutliple.
        if (start === end && !dateRange.checkForSingleDate) {
            setDateRange({ startDate: start, endDate: end, checkForSingleDate: true });
            return;
        }

        setDateRange({ startDate: start, endDate: end, checkForSingleDate: false });
        toggleDisplayDateRange(false);
    }

    const handleMonthChange = (month, year) => {
        setDisplayDateRange((state) => ({ ...state, displayMonth: month, displayYear: year }))
    }

    const dateClickHandler = (event) => {
        if (disabled) {
            return;
        }

        toggleDisplayDateRange(event);
    }

    const namedDates = [
        {
            key: 'today',
            name: 'Today',
            setDates: () => {
                const now = new Date();
                now.setHours(12, 0, 0);
                handleDateChange({ start: now, end: now }, true);
                setTimeout(() => toggleDisplayDateRange(false));
            }
        },
        {
            key: 'yesterday',
            name: 'Yesterday',
            setDates: () => {
                const now = new Date();
                const yesterday = subDays(now, 1);
                yesterday.setHours(12, 0, 0);
                handleDateChange({ start: yesterday, end: yesterday }, true);
                setTimeout(() => toggleDisplayDateRange(false));
            }
        },
        {
            key: 'last-seven',
            name: 'Last 7 Days',
            setDates: () => {
                const now = new Date();
                const yesterday = subDays(now, 1);
                const sevenDays = subDays(yesterday, 6);
                yesterday.setHours(12, 0, 0);
                sevenDays.setHours(12, 0, 0);
                handleDateChange({ start: sevenDays, end: yesterday }, true);
                setTimeout(() => toggleDisplayDateRange(false));
            }
        },
        {
            key: 'this-month',
            name: 'This Month',
            setDates: () => {
                const now = new Date();
                now.setHours(12, 0, 0);
                const monthStart = new Date(now);
                monthStart.setDate(1);
                monthStart.setHours(12, 0, 0);
                handleDateChange({ start: monthStart, end: now }, true);
                setTimeout(() => toggleDisplayDateRange(false));
            }
        },
    ]

    const handleNamedDateClick = (key) => {
        const dateSelected = namedDates.find((item) => item.key === key);
        if (dateSelected.setDates) {
            dateSelected.setDates();
        }
    }

    const dateFormat = {
        year: 'numeric',
        month: 'short',
        day: '2-digit'
    }

    const datePickerClasses = disabled
        ? "LlamaDatePicker__DateFilter LlamaDatePicker__DateFilter--disabled"
        : "LlamaDatePicker__DateFilter"

    return (
        <div className={datePickerClasses} onClick={dateClickHandler} ref={dateFilterElement}>

            <FormattedDate 
                { ...dateFormat }
                value={dateRange.startDate} 
            /> 
            {dateRange.startDate !== dateRange.endDate && (
                <React.Fragment>
                    &nbsp;&ndash;&nbsp;
                    <FormattedDate
                        { ...dateFormat }
                        value={dateRange.endDate}
                    />
                </React.Fragment>
            )}
            <div className="LlamaDatePicker__Icon">
                <svg viewBox="0 0 20 20" className="Polaris-Icon__Svg" focusable="false" aria-hidden="true">
                    <path d="M13 8l-3-3-3 3h6zm-.1 4L10 14.9 7.1 12h5.8z" fill="#637481"></path>
                </svg>
            </div>

            {displayDateRange.display && (
                <div className="LlamaDatePicker__PopoverLocator">
                    <div className="LlamaDatePicker__DatePopover">
                        <ul className="LlamaDatePicker__NamedDates">
                            {namedDates.map((date) => {
                                return <li onClick={() => handleNamedDateClick(date.key)} key={date.key}>
                                    {date.name}
                                </li>
                            })}
                        </ul>
                        <div className="LlamaDatePicker__DatePicker">
                            <DatePicker 
                                month={displayDateRange.displayMonth}
                                year={displayDateRange.displayYear}
                                onMonthChange={handleMonthChange}
                                onChange={handleDateChange}
                                allowRange
                                selected={{ start: dateRange.startDate, end: dateRange.endDate }}
                                disableDatesBefore={new Date('2019-01-01T00:00:00')}
                                disableDatesAfter={new Date()}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
        
}

export default LlamaDatePicker;