import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Select } from '@shopify/polaris';
import { ResponsiveLine } from '@nivo/line';

import LlamaDatePicker from '../llama/date-picker';

import numberFormat, { moneyFormat } from '../../utils/number-format';
import findTopTick, { formatBottomTickValues } from '../../utils/graph-ticks';

import './date-comparison-graph.css';

const graphCompareOptions = [
    { label: 'Clicks', value: 'clicks' },
    { label: 'Impressions', value: 'impressions' },
    { label: 'Unique Impressions', value: 'uniqueImpressionsPerDay' },
    { label: 'Applicants', value: 'applicants' },
    { label: 'Ad Spend', value: 'charges' }
];

const dateFormat = 'YYYY-MM-DD';

const DateGraph = ({ data, ticks, startDate, endDate, metrics }) => {
    const metricsDeduped = [...new Set(metrics)].reverse();
    const metricTitles = metricsDeduped.reduce((acc, metric) => {
        const title = graphCompareOptions.find((option) => {
            return option.value === metric;
        }).label;
        acc.push(title);

        return acc;
    }, []);

    const colors = ['#FFA72C', '#FD2856'];

    // list all the days
    const days = moment(endDate).diff(moment(startDate), 'days');
    let nextDay = moment(startDate);
    const graphData = metricTitles.map((title, index) => {
        return { id: title, data: [], color: colors[index] };
    });

    for (let i = 0; i <= days; i++) {
        const nextDayFormatted = nextDay.format(dateFormat);

        metricsDeduped.forEach((metric, index) => {
            let match = null;
            if (data[metric]) {
                match = data[metric].find((stat) => {
                    return moment(stat.date).format(dateFormat) === nextDayFormatted;
                });
            }

            let amount = 0;
            if (match && match.amount) {
                amount = match.amount;
            }
            // round ad spend to 2 decimals
            if (graphData[index].id === 'Ad Spend') {
                amount = Math.round(amount * 100) / 100;
            }

            graphData[index].data.push({
                x: nextDayFormatted,
                y: amount
            });
        });
        nextDay = nextDay.add(1, 'day');
    }

    let highestValue = 0;
    graphData.forEach((stat) => {
        stat.data.forEach(({ y }) => {
            if (y > highestValue) {
                highestValue = Math.ceil(y);
            }
        });
    });

    // make sure highestValue divisible by number of ticks
    while (highestValue % ticks !== 0) {
        highestValue++;
    }

    const topTick = findTopTick(highestValue);
    const tickDivisor = ticks - 1;
    const leftTickValues = [];
    let numTicks = ticks;
    if (numTicks > highestValue) {
        numTicks = highestValue;
    }
    if (numTicks === 0) {
        numTicks = 1;
    }
    for (let i = 0; i < numTicks; i++) {
        if (i === 0) {
            leftTickValues.push(0);
        } else if (i === numTicks - 1) {
            leftTickValues.push(topTick);
        } else {
            leftTickValues.push(Math.round((topTick / tickDivisor) * i));
        }
    }

    const bottomTickValues = formatBottomTickValues(graphData[0].data.map((item) => {
        return item.x;
    }));
    const axisBottom = {
        tickValues: bottomTickValues,
        format: (date) => {
            return moment(date).format('MM/DD');
        }
    };

    const axisLeft = { tickValues: leftTickValues };

    console.log(graphData, axisBottom, axisLeft);

    return (
        <ResponsiveLine
            data={graphData}
            colors={colors}
            pointSize={10}
            pointBorderWidth={2}
            pointColor="#FFFFFF"
            pointBorderColor={{ from: 'serieColor' }}
            enableGridX={false}
            axisLeft={axisLeft}
            axisBottom={axisBottom}
            gridYValues={leftTickValues}
            enableSlices="x"
            sliceTooltip={({ slice }) => {
                const date = moment(slice.points[0].data.xFormatted).format('MMM D, YYYY');
                return (
                    <div className="nivo-graph-tooltip-wrapper">
                        <span className="nivo-graph-tooltip-date">{date}</span>
                        {slice.points.map((item) => {
                            let amount = numberFormat(item.data.yFormatted);
                            if (item.serieId === 'Ad Spend') {
                                amount = moneyFormat(item.data.yFormatted);
                            }
                            return (
                                <span className="nivo-graph-tooltip-item" key={item.id}>
                                    <span className="nivo-graph-tooltip-color-block" style={{ background: item.serieColor }} />
                                    <span style={{ lineHeight: '1.1em' }}>
                                        {item.serieId}: {amount}
                                    </span>
                                </span>
                            );
                        })}
                    </div>
                );
            }}
            margin={{
                top: 20,
                right: 20,
                bottom: 100,
                left: 30
            }}
            legends={[
                {
                    anchor: 'bottom',
                    direction: 'row',
                    itemWidth: 150,
                    itemHeight: 20,
                    translateY: 70,
                    symbolShape: 'circle'
                }
            ]}
            padding={0.3}
        />
    );
};

export const SkeletonComparisonGraph = React.memo(() => {
    const startDate = moment().subtract(6, 'days').format(dateFormat);
    const endDate = moment().format(dateFormat);
    const graphCompare = ['impressions', 'clicks'];

    const mockData = {
        impressions: [],
        clicks: []
    };

    const days = moment(endDate).diff(moment(startDate), 'days');
    let nextDay = moment(startDate);

    for (let i = 0; i <= days; i++) {
        const nextDayFormatted = nextDay.format(dateFormat);

        mockData.impressions.push({ date: nextDayFormatted, amount: Math.ceil(Math.random() * 100) });
        mockData.clicks.push({ date: nextDayFormatted, amount: Math.ceil(Math.random() * 100) });

        nextDay = nextDay.add(1, 'day');
    }

    return (
        <>
            <div className="date-comparison-graph-heading">
                <div className="comparison">
                    <p>Showing:</p>
                    <Select
                        options={graphCompareOptions}
                        value={graphCompare[0]}
                        disabled
                    />
                    <p>vs.</p>
                    <Select
                        options={graphCompareOptions}
                        value={graphCompare[1]}
                        disabled
                    />
                </div>
                <LlamaDatePicker
                    defaultDates={{
                        startDate: new Date(`${startDate}T05:00:00Z`),
                        endDate: new Date(`${endDate}T05:00:00Z`)
                    }}
                    currentDates={{
                        startDate: new Date(`${startDate}T05:00:00Z`),
                        endDate: new Date(`${endDate}T05:00:00Z`)
                    }}
                    onDateChange={() => { return null; }}
                    disabled
                />
            </div>
            <div className="date-comparison-graph skeleton">
                <DateGraph
                    data={mockData}
                    ticks={4}
                    startDate={startDate}
                    endDate={endDate}
                    metrics={graphCompare}
                />
            </div>
        </>
    );
});

const DateComparisonGraph = ({ data, defaultCompareMetrics }) => {
    const defaultStartDate = moment().subtract(6, 'days').format(dateFormat);
    const defaultEndDate = moment().format(dateFormat);

    const [startDate, setStartDate] = useState(defaultStartDate);
    const [endDate, setEndDate] = useState(defaultEndDate);
    const [graphCompare, setGraphCompare] = useState(defaultCompareMetrics);

    const handleDateChange = (newDateRange) => {
        setStartDate(moment(newDateRange.startDate).format(dateFormat));
        setEndDate(moment(newDateRange.endDate).format(dateFormat));
    };

    return (
        <>
            <div className="date-comparison-graph-heading">
                <div className="comparison">
                    <p>Showing:</p>
                    <Select
                        options={graphCompareOptions}
                        onChange={(value) => { setGraphCompare([value, graphCompare[1]]); }}
                        value={graphCompare[0]}
                    />
                    <p>vs.</p>
                    <Select
                        options={graphCompareOptions}
                        onChange={(value) => { setGraphCompare([graphCompare[0], value]); }}
                        value={graphCompare[1]}
                    />
                </div>
                <LlamaDatePicker
                    onDateChange={handleDateChange}
                    defaultDates={{
                        startDate: new Date(`${defaultStartDate}T05:00:00Z`),
                        endDate: new Date(`${defaultEndDate}T05:00:00Z`)
                    }}
                    currentDates={{
                        startDate: new Date(`${startDate}T05:00:00Z`),
                        endDate: new Date(`${endDate}T05:00:00Z`)
                    }}
                />
            </div>
            <div className="date-comparison-graph">
                <DateGraph
                    data={data}
                    ticks={4}
                    startDate={startDate}
                    endDate={endDate}
                    metrics={graphCompare}
                />
            </div>
        </>
    );
};

export default DateComparisonGraph;
