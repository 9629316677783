import axios from 'axios';
import { NEXT_APP_API_URL } from '../config';
import {sendGraphQL} from './sendRequest'

import offerFragment from './fragments/offer.fragment';

export const GET_ADVERTISER_PENDING = 'GET_ADVERTISER_PENDING';
export const GET_ADVERTISER_FULFILLED = 'GET_ADVERTISER_FULFILLED';
export const GET_ADVERTISER_REJECTED = 'GET_ADVERTISER_REJECTED';

export const getAdvertiser = (shop = null, isAxiosCall = false) => {

    console.log("isAxiosCall", isAxiosCall);
    if (!shop) return null;

    const query = `
        query getAdvertiser($domain: String) {
            advertisers(myshopify_domain: $domain) {
                advertiser_id
                status
                trial_length
                date_added
                phone
                address1
                address2
                city
                region
                zipcode
                country
                tags
                shop_owner
                name
                company
                description
                domain
                email
                myshopify_domain
                avatar_image
                categories
                sign_up_flow
                new_product_status
                timeline_settings{
                    lookback{
                        time
                        edited
                    }
                    lookforward{
                        time
                        edited
                    }
                }
                email_preference{
                    application_general
                    application_alerts
                    application_affiliate_messages
                    application_weekly_summary
                    newsletter_general
                    newsletter_marketing
                    newsletter_updates
                    email_unsubscribe
                }
                paypal {
                    urlCode
                    invoices
                    refresh
                    access_token
                    id_token
                    user
                    logout
                }
                offers {
                    ...OfferData
                }
                notifications {
                    notification_id
                    notification_type
                    recipient_type
                    recipient_id
                    target_type
                    target_id
                    view_status
                    title
                    note
                    destination_link
                    ttl{
                        start
                        end
                    }
                    message
                    image {
                        icon
                        source
                        type
                    }
                }
            }
        }

        ${offerFragment}
    `

    const variables = {
        domain: shop
    }

    let payload = {
        query,
        variables
    }

    if(!isAxiosCall) {
        return {
            type: "GET_ADVERTISER",
            payload: sendGraphQL(payload)
                .then(response => response.data)
        }
    }

    let headers = {
        "Content-Type": 'application/json'
    }

    return {
        type: "GET_ADVERTISER",
        payload: axios.post(NEXT_APP_API_URL, payload, { headers })
            .then(response => response.data)
    }
}
