import React, {useState, useEffect} from 'react';
import {
    RangeSlider,
    Banner,
    Button,
    Modal,
    SkeletonBodyText
} from '@shopify/polaris';
import numberFormat from '../../utils/number-format';

const LookBackSlider = (props) => {
    const [sliderValue, setSliderValue] = useState(props.sliderValue)
    const [modalActive, setModalActive] = useState(false)
    const [saving, setSaving] = useState(false)

    useEffect(() => {
        setSliderValue(props.sliderValue)
    }, [props.sliderValue])

    const handleModal = () => {
        setModalActive(!modalActive)
    }

    const modalConfirmation = () => {
        let now = new Date();
        handleModal();
        setSaving(true)
        let payload = {
            timeline_settings: {
                ['lookback']: {
                    time: sliderValue.toString(),
                    edited: now
                }
            } 
        }
        props.submitAdvertiserUpdate(payload, 'lookback')
            .then(() => {
                setSaving(false)
            })
    }

    // if the user's lockout date has passed show a confirmation modal, otherwise show an error toast
    const saveSettings = () => {
        if(props.lockedDays == 0){
            handleModal();
        }else{
            props.setToastVerbiage("Sorry, you must wait " + props.lockedDays + " days to change this setting")
            handleChange(props.initialValue)
        }
    }

    const restoreDefaults = () => {
        setSliderValue(12)
        saveSettings();
     }

    const handleChange = ( value ) => {
        setSliderValue(value)
        props.calculateLookBackCustomers(value);
    };

    return (
        <>
            <Modal
                open={modalActive}
                onClose={handleModal}
                title="Are you sure you want to save?"
                primaryAction={{
                    content: 'I understand, lock in my settings.' ,
                    onAction: modalConfirmation,
                }}
                secondaryActions={[
                    {
                        content: 'Cancel',
                        onAction: handleModal
                    },
                ]}
                >
                <Modal.Section>
                    <p><strong>Please Note:</strong><br />The Look Back window acts as a contract between you and your ambassadors. Once you save this setting, you won&rsquo;t be able to change it for the next 30 days. Please make sure you&rsquo;re ready to lock in this setting.</p>
                </Modal.Section>
            </Modal>
            
            <figure className="settings-ltv__slider-wrap">
                <figcaption>Your current Look Back period is set to <span>{sliderValue} month{sliderValue !== 1 && 's'}</span></figcaption>
                {sliderValue
                    ? <RangeSlider
                        className="settings-ltv__slider"
                        step="1"
                        min="1"
                        max="24"
                        value={sliderValue}
                        onChange={handleChange}
                    />
                    : <p className="settings-ltv__slider-loading">Loading...</p>
                }
                <p className="start-end-labels">
                    <span className="start">1 month</span>
                    <span className="end">24 months</span>
                </p>
                {props.LTVOrdersLoaded ?
                    <Banner>Based your current order history trends, this period could lock out approximately <strong>{numberFormat(props.lockedOut.oneTime, 1, false)} one-time customer{props.lockedOut.oneTime !== 1 && 's'}</strong> and <strong>{numberFormat(props.lockedOut.repeat, 1, false)} repeat customer{props.lockedOut.repeat !== 1 && 's'}</strong>.</Banner>
                :
                    <SkeletonBodyText lines={1} />
                }
            </figure>
            <p className="settings-ltv__page-actions" data-enabled={props.lockedDays == 0}>
                <Button loading={saving} onClick={saveSettings}>Save Look Back Settings</Button>
                <a className="settings-ltv__restore-ltv" onClick={restoreDefaults}>
                    Restore Default Settings
                </a>
            </p>
        </>
    )
}

export default LookBackSlider;