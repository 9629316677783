/* import axios from 'axios';
import {NEXT_APP_API_URL} from '../config'; */
import {sendGraphQL} from './sendRequest'

export const sendPasswordChangeConfirmationEmail = (advertiser_id, email, browser, os) => {
    if (!advertiser_id) {
        return null;
    }

    let browserOs = browser;
    if(browser && os) browserOs+= ` (${os})`;
    if(!browser && os) browserOs = `Unknown Browser (${os})`;
    if(!browser && !os) browserOs = `Unknown`;

    const query = `
        query sendAdvPasswordChangeConfirmationEmail($advertiser_id: String!, $email: String! $browserOs: String){
            sendAdvPasswordChangeConfirmationEmail(advertiser_id: $advertiser_id, email: $email browserOs: $browserOs){
                sent
            }
        }
    `;

    const variables = {
        advertiser_id,
        email,
        browserOs
    }

    const payload = {
        query,
        variables
    }

    sendGraphQL(payload, true)

    /* const headers = {
        'Content-Type': 'application/json'
    }

    return axios.post(NEXT_APP_API_URL, params, {headers})
        .then(response => response.data) */
}