/* import axios from 'axios';
import { NEXT_APP_API_URL } from '../config'; */
import { sendGraphQL } from './sendRequest';

const GET_BRANDED_SIGNUP = 'GET_BRANDED_SIGNUP';
export const GET_BRANDED_SIGNUP_PENDING = `${GET_BRANDED_SIGNUP}_PENDING`;
export const GET_BRANDED_SIGNUP_FULFILLED = `${GET_BRANDED_SIGNUP}_FULFILLED`;
export const GET_BRANDED_SIGNUP_REJECTED = `${GET_BRANDED_SIGNUP}_REJECTED`;

const INVITE_PAGE_OFFERS = 'INVITE_PAGE_OFFERS';
export const INVITE_PAGE_OFFERS_FULFILLED = `${INVITE_PAGE_OFFERS}_FULFILLED`;

const UPDATE_BRANDED_SIGNUP = 'UPDATE_BRANDED_SIGNUP';
export const UPDATE_BRANDED_SIGNUP_PENDING = `${UPDATE_BRANDED_SIGNUP}_PENDING`;
export const UPDATE_BRANDED_SIGNUP_FULFILLED = `${UPDATE_BRANDED_SIGNUP}_FULFILLED`;
export const UPDATE_BRANDED_SIGNUP_REJECTED = `${UPDATE_BRANDED_SIGNUP}_REJECTED`;

export const getBrandedSignUpCustomizations = (advertiser_id) => {
    if (!advertiser_id) {
        return null;
    }

    const query = `
        query getBrandedSignUp($advertiser_id: String) {
            advertiserById(advertiser_id: $advertiser_id) {
                branded_sign_up{
                    slug
                    display_offer_data
                    body
                    banner_image
                    offer_id
                }
            }
        }
    `;

    const variables = { advertiser_id };

    const payload = {
        query,
        variables
    };

    return {
        type: GET_BRANDED_SIGNUP,
        payload: sendGraphQL(payload)
            .then((response) => { return response.data; })
    };
};

const brandedSignUpFragment = `
    fragment BrandedSignUpData on Advertiser {
        name
        terms
        domain
        avatar_image
        timeline_settings {
            lookback {
                time
            }
            lookforward {
                time
            }
        }
        branded_sign_up {
            slug
            display_offer_data
            body
            banner_image
            offer_id
            brand_color
            shopify_promo
            offer {
                name
                offer_id
                category
                rate {
                    type
                    amount
                }
                longterm_rate {
                    type
                    amount
                }
                tracking {
                    Stat {
                        epc
                        clicks
                        revenue
                        sale_amount
                        conversions
                    }
                }
                long_term_rate{
                    ltv
                    avg_cart_value
                }
                reports {
                    ltv
                    cartAvg
                }
                products {
                    includeAll
                    includeSome
                    includeProducts
                }
            }
        }
    }
`;

export const getBrandedSignUp = (advertiser_id) => {

    if (!advertiser_id) {
        return null;
    }

    const query = `
        query getBrandedSignUp($advertiser_id: String) {
            advertiserById(advertiser_id: $advertiser_id) {
                ...BrandedSignUpData
                offers {
                    name
                    offer_id
                    category
                    rate {
                        type
                        amount
                    }
                    longterm_rate {
                        type
                        amount
                    }
                    tracking {
                        Stat {
                            epc
                            clicks
                            revenue
                            sale_amount
                            conversions
                        }
                    }
                    long_term_rate{
                        ltv
                        avg_cart_value
                    }
                    reports {
                        ltv
                        cartAvg
                    }
                    products {
                        includeAll
                        includeSome
                        includeProducts
                    }
                }
            }
        }

        ${brandedSignUpFragment}
    `;

    const variables = { advertiser_id };

    const payload = {
        query,
        variables
    };

    return {
        type: GET_BRANDED_SIGNUP,
        payload: sendGraphQL(payload)
            .then((response) => { return response.data; })
    };

    /* const headers = {
        "Content-Type": 'application/json'
    }

    return {
        type: GET_BRANDED_SIGNUP,
        payload: axios.post(NEXT_APP_API_URL, payload, { headers })
            .then(response => response.data)
    } */
};

export const updateBrandedSignup = (advertiser_id, data) => {
    if (!advertiser_id || !data || !data.branded_sign_up || !data.branded_sign_up.slug ) {
        return null;
    }

    const query = `
        mutation updateBrandedSignup($advertiser_id: String, $data: UpdateAdvertiserInput) {
            updateAdvertiser(
                advertiser_id: $advertiser_id,
                data: $data
            ) {
                ...BrandedSignUpData
            }
        }

        ${brandedSignUpFragment}
    `;

    const variables = {
        advertiser_id,
        data
    };

    const payload = {
        query,
        variables
    };

    return {
        type: UPDATE_BRANDED_SIGNUP,
        payload: sendGraphQL(payload)
            .then((response) => { return response.data; })
            .catch(() => { return null; })
    };

    /* const headers = {
        "Content-Type": 'application/json'
    }

    return {
        type: UPDATE_BRANDED_SIGNUP,
        payload: axios.post(NEXT_APP_API_URL, payload, { headers })
            .then(response => response.data)
            .catch(() => null)
    } */
};

export const invitePageOffers = (advertiser_id) => {
    if (!advertiser_id) {
        return null;
    }

    const query = `
        query getBrandedSignUp($advertiser_id: String) {
            advertiserById(advertiser_id: $advertiser_id) {
                ...BrandedSignUpData
                offersForBrandedSignup {
                    name
                    offer_id
                    category
                    rate {
                        type
                        amount
                    }
                    longterm_rate {
                        type
                        amount
                    }
                    tracking {
                        Stat {
                            epc
                            clicks
                            revenue
                            sale_amount
                            conversions
                        }
                    }
                    long_term_rate{
                        ltv
                        avg_cart_value
                    }
                    reports {
                        ltv
                        cartAvg
                    }
                    products {
                        includeAll
                        includeSome
                        includeProducts
                    }
                }
            }
        }

        ${brandedSignUpFragment}
    `;

    const variables = { advertiser_id };

    const payload = {
        query,
        variables
    };

    // let headers = {
    //     "Content-Type": 'application/json'
    // }

    // return {
    //     type: INVITE_PAGE_OFFERS,
    //     payload: axios.post(NEXT_APP_API_URL, payload, { headers })
    //         .then(response => {
    //             console.log('action  ',response);
    //             return response.data})
    // }

    return {
        type: INVITE_PAGE_OFFERS,
        payload: sendGraphQL(payload)
            .then((response) => { return response.data; })
    };
};

export const checkBrandedSlug = (advertiser_id, slug) => {
    const query = `
        query checkBrandedSlug($advertiser_id: String, $slug: String){
            checkBrandedSlug(advertiser_id: $advertiser_id, slug: $slug){
                is_available
            }
        }
    `;

    const variables = {
        advertiser_id,
        slug
    };

    const payload = {
        query,
        variables
    };

    return {
        type: 'CHECK_SLUG',
        payload: sendGraphQL(payload)
            .then((response) => { return response.data; })
            .catch((error) => { return error; })
    };
};
