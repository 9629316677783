import React from 'react';
import { uniqBy, isEqual } from 'lodash';

const withProducts = (WrappedComponent) => {
    return class extends React.Component {

        constructor(props) {
            super(props);

            this.state = {
                products: [],
                collections: [],
                savedAdvertiser: props.advertiser,
            };

            this.setProducts = this.setProducts.bind(this);
        }

        /**
         * @author Harshal Mahajan <Harshal.Mahajan@harbingergroup.com>
         * @function getDerivedStateFromProps
         * @description Check if new coming props have different advertiser_id compared to the previous state then update it with new one 
         * @param {Object} nextProps new props receving by component
         * @param {Object} prevState previous state of component
         * @returns {Object} null or updated state 
         */
        static getDerivedStateFromProps(nextProps, prevState) {
            if (
                nextProps.advertiser.id
                && prevState
                && !isEqual(nextProps.advertiser, prevState.savedAdvertiser)
            ) {
                return { savedAdvertiser: nextProps.advertiser };
            }
            return null;
        }

        componentDidMount() {
            if (this.props.advertiser.myshopify_domain) {
                this.props.getProductsForAdvertiser(this.props.advertiser.myshopify_domain)
                    .then(this.setProducts);
            }
        }

        /**
         * @author Harshal Mahajan <Harshal.Mahajan@harbingergroup.com>
         * @function componentDidUpdate
         * @description Compare the current props's advertiser_id with previous props advertiser_id and process the getInvoices if any differences found
         * @param {Object} prevState previous state of component
         */
        componentDidUpdate(prevProps) {
            if(!isEqual(prevProps.advertiser, this.props.advertiser)) {
                this.props.getProductsForAdvertiser(this.props.advertiser.myshopify_domain)
                    .then(this.setProducts);
            }
        }

        setProducts({ value }) {
            const { data } = value;
            if (!data
                || !Array.isArray(data.advertisers)
                || !data.advertisers[0]
                || !Array.isArray(data.advertisers[0].products)
            ) {
                return;
            }

            const products = uniqBy(data.advertisers[0].products, 'product_id');

            this.setState({
                products,
                collections: data.advertisers[0].collections
            });
        }

        render() {
            return <WrappedComponent { ...this.props } { ...this.state } />
        }
    };
};

export default withProducts;
