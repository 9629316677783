import React, { Component } from 'react';
import { connect } from 'react-redux';

import './header.css';
import { Auth } from 'aws-amplify';
import notificationBell from '../../assets/bell-gray.svg';
import { NotificationViewStatus } from '../../utils/types';

/**
 * @class Header
 * Holds user state, renders a class Component that
 * displays header title, username and notifications icon
 * that takes the user to the Notifications Sidebar.
 * 
 * @returns {ComponentClass}
 */
class Header extends Component {

    constructor(props) {
        super(props);

        this.state = {
            user: this.props.authData.username ? this.props.authData.username : '',
		}

    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.authData && nextProps.authData !== null
            && nextProps.authData.username && nextProps.authData.username !== null
            && nextProps.authData.username !== prevState.user) {

            return {
                user: nextProps.authData.username
            };

        }
        return null;
    }
    
    render() {

        const notificationCount = this.props.advertiser && Array.isArray(this.props.advertiser.notifications)
            ? this.props.advertiser.notifications
                .filter((item) => item.view_status === NotificationViewStatus.UNSEEN)
                .length
            : null;

        return (
            <div className="header">
                <div className="header__title">
                    {this.props.title}
                </div>

                <div className="header__notification-container">

                    <div className="header__username">
                        <span> {this.state.user} </span>
                    </div>
                    
                    <div className="header__notifications" onClick={this.props.toggleSideDrawer}>
                        {notificationCount !== 0 && notificationCount && (
                            <div className="header__notifications-indicator">
                                {notificationCount > 10 ? '10+' : notificationCount}
                            </div>
                        )}
                        <span className="header__notification">
                            <img 
                                src={notificationBell} 
                                className="header__notification-icon"
                            />
                        </span>
                    </div>

                </div>
            </div>
        )
    }

}

const mapStateToProps = (state) => {
    return state

}

export default connect(mapStateToProps)(Header);