import React, { useState } from 'react';
import { Modal, Card, Heading, TextStyle } from '@shopify/polaris';
import { LlamaButton, EmptyState } from 'llama-library/components';
import ImageDrop, { saveImage } from '../llama/image-drop';
import './offer-creatives.css';
import { deleteFileFromS3 } from "../../actions/upload-s3-file";

const iconStyle = {
    height: '1.6rem',
    width: '1.6rem',
    position: 'relative',
    top: '-1px',
};

/**
 * @function OfferCreatives
 * Accepts offer and props Objects, returns OfferCreatives Component.
 * Displays uploaded Creatives, gives Merchant option to upload and edit.
 *
 * @param {Object} offer
 * @param {Object} props
 *
 * @returns {FunctionComponent}
 */
const OfferCreatives = ({offer = {}, ...props}) => {
    const { isSubmitting } = props;

    const [images, setImages] = useState([]);
    const [modalShow, setModalShow] = useState({
        active: false,
        image: {
            url: "",
            name: ""
        },
        edit: false,
        deleteConfirm: false
    });

    const[currentCreatives, setCurrentCreatives] = useState([...offer.creatives]);
    const[fileToDelete, setFileToDelete] = useState({});
    const [deleting, setDeleting] = useState(false);
    const [uploading, setUploading] = useState(false);

    if (!offer || !offer.offer_id) {
        return null;
    }

    const uploadPath = `merchants/${props.advertiser.advertiser_id}/offers/${offer.offer_id}`;

    const handleImagePreview = (creative) => {
        setModalShow({active: true, image: {url: creative.url, name: creative.name}, edit: false, deleteConfirm: false});
    };

    const handleModal = () => {
        setModalShow({active: !modalShow.active, image: {url: "", name: ""}, edit: false, deleteConfirm: false})
    };

    const handleEditModal = () => {
        //remove files on close
        if(modalShow.edit){
            for(let i = 0; i < images.length; i++){
                window.URL.revokeObjectURL(images[i])
            }
            setImages([])
        }
        setModalShow({active: modalShow.active, image: modalShow.image, edit: !modalShow.edit, deleteConfirm: false})
    };

    const handleDeleteModal = () => {
        setModalShow({active: modalShow.active, image: modalShow.image, edit: false, deleteConfirm: !modalShow.deleteConfirm})
    };

    const saveFiles = () => {
        setUploading(true)
        const saveImagePromises = images.map(image => {
            return saveImage(image, props.advertiser.advertiser_id, `offers/${offer.offer_id}`, null)
                .then(result => {
                    let data = {
                        creatives: currentCreatives
                    };
                    data.creatives.push(result);
                    offer.creatives.push(result);
                    props.updateOfferCreatives(data, offer.offer_id);
                })
        })

        return Promise.all(saveImagePromises)
            .then(() => {
                setUploading(false)
                handleEditModal()
            });
    }

    const deleteConfirm = (file) => {
        setFileToDelete(file);
        handleDeleteModal();
    }

    const removeCreative = (file) => {
        setDeleting(true)
        deleteFileFromS3(file.name, uploadPath)
            .then(() => {
                const newCreatives = currentCreatives.filter((item) => {
                    return item.url !== file.url;
                });
                let data = {
                    creatives:  newCreatives
                };
                props.updateOfferCreatives(data, offer.offer_id);
                setCurrentCreatives(newCreatives);
                props.changeOfferCreative(newCreatives);
                setDeleting(false)
                handleDeleteModal();
            })
    };

    const renderCreatives = () => {
        return currentCreatives.length === 0
            ? <div className="OfferCreatives__image_container empty">
                    <EmptyState
                    message="You haven’t uploaded any creatives yet."
                    paragraph="Creatives are what we call the images that ambassadors can use to promote this offer on social media"
                    ctaText="Upload Creatives"
                    ctaAction={handleEditModal}
                />
              </div>
            : <>
                <div className="tab-content-heading">
                    <Heading>CREATIVES</Heading>
                    <TextStyle variation="subdued"><span className="subtitle">You have {currentCreatives.length} creatives.</span></TextStyle>
                    <LlamaButton
                        loading={isSubmitting}
                        onClick={handleEditModal}>
                        Add More Creatives
                    </LlamaButton>
                </div>
                <ul className="creatives-list">
                    {currentCreatives.map((creative, index) => {
                        return (
                            <li className="OfferCreatives__image_container" key={`creatives_${index}`}>
                                <Card>
                                    <p className="OfferCreatives__image_thumb" onClick={() => handleImagePreview(creative)}>
                                        <img alt={creative.name} src={creative.url} />
                                    </p>
                                    <LlamaButton onClick={() => deleteConfirm(creative)}>Delete</LlamaButton>
                                </Card>
                            </li>
                        )
                    })}
                </ul>
              </>
    }

    return (
        <div className="OfferCreatives">
            {/* enlarged image preview */}
            <Modal large open={modalShow.active} onClose={handleModal} title="">
                <Modal.Section>
                    <img className="OfferCreatives_imagePreview" src={modalShow.image.url}/>
                </Modal.Section>
            </Modal>

            {renderCreatives()}

            {/* add new images */}
            <Modal large open={modalShow.edit} onClose={handleEditModal} title="Upload Creatives" primaryAction={{
                content: 'Upload Creatives',
                onAction: saveFiles,
                loading: uploading,
                disabled: (images.length === 0)
            }}>
                <Modal.Section>
                    <p>For best results, make sure your images fit within any of the following dimensions:</p>
                    <ul className="image-sizes">
                        <li><b>Facebook:</b> 1200px x 630px</li>
                        <li><b>Instagram:</b> 1080px x 1080px</li>
                        <li><b>Instagram Stories:</b> 1080px x 1920px</li>
                        <li><b>Twitter:</b> 1024px x 512px</li>
                    </ul>
                </Modal.Section>
                <Modal.Section>
                    <ImageDrop 
                        multi={true}
                        buttonText="Add a Promotional Banner"
                        setImage={setImages}
                    />
                </Modal.Section>
            </Modal>

            {/* delete image confirmation */}
            <Modal open={modalShow.deleteConfirm} onClose={handleDeleteModal} title="Delete Creative" primaryAction={{
                content: 'Confirm Delete',
                onAction: () => removeCreative(fileToDelete),
                loading: deleting
            }}>
                <Modal.Section>
                    <p>Are you sure you want to delete this creative? This action cannot be undone.</p>
                </Modal.Section>
            </Modal>
        </div>
    )

};

export default OfferCreatives;