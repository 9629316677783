/* import axios from 'axios';
import { NEXT_APP_API_URL } from '../config'; */
import {sendGraphQL} from './sendRequest'

const UPDATE_ADVERTISER = 'UPDATE_ADVERTISER';
export const UPDATE_ADVERTISER_PAYMENT_PENDING = 'UPDATE_ADVERTISER_PAYMENT_PENDING';
export const UPDATE_ADVERTISER_PAYMENT_FULFILLED = 'UPDATE_ADVERTISER_PAYMENT_FULFILLED';
export const UPDATE_ADVERTISER_PAYMENT_REJECTED = 'UPDATE_ADVERTISER_PAYMENT_REJECTED';

export const updateAdvertiserPayments = (d, advertiser) => {

    const payload = {
        query: `mutation updateAdvertiser($advertiser_id: String, $data: UpdateAdvertiserInput) {
            updateAdvertiser(advertiser_id: $advertiser_id, data: $data) {
                paypal {
                    urlCode
                    invoices
                    refresh
                    access_token
                    id_token
                    user
                    logout
                }
            }
        }`,
        variables: {
            advertiser_id: advertiser,
            data: d
        }
    }

    return {
        type: UPDATE_ADVERTISER,
        payload: sendGraphQL(payload)
            .then(response => response.data)
    }

    /* let headers = {
        "Content-Type": 'application/json'
    }

    return {
        type: UPDATE_ADVERTISER,
        payload: axios.post(NEXT_APP_API_URL, payload, { headers })
            .then(response => response.data)
    } */

}

