import React from 'react';
import PropTypes from 'prop-types';
import { LlamaButton } from 'llama-library/components';

import './new-user-progress.css';

const NewUserProgress = ({ paypalComplete, offersComplete, logoComplete, pageComplete, ambassadorsComplete, history }) => {
    let stepsRemaining = 0;
    if (!paypalComplete) {
        stepsRemaining += 1;
    }
    if (!offersComplete) {
        stepsRemaining += 1;
    }
    if (!logoComplete) {
        stepsRemaining += 1;
    }
    if (!pageComplete) {
        stepsRemaining += 1;
    }
    if (!ambassadorsComplete) {
        stepsRemaining += 1;
    }

    if (stepsRemaining > 0) {
        return (
            <div id="new-user-progress">
                <h2>Welcome to Llama! Glad to have you in our herd.</h2>
                <p>You&rsquo;re only {stepsRemaining} step{stepsRemaining !== 1 && 's'} away from getting ambassadors to find you and promote your products.</p>
                <p className="progress" data-progress={5 - stepsRemaining}>
                    <progress min="0" max="5" value={5 - stepsRemaining} />
                    <span className="notch" />
                    <span className="notch" />
                    <span className="notch" />
                    <span className="notch" />
                </p>
                <ul>
                    <li className="paypal" data-complete={paypalComplete}>
                        <p className="heading">Connect your PayPal</p>
                        {!paypalComplete
                            && (
                                <>
                                    <p className="description">This is how you&rsquo;ll be paying your ambassadors. Your offers can&rsquo;t go live without it.</p>
                                    <p><LlamaButton onClick={() => { return history.push('/settings/payment'); }}>Connect PayPal</LlamaButton></p>
                                </>
                            )
                        }
                    </li>
                    <li className="logo" data-complete={logoComplete}>
                        <p className="heading">Upload your logo</p>
                        {!logoComplete
                            && (
                                <>
                                    <p className="description">Ambassadors are more trusting of brands that have a logo or other custom image beside their name.</p>
                                    <p><LlamaButton onClick={() => { return history.push('/settings/logo'); }}>Upload Logo</LlamaButton></p>
                                </>
                            )
                        }
                    </li>
                    <li className="offer" data-complete={offersComplete}>
                        <p className="heading">Create your first offer</p>
                        {!offersComplete
                            && (
                                <>
                                    <p className="description">Give your future ambassadors something to promote. Select your products, set a commission structure, and define the target audience.</p>
                                    <p><LlamaButton onClick={() => { return history.push('/offer-new'); }}>Create Offer</LlamaButton></p>
                                </>
                            )
                        }
                    </li>
                    <li className="page" data-complete={pageComplete}>
                        <p className="heading">Customize your ambassador sign up page</p>
                        {!pageComplete
                            && (
                                <>
                                    <p className="description">Advertise your offer outside of Llama to maximize your reach with a custom sign up page. Upload a banner, customize your page link, and share anywhere.</p>
                                    <p><LlamaButton onClick={() => { return history.push('/invite'); }}>Customize Page</LlamaButton></p>
                                </>
                            )
                        }
                    </li>
                    <li className="ambassadors" data-complete={ambassadorsComplete}>
                        <p className="heading">Invite some ambassadors to promote your offer</p>
                        {!ambassadorsComplete
                            && (
                                <>
                                    <p className="description">Get ahead of the game. Search our network to find the ambassadors that best fit your goals or import your current ambassadors.</p>
                                    <p><LlamaButton onClick={() => { return history.push('/discover'); }}>Find Ambassadors</LlamaButton></p>
                                </>
                            )
                        }
                    </li>
                </ul>
            </div>
        );
    }
    return null;
};

NewUserProgress.propTypes = {
    paypalComplete: PropTypes.bool.isRequired,
    offersComplete: PropTypes.bool.isRequired,
    logoComplete: PropTypes.bool.isRequired,
    pageComplete: PropTypes.bool.isRequired,
    ambassadorsComplete: PropTypes.bool.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired
};

// the React.memo wrap prevents unnecessary rerenders
export default React.memo(NewUserProgress);
