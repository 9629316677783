import React, { Component } from 'react';
import { 
    Card,
    Heading,
    Select,
    Autocomplete,
    Tag,
    Icon,
    ChoiceList
} from '@shopify/polaris';

import {
    SearchMinor
  } from '@shopify/polaris-icons';

import './campaign-target.css';


// Creates array with values 18 - 65+.
const options = [...Array(48).keys()].map((value) => {
    const age = value + 18;
    if (value === 47) {
        return { value: age, label: '65+' };
    }
    return { value: age, label: age };
});


/**
 * Alters the maximum age in the target object.
 * 
 * @param {Object} currentTarget previous value of target
 * @param {Function} setFieldValue sets new value in form
 */
const changeMaxAge = (currentTarget, setFieldValue) => (value) => {
    value = parseInt(value);
    if (currentTarget.age_low > value) {
        let newTarget = { ...currentTarget, age_low: value, age_high: value };
        setFieldValue('target', newTarget);
        return;
    }

    let newTarget = { ...currentTarget, age_high: value };
    setFieldValue('target', newTarget);
}


/**
 * Alters the minimum age in the target object.
 * 
 * @param {Object} currentTarget previous value of target
 * @param {Function} setFieldValue sets new value in form
 */
const changeMinAge = (currentTarget, setFieldValue) => (value) => {
    value = parseInt(value);
    if (currentTarget.age_high < value) {
        let newTarget = { ...currentTarget, age_low: value, age_high: value };
        setFieldValue('target', newTarget);
        return;
    }

    let newTarget = { ...currentTarget, age_low: value };
    setFieldValue('target', newTarget);
}


/**
 * @class CampaignTarget
 * Holds the state of countryInput and countryOptions.
 * Renders Polaris Card to display a form that allows
 * user to select target demographic data for their campaign.
 * 
 * @returns {ComponentClass}
 */
class CampaignTarget extends Component {

    constructor(props) {
        super(props);

        this.state = {
            countryInput: '',
            countryOptions: this.props.countries
        }
    }

    updateCountryText = (newValue) => {
        this.setState({ countryInput: newValue })
        this.filterCountries(newValue);
    }

    filterCountries = (input) => {
        if (input === '') {
            this.setState({ countryOptions: this.props.countries });
            return;
        }

        const filterRegex = new RegExp(input, 'i');
        const result = this.props.countries.filter((option) => {
            return option.label.match(filterRegex) || option.value.match(filterRegex);
        })

        this.setState({ countryOptions: result })
    }

    renderTags = (countries, removeTag) => {
        // console.log("CAMPAIGN-TARGET", "COUNTRIES:", countries);

        const alphabeticallyDesc = (a, b) => {
            if (a < b) return -1;
            if (a > b) return 1;
            return 0;
        }

        const findInDefaultOptions = (country) => {
            let match = this.props.countries.find((item) => item.value === country);
            if (!match) return null;
            return match;
        }

        return countries
            .map(findInDefaultOptions)
            .sort(alphabeticallyDesc)
            .map((country, index) => {
                return country && <li key={index}><Tag key={country.value} onRemove={() => removeTag(country.value)}>{country.label}</Tag></li>
            })
    }

    render() {
        const { values, setFieldValue } = this.props;
        const { target } = values;

        if (!target) {
            setFieldValue('target', {
                gender: 'ALL',
                age_low: 18,
                age_high: 65,
                interests: [],
                countries: ['US']
            });
            return null;
        }

        const changeTargetGender = (value) => {
            setFieldValue('target', {
                ...values.target,
                gender: value[0]
            });
        };

        const changeTargetCountries = (value) => {
            setFieldValue('target', {
                ...values.target,
                countries: value
            });
        };
        const changeMax = changeMaxAge(target, setFieldValue);
        const changeMin = changeMinAge(target, setFieldValue);

        const { gender, age_low, age_high, countries, interests } = target;

        const removeTag = (tag) => {
            let newSelected = countries;
            newSelected.splice(newSelected.indexOf(tag), 1);
            changeTargetCountries(newSelected);
        };

        console.log("CAMPAIGN-TARGET:", "COUNTRY VALUES", this.props.values.target.countries)

        return (
            <Card >
                <Card.Section>
                    <div className="campaign-target__heading">
                        <Heading>TARGET AUDIENCE</Heading>
                        {/* <TextStyle variation="subdued"><span className="subtitle">Inform affiliates what the suggested audience is.</span></TextStyle> */}
                    </div>
                </Card.Section>
                <Card.Section>
                    <ul className="campaign-target__selection">
                        <li>
                            <span className="field-title">Countries</span>
                            {this.props.values.target.countries.length > 0 && <ul className="campaign-target__country-tags">
                                {this.renderTags(countries, removeTag)}
                            </ul>}
                            <Autocomplete
                                allowMultiple
                                options={this.state.countryOptions}
                                selected={countries}
                                onSelect={changeTargetCountries}
                                textField={
                                    <Autocomplete.TextField
                                        multi
                                        value={this.state.countryInput}
                                        onChange={this.updateCountryText}
                                        prefix={<Icon source={SearchMinor} color="inkLighter" />}
                                        placeholder="Canada, Germany, France, ..."
                                    />
                                }
                            />
                        </li>
                        <li className="genders">
                            <ChoiceList
                                title="Gender"
                                choices={[
                                    { value: 'ALL', label: 'All' },
                                    { value: 'MALE', label: 'Male' },
                                    { value: 'FEMALE', label: 'Female' }
                                ]}
                                selected={[gender]}
                                onChange={changeTargetGender}
                            />
                        </li>
                        <li>
                            <span className="field-title">Age Range</span>
                            <div className="ranges">
                                <Select
                                    options={options}
                                    value={age_low}
                                    onChange={changeMin}
                                />
                                <p className="to">to</p>
                                <Select
                                    options={options}
                                    value={age_high}
                                    onChange={changeMax}
                                />
                            </div>
                        </li>
                    </ul>
                </Card.Section>
            </Card>
        );
    }


}

export default CampaignTarget;