import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router } from 'react-router-dom';
import { connect } from 'react-redux';
import { Page } from '@shopify/polaris';

import { ChatModule } from 'llama-library/components';

import { reconnectIfClosed } from '../actions/websocket';

import getChatAffiliates from '../actions/chat/action_getChatAffiliates';
import getChatMessages, { markChatMessagesAsRead } from '../actions/chat/action_chatMessages';
import getChatAffiliateInfo from '../actions/chat/action_getChatAffiliateInfo';

import './styles/chat-overrides.css';

const Chat = ({ openSocket, advertiser, history, unreadChatMessages, dispatchMarkChatMessagesAsRead, dispatchReconnectIfClosed, match }) => {
    return (
        <Page
            title="Chat with Ambassadors"
            fullWidth
        >
            <Router>
                <ChatModule
                    senderType="advertiser"
                    sender={{
                        id: advertiser?.advertiser_id,
                        dbId: advertiser?.advertiser_id,
                        photo: advertiser?.avatar_image
                    }}
                    unreadChatMessages={unreadChatMessages}
                    urlRecipient={match?.params?.affiliate_id}
                    history={history}
                    recipientType="affiliate"
                    graphQLActions={{
                        getChatRecipients: {
                            action: getChatAffiliates,
                            dataObjectName: 'getChatAffiliates'
                        },
                        getChatMessages,
                        dispatchMarkChatMessagesAsRead,
                        getChatRecipientInfo: {
                            action: getChatAffiliateInfo,
                            dataObjectName: 'getChatAffiliateInfo'
                        }
                    }}
                    openSocket={openSocket}
                    reconnectIfClosed={dispatchReconnectIfClosed}
                />
            </Router>
        </Page>
    );
};

Chat.propTypes = {
    unreadChatMessages: PropTypes.arrayOf(PropTypes.shape({
        sender_id: PropTypes.string,
        message_ids: PropTypes.arrayOf(PropTypes.string)
    })).isRequired,
    openSocket: PropTypes.shape({
        addEventListener: PropTypes.func.isRequired,
        send: PropTypes.func.isRequired
    }),
    advertiser: PropTypes.shape({
        advertiser_id: PropTypes.string.isRequired,
        avatar_image: PropTypes.string
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired,
    dispatchMarkChatMessagesAsRead: PropTypes.func.isRequired,
    dispatchReconnectIfClosed: PropTypes.func.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            affiliate_id: PropTypes.string.isRequired
        }).isRequired
    })
};

Chat.defaultProps = {
    openSocket: null,
    match: null
};

const mapStateToProps = (state) => {
    return {
        openSocket: state.app.openSocket,
        advertiser: state.advertiser,
        unreadChatMessages: state.chat.unreadChatMessages
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchMarkChatMessagesAsRead: (messageIds) => { return dispatch(markChatMessagesAsRead(messageIds)); },
        dispatchReconnectIfClosed: (socket, advertiser_id) => { return dispatch(reconnectIfClosed(socket, advertiser_id)); }

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Chat);
