import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { TextField, Select, Label, Toast, Frame, Modal, Spinner, Checkbox } from '@shopify/polaris';
import { withFormik } from 'formik';
import { ChromePicker as ColorPicker } from 'react-color';
import { MerchantInviteCard, LlamaButton } from 'llama-library/components';
import { saveImage } from '../llama/image-drop';
import InviteSetupSideDrawer from './invite-setup-side-drawer';
import './invite-setup.css';

import { useToggle } from '../../hooks/use-toggle';

/**
 * Agnostic helper function that reduces the values object to just the fields we need to save to the database
 * @param {Object} values Formik values object
 */
const mapUpdatableFields = (values) => {
    const updatableValues = [
        'body',
        'slug',
        'offer_id',
        'display_offer_data',
        'banner_image',
        'brand_color',
        'shopify_promo'
    ];

    return Object.entries(values).reduce((acc, [key, value]) => {
        if (!updatableValues.includes(key)) {
            return acc;
        }

        acc[key] = value;
        return acc;
    }, {});
};

/**
 * @function InviteSignup
 * Accepts props Object, returns InviteSignup Component.
 * Wraps the InviteSetupSideDrawer Component and displays the Offer Banner
 * (which the merchant can edit or upload) and a text field where
 * they can enter some information about their brand.
 * Displays a preview div that contains a preview url and wraps the
 * MerchantInviteCard Component. Contains the renderOfferSelect Function.
 *
 * @param {Object} props
 *
 * @returns {FunctionComponent}
 */
const InviteSignup = (props) => {
    const [showSideDrawer, setShowSideDrawer] = useState(null);
    const [toastVerbiage, setToastVerbiage] = useState(null);
    const [uploading, setUploading] = useState(false);
    const {
        offers = [],
        values, errors,
        setFieldValue,
        setErrors,
        advertiserData = {},
        promotionsData,
        promoQuery,
        setPromoQuery,
        handleSubmit,
        isSubmitting,
        invitePageUrl
    } = props;
    const [showPreviewModal, setShowPreviewModal] = useToggle(false);
    const [showCustomerAmbassadorModal, setShowCustomerAmbassadorModal] = useToggle(false);

    const [call, setCall] = useState(null);
    const [slugWait, setSlugWait] = useState('');
    const [slugAvailability, setSlugAvailability] = useState(null);
    const [promo, setPromo] = useState('');
    const [showColorPicker, setShowColorPicker] = useState(false);

    // fallback for browsers without e.path
    const buildNodePath = (element, pathArray) => {
        pathArray.push({
            id: element.id,
            className: element.className
        });
        if (element.parentElement) {
            return buildNodePath(element.parentElement, pathArray);
        }
        return pathArray;
    };

    // close color picker by clicking outside of it
    const handleClickOutside = (e) => {
        let nodePath = [];
        if (e.path) {
            nodePath = e.path;
        } else {
            nodePath = buildNodePath(e.target, nodePath);
        }

        const colorPickerClicked = nodePath.find((node) => {
            const elementClass = (node.className && typeof (node.className) === 'string') ? node.className.trim() : '';
            return elementClass === 'Polaris-TextField__Input'
                || elementClass === 'hue-horizontal'
                || elementClass === 'chrome-picker';
        });

        if (!colorPickerClicked) {
            setShowColorPicker(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const onChange = (value, name) => {
        setFieldValue(name, value);
    };

    const onColorChange = (value) => {
        setFieldValue('brand_color', value.hex);
    };

    // Handle the offer change seprately to remove the errors
    const handleOfferChange = (value) => {
        setFieldValue('offer_id', value);

        // Remove the offers validation on valid selection
        if (value.length > 0) {
            delete errors.offer_id;
            setErrors(errors);
        }
    };

    // https://stackoverflow.com/a/56474891
    const makeCancelable = (promise) => {
        let rejectFn;

        const wrappedPromise = new Promise((resolve, reject) => {
            rejectFn = reject;

            Promise.resolve(promise)
                .then(resolve)
                .catch(reject);
        });

        wrappedPromise.cancel = () => {
            rejectFn({ canceled: true });
        };

        return wrappedPromise;
    };

    const handleSlugChange = (value) => {
        value = value.replace(/[^\w-]+/g, '');
        setFieldValue('slug', value);
        console.log('slugg value', value);

        // if the user presses too slowly and a database promise was attempted, cancel it
        if (call != null) {
            call.cancel();
        }
        if (slugWait !== '') {
            clearTimeout(slugWait);
        }

        // give the user half a second to finish typing before checking the database for availability
        setSlugWait(setTimeout(() => {
            setSlugWait('');
            setSlugAvailability('loading');

            if (value) {
                setCall(makeCancelable(
                    props.checkBrandedSlug(props.advertiser_id, value)
                        .then((result) => {
                            const availability = result.value.data.checkBrandedSlug.is_available;
                            setSlugAvailability(availability);
                            setFieldValue('availability', availability);
                            if (availability) {
                                delete errors.slug;
                                setErrors(errors);
                            }
                            clearTimeout(slugWait);
                        })
                ));
            } else {
                setSlugAvailability(null);
                setFieldValue('availability', null);
                delete errors.slug;
                setErrors(errors);
            }
        }, 500));
    };

    const handlePromotionChange = (value) => {
        if (value !== '') {
            setPromoQuery(`&promo=${value}`);
        } else {
            setPromoQuery('');
        }
        setPromo(value);
    };

    let inviteOffer;

    // If they don't have an offer selected set invite offer to first offer in offer list
    if (values && values.offer_id) {
        inviteOffer = offers.find((offer) => {
            return offer.offer_id === values.offer_id;
        });
    } else {
        inviteOffer = offers[0];
    }

    const data = {
        branded_sign_up: { ...values, offer: inviteOffer },
        ...advertiserData
    };

    const offerSelections = offers.map((offer) => {
        return {
            label: offer.name,
            value: offer.offer_id
        };
    });

    const saveFile = (file) => {
        return saveImage(file, props.advertiser_id, 'invite-signup', setUploading)
            .then((result) => {
                values.banner_image = result.url;
                const branded_sign_up = mapUpdatableFields(values);
                props.updateBrandedSignup(props.advertiser_id, { branded_sign_up });
                setFieldValue('banner_image', result.url);
                setShowSideDrawer(null);
            });
    };

    const navigateToOffers = () => {
        props.history.push('/offer-new');
    };

    /**
     * @function renderOfferSelect
     * Returns a Polaris Select Component for the offerSelections
     * Array. Returns a React.Fragment with a message that the
     * merchant doesn't have any offers and gives a button to
     * navigateToOffers to create a new Offer.
     *
     * @returns {FunctionComponent}
     */
    const renderOfferSelect = () => {
        if (offerSelections.length >= 1) {
            // Add the default value in offerSelections
            offerSelections.unshift({
                label: 'Select Offer',
                value: ''
            });

            return (
                <Select
                    label="Featured Offer"
                    options={offerSelections}
                    onChange={handleOfferChange}
                    value={values.offer_id}
                    error={errors.offer_id}
                    id="offer_id"
                    data-test="invite-offers"
                />
            );
        }
        return (
            <>
                <span className="InviteSetup__OffersNullState"> Offers </span>
                <div className="InviteSetup__CreateOfferContainer">
                    <span> Looks like you do not have any offers yet!</span>
                    <LlamaButton onClick={navigateToOffers}> Create Offer </LlamaButton>
                </div>

            </>
        );
    };

    const renderPromotionsSelect = () => {
        let promotions = [];
        if (promotionsData && promotionsData.length > 0) {
            promotions = promotionsData
                .filter((promotion) => { return promotion.is_active })
                .map((promotion) => {
                    return {
                        label: promotion.code,
                        value: promotion.code
                    };
                });
        }

        if (promotions.length >= 1) {
            // Add the default value in promotions
            promotions.unshift({
                label: 'Select Promotion',
                value: ''
            });

            return (
                <Select
                    label="Promotions"
                    options={promotions}
                    onChange={handlePromotionChange}
                    value={promo}
                />
            );
        }
        return (
            <>
                <span className="InviteSetup__OffersNullState"> Offers </span>
                <div className="InviteSetup__CreateOfferContainer">
                    <span> Looks like you do not have any offers yet!</span>
                    <LlamaButton onClick={navigateToOffers}> Create Offer </LlamaButton>
                </div>

            </>
        );
    };

    const toastMarkup = toastVerbiage
        ? <Toast content={toastVerbiage} onDismiss={() => { return setToastVerbiage(null); }} />
        : null;

    return (
        <>
            <Modal
                open={showCustomerAmbassadorModal}
                onClose={setShowCustomerAmbassadorModal}
                title="Turn your paying customers into ambassadors"
                primaryAction={{
                    content: 'Yes, turn this on',
                    onAction: () => {
                        setFieldValue('shopify_promo', true);
                        setShowCustomerAmbassadorModal();
                    }
                }}
                secondaryActions={[{
                    content: 'No thanks',
                    onAction: () => {
                        setFieldValue('shopify_promo', false);
                        setShowCustomerAmbassadorModal();
                    }
                }]}
            >
                <Modal.Section>
                    <video src="https://s3.amazonaws.com/llama.creatives/invite-signup/order-confirmation-content-box-placement.mp4" autoPlay muted loop className="InviteSetup__recruit-video" />
                    <p>The most successful ambassadors are the ones who know your brand so well that referring others is a no brainer. Often times, these people are your current customers. With Llama, you can <strong>let your customers join your ambassador program as soon as they check out.</strong></p>

                    <p>When you turn on this setting, <strong>we&rsquo;ll add a content box to your order confirmation page</strong> that not only includes a <strong>link to your invite page</strong>, but also features your <strong>program banner, commission rates, and LTV look forward period</strong> to entice your customers to become your ambassadors.</p>
                </Modal.Section>
            </Modal>

            <div>
                <Modal
                    open={showPreviewModal}
                    onClose={setShowPreviewModal}
                    title="Preview"
                >
                    <Modal.Section>
                        <div className="InviteSetup__Preview--Mobile">
                            <div className="InviteSetup__PreviewSection InviteSetup__PreviewURL">
                                <h3 className="InviteSetup__ModalLabel">Invite Page Link:</h3>
                                <a href={`${invitePageUrl}${values.currentSlug}`} className="InviteSetup__PreviewURL" target="_blank" rel="noopener noreferrer">
                                    {invitePageUrl + values.slug}
                                </a>
                            </div>
                            <div className="InviteSetup__MerchantCardWrapper">
                                <div className="backdrop" style={{ background: values.brand_color }} />
                                <MerchantInviteCard data={data} />
                            </div>
                        </div>
                    </Modal.Section>
                </Modal>
            </div>
            <div data-test="component-invite-signup" className="InviteSetup">
                <form className="InviteSetup__Form" onSubmit={handleSubmit} data-test="invite-form">
                    <fieldset>
                        <legend className="InviteSetup__Heading">Link Settings</legend>
                        <InviteSetupSideDrawer
                            saveFile={saveFile}
                            advertiser={advertiserData}
                            setShowSideDrawer={setShowSideDrawer}
                            showSideDrawer={showSideDrawer !== null}
                            uploading={uploading}
                        />
                        <div className="InviteSetup__InputSection slug">
                            <TextField
                                label="Custom Invite Page Link"
                                value={values.slug}
                                error={errors.slug}
                                prefix={invitePageUrl}
                                onChange={handleSlugChange}
                                id="slug"
                            />
                            {slugAvailability !== null && slugAvailability !== 'loading'
                                && (
                                    <span className="availability" data-is-available={slugAvailability}>
                                        {slugAvailability ? 'Available' : 'Taken'}
                                    </span>
                                )
                            }
                            {slugAvailability === 'loading'
                                && (
                                    <span className="availability-loading">
                                        <Spinner size="small" />
                                        Checking availability...
                                    </span>
                                )
                            }
                        </div>
                        <div className="InviteSetup__InputSection shopify-promo">
                            <div className="recruit-cta">
                                <p className="heading">Turn your paying customers into ambassadors</p>
                                <p>Grow your ambassador network even faster by recruiting your own customers. <strong>Check the box below</strong> and we&rsquo;ll give them a link to your Llama invite page after they check out.
                                    <LlamaButton type="button" onClick={setShowCustomerAmbassadorModal}>Learn more</LlamaButton>
                                </p>
                                <Checkbox
                                    label="Let customers join from my order confirmation page"
                                    checked={values.shopify_promo}
                                    onChange={onChange}
                                    id="shopify_promo"
                                />
                            </div>
                        </div>
                    </fieldset>
                    <fieldset>
                        <legend className="InviteSetup__Heading">Branding</legend>
                        <div className="InviteSetup__InputSection InviteSetup__ImageUpload">
                            <div>
                                <span>Program Banner</span>
                                {values.banner_image
                                    ? <img src={values.banner_image} height="50" width="150" alt="offer banner" />
                                    : 'No banner uploaded'
                                }
                            </div>
                            <button type="button" className="settings-general__edit-text" onClick={() => { return setShowSideDrawer(true); }}>
                                {values.banner_image ? 'Edit' : 'Upload'}
                            </button>
                        </div>

                        <div className="InviteSetup__InputSection InviteSetup__BrandColor">
                            <div>
                                <span className="color-swatch" style={{ backgroundColor: values.brand_color }} />
                                <TextField
                                    label="Brand Color"
                                    id="brand_color"
                                    value={values.brand_color}
                                    pattern="#[abcdef0-9]{0,6}"
                                    onFocus={() => { return setShowColorPicker(true); }}
                                    // onChange={(value) => { return handleColorChange(value); }}
                                    onChange={onChange}
                                    helpText={<>We&rsquo;ll apply this color to your logo background (if it&rsquo;s transparent), the curved backdrop at the top of the page, and the wave shapes at the bottom.</>}
                                    error={errors.brand_color}
                                />
                                {showColorPicker
                                    && (
                                        <ColorPicker
                                            color={values.brand_color}
                                            disableAlpha
                                            onChange={onColorChange}
                                        />
                                    )
                                }
                            </div>
                        </div>
                    </fieldset>

                    <fieldset>
                        <legend className="InviteSetup__Heading">Offer Details</legend>
                        <div className="InviteSetup__InputSection">
                            {renderOfferSelect()}
                        </div>
                        <div className="InviteSetup__InputSection">
                            <TextField
                                label="Program Description"
                                onChange={onChange}
                                value={values.body}
                                multiline={3}
                                id="body"
                                placeholder="Include some information about your store and featured offer"
                                helpText={<>Tell your prospective ambassadors what they&rsquo;ll get by signing up for your ambassador program. This may include commission amounts, in-demand products, payment terms, etc.</>}
                            />
                        </div>
                    </fieldset>
                    <div className="InviteSetup__InputSection">
                        <div className="InviteSetup__InputSectionRow">
                            <LlamaButton
                                type="submit"
                                loading={isSubmitting}
                                disabled={slugAvailability === 'loading'}
                                data-test="invite-submit"
                            >
                                Save
                            </LlamaButton>
                            <div className="InviteSetup__PreviewButton">
                                <LlamaButton
                                    type="button"
                                    inverted
                                    background="#FD2856"
                                    onClick={setShowPreviewModal}
                                >
                                    Preview
                                </LlamaButton>
                            </div>
                        </div>
                    </div>
                </form>
                <div className="InviteSetup__Preview">
                    <div className="InviteSetup__Heading">PREVIEW</div>
                    <div className="InviteSetup__PreviewSection InviteSetup__PreviewURL">
                        <Label>Invite Page Link:</Label>
                        <a href={`${invitePageUrl}${values.currentSlug}`} className="InviteSetup__PreviewURL" target="_blank" rel="noopener noreferrer">
                            {invitePageUrl + values.slug + promoQuery}
                        </a>
                    </div>
                    <div className="InviteSetup__MerchantCardWrapper">
                        <div className="backdrop" style={{ background: values.brand_color }} />
                        <MerchantInviteCard data={data} onEditBanner={() => { return setShowSideDrawer(true); }} />
                    </div>
                </div>
            </div>
            <Frame>{toastMarkup}</Frame>
        </>
    );
};

InviteSignup.propTypes = {
    offers: PropTypes.arrayOf(PropTypes.shape({
        offer_id: PropTypes.string,
        name: PropTypes.string
    })).isRequired,
    values: PropTypes.shape({
        offer_id: PropTypes.string,
        banner_image: PropTypes.string,
        currentSlug: PropTypes.string,
        slug: PropTypes.string,
        body: PropTypes.string,
        brand_color: PropTypes.string,
        shopify_promo: PropTypes.bool
    }).isRequired,
    errors: PropTypes.shape({
        slug: PropTypes.string,
        offer_id: PropTypes.string,
        brand_color: PropTypes.string
    }).isRequired,
    setFieldValue: PropTypes.func.isRequired,
    setErrors: PropTypes.func.isRequired,
    advertiserData: PropTypes.shape({
        tracking: PropTypes.shape({
            Stat: PropTypes.shape({
                clicks: PropTypes.number,
                conversions: PropTypes.number
            })
        }),
        longterm_rate: PropTypes.shape({
            ltv: PropTypes.number,
            avg_cart_value: PropTypes.number
        }),
        rate: PropTypes.shape({
            type: PropTypes.string,
            amount: PropTypes.number
        }),
        long_term_rate: PropTypes.shape({
            type: PropTypes.string,
            amount: PropTypes.number
        }),
        products: PropTypes.shape({
            includeAll: PropTypes.bool,
            includeSome: PropTypes.arrayOf(PropTypes.any),
            excludeSome: PropTypes.arrayOf(PropTypes.any)
        })
    }).isRequired,
    handleSubmit: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    checkBrandedSlug: PropTypes.func.isRequired,
    advertiser_id: PropTypes.string.isRequired,
    updateBrandedSignup: PropTypes.func.isRequired,
    history: PropTypes.shape({ push: PropTypes.func }).isRequired,
    invitePageUrl: PropTypes.string.isRequired,
    promoQuery: PropTypes.string.isRequired,
    setPromoQuery: PropTypes.func.isRequired
};

export default withFormik({
    mapPropsToValues: (props) => {
        if (!props.branded_sign_up) {
            // TODO: Set up default values here.
            return { slug: 'test' };
        }

        const formValues = mapUpdatableFields(props.branded_sign_up);
        formValues.currentSlug = formValues.slug;
        if (!formValues.brand_color) {
            formValues.brand_color = '#421875';
        }

        return formValues;
    },
    handleSubmit: (values, { setSubmitting, setErrors, setFieldValue, props }) => {
        setSubmitting(true);

        const errors = {};
        if (!values.slug) {
            errors.slug = 'Please enter an Invite Page Link';
        } else if (values.availability !== undefined && !values.availability) {
            errors.slug = 'Please enter an Invite Page Link that hasn’t been taken';
        }

        if (!values.offer_id || values.offer_id.length === 0) {
            errors.offer_id = 'Please select an offer';
        }

        if (values.brand_color && !/^#([abcdef0-9]{3}$)|([abcdef0-9]{6}$)/.test(values.brand_color)) {
            errors.brand_color = 'Please enter a valid hex color';
        }

        if (Object.values(errors).length > 0) {
            setErrors(errors);
            setSubmitting(false);
        } else {
            const branded_sign_up = mapUpdatableFields(values);
            props.updateBrandedSignup(props.advertiser_id, { branded_sign_up })
                .then(() => {
                    setSubmitting(false);
                    setFieldValue('currentSlug', branded_sign_up.slug);
                    props.setToastVerbiage('Saved');
                });
        }
    }
})(InviteSignup);
