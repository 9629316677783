import { 
    CREATE_OFFER_FULFILLED, 
    CREATE_OFFER_REJECTED,
    EDIT_OFFER, 
    UPDATE_OFFER_FULFILLED } from '../actions/create-offer';
import { GET_OFFER_FULFILLED } from '../actions/offers';
import { RateTypes } from '../utils/types';

export const defaultState = {
    active: false,
    advertiser_id: null,
    description: "",
    draft: true,
    errors: [],
    tags: [],
    name: "New Campaign",
    id: null,
    offer_url: "",
    preview_url: "",
    rate: {
        type: RateTypes.PERCENT_REVENUE,
        amount: 10
    },
    cookie_days: 30,
    products: {
        includeAll: true,
        includeSome: false,
        excludeSome: false,
        includeProducts: [],
        excludeProducts: []
    },
    category: '',
    creatives: []
};

const createOfferReducer = (state = defaultState, action) => {

    const { type } = action;

    switch (type) {

        case UPDATE_OFFER_FULFILLED: {
            const { payload } = action;

            if (payload.errors) {
                return state
            };
            if (!payload.data || !payload.data.updateOffer) return state;

            const data = payload.data.updateOffer;

            return { ...state, ...data }

        }

        case EDIT_OFFER: {
            const { payload } = action;

            // If value on field is null or undefiend, have it equal an
            // empty string.
            Object.entries(payload).forEach(([key, value]) => {
                if (!value) {
                    payload[key] = '';
                }
            })

            return payload;
        }

        case CREATE_OFFER_FULFILLED: {
            if (!action.payload.createOffer) {
                return { ...state, errors: state.errors.concat('An error has occured. Please try again.') }
            };

            const { status } = action.payload.createOffer;

            // if (status !== "SUCCESS" || errorMessage !== null || errors.length > 0) {
                // TODO: Parse this correctly.
                // return { ...state, error: errorMessage }
            // }

            // if (!data.hasOwnProperty('Offer')) {
            //     return { ...state, errorMessage: "No data returned for Offer.", creating: false }
            // }

            // const { id } = data;
            return { ...state, active: true, draft: false, stuff: "TEST", errors: [] } 
        }

        case CREATE_OFFER_REJECTED: {
            return { ...state, error: "An error has occured. Please try again." }
        }

        case GET_OFFER_FULFILLED: {
            if (!action || !action.payload || !action.payload.data || !Array.isArray(action.payload.data.advertisers)) {
                return state;
            }

            return action.payload;
        }

        default:
            return state;

    }

}

export default createOfferReducer;