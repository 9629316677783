import React, { useState } from 'react';

import LlamaDatePicker from '../llama/date-picker';
import LlamaSelect from '../llama/select';
import TransactionReports, { ReportColumns } from '../transaction-report/transaction-report';
import useTransactionReport from '../../hooks/use-transaction-report';
import './offer-reports.css';

/**
 * @function OfferReports
 * Accepts the following params, returns OfferReports Component.
 * Holds the LLamaSelect and LlamaDatePicker Components, which
 * give the user OfferReport filtering abilities.
 * Holds the TransactionReports Component.
 * 
 * @param {Object} offer - default param is empty Object
 * @param {Object} advertiser
 * @param {Object} history
 * @param {Array} applications - default param is empty array
 * @param {Object} props
 * 
 * @returns {FunctionComponent}
 */
const OfferReports = ({ offer = {}, advertiser, history, applications = [], ...props }) => {

    const now = new Date();
    now.setHours(12, 0, 0);
    const startDate = new Date('2019-01-01T12:00:00');

    const [filters, setFilters] = useState({
        startDate: startDate.toISOString(),
        endDate: now.toISOString(),
        offer_id: offer.offer_id,
        affiliate_id: null,
    })

    const handleDateChange = (newDateRange) => {
        const { startDate, endDate } = newDateRange
        setFilters((state) => {
            return {
                ...state,
                startDate: startDate.toISOString(),
                endDate: endDate.toISOString(),
            }
        });
    }

    const handleAffiliateChange = (affiliate_id) => {

        if (affiliate_id === '') {
            affiliate_id = null;
        } 

        setFilters((state) => {
            return {
                ...state,
                affiliate_id
            }
        })
    }

    const affiliates = Array.isArray(applications) 
        ? applications.map(({ affiliate }) => {
                if (!affiliate || !affiliate.name || !affiliate.tracking_id) {
                    return null;
                }

                return {
                    label: affiliate.name,
                    value: affiliate.tracking_id,
                }
            })
            .filter((item) => item !== null)
        : [];
    affiliates.unshift({ label: "All Ambassadors", value: '' });

    const {
        pageInfo,
        handleOnNext,
        handleOnPervious,
        orders,
        isLoading,
        errorMessage,
    } = useTransactionReport(advertiser.advertiser_id, filters);

    if (!offer || !offer.offer_id) {
        return null;
    }

    const reportColumns = [
        ReportColumns.ORDER_NUMBER,
        ReportColumns.DATE,
        ReportColumns.AFFILIATE,
        ReportColumns.CUSTOMER,
        ReportColumns.COMMISSION,
        ReportColumns.STATUS,
        ReportColumns.SUBTOTAL,
        ReportColumns.LOOK_FORWARD,
    ]

    return (
        <section className="OfferReports">

            {/* FILTERS */}
            <div className="OfferReports__Filters">
                <LlamaSelect
                    options={affiliates}
                    onChange={handleAffiliateChange}
                    value={filters.affiliate_id ? filters.affiliate_id : ''}
                    disabled={isLoading}
                />
                <LlamaDatePicker 
                    onDateChange={handleDateChange}
                    disabled={isLoading}
                />
            </div>

            <TransactionReports
                orders={orders}
                isLoading={isLoading}
                errorMessage={errorMessage}
                history={history}
                pageInfo={pageInfo}
                onNext={handleOnNext}
                onPrevious={handleOnPervious}
                columns={reportColumns}
            />

        </section>
    )

}

export default OfferReports;