import React, { Component } from 'react';
import { connect } from 'react-redux';

import AffiliateDetail from '../components/affiliate-detail/affiliate-detail';
import { getAffiliate, getAffiliateCognito } from '../actions/get-affiliate';
import { getApplicationStatus } from '../actions/applications';
import { applyToAffiliate } from '../actions/apply-to-affiliate';

class AffiliateInfo extends Component {

    constructor(props) {
        super(props);

        // const affiliate_id = props.match && props.match.params && props.match.params.id
        //     ? props.match.params.id
        //     : null;

        this.state = {
            affiliate: {
                account: {},
                instagramData: [],
                twitterData: [],
                youTubeData: [],
                tikTokData: []
            },
            applications: [],
            cachedAdvertiserId: props.advertiser.advertiser_id,
            applicationStatus: null,
            loadingApplication: false,
            isLoading: false,
            selected: 0
        }

        this.submitApplication = this.submitApplication.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
        this.updateInvitedOffers = this.updateInvitedOffers.bind(this);
    }

    /**
     * @author Harshal Mahajan <Harshal.Mahajan@harbingergroup.com>
     * @function getDerivedStateFromProps
     * @description Check if new coming props have different advertiser_id compared to the previous state then update it with new one 
     * @param {Object} nextProps new props receving by component
     * @param {Object} prevState previous state of component
     * @returns {Object} null or updated state 
     */
    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.advertiser.advertiser_id && prevState && (nextProps.advertiser.advertiser_id !== prevState.cachedAdvertiserId)) {
            return {
                cachedAdvertiserId: nextProps.advertiser.advertiser_id
            };
        }
        return null;
    }

    handleTabChange = (selectedTabIndex) => {
        this.setState({ selected: selectedTabIndex });
    };

    /**
     * @author Harshal Mahajan <Harshal.Mahajan@harbingergroup.com>
     * @function updateInvitedOffers
     * @description Update the affiliate.invitedOffers without API call
     * @param {Array} invitedOffers Latest Offers for which Advertiser invited
     * @returns {Object} Updated invited offers with previous state
     */
    updateInvitedOffers = (invitedOffers) => {
        this.setState((prevState) => {
            return {
                ...prevState,
                affiliate: {
                    ...prevState.affiliate,
                    invitedOffers: prevState.affiliate.invitedOffers.concat(invitedOffers)
                }
            }
        })
    }

    submitApplication() {
        this.setState({ loadingApplication: true });
        applyToAffiliate(this.state.affiliate_id, this.props.advertiser.id)
            .then((result) => result.data)
            .then((result) => {
                if (result.applyToAffiliate && result.applyToAffiliate.status) {
                    this.setState({ applicationStatus: result.applyToAffiliate.status, loadingApplication: false });
                }
            })
            .catch((error) => {
                this.setState({ loadingApplication: false });
            });
    }

    componentDidMount() {

        this.setState({ isLoading: true });
        getAffiliateCognito(this.props.match && this.props.match.params && this.props.match.params.id)
            .then(response => response.data)
            .then((result) => {
                if (!result) {
                    return;
                }
                getAffiliate(result.affiliateByMongoId.cognito_id, this.state.cachedAdvertiserId)
                    .then(response => response.data)
                    .then((result) => {

                        const applications = result && result.advertiserById && result.advertiserById.applications
                            ? result.advertiserById.applications
                            : [];

                        this.setState({
                            affiliate: result.affiliateById,
                            applications,
                            isLoading: false,
                        })
                    });
            })

    }

    render() {
        return (
            <AffiliateDetail
                {...this.state}
                {...this.props}
                submitApplication={this.submitApplication}
                updateInvitedOffers={this.updateInvitedOffers}
            />
        )
    }

}

const mapStateToProps = (state) => {
    return {
        advertiser: state.advertiser
    }
}

export default connect(mapStateToProps)(AffiliateInfo);