import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, TextField, Checkbox, ChoiceList, Icon } from '@shopify/polaris';
import { SearchMinor } from '@shopify/polaris-icons';

import { numberFormat, moneyFormat } from 'llama-library/utils';

import './coupon-product-selections.css';

const CouponProductSelections = ({ products, setFieldValue, selectedProductValues }) => {
    const [productSelectionType, setProductSelectionType] = useState('all');
    const [initialProductRows, setInitialProductRows] = useState([]);
    const [productRows, setProductRows] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    const [modalOpen, setModalOpen] = useState(false);
    const [selectedProducts, setSelectedProducts] = useState([]);

    useEffect(() => {
        const formattedProducts = products.map((product) => {
            return {
                id: product.product_id,
                image: product.image.src,
                name: product.title,
                price: product.variants[0].price,
                quantity: product.variants.reduce((acc, variant) => {
                    return acc + (variant.inventory_quantity || 0);
                }, 0)
            };
        });

        setProductRows(formattedProducts);
        setInitialProductRows(formattedProducts);
    }, []);

    // reset after form submit
    useEffect(() => {
        if (!selectedProductValues && productSelectionType !== 'all') {
            setProductSelectionType('all');
            setSelectedProducts([]);
        }
    }, [selectedProductValues]);

    const handleSearchTermChange = (value) => {
        setSearchTerm(value);
        if (!value || value === '') {
            setProductRows(initialProductRows);
        } else {
            const filteredRows = initialProductRows.filter((product) => {
                return product.name.toLowerCase().search(value.toLowerCase()) !== -1;
            });
            setProductRows(filteredRows);
        }
    };

    useEffect(() => {
        if (modalOpen) {
            // remove outer scroll from modal
            setTimeout(() => {
                const modalContentWrap = document.querySelector('.coupon-modal-section-wrap').parentNode;

                modalContentWrap.classList.remove('Polaris-Scrollable--hasBottomShadow');
                modalContentWrap.classList.remove('Polaris-Scrollable');
                modalContentWrap.classList.remove('Polaris-Scrollable--verticle');
            }, 600);
        } else if (searchTerm !== '' && initialProductRows.length > 0) {
            // reset search query
            handleSearchTermChange('');
        }
    }, [modalOpen]);

    const handleProductSelectionTypeChange = ([value]) => {
        if (value === 'some') {
            setModalOpen(true);
        }
        setProductSelectionType(value);
    };

    const toggleAllProducts = (checked) => {
        if (checked) {
            setSelectedProducts(productRows.map((product) => {
                return product.id;
            }));
        } else {
            setSelectedProducts([]);
        }
    };

    const toggleProductSelection = (productId) => {
        const selectedProductsCopy = [...selectedProducts];
        if (selectedProducts.indexOf(productId) === -1) {
            selectedProductsCopy.push(productId);
        } else {
            selectedProductsCopy.splice(selectedProductsCopy.indexOf(productId), 1);
        }

        setSelectedProducts(selectedProductsCopy);
        setFieldValue('entitled_product_ids', selectedProductsCopy);
    };

    const closeModal = () => {
        if (selectedProducts.length === 0) {
            setProductSelectionType('all');
        }
        setModalOpen(false);
    };

    return (
        <>
            <ChoiceList
                title="Valid For"
                choices={[
                    { label: 'All Products', value: 'all' },
                    { label: 'Specific Products', value: 'some' }
                ]}
                selected={[productSelectionType]}
                onChange={handleProductSelectionTypeChange}
            />
            {productSelectionType === 'some'
                && (
                    <>
                        <p className="num-coupon-product-selections">
                            {selectedProducts.length} product{selectedProducts.length !== 1 && 's'} selected
                            <button className="plain-text" type="button" onClick={() => { return setModalOpen(true); }}>Change Selection</button>
                        </p>

                        <Modal
                            noScroll
                            open={modalOpen}
                            title="Select Products"
                            onClose={closeModal}
                            primaryAction={{
                                onAction: closeModal,
                                content: 'Select',
                                disabled: selectedProducts.length === 0
                            }}
                        >
                            <div className="coupon-modal-section-wrap">
                                <Modal.Section>
                                    <p>Which products should this coupon be valid for?</p>
                                    <TextField
                                        placeholder="Search products by name"
                                        prefix={<Icon source={SearchMinor} />}
                                        value={searchTerm}
                                        onChange={handleSearchTermChange}
                                    />
                                    <div className="coupon-products-list">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th className="checkbox">
                                                        <Checkbox
                                                            onChange={toggleAllProducts}
                                                            checked={productRows.length > 0 && selectedProducts.length === productRows.length}
                                                            label="Select All Products"
                                                        />
                                                    </th>
                                                    <th className="product">Product</th>
                                                    <th className="amount">Price</th>
                                                    <th className="amount">In Stock</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {productRows.map((product) => {
                                                    return (
                                                        <tr key={product.id}>
                                                            <td className="checkbox">
                                                                <Checkbox
                                                                    label={`Select ${product.name}`}
                                                                    onChange={() => { return toggleProductSelection(product.id); }}
                                                                    checked={selectedProducts.indexOf(product.id) !== -1}
                                                                />
                                                            </td>
                                                            <td className="product"><span><img src={product.image} alt={product.name} /> {product.name}</span></td>
                                                            <td className="amount">{moneyFormat(product.price)}</td>
                                                            <td className="amount">{numberFormat(product.quantity)}</td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </Modal.Section>
                            </div>
                        </Modal>
                    </>
                )
            }
        </>
    );
};

CouponProductSelections.propTypes = {
    products: PropTypes.arrayOf(PropTypes.shape({
        product_id: PropTypes.string,
        image: PropTypes.shape({
            src: PropTypes.string.isRequired
        }).isRequired,
        title: PropTypes.string.isRequired,
        variants: PropTypes.arrayOf(PropTypes.shape({
            price: PropTypes.string.isRequired,
            inventory_quantity: PropTypes.number.isRequired
        })).isRequired
    })).isRequired,
    setFieldValue: PropTypes.func.isRequired
};

export default CouponProductSelections;
