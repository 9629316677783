import React from 'react';
import moment from 'moment';
import './notifications-menu-items.css';
import { NotificationViewStatus } from '../../../utils/types';

import llamaIcon from '../../../assets/llama-icon.png';
import boostIcon from '../../../assets/offer-boost-icon.png';
import llamaPlaceholder from '../../../assets/llama-user-icon.png';

/**
 * @function NotificationsMenuItem
 * Accepts props Object, returns NotificationsMenuItem Component
 * for each notification (notification title, note and number of days
 * since it was posted). 
 * 
 * @param {Object} props 
 * 
 * @returns {FunctionComponent}
 */
const NotificationsMenuItem = (props) => {

    const { notification } = props;

    if (!notification) {
        return null;
    }

    const navigateToDestination = notification.destination_link
        ? () => { 
            props.handleClose()
            props.history.push(notification.destination_link) 
        }
        : () => null;

    const notificationClasses = ['NotificationMenuItem'];
    const indicatorClasses = ['NotificationMenuItem__Indicator']
    if (notification.view_status === NotificationViewStatus.UNSEEN) {
        notificationClasses.push('NotificationMenuItem--active');
        indicatorClasses.push('NotificationMenuItem__Indicator--active')
    }

    let timeAgo = null;
    if(notification.ttl && notification.ttl.start){
        timeAgo = new Date(notification.ttl.start).getTime();
        timeAgo = moment(timeAgo).fromNow()
    }
    const message = notification.message.replace(/<(?!\/?strong).+?>/g); //remove any tags that are not strong tags
    let image = notification.image.source;
    if (notification.image.type === 'llama') {
        image = llamaIcon;
    }
    if (notification.image.type === 'boost') {
        image = boostIcon;
    }
    if (!image && notification.image.type === 'affiliate') {
        image = llamaPlaceholder;
    }

    return (
        <li data-unread={notification.view_status === NotificationViewStatus.UNSEEN} data-clickable={!notification.destination_link ? false : true} onClick={navigateToDestination}>
            <span className="notification--image" data-icon={notification.image.icon}>
                <img src={image} alt=""/>
            </span>
            <span className="notification--message">
                <span dangerouslySetInnerHTML={{__html: message}} />
                <time dateTime={notification.ttl.start}>{timeAgo}</time>
            </span>
        </li>
    )
}
export default NotificationsMenuItem;
