/* import axios from 'axios';
import { NEXT_APP_API_URL } from '../config'; */
import {sendGraphQL} from './sendRequest'

/**
 * @enum AppSubscritionTypes
 * 
 * List of the different types of subscrition types offered by
 * Llama.app.  Key and value pairs should match.
 */
export const AppSubscriptionPlan = {
    TIER_1: 'TIER_1',
    TIER_2: 'TIER_2',
    TIER_3: 'TIER_3'
}

/**
 * @function updateAppSubscription
 * 
 * Set the subscrition type for an advertiser using Llama.app.
 * The option selected will determin the price point and features
 * offered by the app.
 * 
 * @param {Object} variables 
 *  {
 *      adveritiser_id: String!,
 *      plan: AppSubscriptionPlan!
 *  }
 */
const updateAppSubscription = (variables) => {

    // Validate input
    const { advertiser_id, plan } = variables;

    if (!advertiser_id) {
        return Promise.reject("No advertiser_id supplied to updateAppSubscrition.");
    }

    if (!AppSubscriptionPlan[plan]) {
        return Promise.reject("Invalid plan selected.");
    }

    const query = `
        mutation updateAppSubscription($advertiser_id: String!, $plan: AppSubscriptionPlan!) {
            updateAppSubscription(advertiser_id: $advertiser_id, plan: $plan) {
                status
                confirmation_url
            }
        }
    `

    const payload = {
        query,
        variables
    }

    return sendGraphQL(payload)
        .then(response => response.data)

    /* let headers = {
        "Content-Type": 'application/json'
    }

    return axios.post(NEXT_APP_API_URL, payload, { headers })
        .then(response => response.data) */

}

const getAppSubscription = (advertiser_id) => {

    if (!advertiser_id) {
        return Promise.reject("No advertiser_id supplied to getAppSubscription.");
    }

    const query = `
        query getAppSubscription($advertiser_id: String) {
            app {
                advertiserPlans {
                    name
                    key
                    price
                }
            }
            advertisers(advertiser_id: $advertiser_id) {
                charges(status: "active") {
                    name
                    price
                    status
                    trial_days
                    trial_ends_on
                    billing_on
                }
            }
        }
    `

    const variables = {
        advertiser_id
    }

    const payload = {
        query,
        variables
    }

    return sendGraphQL(payload)
        .then(response => response.data)

    /* const headers = {
        "Content-Type": 'application/json'
    }

    return axios.post(NEXT_APP_API_URL, payload, { headers })
        .then(response => response.data) */
}

export {
    getAppSubscription,
    updateAppSubscription,
};