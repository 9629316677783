import { sendGraphQL } from '../sendRequest';
import { PER_PAGE } from '../../config';

export const GET_UNREAD_CHAT_MESSAGES = 'GET_UNREAD_CHAT_MESSAGES';
export const GET_UNREAD_CHAT_MESSAGES_FULFILLED = 'GET_UNREAD_CHAT_MESSAGES_FULFILLED';

export const MARK_CHAT_MESSAGES_AS_READ = 'MARK_CHAT_MESSAGES_AS_READ';
export const MARK_CHAT_MESSAGES_AS_READ_FULFILLED = 'MARK_CHAT_MESSAGES_AS_READ_FULFILLED';

export const ADD_MESSAGE_ID_TO_UNREAD = 'ADD_MESSAGE_ID_TO_UNREAD';

const getChatMessages = ({ affiliate_id, advertiser_id }, skip = 0, attachments) => {
    let linksFragment = '';
    if (attachments?.links) {
        linksFragment = `
            links {
                _id
                image
                url
                title
                sender
                date
            }
        `;
    }

    const query = `
        query getChatMessages($affiliate_id: String!, $advertiser_id: String!, $skip: Int, $perPage: Int) {
            getChatMessages(affiliate_id: $affiliate_id, advertiser_id: $advertiser_id) {
                messages(skip: $skip, perPage: $perPage) {
                    _id
                    affiliate_id
                    advertiser_id
                    sender
                    date
                    type
                    text
                    unread
                    title
                    url
                    description
                    favicon
                    image
                }
                ${linksFragment}
            }
        }
    `;

    const variables = {
        affiliate_id,
        advertiser_id,
        skip,
        perPage: PER_PAGE.CHAT_MESSAGES
    };

    const payload = {
        query,
        variables
    };

    return sendGraphQL(payload, false, true)
        .then((response) => { return response.data; })
        .catch((err) => { console.error(err); });
};

export const getUnreadChatMessages = (advertiser_id) => {
    console.log('GET UNREADS ACTION', advertiser_id);
    const query = `
        query getUnreadChatMessages($advertiser_id: String) {
            getUnreadChatMessages(advertiser_id: $advertiser_id) {
                sender_id
                message_ids
            }
        }
    `;

    const variables = {
        advertiser_id
    };

    const payload = {
        query,
        variables
    };

    return {
        type: GET_UNREAD_CHAT_MESSAGES,
        payload: sendGraphQL(payload)
            .then((response) => { return response.data; })
            .catch((err) => { console.error(err); })
    };
};

export const markChatMessagesAsRead = (message_ids) => {
    const query = `
        mutation markChatMessagesAsRead($message_ids: [ID!]!) {
            markChatMessagesAsRead(message_ids: $message_ids)
        }
    `;

    const variables = {
        message_ids
    };

    const payload = {
        query,
        variables
    };

    return {
        type: MARK_CHAT_MESSAGES_AS_READ,
        payload: sendGraphQL(payload)
            .then(() => { return message_ids; })
            .catch((err) => { console.error(err); })
    };
};

// only updates redux -- skips the trip to the database since the unread status will be set to true when the message is saved
export const addMessageIdToUnread = (sender_id, message_id) => {
    return {
        type: ADD_MESSAGE_ID_TO_UNREAD,
        payload: {
            sender_id,
            message_id
        }
    };
};

export default getChatMessages;
