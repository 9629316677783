import { GET_PROMOTIONS_DATA_FULFILLED } from '../actions/getPromotions';

const defaultState = {
    promotionsData: []
};

const promotionsReducer = (state = defaultState, action) => {
    const { type } = action;

    switch (type) {
        case GET_PROMOTIONS_DATA_FULFILLED: {
            if (typeof action.payload !== 'object') {
                return state;
            }
            return { ...state, promotionsData: action.payload };
        }

        default: {
            return state;
        }
    }
};

export default promotionsReducer;
