import React from 'react';
import { SkeletonBodyText } from '@shopify/polaris';
import { ResponsiveBar } from '@nivo/bar';

import numberFormat from '../../utils/number-format';

import useResponsive from '../../hooks/use-responsive';

const LookForwardInsightsSkeleton = (props) => {
    const pageWidth = useResponsive();

    const renderBarGraph = (direction = 'vertical') => {
        if(pageWidth <= 450){
            direction = 'vertical';
        }
        let margin = {}
        let x = null;
        let y = null;
        if(direction === 'horizontal'){
            margin = {
                "top": 0,
                "right": 100,
                "bottom": 0,
                "left":10
            }
            x = '90%'
            y = null;
        }else{
            margin = {
                "top": 20,
                "right": 10,
                "bottom": 20,
                "left":10
            }
            y = -10
            x = null
        }
        return (
            <div className="ltv-graph">
                <h3>Estimated LTV</h3>
                <p>Total estimated revenue per customer.</p>
                <div className="settings-ltv__nivo-bgcolor">
                    <div className='nivo__ltv'>
                        <ResponsiveBar
                            data={props.ltvGraphData}
                            keys={[
                                "Estimated Lifetime Value"
                            ]}
                            indexBy="storeType"
                            margin={margin}
                            innerPadding={0}
                            padding={0.3}
                            colors={['#fc2c58', '#00a313']}
                            colorBy={function(e){var t=e.id;return e.data["".concat(t,"Color")]}}
                            layout={direction}
                            axisTop={null}
                            axisRight={null}
                            axisBottom={null}
                            axisLeft={null}
                            borderColor="inherit:darker(1.6)"
                            labelSkipWidth={12}
                            labelSkipHeight={12}
                            labelTextColor="#000000"
                            labelFormat={d => <tspan y={ y } x={ x } style={{fontWeight: 'bold', fontSize: '1.4rem'}}>{ `$${numberFormat(d, 2)}` }</tspan>}
                            animate={true}
                            motionStiffness={90}
                            motionDamping={15}
                        />
                    </div>
                    <p className="settings-ltv__graph-labels">
                        <span className="settings-ltv__graph-label">One-Time Customers</span>
                        <span className="settings-ltv__graph-label">Repeat Customers</span>
                    </p>
                </div>
            </div>
        )
    }

    return (
        <>
            <p className="breakdown-intro">Based on your store&rsquo;s past order history, we&rsquo;ve estimated what your average order value, long term value (LTV), and commission payments could be over the next {props.forwardSliderValue} month{props.forwardSliderValue !== 1 && 's'}.</p>

            <div className="info-blocks">
                <div>
                    <h3>Total Sales History</h3>
                    <p>Total revenue your store produced over the past {props.ltvMonths} month{props.ltvMonths !== 1 && 's'}.</p>
                    <dl>
                        <dt className="all">All Customers</dt>
                        <dd><SkeletonBodyText lines={1} /></dd>
                        <dt className="one-time">One-Time Customers</dt>
                        <dd><SkeletonBodyText lines={1} /></dd>
                        <dt className="repeat">Repeat Customers</dt>
                        <dd><SkeletonBodyText lines={1} /></dd>
                    </dl>
                </div>
                <div>
                    <h3>Average Order Value</h3>
                    <p>Total estimated revenue per customer per order.</p>
                    <dl>
                        <dt className="all">All Customers</dt>
                        <dd><SkeletonBodyText lines={1} /></dd>
                        <dt className="one-time">One-Time Customers</dt>
                        <dd><SkeletonBodyText lines={1} /></dd>
                        <dt className="repeat">Repeat Customers</dt>
                        <dd><SkeletonBodyText lines={1} /></dd>
                    </dl>
                </div>
                {pageWidth <= 975 && renderBarGraph('horizontal')}
                <div className="commission">
                    <h3>Projected Commission Payments</h3>
                    <p>Estimate of what you&rsquo;ll pay ambassadors for initial and long term sales per order for 5 common structures.</p>
                    <table>
                        <thead>
                            <tr>
                                <th>Commission Structure</th>
                                <th>Rate</th>
                                <th>Initial Sale</th>
                                <th>Long Term Sales</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            [5, 10, 15, 20, 25].map((commissionPercent) => {
                                return (
                                    <tr key={commissionPercent}>
                                        <td><SkeletonBodyText lines={1} />% Per Order</td>
                                        <td><SkeletonBodyText lines={1} /></td>
                                        <td><SkeletonBodyText lines={1} /></td>
                                        <td><SkeletonBodyText lines={1} /></td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </table>
                </div>
            </div>
            {pageWidth > 975 && renderBarGraph()}
        </>
    )
}

export default LookForwardInsightsSkeleton;