import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import { Badge, SkeletonBodyText } from '@shopify/polaris';

import { EmptyState, LlamaPagination } from 'llama-library/components';
import { moneyFormat } from '../../utils/number-format';

import './billing-history.css';

/**
 * Reusable component to show a table of invoices (date, order number, description, status, and amount). 10 rows wil show per page, but this can be overridden through props.perPage. Pagination is done client side. Invoices can be passed as a simple list or as a list of groupings where each grouping has a list of invoices. The latter is ideal for tracking subscription periods as it will show larger row dividers between groups.
 *
 * invoice data structure: {created: Timestamp!, number: String (the invoice/order number), description: String!, status: String!, amount: Float!}
 *
 * Props:
 * @param {Int} perPage The amount of rows to show per page. Optional, defaults to 10.
 * @param {Array} invoiceGroups An array of groups of invoices—example: [[invoice data, invoice data, invoice data], [invoice data, invoice data], ...]
 * @param {Array} invoices An array of of invoices—example: [invoice data, invoice data, invoice data, ...]
 * @param {Boolean} loading Whether the billing history is still being fetched. If true, will show a skeleton table
 * @param {Boolean} showEmptyState Whether to show the llama head empty state when there's no data available
 */
const LlamaBillingHistory = ({ invoiceGroups, invoices, perPage = 10, loading, showEmptyState = true }) => {
    if (invoiceGroups && invoices) {
        throw new Error('Please pass only invoices or invoiceGroups to the LlamaBillingHistory component, not both');
    }

    let initialInvoiceGroups = invoiceGroups;

    if (invoices) {
        initialInvoiceGroups = [invoices];
    }

    const [currentPage, setCurrentPage] = useState(1);
    const [groupedInvoices, setGroupedInvoices] = useState([]);
    const [maxPages, setMaxPages] = useState(0);

    // paginate the list of invoices while keeping the groupings intact
    useEffect(() => {
        if (initialInvoiceGroups) {
            let invoiceCounter = 0;
            // start with an empty invoice group so we can rebuild it based on pagination
            let curInvoiceGroup = [];

            const invoiceGroupsPaginated = initialInvoiceGroups.reduce((groups, invoiceGroup) => {
                const startIndex = ((currentPage - 1) * perPage);

                // for each invoice, increment the invoice counter. if the invoice counter is between the page start and page end, add it to the current invoice group
                invoiceGroup.forEach((invoice) => {
                    if (invoiceCounter >= startIndex && invoiceCounter < startIndex + perPage) {
                        curInvoiceGroup.push(invoice);
                    }
                    invoiceCounter++;
                });
                // if the current invoice group isn't empty, add it to the parent list of groups
                if (curInvoiceGroup.length > 0) {
                    groups.push(curInvoiceGroup);
                }
                // empty the invoice group so we can use it to rebuild the next group iteration
                curInvoiceGroup = [];

                return groups;
            }, []);

            setGroupedInvoices(invoiceGroupsPaginated);
            // only set maxPages for the first run through
            if (maxPages === 0) {
                setMaxPages(Math.ceil((invoiceCounter + 1) / perPage));
            }
        }
    }, [currentPage, initialInvoiceGroups]);

    if (loading) {
        const rows = [];
        for (let i = 0; i < perPage; i++) {
            rows.push(i);
        }

        return (
            <table width="100%" className="billing-history skeleton">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Description</th>
                        <th>Status</th>
                        <th className="amount">Amount</th>
                    </tr>
                </thead>
                <tbody>
                    {rows.map((row) => {
                        return (
                            <tr key={row}>
                                <td className="date"><SkeletonBodyText lines={2} /></td>
                                <td className="description"><SkeletonBodyText lines={1} /></td>
                                <td className="status"><SkeletonBodyText lines={1} /></td>
                                <td className="amount"><SkeletonBodyText lines={1} /></td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        );
    }

    if (!initialInvoiceGroups || initialInvoiceGroups.length === 0) {
        return (showEmptyState)
            ? <EmptyState message="You haven't made any payments yet." />
            : null;
    }

    const handleNext = () => {
        window.scrollTo(0, document.querySelector('.billing-history').offsetTop - 60);
        let curPage = currentPage;
        curPage++;
        setCurrentPage(curPage);
    };

    const handlePrevious = () => {
        window.scrollTo(0, document.querySelector('.billing-history').offsetTop - 60);
        let curPage = currentPage;
        curPage--;
        setCurrentPage(curPage);
    };

    return (
        <>
            <table width="100%" className="billing-history">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Description</th>
                        <th>Status</th>
                        <th className="amount">Amount</th>
                    </tr>
                </thead>
                {groupedInvoices.map((invoiceList, index) => {
                    return (
                        <tbody key={index}>
                            {invoiceList.map((invoice) => {
                                let badgeStatus = 'success';
                                switch (invoice.status) {
                                    case 'unpaid':
                                        badgeStatus = 'warning';
                                        break;
                                    case 'cancelled':
                                        badgeStatus = 'critical';
                                        break;
                                    default:
                                        break;
                                }
                                return (
                                    <tr key={invoice.created}>
                                        <td className="date">
                                            {moment(invoice.created * 1000).format('MMM D, YYYY')}
                                            {invoice.number && <span className="order-number">{invoice.number}</span>}
                                        </td>
                                        <td className="description">{invoice.description}</td>
                                        <td className="status"><Badge status={badgeStatus}>{invoice.status.substr(0, 1).toUpperCase() + invoice.status.substr(1)}</Badge></td>
                                        <td className="amount">{moneyFormat(invoice.amount_paid, false)}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    );
                })}
            </table>
            {maxPages > 1
                && (
                    <LlamaPagination
                        hasNext={currentPage < maxPages}
                        hasPrevious={currentPage > 1}
                        onNext={handleNext}
                        onPrevious={handlePrevious}
                        currentPage={currentPage - 1}
                        maxPages={maxPages - 1}
                    />
                )}
        </>
    );
};

const invoiceShape = PropTypes.shape({
    created: PropTypes.number.isRequired,
    number: PropTypes.string,
    description: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    amount_paid: PropTypes.number
});

LlamaBillingHistory.propTypes = {
    invoiceGroups: PropTypes.arrayOf(PropTypes.arrayOf(invoiceShape)),
    invoices: invoiceShape,
    perPage: PropTypes.number,
    loading: PropTypes.bool.isRequired,
    showEmptyState: PropTypes.bool
};

LlamaBillingHistory.defaultProps = {
    invoiceGroups: null,
    invoices: null,
    perPage: 10,
    showEmptyState: true
};

export default LlamaBillingHistory;
