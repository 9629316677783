import { CHANGE_HEADER_TITLE } from "../actions/header";


const defaultState = {
    title: '',
    affiliateLink: '',
    displayNotifications: false,
    notification: []
}

const headerReducer = (state = defaultState, action) => {

    const { type } = action;
    
    switch (type) {

        case CHANGE_HEADER_TITLE: {
            if (!action.payload || !(typeof action.payload === 'string')) {
                return state;
            }
            return { ...state, title: action.payload }
        }

        default:
            return state;

    }

}

export default headerReducer;