import React, { useState, useEffect } from 'react';
import { LlamaPagination } from 'llama-library/components';

import usePages from '../../hooks/use-pages';
import './ambassadors.css';

import AmbassadorsSidebar from './ambassador-sidebar';
import AmbassadorApplication from './ambassador-application';
import LoadingApplication from './loading-application';
import useSocialFilter from '../../hooks/use-social-filter';
import { EmptyState, LlamaToast } from 'llama-library/components';

/**
 * @function EmptyStateApplications
 * Accepts applications Object, returns EmptyStateApplications Component
 * Displays a 'no applications to display' message. Provides link to Discover Ambassadors.
 *
 * @param {Object} applications
 *
 * @returns {FunctionComponent}
 */
const EmptyStateApplications = ({applications, history, currentTab, filtersApplied}) => { 
    let message = ''
    let paragraph = (filtersApplied) ? 'Try broadening your search filters to see more ambassadors' : null;
    switch(currentTab){
        case 'shortlist':
            message = 'You haven’t shortlisted any ambassadors'
            if(filtersApplied) message = 'No shortlisted ambassadors matched your filters'
        break;
        case 'approved':
        case 'denied':
            message = 'You haven’t '+currentTab+' any ambassadors'
            if(currentTab === 'denied' && !filtersApplied) paragraph = 'But that’s probably a good thing.'
            if(filtersApplied) message = 'No '+currentTab+' ambassadors matched your filters'
        break;
        case 'new':
            message = 'You don’t have any new ambassadors'
            if(filtersApplied) message = 'No new ambassadors matched your filters'
        break;
        case 'all':
            if(filtersApplied) message = 'No ambassadors matched your filters'
        break;
    }
    return (
        <div className="ambassadors-empty">
            <div>
                {currentTab === 'all' && !filtersApplied
                    ? <EmptyState message="Nobody’s applied to your offers yet" paragraph="Maybe try inviting some ambassadors? I can help you find the best ones for your brand." ctaText="Discover Ambassadors" ctaAction={() => history.push("/discover")} />
                    : <EmptyState message={message} paragraph={paragraph} />
                }
            </div>
        </div>
    )

}

const tabs = {
    all: {
        key: 'all',
        name: 'ALL',
        filter: () => true,
    },
    new: {
        key: 'new',
        name: 'NEW',
        filter: (item) => {
            return item.affiliate_status === 'APPROVED' && item.advertiser_status === 'PENDING';
        },
    },
    shortlist: {
        key: 'shortlist',
        name: 'SHORTLIST',
        filter: (item) => {
            return item.advertiser_favorite
        },
    },
    approved: {
        key: 'approved',
        name: 'APPROVED',
        filter: (item) => {
            return item.affiliate_status === 'APPROVED' && item.advertiser_status === 'APPROVED';
        },
    },
    denied: {
        key: 'denied',
        name: 'DENIED',
        filter: (item) => {
            return item.advertiser_status === 'DENIED';
        },
    },
}

/**
 * @function AmbassadorsMain
 * Accepts the following params, returns AmbassadorMain Component.
 * Displays all discoverable ambassadors.
 *
 * @param {Array} applications - Array of applications
 * @param {Function} updateApplication - method updateApplication
 * @param {Object} history
 *
 * @returns {FunctionComponent}
 */
const AmbassadorsMain = ({ applications, updateApplication, history, filtersApplied }) => {
    const defaultTab = tabs.all;
    const defaultFilter = () => true;

    const [currentTab, setTab] = useState(defaultTab.key);
    const [toastVerbiage, setToastVerbiage] = useState(null);

    // Apply filter based on tab selection.
    const tabFilter = tabs[currentTab] && tabs[currentTab].filter
        ? tabs[currentTab].filter
        : defaultFilter;

    const filteredApplications = applications && applications
        .filter(tabFilter);

    let {
        previousPage,
        nextPage,
        currentPage,
        hasNext,
        hasPrevious,
        maxPages,
        posts
    } = usePages(0, 5, filteredApplications, currentTab);

    /**
     * @function renderTabs
     * Accepts tab string and array of applications and returns div
     * element according to the applications available and populates
     * the value for each status of application.
     *
     * @param {String} tab - String that represents tab name
     * @param {Array} applications - Array of applications
     *
     * @returns {HTMLDivElement}
     */
    const renderTabs = (tab, applications) => {

        const isActive = currentTab === tab.key;
        const classes = ['ambassadors-main__tab']

        if (isActive) {
            classes.push('ambassadors-main__tab--active')
        }

        const value = Array.isArray(applications)
            ? applications.filter(tab.filter).length
            : '-';

        return (
            <div
                key={tab.key}
                className={classes.join(' ')}
                onClick={() => setTab(tab.key)}
            >
                <div className="ambassadors-main__tab-value">{value}</div>
                <div className="ambassadors-main__tab-name">{tab.name}</div>
            </div>
        )
    }

    return (
        <div className="ambassadors-main" data-test="component-ambassadorsMain">
            <div className="ambassadors-main__tabs">
                {Object.values(tabs).map((tab) => renderTabs(tab, applications))}
            </div>
            <div className="ambassadors-main__primary" data-test="component-applications">
                {Array.isArray(posts) && posts.length === 0
                    && <EmptyStateApplications applications={applications} history={history} currentTab={currentTab} filtersApplied={filtersApplied} />
                }
                {Array.isArray(posts)
                    ? (
                        <>
                            {posts.map((item) => {
                                return (
                                    <AmbassadorApplication
                                        data={item}
                                        key={item.application_id}
                                        updateApplication={updateApplication}
                                        history={history}
                                        data-test="component-application"
                                        setToastVerbiage={setToastVerbiage}
                                    />
                                );
                            })}
                            <LlamaToast toastVerbiage={toastVerbiage} setToastVerbiage={setToastVerbiage} />
                        </>
                    )
                    : <LoadingApplication />
                }
            </div>
            <div className="ambassadors-main__pagination">
                {maxPages > 0
                    && (
                        <LlamaPagination
                            hasNext={hasNext}
                            hasPrevious={hasPrevious}
                            onNext={nextPage}
                            onPrevious={previousPage}
                            currentPage={currentPage}
                            maxPages={maxPages}
                        />
                    )
                }
            </div>
        </div>
    );

}

const sortFilters = {
    new: (a, b) => {
        if (a.created_at > b.created_at) return -1;
        if (b.created_at < b.created_at) return 1;
        return 0;
    },
    old: (a, b) => {
        if (a.created_at > b.created_at) return 1;
        if (b.created_at < b.created_at) return -1;
        return 0;
    },
    reach: (a, b) => {
        if (a.reach > b.reach) return -1;
        if (b.reach < b.reach) return 1;
        return 0;
    }
}

/**
 * @function Ambassadors
 * Accepts props Object, returns Ambassadors Component.
 * Wraps the AmbassadorsMain and AmbassadorsSidebar Components.
 *
 * @param {Object}
 *
 * @returns {FunctionComponent}
 */
const Ambassadors = (props) => {
    // console.log("AMBASSADORS PROPS", props);

    const [searchValue, setSearchValue] = useState("");
    const [sort, setSort] = useState("new");
    const [offerFilter, setOfferFilter] = useState('');
    const { socialFilter, setSocialFilter, appliedSocialFilter } = useSocialFilter("all");
    const [filtersApplied, setFiltersApplied] = useState(false);

    const sortBy = sortFilters[sort] ? sortFilters[sort] : () => 0;

    //detect whether the user used filters so we can show them an accurate empty state message
    useEffect(() => {
        if(searchValue === '' && offerFilter === '' && socialFilter === 'all'){
            setFiltersApplied(false)
        }else{
            setFiltersApplied(true)
        }
    }, [searchValue, offerFilter, socialFilter])

    const applications = props.applications && props.applications
        .filter((item) => {
            if( item.offer === null && item.affiliate && item.affiliate.name ) return false;
            const offerCase = item.offer !== null ? item.offer.name.toLowerCase() : null;
            const affiliateCase = (item.affiliate && item.affiliate.name) ? item.affiliate.name.toLowerCase() : null;
            if( offerCase !== null ) {
                return item.offer.name.includes(searchValue) || offerCase.includes(searchValue)
            } else {
                return item.affiliate.name.includes(searchValue) || affiliateCase.includes(searchValue);
            }
        })
        .filter(appliedSocialFilter)
        .filter((item) => {
            if (!offerFilter || offerFilter === '') return true;
            return item.offer.offer_id === offerFilter;
        })
        .sort(sortBy);

    return (
        <div className="ambassadors__wrapper" data-test="component-ambassadors">
            <AmbassadorsMain {...props} applications={applications} filtersApplied={filtersApplied} />
            <AmbassadorsSidebar
                {...props}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                socialFilter={socialFilter}
                setSocialFilter={setSocialFilter}
                sort={sort}
                setSort={setSort}
                offerFilter={offerFilter}
                setOfferFilter={setOfferFilter}
                refreshApplications={props.refreshApplications}
            />
        </div>
    )

}

export default Ambassadors;