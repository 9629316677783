import React from 'react';

import './featured-ambassadors-list.css';

import PropTypes from 'prop-types';

import { SkeletonBodyText, SkeletonDisplayText } from '@shopify/polaris';

import FeaturedAmbassador from './featured-ambassador';

const FeaturedAmbassadorsList = ({ ambassadors, history }) => {

    // Skeleton of featured ambassadors for loading state
    const AmbassadorsSkeleton = () => {
        const loadingAmbassadors = ['A1', 'A2', 'A3', 'A4', 'A5', 'A6', 'A7', 'A8', 'A9'];
        return (
            <div className="FeaturedAmbassadors__Grid">
                {loadingAmbassadors.map((loadingAmbassador) => {
                    return (
                        <div className="FeaturedAmbassador__LoadingCard" key={loadingAmbassador}>
                            <div className="FeaturedAmbassador__LoadingImg">{' '}</div>
                            <div className="FeaturedAmbassador__LoadingContent">
                                <div className="FeaturedAmbassador__LoadingCategory">
                                    <SkeletonBodyText lines={1} />
                                </div>
                                <h1>
                                    <SkeletonDisplayText size="small" />
                                </h1>
                                <div className="FeaturedAmbassador__LoadingStats">
                                    <div className="FeaturedAmbassador__LoadingStat">
                                        <div className="label">Reach</div>
                                        <div className="value"><SkeletonBodyText lines={1} /></div>
                                    </div>
                                    <div className="FeaturedAmbassador__LoadingStat">
                                        <div className="label">Engagement</div>
                                        <div className="value"><SkeletonBodyText lines={1} /></div>
                                    </div>
                                    <div className="FeaturedAmbassador__LoadingStat">
                                        <div className="label">EPC</div>
                                        <div className="value"><SkeletonBodyText lines={1} /></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    };

    // Loading state
    if (!ambassadors || ambassadors.length === 0) {
        return (
            <div className="FeaturedAmbassadors__Wrapper">
                <h1>Featured Ambassadors</h1>
                <p className="FeaturedAmbassadors__Description">These are the ambassadors that are catching our eye. We recommend inviting any of them to run your offers to really kickstart your program.</p>
                <AmbassadorsSkeleton />
            </div>
        );
    }

    return (
        <div className="FeaturedAmbassadors__Wrapper">
            <h1>Featured Ambassadors</h1>
            <p className="FeaturedAmbassadors__Description">These are the ambassadors that are catching our eye. We recommend inviting any of them to run your offers to really kickstart your program.</p>
            <div className="FeaturedAmbassadors__Grid">
                {ambassadors.map((ambassador) => {
                    const { cognito_id } = ambassador;
                    return (
                        <FeaturedAmbassador
                            key={cognito_id}
                            history={history}
                            ambassador={ambassador}
                        />
                    );
                })}
            </div>
        </div>
    );
};

FeaturedAmbassadorsList.propTypes = {
    ambassadors: PropTypes.arrayOf(PropTypes.shape({
        _id: PropTypes.string,
        name: PropTypes.string,
        account: PropTypes.shape({ categories: PropTypes.arrayOf(PropTypes.string) }),
        reach: PropTypes.string,
        tracking: PropTypes.shape({ Stat: PropTypes.shape({ epc: PropTypes.string }) }),
        photo: PropTypes.string,
        facebookData: PropTypes.arrayOf(PropTypes.shape({ score: PropTypes.number })),
        instagramData: PropTypes.arrayOf(PropTypes.shape({ score: PropTypes.number })),
        twitterData: PropTypes.arrayOf(PropTypes.shape({ score: PropTypes.number })),
        youTubeData: PropTypes.arrayOf(PropTypes.shape({ score: PropTypes.number }))
    })),
    history: PropTypes.shape({ push: PropTypes.func })
};

FeaturedAmbassadorsList.defaultProps = { ambassadors: [], history: null };

export default FeaturedAmbassadorsList;
