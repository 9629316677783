import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import {
    Page,
    Button,
    Layout,
    Card,
    ButtonGroup,
    Banner,
    Select
} from '@shopify/polaris';

import { updateOfferStatus } from '../actions/create-offer';
import { getApplicationsForOffer } from '../actions/applications';
import { invitePageOffers } from '../actions/branded-sign-up';

import './styles/offer-form.css';

const OfferManageContainer = (props) => {
    const { values, isSubmitting, offer } = props;
    const offerStatus = !!(offer && offer.active && (offer.interim_status !== 'ACTIVE'));
    const [isActive, setIsActive] = useState(null);

    const [showApplicationWarning, setShowApplicationWarning] = useState(false);
    const [showInviteOfferWarning, setShowInviteOfferWarning] = useState(false);
    const [fetchInviteOffers, setFetchInviteOffers] = useState(false);
    const [inviteOffers, setInviteOffers] = useState([]);

    // Assign the values of activeOffer into the formik values
    // And if active offer is not present in props then redirect to offers listing.
    useEffect(() => {
        if (props.offer && props.offer.offer_id) {
            props.setValues({ ...props.offer, selectedOffer: '' });
        } else {
            props.history.push('/offers');
        }
    }, [offer]);

    // Set the Button status as per offer status
    useEffect(() => {
        setIsActive(offerStatus);
    }, [offerStatus]);

    // Fetch the Invite page offers only if fetchInviteOffers state is true
    useEffect(() => {
        if (fetchInviteOffers) {
            const { offer, getInviteOffers } = props;
            const getOfferList = async () => {
                let featuredOffers = [];
                featuredOffers = await getInviteOffers(offer.advertiser_id);
                featuredOffers = featuredOffers.value.data.advertiserById.offersForBrandedSignup;
                if (featuredOffers.length > 0) {
                    featuredOffers = featuredOffers
                        .filter((offer) => { return offer.offer_id !== props.offer.offer_id; })
                        .map((offer) => {
                            return { label: offer.name, value: offer.offer_id };
                        });

                    featuredOffers.unshift({
                        label: 'Select Offer',
                        value: ''
                    });
                }

                setInviteOffers(featuredOffers);
                setShowInviteOfferWarning(true);
                setFetchInviteOffers(false);
            };

            getOfferList();
        }
    }, [fetchInviteOffers]);

    const setBreadcrumb = (offerId) => {
        return {
            content: 'Back to Offer Edit',
            onAction: () => { return props.history.push(`/offer/${offerId}/edit`); }
        };
    };

    const changeOfferStatusDeactive = async () => {
        if (!isActive) {
            return;
        }
        setIsActive(false);

        const { offer, setFieldValue, branded_sign_up } = props;

        const advertiserData = await getApplicationsForOffer(offer.offer_id, offer.advertiser_id);
        const applications = advertiserData.advertisers[0].applications.filter((app) => { return app.advertiser_status === 'APPROVED'; });

        // If offer attached to any application then show the confirmation box and return
        if (applications.length > 0) {
            setShowApplicationWarning(true);
            setFieldValue('interim_status', 'ACTIVE', false);
        } else {
            setFieldValue('active', false, false);
            setFieldValue('interim_status', 'INACTIVE', false);
        }

        setFieldValue('inactive_reason', `Offer deactivated by the adveriser[${offer.advertiser_id}]`, false);

        // Check if deactivating offer is same as Invite Page offer
        // then show the warning with list
        if (branded_sign_up.offer_id === offer.offer_id) {
            setFetchInviteOffers(true);
        }
    };

    const changeOfferStatusActive = async () => {
        if (isActive) {
            return;
        }
        setIsActive(true);

        const { setFieldValue } = props;

        // Remove the warnings if coming for active action
        setShowInviteOfferWarning(false);
        setShowInviteOfferWarning(false);

        // Set the value if active request came for offer
        setFieldValue('interim_status', 'INACTIVE', false);
        setFieldValue('active', true, false);
    };

    const setError = (fieldName) => {
        const { errors } = props;
        if (errors.length > 0) {
            const errorObj = errors.find((error) => {
                return error.field === fieldName;
            });
            return (errorObj !== undefined && errorObj.message !== undefined)
                ? errorObj.message
                : null;
        }
        return null;
    };

    const ShowInviteOffersContainer = () => {
        const { setFieldValue } = props;
        let warningMsg = 'The offer you are going to deactivate will also be removed from Invite Page.Choose another offer to replace the featured offer of Invite Page.';

        if (inviteOffers.length === 1) {
            warningMsg = 'This offer is being used on your branded Invite Ambassadors page and deactivating it will fully reset that page where you will need to configure it again if you would like to use it.';
        }

        const handleSelectChange = (value) => {
            setFieldValue('selectedOffer', value);
        };

        return (
            <>
                <div className="offer-status__warning-banner">
                    <Banner
                        title={warningMsg}
                        status="warning"
                    />
                </div>
                {inviteOffers.length > 1
                && (
                    <div className="offer-status__warning-banner">
                        <Select
                            label="FEATURED OFFER"
                            options={inviteOffers}
                            onChange={handleSelectChange}
                            value={values.selectedOffer}
                            error={setError('selectedOffer')}
                        />
                    </div>
                )}
            </>
        );
    };

    const renderOfferStatus = () => {
        return (
            <Card>
                <Card.Section>
                    <div className="offer-status-card__conatiner">
                        <div className="campaign-name__heading">
                            <h2 className="Polaris-Heading">OFFER STATUS</h2>
                        </div>
                        <ButtonGroup segmented>
                            <Button pressed={isActive} onClick={changeOfferStatusActive}>
                                Activate
                            </Button>
                            <Button pressed={!isActive} onClick={changeOfferStatusDeactive}>
                                Deactivate
                            </Button>
                        </ButtonGroup>
                    </div>
                    {showApplicationWarning
                    && (
                        <div className="offer-status__warning-banner">
                            <Banner
                                title="Setting this Offer to Inactive status will notify all approved Ambassadors of its status change.
                                Offer will remain active for another 2 days to allow Ambassadors to adjust campaigns on their side."
                                status="warning"
                            />
                        </div>
                    )}
                    {showInviteOfferWarning && <ShowInviteOffersContainer />}
                </Card.Section>
            </Card>
        );
    };

    const validate = (e) => {
        const errors = [];

        if (showInviteOfferWarning && inviteOffers.length > 1 && props.values.selectedOffer === '') {
            errors.push({ field: 'selectedOffer', message: 'Featured Offer is required' });
        }

        if (errors.length === 0) {
            props.handleSubmit(e);
        } else {
            props.setSubmitting(false);
            props.setErrors(errors);
        }
    };

    return (
        <Page
            title={values.name}
            separator
            primaryAction={{
                content: 'Save',
                onAction: validate,
                disabled: isSubmitting,
                loading: isSubmitting
            }}
            breadcrumbs={[setBreadcrumb(values.offer_id)]}
        >
            <Layout>
                <Layout.Section>
                    <div className="offer-form">
                        {renderOfferStatus()}
                    </div>
                </Layout.Section>
            </Layout>
        </Page>
    );
};

OfferManageContainer.defaultProps = { branded_sign_up: PropTypes.shape({ offer_id: null }) };

OfferManageContainer.propTypes = {
    isSubmitting: PropTypes.bool.isRequired,
    values: PropTypes.shape({
        active: PropTypes.bool,
        offer_id: PropTypes.string,
        name: PropTypes.string,
        interim_status: PropTypes.string,
        selectedOffer: PropTypes.string
    }).isRequired,
    offer: PropTypes.shape({
        active: PropTypes.bool.isRequired,
        interim_status: PropTypes.string,
        offer_id: PropTypes.string.isRequired,
        advertiser_id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
    }).isRequired,
    history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
    getInviteOffers: PropTypes.func.isRequired,
    branded_sign_up: PropTypes.shape({ offer_id: PropTypes.string }),
    setValues: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    setSubmitting: PropTypes.func.isRequired,
    setErrors: PropTypes.func.isRequired
};

const submitForm = async (values, { props, setSubmitting }) => {
    const brandedSignupData = {
        branded_sign_up: {
            slug: '',
            offer_id: values.selectedOffer,
            display_offer_data: true,
            banner_image: '',
            body: ''
        }
    };

    const updateOfferStatusInput = { ...values, ...brandedSignupData };

    console.log('updateOfferStatusInput>>>', updateOfferStatusInput);
    updateOfferStatus(updateOfferStatusInput)
        .then((result) => {
            console.log('result', result);
            props.history.push(`/offer/${values.offer_id}`, { offerStateUpdate: true });
            setSubmitting(false);
        });
};

const OfferManage = withFormik({
    mapPropsToValues: (props) => {
        if (
            props
            && props.location
            && props.location.state
            && props.location.state.values
        ) {
            return props.location.state.values;
        }

        return {};
    },
    handleSubmit: submitForm
})(OfferManageContainer);

const mapStateToProps = ({ activeOffer }) => {
    return {
        offer: activeOffer.data ? activeOffer.data.advertisers[0].offers[0] : null,
        branded_sign_up: activeOffer.data ? activeOffer.data.advertisers[0].branded_sign_up : null
    };
};

const mapDispatchToProps = (dispatch) => {
    return { getInviteOffers: (advertiser_id) => { return dispatch(invitePageOffers(advertiser_id)); } };
};

export default connect(mapStateToProps, mapDispatchToProps)(OfferManage);
