import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Page,
    Card,
} from '@shopify/polaris';

import PlanSettings from '../components/settings-plan/settings-plan';
import { updateAppSubscription, getAppSubscription } from '../actions/app-subscription';

class PlanSettingsContainer extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        
        this.state = {
            starterMonthly: true,
            proMonthly: true,
            loading: null,
            planSelected: null,
            displaySelection: false,
            plan: null,
            dataIsLoading: true,
            charges: null
        }

        this.toggleStarterPlanType = this.toggleStarterPlanType.bind(this);
        this.toggleProPlanType = this.toggleProPlanType.bind(this);
        this.toggleDisplaySelection = this.toggleDisplaySelection.bind(this);
        this.selectPlan = this.selectPlan.bind(this);
        this.parseSubscription = this.parseSubscription.bind(this);
    }

    /**
     * @function parseSubscription
     * Grabs the first item of the advertiser's charges array and checks 
     * if the name matches the name in the app advertiserPlans array.
     * Sets dataIsLoading to false, plan to the first item of charges array,
     * planSelected as that matched plan name.
     * 
     * @param {Object} data - advertiser plans, advertiser charges
     */
    parseSubscription({ data }) {
        // If there's no charge data, or no data in the advertisers array
        // set displaySelection to true, will show Plans
        // and dataIsLoading to false
        if (
            !data 
            || !Array.isArray(data.advertisers) 
            || !data.advertisers[0] 
            || !data.advertisers[0].charges 
            || data.advertisers[0].charges.length === 0
            ) {
            this.setState({ dataIsLoading: false, displaySelection: true, charges: [] });
        // Else grab charges data from advertiser
        // and the current plan data
        } else {
            const { charges } = data.advertisers[0];

            let planSelected = null;
            if (data.app && Array.isArray(data.app.advertiserPlans)) {
                const currentPlan = data.app.advertiserPlans.find((item) => {
                    return item.name === charges[0].name;
                })
                planSelected = currentPlan.key;
            }
    
            this.setState({ dataIsLoading: false, plan: charges[0], planSelected, charges });
        }
         
    }

    //Called FIRST when component mounts, checks if advertiser_id exists, if so
    //calls getAppSubscription with advertiser_id, on success returns price plans,
    //and advertiser charges object and calls parseSubscription with this object
    componentDidMount() {
        this._isMounted = true;
        if (this.props.advertiser.advertiser_id) {
            getAppSubscription(this.props.advertiser.advertiser_id)
                .then((result) => {
                    if(this._isMounted) {
                        this.parseSubscription(result); 
                    }
                })
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    toggleStarterPlanType() {
        this.setState({ 
            starterMonthly: !this.state.starterMonthly 
        });
    }

    toggleProPlanType() {
        this.setState({ 
            proMonthly: !this.state.proMonthly 
        });
    }

    toggleDisplaySelection(displaySelection) {
        // console.log(displaySelection);
        this.setState({ displaySelection: !displaySelection })
    }

    /**
     * @function selectPlan
     * Calls updateAppSubscription with advertiser_id and plan.

     * @param {Object} plan 
     */
    selectPlan(plan) {

        const { advertiser_id } = this.props.advertiser;
        if (!advertiser_id) return null;

        const variables = {
            advertiser_id,
            plan
        }

        this.setState({ selectionIsLoading: true });

        updateAppSubscription(variables)
            .then(({ data }) => {
                // If there is no data, confirmation_url or status
                // Set state of planSelected to null, selectionIsLoading to null
                if (
                    !data
                    || !data.updateAppSubscription
                    || !data.updateAppSubscription.confirmation_url
                    || !data.updateAppSubscription.status
                ) {
                    this.setState({ planSelected: null, selectionIsLoading: null });
                    return null;
                }

                // If user selects a plan and their status is 'active'
                // planSelected state is set with the plan the user clicked
                if (data.updateAppSubscription.status === 'active') {
                    this.setState({ planSelected: plan, selectionIsLoading: null });
                    return null;
                }

                // If status is 'pending' user is redirected to the confirmation url
                if (data.updateAppSubscription.status === 'pending') {
                    window.location.href = data.updateAppSubscription.confirmation_url;
                }

                this.setState({ selectionIsLoading: null });

            })
            .catch((err) => {
                console.log("ERROR", err)
                this.setState({ selectionIsLoading: null })
            })
    } 

    render() {
        if (!this.props.advertiser) {
            return null;
        }
        //displaySelection is true if advertiser status is not ACTIVE,
        //else it gets its initial state of false
        // const displaySelection = (this.props.advertiser && this.props.advertiser.status !== 'ACTIVE') || this.state.displaySelection;

        // const pageActions = (this.props.advertiser && this.props.advertiser.status === 'ACTIVE')
        //     && this.state.displaySelection
        //     ? [{
        //         content: 'Back',
        //         onAction: () => this.setState({ displaySelection: false }),
        //         icon: 'arrowLeft'
        //     }]
        //     : [];

        return (
            <PlanSettings 
                { ...this.props }
                { ...this.state }
                toggleStarterPlanType={this.toggleStarterPlanType}
                toggleDisplaySelection={this.toggleDisplaySelection}
                toggleProPlanType={this.toggleProPlanType}
                selectPlan={this.selectPlan}
                // displaySelection={displaySelection}
            >
            </PlanSettings>
        )
    }

}

const mapStateToProps = ({ advertiser }) => {

    return {
        advertiser
    }

}

export default connect(mapStateToProps)(PlanSettingsContainer);

