const offerFragment = `
    fragment OfferData on Offer {
        offer_id
        offer_type
        private_offer
        preview_url
        expiration_date
        active
        advertiser_id
        errors
        name
        bannerImage
        tags
        offer_url
        interim_status
        products {
            includeAll
            includeSome
            excludeSome
            includeProducts
            excludeProducts
        }
        rate {
            type
            amount
        }
        longterm_rate {
            type
            amount
        }
        description
        category
        EPC
        date_added
        cookie_days
        creatives {
            name
            size
            type
            url
        }
        tracking_pixel {
            test_click_url
            code
            url
            domain
        }
        price_rule_id
        target {
            gender
            age_low
            age_high
            interests
            countries
        }
        sponsored
    }
`;

export default offerFragment;
