/* import axios from 'axios';
import { NEXT_APP_API_URL } from '../config'; */
import { subDays } from 'date-fns';
import { sendGraphQL } from './sendRequest';

const getCurrentSales = (advertiser_id, start_date, end_date) => {

    if (!end_date) {
        const now = new Date();
        end_date = now.toISOString();
    }

    if (!start_date) {
        start_date = subDays(end_date, 30).toISOString();
    }

    const query = `
        query getCurentSales(
            $advertiser_id: String, 
            $start_date: String,
            $end_date: String
        ) {
            advertisers(advertiser_id: $advertiser_id) {
                reports(
                    start_date: $start_date,
                    end_date: $end_date
                ) {
                    salesData {
                        date
                        volume
                    }
                    productData {
                        title
                        product_id
                        volume
                        quantity
                    }
                    affiliateData {
                        affiliate_id
                        affiliate {
                            name
                        }
                        volume
                        sales
                        products_sold
                    }
                }
            }
        }
    `

    const variables = {
        advertiser_id,
        start_date,
        end_date
    }

    let payload = {
        query,
        variables
    }

    return sendGraphQL(payload)
        .then(response => response.data)

    /* let headers = {
        "Content-Type": 'application/json'
    }

    return axios.post(NEXT_APP_API_URL, payload, { headers })
        .then(response => response.data) */

}

export {
    getCurrentSales
}