import React from 'react';

import twitterIcon from '../../assets/twitter-icon.svg';
import youtubeIcon from '../../assets/youtube-icon.svg';
import facebookIcon from '../../assets/facebook-icon.svg';
import instagramIcon from '../../assets/instagram-icon.png';
import tiktokIcon from '../../assets/tiktok-icon.svg';

import './social-icons.css';

const checkHasActiveInstagram = (instaData) => {
    if (!Array.isArray(instaData) || instaData.length === 0) {
        return false;
    }

    const activeInstaAccounts = instaData.filter((item) => {
        return item.account_verified && (item.account_type === 'ACTIVE' || item.account_type === 'TRIAL');
    });

    return activeInstaAccounts.length > 0;
};

const checkHasActiveTikTok = (tikTokData) => {
    if (!Array.isArray(tikTokData) || tikTokData.length === 0) {
        return false;
    }

    const activeTikTokAccounts = tikTokData.filter((item) => {
        return item.account_verified && (item.account_type === 'ACTIVE' || item.account_type === 'TRIAL');
    });

    return activeTikTokAccounts.length > 0;
};

/**
 * @function SocialIcons
 * Accepts affiliate object, returns SocialIcons Component.
 * displays social icon for ambassador's connected social accounts.
 * 
 * @param {Object} affiliate 
 * 
 * @returns {FunctionComponent}
 */
const SocialIcons = ({ affiliate }) => {

    if (!affiliate) return null;

    // Set up social icons
    const hasTwitter = Array.isArray(affiliate.twitterData) && affiliate.twitterData.length > 0;
    const hasInstagram = checkHasActiveInstagram(affiliate.instagramDataV2);
    const hasYoutube = Array.isArray(affiliate.youTubeData) && affiliate.youTubeData.length > 0;
    const hasFacebook = Array.isArray(affiliate.facebookData) && affiliate.facebookData.length > 0;
    const hasTikTok = checkHasActiveTikTok(affiliate.tikTokData);

    return (
        <React.Fragment>
            <div className="social-icons">
                {hasFacebook && <img src={facebookIcon} className="social-icons__icon" />}
                {hasInstagram && <img src={instagramIcon} className="social-icons__icon" />}
                {hasTwitter && <img src={twitterIcon} className="social-icons__icon" />}
                {hasYoutube && <img src={youtubeIcon} className="social-icons__icon" />}
                {hasTikTok && <img src={tiktokIcon} className="social-icons__icon" />}
            </div>
            {!hasFacebook && !hasInstagram && !hasTwitter && !hasYoutube && !hasTikTok &&
            <p className="social-icons__empty-state">None</p>
            }
        </React.Fragment>
    )

}

export default SocialIcons;