import React from 'react';
import { SkeletonBodyText, SkeletonDisplayText} from '@shopify/polaris';
import { LlamaButton } from 'llama-library/components';

import llamaPlaceholder from '../../assets/llama-user-icon.png';
import platformIcon from '../../assets/platform-icon.svg';
import reachIcon from '../../assets/reach-icon.svg';
import engageIcon from '../../assets/engage-icon.svg';
import audienceIcon from '../../assets/audience-icon.svg';
import countryIcon from '../../assets/country-icon.svg';

/**
 * @function PlaceHolderAffiliate
 * Returns PlaceHolderAffiliate Component that returns
 * Polaris Skeleton affiliate during inital load .
 * 
 * @returns {FunctionComponent}
 */
const PlaceHolderAffiliate = () => {

    const affilaiteInfo = [
        {
            key: 'Marketing Platforms',
            value: <SkeletonBodyText lines={1} />,
            image: platformIcon
        },
        {
            key: 'Total Reach',
            value: <SkeletonBodyText lines={1} />,
            image: reachIcon
        },{
            key: 'Engagement Score',
            value: <SkeletonBodyText lines={1} />,
            image: engageIcon
        },
        {
            key: 'Audience',
            value: <SkeletonBodyText lines={1} />,
            image: audienceIcon
        },
        {
            key: 'Audience Country',
            value: <SkeletonBodyText lines={1} />,
            image: countryIcon
        },
    ]

    return (
        <div className="discover-affiliate skeleton">
            <div className="discover-affiliate__top">
                <img src={llamaPlaceholder} className="discover-affiliate__profile-image" />
                <div className="discover-affiliate__top-info">
                    <div className="discover-affiliate__name">
                        <SkeletonDisplayText size="medium" />
                    </div>
                    <div className="discover-affiliate__categories">
                        <SkeletonBodyText lines={2} />
                    </div>
                </div>
            </div>
            {affilaiteInfo.map((item) => {
                return <div className="discover-affiliate__section" key={item.key.replace(' ', '').toLowerCase()}>
                    <div className="discover-affiliate__key-icon">
                        <img src={item.image} className="discover-affiliate__section-icon" />
                        <div className="discover-affiliate__info-key">{item.key}</div>
                    </div>
                    <div className="discover-affiliate__info-value" style={{ width: '30%' }}>{item.value}</div>
                </div>
            })}
            <div className="discover-affiliate__section discover-affiliate__buttons">
                <LlamaButton secondary disabled>More Info</LlamaButton>
                <LlamaButton primary disabled>Invite</LlamaButton>
            </div>
        </div>
    )

}

export default PlaceHolderAffiliate