import React, { useState } from 'react';
import { Card, DropZone, TextStyle } from '@shopify/polaris';
import './import-affiliates.css';

import refersionLogo from '../../assets/refersion-logo.png';
import leadDynoLogo from '../../assets/lead-dyno-logo.png';
import affiliatlyLogo from '../../assets/affiliatly-logo.png';
import csvTemplate from '../../assets/ambassador-import.csv';

import {EmptyState} from 'llama-library/components';

const RenderRefersion = () => {
    return (
        <div className="import-affiliates__migration-instructions">
            <h1 className="import-affiliates__instruction-title">To migrate from Refersion:</h1>
            <div className="import-affiliates__steps-list">
                <div className="import-affiliates__step">1. From your dashboard, click <span className="import-affiliates__step import-affiliates__step--bold">Manage</span>, then click <span className="import-affiliates__step import-affiliates__step--bold">Affiliates</span>.</div>
                <div className="import-affiliates__step">2. In the top right corner, click <span className="import-affiliates__step import-affiliates__step--bold">Export All</span>. Refersion will email you a CSV file of all your affiliates.</div>
                <div className="import-affiliates__step">3. Upload the CSV file to Llama:</div>
            </div>
        </div>
    )
}

const RenderLead = () => {
    return (
        <div className="import-affiliates__migration-instructions">
            <h1 className="import-affiliates__instruction-title">To migrate from LeadDyno:</h1>
            <div className="import-affiliates__steps-list">
                <div className="import-affiliates__step">1. From your dashboard, click <span className="import-affiliates__step import-affiliates__step--bold">Affiliates</span>.</div>
                <div className="import-affiliates__step">2. In the top left corner of your affiliates list, click the <span className="import-affiliates__step import-affiliates__step--bold">Actions</span> dropdown, then select <span className="import-affiliates__step import-affiliates__step--bold">Export to CSV</span></div>
                <div className="import-affiliates__step">3. Upload the CSV file you just downloaded to Llama:</div>
            </div>
        </div>
    )
}

const RenderAffiliatly = () => {
    return (
        <div className="import-affiliates__migration-instructions">
            <h1 className="import-affiliates__instruction-title">To migrate from Affiliatly:</h1>
            <div className="import-affiliates__steps-list">
                <div className="import-affiliates__step">1. From your dashboard, click <span className="import-affiliates__step import-affiliates__step--bold">Affiliates</span>.</div>
                <div className="import-affiliates__step">2. Above your affiliates list, click <span className="import-affiliates__step import-affiliates__step--bold">Export</span>.</div>
                <div className="import-affiliates__step">3. Upload the CSV file you just downloaded to Llama:</div>
            </div>
        </div>
    )
}

const RenderOther = () => {
    return (
        <div className="import-affiliates__migration-instructions">
            <h1 className="import-affiliates__instruction-title">To migrate from Affiliatly:</h1>
            <div className="import-affiliates__steps-list">
                <a href={csvTemplate}>
                    <div className="import-affiliates__step">1. <span className="import-affiiates__csv-link">Download our sample CSV file.</span></div>
                </a>
                <div className="import-affiliates__step">2. Open the file in Excel, Numbers, or Google Sheets.</div>
                <div className="import-affiliates__step">3. Fill in your ambassador's names and emails, then save it as a CSV file.</div>
                <div className="import-affiliates__step">4. Upload the CSV file to LLama:</div>
            </div>
        </div>
    )
}

/**
 * @function ImportAffiliates
 * Accepts props Object, returns ImportAffiliates Component.
 * Displays the Polaris DropZone in which the merchant
 * can import a csv file of their ambassadors.
 * 
 * @param {Object} props 
 * 
 * @returns {FunctionComponent}
 */
const ImportAffiliates = (props) => {

    const fileUpload =  <DropZone.FileUpload actionTitle="Add a CSV file" actionHint="or drop CSV files to upload" />;

    const [migrationType, setMigrationType] = useState("refersion");

    if(props.advertiser.offers.length === 0){
        return (
            <EmptyState message="You don’t have any active offers for your ambassadors" paragraph="To build trust and encourage your current ambassadors to join you on Llama, you need to have an active primary offer." ctaText="Create an Offer" ctaAction={() => props.history.push('/offer-new')}  />
        )
    }

    if(!props.advertiser.branded_sign_up.offer){
        return (
            <EmptyState message="You haven’t selected a primary offer" paragraph="To build trust and encourage your current ambassadors to join you on Llama, you need to select a primary offer." ctaText="Customize Invitation" ctaAction={() => props.history.push('/invite')}  />
        )
    }

    if(!props.advertiser.branded_sign_up.banner_image){
        return (
            <EmptyState message="You haven’t uploaded a banner for your primary offer" paragraph="To build trust and encourage your current ambassadors to join you on Llama, you need to upload a banner that reflects your brand." ctaText="Customize Invitation" ctaAction={() => props.history.push('/invite')}  />
        )
    }

    if(!props.advertiser.avatar_image){
        return (
            <EmptyState message="You haven’t uploaded your store logo" paragraph="To build trust and encourage your current ambassadors to join you on Llama, you need to upload a logo." ctaText="Upload Your Logo in Settings" ctaAction={() => props.history.push('/settings')}  />
        )
    }

    return (
        <React.Fragment>
            <div className="import-affiliates__wrapper">
                <TextStyle variation="subdued"><span className="subtitle">
                    Ready to migrate your current ambassadors into Llama? Let us know which platform you&rsquo;re using and we&rsquo;ll guide you
                    through the process of inviting them. <strong>Please note: once they&rsquo;ve signed up they&rsquo;ll be automatically approved to run ALL of your offers.</strong></span>
                </TextStyle>
            </div>
            <div className="import-affiliates__navigation">
                <div
                onClick={() => setMigrationType("refersion")} 
                className={migrationType === "refersion" ? "import-affiliate__migration-type import-affiliate__migration-type--active" : "import-affiliate__migration-type"}>
                    <img src={refersionLogo} className="import-affiliate__refersion-logo" />
                </div>
                <div 
                onClick={() => setMigrationType("lead")} 
                className={migrationType === "lead" ? "import-affiliate__migration-type import-affiliate__migration-type--active" : "import-affiliate__migration-type"}>
                    <img src={leadDynoLogo} className="import-affiliate__leaddyno-logo" />
                </div>
                <div 
                onClick={() => setMigrationType("affiliatly")}
                className={migrationType === "affiliatly" ? "import-affiliate__migration-type import-affiliate__migration-type--active" : "import-affiliate__migration-type"}>
                    <img src={affiliatlyLogo} className="import-affiliate__affiliatly-logo" />
                </div>
                <div 
                onClick={() => setMigrationType("other")}
                className={migrationType === "other" ? "import-affiliate__migration-type import-affiliate__migration-type--active" : "import-affiliate__migration-type"}>
                    Other
                </div>
            </div>
            {migrationType === "refersion" &&
                <RenderRefersion />
            }
            {migrationType === "lead" &&
                <RenderLead />
            }
            {migrationType === "affiliatly" &&
                <RenderAffiliatly />
            }
            {migrationType === "other" &&
                <RenderOther />
            }
            <DropZone 
                allowMultiple
                accept="text/csv"
                errorOverlayText="File type must be .csv"
                onDrop={props.handleFiles}
                openFileDialog={false}
                onDropAccepted={props.handleUpload}
            >
                {fileUpload}
            </DropZone>
        </React.Fragment>
    )
}

export default ImportAffiliates;