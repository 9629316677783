import React, { useState } from 'react';
import { Badge, Popover, ActionList } from '@shopify/polaris';

import SocialIcons from '../social-icons';
import llamaPlaceholder from '../../assets/llama-user-icon.png';
import { updateFavoriteStatus, changeApplicationStatus } from '../../actions/applications';
import { generateTrackingLink } from '../../actions/generate-link';
import { addToAutoSmartLinks } from '../../actions/add-to-auto-smartlinks';
import formatMetrics from '../../utils/format-metrics';
import formatPercent from '../../utils/format-percent';
import {moneyFormat} from '../../utils/number-format';
import { formatAudience } from '../../utils/format-audience';
import formatCountries from '../../utils/format-countries';
import formatOfferName from '../../utils/format-offer-name';
import moreIcon from '../../assets/more-icon.svg';

import { LlamaButton } from 'llama-library/components';

const StatusBadges = {
    'APPROVED': <Badge status="success">Approved</Badge>,
    'PENDING': <Badge status="attention">Pending</Badge>,
    'DENIED': <Badge status="warning">Denied</Badge>,
}

/**
 * @function AmbassadorApplication
 * Accepts following params, returns AmbassadorApplication Component.
 * Displays the current applications from ambassadors, which contain
 * a quick view that displays tracking data and basic ambassador info.
 *
 * @param {Object} data - data Object
 * @param {Function} updateApplication - updateApplication method
 * @param {Object} history - history Object
 *
 * @returns {FunctionComponent}
 */
const AmbassadorApplication = ({ data, updateApplication, history, setToastVerbiage }) => {

    const [favoriteChangeLoading, setFavoriteChangeLoading] = useState(false);
    const [approvalLoading, setApprovalLoading] = useState(false);
    const [declineLoading, setDeclineLoading] = useState(false);
    const [displayPopover, setDisplayPopover] = useState(false);

    if (!data || !data.affiliate || !data.affiliate.account) {
        return null;
    }

    const socialIcons = <SocialIcons affiliate={data.affiliate} />

    const { tracking } = data.affiliate;
    const clicks = tracking && tracking.Stat ? formatMetrics(tracking.Stat.clicks) : 0;
    const epc = tracking && tracking.Stat ? moneyFormat(tracking.Stat.epc, true) : '$0'
    const conversions = tracking && tracking.Stat && tracking.Stat.conversions
        ? tracking.Stat.conversions
        : 0;
    const conversionPercent = formatPercent(conversions / clicks, true);

    let affiliateInfo;

    if(data.offer.offer_type === 'product_giveaway'){
        affiliateInfo = {
            reach: formatMetrics(data.reach),
            audience_country: formatCountries(data.affiliate.account),
            marketing_platforms: socialIcons,
            audience: formatAudience(data.affiliate.account),
        }
    }
    else{
        affiliateInfo = {
            reach: formatMetrics(data.reach),
            audience_country: formatCountries(data.affiliate.account),
            marketing_platforms: socialIcons,
            audience: formatAudience(data.affiliate.account),
            conversion_rate: conversionPercent ? conversionPercent : 0,
            epc
        }
    }

    const renderInfo = ([key, value]) => {
        return <div className="ambassadors-application__info-item" key={key}>
            <div className="ambassadors-application__info-key">{key.replace('_', ' ')}</div>
            <div className="ambassadors-application__info-value">{value}</div>
        </div>
    }


    // Button Actions
    const toggleFavoriteStatus = () => {
        setFavoriteChangeLoading(true);
        return updateFavoriteStatus(data.application_id, data.advertiser_id, !data.advertiser_favorite)
            .then((result) => updateApplication(result))
            .then(() => {
                // if the advertiser added them to favorites
                if (!data.advertiser_favorite) {
                    setToastVerbiage(`${data.affiliate.name} has been added to your Shortlist`);
                } else {
                    setToastVerbiage(`${data.affiliate.name} has been removed from your Shortlist`);
                }
                setFavoriteChangeLoading(false);
            })
            .catch(() => {
                if (!data.advertiser_favorite) {
                    setToastVerbiage(`Oops, something went wrong. ${data.affiliate.name} could not be added to your Shortlist`);
                } else {
                    setToastVerbiage(`Oops, something went wrong. ${data.affiliate.name} could not be removed from your Shortlist`);
                }
                setFavoriteChangeLoading(false);
            });
    };

    const viewAffiliate = () => {
        history.push(`/affiliate/${data.affiliate._id}`)
    }

    const updateApplicationStatus = (status, setLoading) => {
        setLoading(true);

        return generateTrackingLink(data.offer.offer_id, data.affiliate.tracking_id).then(result => {
            return result.generateTrackingLink.click_url
        }).then(tracking_link => {
            changeApplicationStatus(data.application_id, status, data.affiliate_status, tracking_link)
                .then((result) => {
                    if (!Array.isArray(result)) {
                        return null;
                    }

                    result.forEach((item) => updateApplication(item));
                })
                .then(() => {
                    if (status === 'APPROVED' && data.offer_type !== 'product_giveaway') {
                        const offerWithoutType = { ...data.offer };
                        delete offerWithoutType.offer_type;
                        return addToAutoSmartLinks(data.affiliate.cognito_id, offerWithoutType, tracking_link)
                            .then((result) => { return result; });
                    }
                    return null;
                })
                .then(() => {
                    if (status === 'APPROVED') {
                        setToastVerbiage(`${data.affiliate.name} is now approved`);
                    } else if (status === 'DENIED') {
                        setToastVerbiage(`${data.affiliate.name} is now denied`);
                    }
                    setLoading(false);
                })
                .catch(() => {
                    if (status === 'APPROVED') {
                        setToastVerbiage(`Oops something went wrong. ${data.affiliate.name} could not be approved`);
                    } else if (status === 'DENIED') {
                        setToastVerbiage(`Oops something went wrong. ${data.affiliate.name} could not be denied`);
                    }
                    setLoading(false);
                });
        })
    }

    const approveApplication = () => updateApplicationStatus("APPROVED", setApprovalLoading);
    const declineApplication = () => updateApplicationStatus("DENIED", setDeclineLoading);


    // Button Classes
    const buttonClasses = ["ambassadors-application__button"]

    const favoriteButtonClasses = buttonClasses.slice();
    data.advertiser_favorite
        ? favoriteButtonClasses.push("ambassadors-application__fav-button", "ambassadors-application__fav-button--active")
        : favoriteButtonClasses.push("ambassadors-application__fav-button");

    const approveButtonClasses = buttonClasses.slice();
    approveButtonClasses.push("ambassadors-application__approve-button");

    const declineButtonClasses = buttonClasses.slice();
    declineButtonClasses.push("ambassadors-application__decline-button");

    const affiliateButtonClass = buttonClasses.slice();
    affiliateButtonClass.push("ambassadors-application__affiliate-button");


    // Edit popover.
    const togglePopover = () => setDisplayPopover(!displayPopover);

    const actionList = [];
    if (data.advertiser_status !== 'APPROVED') {
        actionList.push({ content: 'Approve', onAction: approveApplication });
    }

    if (data.advertiser_status !== 'DENIED') {
        actionList.push({ content: 'Decline', onAction: declineApplication });
    }

    return (
        <div className="ambassadors-application" data-test="component-ambassadorApplication">
            <div className="ambassadors-application__wrapper">
                <div className="ambassadors-application__left">
                    <img src={data.affiliate.photo ? data.affiliate.photo : llamaPlaceholder} alt="profile photo" className="ambassadors-application__profile-image" />
                    <p className="ambassadors-application__name" onClick={viewAffiliate}>{data.affiliate.name}</p>
                    <div className="ambassadors-application__button-wrapper">
                        <LlamaButton
                            loading={false}
                            onClick={viewAffiliate}
                            classes={affiliateButtonClass}
                        >
                            View Profile
                        </LlamaButton>
                    </div>
                    {/* <div className="ambassadors-application__rating">
                        <div className="ambassadors-application__star"></div>
                        <div className="ambassadors-application__star"></div>
                        <div className="ambassadors-application__star"></div>
                        <div className="ambassadors-application__star"></div>
                        <div className="ambassadors-application__star"></div>
                    </div>
                    <p className="ambassadors-application__rating-label">RATING</p> */}
                </div>
                <div className="ambassadors-application__right">
                    <div className="ambassadors-application__top">
                        <div className="ambassadors-application__info-item">
                            <div className="ambassadors-application__info-key">Application for:</div>
                            <div className="ambassadors-application__offer">
                                {formatOfferName(data.offer)}
                            </div>
                        </div>
                        {StatusBadges[`${data.advertiser_status}`]}
                    </div>
                    <div className="ambassadors-application__info">
                        {Object.entries(affiliateInfo).map(renderInfo)}
                    </div>
                    <div className="ambassadors-application__buttons">
                        <div className="ambassadors-application__button-group">
                            <LlamaButton
                                inverted background={"#FD2856"}
                                loading={favoriteChangeLoading}
                                onClick={toggleFavoriteStatus}
                            >
                                {data.advertiser_favorite ? 'Remove from' : 'Add to'} Shortlist
                            </LlamaButton>
                        </div>
                        <div className="ambassadors-application__button-group">
                            {
                                data.advertiser_status === 'PENDING'
                                    ?
                                    <React.Fragment>
                                        <LlamaButton
                                            loading={approvalLoading}
                                            onClick={approveApplication}
                                            classes={approveButtonClasses}
                                        >
                                            Approve
                                        </LlamaButton>
                                        <LlamaButton
                                            loading={declineLoading}
                                            onClick={declineApplication}
                                            classes={declineButtonClasses}
                                        >
                                            Deny
                                        </LlamaButton>
                                    </React.Fragment>
                                    : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default AmbassadorApplication;