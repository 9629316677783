import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { LlamaToast } from 'llama-library/components';

import { getBillingInfo, updateSubscription, cancelSubscription } from '../../actions/offer-boost-billing';
import { getBoostedOfferNames } from '../../actions/offer-boost';

import LlamaCreditCardDetails from '../llama/credit-card-details';
import LlamaBillingHistory from '../llama/billing-history';

import formatOfferName from '../../utils/format-offer-name';

const OfferBoostBilling = ({ advertiser, history }) => {
    const [cardData, setCardData] = useState(null);
    const [invoices, setInvoices] = useState(null);
    const [boostedOffers, setBoostedOffers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [toastVerbiage, setToastVerbiage] = useState(null);

    useEffect(() => {
        getBillingInfo(advertiser.advertiser_id)
            .then((result) => {
                if (result && result.data && result.data.getOfferBoostBillingInfo && result.data.getOfferBoostBillingInfo.card_data) {
                    setCardData(result.data.getOfferBoostBillingInfo.card_data);
                }
                if (result && result.data && result.data.getOfferBoostBillingInfo && result.data.getOfferBoostBillingInfo.invoiceGroups) {
                    setInvoices(result.data.getOfferBoostBillingInfo.invoiceGroups);
                }
                setLoading(false);
            });

        getBoostedOfferNames(advertiser.advertiser_id)
            .then((result) => {
                console.log(result);
                if (result && result.data && result.data.offers && Array.isArray(result.data.offers) && result.data.offers.length > 0) {
                    const offersWithBoost = result.data.offers.filter((offer) => {
                        return offer.sponsored === 'ACTIVE';
                    });
                    setBoostedOffers(offersWithBoost);
                }
            });
    }, []);

    const deleteCard = () => {
        return cancelSubscription(advertiser.advertiser_id)
            .then((result) => {
                if (result.data && result.data.cancelOfferBoostSubscription) {
                    setToastVerbiage('Payment method deleted successfully.');
                    return { success: true, cardData: null };
                }
                if (result.errors && result.errors[0] && result.errors[0].message) {
                    return { success: false, error: result.errors[0].message };
                }
            });
    };

    const updateCard = (tokenData, cardholderName) => {
        return updateSubscription(advertiser.advertiser_id, tokenData.id, cardholderName)
            .then((result) => {
                if (result.data && result.data.updateOfferBoostSubscription) {
                    setToastVerbiage('Payment method updated successfully.');
                    if (history.location && history.location.state && history.location.state.fromBoost && history.location.state.offerId) {
                        history.push(`/offer/${history.location.state.offerId}/boost`, {
                            fromSettings: true,
                            launchBidModal: true
                        });
                    }
                    return { success: true, cardData: result.data.updateOfferBoostSubscription };
                }
                if (result.errors && result.errors[0] && result.errors[0].message) {
                    return { success: false, error: result.errors[0].message };
                }
            });
    };

    const deleteConfirmation = (
        <>
            <p>Deleting your payment method will stop all future charges until you add a new payment method. {boostedOffers.length > 0 && 'The following offers will no longer be boosted:'}</p>
            {boostedOffers.length > 0
                && (
                    <ul>
                        {boostedOffers.map((offer) => {
                            return <li key={offer.name}>{formatOfferName(offer)}</li>;
                        })}
                    </ul>
                )
            }
        </>
    );

    return (
        <div>
            <LlamaCreditCardDetails
                loading={loading}
                cardData={cardData}
                updateCard={updateCard}
                deleteCard={deleteCard}
                deleteConfirmation={deleteConfirmation}
            />
            {(loading || (invoices && invoices.length > 0))
                && <h3>Billing History</h3>
            }
            <LlamaBillingHistory
                invoiceGroups={invoices}
                loading={loading}
                perPage={5}
                showEmptyState={false}
            />
            <LlamaToast toastVerbiage={toastVerbiage} setToastVerbiage={setToastVerbiage} />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        advertiser: state.advertiser
    };
};

OfferBoostBilling.propTypes = {
    advertiser: PropTypes.shape({
        advertiser_id: PropTypes.string
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func,
        location: PropTypes.shape({
            state: PropTypes.shape({
                fromBoost: PropTypes.bool,
                offerId: PropTypes.string
            })
        })
    }).isRequired
};

export default connect(mapStateToProps)(OfferBoostBilling);
