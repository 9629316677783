import React, {Component} from 'react';
import {connect} from 'react-redux';
import './payments.css';
import {
    Spinner
} from '@shopify/polaris';
import axios from 'axios';

import {withRouter} from "react-router";
import {getPaypalAuthUrl} from '../../actions/payment-url';
import {isEqual} from 'lodash';
import {createAuthToken} from '../../actions/payment-authToken';
import {updateAdvertiserPayments} from '../../actions/update-advertiser-payments';
import {getAdvertiser} from '../../actions/advertiser';
import { PAYPAL_IDENTITY_URL } from '../../config'

/**
 * @class Payment
 * Holds state for merchant paypal data.
 * Wraps paypal Auth functions.
 * Renders Polaris Card Section with Paypal login
 * or message with Paypal user data depending on state of Login.
 *
 * @returns {ComponentClass}
 */
const qs = require('querystring');

class Payment extends Component {
    constructor(props) {
        super(props);

        this.state = {
            savedAdvertiser: props.advertiser,
            advertiser_id: '',
            paypalClicked: false,
            connectedToPaypal: false,
            paypalEmail: null,
            authUrl: '',
            code: '',
            loggedIn: false
        };

        this.paypalLogin = this.paypalLogin.bind(this);

        if (this.props.location.pathname === "/settings/payment" || 
            this.props.location.pathname === "/offer-new" || 
            this.props.location.pathname === "/signUp/5") {
            this.props.getPaypalAuthUrl().then((result) => {
                this.setState({authUrl: result})
            });
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps.advertiser && !isEqual(nextProps.advertiser !== prevState.savedAdvertiser)) {
            return {
                savedAdvertiser: nextProps.advertiser
            };
        }

        return null;
    }

    componentDidUpdate(prevProps) {
        // check if paypal error flag is set in localstorage by paypal login window.
        if(localStorage.getItem(`payment-status-${this.props.authData.username}`) && 
        localStorage.getItem(`payment-status-${this.props.authData.username}`) === 'failed') {
            // trigger the paypal error banner
            this.props.togglePayPalError(true);
            // remove the error flag as we have notified the component to show the error banner
            localStorage.removeItem(`payment-status-${this.props.authData.username}`);
        }

        if(!isEqual(prevProps.advertiser, this.props.advertiser)) {
            this.getPaypalEmail();
            sessionStorage.setItem('username', this.props.advertiser.advertiser_id);
            sessionStorage.setItem('store', this.props.advertiser.myshopify_domain);
        }
    }

    componentDidMount() {
        if (this.props.advertiser.myshopify_domain) {
            this.props.getAdvertiser(this.props.advertiser.myshopify_domain);
            this.getPaypalEmail();
            sessionStorage.setItem('username', this.props.advertiser.advertiser_id);
            sessionStorage.setItem('store', this.props.advertiser.myshopify_domain);
        }
    }

    getAdvertiserPayPalEmail = async (access_token) => {
        return await axios({
            url: PAYPAL_IDENTITY_URL,
            headers: { 'Authorization': 'Bearer ' + access_token, 'Content-Type': 'application/json' }
        }).then((resp) => {
            return resp.data.email ? resp.data.email : null;
        }).catch((error) => {
            console.log('Error: ', error);
            return null;
        })
    }

    paypalLogin() {
        var l = (window.screen.width / 2) - 200;
        var t = (window.screen.height / 2) - 275;

        //change the client_id and make sure the redirect_uri is defined in your paypal app settings
        var paypal_auth = window.open(this.state.authUrl.value.url, 'Login with Paypal', 'width=600,height=750,left=' + l + ',top=' + t);
        this.setState({
            paypalClicked: true
        })

        var checkWindow = setInterval(() => {
            if (paypal_auth.closed) {
                clearInterval(checkWindow);
                this.props.getAdvertiser(sessionStorage.getItem('store'))
                .then((response) => {
                    console.log('RESPONSE::', response)
                    this.setState({
                        ...this.state,
                        savedAdvertiser: response.value.data.advertisers[0]
                    });
                    this.getPaypalEmail();
                })
            }
        }, 1000)
    }

    getPaypalEmail() {
        //connect to db here - function should return whether they have a connected paypal and their email address
        if (this.state.savedAdvertiser && this.state.savedAdvertiser.paypal && this.state.savedAdvertiser.paypal.refresh) {
            this.setState({
                connectedToPaypal: true,
                paypalEmail: this.state.savedAdvertiser.paypal.user
            }, () => {
                if(this.props.fromOnboarding) {
                    // Remove the paypal error banner if present in previous try of paypal login
                    this.props.togglePayPalError(false);
                }
            })
            if(this.props.callback) this.props.callback();
        } else {
            this.setState({
                connectedToPaypal: false,
                paypalEmail: null,
                paypalClicked: false
            })
        }
        console.log("GET PAYPAL EMAIL CALLED", this.state);
    }

    revokePaypal(e) {

        e.preventDefault();
        let payload = {
            paypal: {
                refresh: null,
                user: null,
            }
        };

        this.props.updateAdvertiserPayments(payload, sessionStorage.getItem('username')).then((res) => {
            this.setState({
                connectedToPaypal: false,
                paypalEmail: null,
                paypalClicked: false
            })
        })

    }

    render() {

        const onboardingClass = (this.props.fromOnboarding)
            ? {className: 'onboarding--paypal-connected'}
            : {}
        return (
            <>
                {!this.state.connectedToPaypal ?
                    <p className="paypal-button">{!this.state.paypalClicked ? <img
                        src="https://www.paypalobjects.com/webstatic/en_US/developer/docs/lipp/loginwithpaypalbutton.png"
                        alt="" onClick={this.paypalLogin.bind(this)} /> : <Spinner size="small" />}</p>
                    : <p {...onboardingClass}>Connected to PayPal as {this.state.savedAdvertiser.paypal.user} </p>
                }
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        advertiser: state.advertiser,
        authData: state.authData,
        payment: state.payment,
        ...state
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAdvertiser: (shop) => dispatch(getAdvertiser(shop)),
        getPaypalAuthUrl: () => dispatch(getPaypalAuthUrl()),
        updateAdvertiserPayments: (payload, advertiser) => dispatch(updateAdvertiserPayments(payload, advertiser)),
        createAuthToken: (code) => dispatch(createAuthToken(code))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Payment));