import React from 'react';
import { Spinner } from '@shopify/polaris';

/**
 * @function ApplicationButton
 * Accepts the following params, returns ApplicationButton Component.
 * Displays button.
 * 
 * @param {Array} classes - default param empty array
 * @param {Event} onClick
 * @param {Boolean} loading
 * @param {Array} children
 * 
 * @returns {FunctionComponent}
 */
const ApplicationButton = ({ classes = [], onClick, loading, children }) => {

    return (
        <div
            className={classes.join(' ')}
            onClick={onClick}
            style={{ opacity: loading ? 0.7 : 1 }}
        >
            {
                loading
                && <div className="ambassadors-application__button-spinner"><Spinner color="inkLightest" /></div>
            }
            <div style={{ opacity: loading ? 0 : 1 }}>{children}</div>
        </div>
    )

}

export default ApplicationButton;