import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { Popover, ActionList } from '@shopify/polaris';

import logo from '../../assets/logo.svg';
import userlogo from '../../assets/llama-user-icon.png';
import notification from '../../assets/notification-icon.svg';
import settingsIcon from '../../assets/settings-icon.svg';
import helpIcon from '../../assets/help-icon.svg';
import signoutIcon from '../../assets/signout-icon.svg';
import './navigation-sidebar.css';

/**
 * @function NavigationSidebar
 * Accepts props Object, returns NavigationSidebar Component.
 * Displays the navigation menu for the application.
 *
 * @param {Object} props
 *
 * @returns {FunctionComponent}
 */
const NavigationSidebar = (props) => {
    const [unreadChats, setUnreadChats] = useState(0);
    // Help nav state management.
    const [openHelpNav, setOpenHelpNav] = useState(false);
    const helpNavElement = useRef(null);

    // Help nav mobile
    const [openMobileHelp, setOpenMobileHelp] = useState(false);

    // Click handler for outside events.
    const helpNavClickHandler = (e) => {
        if (helpNavElement.current.contains(e.target)) {
            return;
        }
        toggleHelpNav(false);
    };

    // Open and close the help nav, sets up 'click' event listeners to see
    // if a click happens outside of the help nav element.
    const toggleHelpNav = useCallback((event) => {
        if (openHelpNav || !event) {
            document.removeEventListener('click', helpNavClickHandler, false);
            setOpenHelpNav(false);
        } else {
            document.addEventListener('click', helpNavClickHandler, false);
            setOpenHelpNav(true);
        }
    }, []);

    // Clean up click handler if component is unmounted.
    useEffect(() => {
        return () => {
            toggleHelpNav(false);
        };
    }, []);

    // get total number of unread chats
    useEffect(() => {
        console.log('UPDATING URNREAD CHATS...', props.unreadChatMessages);
        if (props.unreadChatMessages.length > 0) {
            console.log('hiii');
            const totalUnread = props.unreadChatMessages.reduce((acc, message) => {
                if (message?.message_ids) {
                    return acc + message.message_ids.length;
                }
                return acc;
            }, 0);
            console.log(totalUnread);
            setUnreadChats(totalUnread);
        } else {
            setUnreadChats(0);
        }
    }, [props.unreadChatMessages]);

    console.log('UNREAD CHAT MESSAGE PROPS', props.unreadChatMessages);

    // 'Start tutorial' click handler, opens chameleon.
    // const startTutorial = () => {
    //     setTimeout(() => toggleHelpNav(false));
    //     window.chmln.show('5c6da728a802ae000496535b', {open: true})
    // }

    const activator = (
        <div onClick={props.togglePopover} className="NavigationSidebar__Popover-Activator">
            <img src={userlogo} className="NavigationSidebar__User-Mobile" />
        </div>
    );

    const { signOut, openTour } = props;

    return (
        <>
            <div className={props.isMobile === true ? 'NavigationSidebar__Main NavigationSidebar__Main--open' : 'NavigationSidebar__Main'}>
                <div className="NavigationSidebar__Nav-Flex">
                    <div className="NavigationSidebar__Nav-Top">
                        <div className="NavigationSidebar__Logo-Wrapper">
                            <img src={logo} className="NavigationSidebar__Logo" />
                            <div className="NavigationSidebar__Mobile-Menu">
                                <div className="NavigationSidebar__Ham-Wrapper">
                                    <div className="NavigationSidebar__Hamburger" onClick={props.toggleMobile}>
                                        <div className={props.isMobile === true ? 'NavigationSidebar__Ham-Ingredient NavigationSidebar__Ham-Ingredient--close01' : 'NavigationSidebar__Ham-Ingredient'} />
                                        <div className={props.isMobile === true ? 'NavigationSidebar__Ham-Ingredient NavigationSidebar__Ham-Ingredient--open' : 'NavigationSidebar__Ham-Ingredient'} />
                                        <div className={props.isMobile === true ? 'NavigationSidebar__Ham-Ingredient NavigationSidebar__Ham-Ingredient--close02' : 'NavigationSidebar__Ham-Ingredient'} />
                                    </div>
                                </div>
                                <div className="NavigationSidebar__Logo-Wrapper-Mobile">
                                    <img src={logo} className="NavigationSidebar__Logo-Mobile" />
                                </div>
                                <div className="NavigationSidebar__Popover-Wrapper">
                                    {/* <NavLink to="/alerts" exact> */}
                                    <img src={notification} onClick={props.sideDrawerToggleHandler} className="NavigationSidebar__Notification-Logo" />
                                    {/* </NavLink> */}
                                    <Popover
                                        active={props.popoverActive}
                                        activator={activator}
                                        onClose={props.togglePopover}
                                        preferredAlignment="right"
                                        preferredPosition="mostSpace"
                                    >
                                        <ActionList items={[{ content: 'Sign Out', onAction: signOut }]} />
                                    </Popover>
                                </div>
                            </div>
                        </div>

                        <div className="NavigationSidebar__Profile">
                            <div className="NavigationSidebar__Section-Header">
                                <h1 className="NavigationSidebar__h1">
                                    Profile
                                </h1>
                            </div>
                            <div className="NavigationSidebar__Section-Links">
                                <ul className="NavigationSidebar__Links-List">
                                    <li data-tour="step-dashboard">
                                        <NavLink to="/" onClick={props.toggleMobile} className="NavigationSidebar__Link" activeClassName="NavigationSidebar__active" exact>
                                            Dashboard
                                        </NavLink>
                                    </li>
                                    <li data-tour="step-products">
                                        <NavLink to="/products" onClick={props.toggleMobile} className="NavigationSidebar__Link" activeClassName="NavigationSidebar__active" exact>
                                            Products
                                        </NavLink>
                                    </li>
                                    <li data-tour="step-tour-offers">
                                        <NavLink to="/offers" onClick={props.toggleMobile} className="NavigationSidebar__Link" activeClassName="NavigationSidebar__active">
                                            Offers
                                        </NavLink>
                                    </li>

                                    <li data-tour="step-tour-invoices">
                                        <NavLink
                                            to="/invoices"
                                            className="NavigationSidebar__Link"
                                            onClick={props.toggleMobile}
                                            activeClassName="NavigationSidebar__active"
                                        >
                                            Invoices
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="NavigationSidebar__Discover">
                            <div className="NavigationSidebar__Section-Header">
                                <h1 className="NavigationSidebar__h1">
                                    Ambassadors
                                </h1>
                            </div>
                            <div className="NavigationSidebar__Section-Links">
                                <ul className="NavigationSidebar__Links-List">

                                    <NavLink
                                        to="/performance"
                                        onClick={props.toggleMobile}
                                        className="NavigationSidebar__Link"
                                        activeClassName="NavigationSidebar__active"
                                        data-tour="step-tour-performance"
                                    >
                                        Performance
                                    </NavLink>

                                    <NavLink
                                        to="/applications"
                                        onClick={props.toggleMobile}
                                        className="NavigationSidebar__Link"
                                        activeClassName="NavigationSidebar__active"
                                        data-tour="step-tour-applications"
                                    >
                                        Applications
                                    </NavLink>

                                    <NavLink
                                        to="/discover"
                                        onClick={props.toggleMobile}
                                        className="NavigationSidebar__Link"
                                        activeClassName="NavigationSidebar__active"
                                        data-tour="step-tour-discover"
                                    >
                                        Discover
                                    </NavLink>

                                    <NavLink
                                        to="/chat"
                                        className="NavigationSidebar__Link"
                                        activeClassName="NavigationSidebar__active"
                                    >
                                        Chat {unreadChats > 0 && <span className="alert">{unreadChats}</span>}
                                    </NavLink>

                                    <NavLink
                                        to="/invite"
                                        className="NavigationSidebar__Link"
                                        onClick={props.toggleMobile}
                                        activeClassName="NavigationSidebar__active"
                                        data-tour="step-tour-invite"
                                    >
                                        Invite Ambassadors
                                    </NavLink>

                                    <NavLink
                                        to="/import-ambassadors"
                                        onClick={props.toggleMobile}
                                        className="NavigationSidebar__Link"
                                        activeClassName="NavigationSidebar__active"
                                        data-tour="step-tour-import"
                                    >
                                        Migrate Ambassadors
                                    </NavLink>

                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="NavigationSidebar__Nav-Bottom">
                        <div className="NavigationSidebar__Settings">
                            <div className="NavigationSidebar__Settings-Link">

                                {/* Only display for Mobile */}
                                <div className="NavigationSidebar__HelpMobile">
                                    <div className="NavigationSidebar__Link" onClick={() => { return setOpenMobileHelp(!openMobileHelp); }}>
                                        <span className="NavigationSidebar__Gear-Flex"><img src={helpIcon} className="NavigationSidebar__Link-Icon" /> Help/Policies</span>
                                    </div>
                                    {openMobileHelp
                                        && (
                                            <>
                                                <div className="NavigationSidebar__Link NavigationSidebar__Link--SubMenu" onClick={openTour}>Start Tour</div>
                                                <NavLink to="/terms" onClick={props.toggleMobile} className="NavigationSidebar__Link NavigationSidebar__Link--SubMenu" activeClassName="NavigationSidebar__active">
                                                    Terms of Service
                                                </NavLink>
                                                <NavLink to="/privacy" onClick={props.toggleMobile} className="NavigationSidebar__Link NavigationSidebar__Link--SubMenu" activeClassName="NavigationSidebar__active">
                                                    Privacy Policy
                                                </NavLink>
                                                <NavLink to="/dmca" onClick={props.toggleMobile} className="NavigationSidebar__Link NavigationSidebar__Link--SubMenu" activeClassName="NavigationSidebar__active">
                                                    DMCA
                                                </NavLink>
                                                <NavLink to="/gdpr" onClick={props.toggleMobile} className="NavigationSidebar__Link NavigationSidebar__Link--SubMenu" activeClassName="NavigationSidebar__active">
                                                    GDPR
                                                </NavLink>
                                            </>
                                        )}
                                </div>

                                <NavLink
                                    to="/settings"
                                    onClick={props.toggleMobile}
                                    className="NavigationSidebar__Link"
                                    activeClassName="NavigationSidebar__active"
                                    data-tour="step-tour-settings"
                                    exact
                                >
                                    <span className="NavigationSidebar__Gear-Flex"><img src={settingsIcon} className="NavigationSidebar__Link-Icon" /> Settings</span>
                                </NavLink>

                                {/* Only display for desktop */}
                                <div className="NavigationSidebar__HelpDesktop" data-tour="step-tour-help">
                                    <div className={openHelpNav ? 'NavigationSidebar__Link NavigationSidebar__active' : 'NavigationSidebar__Link'} onClick={() => { return toggleHelpNav(!openHelpNav); }} ref={helpNavElement}>
                                        <span onClick={() => { return setOpenHelpNav(!openHelpNav); }} className="NavigationSidebar__Gear-Flex"><img src={helpIcon} className="NavigationSidebar__Link-Icon" /> Help/Policies</span>
                                        {openHelpNav
                                && (
                                    <div className="NavigationSidebar__Popout">
                                        <div className="NavigationSidebar__PopoutArrow" />
                                        <div className="NavigationSidebar__PopoutItem" onClick={openTour}>Start Tour</div>
                                        <NavLink to="/terms" className="NavigationSidebar__PopoutItemLink">
                                            <div onClick={() => { return toggleHelpNav(false); }} className="NavigationSidebar__PopoutItem">Terms of Service</div>
                                        </NavLink>
                                        <NavLink to="/privacy" className="NavigationSidebar__PopoutItemLink">
                                            <div onClick={() => { return toggleHelpNav(false); }} className="NavigationSidebar__PopoutItem">Privacy Policy</div>
                                        </NavLink>
                                        <NavLink to="/dmca" className="NavigationSidebar__PopoutItemLink">
                                            <div onClick={() => { return toggleHelpNav(false); }} className="NavigationSidebar__PopoutItem">DMCA</div>
                                        </NavLink>
                                        <NavLink to="/gdpr" className="NavigationSidebar__PopoutItemLink">
                                            <div onClick={() => { return toggleHelpNav(false); }} className="NavigationSidebar__PopoutItem">GDPR</div>
                                        </NavLink>
                                    </div>
                                )}
                                    </div>
                                </div>

                                <div className="NavigationSidebar__Link">
                                    <span onClick={signOut} className="NavigationSidebar__Gear-Flex"><img src={signoutIcon} className="NavigationSidebar__Link-Icon" /> Sign Out</span>
                                </div>
                            </div>
                        </div>

                    </div>
                    {/* End NavigationSidebar__Nav-Botton */}

                </div>
                {/* End NavigationSidebar__Nav-Flex */}

            </div>
            {/* ----End NavigationSidebar__Main----*/}
            <div className="NavigationSidebar__Main NavigationSidebar__FixedSpacer" />
        </>
    );
};

NavigationSidebar.propTypes = {
    unreadChatMessages: PropTypes.arrayOf(PropTypes.shape({
        sender_id: PropTypes.string,
        message_ids: PropTypes.arrayOf(PropTypes.string)
    })).isRequired,
    togglePopover: PropTypes.func.isRequired,
    isMobile: PropTypes.bool.isRequired,
    toggleMobile: PropTypes.func.isRequired,
    sideDrawerToggleHandler: PropTypes.func.isRequired,
    popoverActive: PropTypes.bool.isRequired,
    signOut: PropTypes.func.isRequired,
    openTour: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
    return {
        unreadChatMessages: state.chat.unreadChatMessages
    }
};

export default connect(mapStateToProps)(NavigationSidebar);
