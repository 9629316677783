import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Tooltip } from '@shopify/polaris';
import { EmptyState } from 'llama-library/components';
import { defaultBanners, numberFormat, moneyFormat, percentFormat } from 'llama-library/utils';

import getBoostedOffers from '../../actions/offer-boost';

import DateComparisonGraph from '../offer-boost/date-comparison-graph';

import useResponsive from '../../hooks/use-responsive';
import { RateTypes } from '../../utils/types';
import formatOfferName from '../../utils/format-offer-name';
import './offers-boosted.css';

const BoostedOffers = ({ advertiser, history }) => {
    const mobileWidth = 1180;

    const [boostedOffers, setBoostedOffers] = useState([]);
    const [boostedOffersLoading, setBoostedOffersLoading] = useState(true);
    const [boostHistory, setBoostHistory] = useState([]);
    const [boostTotals, setBoostTotals] = useState([]);

    const pageWidth = useResponsive();
    console.log(advertiser);

    useEffect(() => {
        getBoostedOffers(advertiser.advertiser_id)
            .then((result) => {
                if (result && result.data && result.data.offers) {
                    const offersWithBoost = result.data.offers.filter((offer) => {
                        return offer.sponsored && offer.sponsored !== 'OFF';
                    });
                    setBoostedOffers(offersWithBoost);

                    // merge stats
                    const combinedBoostHistory = {
                        applicants: [],
                        charges: [],
                        clicks: [],
                        impressions: [],
                        uniqueImpressions: [],
                        uniqueImpressionsPerDay: []
                    };

                    const metricTotals = {
                        applicants: 0,
                        charges: 0,
                        clicks: 0,
                        impressions: 0,
                        uniqueImpressions: 0
                    };

                    // the push furthur down was also mutating the original offersWithBoost array, so i made a copy
                    const offersWithBoostCopy = JSON.parse(JSON.stringify(offersWithBoost));

                    offersWithBoostCopy.forEach((offer) => {
                        console.log(offer.offerBoostHistory);
                        Object.entries(offer.offerBoostHistory).forEach(([key, metrics]) => {
                            if (Array.isArray(metrics)) {
                                metrics.forEach((metric) => {
                                    const matchingIndex = combinedBoostHistory[key].findIndex((historyItem) => {
                                        return historyItem.date === metric.date;
                                    });

                                    console.log(matchingIndex, key, metric);

                                    if (matchingIndex !== -1) {
                                        combinedBoostHistory[key][matchingIndex].amount += metric.amount;
                                    } else {
                                        combinedBoostHistory[key].push(metric);
                                    }

                                    metricTotals[key] += metric.amount;

                                    console.log(metricTotals);
                                });
                            }
                        });
                    });

                    setBoostHistory(combinedBoostHistory);
                    setBoostTotals(metricTotals);
                }
                setBoostedOffersLoading(false);
            });
    }, []);

    const sentenceCase = (str) => {
        const lowercaseStr = str.toLowerCase().substr(1);
        const capitalFirstLetter = str.toUpperCase().substr(0, 1);

        return `${capitalFirstLetter}${lowercaseStr}`;
    };

    const displayCampaignRate = (rate) => {
        const { type, amount } = rate;

        switch (type) {
            case RateTypes.FLAT_ITEM:
                return `${moneyFormat(amount, true)} per item`;
            case RateTypes.FLAT_ORDER:
                return `${moneyFormat(amount, true)} per order`;
            case RateTypes.PERCENT_REVENUE:
            default:
                return `${amount}% of order total`;
        }
    };

    if (boostedOffersLoading) {
        return 'Loading...';
    }

    if (!boostedOffers || (Array.isArray(boostedOffers) && boostedOffers.length === 0)) {
        return (
            <EmptyState
                message="You haven’t boosted any offers yet"
                paragraph="Boosting an offer can help it reach more potential ambassadors."
                ctaText="Boost an Offer"
                ctaAction={() => { return history.push('/offers'); }}
            />
        );
    }

    return (
        <div className="offers-boosted-wrapper">
            <h1>Ad Boost Summary</h1>
            <ul className="offer-boost-metrics">
                <li>Impressions: <span>{numberFormat(boostTotals.impressions)}</span></li>
                <li>Unique Impressions: <span>{numberFormat(boostTotals.uniqueImpressions)}</span></li>
                <li>Clicks: <span>{numberFormat(boostTotals.clicks)}</span></li>
                <li>Applicants: <span>{numberFormat(boostTotals.applicants)}</span></li>
                <li>Total Spent: <span>{moneyFormat(boostTotals.charges)} <Tooltip content="Includes impressions you haven't paid for yet, but does not include minimum charges."><span className="info-tooltip">i</span></Tooltip></span></li>
            </ul>

            <DateComparisonGraph data={boostHistory} defaultCompareMetrics={['impressions', 'clicks']} />

            <table className="offer-list">
                <thead>
                    <tr>
                        <th>Offer</th>
                        <th className="number impressions">Impressions</th>
                        <th className="number unique">Unique Impressions</th>
                        <th className="number clicks">Clicks</th>
                        <th className="number applicants">Applicants</th>
                        <th className="money bid">Current Bid</th>
                        <th className="money spent">Total Spent</th>
                        <th className="status">Status</th>
                    </tr>
                </thead>
                <tbody>
                    {boostedOffers.map((offer) => {
                        console.log(offer.offerBoostHistory);
                        let bannerImage = (defaultBanners[offer.category]) ? defaultBanners[offer.category][0] : defaultBanners.default[0];
                        if (offer.bannerImage) {
                            bannerImage = offer.bannerImage;
                        }

                        const offerMetricTotals = {
                            impressions: 0,
                            uniqueImpressions: 0,
                            clicks: 0,
                            applicants: 0,
                            charges: 0
                        };

                        Object.entries(offer.offerBoostHistory).forEach(([key, amounts]) => {
                            if (Array.isArray(amounts)) {
                                offerMetricTotals[key] = amounts.reduce((acc, metric) => {
                                    return acc + metric.amount;
                                }, 0);
                            }
                        });

                        return (
                            <tr key={offer.offer_id} onClick={() => { return history.push(`/offer/${offer.offer_id}/boost`); }}>
                                <td className="name">
                                    <img src={bannerImage} alt="" />
                                    <div>
                                        <p>{formatOfferName(offer)}</p>
                                        <ul className="commission-rates">
                                            <li>Initial: {displayCampaignRate(offer.rate)}</li>
                                            <li>Long-Term: {displayCampaignRate(offer.longterm_rate)}</li>
                                        </ul>
                                    </div>
                                </td>
                                <td className="number impressions">{numberFormat(offerMetricTotals.impressions)}</td>
                                <td className="number unique">{numberFormat(offerMetricTotals.uniqueImpressions)}</td>
                                <td className="number clicks">
                                    {numberFormat(offerMetricTotals.clicks)}
                                    {offerMetricTotals.impressions > 0
                                        && <span>{percentFormat(offerMetricTotals.clicks / offerMetricTotals.impressions)} <abbr title="Click-through rate">CTR</abbr></span>
                                    }
                                </td>
                                <td className="number applicants">
                                    {numberFormat(offerMetricTotals.applicants)}
                                    {offerMetricTotals.impressions > 0
                                        && <span>{percentFormat(offerMetricTotals.applicants / offerMetricTotals.uniqueImpressions)} <abbr title="Conversion rate">CR</abbr></span>
                                    }
                                </td>
                                <td className="money bid">
                                    {offer.sponsored === 'ACTIVE'
                                        ? `${moneyFormat(offer.offerBoostHistory.current_bid)} CPM`
                                        : 'N/A'
                                    }
                                </td>
                                <td className="money spent">{moneyFormat(offerMetricTotals.charges)}</td>
                                <td className="status">
                                    <p>
                                        <span className="boost-status" data-status={offer.sponsored.toLowerCase()}>{pageWidth <= mobileWidth && 'Ad Boost: '}{sentenceCase(offer.sponsored)}</span>
                                        <Link to={`offer/${offer.offer_id}/boost`}>Manage/View Stats</Link>
                                    </p>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

const mapStateToProps = (state) => {
    console.log(state);
    return {
        advertiser: state.advertiser
    };
};

export default connect(mapStateToProps)(BoostedOffers);
