import React, {useState} from "react";
import { Modal } from "@shopify/polaris";
import ImageDrop from '../llama/image-drop';
import './invite-setup-side-drawer.css';

/**
 * @function InviteSetupSideDrawer
 * Accepts the following params, returns InviteSetupSideDrawer Component.
 * Displays a Polaris Modal in which the merchant is able to upload files.
 * If there are multiple uploads they are displayed in a Polaris Stack Component.
 * 
 * @param {Function} handleFileUpload
 * @param {Boolean} showSideDrawer
 * @param {Function} setShowSideDrawer
 * 
 * @returns {FunctionComponent}
 */
const InviteSetupSideDrawer = ({saveFile, showSideDrawer, setShowSideDrawer, uploading}) => {
    const [uploadedImage, setImage] = useState(null);
    const sideDrawerClosedHandler = () => setShowSideDrawer(null);

    return (
        <div>
            <Modal
                large
                open={showSideDrawer}
                onClose={sideDrawerClosedHandler}
                title="Upload a Banner"
                primaryAction={{
                    content: 'Upload Banner',
                    onAction: () => saveFile(uploadedImage),
                    loading: uploading,
                    disabled: !uploadedImage
                }}
                secondaryActions={[
                    {
                        content: 'Cancel',
                        onAction: sideDrawerClosedHandler,
                    },
                ]}
            >
                <Modal.Section>
                    <p className="InviteSidedrawer__ModalText">Your banner image should be 1040px x 344px. The minimum safe area for artwork is 736px x 344px. Avoid images with text in them.</p>
                    <p className="InviteSidedrawer__ModalText">For device guidelines and coverage overlays, <a href="https://s3.amazonaws.com/llama.creatives/invite-signup/llama-invite-banner-template.psd" target="_blank" rel="noopener noreferrer">download our PSD template</a>.</p>
                </Modal.Section>
                <Modal.Section>
                    <ImageDrop
                        setImage={setImage}
                        buttonText="Add a Banner Image"
                    />
                </Modal.Section>
            </Modal>
        </div>
    )
};

export default InviteSetupSideDrawer;