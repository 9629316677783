const reformatAbbreviation = (number) => {

    if (typeof number === 'number') {
        return number;
    }

    const abbreviations = {
        k: (x) => ((parseFloat(x) * 1000000) / 1000),
        K: (x) => parseInt(x) * 1000,
        M: (x) => parseInt(x) * 1000000,
        B: (x) => parseInt(x) * 1000000000,
    }

    const abbr = Object.keys(abbreviations)
        .map((item) => number.includes(item) ? item : false)
        .find((item) => item)

    if (abbr) {
        return abbreviations[abbr](number);
    } else {
        return parseInt(number);
    }

}

const calculateAffiliateReach = (affiliate) => {
    if (!affiliate) {
        return 0;
    }

    const instagramReach = Array.isArray(affiliate.instagramDataV2) && affiliate.instagramDataV2.length > 0
        ? affiliate.instagramDataV2.reduce((acc, account) => {
            if (!account || !account.followers_count) {
                return acc;
            }
            return acc + account.followers_count;
        }, 0)
        : 0;

    const twitterReach = Array.isArray(affiliate.twitterData) && affiliate.twitterData.length > 0
        ? affiliate.twitterData.reduce((acc, account) => {
            if (!account || !account.user || !account.user.followers) {
                return acc;
            }
            return acc + account.user.followers;
        }, 0)
        : 0;

    const facebookReach = Array.isArray(affiliate.facebookData) && affiliate.facebookData.length > 0
        ? affiliate.facebookData.reduce((acc, account) => {
            let fans = 0;
            if (Array.isArray(account.pageStats) && account.pageStats.length > 0) {
                account.pageStats.forEach((page) => {
                    fans += page.fan_count;
                });
            }
            return acc + fans;
        }, 0)
        : 0;

    const youtubeReach = Array.isArray(affiliate.youTubeData) && affiliate.youTubeData.length > 0
        ? affiliate.youTubeData.reduce((acc, account) => {
            let subscribers = 0;
            if (Array.isArray(account.channelStats) && account.channelStats.length > 0) {
                account.channelStats.forEach((channel) => {
                    subscribers += channel.subscribers;
                });
            }
            return acc + subscribers;
        }, 0)
        : 0;

    return instagramReach + twitterReach + facebookReach + youtubeReach;
};

export default calculateAffiliateReach;
