const formatPercent = (value, moveDecimal) => {

    const float = value !== Infinity
        ? parseFloat(value)
        : 0.00;

    let fixedFloat = '0.00';
    //fix to two decimal places
    if(!isNaN(float)){
        fixedFloat = (moveDecimal)
            ? (float * 100).toFixed(2)
            : float.toFixed(2)
    }

    //drop trailing zeros from decimal
    fixedFloat = fixedFloat.replace(/(\..+?)0+/, '$1').replace(/\.0$/, '');

   /*  let fixedFloat = float.toFixed(2);

    if (isNaN(fixedFloat)) {
        return '0%';
    }

    if (moveDecimal) {
        fixedFloat = fixedFloat * 100; 
    } */

    return `${fixedFloat}%`;

}

export default formatPercent;