import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Page, Frame, Toast } from '@shopify/polaris';

import Discover from '../components/discover';
import InviteSideDrawer from '../components/discover/side-drawer';
import { getAppData } from '../actions/app-data';
import { discoverFilters } from '../actions/action_updateDiscoverFilters';
import useSocialFilter from '../hooks/use-social-filter';
import { useToggle } from '../hooks/use-toggle';
import { isEqual } from 'lodash';

export const DiscoverContainer = (props) => {

    const { 'custom:advertiser_id': advertiser_id } = props.authData && props.authData.attributes;

    const ageRange = [
        "18-24",
        "25-34",
        "35-44",
        "45-54",
        "55-64",
        "65+",
        "All"
    ]

    useEffect(() => {
        const activeOffers = props.allOffers.filter((offer) => {
            return (offer.active && offer.interim_status !== 'ACTIVE') === true;
        }).map((offer) => {
            return {
                ...offer,
                company: props.advertiserCompany
            };
        });
        setOffers(activeOffers);

        // If categories are not available in redux then pull it from database.
        if(props.storeCategories && props.storeCategories.length > 0) {
            let categoryNames = props.storeCategories.map(category => {
                return category.name
            })

            // If redux filter object already has selected selectedCategories then don't overwrite it
            if(!props.preservDiscoverFilters.selectedCategories || props.preservDiscoverFilters.selectedCategories.length === 0 ) {
                setSelectedCategories(categoryNames);
            }
            
            setCategories(categoryNames);
            // set the initial load true so that searchAffiliate API call should trigger once the categories pulled from db.
            setInitalLoad(true);
            setDiscoverFilters({
                ...discoverFilters,
                selectedCategories: categoryNames,
            })
        } else {
            props.getAppData()
            .then(result => {
                let categoryNames = result.value.data.app.categories.map(category => {
                    return category.name
                })

                setSelectedCategories(categoryNames);
                setCategories(categoryNames);
                setInitalLoad(true);
                setDiscoverFilters({
                    ...discoverFilters,
                    selectedCategories: categoryNames,
                })
            })
        }
    }, []);

    let defaultDiscoverFilterValues = {
        searchTerm : '',
        socialFilter: 'all',
        selectedGender: 'ALL',
        selectedAgeRange: ageRange,
        rangeValues: [0, 10000000000],
        rangeNames: ["0", "13"],
        selectedCategories: props.storeCategories && props.storeCategories.length > 0 ? props.storeCategories.map(category => category.name) : [],
        initialPage: 1,
        skip: 0,
        allCategories: true,
    }

    let defaultDiscoverFilters;

    if(Object.keys(props.preservDiscoverFilters).length > 0 && !isEqual(defaultDiscoverFilters, props.preservDiscoverFilters)) {
        defaultDiscoverFilters = props.preservDiscoverFilters;
    } else {
        defaultDiscoverFilters = defaultDiscoverFilterValues;
    }

    const [searchTerm, setSearchTerm] = useState(defaultDiscoverFilters.searchTerm);
    const {socialFilter, setSocialFilter} = useSocialFilter(defaultDiscoverFilters.socialFilter);
    const [selectedGender, setGenderValue] = useState(defaultDiscoverFilters.selectedGender);
    const [selectedAgeRange, setAgeRange] = useState(defaultDiscoverFilters.selectedAgeRange);
    const [rangeValues, setRangeValues] = useState(defaultDiscoverFilters.rangeValues);
    const [rangeNames, setRangeNames] = useState(defaultDiscoverFilters.rangeNames);
    const [selectedCategories, setSelectedCategories] = useState(defaultDiscoverFilters.selectedCategories);

    const [allCategories, setAllCategories] = useState(defaultDiscoverFilters.allCategories);
    const [sideDrawerAffiliate, setSideDrawerAffiliate] = useState(null);
    const [affiliates, setAffiliates] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [initialLoad, setInitalLoad] = useState(false);
    const [toastVerbiage, setToastVerbiage] = useState(null);
    const [offers, setOffers] = useState([]);
    const [searchButton, setSearchButton] = useState(false);
    const [initialPage, setInitialPage] = useState(defaultDiscoverFilters.initialPage);
    const [skip, setSkip] = useState(defaultDiscoverFilters.skip);
    const [isApplyFilterDisable, setIsApplyFilterDisable] = useState(true);
    const [isResetFilterDisable, setIsResetFilterDisable] = useState(true);
    const [isInvitationSent, setIsInvitationSent] = useState(false);

    const [isApplyDiscoverFilters, setIsApplyDiscoverFilters] = useState(false);
    const [isResetFilter, setIsResetFilter] = useState(false);
    const [discoverFilters, setDiscoverFilters] = useState(defaultDiscoverFilters);

    const [openSection, toggleOpen] = useToggle(false);
    const [categories, setCategories] = useState(defaultDiscoverFilters.selectedCategories);

    useEffect(() => {
        if( searchTerm !== defaultDiscoverFilterValues.searchTerm ||
            socialFilter !== defaultDiscoverFilterValues.socialFilter || 
            selectedGender !== defaultDiscoverFilterValues.selectedGender || 
            selectedAgeRange.length !== defaultDiscoverFilterValues.selectedAgeRange.length || 
            rangeValues[0] !== defaultDiscoverFilterValues.rangeValues[0] || 
            rangeValues[1] !== defaultDiscoverFilterValues.rangeValues[1] || 
            selectedCategories.length !== defaultDiscoverFilterValues.selectedCategories.length 
        ) {
            setIsApplyFilterDisable(false);
            setIsResetFilterDisable(false);
        } else {
            setIsApplyFilterDisable(true);
            setIsResetFilterDisable(true);
        }
    },[searchTerm, socialFilter, selectedGender, selectedAgeRange, rangeValues, selectedCategories, allCategories])

    useEffect(() => {
        if(!initialLoad) {
            setDiscoverFilters({
            ...discoverFilters,
            initialPage,
            skip
            })
        }
        props.discoverFilters({
            searchTerm,
            selectedCategories,
            socialFilter,
            rangeValues,
            rangeNames,
            selectedGender,
            selectedAgeRange,
            initialPage,
            skip,
            allCategories
        });
    },[skip])

    const applyDiscoverFilters = () => {
        setInitialPage(1);
        setSkip(0);
        setIsApplyFilterDisable(true);
        setDiscoverFilters({
            searchTerm,
            selectedCategories,
            socialFilter,
            rangeValues,
            rangeNames,
            selectedGender,
            selectedAgeRange,
            initalPage: 1,
            skip: 0
        })
        setIsApplyDiscoverFilters(true);
        props.discoverFilters({
            searchTerm,
            selectedCategories,
            socialFilter,
            rangeValues,
            rangeNames,
            selectedGender,
            selectedAgeRange,
            initalPage: 1,
            skip: 0,
            allCategories
        });
        toggleOpen(false);
    }

    useEffect(() => {
        if(isResetFilter) {
            setInitialPage(defaultDiscoverFilterValues.initialPage);
            setSkip(defaultDiscoverFilterValues.skip);
            setSearchTerm(defaultDiscoverFilterValues.searchTerm);
            setSocialFilter(defaultDiscoverFilterValues.socialFilter);
            setGenderValue(defaultDiscoverFilterValues.selectedGender);
            setAgeRange(defaultDiscoverFilterValues.selectedAgeRange);
            setRangeValues(defaultDiscoverFilterValues.rangeValues);
            setRangeNames(defaultDiscoverFilterValues.rangeNames);
            const categoryNames = props.storeCategories.map(category => {
                return category.name
            })
            setSelectedCategories(categoryNames)
            defaultDiscoverFilterValues.selectedCategories = categoryNames;
            setDiscoverFilters(defaultDiscoverFilterValues);
            props.discoverFilters(defaultDiscoverFilterValues);
            setIsApplyDiscoverFilters(true);
            setIsResetFilter(false);
            setIsApplyFilterDisable(false);
            setAllCategories(true);
            toggleOpen(false);
        }
    }, [isResetFilter])

    useEffect(() => {
        if(searchButton) {
            setInitialPage(1);
            setSkip(0);
            props.discoverFilters(discoverFilters);
            setIsApplyDiscoverFilters(true);
            setSearchButton(false);
        }
    },[searchButton])

    const toastMarkup = toastVerbiage ? <Toast content={toastVerbiage} onDismiss={() => setToastVerbiage(null)}/> : null;

    if (!props.authData || !props.authData.attributes) return null;
    if (!advertiser_id) return null;

    return (
        <div style={{ maxWidth: "1440px", margin: 'auto' }} data-test="container-discover">
            <Page
                title="Discover Ambassadors"
                separator={true}
                fullWidth
            >
                <InviteSideDrawer
                    {...props}
                    offers={offers}
                    advertiser_id={advertiser_id}
                    showSideDrawer={sideDrawerAffiliate !== null}
                    affiliate={sideDrawerAffiliate}
                    setSideDrawerAffiliate={setSideDrawerAffiliate}
                    setToastVerbiage={setToastVerbiage}
                    setIsInvitationSent={setIsInvitationSent}
                />
                <Discover
                    {...props}
                    advertiser_id={advertiser_id}
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    categories={categories}
                    selectedCategories={selectedCategories}
                    setSelectedCategories={setSelectedCategories}
                    affiliates={affiliates}
                    isLoading={isLoading}
                    initialLoad={initialLoad}
                    setSideDrawerAffiliate={setSideDrawerAffiliate}
                    setAffiliates={setAffiliates}
                    setIsLoading={setIsLoading}
                    setInitalLoad={setInitalLoad}
                    discoverFilters={discoverFilters}
                    setDiscoverFilters={setDiscoverFilters}
                    socialFilter={socialFilter}
                    setSocialFilter={setSocialFilter}
                    rangeValues={rangeValues}
                    setRangeValues={setRangeValues}
                    selectedGender={selectedGender}
                    setGenderValue={setGenderValue}
                    selectedAgeRange={selectedAgeRange}
                    setAgeRange={setAgeRange}
                    applyDiscoverFilters={applyDiscoverFilters}
                    isApplyDiscoverFilters={isApplyDiscoverFilters}
                    setIsApplyDiscoverFilters={setIsApplyDiscoverFilters}
                    isResetFilter={isResetFilter}
                    setIsResetFilter={setIsResetFilter}
                    setSearchButton={setSearchButton}
                    initialPage={initialPage}
                    setInitialPage={setInitialPage}
                    skip={skip}
                    setSkip={setSkip}
                    isApplyFilterDisable={isApplyFilterDisable}
                    setIsApplyFilterDisable={setIsApplyFilterDisable}
                    isResetFilterDisable={isResetFilterDisable}
                    setIsResetFilterDisable={setIsResetFilterDisable}
                    rangeNames={rangeNames}
                    setRangeNames={setRangeNames}
                    allCategories={allCategories}
                    setAllCategories={setAllCategories}
                    offers={offers}
                    isInvitationSent={isInvitationSent}
                    setIsInvitationSent={setIsInvitationSent}
                    openSection={openSection}
                    toggleOpen={toggleOpen}
                />
                <Frame>{toastMarkup}</Frame>
            </Page>
        </div>
    )

}

const mapStateToProps = (state) => {
    return {
        storeCategories: state.app.categories,
        countries: state.app.countries,
        authData: state.authData,
        allOffers: state.advertiser.offers,
        advertiserCompany: state.advertiser.company,
        preservDiscoverFilters: state.filtersReducer.discoverFilters
    }
}

const mapDispatchToProps = (dispatch) => {
	return {
        getAppData: ()  => dispatch(getAppData()),
        discoverFilters: (discoverFilterValues) => dispatch(discoverFilters(discoverFilterValues))
	}
  }

export default connect(mapStateToProps, mapDispatchToProps)(DiscoverContainer);
