/* import axios from 'axios';
import { NEXT_APP_API_URL } from '../config'; */
import {sendGraphQL} from './sendRequest'

const GENERATE_PIXEL = 'GENERATE_PIXEL';
export const GENERATE_PIXEL_PENDING = 'GENERATE_PIXEL_PENDING';
export const GENERATE_PIXEL_FULFILLED = 'GENERATE_PIXEL_FULFILLED';
export const GENERATE_PIXEL_REJECTED = 'GENERATE_PIXEL_REJECTED';

export const generateTrackingPixel = (offer_id, advertiser_id) => {

    const query = `
        query getPixels($offer_id: String!, $advertiser_id: String!) {
            generateTrackingPixel(offer_id: $offer_id) {
                test_click_url
                url
                domain
                code     
            }
            advertisers(advertiser_id: $advertiser_id) {
                applications(offer_id: $offer_id) {
                    affiliate {
                        affiliate_id
                        tracking_id
                        name
                    }
                }
            }
        }
    `

    const variables = {
        offer_id,
        advertiser_id
    }

    const payload = {
        query,
        variables
    }

    return sendGraphQL(payload)
        .then(response => response.data.data)

    /* let headers = {
        "Content-Type": 'application/json'
    }

    return axios.post(NEXT_APP_API_URL, { query, variables }, { headers })
        .then(response => response.data.data) */


}
