import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { Page } from '@shopify/polaris';

import OfferList from '../components/offer-list/offer-list';
import BoostedOffers from '../components/offer-list/offers-boosted';
import LlamaTabs from '../components/llama/tabs';

import { generateTrackingPixel } from '../actions/generate-pixel';
import { changeHeaderTitle } from '../actions/header';

import './styles/offers-list.css';

const tabs = [
    {
        key: 'offers',
        name: 'All offers',
        link: '/offers'
    },
    {
        key: 'boost',
        name: 'Ad Boost',
        link: '/offers/boosted'
    }
];

export class Offers extends Component {
    constructor(props) {
        super(props);
        this.navigateToNew = this.navigateToNew.bind(this);
    }

    navigateToNew() {
        this.props.history.push('/offer-new');
    }

    render() {
        return (
            <Page
                data-test="offers-container"
                title="Offers"
                separator
                fullWidth
                primaryAction={{ content: 'Create New Offer', onClick: this.navigateToNew }}
            >
                <LlamaTabs tabs={tabs} location={this.props.location} initialFit="condensed" />
                <Switch>
                    <Route path="/offers" render={(renderProps) => <OfferList { ...renderProps } { ...this.props } navigateToNew={this.navigateToNew} />} exact />
                    <Route path="/offers/boosted" render={(renderProps) => <BoostedOffers { ...renderProps } { ...this.props } />} exact />
                </Switch>
            </Page>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        advertiser: state.advertiser,
        loadingOffers: state.loading.GET_ADVERTISER,
        offers: state.offers
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        generateTrackingPixel: (id) => { return dispatch(generateTrackingPixel(id)); },
        changeHeaderTitle: (title) => { return dispatch(changeHeaderTitle(title)); }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Offers);
