import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { Route, Switch } from 'react-router-dom';
import {
    Page,
    Button,
    Layout
} from '@shopify/polaris';

import CampaignName from '../components/campaign-name/campaign-name';
import CampaignRates from '../components/campaign-rates/campaign-rates';
import CampaignCreative from '../components/campaign-creative/campaign-creative';
import CampaignProducts from '../components/campaign-products/campaign-products';
import CampaignTarget from '../components/campaign-target/campaign-target';
import CampaignSummary from '../components/campaign-summary/campaign-summary';

import { updateOffer } from '../actions/create-offer';
import { getOfferById } from '../actions/offers';
import { getAppData } from '../actions/app-data';
import { getProductsForAdvertiser } from '../actions/products';
import withProducts from '../hoc/with-products';
import CenteredSpinner from '../components/centered-spinner';
import { saveImage } from '../components/llama/image-drop';

import './styles/offer-form.css';

class OfferEditContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            savedAdvertiserId: props.advertiser.advertiser_id,
            categories: [],
            countries: [],
            errors: []
        };

        this.validate = this.validate.bind(this);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.advertiser.advertiser_id && prevState && (nextProps.advertiser.advertiser_id !== prevState.savedAdvertiserId)) {
            return { savedAdvertiserId: nextProps.advertiser.advertiser_id };
        }
        return null;
    }

    componentDidMount() {
        if (this.props.advertiser.advertiser_id && this.props.match.params.id) {
            this.getOffer(this.props.match.params.id, this.props.advertiser.advertiser_id);
        }

        if (this.props.app.categories.length === 0 || this.props.app.countries.length === 0) {
            console.log('Initiate the API call');
            // Initiate the API call
            this.props.getAppData()
                .then((result) => {
                    this.setState({
                        categories: result.value.data.app.categories,
                        countries: result.value.data.app.countries.map((item) => {
                            return { label: item.name, value: item.country_code };
                        })
                    });
                });
        } else {
            console.log('Setting from in state');
            this.setState({
                categories: this.props.app.categories,
                countries: this.props.app.countries.map((item) => {
                    return { label: item.name, value: item.country_code };
                })
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.advertiser.advertiser_id !== this.props.advertiser.advertiser_id) {
            this.getOffer(this.props.match.params.id, this.props.advertiser.advertiser_id);
        }
    }

    getOffer(offer_id, advertiser_id) {
        this.props.getOffer(offer_id, advertiser_id)
            .then(({ value: { data } }) => {
                if (
                    !Array.isArray(data.advertisers)
                    || Array.isArray(!data.advertisers[0].offers)
                ) {
                    return;
                }

                const newValues = {
                    ...this.props.values,
                    ...data.advertisers[0].offers[0]
                };

                this.props.setValues(newValues);
            });
    }

    setBreadcrumb = (offerId) => {
        if (this.props.history.location.from && this.props.history.location.from === 'offers') {
            return {
                content: 'Back to Offers',
                onAction: () => { return this.props.history.push('/offers'); }
            };
        }
        return {
            content: 'Back to Offer Details',
            onAction: () => { return this.props.history.push(`/offer/${offerId}`); }
        };
    }

    validate(e) {
        const errors = [];

        if (this.props.values.name.trim().length === 0) {
            errors.push({ field: 'name', message: 'Offer Name is required' });
        }
        if (this.props.values.category.trim().length === 0) {
            errors.push({ field: 'category', message: 'Please select a category' });
        }
        if (document.getElementById('offer-preview_url').value === `${this.props.advertiser.domain}/`) {
            this.props.setFieldValue('preview_url', `https://${this.props.advertiser.domain}/`, true);
        } else if (this.props.values.preview_url.trim().length === 0) {
            errors.push({ field: 'preview_url', message: 'Destination URL is required' });
        } else {
            let domain = this.props.values.preview_url.toLowerCase().replace('https://', '');
            if (domain.indexOf('/') !== -1) {
                domain = domain.substr(0, domain.indexOf('/'));
            }
            // make sure the domain tld is at least two letters long
            if (!/\.[a-z]{2,}$/.test(domain)) {
                errors.push({ field: 'preview_url', message: 'Destination URL is invalid' });
            }
        }
        if (this.props.values.products.includeSome && this.props.values.products.includeProducts.length === 0) {
            errors.push({ field: 'products', message: 'Please select the products you want to include in your offer' });
        }
        if (this.props.values.rate.amount.toString().trim().length === 0) {
            let commissionField = '';
            let commissionMessage = 'Please enter a';
            switch (this.props.values.rate.type) {
                case 'PERCENT_REVENUE':
                    commissionField = 'rate_percent';
                    commissionMessage += ' percentage';
                    break;
                case 'FLAT_ORDER':
                case 'FLAT_ITEM':
                    commissionField = 'rate_flat';
                    commissionMessage += 'n amount';
                    break;
                default:
                    break;
            }
            errors.push({ field: commissionField, message: commissionMessage });
        }
        if (this.props.values.longterm_rate.amount.toString().trim().length === 0) {
            let commissionField = '';
            let commissionMessage = 'Please enter a';
            switch (this.props.values.longterm_rate.type) {
                case 'PERCENT_REVENUE':
                    commissionField = 'rate_percent';
                    commissionMessage += ' percentage';
                    break;
                case 'FLAT_ORDER':
                case 'FLAT_ITEM':
                    commissionField = 'rate_flat';
                    commissionMessage += 'n amount';
                    break;
                default:
                    break;
            }
            errors.push({ field: `longterm_${commissionField}`, message: commissionMessage });
        }

        if (errors.length === 0) {
            this.props.handleSubmit(e);
        } else {
            this.props.setSubmitting(false);
            this.setState({ errors });
        }
    }

    renderEditDetails(offer) {
        if (!offer.offer_id) {
            return <CenteredSpinner />;
        }
        const edit = true;
        const propsAndState = { ...this.props, ...this.state, edit };

        return (

            <Switch>
                <Route path="/offer/:id/edit/creatives" exact>
                    <>
                        <CampaignCreative {...this.props} />
                    </>
                </Route>
                <Route path="/offer/:id/edit" exact>
                    <div className="offer-form">
                        <CampaignName {...propsAndState} />
                        <CampaignProducts {...propsAndState} />
                        {offer.offer_type === 'product_giveaway' ? null
                            : <CampaignRates {...propsAndState} />}
                        <CampaignTarget {...propsAndState} />
                    </div>
                </Route>
            </Switch>
        );
    }

    render() {
        const { values, isSubmitting } = this.props;

        return (
            <Page
                title={values.name}
                separator
                primaryAction={{
                    content: 'Save',
                    onAction: this.validate,
                    disabled: isSubmitting,
                    loading: isSubmitting
                }}
                breadcrumbs={[this.setBreadcrumb(values.offer_id)]}
            >
                <div className="offer-form-layout-wrapper">
                    <Layout>
                        <Layout.Section>
                            {this.renderEditDetails(values)}
                            <div className="offer-form-save">
                                <Button
                                    primary
                                    onClick={this.validate}
                                    disabled={isSubmitting}
                                    loading={isSubmitting}
                                >
                                    Save
                                </Button>
                            </div>
                        </Layout.Section>
                        <Layout.Section secondary>
                            {values.offer_id && <div className="offer-new__summary-wrapper"><CampaignSummary showManageOffer {...this.props} /></div>}
                        </Layout.Section>
                    </Layout>
                </div>
            </Page>
        );
    }
}

OfferEditContainer.propTypes = {
    advertiser: PropTypes.shape({
        advertiser_id: PropTypes.string,
        paypal: PropTypes.shape({
            refresh: PropTypes.string,
            user: PropTypes.string
        }),
        domain: PropTypes.string
    }).isRequired,
    app: PropTypes.shape({
        categories: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string,
            value: PropTypes.number
        })),
        countries: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string,
            country_code: PropTypes.string
        }))
    }).isRequired,
    getAppData: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    values: PropTypes.shape({
        offer_id: PropTypes.string,
        name: PropTypes.string,
        category: PropTypes.string,
        preview_url: PropTypes.string,
        offer_type: PropTypes.string,
        products: PropTypes.shape({
            includeSome: PropTypes.bool,
            includeProducts: PropTypes.arrayOf(PropTypes.string)
        }),
        rate: PropTypes.shape({
            amount: PropTypes.string,
            type: PropTypes.string
        }),
        longterm_rate: PropTypes.shape({
            amount: PropTypes.string,
            type: PropTypes.string
        })
    }).isRequired,
    setValues: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    setSubmitting: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
        location: PropTypes.shape({
            from: PropTypes.string
        })
    }).isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string
        })
    }).isRequired,
    getOffer: PropTypes.func.isRequired
};

const submitForm = async (values, { props, setSubmitting }) => {
    const activeCampaign = {
        formData: values,
        advertiser: props.advertiser
    };
    console.log('values::', values);

    // if they uploaded a new banner, save to s3 and replace the bannerImage field with the new url
    if (values.banner && values.banner.imageData) {
        const imageData = await saveImage(values.banner.imageData, props.advertiser.id, `offers/${values.offer_id}/banner`, null);
        activeCampaign.formData.bannerImage = imageData.url;
    }

    updateOffer(activeCampaign)
        .then(() => {
            props.history.push(`/offer/${values.offer_id}`, { offerStateUpdate: true });
            setSubmitting(false);
        });
};

const EditOffer = withFormik({
    mapPropsToValues: (props) => {
        if (
            props
            && props.location
            && props.location.state
            && props.location.state.values
        ) {
            return props.location.state.values;
        }

        return {};
    },
    handleSubmit: submitForm
})(OfferEditContainer);

const WithProductsEditOffer = withProducts(EditOffer);

const mapStateToProps = ({ advertiser, app, products }) => {
    return {
        advertiser,
        app,
        products: products.products
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAppData: () => { return dispatch(getAppData()); },
        getOffer: (offer_id, advertiser_id) => { return dispatch(getOfferById(offer_id, advertiser_id)); },
        getProductsForAdvertiser: (shop) => dispatch(getProductsForAdvertiser(shop))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(WithProductsEditOffer);
