import React, { useState, useEffect } from 'react';
import { Card, Select, Tooltip, Banner, Icon, Badge } from '@shopify/polaris';
import { ExternalSmallMinor } from '@shopify/polaris-icons';
import moment from 'moment';
import { LlamaPagination, EmptyState } from 'llama-library/components';

import formatMetrics from '../../utils/format-metrics';
import usePages from '../../hooks/use-pages';

import './youtube-affiliate-detail.css';

export const checkValidYoutube = (youTubeAccount) => {
    if ( 
        !youTubeAccount
        || !youTubeAccount.channels
        || !youTubeAccount.channelStats
    ) {
        return false;
    }

    return true;
}

/**
 * @function YoutubeAffiliateDetail
 * Accepts props object, returns YouTubeAffiliateDetail Component.
 * Displays YouTube posts, pagination for posts and
 * account selection controller.
 * 
 * @param {Object} props - props Object
 * 
 * @returns {FunctionComponent}
 */
const YoutubeAffiliateDetail = (props) => {
    console.log(props);

    let youTubeLiveData = props.affiliate.youTubeData;
    if (!youTubeLiveData || !Array.isArray(youTubeLiveData) || youTubeLiveData.length === 0) {
        youTubeLiveData = [null];
    }

    // remap fields
    youTubeLiveData = youTubeLiveData.reduce((acc, account) => {
        account.channelStats.forEach((channelStats) => {
            const channelInfo = account.channels.find((channel) => {
                return channel.id === channelStats.id;
            });
            channelInfo.numVideos = channelInfo.videos;
            delete channelInfo.videos;

            const videos = account.videos.filter((video) => {
                return video.channelId === channelStats.id;
            });

            acc.push({
                ...channelStats,
                ...channelInfo,
                videos
            });
        });
        return acc;
    }, []);

    let primaryChannel = youTubeLiveData.find((channel) => {
        return props.affiliate.youtube === channel.id;
    });

    if (!primaryChannel) {
        primaryChannel = youTubeLiveData[0];
    }

    let totalReach = 0;
    if (primaryChannel.subscribers || primaryChannel.subscribers > 0) {
        totalReach = youTubeLiveData.reduce((acc, channel) => {
            return acc + channel.subscribers;
        }, 0);
    }

    console.log('YOUTUBE AFFILIATE DETAIL PROPS', youTubeLiveData);

    const [youtubeChannel, setYoutubeChannel] = useState(primaryChannel);
    const [youtubeVideos, setYoutubeVideos] = useState([]);
    const [expandedDescriptions, setExpandedDescriptions] = useState([]);

    const handleSelectAccount = (value) => {
        const newChannel = youTubeLiveData.find((item) => {
            return item.id === value;
        });
        console.log('ACCOUNT SWITCH', newChannel);
        if (newChannel) {
            setYoutubeChannel(newChannel);
            if (newChannel.videos) {
                setYoutubeVideos(newChannel.videos);
                setPageTo(0);
            } else {
                setYoutubeVideos([]);
                setPageTo(0);
            }
            window.scrollTo(0, 0);
        }
    };

    useEffect(() => {
        // Set up youtube videos
        if (youtubeChannel && youtubeChannel.videos) {
            setYoutubeVideos(youtubeChannel.videos);
        } else {
            setYoutubeVideos([]);
        }
    }, []);

    console.log(youtubeVideos)

    const {
        previousPage,
        nextPage,
        currentPage,
        hasNext,
        hasPrevious,
        maxPages,
        posts,
        setPageTo
    } = usePages(0, 5, youtubeVideos);

    if (!youTubeLiveData || !Array.isArray(youTubeLiveData) || youTubeLiveData.length === 0) {
        return null;
    }

    const youtubeLink = `https://yotube.com/${youtubeChannel.id}`;

    const goToYouTube = () => {
        window.open(youtubeLink);
    };

    // if(!checkValidYoutube(youtubeChannel)) {
    //     return null;
    // }

    const { fromCache } = youtubeChannel;

    const showLetterGrade = (score) => {
        if (score >= 81) {
            return <Badge status="success"> A </Badge>;
        }
        if (score <= 80 && score >= 61) {
            return <Badge status="info"> B </Badge>;
        }
        if (score <= 60 && score >= 41) {
            return <Badge status="attention"> C </Badge>;
        }
        if (score <= 40 && score >= 21) {
            return <Badge status="warning"> D </Badge>;
        }
        return <Badge status="warning"> F </Badge>;
    };

    const handleExpandDescription = (videoId) => {
        setExpandedDescriptions({
            ...expandedDescriptions,
            [videoId]: !expandedDescriptions[videoId]
        });
    };

    /**
     * @function renderYouTubeVideos
     * Accepts an array of youtube posts, maps through and renders
     * each post as its own YoutubePost component
     * @param {Array} videoPosts - array of affiliate youtube posts
     * @returns {Component}
     */
    const renderYouTubeVideos = () => {
        if (posts && Array.isArray(posts) && posts.length > 0) {
            return (
                <ul className="affiliate-tab__youtube-posts">
                    {
                        posts.map((video) => {
                            let duration = '';
                            let timeUnits = video.duration.match(/(\d+H)?(\d+M)?(\d+S)/);
                            timeUnits.shift();
                            timeUnits = timeUnits.map((timeUnit) => {
                                if (timeUnit) {
                                    return parseInt(timeUnit);
                                }
                                return timeUnit;
                            });
                            const [h, m, s] = timeUnits;
                            // use the longest time unit as the duration and round up if the next unit is more than 50
                            if (h) {
                                duration = (m < 50) ? `${h} hour` : `${h + 1} hour`;
                            } else if (m) {
                                duration = (s < 50) ? `${m} minute` : `${m + 1} minute`;
                            } else {
                                duration = (s < 50) ? `${s} second` : '1 minute';
                            }

                            const description = video.description.replace(/<.+?>/g, '').replace(/\n/g, '<br />');

                            return (
                                <li className="post" key={video.id}>
                                    <p className="label"><img src={youtubeChannel.channelPhoto} alt={`${youtubeChannel.name}'s profile pic`} width="20" height="20" /> {youtubeChannel.name} uploaded a {duration} video</p>
                                    <p className="content">{video.title}</p>
                                    <iframe width="516" height="290" src={`https://www.youtube.com/embed/${video.id}`} title={video.title} frameBorder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                                    <p className="description" dangerouslySetInnerHTML={{ __html: description }} data-is-expanded={expandedDescriptions[video.id]} />
                                    {description && <p className="more-description" onClick={() => handleExpandDescription(video.id)}>Read {(!expandedDescriptions[video.id]) ? 'More' : 'Less'}</p>}
                                    <div className="metrics">
                                        <ul>
                                            <li>{formatMetrics(video.statistics.viewCount)} View{ video.statistics.viewCount !== 1 && 's'}</li>
                                            <li>{formatMetrics(video.statistics.likeCount)} Like{ video.statistics.likeCount !== 1 && 's'}</li>
                                            <li>{formatMetrics(video.statistics.dislikeCount)} Dislike{ video.statistics.dislikeCount !== 1 && 's'}</li>
                                            <li>{formatMetrics(video.statistics.commentCount)} Comment{ video.statistics.commentCount !== 1 && 's'}</li>
                                        </ul>
                                        <p><a href={`https://youtube.com/watch?v=${video.id}`} target="_blank" rel="noopener noreferrer"><time dateTime={video.publishedAt}>{moment(video.publishedAt).format('MMM D, YYYY')}</time></a></p>
                                    </div>
                                </li>
                            );
                        })
                    }
                </ul>
            );
        }
        return <div className="affiliate-tab__empty">There are no videos to display.</div>;
    };

    const selectOptions = youTubeLiveData.map((item) => {
        return {
            label: item.name,
            value: item.id
        };
    });

    return (
        <>
            {youTubeLiveData.length > 1
                && (
                    <div className="account-switcher">
                        <p className="num-pages">{props.affiliate.name} reaches {formatMetrics(totalReach)} people across {youTubeLiveData.length} channel{youTubeLiveData.length !== 1 && 's'}</p>
                        <div className="current-account">
                            <Select
                                label="Choose Channel:"
                                labelInline
                                options={selectOptions}
                                value={youtubeChannel.id}
                                onChange={handleSelectAccount}
                            />
                        </div>
                    </div>
                )
            }
            <div className="affiliate-youtube" data-test="component-youtubeDetail">
                <Card.Section>
                    {fromCache && <Banner status="info">Oops, we&rsquo;re having some trouble connecting to YouTube. These may not be the most recent videos.</Banner>}
                    <div className="affiliate-tab__top">
                        <p className="affiliate-tab__user-info">
                            <img className="youtube-photo" src={youtubeChannel.channelPhoto} alt={`${youtubeChannel.name}'s profile pic`} />
                            <span className="affiliate-tab__title">
                                <span className="affiliate-tab__name">{youtubeChannel.name}</span>
                                <span className="affiliate-tab__stat-label">{youtubeChannel.kidsOnly && <Badge status="info">Kids Only Channel</Badge> }{youtubeChannel.keywords}</span>
                            </span>
                        </p>
                        <a href={youtubeLink} target="_blank" rel="nofollow noopener noreferrer" className="affiliate-tab__link-button">
                            View on YouTube <Icon source={ExternalSmallMinor} />
                        </a>
                    </div>
                </Card.Section>
                <Card.Section>
                    <div className="affiliate-tab__row">
                        <div className="affiliate-tab__stats affiliate-tab__stats-youtube">
                            <div className="affiliate-tab__stat-item">
                                <h2 className="affiliate-tab__stat-num">
                                    {formatMetrics(youtubeChannel.subscribers)}
                                </h2>
                                <p className="affiliate-tab__stat-label">
                                    Subscriber{youtubeChannel.subscribers !== 1 && 's'}
                                </p>
                            </div>
                            <div className="affiliate-tab__stat-item">
                                <h2 className="affiliate-tab__stat-num">
                                    {formatMetrics(youtubeChannel.numVideos)}
                                </h2>
                                <p className="affiliate-tab__stat-label">
                                    Video{youtubeChannel.numVideos !== 1 && 's'}
                                </p>
                            </div>
                            <div className="affiliate-tab__stat-item">
                                <h2 className="affiliate-tab__stat-num">
                                    {formatMetrics(youtubeChannel.views)}
                                </h2>
                                <p className="affiliate-tab__stat-label">
                                    View{youtubeChannel.views !== 1 && 's'}
                                </p>
                            </div>
                            <div className="affiliate-tab__stat-item">
                                <h2 className="affiliate-tab__stat-num">
                                    {formatMetrics(youtubeChannel.avgViews)}
                                </h2>
                                <p className="affiliate-tab__stat-label">
                                    Avg. View{youtubeChannel.avgViews !== 1 && 's'}<Tooltip content="Based on the latest 50 videos"><span className="info-tooltip">i</span></Tooltip>
                                </p>
                            </div>
                            <div className="affiliate-tab__stat-item">
                                <h2 className="affiliate-tab__stat-num">
                                    {formatMetrics(youtubeChannel.avgLikes)}
                                </h2>
                                <p className="affiliate-tab__stat-label">
                                    Avg. Like{youtubeChannel.avgLikes !== 1 && 's'}<Tooltip content="Based on the latest 50 videos"><span className="info-tooltip">i</span></Tooltip>
                                </p>
                            </div>
                            <div className="affiliate-tab__stat-item">
                                <h2 className="affiliate-tab__stat-num">
                                    {formatMetrics(youtubeChannel.avgComments)}
                                </h2>
                                <p className="affiliate-tab__stat-label">
                                    Avg. Comment{youtubeChannel.avgComments !== 1 && 's'}<Tooltip content="Based on the latest 50 videos"><span className="info-tooltip">i</span></Tooltip>
                                </p>
                            </div>
                            <div className="affiliate-tab__stat-item">
                                <h2 className="affiliate-tab__stat-num">
                                    {showLetterGrade(youtubeChannel.score)}
                                </h2>
                                <p className="affiliate-tab__stat-label">
                                    Engagement Score
                                </p>
                            </div>
                        </div>
                    </div>
                </Card.Section>
                <Card.Section>
                    <div className="affiliate-tab__posts">
                        {renderYouTubeVideos()}
                        {!hasNext
                            && (
                                <div className="more-posts">
                                    <EmptyState message="Looking for more videos?" paragraph={`These are all the videos we graded ${youtubeChannel.name} on. If you need to look deeper, you’ll have to go to their YouTube page.`} ctaText="View more posts on YouTube" ctaAction={goToYouTube} />
                                </div>
                            )
                        }
                    </div>
                    <div className="affiliate-tab__page-controls">
                        {maxPages > 0
                            && (
                                <LlamaPagination
                                    hasNext={hasNext}
                                    hasPrevious={hasPrevious}
                                    onNext={nextPage}
                                    onPrevious={previousPage}
                                    currentPage={currentPage}
                                    maxPages={maxPages}
                                    data-test="component-llamaPagination"
                                />
                            )
                        }
                    </div>
                </Card.Section>
            </div>
        </>
    );
};

export default YoutubeAffiliateDetail;
