import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DataTable, TextStyle } from '@shopify/polaris';
import { ResponsiveBar } from '@nivo/bar';
import { subDays, addDays, format } from 'date-fns';

import { moneyFormat } from 'llama-library/utils';
import { getLeftTicks, formatBottomTickValues } from '../../utils/graph-ticks';

import { getCurrentSales } from '../../actions/reports';

import useResponsive from '../../hooks/use-responsive';

import './dashboard-stats.css';

const createEmptyDateArray = (startDate, endDate) => {
    const dates = {};
    const i = new Date(startDate);

    while (i < endDate) {
        const year = i.getFullYear();
        const month = (`0${(i.getMonth() + 1)}`).slice(-2);
        const day = (`0${i.getDate()}`).slice(-2);
        dates[`${year}-${month}-${day}`] = 0;
        i.setDate(i.getDate() + 1);
    }
    return dates;
};

const DashboardStats = ({ advertiser_id }) => {
    const now = new Date();
    const endDate = addDays(now, 1);
    const startDate = subDays(endDate, 30);

    const emptyDates = createEmptyDateArray(startDate, endDate);

    const [reports, setReports] = useState({
        product: [],
        affiliate: [],
        sales: []
    });

    const pageWidth = useResponsive();

    const mapAffiliateSales = (affiliateData) => {
        if (!Array.isArray(affiliateData) || affiliateData.length === 0) {
            return [];
        }
        return affiliateData.map((item) => {
            const revenue = parseFloat(item.volume / 100).toFixed(2);
            const name = item.affiliate ? item.affiliate.name : '';
            return [
                name,
                item.sales,
                item.products_sold,
                `$ ${revenue}`
            ];
        });
    };

    const mapSalesData = (salesData) => {
        if (!salesData || salesData.length === 0) {
            return [];
        }

        const orderTotals = salesData
            .reduce((acc, next) => {
                const { date } = next;
                const subtotal = parseFloat(next.volume / 100);

                // If date is not in object, return acc.
                if (acc[date] === undefined) {
                    return acc;
                }

                // If date is in object, increment value.
                acc[date] += subtotal;
                return acc;
            }, emptyDates);

        return Object.entries(orderTotals).map(([key, value]) => {
            const dateValues = key.split('-');
            const date = new Date(dateValues[0], dateValues[1] - 1, dateValues[2], 12);
            return {
                date,
                value
            };
        });
    };

    const mapProductSales = (productData) => {
        if (!Array.isArray(productData) || productData.length === 0) {
            return [];
        }
        return productData.map((item) => {
            const revenue = parseFloat(item.volume / 100).toFixed(2);
            return [
                item.title,
                item.quantity,
                `$ ${revenue}`
            ];
        });
    };

    useEffect(() => {
        getCurrentSales(advertiser_id, startDate, endDate)
            .then(({ data }) => {
                if (
                    !data
                    || !data.advertisers
                    || !Array.isArray(data.advertisers)
                    || !data.advertisers[0].reports
                ) {
                    return;
                }

                const { productData, affiliateData, salesData } = data.advertisers[0].reports;
                setReports({
                    product: mapProductSales(productData),
                    affiliate: mapAffiliateSales(affiliateData),
                    sales: mapSalesData(salesData)
                });
            });
    }, []);

    const highestValue = reports.sales.reduce((acc, next) => {
        if (next.value > acc) {
            return next.value;
        }
        return acc;
    }, 0);

    const leftTickValues = getLeftTicks(11, highestValue);

    const axisLeft = {
        tickValues: leftTickValues,
        legend: 'Amount ($)',
        legendOffset: -60,
        legendPosition: 'middle',
        format: (x) => {
            return moneyFormat(x, true);
        }
    };

    const bottomTickValues = formatBottomTickValues(reports.sales.map((item) => {
        return item.date;
    }));

    const axisBottom = {
        tickValues: bottomTickValues,
        format: (date) => {
            return format(date, 'LL/dd');
        }
    };

    const salesTotal = reports.sales.reduce((acc, item) => {
        return acc + item.value;
    }, 0);

    // If there is no sales data for the past 30 days show nothing
    if (salesTotal === 0 && reports.product.length === 0) {
        return (
            <div className="dashboard-empty-graphs">
                {' '}
            </div>
        );
    }

    return (
        <div className="dashboard-sales-graphs">
            <div className="revenue">
                <h2 className="revenue_chart_title">Revenue for the last 30 days</h2>
                <div className="nivo">
                    <ResponsiveBar
                        data={reports.sales}
                        margin={{
                            top: pageWidth > 790 ? 50 : 20,
                            right: pageWidth > 790 ? 50 : 20,
                            bottom: 60,
                            left: pageWidth > 790 ? 80 : 60
                        }}
                        indexBy="date"
                        colors="#EB4159"
                        axisBottom={axisBottom}
                        enableLabel={false}
                        axisLeft={axisLeft}
                        gridYValues={leftTickValues}
                        tooltip={({ data, value, color }) => {
                            return (
                                <div className="nivo-graph-tooltip-wrapper">
                                    <span className="nivo-graph-tooltip-date">{format(data.date, 'LLL d, yyyy')}</span>
                                    <span className="nivo-graph-tooltip-item">
                                        <span className="nivo-graph-tooltip-color-block" style={{ background: color }} />
                                        Revenue: <span style={{ fontWeight: 'bold' }}>{moneyFormat(value)}</span>
                                    </span>
                                </div>
                            );
                        }}
                    />
                </div>
            </div>

            <div className="product-sales-table">
                <h2 className="product_sales_title">Product Sales</h2>
                {reports.product.length > 0
                    ? (
                        <DataTable
                            columnContentTypes={[
                                'text',
                                'numeric',
                                'numeric'
                            ]}
                            headings={[
                                'Product Name',
                                'Quantity',
                                'Revenue'
                            ]}
                            rows={reports.product}
                            footerContent={`Showing ${reports.product.length} of ${reports.product.length} results`}
                        />
                    )
                    : (
                        <div className="dashboard__empty-set">
                            <TextStyle variation="subdued">You haven&rsquo;t had any sales lately</TextStyle>
                        </div>
                    )
                }
            </div>

            <div className="ambassador-sales-table">
                <h2 className="ambassador_sales_title">Ambassador Sales</h2>
                {reports.affiliate.length
                    ? (
                        <DataTable
                            columnContentTypes={[
                                'text',
                                'numeric',
                                'numeric',
                                'numeric'
                            ]}
                            headings={[
                                'Ambassador Name',
                                'Orders',
                                'Items Sold',
                                'Revenue'
                            ]}
                            rows={reports.affiliate}
                            footerContent={`Showing ${reports.affiliate.length} of ${reports.affiliate.length} results`}
                        />
                    )
                    : (
                        <div className="dashboard__empty-set">
                            <TextStyle variation="subdued">Your ambassadors haven&rsquo;t produced any sales lately</TextStyle>
                        </div>
                    )
                }
            </div>
        </div>
    );
};

DashboardStats.propTypes = {
    advertiser_id: PropTypes.string.isRequired
};

export default DashboardStats;
