import React, { useState } from 'react';
import './campaign-links.css';
import {
    TextStyle,
    Select,
    Button,
    DisplayText,
    Frame,
    Toast
} from '@shopify/polaris';
import { DuplicateMinor } from '@shopify/polaris-icons';
import CenteredSpinner from '../centered-spinner';
import {EmptyState} from 'llama-library/components';

import { generateTrackingLink } from '../../actions/generate-link';

/**
 * @function CampaignLinks
 * Accepts the following params, returns CampaignLinks Component.
 * Displays an ambassador's campaign links. 
 * Gives user the ability to generate ambassador
 * links and copy to their clipboard.
 * 
 * @param {Array} applications 
 * @param {Object} offer
 * @param {Object} advertiser
 * 
 * @returns {FunctionComponent}
 */
const CampaignLinks = ({ applications, offer, advertiser, history }) => {

    const [selectedAffiliate, setSelectedAffiliate] = useState('');
    const [link, setLink] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [toastVerbiage, setToastVerbiage] = useState(null);

    if (!applications || !offer || !offer.offer_id) {
        return <div className="campaign-links">
            <DisplayText size="small"><span style={{fontWeight: 'bold'}}>AMBASSADOR LINKS</span></DisplayText>
            <CenteredSpinner />
        </div>
    }

    const copyLinkToclipboard = (link) => {
        let copyToClipboard;
        if (navigator.clipboard) {
            copyToClipboard = navigator.clipboard.writeText(link)
        } else {
            // For versions of Safari older than 13
            const textArea = document.createElement("textarea");
            textArea.value = link;
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();

            copyToClipboard = new Promise((resolve, reject) => {
                const copySuccess = document.execCommand('copy');
                if (copySuccess) {
                    resolve("Resolved")
                } else {
                    reject("Unable to execute copy command")
                }  
            })

            document.body.removeChild(textArea);
        }
        
        copyToClipboard
        .then(() => {
            setToastVerbiage("Link copied to clipboard")
        })
        .catch(err => {
            console.log("Error copying link:", err);
            setToastVerbiage("Oops, something went wrong. Unable to copy link.")
        })
    }

    const handleChange = (value) => {
        setSelectedAffiliate(value);
        setIsLoading(true);
        setLink(null);
        generateTrackingLink(offer.offer_id, value)
            .then(({ generateTrackingLink }) => {
                setLink(generateTrackingLink);
                setIsLoading(false);
            })
            .catch(() => setIsLoading(false));
    }

    if(applications.length === 0){
        return (
            <EmptyState
                message="You haven’t approved any ambassadors for this offer"
                paragraph="If ambassadors aren’t applying to promote your offer yet, try inviting them yourself. I can help you find the best ones." ctaText="Discover Ambassadors"
                ctaAction={() => history.push("/discover")}
            />
        )
    }

    const affiliates = applications
        .map((item) => {
            if (!item.affiliate) {
                return null;
            }

            return {
                label: item.affiliate.name,
                value: item.affiliate.tracking_id
            }
        })
        .filter((item) => item !== null);
    affiliates.unshift({ label: '-- Select Ambassador --', value: '' });

    const renderLink = () => {
        if (!selectedAffiliate || isLoading) {
            return null;
        }

        if (link && link.click_url) {
            return (
                <div className="campaign-links__display">
                    <div style={{ wordWrap: "break-word", wordBreak: 'break-word', overflowWrap: 'break-word' }}>
                        <TextStyle variation="strong">LINK</TextStyle>
                        <div>{link.click_url}</div>
                    </div>
                    <div className="campaign-links__copy-button">
                        <Button icon={DuplicateMinor} onClick={() => { return copyLinkToclipboard(link.click_url); }}>Copy</Button>
                    </div>
                </div>
            );
        }

        return <div className="campaign-links__display">Oops, something went wrong and we weren&rsquo;t able to load the tracking link. If this problem persists, please contact us.</div>;
    };

    return (
        <div className="campaign-links">
            <DisplayText size="small"><span style={{fontWeight: 'bold'}}>AMBASSADOR LINKS</span></DisplayText>
            <div className="campaign-links__input-field">
                <Select
                    onChange={(value) => handleChange(value)}
                    id="affiliate-link"
                    options={affiliates}
                    placeholder=""
                    value={selectedAffiliate}
                    label="Ambassador"
                    disabled={isLoading}
                    helpText={isLoading ? 'Loading Link...' : null}
                />
            </div>
            {renderLink()}
            <Frame>
                {toastVerbiage ? <Toast onDismiss={() => setToastVerbiage(null)} content={toastVerbiage} /> : null}
            </Frame>
        </div>
    )

}

export default CampaignLinks;