import React from 'react';
import { connect } from 'react-redux';
import './settings-ltv.css';
import {
    Frame,
    Toast,
    Banner,
} from '@shopify/polaris';

import { updateAdvertiser } from '../../actions/update-advertiser';
import { getAdvertiser } from '../../actions/advertiser';

import LookForwardSlider from './look-forward-slider';
import LookBackSlider from './look-back-slider';

import LookForwardInsightsSkeleton from './ltv-skeleton';
import LookForwardInsights from './look-forward-insights';

import numberFormat from '../../utils/number-format';

import { getLTVOrders } from '../../actions/get-ltv-orders';

/**
 * @class SettingsLTV
 * Holds state for Merchant LTV Data.
 * Renders LookBack and LookForward LTV Data as nivo ResponsiveBars.
 * Holds controls for setting new LookBack and LookForward Settings.
 * 
 * @returns {ComponentClass}
 */
class SettingsLTV extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            forwardSliderValue: null,
            backSliderValue: null,
            initialForwardSliderValue: 12,
            initialBackSliderValue: 12,
            LTVOrdersNew: [],
            LTVOrdersLoaded: false,
            lockedOut: {
                oneTime: 0,
                repeat: 0
            },
            initialLockedOut: {
                oneTime: 0,
                repeat: 0
            },
            salesData: {
                totalSales: 0,
                totalOrders: 0,
                totalNonRepeatOrders: 0,
                totalNonRepeatSales: 0,
                totalRepeatSales: 0,
                totalRepeatOrders: 0,
                totalRepeatCustomers: 0,
                totalNonRepeatCustomers: 0
            },
            ltvMonths: 6,
            ltvGraphData: [
                {
                    "storeType": "One-Time Customers",
                    "Estimated Lifetime Value": 0,
                    "Estimated Lifetime ValueColor": "#fc2c58"
                },
                {
                    "storeType": "Repeat Customers",
                    "Estimated Lifetime Value": 0,
                    "Estimated Lifetime ValueColor": "#00a313"
                }
            ],
            lfLockedDays: 0,
            lbLockedDays: 0,
            toastVerbiage: null
        }
    };

    componentDidMount(){
        const pullSince = new Date();
        const dateSub = pullSince.getDate() - 180;
        pullSince.setDate(dateSub);

        this.props.getLTVOrders(this.props.advertiser.advertiser_id, pullSince.toISOString()).then((result) => {
            let hasLookBack = false;
            let hasLookForward = false;

            if(this.props.advertiser.timeline_settings && this.props.advertiser.timeline_settings.lookback && this.props.advertiser.timeline_settings.lookback.time){
                hasLookBack = true;
                const savedLookBack = parseInt(this.props.advertiser.timeline_settings.lookback.time)
                const lockedDays = this.settingsLocked('lookback');
    
                this.setState({
                    backSliderValue: savedLookBack,
                    initialBackSliderValue: savedLookBack,
                    lbLockedDays: lockedDays
                })
            }else{
                this.setState({
                    backSliderValue: 12
                })
            }

            if(this.props.advertiser.timeline_settings && this.props.advertiser.timeline_settings.lookforward && this.props.advertiser.timeline_settings.lookforward.time){
                hasLookForward = true;
                const savedLookForward = parseInt(this.props.advertiser.timeline_settings.lookforward.time)
                const lockedDays = this.settingsLocked('lookforward');
    
                this.setState({
                    forwardSliderValue: savedLookForward,
                    initialForwardSliderValue: savedLookForward,
                    lfLockedDays: lockedDays
                })
            }else{
                this.setState({
                    forwardSliderValue: 12
                })
            }


            if(result && result.value && result.value.data && result.value.data.orders && result.value.data.orders.length > 0){
                this.setState({
                    LTVOrdersNew: result.value.data.orders,
                    LTVOrdersLoaded: true
                }, () => {
                    if(hasLookBack){
                        this.getExcludedCustomers();
                    }
            
                    if(hasLookForward){
                        this.getOrderDataSet();
                    }
                })
            }else if(result && result.value && result.value.data && result.value.data.orders && result.value.data.orders.length === 0){
                this.setState({
                    LTVOrdersLoaded: true
                })
            }
        })
    }

    //on initial load, figure how many repeat vs one-time customers they had for the past 6 months or less worth of data
    getExcludedCustomers = () => {
        const now = new Date();

        let seenEmails = [];
        let oneTimeCustomers = [];
        let repeatCustomers = [];
        
        if(this.state.LTVOrdersNew.length > 0){
            for(let i = 0; i < this.state.LTVOrdersNew.length; i++){
                const order = this.state.LTVOrdersNew[i];
                
                if(order.customer && order.customer.id){
                    //if we haven't seen the customer id, assume it's one-time
                    if(!seenEmails.includes(order.customer.id)){
                        oneTimeCustomers.push(order.customer.id)
                        seenEmails.push(order.customer.id);
                    //if we've seen the id, that means they are repeat, so add one instance to the repeat list and remove it from the one-time list
                    }else{
                        if(!repeatCustomers.includes(order.customer.id)){
                            repeatCustomers.push(order.customer.id)
                            oneTimeCustomers.splice(oneTimeCustomers.indexOf(order.customer.id), 1);
                        }
                    }
                }
            }
            //const earliestDate = new Date(this.state.LTVOrdersNew[this.state.LTVOrdersNew.length - 1].processed_at);

            const lockedOut = {
                repeat: repeatCustomers.length,
                oneTime: oneTimeCustomers.length
            }

            //figure out how many months of data there is
            /* const earliestYear = earliestDate.getFullYear();
            const thisYear = now.getFullYear();
            const earliestMonth = earliestDate.getMonth();
            const thisMonth = now.getMonth();

            const lbMonths = thisMonth - earliestMonth + (12 * (thisYear - earliestYear)); */
    
            this.setState({
                initialLockedOut: lockedOut,
                //lbMonths
            }, () => this.calculateLookBackCustomers(this.state.backSliderValue))
        }
    }

    //prep data set for projected calculations
    getOrderDataSet = () => {
        if(this.state.LTVOrdersNew.length > 0){
            let dataSet = this.state.salesData
            dataSet.totalOrders = this.state.LTVOrdersNew.length;
            
            let customers = {}

            let orders = this.state.LTVOrdersNew;
            for(let i=0; i < orders.length; i++){
                dataSet.totalSales += parseFloat(orders[i].total_price);
                if(orders[i].customer && orders[i].customer.id){
                    if(!customers[orders[i].customer.id]) customers[orders[i].customer.id] = [];
                    customers[orders[i].customer.id].push(orders[i].total_price);
                }
            }
            for(let id in customers){
                let totals = customers[id];
                if(totals.length === 1){
                    dataSet.totalNonRepeatSales+= parseFloat(totals[0])
                    dataSet.totalNonRepeatOrders++;
                    dataSet.totalNonRepeatCustomers++;
                }else{
                    const totals_sum = totals.reduce((prevSale, nextSale) => {
                        return parseFloat(nextSale) + parseFloat(prevSale)
                    });
                    dataSet.totalRepeatSales+= totals_sum
                    dataSet.totalRepeatOrders+= totals.length;
                    dataSet.totalRepeatCustomers++;
                }
            }
            this.setState({
                salesData: dataSet
            }, () => this.calculateLookForwardLTV(this.state.forwardSliderValue))
        }
    }

    calculateLookForwardLTV = (months) => {
        const ltvNonRepeat = (this.state.salesData.totalNonRepeatCustomers > 0) ? (parseFloat((this.state.salesData.totalNonRepeatSales / this.state.salesData.totalNonRepeatCustomers / this.state.ltvMonths * months).toFixed(2))) : 0;
        const ltvRepeat = (this.state.salesData.totalRepeatCustomers > 0) ? (parseFloat((this.state.salesData.totalRepeatSales / this.state.salesData.totalRepeatCustomers / this.state.ltvMonths * months).toFixed(2))) : 0;

        let graphData = this.state.ltvGraphData;
        graphData[0]['Estimated Lifetime Value'] = ltvNonRepeat;
        graphData[1]['Estimated Lifetime Value'] = ltvRepeat;

        const avgOrderValue = {}
        avgOrderValue.all = (this.state.salesData.totalOrders > 0) ? '$'+numberFormat(this.state.salesData.totalSales / this.state.salesData.totalOrders / this.state.ltvMonths * months, 2) : '$0.00';
        avgOrderValue.one = (this.state.salesData.totalNonRepeatOrders > 0) ? '$'+numberFormat(this.state.salesData.totalNonRepeatSales / this.state.salesData.totalNonRepeatOrders / this.state.ltvMonths * months, 2) : '$0.00';
        avgOrderValue.repeat = (this.state.salesData.totalRepeatOrders > 0) ? '$'+numberFormat(this.state.salesData.totalRepeatSales / this.state.salesData.totalRepeatOrders / this.state.ltvMonths * months, 2) : '$0.00';

        const commissionStructs = [5, 10, 15, 20, 25];
        let commissions = []
        for(let i = 0; i < commissionStructs.length; i++){
            const rate = this.state.salesData.totalNonRepeatSales > 0 && this.state.salesData.totalNonRepeatCustomers > 0 
                ? this.state.salesData.totalNonRepeatSales / this.state.salesData.totalNonRepeatCustomers / this.state.ltvMonths * months * (commissionStructs[i] / 100) 
                : 0;
            const percent = commissionStructs[i];

            if(!commissions[percent]) commissions[percent] = {}
            commissions[percent].rate = '$'+numberFormat(rate, 2);

            commissions[percent].initialSale = this.state.salesData.totalNonRepeatOrders > 0 ? '$'+numberFormat(this.state.salesData.totalNonRepeatOrders / this.state.ltvMonths * months * rate, 2) : '$0.00';
           
            commissions[percent].longtermSale = this.state.salesData.totalRepeatOrders > 0 ? '$'+numberFormat(this.state.salesData.totalRepeatOrders / this.state.ltvMonths * months * rate, 2) : '$0.00';
        }

        this.setState({
            ltvGraphData: graphData,
            avgOrderValue: avgOrderValue,
            commissions: commissions
        })
    }

    //calculates the number of one time customers and repeat customers that will be locked out
    calculateLookBackCustomers = (months) => {
        let initialLocked = JSON.parse(JSON.stringify(this.state.initialLockedOut));
        const ltvMonths = this.state.ltvMonths;

        initialLocked.repeat = parseFloat((initialLocked.repeat / ltvMonths * months).toFixed(1))
        initialLocked.oneTime = parseFloat((initialLocked.oneTime / ltvMonths * months).toFixed(1));

        this.setState({
            lockedOut: initialLocked
        })
    }

    //calculates the number of days bewtween for the 30 day limit to save states
    numberDaysBewteen = (d1, d2) => {
        let diff = Math.abs(d1.getTime() - d2.getTime());
        return diff / (1000 * 60 * 60 *24);
    };

    //checks if settings are locked in for the given type (lookforward or lookback) and returns the amount of days it's locked for
    settingsLocked = (type) => {
        let now = new Date();
        let edited = new Date(this.props.advertiser.timeline_settings[type].edited);
        if(this.numberDaysBewteen(now, edited) > 30 ){
            return 0
        }else{
            return (30 - this.numberDaysBewteen(now, edited)).toFixed(0);
        }
    }

    submitAdvertiserUpdate = (payload, type) => {
        return this.props.updateAdvertiser(payload, this.props.advertiser)
            .then(() => {
                const lockedDays = this.settingsLocked(type)
                if(type == 'lookforward'){
                    this.setState({
                        lfLockedDays: lockedDays,
                        initialForwardSliderValue: parseInt(payload.timeline_settings.lookforward.time)
                    })
                }else{
                    this.setState({
                        lbLockedDays: lockedDays,
                        initialBackSliderValue: parseInt(payload.timeline_settings.lookback.time)
                    })
                }
                this.setToastVerbiage('Saved');
            })
    }
    
    setToastVerbiage = (toastVerbiage) => {
        this.setState({ toastVerbiage })
    }

    render(){
        const toastMarkup = this.state.toastVerbiage ? <Toast content={this.state.toastVerbiage} onDismiss={() => this.setToastVerbiage(null)}/> : null;

        return (
            <div className="settings-ltv__wrapper">
                <section className="lookforward">
                    <h2>Look Forward Period</h2>
                    {this.state.lfLockedDays > 0 && <Banner status="info">Look Forward Period is currently locked in and cannot be changed for {this.state.lfLockedDays} days.</Banner>}
                    <p className="intro">Use the slider below to define how long an ambassador can continue earning commissions from each referred customer.</p>
                    
                    <LookForwardSlider 
                        lockedDays={this.state.lfLockedDays}
                        sliderValue={this.state.forwardSliderValue} 
                        initialValue={this.state.initialForwardSliderValue} 
                        calculateLookForwardLTV={this.calculateLookForwardLTV}
                        submitAdvertiserUpdate={this.submitAdvertiserUpdate}
                        setToastVerbiage={this.setToastVerbiage} 
                    />
                    <aside className="breakdown">
                        <h3>Look Forward Insights</h3>
                        {!this.state.LTVOrdersLoaded 
                            ? <LookForwardInsightsSkeleton 
                                ltvGraphData={this.state.ltvGraphData} 
                                forwardSliderValue={this.state.forwardSliderValue}
                                ltvMonths={this.state.ltvMonths}
                              />
                            : <LookForwardInsights 
                                ltvGraphData={this.state.ltvGraphData} 
                                forwardSliderValue={this.state.forwardSliderValue}
                                ltvMonths={this.state.ltvMonths}
                                salesData={this.state.salesData}
                                avgOrderValue={this.state.avgOrderValue}
                                commissions={this.state.commissions}
                              />
                        }
                    </aside>
                </section>

                <section className="lookback">
                    <h2>Look Back Period</h2>
                    {this.state.lbLockedDays > 0 && <Banner status="info">Look Back Period is currently locked in and cannot be changed for {this.state.lbLockedDays} days.</Banner>}
                    <p className="intro">Use the slider below to define the amount of time before each of your existing customers can be eligible for ambassadors to refer and earn commission from them.</p>
                    <LookBackSlider 
                        lockedDays={this.state.lbLockedDays}
                        sliderValue={this.state.backSliderValue} 
                        initialValue={this.state.initialBackSliderValue}
                        calculateLookBackCustomers={this.calculateLookBackCustomers}
                        LTVOrdersLoaded={this.state.LTVOrdersLoaded}
                        lockedOut={this.state.lockedOut}
                        submitAdvertiserUpdate={this.submitAdvertiserUpdate}
                        setToastVerbiage={this.setToastVerbiage}
                    />
                </section>

                <Frame>{toastMarkup}</Frame>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    console.log("advertiser:::", state.advertiser)
	return {
      advertiser: state.advertiser
	}
  }
  
  const mapDispatchToProps = (dispatch) => {
	return {
        updateAdvertiser: (data, advertiser) => dispatch(updateAdvertiser(data, advertiser)),
        getAdvertiser: (shop) => dispatch(getAdvertiser(shop)),
        getLTVOrders: (advid, earliest_date) => dispatch(getLTVOrders(advid, earliest_date))
	}
  }

export default connect( mapStateToProps, mapDispatchToProps )(SettingsLTV);