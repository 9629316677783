import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Card, Select, Tooltip, Icon, Badge, Banner } from '@shopify/polaris';
import { ExternalSmallMinor } from '@shopify/polaris-icons';
import { LlamaPagination, EmptyState } from 'llama-library/components';

import formatMetrics from '../../utils/format-metrics';
import usePages from '../../hooks/use-pages';

import './facebook-affiliate-detail.css';

import llamaPlaceholder from '../../assets/llama-user-icon.png';

export const checkValidFacebook = (facebookAccount) => {
    if (
        !facebookAccount
        || !facebookAccount.page_ids
        || !facebookAccount.pages 
        || !facebookAccount.postsStats
    ) {
        return false
    }

    return true;
}

/**
 * @function filterDuplicateFacebookAccounts
 * Accepts facebookAccounts array, filters out duplicate accounts, returns
 * normalized array of facebookAccount objects.
 * 
 * @param {Array} facebookAccounts - Array of affiliate facebook accounts
 * 
 * @returns {Array}
 */
const filterDuplicateFacebookAccounts = (facebookAccounts) => {

    if (!facebookAccounts) {
        return null;
    }

    if (!Array.isArray(facebookAccounts)) {
        facebookAccounts = [facebookAccounts]
    }

    const normalizedData = facebookAccounts
        //Mapping through facebookAccounts to check if it contains an array page_ids of at least 1 
        .map((item) => {
            if (!item.page_ids || !Array.isArray(item.page_ids) || item.page_ids.length === 0) {
                return null;
            }

            //Mapping through page_ids from facebookAccounts Array, we pass it the pageID
            return item.page_ids.map((pageID) => {
                //Checks for a match between item.id and the pageID
                const findById = (item) => {
                    return item.id === pageID;
                }

                //Checks for match between item ids on page, pageStats and posts
                const page = item.pages.find(findById)
                const pageStats = item.pageStats.find(findById)
                const posts = item.postsStats.find(findById)
                const fromCache = item.fromCache

                //Returns normalizedData object
                return { ...page, ...pageStats, ...posts, fromCache }

            })

        })
        .reduce((acc, userData) => {
            return [ ...acc, ...userData ]
        }, [])

    //Initialize uniqueAccounts as empty array
    let uniqueAccounts = [];
    //Filters through normalizedData to check for duplicate ids
    //If duplicate is not found, the item is pushed to uniqueAccounts array
    return normalizedData.filter((item) => {
        if (uniqueAccounts.includes(item.id)) {
            return false;
        }

        uniqueAccounts.push(item.id);
        return true;
    })


}

/**
 * @function FacebookAffiliateDetail
 * Accepts props, returns FacebookAffiliateDetail Component.
 * Renders the facebook posts, pagination for posts, account selection controller.
 * 
 * @param {Object} props
 * 
 * @returns {FunctionComponent}
 */
const FacebookAffiliateDetail = (props) => {
    console.log('FACEBOOK AFFILIATE DETAIL PROPS', props);
    
    let facebookLiveData = filterDuplicateFacebookAccounts(props.affiliate.facebookData);
    if (!facebookLiveData || !Array.isArray(facebookLiveData) || facebookLiveData.length === 0) {
        facebookLiveData = [null];
    }

    let primaryAccount = facebookLiveData.find((page) => {
        return props.affiliate.facebook === page.id;
    });

    if (!primaryAccount) {
        primaryAccount = facebookLiveData[0];
    }

    let totalReach = 0;
    if (primaryAccount.fan_count) {
        totalReach = facebookLiveData.reduce((acc, page) => {
            return acc + page.fan_count;
        }, 0);
    }

    console.log('FACEBOOK AFFILIATE DETAIL PROPS', facebookLiveData);

    const [facebookAccount, setFacebookAccount] = useState(primaryAccount);
    const [facebookPosts, setFacebookPosts] = useState([]);

    const handleSelectAccount = (value) => {
        const newAccount = facebookLiveData.find((item) => {
            return item.id === value;
        });
        console.log('ACCOUNT SWITCH', newAccount);
        if (newAccount) {
            setFacebookAccount(newAccount);
            if (newAccount.posts) {
                setFacebookPosts(newAccount.posts);
                setPageTo(0);
            } else {
                setFacebookPosts([]);
                setPageTo(0);
            }
            window.scrollTo(0, 0);
        }
    };

    useEffect(() => {
        // Set up facebook posts
        if (facebookAccount && facebookAccount.posts) {
            setFacebookPosts(facebookAccount.posts);
        } else {
            setFacebookPosts([]);
        }
    }, []);

    const {
        previousPage,
        nextPage,
        currentPage,
        hasNext,
        hasPrevious,
        maxPages,
        posts,
        setPageTo
    } = usePages(0, 5, facebookPosts);

    if (!facebookLiveData || !Array.isArray(facebookLiveData) || facebookLiveData.length === 0) {
        return null;
    }

    const goToFacebook = () => {
        window.open(`https://facebook.com/${facebookAccount.id}`);
    };

    // if(!checkValidFacebook(facebookAccount)) {
    //     return null;
    // }

    const showLetterGrade = (score) => {
        if (score >= 81) {
            return <Badge status="success"> A </Badge>;
        }
        if (score <= 80 && score >= 61) {
            return <Badge status="info"> B </Badge>;
        }
        if (score <= 60 && score >= 41) {
            return <Badge status="attention"> C </Badge>;
        }
        if (score <= 40 && score >= 21) {
            return <Badge status="warning"> D </Badge>;
        }
        return <Badge status="warning"> F </Badge>;
    };

    /**
     * Adds appropriate links to message from provided tags
     */
    const formatMessage = (message, tags) => {
        if (!message) {
            return '';
        }

        let formattedMessage = message.replace(/<.+?>/g, '').replace(/\n/g, '<br />');

        if (!tags || !Array.isArray(tags) || tags.length === 0) {
            return formattedMessage;
        }

        tags.forEach((tag) => {
            const facebookUrl = `https://facebook.com/${tag.id}`;
            formattedMessage = formattedMessage.replace(tag.name, `<a href=${facebookUrl} target="_blank" rel="noopener noreferrer">${tag.name}</a>`);
        });

        return formattedMessage;
    };

    const formatAttachmentLabel = (type, url) => {
        if (type === 'share') {
            return <>a <a href={url} target="_blank" rel="noopener noreferrer">link</a></>;
        }

        let newType = type;
        if (type === 'cover_photo') {
            newType = 'photo';
        } else if (type === 'video_inline') {
            newType = 'video';
        }

        return <>another page&rsquo;s <a href={url} target="_blank" rel="noopener noreferrer">{newType}</a></>;
    };

    const formatAttachmentTitle = (title, url) => {
        if (title) {
            return <p className="op">{title}</p>;
        }

        // if the url is a facebook url and contains a slug that isn't a numeric id, use that as the title since we can't access the actual page name
        const slug = url.match(/https:\/\/www\.facebook\.com\/(.+?)\//);
        if (slug[1] && !/^\d+$/.test(slug[1])) {
            return <p className="op">facebook.com/{slug[1]}</p>;
        }

        return '';
    };

    /**
     * @function renderFacebookPosts
     * Accepts array facebookPosts, maps through and renders
     * each post as its own FacebookPost component
     * 
     * @returns {FunctionComponent}
     */
    const renderFacebookPosts = () => {
        if (posts) {
            return (
                <ul className="affiliate-tab__facebook-posts">
                    {
                        posts.map((post) => {
                            const attachment = (post.attachments && post.attachments.data && post.attachments.data[0]) ? post.attachments.data[0] : null;
                            return (
                                <li className="post" key={post.id}>
                                    {post.status_type === 'added_photos'
                                        && (
                                            <>
                                                <p className="label">
                                                    <img
                                                        src={facebookAccount.fromCache ? llamaPlaceholder : facebookAccount.profilePhoto}
                                                        alt={`${facebookAccount.name}'s profile pic`}
                                                        width="20"
                                                        height="20"
                                                    />
                                                    {facebookAccount.name} uploaded a photo
                                                </p>
                                                <p className="content" dangerouslySetInnerHTML={{ __html: formatMessage(post.message, post.message_tags) }} />
                                                <p className="photo"><img src={post.full_picture} alt="" /></p>
                                            </>
                                        )
                                    }
                                    {post.status_type === 'added_video'
                                        && (
                                            <>
                                                <p className="label">
                                                    <img
                                                        src={facebookAccount.fromCache ? llamaPlaceholder : facebookAccount.profilePhoto}
                                                        alt={`${facebookAccount.name}'s profile pic`}
                                                        width="20"
                                                        height="20"
                                                    />
                                                    {facebookAccount.name} uploaded a video
                                                </p>
                                                <p className="content" dangerouslySetInnerHTML={{ __html: formatMessage(post.message, post.message_tags) }} />
                                                {attachment && attachment.media && attachment.media.source
                                                    ? <video controls><source src={attachment.media.source} poster={post.full_picture} /></video>
                                                    : <p className="photo"><img src={post.full_picture} alt="" /></p>
                                                }
                                            </>
                                        )
                                    }
                                    {post.status_type === 'mobile_status_update' && !attachment
                                        && (
                                            <>
                                                <p className="label">
                                                    <img
                                                        src={facebookAccount.fromCache ? llamaPlaceholder : facebookAccount.profilePhoto}
                                                        alt={`${facebookAccount.name}'s profile pic`}
                                                        width="20"
                                                        height="20"
                                                    />
                                                    {facebookAccount.name} updated their status
                                                </p>
                                                <p className="content" dangerouslySetInnerHTML={{ __html: formatMessage(post.message, post.message_tags) }} />
                                            </>
                                        )
                                    }
                                    {post.status_type === 'mobile_status_update' && attachment
                                        && (
                                            <>
                                                <p className="label">
                                                    <img
                                                        src={facebookAccount.fromCache ? llamaPlaceholder : facebookAccount.profilePhoto}
                                                        alt={`${facebookAccount.name}'s profile pic`}
                                                        width="20"
                                                        height="20"
                                                    />
                                                    <span>{facebookAccount.name} shared {formatAttachmentLabel(attachment.type, attachment.url)}</span>
                                                </p>
                                                <p className="content" dangerouslySetInnerHTML={{ __html: formatMessage(post.message, post.message_tags) }} />
                                                <div className="attachment">
                                                    <a href={attachment.url} target="_blank" rel="noopener noreferrer">
                                                        <p className="share-image"><img src={attachment.media.image.src} alt="" /></p>
                                                        <div className="share-content">
                                                            {formatAttachmentTitle(attachment.title, attachment.url)}
                                                            {attachment.type === 'album' && attachment.subattachments && attachment.subattachments.data && attachment.subattachments.data[0]
                                                                && (
                                                                    <ul className="photo-album">
                                                                        {attachment.subattachments.data.map((subAttch) => {
                                                                            return <li key={subAttch.url}><img src={subAttch.media.image.src} alt="" /></li>;
                                                                        })}
                                                                    </ul>
                                                                )
                                                            }
                                                            <p className="text" dangerouslySetInnerHTML={{ __html: formatMessage(attachment.description, attachment.description_tags) }} />
                                                            <p className="read-more">View Content</p>
                                                        </div>
                                                    </a>
                                                </div>
                                            </>
                                        )
                                    }
                                    <div className="metrics">
                                        <ul>
                                            {post.shares && post.shares.count
                                                ? <li>{formatMetrics(post.shares.count)} Share{ post.shares.count !== 1 && 's'}</li>
                                                : <li>0 Shares</li>
                                            }
                                            <li>{formatMetrics(post.comments.summary.total_count)} Comment{ post.comments.summary.total_count !== 1 && 's'}</li>
                                            <li>{formatMetrics(post.reactions.summary.total_count)} Reaction{ post.reactions.summary.total_count !== 1 && 's'}</li>
                                        </ul>
                                        <p><a href={post.permalink_url} target="_blank" rel="noopener noreferrer"><time dateTime={post.created_time}>{moment(post.created_time).format('MMM D, YYYY')}</time></a></p>
                                    </div>
                                </li>
                            );
                        })
                    }
                </ul>
            );
        }
        return '';
    };

    const selectOptions = facebookLiveData.map((item) => {
        return {
            label: item.name,
            value: item.id,
        }
    })

    return (
        <>
            {facebookLiveData.length > 1
                && (
                    <div className="account-switcher">
                        <p className="num-pages">{props.affiliate.name} reaches {formatMetrics(totalReach)} people across {facebookLiveData.length} page{facebookLiveData.length !== 1 && 's'}</p>
                        <div className="current-account">
                            <Select
                                label="Choose Page:"
                                labelInline
                                options={selectOptions}
                                value={facebookAccount.id}
                                onChange={handleSelectAccount}
                            />
                        </div>
                    </div>
                )
            }
            <div className="affiliate-facebook">
                <Card.Section>
                    {facebookAccount.fromCache && <Banner status="info">Oops, we&rsquo;re having some trouble connecting to Facebook. These may not be the most recent posts.</Banner>}
                    <div className="affiliate-tab__top">
                        <p className="affiliate-tab__user-info">
                            <img
                                className="facebook-photo"
                                src={facebookAccount.fromCache ? llamaPlaceholder : facebookAccount.profilePhoto}
                                alt={`${facebookAccount.name}'s profile pic`}
                            />
                            <span className="affiliate-tab__title">
                                <span className="affiliate-tab__name" data-is-facebook-verified={facebookAccount.verification_status === 'blue_verified' || facebookAccount.verification_status === 'gray_verified'}>{facebookAccount.name}</span>
                                <span className="affiliate-tab__stat-label">{facebookAccount.category}</span>
                            </span>
                        </p>
                        <a href={`https://facebook.com/${facebookAccount.id}`} target="_blank" rel="noopener noreferrer" className="affiliate-tab__link-button">
                            View on Facebook <Icon source={ExternalSmallMinor} />
                        </a>
                    </div>
                </Card.Section>
                <Card.Section>
                    <div className="affiliate-tab__row">
                        <div className="affiliate-tab__stats affiliate-tab__stats-facebook">
                            <div className="affiliate-tab__stat-item">
                                <h2 className="affiliate-tab__stat-num">
                                    {formatMetrics(facebookAccount.fan_count)}
                                </h2>
                                <p className="affiliate-tab__stat-label">
                                    Reach
                                </p>
                            </div>
                            <div className="affiliate-tab__stat-item">
                                <h2 className="affiliate-tab__stat-num">
                                    {formatMetrics(facebookAccount.avgReactions)}
                                </h2>
                                <p className="affiliate-tab__stat-label">
                                    Avg. Reaction{facebookAccount.avgReactions !== 1 && 's'}<Tooltip content="Based on the latest 30 posts"><span className="info-tooltip">i</span></Tooltip>
                                </p>
                            </div>
                            <div className="affiliate-tab__stat-item">
                                <h2 className="affiliate-tab__stat-num">
                                    {formatMetrics(facebookAccount.avgShares)}
                                </h2>
                                <p className="affiliate-tab__stat-label">
                                    Avg. Share{facebookAccount.avgShares !== 1 && 's'}<Tooltip content="Based on the latest 30 posts"><span className="info-tooltip">i</span></Tooltip>
                                </p>
                            </div>
                            <div className="affiliate-tab__stat-item">
                                <h2 className="affiliate-tab__stat-num">
                                    {formatMetrics(facebookAccount.avgComments)}
                                </h2>
                                <p className="affiliate-tab__stat-label">
                                    Avg. Comment{facebookAccount.avgComments !== 1 && 's'}<Tooltip content="Based on the latest 30 posts"><span className="info-tooltip">i</span></Tooltip>
                                </p>
                            </div>
                            <div className="affiliate-tab__stat-item">
                                <h2 className="affiliate-tab__stat-num">
                                    {showLetterGrade(facebookAccount.score)}
                                </h2>
                                <p className="affiliate-tab__stat-label">
                                    Engagement Score
                                </p>
                            </div>
                        </div>
                    </div>
                </Card.Section>
                <Card.Section>
                    <div className="affiliate-tab__posts">
                        {renderFacebookPosts()}
                        {!hasNext
                            && (
                                <div className="more-posts">
                                    <EmptyState message="Looking for more posts?" paragraph={`These are all the posts we graded ${facebookAccount.name} on. If you need to look deeper, you’ll have to go to their Facebook page.`} ctaText="View more posts on Facebook" ctaAction={goToFacebook} />
                                </div>
                            )
                        }
                    </div>
                    {maxPages > 0
                        && (
                            <div className="affiliate-tab__page-controls">
                                <LlamaPagination
                                    hasNext={hasNext}
                                    hasPrevious={hasPrevious}
                                    onNext={nextPage}
                                    onPrevious={previousPage}
                                    currentPage={currentPage}
                                    maxPages={maxPages}
                                />
                            </div>
                        )
                    }
                </Card.Section>
            </div>
        </>
    )
}

export default FacebookAffiliateDetail;