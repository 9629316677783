import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Tooltip, Modal, Banner, TextField, SkeletonBodyText, Spinner } from '@shopify/polaris';
import { PlaceholderPhoto, LlamaButton } from 'llama-library/components';

import { getBoostHistory, updateBid, changeBoostStatus } from '../../actions/offer-boost';
import { getBillingInfo } from '../../actions/offer-boost-billing';

import { LlamaCreditCardDetailsRender } from '../llama/credit-card-details';
import DateComparisonGraph, { SkeletonComparisonGraph } from './date-comparison-graph';

import { RateTypes } from '../../utils/types';
import numberFormat, { moneyFormat } from '../../utils/number-format';

import './offer-boost.css';

const recommendedBid = '1.00';

const BoostHistory = ({ attemptBid, boostEnabled, deactivateBoost, boostHistory, boostStatusLoading }) => {
    const [hideBenefitsBanner, setHideBenefitsBanner] = useState(localStorage.getItem('hideBoostBenefitsBanner'));

    const toggleBoost = () => {
        if (!boostEnabled) {
            attemptBid();
        } else {
            deactivateBoost();
        }
    };

    const handleDismiss = () => {
        setHideBenefitsBanner(true);
        localStorage.setItem('hideBoostBenefitsBanner', true);
        console.log(localStorage);
    };

    const metricTotals = {
        impressions: 0,
        uniqueImpressions: 0,
        clicks: 0,
        applicants: 0,
        charges: 0
    };

    Object.entries(boostHistory).forEach(([key, amounts]) => {
        console.log(key, amounts);
        if (Array.isArray(amounts)) {
            metricTotals[key] = amounts.reduce((acc, metric) => {
                return acc + metric.amount;
            }, 0);
        }
        console.log(metricTotals);
    });

    console.log('HISTORY', boostHistory);

    return (
        <>
            {!boostEnabled && hideBenefitsBanner !== 'true'
                && (
                    <Banner status="info" title="Reach more ambassadors with Ad Boost">
                        <p>Turn Ad Boost back on for a higher chance of having this offer shown in an ambassador&rsquo;s Featured Offers list.</p>
                        <p><button type="button" onClick={handleDismiss}>Don&rsquo;t show again</button></p>
                    </Banner>
                )
            }
            <div className="heading">
                <h2>Ad Boost</h2>
                {boostStatusLoading
                    ? 'Deactivating...'
                    : (
                        <button
                            type="button"
                            className="toggle"
                            onClick={toggleBoost}
                            data-enabled={boostEnabled ? 'true' : 'false'}
                        >
                            {boostEnabled ? 'Deactivate' : 'Activate'}
                        </button>
                    )
                }
            </div>
            <p className="info">
                {boostEnabled
                    ? 'Ad Boost is currently running for this offer. To deactivate it, click the toggle switch.'
                    : 'Ad Boost is currently inactive for this offer. To resume, click the toggle switch. Then, choose your bid amount.'
                }
            </p>

            {boostEnabled
                && (
                    <div className="bid">
                        <h3>Current Bid Amount:</h3>
                        <p className="amount">{moneyFormat(boostHistory.current_bid)} CPM <button type="button" onClick={attemptBid}>Edit</button></p>
                        <p className="history">View your billing history in <Link to="/settings/payment">Payment Settings</Link></p>
                    </div>
                )
            }

            <h3>{!boostEnabled && 'Past '}Results</h3>
            <ul className="offer-boost-metrics">
                <li>Impressions: <span>{numberFormat(metricTotals.impressions)}</span></li>
                <li>Unique Impressions: <span>{numberFormat(metricTotals.uniqueImpressions)}</span></li>
                <li>Clicks: <span>{numberFormat(metricTotals.clicks)}</span></li>
                <li>Applicants: <span>{numberFormat(metricTotals.applicants)}</span></li>
                <li>Total Ad Spend: <span>{moneyFormat(metricTotals.charges)} <Tooltip content="This refers to the actual impression costs for this offer. If any of your weekly costs were lower than the minimum required charge of $0.50, the differences you paid were not added to this total."><span className="info-tooltip">i</span></Tooltip></span></li>
            </ul>

            <DateComparisonGraph data={boostHistory} defaultCompareMetrics={['impressions', 'clicks']} />
        </>
    );
};

const BoostFirstTime = ({ offer, attemptBid, logo }) => {
    const formatRate = ({ type, amount }) => {
        switch (type) {
            case RateTypes.FLAT_ITEM:
                return <strong>{`${moneyFormat(amount, true)} per item`}</strong>;
            case RateTypes.FLAT_ORDER:
                return <strong>{`${moneyFormat(amount, true)} per order`}</strong>;
            case RateTypes.PERCENT_REVENUE:
            default:
                return <strong>{`${amount}% of revenue`}</strong>;
        }
    };

    return (
        <>
            <h2>Ad Boost <Tooltip content={<>Ad Boost works by letting you bid money for each 1,000 impressions your offer receives from an ambassador&rsquo;s Featured Offers list. The more you bid, the higher you&rsquo;ll be able to show up above competing brands.</>}><span className="info-tooltip">i</span></Tooltip></h2>

            <p className="info">Get your offer in front of more ambassadors by turning on Ad Boost. Ad Boost lets you bid on having your offer appear in the top 3 slots of every ambassador&rsquo;s Featured Offers list.</p>
            <ul className="boost-preview-graphic">
                <li className="your-offer">
                    <PlaceholderPhoto photo={logo} />
                    <div className="offer-info">
                        <p className="category">{offer.category}</p>
                        <p className="name">{offer.name}&nbsp;<span className="sponsored" aria-label="Sponsored listing">Ad</span></p>

                        <p className="commission-rate">
                            Earn {formatRate(offer.rate)} on
                            {offer.rate.amount === offer.longterm_rate.amount && offer.rate.type === offer.longterm_rate.type
                                ? ' all orders.'
                                : <> initial orders and {formatRate(offer.longterm_rate)} on future orders.</>
                            }
                        </p>

                        <p className="details">View Details</p>
                    </div>
                </li>
                {[1, 2, 3, 4, 5].map((index) => {
                    return (
                        <li className="skeleton" key={index}>
                            <PlaceholderPhoto photo={null} />
                            <div className="offer-info">
                                <p className="category" />
                                <p className="name"><span className="line" />{index < 3 && <>&nbsp;<span className="sponsored" aria-label="Sponsored listing">Ad</span></>}</p>
                                <p className="commission-rate">
                                    <span className="line" />
                                    <span className="line" />
                                    <span className="line" />
                                </p>
                                <p className="details" />
                            </div>
                        </li>
                    );
                })}
            </ul>
            <p><LlamaButton onClick={attemptBid} classes={['boost']}>Boost Offer</LlamaButton></p>
        </>
    );
};

const OfferBoost = ({ offer, advertiser, history, isLoading }) => {
    console.log('OFFER', offer.sponsored, offer);
    console.log('ADVERTISER', advertiser);
    const [bidModalOpen, setBidModalOpen] = useState(false);
    const [noSavedCardModal, setNoSavedCardModal] = useState(false);
    const [loadingModalOpen, setLoadingModalOpen] = useState(false);

    const [boostEnabled, setBoostEnabled] = useState(offer.sponsored === 'ACTIVE');
    const [boostStatusLoading, setBoostStatusLoading] = useState(false);
    const [boostHistoryLoading, setBoostHistoryLoading] = useState(true);
    const [boostHistory, setBoostHistory] = useState(null);

    const [currentBidField, setCurrentBidField] = useState(recommendedBid);
    const [bidError, setBidError] = useState('');
    const [bidLoading, setBidLoading] = useState(false);

    const [cardData, setCardData] = useState({});
    const [cardDataLoading, setCardDataLoading] = useState(true);

    useEffect(() => {
        getBoostHistory(offer.offer_id)
            .then((result) => {
                console.log('BOOST HISTORY', result.data.offerBoostHistory);
                if (result && result.data && result.data.offerBoostHistory) {
                    setBoostHistory(result.data.offerBoostHistory);
                    setCurrentBidField(result.data.offerBoostHistory.current_bid.toString() || 0);
                }
                setBoostHistoryLoading(false);
            })
            .catch(err => console.log(err));

        getBillingInfo(advertiser.advertiser_id)
            .then((result) => {
                if (result && result.data && result.data.getOfferBoostBillingInfo && result.data.getOfferBoostBillingInfo.card_data) {
                    setCardData(result.data.getOfferBoostBillingInfo.card_data);
                }
                setCardDataLoading(false);
            });

        if (history.location && history.location.state && history.location.state.fromSettings && history.location.state.launchBidModal) {
            attemptBid();
            // clear the history state
            history.replace(`/offer/${offer.offer_id}/boost`, {});
        }
    }, []);

    useEffect(() => {
        if (!isLoading && offer.sponsored) {
            setBoostEnabled(offer.sponsored === 'ACTIVE');
        }
    }, [isLoading]);

    useEffect(() => {
        if (!cardDataLoading && loadingModalOpen) {
            setLoadingModalOpen(false);
            attemptBid();
        }
    }, [cardDataLoading]);

    const attemptBid = () => {
        if (cardDataLoading) {
            setLoadingModalOpen(true);
        } else if (cardData.last4) {
            setBidModalOpen(true);
        } else {
            setNoSavedCardModal(true);
        }
    };

    const handleBidChange = (value) => {
        if (bidError && parseFloat(value) >= 1) {
            setBidError(null);
        }
        setCurrentBidField(value);
    };

    const saveBid = () => {
        if (parseFloat(currentBidField) < 1) {
            setBidError('Bid must be at least $1');
            return false;
        }

        setBidLoading(true);

        return updateBid(offer.offer_id, currentBidField, advertiser.advertiser_id)
            .then((result) => {
                setBidLoading(false);
                if (result.data && result.data.updateOfferBoostBid && result.data.updateOfferBoostBid.current_bid === parseFloat(currentBidField)) {
                    setBoostHistory({
                        ...boostHistory,
                        current_bid: result.data.updateOfferBoostBid.current_bid
                    });
                    setBidModalOpen(false);
                    if (!boostEnabled) {
                        setBoostEnabled(true);
                    }
                }
            });
    };

    const deactivateBoost = () => {
        setBoostStatusLoading(true);
        changeBoostStatus(offer.offer_id, 'INACTIVE')
            .then((result) => {
                if (result.data && result.data.updateOfferBoostStatus && result.data.updateOfferBoostStatus.success) {
                    setBoostStatusLoading(false);
                    setBoostEnabled(false);
                }
            });
    };

    const goToSettings = () => {
        history.push('/settings/payment', {
            fromBoost: true,
            offerId: offer.offer_id
        });
    };

    // skeleton
    if (offer.sponsored && (boostHistoryLoading || isLoading)) {
        return (
            <div className="offer-view-ad-boost">
                <h2>Ad Boost</h2>
                <div className="info"><SkeletonBodyText lines={2} /></div>

                <h3>Results</h3>
                <ul className="offer-boost-metrics">
                    <li>Impressions: <SkeletonBodyText lines={1} /></li>
                    <li>Unique Impressions: <SkeletonBodyText lines={1} /></li>
                    <li>Clicks: <SkeletonBodyText lines={1} /></li>
                    <li>Applicants: <SkeletonBodyText lines={1} /></li>
                    <li>Total Spent: <SkeletonBodyText lines={1} /></li>
                </ul>

                <SkeletonComparisonGraph />
            </div>
        );
    }

    return (
        <div className="offer-view-ad-boost">
            <Modal
                open={bidModalOpen}
                onClose={() => { return setBidModalOpen(false); }}
                title="Manage your budget"
                primaryAction={{
                    content: 'Place Bid',
                    onAction: saveBid,
                    loading: bidLoading
                }}
            >
                <Modal.Section>
                    <p>How much would you like to bid for this offer to appear more often in an ambassador&rsquo;s Featured Offers list?</p>
                    <div className="offer-budget-fields">
                        <aside className="payment-method">
                            <p>Payment Method</p>
                            <p className="help-text">To use a different card, update your <Link to="/settings/payment">Payment Settings</Link></p>
                            <div className="card-info">
                                <LlamaCreditCardDetailsRender
                                    allowEdit={false}
                                    cardData={cardData}
                                    loading={cardDataLoading}
                                />
                            </div>
                        </aside>
                        <TextField
                            label="Bid per 1,000 impressions"
                            helpText={`Recommended bid is ${moneyFormat(recommendedBid)}`}
                            value={currentBidField}
                            onChange={handleBidChange}
                            error={bidError}
                            prefix="$"
                            type="number"
                            step=".01"
                            min={1}
                        />
                    </div>
                </Modal.Section>
            </Modal>

            <Modal
                open={noSavedCardModal}
                onClose={() => { return setNoSavedCardModal(false); }}
                title="Reach more ambassadors with Ad Boost"
                primaryAction={{
                    content: 'Add Payment Method',
                    onAction: goToSettings
                }}
            >
                <Modal.Section>
                    <p>Before you can start boosting this offer, you&rsquo;ll need to <strong>add a payment method</strong> in your account settings.</p>
                </Modal.Section>
            </Modal>

            <Modal
                open={loadingModalOpen}
                onClose={() => { return setLoadingModalOpen(false); }}
                title="Manage your budget"
                primaryAction={{
                    content: 'Place Bid',
                    disabled: true
                }}
            >
                <Modal.Section>
                    <div style={{ display: 'flex', justifyContent: 'center', padding: '2rem' }}>
                        <Spinner size="large" />
                    </div>
                </Modal.Section>
            </Modal>

            {!boostHistory
                ? (
                    <BoostFirstTime
                        attemptBid={attemptBid}
                        offer={offer}
                        logo={advertiser.avatar_image}
                    />
                )
                : (
                    <BoostHistory
                        attemptBid={attemptBid}
                        boostEnabled={boostEnabled}
                        boostStatusLoading={boostStatusLoading}
                        deactivateBoost={deactivateBoost}
                        offer={offer}
                        boostHistory={boostHistory}
                    />
                )
            }
        </div>
    );
};

BoostHistory.propTypes = {
    attemptBid: PropTypes.func.isRequired,
    boostEnabled: PropTypes.bool.isRequired,
    deactivateBoost: PropTypes.func.isRequired,
    boostHistory: PropTypes.shape({
        current_bid: PropTypes.string,
        charges: PropTypes.arrayOf(PropTypes.shape({
            date: PropTypes.string,
            amount: PropTypes.number
        })),
        impressions: PropTypes.arrayOf(PropTypes.shape({
            date: PropTypes.string,
            amount: PropTypes.number
        })),
        uniqueImpressions: PropTypes.arrayOf(PropTypes.shape({
            date: PropTypes.string,
            amount: PropTypes.number
        })),
        uniqueImpressionsPerDay: PropTypes.arrayOf(PropTypes.shape({
            date: PropTypes.string,
            amount: PropTypes.number
        })),
        clicks: PropTypes.arrayOf(PropTypes.shape({
            date: PropTypes.string,
            amount: PropTypes.number
        })),
        applicants: PropTypes.arrayOf(PropTypes.shape({
            date: PropTypes.string,
            amount: PropTypes.number
        }))
    }).isRequired,
    boostStatusLoading: PropTypes.bool.isRequired
};

BoostFirstTime.propTypes = {
    offer: PropTypes.shape({
        category: PropTypes.string,
        name: PropTypes.string,
        rate: PropTypes.shape({
            amount: PropTypes.string,
            type: PropTypes.oneOf(Object.values(RateTypes))
        }),
        longterm_rate: PropTypes.shape({
            amount: PropTypes.string,
            type: PropTypes.oneOf(Object.values(RateTypes))
        })
    }).isRequired,
    attemptBid: PropTypes.func.isRequired,
    logo: PropTypes.string
};

BoostFirstTime.defaultProps = {
    logo: null
};

OfferBoost.propTypes = {
    offer: PropTypes.shape({
        offer_id: PropTypes.string.isRequired,
        category: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        rate: PropTypes.shape({
            amount: PropTypes.string,
            type: PropTypes.oneOf(Object.values(RateTypes))
        }),
        longterm_rate: PropTypes.shape({
            amount: PropTypes.string,
            type: PropTypes.oneOf(Object.values(RateTypes))
        }),
        sponsored: PropTypes.string
    }).isRequired,
    advertiser: PropTypes.shape({
        avatar_image: PropTypes.string,
        advertiser_id: PropTypes.string
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
        location: PropTypes.shape({
            state: PropTypes.shape({
                fromSettings: PropTypes.bool,
                launchBidModal: PropTypes.bool
            })
        }),
        replace: PropTypes.func
    }).isRequired,
    isLoading: PropTypes.bool.isRequired
};

export default OfferBoost;
