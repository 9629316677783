import React, { useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {
    FormLayout,
    Avatar,
    Select,
    TextField
} from '@shopify/polaris';

import { LlamaButton, LlamaToast } from 'llama-library/components';
import { countryList } from 'llama-library/utils';

import GeneralSettingsSideDrawer from './side-drawer';
import ChangePassword from '../../authentiction/change-password';

import './settings-general.css';

const EditableField = ({ id, value, label, labelHint, handleChange, saveChange, options, currentEdit, setCurrentEdit, setOrigValue }) => {
    const [fieldFocus, setFieldFocus] = useState(false);
    const [saving, setSaving] = useState(false);

    const toggleEditMode = () => {
        setCurrentEdit(id);
        setFieldFocus(true);
        setOrigValue(value);
    };

    const inputField = options
        ? (
            <Select
                id={`user-${id}`}
                value={value || ''}
                options={options}
                onChange={(val) => { return handleChange(val, id); }}
                className="settings-general__select"
                focused={fieldFocus}
            />
        )
        : (
            <TextField
                id={`user-${id}`}
                value={value || ''}
                onChange={(val) => { return handleChange(val, id); }}
                className="settings-general__input"
                type="text"
                focused={fieldFocus}
            />
        );
    let selectedOption = options
        ? options.find((option) => { return option.value === value; })
        : null;
    if (selectedOption) {
        selectedOption = selectedOption.label;
    }

    const handleSave = async () => {
        setSaving(true);
        const result = await saveChange();
        if (result === 'success') {
            setSaving(false);
            setFieldFocus(false);
            setCurrentEdit(null);
            setOrigValue(null);
        }
    };

    return (
        <div className="settings-general__input-row" data-editing={currentEdit === id}>
            <p className="settings-general--input-label">
                {label}
                {labelHint && <span className="hint">{labelHint}</span>}
            </p>
            <div className="settings-general__edit">
                {currentEdit === id
                    ? (
                        <>
                            {inputField}
                            <LlamaButton
                                onClick={handleSave}
                                loading={saving}
                                disabled={saving}
                            >
                                Save
                            </LlamaButton>
                        </>
                    )
                    : (
                        <>
                            <p className="settings-general__display">{options ? selectedOption : value}</p>
                            <button type="button" className="settings-general__edit-text" onClick={toggleEditMode}>Edit</button>
                        </>
                    )
                }
            </div>
        </div>
    );
};

/**
 * @function SettingsGeneral
 * Accepts props, returns SettingsGeneral Component.
 * Displays merchant's general settings data
 * (i.e. : photo, name, account email, phone,
 * address 1 and 2, city, state, zip, country).
 * Merchant has ability to edit fields and save changes.
 * Wraps SettingsShop Component.
 *
 * @param {Object} props
 *
 * @returns {Component}
 */
const SettingsGeneral = (props) => {
    const { values } = props;

    const [toastVerbiage, setToastVerbiage] = useState(null);
    const [currentEdit, setCurrentEdit] = useState(null);
    const [origValue, setOrigValue] = useState(null);

    useEffect(() => {
        const clickOutside = (e) => {
            if (e.target.id !== `user-${currentEdit}`
                && e.target.className !== 'settings-general__edit-text'
                && e.target.className !== 'llama-button'
                && e.target.parentNode.className !== 'llama-button'
            ) {
                props.setFieldValue(currentEdit, origValue, true);
                setCurrentEdit(null);
                document.removeEventListener('click', clickOutside);
            }
        };
        if (currentEdit) {
            document.addEventListener('click', clickOutside);
        } else {
            document.removeEventListener('click', clickOutside);
        }
        return () => {
            return document.removeEventListener('click', clickOutside);
        };
    }, [currentEdit]);

    const handleChange = (value, field) => {
        props.setFieldValue(field, value, true);
    };

    const saveChange = () => {
        const data = { [currentEdit]: values[currentEdit] };
        return props.updateAdvertiser(data, props.advertiser)
            .then(() => {
                setToastVerbiage('Your settings have been saved');
                return 'success';
            });
    };

    const syncOptions = [
        { label: 'Automatically Activate', value: 'active' },
        { label: 'Manually Activate', value: 'inactive' }
    ];

    const shopProfileFields = [
        {
            id: 'name',
            label: 'Name',
            value: values.name
        },
        {
            id: 'domain',
            label: 'Domain',
            value: values.domain
        },
        {
            id: 'description',
            label: 'Description',
            value: values.description
        },
        {
            id: 'tags',
            label: 'Tags',
            value: values.tags
        }
    ];

    const accountFields = [
        {
            id: 'new_product_status',
            label: 'Product Sync Status',
            labelHint: 'Choose how new products from your Shopify store will become active for your offers in Llama',
            value: values.new_product_status || 'active',
            options: syncOptions
        },
        {
            id: 'email',
            label: 'Account Email',
            value: values.email
        }
    ];

    const contactFields = [
        {
            id: 'shop_owner',
            label: 'Name',
            value: values.shop_owner
        },
        {
            id: 'phone',
            label: 'Phone',
            value: values.phone
        },
        {
            id: 'address1',
            label: 'Address 1',
            value: values.address1
        },
        {
            id: 'address2',
            label: 'Address2',
            value: values.address2
        },
        {
            id: 'city',
            label: 'City',
            value: values.city
        },
        {
            id: 'region',
            label: 'State / Province',
            value: values.region
        },
        {
            id: 'zipcode',
            label: 'Zip',
            value: values.zipcode
        },
        {
            id: 'country',
            label: 'Country',
            value: values.country,
            options: countryList
        }
    ];

    const renderEditableFields = (fields) => {
        return fields.map((item) => {
            return (
                <EditableField
                    key={item.id}
                    id={item.id}
                    label={item.label}
                    labelHint={item.labelHint}
                    value={item.value}
                    setOrigValue={setOrigValue}
                    handleChange={handleChange}
                    saveChange={saveChange}
                    options={item.options}
                    setCurrentEdit={setCurrentEdit}
                    currentEdit={currentEdit}
                />
            );
        });
    };

    return (
        <div className="settings-general__wrapper">
            <GeneralSettingsSideDrawer
                saveFile={props.saveFile}
                setShowSideDrawer={props.setShowSideDrawer}
                showSideDrawer={props.showSideDrawer !== null}
                uploading={props.uploading}
            />

            <FormLayout>
                <div className="settings-general">
                    <fieldset className="settings-general__shop-info">
                        <legend>Shop Profile</legend>
                        <div className="settings-general__input-row">
                            <p className="settings-general--input-label">
                                Logo
                            </p>
                            <div className="settings-general__edit">
                                <Avatar customer name={values.shop_owner} source={values.avatar_image} size="small" />
                                <button type="button" className="settings-general__edit-text" onClick={() => { props.setShowSideDrawer(true); }}>
                                    Edit
                                </button>
                            </div>
                        </div>

                        {renderEditableFields(shopProfileFields)}
                    </fieldset>

                    <fieldset className="settings-general__account-info">
                        <legend>Account</legend>
                        <div className="settings-general__input-row">
                            <p className="settings-general--input-label">
                                Password
                            </p>
                            <ChangePassword advertiser={props.advertiser} />
                        </div>

                        {renderEditableFields(accountFields)}
                    </fieldset>

                    <fieldset className="settings-general__contact-info">
                        <legend>Contact Info</legend>
                        {renderEditableFields(contactFields)}
                    </fieldset>
                </div>
            </FormLayout>
            <LlamaToast toastVerbiage={toastVerbiage} setToastVerbiage={setToastVerbiage} />
        </div>
    );
};

EditableField.propTypes = {
    currentEdit: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    labelHint: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired
    })),
    saveChange: PropTypes.func.isRequired,
    setCurrentEdit: PropTypes.func.isRequired,
    setOrigValue: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired
};

EditableField.defaultProps = {
    labelHint: null,
    options: null
};

SettingsGeneral.propTypes = {
    advertiser: PropTypes.shape({
        advertiser_id: PropTypes.string.isRequired
    }).isRequired,
    saveFile: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    setShowSideDrawer: PropTypes.func.isRequired,
    showSideDrawer: PropTypes.bool,
    updateAdvertiser: PropTypes.func.isRequired,
    uploading: PropTypes.bool.isRequired,
    values: PropTypes.shape({
        address1: PropTypes.string,
        address2: PropTypes.string,
        avatar_image: PropTypes.string,
        city: PropTypes.string,
        country: PropTypes.string,
        description: PropTypes.string,
        domain: PropTypes.string,
        email: PropTypes.string,
        name: PropTypes.string,
        new_product_status: PropTypes.string,
        phone: PropTypes.string,
        region: PropTypes.string,
        shop_owner: PropTypes.string,
        tags: PropTypes.string,
        zipcode: PropTypes.string
    }).isRequired
};

SettingsGeneral.defaultProps = {
    showSideDrawer: null
};

export default SettingsGeneral;
