import { sendGraphQL } from './sendRequest';

import productFragment from './fragments/product.fragment';

export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_PRODUCTS_PENDING = 'GET_PRODUCTS_PENDING';
export const GET_PRODUCTS_FULFILLED = 'GET_PRODUCTS_FULFILLED';
export const GET_PRODUCTS_REJECTED = 'GET_PRODUCTS_REJECTED';

export const UPDATE_PRODUCTS = 'UPDATE_PRODUCTS';
export const UPDATE_PRODUCTS_PENDING = 'UPDATE_PRODUCTS_PENDING';
export const UPDATE_PRODUCTS_FULFILLED = 'UPDATE_PRODUCTS_FULFILLED';
export const UPDATE_PRODUCTS_REJECTED = 'UPDATE_PRODUCTS_REJECTED';

export const getProductsForAdvertiser = (shop = null) => {
    if (!shop) return null;

    const query = `
        query getProductsForAdvertiser($domain: String) {
            advertisers(myshopify_domain: $domain) {
                products {
                    ...ProductData
                }
                collections {
                    title
                    collection_id
                }
            }
        }

        ${productFragment}
    `

    const variables = {
        domain: shop
    }

    let payload = {
        query,
        variables
    };

    return {
        type: GET_PRODUCTS,
        payload: sendGraphQL(payload)
            .then(response => response.data)
    };
};

export const getProductListByCollection = (advertiser_id = null, collection_id = null) => {

    if (!advertiser_id || !collection_id) return;

    const query = `
        query productListByCollection($advertiser_id: String, $collection_id: String!) {
            advertisers(advertiser_id: $advertiser_id) {
                store {
                    productsForCollection(collection_id: $collection_id)
                }
            }
        }
    `

    const variables = {
        advertiser_id,
        collection_id
    };

    const payload = {
        query,
        variables
    };

    return sendGraphQL(payload)
        .then(response => response.data)
};

export const updateAdvertiserProducts = (advertiser_id, shop) => {
    if (!advertiser_id || !shop) {
        return null;
    }

    const query = `
        mutation updateAdvertiserProducts($advertiser_id: String!, $shop: String!) {
            updateAdvertiserProducts(advertiser_id: $advertiser_id, shop: $shop) {
                ...ProductData
            }
        }

        ${productFragment}
    `;

    const variables = {
        advertiser_id,
        shop
    };

    const payload = {
        query,
        variables
    };

    return {
        type: UPDATE_PRODUCTS,
        payload: sendGraphQL(payload)
            .then(response => response.data)
    };
};
