import React from 'react';
import { Page, Spinner, Tabs, TextStyle, ResourceList, Badge, Card } from '@shopify/polaris';
import { LlamaPagination, EmptyState } from 'llama-library/components';

import './invoices.css';

/**
 * @function Invoices
 * Accepts the following params, returns Invoices Component.
 * Displays the invoice Resource List headers and wraps
 * the renderInvoices Component. 
 * Holds tabs for the status of invoices and pagination.
 * 
 * @param {invoices} invoices 
 * 
 * @returns {FunctionComponent}
 */
const Invoices = ({ invoices, loading, selectedTab, handleTabChange, pagination, ...props }) => {

    // Pagination Variables.
    const {
        hasNext,
        hasPrevious,
        nextPage,
        previousPage,
        currentPage,
        totalPages } = pagination;

    const tabs = [
        {
            id: 'all-invoices',
            content: 'All',
            accessibilitylabel: 'All Invoices',
            panelId: 'all-invoices'
        }, {
            id: 'outstanding-invoices',
            content: 'Outstanding',
            accessibilitylabel: 'Outstanding Invoices',
            panelId: 'outstanding-invoices'
        }, {
            id: 'paid-invoices',
            content: 'Paid',
            accessibilitylabel: 'Paid Invoices',
            panelId: 'paid-invoices'
        }
    ]

    /**
     * @function renderInvoices
     * Accepts invoiceData and index, returns renderInvoices Component.
     * Displays a Polaris Resource List of invoices (includes 
     * Invoice Date, Due Date, Amount Due and Status).
     * 
     * @param {Object} invoiceData 
     * @param {Integer} index 
     * 
     * @returns {FunctionComponent}
     */
    let renderInvoices = (invoiceData, index) => {

        const { invoice, affiliate } = invoiceData;
        const { invoice_date, links, payment_term, total_amount, status } = invoice;

        const invoiceDate = invoice_date.replace(' PST', '');
        const dueDate = payment_term.due_date.replace(' PST', '');
        const totalDue = `${total_amount.value} ${total_amount.currency}`;

        const invoiceStatus = status === 'PAID'
            ? <Badge status="success">Paid</Badge>
            : <Badge status="info">Outstanding</Badge>;

        const shortCutActions = links.payerView && links.payerView.href 
            ? [{ content: 'View Invoice', url: links.payerView.href }]
            : null;

        return (
            
            <ResourceList.Item key={`${invoice.id}__${index}`} shortcutActions={shortCutActions}>
                <div className="invoices__item">
                    <div className="invoices__info">
                        <TextStyle variation="strong">{invoiceData.affiliate_name}</TextStyle>
                        <TextStyle variation="subdued">{invoice.items[0].name}</TextStyle>
                    </div>
                    <div className="invoices__date">{invoiceDate}</div>
                    <div className="invoices__due-date">{dueDate}</div>
                    <div className="invoices__amount">{totalDue}</div>
                    <div className="invoices__status">{invoiceStatus}</div>
                </div>
            </ResourceList.Item>
        )
    }

    /* if (!invoices || invoices.length === 0 || !Array.isArray(invoices)) {
        invoices = [{}];
        renderInvoices = (invoice) => {
            return (
                <div className="invoices__empty-set" key={invoice.id}>
                    <TextStyle variation="subdued">There are no invoices to display.</TextStyle>
                </div>
            )
        }
    } */

    const renderInvoicesList = () => {
        if(invoices && Array.isArray(invoices) && invoices.length > 0){
            return (<div>
                <ResourceList
                    resourceName={{ singular: 'Invoice', plural: 'Invoices' }}
                    items={invoices}
                    renderItem={renderInvoices}
                />
                {(totalPages - 1) > 0 &&
                <div className="invoices__pagination">
                    <LlamaPagination
                        hasNext={hasNext}
                        hasPrevious={hasPrevious}
                        onNext={nextPage}
                        onPrevious={previousPage}
                        currentPage={currentPage}
                        maxPages={totalPages - 1}
                        data-test="component-pagination"
                    />
                </div>
                }
            </div>)
        }else{
            return <EmptyState message="You don’t have any invoices yet" paragraph="When your ambassadors earn commissions from your offers, you’ll get an invoice from us via PayPal and we’ll show your payment history here." />
        }
    }

    return (
        <Page
            title="Invoices"
            separator={true}
        >
            <div className="invoices__tabs" data-test="component-invoicesTabs">
                <Tabs tabs={tabs} selected={selectedTab} onSelect={handleTabChange} />
            </div>

            <div className="invoices__wrapper" data-test="component-invoices">

                <div className="invoices__item invoices__header">
                    <div className="invoices__info">Ambassador</div>
                    <div className="invoices__date">Invoice Date</div>
                    <div className="invoices__due-date">Due Date</div>
                    <div className="invoices__amount">Amount Due</div>
                    <div className="invoices__status">Status</div>
                </div>

                {loading 
                    ? <div className="invoices__spinner-wrapper"><Spinner /></div>
                    : renderInvoicesList()
                }
            </div>
        </Page>
    )
    

}

export default Invoices;