import { GET_FEATURED_AMBASSADORS_FULFILLED } from '../actions/featured-ambassadors';

const initialState = { ambassadors: [] };

const featuredAmbassadorsReducer = (state = initialState, action) => {
    const { type } = action;
    switch (type) {
        case GET_FEATURED_AMBASSADORS_FULFILLED: {
            return { ...state, ambassadors: action.payload.affiliates };
        }
        default: {
            return state;
        }
    }
};

export default featuredAmbassadorsReducer;
