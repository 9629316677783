import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { TextField, Icon, Select, DatePicker, ChoiceList } from '@shopify/polaris';
import { SearchMinor } from '@shopify/polaris-icons';

import './ambassador-performance-filters.css';

const sortOptions = [
    {
        label: 'Revenue',
        value: 'revenue'
    },
    {
        label: 'Orders',
        value: 'totalOrders'
    },
    {
        label: 'Repeat Orders',
        value: 'repeatOrders'
    },
    {
        label: 'Avg. Revenue Per Order',
        value: 'avgRevenue'
    },
    {
        label: 'Ambassador',
        value: 'ambassador__name'
    }
];

const AmbassadorPerformanceFilters = (props) => {
    const {
        offersLoading,
        offers, filters, datePicker, searchValue, advertiserStartDate, filtersOpen,
        handleOfferChange, handleDateChange, handleSearchChange, handleSearchEnter, handleSortChange, setFiltersOpen
    } = props;

    const now = new Date();
    const [displayDate, setDisplayDate] = useState({
        month: now.getMonth(),
        year: now.getFullYear()
    });

    // format and sort offers for dropdown menu
    let offerOptions = [];
    let offerName = 'All Offers';
    if (offers.length > 0) {
        offerOptions = offers.map((offer) => {
            return {
                label: offer.name,
                value: offer.offer_id
            };
        })
            .sort((a, b) => {
                if (a.label > b.label) {
                    return 1;
                }
                if (a.label < b.label) {
                    return -1;
                }
                return 0;
            });

        if (filters.offer_id) {
            offerName = offerOptions.find((offer) => {
                return offer.value === filters.offer_id;
            }).label;
        }
    }
    offerOptions.unshift({ value: '', label: 'All Offers' });

    const handleMonthChange = (month, year) => {
        setDisplayDate({ month, year });
    };

    console.log(filters);

    return (
        <section className="PerformanceSidebar" data-test="component-performanceSidebar">
            <div className="PerformanceSidebar__Search">
                <form onSubmit={handleSearchEnter}>
                    <TextField
                        value={searchValue}
                        onChange={handleSearchChange}
                        suffix={<Icon source={SearchMinor} onClick={handleSearchEnter} color="inkLightest" />}
                        placeholder="Search"
                    />
                </form>
            </div>
            <p className="expand-filters">
                <button type="button" onClick={() => { return setFiltersOpen(!filtersOpen); }}>
                    Filter &amp; Sort
                    <span className="selections">: <strong>{offerName}, {moment(filters.startDate).format('MMM D')}–{moment(filters.endDate).format('MMM D')}</strong></span>
                </button>
            </p>
            <ul className="PerformanceSidebar__Filters" data-displayed={filtersOpen}>
                <li>
                    <label htmlFor="offer" className="filter-label">Filter by Offer</label>
                    {!offersLoading
                        ? (
                            <Select
                                id="offer"
                                options={offerOptions}
                                onChange={handleOfferChange}
                                value={(!filters.offer_id) ? '' : filters.offer_id}
                            />
                        )
                        : <Select id="offer" options={[{ label: 'Loading...', value: '' }]} onChange={handleOfferChange} />
                    }
                </li>
                <li>
                    <label htmlFor="date-range" className="filter-label">Time Period</label>
                    <DatePicker
                        id="date-range"
                        month={displayDate.month}
                        year={displayDate.year}
                        onMonthChange={handleMonthChange}
                        onChange={handleDateChange}
                        selected={{ start: datePicker.startDate, end: datePicker.endDate }}
                        allowRange
                        disableDatesBefore={new Date(advertiserStartDate)}
                        disableDatesAfter={new Date()}
                    />
                </li>
                <li>
                    <label htmlFor="sort" className="filter-label">Sort By</label>
                    <ChoiceList
                        id="sort"
                        choices={sortOptions}
                        onChange={handleSortChange}
                        selected={filters.sortBy}
                        allowMultiple={false}
                    />
                </li>
            </ul>
        </section>
    );
};

AmbassadorPerformanceFilters.propTypes = {
    advertiserStartDate: PropTypes.string.isRequired,
    datePicker: PropTypes.shape({
        startDate: PropTypes.instanceOf(Date).isRequired,
        endDate: PropTypes.instanceOf(Date).isRequired
    }).isRequired,
    filters: PropTypes.shape({
        offer_id: PropTypes.string,
        sortBy: PropTypes.string.isRequired
    }).isRequired,
    filtersOpen: PropTypes.bool.isRequired,
    handleDateChange: PropTypes.func.isRequired,
    handleOfferChange: PropTypes.func.isRequired,
    handleSearchChange: PropTypes.func.isRequired,
    handleSearchEnter: PropTypes.func.isRequired,
    handleSortChange: PropTypes.func.isRequired,
    offers: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        offer_id: PropTypes.string.isRequired
    })).isRequired,
    offersLoading: PropTypes.bool.isRequired,
    searchValue: PropTypes.string.isRequired,
    setFiltersOpen: PropTypes.func.isRequired
};

export default AmbassadorPerformanceFilters;
