import React, { useState } from 'react';
import { TextField, Icon, Select, Button, ChoiceList } from '@shopify/polaris';

import {
    SearchMinor,
    RefreshMajorMonotone
  } from '@shopify/polaris-icons';

import { socialOptions } from '../../hooks/use-social-filter';
import { useToggle } from '../../hooks/use-toggle';

import dropDown from '../../assets/dropdown-arrow.svg';

import './ambassadors.css';

/**
 * @function AmbassadorsSidebar
 * Accepts the following params, returns AmbassadorsSidebar Component.
 * Displays search, sort and filter controls for the list of ambassadors.
 * 
 * @param {String} searchValue - searchValue string
 * @param {Function} setSearchValue - function to which we pass searchValue String
 * @param {String} socialFilter - socialFilter string
 * @param {Function} setSocialFilter - function to which we pass socialFilter String
 * @param {Function} refreshApplications - function that assigns null to setApplications
 * @param {String} sort - sort String
 * @param {Function} setSort - function to which we pass the sort String
 * @param {Array} offers - Array of offers
 * @param {String} offerFilter - offerFilter String
 * @param {Function} setOfferFilter - function to which we pass offerFilter String
 * 
 * @returns {FunctionComponent}
 */
const AmbassadorsSidebar = ({
    searchValue,
    setSearchValue,
    socialFilter,
    setSocialFilter,
    refreshApplications,
    sort,
    setSort,
    offers,
    offerFilter,
    setOfferFilter,
}) => {

    const [openSection, toggleOpen] = useToggle(false);

    const onSearchChange = (value) => setSearchValue(value);
    const onSocailChange = (value) => setSocialFilter(value);
    const onSortChange = (value) => setSort(value);
    const onOfferChange = (value) => setOfferFilter(value);

    const sortOptions = [
        { label: 'Newest to Oldest', value: 'new' },
        { label: 'Oldest to Newest', value: 'old' },
        { label: 'Reach', value: 'reach' },
    ]

    const offerOptions = offers.map((item) => {
        return { label: item.name, value: item.offer_id }
    })
    offerOptions.unshift({ label: 'All', value: '' })

    return (
        <div className="ambassadors-sidebar__sidebar" data-test="component-ambassadorsSidebar">
            <div className="ambassadors-sidebar__search">
                <TextField
                    value={searchValue}
                    onChange={onSearchChange}
                    suffix={<Icon source={SearchMinor} color="inkLightest" />}
                    placeholder="Search"
                />
            </div>
            <div 
                className={
                openSection ? 
                "ambassadors-sidebar__section ambassadors-sidebar__section-top ambassadors-sidebar--mobile-tab ambassadors-sidebar__section--active" 
                : "ambassadors-sidebar__section ambassadors-sidebar__section-top ambassadors-sidebar--mobile-tab"
                } 
                onClick={toggleOpen}>
                <label className="ambassadors-sidebar__section-label">Sort / Filter Options</label>
                {openSection ?
                    <img 
                        src={dropDown} 
                        className="ambassadors-sidebar__dropdown-up"
                        onClick={toggleOpen} 
                    />
                    :
                    <img 
                        src={dropDown} 
                        className="ambassadors-sidebar__dropdown-arrow"
                        onClick={toggleOpen} 
                    />
                    }
                {/* <Button
                    onClick={refreshApplications}
                >
                    <Icon source={RefreshMajorMonotone} />
                </Button> */}
            </div>
            <div className="ambassadors-sidebar__section">
                <div className={openSection ? "ambassadors-sidebar__content ambassadors-sidebar__content--expanded" : "ambassadors-sidebar__content"}>
                    <label className="ambassadors-sidebar__label">Filter By Offer</label>
                    <Select
                        options={offerOptions}
                        value={offerFilter}
                        onChange={onOfferChange}
                    />
                </div>
            </div>
            <div className="ambassadors-sidebar__section">
                <div className={openSection ? "ambassadors-sidebar__content ambassadors-sidebar__content--expanded" : "ambassadors-sidebar__content"}>
                    <label className="ambassadors-sidebar__label">Sort By</label>
                    <ChoiceList
                        choices={sortOptions}
                        onChange={onSortChange}
                        selected={sort}
                    />
                </div>
            </div>
            <div className="ambassadors-sidebar__section">
                <div className={openSection ? "ambassadors-sidebar__content ambassadors-sidebar__content--expanded" : "ambassadors-sidebar__content"}>
                    <label className="ambassadors-sidebar__label">Social Media Channel</label>
                    <Select
                        options={socialOptions}
                        value={socialFilter}
                        onChange={onSocailChange}
                    />
                </div>
            </div>
        </div>
    );

}

export default AmbassadorsSidebar;