/* import axios from 'axios';
import { NEXT_APP_API_URL } from '../config'; */
import {sendGraphQL} from './sendRequest'

import offerFragment from './fragments/offer.fragment';
import discountFragment from './fragments/discount.fragment';
import productFragment from './fragments/product.fragment';

export const GET_OFFERS_PENDING = 'GET_OFFERS_PENDING';
export const GET_OFFERS_FULFILLED = 'GET_OFFERS_FULFILLED';
export const GET_OFFERS_REJECTED = 'GET_OFFERS_REJECTED';

export const GET_OFFER = 'GET_OFFER';
export const GET_OFFER_PENDING = 'GET_OFFER_PENDING';
export const GET_OFFER_FULFILLED = 'GET_OFFER_FULFILLED';
export const GET_OFFER_REJECTED = 'GET_OFFER_REJECTED';

export const getOffers = (advertiser_id, start_date, end_date) => {

    if (!advertiser_id) return null;

    const query = `
        query getAdvertiser(
            $advertiser_id: String
            $start_date: String, 
            $end_date: String
        ) {
            advertisers(advertiser_id: $advertiser_id) {
                offers {
                    ...OfferData
                    reports(start_date: $start_date, end_date: $end_date) {
                        affiliateData {
                          affiliate_id
                          sales
                        }
                        salesData {
                            date
                            volume
                            sales
                        }
                    }
                    applicantCount{
                        pending
                        approved
                        denied
                    }
                }
            }
        }

        ${offerFragment}
    `

    const variables = {
        advertiser_id,
        start_date,
        end_date
    }

    let payload = {
        query,
        variables
    }

    return sendGraphQL(payload)
        .then(response => response.data)

    /* let headers = {
        "Content-Type": 'application/json'
    }

    return axios.post(NEXT_APP_API_URL, payload, { headers })
        .then(response => response.data) */

}

export const getOfferById = (offer_id, advertiser_id, start_date, end_date) => {

    const query = `
        query getOfferById(
            $advertiser_id: String, 
            $offer_id: String, 
            $start_date: String, 
            $end_date: String
        ){
            advertisers(advertiser_id: $advertiser_id) {
                branded_sign_up{
                    banner_image
                    body
                    display_offer_data
                    offer_id
                    slug
                    brand_color
                }
                offers(filter: { offer_id: $offer_id } ) {
                    ...OfferData
                    discounts {
                        ...DiscountData
                    }
                    product_info {
                        ...ProductData
                    }
                    reports(start_date: $start_date, end_date: $end_date) {
                        affiliateData {
                          affiliate_id
                          sales
                        }
                        productData(options: { limit: 3 }) {
                            product_id
                            title
                            volume
                            quantity
                            product {
                                images {
                                    src
                                }
                            }
                        }
                        salesData {
                            date
                            volume
                            sales
                        }
                    }
                }
            }
        }

        ${offerFragment}

        ${discountFragment}

        ${productFragment}
    `

    let variables = {
        offer_id,
        advertiser_id,
        start_date,
        end_date,
    }

    const payload = {
        variables,
        query
    }

    return {
        type: GET_OFFER,
        payload: sendGraphQL(payload)
            .then(response => response.data)
    };

    /* let headers = {
        "Content-Type": 'application/json'
    }

    return axios.post(NEXT_APP_API_URL, payload, { headers })
        .then(response => response.data) */

}
