import React from 'react';
import { Badge, SkeletonBodyText, SkeletonDisplayText } from '@shopify/polaris';

import llamaPlaceholder from '../../assets/llama-user-icon.png';
import './ambassadors.css';

import LlamaButton from './application-button';

const StatusBadges = {
    'LOADING': <Badge>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</Badge>
}

/**
 * @function LoadingApplication
 * Returns LoadingApplication Component.
 * Displays Polaris Skeleton loading for basic shape of the expected data.
 * 
 * @returns {FunctionComponent}
 */
const LoadingApplication = () => {

    const affiliateInfo = {
        reach: null,
        audience_country: null,
        marketing_platforms: null,
        audience: null,
        avg_conversion_rate: null,
        epc: null,
    }

    const renderInfo = ([key]) => {
        return <div className="ambassadors-application__info-item" key={key} style={{ display: 'block' }}>
            <SkeletonBodyText lines={1}/>
            <div className="ambassadors-application__info-key">{key.replace('_', ' ')}</div>
        </div>
    }

    const buttonClasses = ["ambassadors-application__button"]
    const buttonAction = () => null;

    return (
        <div className="ambassadors-application">
            <div className="ambassadors-application__wrapper">
                <div className="ambassadors-application__left">
                    {StatusBadges.LOADING}
                    <img src={llamaPlaceholder} className="ambassadors-application__profile-image" />
                    <div className="ambassadors-application__name" style={{ width: '50%' }}><SkeletonDisplayText size="medium"/></div>
                </div>
                <div className="ambassadors-application__right">
                    <div className="ambassadors-application__top">
                        <div className="ambassadors-application__offer" style={{ width: '20rem' }}><SkeletonDisplayText size="small"/></div>
                    </div>
                    <div className="ambassadors-application__info">
                        {Object.entries(affiliateInfo).map(renderInfo)}
                    </div>
                    <div className="ambassadors-application__buttons">
                        <div className="ambassadors-application__button-group">
                            <LlamaButton
                                loading={false}
                                onClick={buttonAction}
                                classes={buttonClasses}
                            >
                                View Affiliate
                            </LlamaButton>
                            <LlamaButton
                                loading={false}
                                onClick={buttonAction}
                                classes={buttonClasses}
                            >
                                Shortlist
                            </LlamaButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
    
}

export default LoadingApplication;