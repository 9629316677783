import React, { Component } from 'react';

// Amplify
import Amplify, { Auth } from 'aws-amplify';
import aws_exports from './aws.config';
import {
    Authenticator,
    ConfirmSignIn,
    RequireNewPassword,
    VerifyContact,
    ForgotPassword,
    TOTPSetup,
 } from 'aws-amplify-react';

import App from './App';
import { LlamaSignUp, LlamaInitalLogin, LlamaSignIn, LlamaConfirmSignUp, LlamaForgotPassword } from './authentiction';
import './authentiction/amplify.css'
import logo from './assets/logo.svg';
import qs from 'qs';

const llamaTheme = {
    button: {
        backgroundColor: 'rgba(253,42,84,1)',
        borderRadius: '23px',
        color: 'rgba(255,255,255,1)'
    },
    a: {
        color: 'rgba(253,42,84,1)'
    },
    formSection: {
        textAlign: 'left',
        display: 'inline-block',
        // minWidth: '460px',
        background: '#FFFFFF'
        // padding: '35px 40px'
    },
    sectionHeader: {
        background: '#FFFFFF',
        fontSize: '18px',
        fontWeight: '500',
        textAlign: 'left',
        display: 'inline-block',
    },
    toast: {
        position: 'absolute',
        top: 0,
        background: '#FB2956',
        color: '#FFFFFF',
        padding: '16px',
        display: 'flex',
        justifyContent: 'space-between',
        left: 0,
        width: '100%',
    }
}

// eslint-disable-next-line
const search = location.search.replace('?', '');
const params = qs.parse(search);
const { install, email, shop, advertiser_id, token, create_user, action, promo } = params;

Amplify.configure(aws_exports);

const Header = ({ authState }) => {

    if (authState === 'signedIn') return null;

    return (
        <div className="llama-auth-header">
            <img src={logo} alt="Llama logo" />
        </div>
    )
}

const authStates = [
    'signIn',
    'signUp',
    'confirmSignIn',
    'confirmSignUp',
    'forgotPassword',
    'requireNewPassword',
    'verifyContact',
    'signedIn',
    'signedUp'
]

class AuthWrapper extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showHeader: false,
            params
        };
    }

    authStateChange = (authState) => {
        if (authState === 'signedIn') {
            this.setState({ params: {} });
        }

        if (!window || !window.driftApi) {
            return;
        }

        if (authState === 'signedIn') {
            window.driftApi.widget.show();
        } else {
            window.driftApi.widget.hide();
        }
    }

    render() {

        let authState = 'signIn';
        
        if ((install && email) || create_user || (create_user && token) || (promo && promo.length > 0)) {
            authState = 'signUp';
        }

        if (authStates.includes(action)) {
            authState = action;
        }

        return (
            <Authenticator 
                theme={llamaTheme}
                onStateChange={this.authStateChange}
                hideDefault={true}
                authState={authState}
            >
                <Header />
                <App />
                {/* <Greetings/> */}
                <LlamaSignIn override={['SignIn']} params={this.state.params} />
                <ConfirmSignIn/>
                <RequireNewPassword/>
                <LlamaSignUp override={['SignUp']} data={{ username: email, myshopify_domain: shop, advertiser_id }} params={this.state.params}/>
                {/* <ConfirmSignUp/> */}
                <LlamaConfirmSignUp override={['ConfirmSignUp']}/>
                <VerifyContact/>
                <LlamaForgotPassword override={['ForgotPassword']}/>
                <TOTPSetup/>
            </Authenticator>
        )
    }

}

export default AuthWrapper;