import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { DisplayText, Badge, Heading, TextStyle } from '@shopify/polaris';
import { defaultBanners, pluralize } from 'llama-library/utils';

import './campaign-summary.css';
import { Tag } from '../tag';
import { displayCampaignRate, displayLongTermRate, displayProductsSummary } from '../../utils';

/**
 * @function CampaignSummary
 * Accepts props Object, returns CampaignSummary Component.
 * Displays details about the Campaign (name, description, category
 * tags, preview URL) as well as Demographic data.
 *
 * @param {Object} props
 *
 * @returns {FunctionComponent}
 */
const CampaignSummary = (props) => {
    const { values, advertiser, showManageOffer } = props;
    console.log('CAMPAIGN PROPS', props);

    const displayTags = () => {
        if (!Array.isArray(values.tags)) {
            return null;
        }

        const tags = values.tags.filter((item) => {
            return item !== '';
        });

        if (tags.length === 0) {
            return <TextStyle subdued>No tags attached to campaign.</TextStyle>;
        }

        return tags.map((item) => {
            return <Tag key={item}>{item}</Tag>;
        });
    };

    const displayViewLink = () => {
        if (advertiser.domain !== undefined && (values.preview_url === '...' || values.preview_url === undefined)) {
            return <a href={`https://${advertiser.domain}/`} target="_blank" rel="noopener noreferrer">{`https://${advertiser.domain}/`}</a>;
        }
        if (advertiser.domain === undefined || values.preview_url === undefined || values.preview_url === '') {
            return '';
        }
        return <a href={values.preview_url} target="_blank" rel="noopener noreferrer">{values.preview_url}</a>;
    };

    const displayBadge = () => {
        const { active, draft, interim_status } = values;

        if (active && interim_status !== 'ACTIVE') {
            return <Badge status="success">Active</Badge>;
        }

        if (draft) {
            return <Badge status="attention">Unsaved</Badge>;
        }
        return <Badge status="warning">Inactive</Badge>;
    };

    const displayBanner = () => {
        if (values.banner?.url) {
            return <div className="campaign-summary__banner"><img src={values.banner.url} alt="" /></div>;
        }
        if (values.bannerImage) {
            return <div className="campaign-summary__banner"><img src={values.bannerImage} alt="" /></div>;
        }
        if (values.category && defaultBanners[values.category]) {
            return <div className="campaign-summary__banner"><img src={defaultBanners[values.category][0]} srcSet={`${defaultBanners[values.category][0]} 1x, ${defaultBanners[values.category][1]} 2x, `} alt="" /></div>;
        }
        return null;
    };

    console.log(values);

    return (
        <div className="campaign-summary">
            <div className="campaign-summary__section--name">
                {displayBanner()}
                <div className="campaign-summary__heading">
                    <DisplayText size="small">{values.name !== '' ? values.name : 'New Offer'}</DisplayText>
                    <p>
                        {displayBadge()}
                        {values.private_offer && <Badge status="info">Private</Badge>}
                        {values.offer_type === 'product_giveaway' && <Badge status="info">Product Giveaway</Badge>}
                    </p>
                </div>
                <div className="campaign-summary__category">
                    {values.category}
                </div>
                <div className="campaign-summary__description">
                    {values.description}
                </div>
                <div className="campaign-summary__url">
                    {displayViewLink()}
                </div>
            </div>
            <div className="campaign-summary__section--tags">
                <Heading>TAGS</Heading>
                <div className="campaign-summary__keywords">
                    {displayTags()}
                </div>
            </div>
            {values.offer_type !== 'product_giveaway'
                ? (
                    <div className="campaign-summary__section--commission">
                        <Heading>COMMISSION</Heading>
                        <ul className="campaign-summary__list-wrapper">
                            <li className="included-products">{displayProductsSummary(values)}</li>
                            <li className="rates">{displayCampaignRate(values)}</li>
                            <li className="rates">{displayLongTermRate(values)}</li>
                            {/* <li>Cookies persist for {values.cookie_days} days</li> */}
                        </ul>
                    </div>
                )
                : (
                    <div className="campaign-summary__section--products">
                        <Heading>INCLUDES</Heading>
                        <p className="campaign-summary__products">
                            {values.products.includeAll
                                ? 'ALL products'
                                : `${pluralize(values.products.includeProducts.length, 'product', 'products')}`
                            }
                        </p>
                    </div>
                )
            }
            {values.target && (
                <div className="campaign-summary__section--audience">
                    <Heading>DEMOGRAPHICS</Heading>
                    <ul className="campaign-summary__list-wrapper">
                        <li>Gender: {values.target.gender}</li>
                        <li>Age: {values.target.age_low} - {values.target.age_high === 65 ? '65+' : values.target.age_high}</li>
                        <li>Countries: {values.target.countries.join(', ')}</li>
                    </ul>
                </div>
            )}
            {showManageOffer && (advertiser.paypal && advertiser.paypal.refresh && advertiser.paypal.user)
                && (
                    <div className="campaign-summary__section--audience">
                        <Link to={`/offer/${values.offer_id}/manage`}>Manage Offer Status</Link>
                    </div>
                )}
        </div>
    );
};

CampaignSummary.propTypes = {
    advertiser: PropTypes.shape({
        domain: PropTypes.string,
        paypal: PropTypes.shape({
            refresh: PropTypes.string,
            user: PropTypes.string
        })
    }).isRequired,
    showManageOffer: PropTypes.bool,
    values: PropTypes.shape({
        tags: PropTypes.arrayOf(PropTypes.string),
        preview_url: PropTypes.string,
        active: PropTypes.bool,
        draft: PropTypes.bool,
        interim_status: PropTypes.string,
        banner: PropTypes.shape({
            url: PropTypes.string
        }),
        bannerImage: PropTypes.string,
        category: PropTypes.string,
        name: PropTypes.string,
        description: PropTypes.string,
        offer_type: PropTypes.string,
        private_offer: PropTypes.bool,
        target: PropTypes.shape({
            gender: PropTypes.string,
            age_low: PropTypes.number,
            age_high: PropTypes.number,
            countries: PropTypes.arrayOf(PropTypes.string)
        }),
        products: PropTypes.shape({
            includeAll: PropTypes.bool,
            includeProducts: PropTypes.arrayOf(PropTypes.string)
        }),
        offer_id: PropTypes.string
    }).isRequired
};

CampaignSummary.defaultProps = {
    showManageOffer: false
};

export default CampaignSummary;
