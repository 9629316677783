import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CardElement, injectStripe } from 'react-stripe-elements';
import { Modal, TextField, InlineError, Banner, SkeletonBodyText, SkeletonDisplayText } from '@shopify/polaris';
import { LlamaButton } from 'llama-library/components';

import './card-details.css';

export const LlamaCreditCardDetailsRender = ({ cardData, allowEdit, allowDelete, toggleModal, loading }) => {
    const renderActions = () => {
        let editButton = '';
        let deleteButton = '';
        if (allowEdit) {
            editButton = <LlamaButton classes={['edit']} onClick={() => toggleModal('edit')}>Edit</LlamaButton>;
        }
        if (allowDelete) {
            deleteButton = <LlamaButton classes={['delete']} onClick={() => toggleModal('delete')}>Delete</LlamaButton>;
        }

        if (editButton || deleteButton) {
            return (
                <p className="actions">
                    {editButton}
                    {deleteButton}
                </p>
            );
        }
        return '';
    };

    if (loading) {
        return (
            <div className="cc-info-wrapper skeleton">
                <div className="card-info">
                    <SkeletonDisplayText size="small" />
                    <SkeletonBodyText lines={1} />
                </div>
            </div>
        );
    }

    if (!cardData) {
        return (
            <div className="cc-info-wrapper">
                <p className="card-info unknown">No payment method on file</p>
                {allowEdit
                    && (
                        <p className="actions">
                            <LlamaButton onClick={() => toggleModal('edit')}>Add Payment Method</LlamaButton>
                        </p>
                    )
                }
            </div>
        );
    }

    return (
        <div className="cc-info-wrapper">
            {(cardData.brand && cardData.last4 && cardData.exp_month && cardData.exp_year)
                ? (
                    <>
                        <p className={`card-info ${cardData.brand.toLowerCase().replace(/\s/g, '-')}`}>
                            {cardData.brand} ending in {cardData.last4}
                            <span className="expiration-date">Expires on {cardData.exp_month}/{cardData.exp_year}</span>
                        </p>
                        {renderActions()}
                    </>
                )
                : <p className="card-info unknown">Unable to load payment method details</p>
            }
        </div>
    );
};

/**
 * Reusable component to show the user the credit card info on file (card type, last 4 digits, and expiration date). Loads a Stripe credit card form in a modal when the edit button is clicked, action should be handled in parent component via props.updateCard
 * Parent needs to be wrapped in a StripeProvider in App.js
 *
 * Props:
 * @param {Object}   cardData {brand: String!, last4: Int!, exp_month: Int!, exp_year: Int!} The user's card info
 * @param {Function} updateCard(stripeToken) function should return a promise that returns an object with success status and either the new card data or an error message {success: Boolean!, cardData: Object (same structure as props), error: String}
 */
const LlamaCreditCardDetails = (props) => {
    const { stripe, loading, deleteConfirmation } = props;

    const [cardData, setCardData] = useState({});

    const [modals, setModals] = useState({
        edit: false,
        delete: false
    });
    const [ccDetails, setCcDetails] = useState({
        nameOnCard: ''
    });
    const [ccErrors, setCcErrors] = useState({
        nameOnCard: null,
        card: null
    });
    const [generalError, setGeneralError] = useState('');

    const [submitAttempted, setSubmitAttempted] = useState(false);
    const [saving, setSaving] = useState(false);

    const toggleModal = (type) => {
        setModals({
            ...modals,
            [type]: !modals[type]
        });
    };

    // set cardData state when props are available
    useEffect(() => {
        setCardData(props.cardData);
    }, [props.cardData]);

    // if previously submitted, validate while typing
    useEffect(() => {
        if (submitAttempted) {
            validateForm();
        }
    }, [ccDetails.nameOnCard]);

    const handleNameChange = (value) => {
        setCcDetails({
            ...ccDetails,
            nameOnCard: value
        });
    };

    const validateForm = () => {
        let isFormValid = true;
        const errors = {};
        const { nameOnCard } = ccDetails;

        if (!nameOnCard || nameOnCard.trim().length === 0) {
            isFormValid = false;
            errors.nameOnCard = 'Name is required.';
        } else {
            const pattern = new RegExp(/^[A-Za-z '\-.]+$/);
            if (!pattern.test(nameOnCard)) {
                isFormValid = false;
                errors.nameOnCard = 'Please enter valid name.';
            }
        }

        setCcErrors(errors);

        return !isFormValid;
    };

    const updateCard = async () => {
        setSubmitAttempted(true);
        const hasFormErrors = validateForm();

        if (!hasFormErrors) {
            setSaving(true);

            const stripeTokenData = await stripe.createToken({});

            if (stripeTokenData.error && (!stripeTokenData.token || !stripeTokenData.token.id)) {
                setCcErrors({
                    ...ccErrors,
                    card: stripeTokenData.error.message
                });
                setSaving(false);
                return;
            }

            props.updateCard(stripeTokenData.token, ccDetails.nameOnCard)
                .then((cardDataResponse) => {
                    if (cardDataResponse.success) {
                        setCardData(cardDataResponse.cardData);
                        setSaving(false);
                        toggleModal('edit');
                    } else {
                        setSaving(false);
                        setGeneralError(cardDataResponse.error);
                    }
                });
        }
    };

    const updateCardEnter = (e) => {
        e.preventDefault();
        updateCard();
    };

    const deleteCard = async () => {
        setSaving(true);
        props.deleteCard()
            .then((response) => {
                if (response.success) {
                    setCardData(response.cardData);
                    setSaving(false);
                    toggleModal('delete');
                } else {
                    setSaving(false);
                    setGeneralError(response.error);
                }
            });
    };

    return (
        <>
            <Modal
                title={`${cardData ? 'Update' : 'Add'} Payment Method`}
                primaryAction={{
                    content: 'Save',
                    onAction: updateCard,
                    loading: saving
                }}
                secondaryActions={[{
                    content: 'Cancel',
                    onAction: () => toggleModal('edit')
                }]}
                onClose={() => toggleModal('edit')}
                open={modals.edit}
            >
                <Modal.Section>
                    <form onSubmit={updateCardEnter} className="cc-update-form">
                        {generalError && !saving
                            && <Banner status="critical" title={generalError} />
                        }
                        <ul>
                            <li>
                                <TextField
                                    value={ccDetails.nameOnCard}
                                    label="Cardholder Name"
                                    type="text"
                                    onChange={handleNameChange}
                                    error={ccErrors.nameOnCard}
                                />
                            </li>
                            <li>
                                <label htmlFor="card-details">Card Number and Expiration Date</label>
                                <CardElement id="card-details" />
                                {ccErrors.card && <InlineError message={ccErrors.card} fieldID="card-details" />}
                                <p className="cards">We accept Visa, MasterCard, American Express, Discover, Diners Club, China UnionPay, and JCB</p>
                            </li>
                        </ul>
                    </form>
                </Modal.Section>
            </Modal>

            <Modal
                title="Are you sure you want to delete this payment method?"
                primaryAction={{
                    content: 'Yes, delete',
                    onAction: deleteCard,
                    loading: saving
                }}
                secondaryActions={[{
                    content: 'No, go back',
                    onAction: () => toggleModal('delete')
                }]}
                onClose={() => toggleModal('delete')}
                open={modals.delete}
            >
                <Modal.Section>
                    {deleteConfirmation}
                </Modal.Section>
            </Modal>

            <LlamaCreditCardDetailsRender
                loading={loading}
                cardData={cardData}
                toggleModal={toggleModal}
                allowEdit
                allowDelete={!!deleteConfirmation}
            />
        </>
    );
};

LlamaCreditCardDetailsRender.propTypes = {
    cardData: PropTypes.shape({
        brand: PropTypes.string,
        last4: PropTypes.string,
        exp_month: PropTypes.number,
        exp_year: PropTypes.number
    }),
    allowEdit: PropTypes.bool.isRequired,
    allowDelete: PropTypes.bool,
    toggleModal: PropTypes.func,
    loading: PropTypes.bool
};

LlamaCreditCardDetailsRender.defaultProps = {
    toggleModal: null,
    cardData: null,
    loading: false,
    allowDelete: false
};

LlamaCreditCardDetails.propTypes = {
    cardData: PropTypes.shape({
        brand: PropTypes.string,
        last4: PropTypes.string,
        exp_month: PropTypes.number,
        exp_year: PropTypes.number
    }),
    stripe: PropTypes.shape({
        createToken: PropTypes.func
    }).isRequired,
    loading: PropTypes.bool,
    updateCard: PropTypes.func.isRequired,
    deleteCard: PropTypes.func,
    deleteConfirmation: PropTypes.element
};

LlamaCreditCardDetails.defaultProps = {
    cardData: null,
    loading: false,
    deleteCard: null,
    deleteConfirmation: null
};

export default injectStripe(LlamaCreditCardDetails);
