import { useState } from 'react';

export const socialOptions = [
    { label: 'All', value: 'all' },
    { label: 'Facebook', value: 'facebook' },
    { label: 'Instagram', value: 'instagram' },
    { label: 'Twitter', value: 'twitter' },
    { label: 'YouTube', value: 'youtube' },
    { label: 'TikTok', value: 'tiktok' },
];

const checkIfValidTikTokAccount = (tikTokData) => {
    const activeTikTokAccounts = tikTokData.filter((item) => { return item.account_verified === true; });
    return activeTikTokAccounts.length > 0;
};

const filterFactory = (key) => (item) => {
    if (item.affiliate) {
        if (key === 'tikTokData') {
            return item.affiliate
            && Array.isArray(item.affiliate[key])
            && item.affiliate[key].length > 0
            && checkIfValidTikTokAccount(item.affiliate[key]);
        }

        return item.affiliate
        && Array.isArray(item.affiliate[key])
        && item.affiliate[key].length > 0;
    }

    if (Array.isArray(item[key])) {
        return item[key].length > 0;
    }

    return false;
};

const useSocialFilter = (defaultValue) => {

    const defaultFilter = () => true;
    const [socialFilter, setSocialFilter] = useState(defaultValue);

    const filters = {
        all: defaultFilter,
        facebook: filterFactory('facebookData'),
        instagram: filterFactory('instagramDataV2'),
        youtube: filterFactory('youTubeData'),
        twitter: filterFactory('twitterData'),
        tiktok: filterFactory('tikTokData')
    }

    const appliedSocialFilter = filters[socialFilter] 
        ? filters[socialFilter] 
        : defaultFilter;

    return { socialFilter, setSocialFilter, appliedSocialFilter };

}

export default useSocialFilter;