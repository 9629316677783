import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Page, Spinner } from '@shopify/polaris';

import { getApplicationsSummary } from '../actions/applications';
import { getBrandedSignUpCustomizations } from '../actions/branded-sign-up';
import getFeaturedAmbassadors from '../actions/featured-ambassadors';

import NewUserProgress from '../components/dashboard/new-user-progress';
import DashboardStats from '../components/dashboard/dashboard-stats';
import FeaturedAmbassadorsList from '../components/dashboard/featured-ambassadors-list';

const Dashboard = ({
    advertiser,
    getBrandedSignUpCustomizations,
    history,
    getFeaturedAmbassadors,
    featuredAmbassadors
}) => {

    const [brandedSignUp, setBrandedSignUp] = useState(null);
    const [applications, setApplications] = useState([]);
    const [brandedLoading, setBrandedLoading] = useState(true);
    const [applicationsLoading, setApplicationsLoading] = useState(true);
    const [ambassadors, setAmbassadors] = useState(featuredAmbassadors);
    const [fromRedux, setFromRedux] = useState(false);

    // Make graph call for featured ambassadors
    const makeAPICall = (advertiserIdParam) => {
        getFeaturedAmbassadors(advertiserIdParam)
            .then((result) => {
                if (
                    result
                    && result.action
                    && result.action.payload
                    && result.action.payload.affiliates
                ) {
                    setAmbassadors(result.action.payload.affiliates);
                    setFromRedux(true);
                }
            });
    };

    // Initial Calls
    useEffect(() => {
        getBrandedSignUpCustomizations(advertiser.advertiser_id)
            .then((result) => {
                if (result && result.value && result.value.data && result.value.data.advertiserById && result.value.data.advertiserById.branded_sign_up && result.value.data.advertiserById.branded_sign_up.offer_id) {
                    setBrandedSignUp(result.value.data.advertiserById.branded_sign_up.offer_id);
                }
                setBrandedLoading(false);
            });

        getApplicationsSummary(advertiser.advertiser_id)
            .then((result) => {
                if (result && result.applications) {
                    setApplications(result.applications);
                }
                setApplicationsLoading(false);
            });

        // Call graph if fromRedux is false and if ambassadors is its initial value of []
        if (!fromRedux && ambassadors.length === 0) {
            makeAPICall(advertiser.advertiser_id);
        }
    }, []);

    let hasPaypal = false;
    if (advertiser.paypal && advertiser.paypal.user) {
        hasPaypal = true;
    }

    return (
        <Page separator title="Dashboard">
            {!brandedLoading && !applicationsLoading
                ? (
                    <>
                        <NewUserProgress
                            paypalComplete={hasPaypal}
                            offersComplete={advertiser.offers.length > 0}
                            logoComplete={!!advertiser.avatar_image}
                            pageComplete={!!brandedSignUp}
                            ambassadorsComplete={applications.length > 0}
                            history={history}
                        />
                        {advertiser.offers.length > 0 && applications.length > 0
                            && <DashboardStats advertiser_id={advertiser.advertiser_id} />
                        }
                        <FeaturedAmbassadorsList ambassadors={ambassadors} history={history} />
                    </>
                )
                : <Spinner />
            }
        </Page>
    );
};

Dashboard.propTypes = {
    advertiser: PropTypes.shape({
        advertiser_id: PropTypes.string,
        paypal: PropTypes.shape({
            user: PropTypes.string
        }),
        offers: PropTypes.arrayOf(PropTypes.shape({
            offer_id: PropTypes.string
        })),
        avatar_image: PropTypes.string
    }).isRequired,
    getBrandedSignUpCustomizations: PropTypes.func.isRequired,
    history: PropTypes.shape({ push: PropTypes.func }).isRequired,
    getFeaturedAmbassadors: PropTypes.func.isRequired,
    featuredAmbassadors: PropTypes.arrayOf(PropTypes.shape({
        _id: PropTypes.string,
        name: PropTypes.string,
        account: PropTypes.shape({ categories: PropTypes.arrayOf(PropTypes.string) }),
        reach: PropTypes.string,
        tracking: PropTypes.shape({ Stat: PropTypes.shape({ epc: PropTypes.string }) }),
        photo: PropTypes.string,
        facebookData: PropTypes.arrayOf(PropTypes.shape({ score: PropTypes.number })),
        instagramData: PropTypes.arrayOf(PropTypes.shape({ score: PropTypes.number })),
        twitterData: PropTypes.arrayOf(PropTypes.shape({ score: PropTypes.number })),
        youTubeData: PropTypes.arrayOf(PropTypes.shape({ score: PropTypes.number }))
    }))
};

Dashboard.defaultProps = { featuredAmbassadors: [] };

const mapStateToProps = (state) => {
    return {
        advertiser: state.advertiser,
        featuredAmbassadors: state.featuredAmbassadors.ambassadors
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getBrandedSignUpCustomizations: (advertiserId) => { return dispatch(getBrandedSignUpCustomizations(advertiserId)); },
        getFeaturedAmbassadors: (advertiserId) => { return dispatch(getFeaturedAmbassadors(advertiserId)); }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
