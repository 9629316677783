import React from 'react';

import { I18n, ConsoleLogger as Logger } from '@aws-amplify/core';
import { Auth } from 'aws-amplify';
import {
    SignIn,
    AuthPiece,
    FormSection,
    SectionHeader,
    SectionBody,
    FormRow,
    InputRow,
    Label,
    SectionFooter,
    SectionFooterContent,
    Button,
    Link
} from 'aws-amplify-react';
import { LlamaButton } from 'llama-library/components';

class LlamaInitalLogin extends AuthPiece {

    render() {

        const { theme, email } = this.props;

        const applyButtonClass = ['amplify-button'];

        return (
            <div className="amplify-wrapper">
                {/* <FormSection theme={theme}> */}
                    <SectionHeader theme={theme}>Thank you for signing up!</SectionHeader>
                    <SectionBody theme={theme}>
                        <p className="amplify-paragraph">
                            Our herd of llamas are working hard preparing your account.
                        </p>
                        <p className="amplify-paragraph">
                            Your username and password have been sent to the email address associated with Shopify: <br />
                            <span style={{ fontWeight: 'bold' }} className="amplify-accent">{` ${email}`}</span>
                        </p>
                    </SectionBody>
                    <div className="amplify__custom-footer">
                        <div className="amplify__button-wrapper">
                            <LlamaButton 
                                background="rgb(253, 42, 84)"
                                classes={applyButtonClass}
                                theme={theme} 
                                onClick={() => window.location.href = `https://merchants.llama.app/?email=${email}`}
                                disabled={this.state.isLoading}>
                                Sign In
                            </LlamaButton>
                            {/* <Button theme={theme} onClick={() => window.location.href = `https://merchants.llama.app/?email=${email}`}>Sign In</Button> */}
                        </div>
                    </div>
                {/* </FormSection> */}
            </div>
        )
    }

}

export default LlamaInitalLogin;