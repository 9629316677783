import React, { useState, useEffect } from 'react';
import { Card, Select, Tooltip, Banner, Icon, Badge } from '@shopify/polaris';
import { ExternalSmallMinor } from '@shopify/polaris-icons';
import moment from 'moment';
import { LlamaPagination, EmptyState } from 'llama-library/components';

import formatMetrics from '../../utils/format-metrics';
import usePages from '../../hooks/use-pages';

import './twitter-affiliate-detail.css';

export const checkValidTwitter = (twitterAccount) => {
    if (
        !twitterAccount
        || !twitterAccount.statistics
        || twitterAccount.statistics.avgRetweets === undefined
        || twitterAccount.statistics.avgRetweets === null
        || twitterAccount.statistics.avgFavorites === undefined
        || twitterAccount.statistics.avgFavorites === null
    ) {
        return false;
    }

    return true;
}

/**
 * @function TwitterAffiliateDetail
 * Accepts props, returns TwitterAffiliateDetail Component.
 * Displays Twitter posts, pagination for posts and account selection controller.
 * 
 * @param {Object} props - props Object
 * @returns {Component}
 */
const TwitterAffiliateDetail = (props) => {
    console.log("TWITTER PROPS", props)

    let twitterLiveData = props.data;
    if (!twitterLiveData || !Array.isArray(twitterLiveData) || twitterLiveData.length === 0) {
        twitterLiveData = [null];
    }

    let primaryAccount = twitterLiveData.find((account) => {
        return props.affiliate.twitter === account.user.id;
    });

    if (!primaryAccount) {
        primaryAccount = twitterLiveData[0];
    }

    let totalReach = 0;
    if (primaryAccount.user.followers) {
        totalReach = twitterLiveData.reduce((acc, account) => {
            return acc + account.user.followers;
        }, 0);
    }

    const [twitterAccount, setTwitterAccount] = useState(primaryAccount);
    const [twitterPosts, setTwitterPosts] = useState([]);

    // Handle account selection
    const handleSelectAccount = (value) => {
        const newAccount = twitterLiveData.find((item) => {
            return item.user.id === value;
        })
        if (newAccount && checkValidTwitter(newAccount)) {
            setTwitterAccount(newAccount);
            if (newAccount.posts) {
                setTwitterPosts(newAccount.posts);
                setPageTo(0);
            } else {
                setTwitterPosts([]);
                setPageTo(0);
            }
            window.scrollTo(0, 0);
        }
    }

    useEffect(() => {
        // Set up twitter posts.
        if (twitterAccount && twitterAccount.posts) {
            setTwitterPosts(twitterAccount.posts);
        } else {
            setTwitterPosts([]);
        }
    }, []);

    const {
        previousPage,
        nextPage,
        currentPage,
        hasNext,
        hasPrevious,
        maxPages,
        posts,
        setPageTo
    } = usePages(0, 5, twitterPosts);

    if (!twitterLiveData || !Array.isArray(twitterLiveData) || twitterLiveData.length === 0) {
        return null;
    }

    const goToTwitter = () => {
        window.open(`https://twitter.com/${twitterAccount.user.screenName}`);
    };

    // This check should always return false because the twitter
    // account should be filtered our prior to being passed into
    // this component. Additionally this check is done after the
    // the usePages hook in order to ensure that the correct
    // number of hooks is called.
    if (!checkValidTwitter(twitterAccount)) {
        return null;
    }

    // Twitter account general info.
    const twitterUser = twitterAccount.user.screenName;
    const twitterLink = `https://twitter.com/${twitterUser}`;

    // Twitter Account Statistics
    const statValues = twitterAccount.statistics;
    const { avgRetweets, avgFavorites } = statValues;
    const followerCount = twitterAccount.user.followers;
    const { postCount } = twitterAccount.user;
    const { fromCache } = twitterAccount;

    const showLetterGrade = (score) => {
        if (score >= 81) {
            return <Badge status="success"> A </Badge>;
        }
        if (score <= 80 && score >= 61) {
            return <Badge status="info"> B </Badge>;
        }
        if (score <= 60 && score >= 41) {
            return <Badge status="attention"> C </Badge>;
        }
        if (score <= 40 && score >= 21) {
            return <Badge status="warning"> D </Badge>;
        }
        return <Badge status="warning"> F </Badge>;
    };

    /**
     * @function renderTwitterPost
     * Accepts an array of twitterPosts, maps through and renders
     * each post as its own TwitterPost component.
     * 
     * @param {Array} twitterPosts - array of twitterPosts
     * @returns {FunctionComponent}
     */
    const renderTwitterPosts = () => {
        if (posts && Array.isArray(posts) && posts.length > 0) {
            return (
                <ul className="affiliate-tab__twitter-posts">
                    {
                        posts.map((tweet) => {
                            let content = tweet.content.replace(/<.+?>/g, ''); // remove tags if they exist
                            if (tweet.links && tweet.links.length > 0) {
                                tweet.links.forEach((link) => {
                                    content = content.replace(link.text, `<a href="${link.link}" target="_blank" rel="noopener nofollow">${link.replace}</a>`);
                                });
                            }
                            content = content.replace(/\n/g, '<br />');
                            return (
                                <li className="tweet" key={tweet.id}>
                                    <div className="user">
                                        <p className="photo"><a href={`https://twitter.com/${tweet.user.handle}`} target="_blank" rel="noopener noreferrer"><img src={tweet.user.photo} alt={`${tweet.user.name}'s profile pic`} width="30" height="30" /></a></p>
                                        <p className="name">
                                            <a href={`https://twitter.com/${tweet.user.handle}`} target="_blank" rel="noopener noreferrer">
                                                <b data-is-verified={tweet.user.verified}>{tweet.user.name}</b>
                                                <span>{tweet.user.handle}</span>
                                            </a>
                                        </p>
                                    </div>
                                    {tweet.replyTo
                                        && <p className="label">Replying to <a href={`https://twitter.com/${tweet.replyTo}`} target="_blank" rel="noopener noreferrer">@{tweet.replyTo}</a></p>
                                    }
                                    {tweet.isRetweet
                                        && <p className="label retweet">Retweeted by <a href={`https://twitter.com/${twitterAccount.user.screen_name}`} target="_blank" rel="noopener noreferrer">{twitterAccount.user.name}</a></p>
                                    }
                                    <p className="content" dangerouslySetInnerHTML={{ __html: content }} />
                                    {tweet.media != null && tweet.media.length === 1 && tweet.media[0].type === 'photo'
                                        && <p className="photo" key={tweet.media[0].photo}><img src={tweet.media[0].photo} alt="" /></p>
                                    }
                                    {tweet.media != null && tweet.media.length === 1 && tweet.media[0].type === 'video'
                                        && <video controls key={tweet.media[0].photo}><source src={tweet.media[0].videoSource} poster={tweet.media[0].photo} /></video>
                                    }
                                    {tweet.media != null && tweet.media.length > 1
                                        && (
                                            <ul className="photo-album">
                                                {tweet.media.map((media) => {
                                                    return <li key={media.photo}><img src={media.photo} alt="" /></li>;
                                                })}
                                            </ul>
                                        )
                                    }
                                    <div className="metrics">
                                        <ul>
                                            <li>{formatMetrics(tweet.retweetCount)} Retweet{ tweet.retweetCount !== 1 && 's'}</li>
                                            <li>{formatMetrics(tweet.favoriteCount)} Like{ tweet.favoriteCount !== 1 && 's'}</li>
                                        </ul>
                                        <p><time dateTime={tweet.createdDate}><a href={`https://twitter.com/${tweet.user.handle}/status/${tweet.id}`} target="_blank" rel="noopener noreferrer">{moment(tweet.createdDate).format('MMM D, YYYY')}</a></time></p>
                                    </div>
                                    <p className="actions">
                                        <a className="reply" href={`https://twitter.com/intent/tweet?in_reply_to=${tweet.id}`}>Reply</a>
                                        <a className="retweet" href={`https://twitter.com/intent/retweet?tweet_id=${tweet.id}`}>Retweet</a>
                                        <a className="like" href={`https://twitter.com/intent/like?tweet_id=${tweet.id}`}>Like</a>
                                    </p>
                                </li>
                            );
                        })
                    }
                </ul>
            );
        }
        return <div className="affiliate-tab__empty">There are no tweets to display.</div>;
    };

    const selectOptions = twitterLiveData.map((item) => {
        return {
            label: item.user.name,
            value: item.user.id
        };
    });

    return (
        <>
            {twitterLiveData.length > 1
                && (
                    <div className="account-switcher">
                        <p className="num-pages">
                            {props.affiliate.name} reaches {formatMetrics(totalReach)} people across {twitterLiveData.length} page{twitterLiveData.length !== 1 && 's'}
                        </p>
                        <div className="current-account">
                            <Select
                                label="Choose Page:"
                                labelInline
                                options={selectOptions}
                                value={twitterAccount.user.id}
                                onChange={handleSelectAccount}
                            />
                        </div>
                    </div>
                )
            }
            <div className="affiliate-twitter" data-test="component-twitterDetail">
                <Card.Section>
                    {fromCache && <Banner status="info">Oops, we&rsquo;re having some trouble connecting to Twitter. These may not be the most recent tweets.</Banner>}
                    <div className="affiliate-tab__top">
                        <p className="affiliate-tab__user-info">
                            <img className="twitter-photo" src={twitterAccount.user.profilePicture} alt={`${twitterAccount.user.name}'s profile pic`} />
                            <span className="affiliate-tab__title">
                                <span className="affiliate-tab__name" data-is-twitter-verified={twitterAccount.user.verified}>{twitterAccount.user.name}</span>
                                <span className="affiliate-tab__stat-label">@{twitterAccount.user.screenName}</span>
                            </span>
                        </p>
                        <a href={twitterLink} target="_blank" rel="nofollow noopener noreferrer" className="affiliate-tab__link-button">
                            View on Twitter <Icon source={ExternalSmallMinor} />
                        </a>
                    </div>
                </Card.Section>
                <Card.Section>
                    <div className="affiliate-tab__row">
                        <div className="affiliate-tab__stats affiliate-tab__stats-twitter">
                            <div className="affiliate-tab__stat-item">
                                <h2 className="affiliate-tab__stat-num">
                                    {formatMetrics(followerCount)}
                                </h2>
                                <p className="affiliate-tab__stat-label">
                                    Follower{followerCount !== 1 && 's'}
                                </p>
                            </div>
                            <div className="affiliate-tab__stat-item">
                                <h2 className="affiliate-tab__stat-num">
                                    {formatMetrics(postCount)}
                                </h2>
                                <p className="affiliate-tab__stat-label">
                                    Tweet{postCount !== 1 && 's'}<Tooltip content="Refers to the ambassador’s own tweets and content they’ve retweeted."><span className="info-tooltip">i</span></Tooltip>
                                </p>
                            </div>
                            <div className="affiliate-tab__stat-item">
                                <h2 className="affiliate-tab__stat-num">
                                    {formatMetrics(avgRetweets)}
                                </h2>
                                <p className="affiliate-tab__stat-label">
                                    Avg. Retweet{avgRetweets !== 1 && 's'}<Tooltip content="Refers to retweets received on the ambassador’s own tweets"><span className="info-tooltip">i</span></Tooltip>
                                </p>
                            </div>
                            <div className="affiliate-tab__stat-item">
                                <h2 className="affiliate-tab__stat-num">
                                    {formatMetrics(avgFavorites)}
                                </h2>
                                <p className="affiliate-tab__stat-label">
                                    Avg. Like{avgFavorites !== 1 && 's'}<Tooltip content="Refers to likes received on the ambassador’s own tweets"><span className="info-tooltip">i</span></Tooltip>
                                </p>
                            </div>
                            <div className="affiliate-tab__stat-item">
                                <h2 className="affiliate-tab__stat-num">
                                    {showLetterGrade(twitterAccount.score)}
                                </h2>
                                <p className="affiliate-tab__stat-label">
                                    Engagement Score
                                </p>
                            </div>
                        </div>
                    </div>
                </Card.Section>
                <Card.Section>
                    <div className="affiliate-tab__posts">
                        {renderTwitterPosts()}
                        {!hasNext
                            && (
                                <div className="more-posts">
                                    <EmptyState
                                        message="Looking for more tweets?"
                                        paragraph={`These are all the posts we graded ${twitterAccount.user.name} on. If you need to look deeper, you’ll have to go to their Twitter page.`}
                                        ctaText="View more posts on Twitter"
                                        ctaAction={goToTwitter}
                                    />
                                </div>
                            )}
                    </div>
                    <div className="affiliate-tab__page-controls">
                        {maxPages > 0
                            && (
                                <LlamaPagination
                                    hasNext={hasNext}
                                    hasPrevious={hasPrevious}
                                    onNext={nextPage}
                                    onPrevious={previousPage}
                                    currentPage={currentPage}
                                    maxPages={maxPages}
                                    data-test="component-llamaPagination"
                                />
                            )
                        }
                    </div>
                </Card.Section>
            </div>
        </>
    )
}

export default TwitterAffiliateDetail;
