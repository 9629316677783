import React, {Component} from 'react';
import {connect} from 'react-redux';
import ImportAffiliate from '../components/import-affiliates/import-affiliates';
import ImportAffiliatesList from '../components/import-affiliates/import-affiliates-list';
import {searchImportedAffiliates} from '../actions/searchImportedAffiliates';
import {getAdvertiser} from "../actions/advertiser";
import { getBrandedSignUp } from '../actions/branded-sign-up';
import calculateAffiliateReach from '../utils/affiliate-reach';
import {
    Page,
    Layout,
    Spinner,
    Banner
} from '@shopify/polaris';

/* import axios from 'axios';
import {NEXT_APP_CSV_PARSE} from '../config'; */
import {sendBackendRequest} from '../actions/sendRequest'
const ITEMS_PRE_PAGE = 10;

export class AffiliateImport extends Component {

    constructor(props) {
        super(props);
        this.handleFiles = this.handleFiles.bind(this);
        this.state = {
            advertiser_id: props.advertiser.advertiser_id,
            uploaded: false,
            currentPage: 0,
            importedAffiliates: [],
            loading: true,
            isLoading: false,
            errorMsg: null,
            successMsg: null,
            warningMsg: null,
            programDetails: null,
            brandedSignupLoading: true
        };

        this.nextPage = this.nextPage.bind(this);
        this.previousPage = this.previousPage.bind(this);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps.advertiser && (nextProps.advertiser.advertiser_id !== prevState.advertiser_id)) {
            return {
                advertiser_id: nextProps.advertiser.advertiser_id
            };
        }

        return null;
    }

    componentDidMount() {
        this.props.searchImportedAffiliates(this.props.advertiser.advertiser_id, "")
            .then((response) => {
                let importedAffiliates = [];
                response.value.affiliates.forEach((item) => {
                    item.reach = calculateAffiliateReach(item);
                    if (item.reference !== null && item.reference[0].referrer && (item.reference[0].referrer === this.props.advertiser.advertiser_id)) {
                        importedAffiliates.push(item);
                    }
                })
                this.setState({"importedAffiliates": importedAffiliates, loading: false});
            });
        
        this.props.getBrandedSignUp(this.props.advertiser.advertiser_id)
            .then(response => {
                this.setState({
                    brandedSignupLoading: false
                })
                if(response.value && response.value.data && response.value.data.advertiserById){
                    this.setState({
                        programDetails: response.value.data.advertiserById
                    });
                }
            })
    }

    componentDidUpdate(prevProps){
        if(prevProps.advertiser.advertiser_id !== this.props.advertiser.advertiser_id) {
            this.props.searchImportedAffiliates(this.state.advertiser_id, "")
                .then((response) => {
                    let importedAffiliates = [];
                    response.value.affiliates.forEach((item) => {
                        item.reach = calculateAffiliateReach(item);
                        if (item.reference[0].referrer && item.reference[0].referrer === this.state.advertiser_id) {
                            importedAffiliates.push(item);
                        }
                    })
                    this.setState({"importedAffiliates": importedAffiliates, loading: false});
                });
        }
	}

    handleUpload = () => {
        this.setState({
            uploaded: !this.state.uploaded
        });
    };

    fileReset = () => {
        this.setState({
            uploaded: !this.state.uploaded
        })
    };

    handleFiles = (files) => {
        if (files[0].type !== 'text/csv') {
            this.setState({
                errorMsg: 'Oops, we couldn\'t find any ambassadors in that file. Check to make sure the file ends in .csv and try again.'
            });
            return;
        }

        this.setState({ isLoading: true, warningMsg: null, successMsg: null, errorMsg: null });
        let shopId = this.props.advertiser.advertiser_id;
        const programDetails = this.state.programDetails;
        
        let currentThis = this;
        var reader = new FileReader();
        reader.onload = function (e) {
            const payload = {
                data: [reader.result],
                shopId,
                programDetails
            };

            /* let headers = {
                "Content-Type": 'application/json'
            };

            let parseCsvData = axios.post(NEXT_APP_CSV_PARSE, payload, {headers}) */
            return sendBackendRequest('merchant', 'csvParser', payload)
                .then((response) => {
                    //if a human-friendly error message was sent, throw it, otherwise continue processing
                    if(response.data.csvError && response.data.csvError.type === 'failed'){
                        throw {
                            csvError: response.data.csvError.message
                        };
                    }

                    let newAffiliates = [];
                    if (response.data.affiliates && response.data.affiliates.length > 0) {
                        newAffiliates = currentThis.addImportedAffiliated(response.data.affiliates);
                        
                        if(response.data.csvError && response.data.csvError.type && response.data.csvError.type === 'partial_import'){
                            //alert user if there's rows in the file that were empty (show the count)
                            currentThis.setState({ importedAffiliates: newAffiliates, isLoading: false, warningMsg: response.data.csvError.message })
                        }else{
                            currentThis.setState({ importedAffiliates: newAffiliates, isLoading: false, successMsg: 'Invitations successfully sent to '+newAffiliates.length + ' ambassadors' })
                        }
                    }else {
                        if(response.data.csvError && response.data.csvError.type && response.data.csvError.type === 'partial_import'){
                            //alert user if there's rows in the file that were empty (show the count)
                            currentThis.setState({ isLoading: false, warningMsg: response.data.csvError.message })
                        }else{
                            let successMsg = 'Invitations successfully sent';
                            if(response.data.emailCount){
                                successMsg = 'Invitations successfully sent to '+ response.data.emailCount + ' ambassador';
                                if(response.data.emailCount != 1) successMsg+= 's';
                            }
                            currentThis.setState({ isLoading: false, successMsg })
                        }
                    }
                    return currentThis.props.searchImportedAffiliates(currentThis.props.advertiser.advertiser_id, "");
                })
                .then((response) => {
                    console.log(response);
                    let importedAffiliates = [];
                    response.value.affiliates.forEach((item) => {
                        item.reach = calculateAffiliateReach(item);
                        if (item.reference !== null && item.reference[0].referrer && (item.reference[0].referrer === currentThis.props.advertiser.advertiser_id)) {
                            importedAffiliates.push(item);
                        }
                    })
                    currentThis.setState({ "importedAffiliates": importedAffiliates, loading: false });
                })
                .catch(err => {
                    console.log('Parse CSV ERROR:', err);

                    let errorMessage = 'An error occurred when importing ambassadors. Please try again later.';
                    if(err.csvError){
                        errorMessage = err.csvError;
                    }
                    currentThis.setState({ isLoading: false, errorMsg: errorMessage });
                })
        };
        reader.readAsText(files[0]);
    };

    nextPage() {
        const newPageNumber = this.state.currentPage + 1;
        this.setState({currentPage: newPageNumber});
    }

    previousPage() {
        if (this.state.currentPage === 0) {
            return
        }
        const newPageNumber = this.state.currentPage - 1;
        this.setState({currentPage: newPageNumber});
    }

    addImportedAffiliated = (affiliates) => {
        let tempAffiliates = this.state.importedAffiliates;

        if (!(affiliates.length > 0))
            return null;
        tempAffiliates.unshift(...affiliates);

        return tempAffiliates;
    };

    dismissBanner = () => {
        this.setState({ successMsg: null, errorMsg: null, warningMsg: null });
    }

    render() {
        let {importedAffiliates} = this.state;
        let hasNext = true;
        let hasPrevious = true;
        const {currentPage} = this.state;
        const totalMatches = importedAffiliates.length;
        const totalPages = Math.ceil(totalMatches / ITEMS_PRE_PAGE);

        if (totalMatches > ITEMS_PRE_PAGE) {
            const start = currentPage * ITEMS_PRE_PAGE;
            const end = (currentPage + 1) * ITEMS_PRE_PAGE;
            importedAffiliates = importedAffiliates.slice(start, end);
        }

        if (importedAffiliates.length === 0) {
            hasNext = false;
            hasPrevious = true;
        }

        if (ITEMS_PRE_PAGE * (currentPage + 1) >= totalMatches) {
            hasNext = false;
        }

        if (currentPage === 0) {
            hasPrevious = false;
        }

        const pagination = {
            nextPage: this.nextPage,
            previousPage: this.previousPage,
            hasNext,
            hasPrevious,
            currentPage,
            totalPages
        };

        const errorBanner = this.state.errorMsg ? (<div className="import--status"><Banner title="Unable to invite ambassadors" status="critical" onDismiss={this.dismissBanner}>{this.state.errorMsg}</Banner></div>) : null;
        const warningBanner = this.state.warningMsg ? (<div className="import--status"><Banner title="Only some of your ambassadors were invited." status="warning" onDismiss={this.dismissBanner}>{this.state.warningMsg}</Banner></div>) : null;
        const successBanner = this.state.successMsg ? (<div className="import--status"><Banner title="Ambassadors invited" status="success" onDismiss={this.dismissBanner}>{this.state.successMsg}</Banner></div>) : null;

        return (
            this.state.isLoading ? 
            <div className="import__spinner-wrapper"><Spinner/></div> :
            <Page
                title="Import Ambassadors"
                separator={true}
            >
                {errorBanner}
                {successBanner}
                {warningBanner}
                <Layout>
                    <Layout.Section>
                        {this.state.brandedSignupLoading
                        ? <div className="import__spinner-wrapper"><Spinner/></div>
                        : <ImportAffiliate {...this.props} handleFiles={this.handleFiles}
                                         handleUpload={this.handleUpload}/>
                        }
                    </Layout.Section>
                    <Layout.Section>
                        {this.props.advertiser.offers.length > 0 &&
                        this.props.advertiser.branded_sign_up &&
                        this.props.advertiser.branded_sign_up.offer &&
                        this.props.advertiser.branded_sign_up.banner_image &&
                        this.props.advertiser.avatar_image &&
                        <ImportAffiliatesList
                            {...this.props}
                            {...this.state}
                            importedAffiliates={importedAffiliates}
                            pagination={pagination}/>
                        }
                    </Layout.Section>
                </Layout>
            </Page>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        advertiser: state.advertiser
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getBrandedSignUp: (advertiser_id) => dispatch(getBrandedSignUp(advertiser_id)),
        getAdvertiser: (shop) => dispatch(getAdvertiser(shop)),
        searchImportedAffiliates: (term, reference_id, filters, options) => dispatch(searchImportedAffiliates(term, reference_id, filters, options))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AffiliateImport);