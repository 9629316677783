/* import axios from 'axios';
import { NEXT_APP_API_URL } from '../config'; */
import {sendGraphQL} from './sendRequest'

const GET_EMAIL_PREFERENCE = 'GET_EMAIL_PREFERENCE';

export const GET_EMAIL_PREFERENCE_PENDING = `${GET_EMAIL_PREFERENCE}_PENDING`;
export const GET_EMAIL_PREFERENCE_FULFILLED = `${GET_EMAIL_PREFERENCE}_FULFILLED`;
export const GET_EMAIL_PREFERENCE_REJECTED = `${GET_EMAIL_PREFERENCE}_REJECTED`;

export function getEmailPreference(advertiser_id) {

    if (!advertiser_id) {
        return null;
    }

    const query = `
    query getEmailPreference($advertiser_id: String) {
        advertiserById(advertiser_id: $advertiser_id) {
                email_preference {
                    application_general
                    application_alerts
                    application_affiliate_messages
                    application_weekly_summary
                    newsletter_general
                    newsletter_marketing
                    newsletter_updates
                    email_unsubscribe
                }
            }
        }
    `

    const variables = {
        advertiser_id,
    };

    const payload = {
        query,
        variables,
    };

    return {
        type: GET_EMAIL_PREFERENCE,
        payload: sendGraphQL(payload)
            .then(response => response.data)
    }

    /* const headers = {
        "Content-Type": 'application/json'
    };

    return {
        type: GET_EMAIL_PREFERENCE,
        payload: axios.post(NEXT_APP_API_URL, payload, { headers })
            .then(response => response.data)
    } */

}