/* import axios from 'axios';
import { NEXT_APP_API_URL } from '../config'; */
import {sendGraphQL} from './sendRequest'

export const GET_AFFILIATE_INFO_PENDING = 'GET_AFFILIATE_INFO_PENDING';
export const GET_AFFILIATE_INFO_FULFILLED = 'GET_AFFILIATE_INFO_FULFILLED';
export const GET_AFFILIATE_INFO_REJECTED = 'GET_AFFILIATE_INFO_REJECTED';

export const searchAffiliatesInfo = (advertiser_id, term, filters = {}, options ) => {

    const query = `
        query searchAffiliates(
            $advertiser_id: String,
            $term: String!,
            $filters: AffiliateSearchFilterInput, 
            $options: AffiliateQueryOptions
        ) {
            searchAffiliates(advertiser_id: $advertiser_id, term: $term, filters: $filters, options: $options) {
                affiliates {
                    _id
                    cognito_id
                    name
                    email
                    photo
                    website
                    reach
                    invitedOffers
                    account {
                        bio
                        categories
                        signUpDate
                        audience_gender
                        audience_country
                        audience_age_range
                    }
                    tracking_id
                    tracking {
                        Stat {
                            revenue
                            epc
                            clicks
                            offer_id
                            conversions
                        }
                    }
                    youTubeData(scoreOnly: true) {
                        score
                    }
                    twitterData(scoreOnly: true) {
                        score
                    }
                    instagramData {
                        score
                    }
                    instagramDataV2 {
                        account_verified
                        account_type
                    }
                    facebookData(scoreOnly: true) {
                        score
                    }
                    tikTokData{
                        aqs
                        account_verified
                        account_type
                    }
                }
                pageInfo {
                    count
            }
        }
        }
    `

    let variables = {
        advertiser_id,
        term,
        filters: {
            channel: filters.socialFilter,
            gender: filters.selectedGender,
            ageRange: filters.selectedAgeRange,
            reach: filters.rangeValues,
            category: filters.selectedCategories
        },
        options
    }

    let payload = {
        query,
        variables
    };

    return sendGraphQL(payload)
        .then(response => response.data.data.searchAffiliates)

    /* let headers = {
        "Content-Type": 'application/json'
    };

    return axios.post(NEXT_APP_API_URL, payload, { headers })
        .then(response => response.data.data.searchAffiliates) */

};
