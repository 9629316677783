import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { orderBy } from 'lodash';
import {
    Thumbnail,
    Badge,
    TextField,
    Icon,
    Checkbox,
    Tooltip,
    Select,
    Spinner,
    SkeletonThumbnail,
    SkeletonBodyText,
    Banner
} from '@shopify/polaris';
import { SearchMinor } from '@shopify/polaris-icons';

import { LlamaPagination, EmptyState, LlamaButton } from 'llama-library/components';
import { moneyFormat, pluralize } from 'llama-library/utils';

import useResponsive from '../../hooks/use-responsive';

import './products-list.css';

/**
 * @readonly
 * @enum {String}
 *
 * Enum for options to sort products by.
 */
export const SortOptions = {
    NAME_ASC: 'NAME_ASC',
    NAME_DESC: 'NAME_DESC',
    NEWEST_DESC: 'NEWEST_DESC',
    VOLUME_DESC: 'VOLUME_DESC',
    SALES_DESC: 'SALES_DESC'
};

const sortOptions = [
    { label: 'Name A-Z', value: SortOptions.NAME_ASC },
    { label: 'Name Z-A', value: SortOptions.NAME_DESC },
    { label: 'Newest', value: SortOptions.NEWEST_DESC },
    { label: 'Revenue', value: SortOptions.VOLUME_DESC },
    { label: 'Quantity Sold', value: SortOptions.SALES_DESC }
];

const sortFilters = {
    [SortOptions.NAME_ASC]: { value: ['title'], order: ['asc'] },
    [SortOptions.NAME_DESC]: { value: ['title'], order: ['desc'] },
    [SortOptions.NEWEST_DESC]: { value: ['created_at'], order: ['desc'] },
    [SortOptions.VOLUME_DESC]: { value: ['reports.volume'], order: ['desc'] },
    [SortOptions.SALES_DESC]: { value: ['reports.quantity'], order: ['desc'] }
};

const mobileWidth = 850;

const SkeletonProductsList = ({ count }) => {
    const numRange = [];
    for (let i = 0; i < count; i++) {
        numRange.push(i);
    }
    return numRange.map((number) => {
        return (
            <tr key={number}>
                <td className="product">
                    <div className="product-wrap">
                        <SkeletonThumbnail size="small" />
                        <SkeletonBodyText lines={1} />
                    </div>
                </td>
                <td className="price"><SkeletonBodyText lines={1} /></td>
                <td className="qty"><SkeletonBodyText lines={1} /></td>
                <td className="revenue"><SkeletonBodyText lines={1} /></td>
                <td className="status"><SkeletonBodyText lines={1} /></td>
            </tr>
        );
    });
};

const ProductsTable = ({ isLoading, updating, initialProducts, products, handleStatusToggle, itemsPerPage }) => {
    const [selectedProducts, setSelectedProducts] = useState([]);

    const pageWidth = useResponsive();

    const toggleAllProductsSelection = (checked) => {
        if (checked) {
            const productIds = products.map((product) => {
                return product.product_id;
            });
            setSelectedProducts(productIds);
        } else {
            setSelectedProducts([]);
        }
    };

    const toggleProductSelection = (checked, productId) => {
        if (checked) {
            setSelectedProducts([
                ...selectedProducts,
                productId
            ]);
        } else {
            const newSelectedProducts = selectedProducts.filter((selectedProduct) => {
                return selectedProduct !== productId;
            });
            setSelectedProducts(newSelectedProducts);
        }
    };

    const allProductsSelected = () => {
        if (selectedProducts.length === 0) {
            return false;
        }
        if (selectedProducts.length === products.length) {
            return true;
        }
        return 'indeterminate';
    };

    if (!isLoading && initialProducts.length > 0 && products.length === 0) {
        return (
            <EmptyState
                message="Sorry, I couldn’t find those products"
                paragraph="No products matched your search term. Try adjusting your search filters."
            />
        );
    }

    return (
        <table className="products-table">
            <thead>
                {selectedProducts.length === 0
                    ? ( // normal table heading
                        <tr>
                            <th className="product">
                                <Checkbox
                                    label="Select all products"
                                    checked={allProductsSelected()}
                                    onChange={toggleAllProductsSelection}
                                    disabled={isLoading || updating}
                                />
                                {pageWidth > mobileWidth ? 'Product' : `Showing ${pluralize(products.length, 'product', 'products')}`}
                            </th>
                            <th className="price">Price</th>
                            <th className="qty">Qty Sold</th>
                            <th className="revenue">Revenue</th>
                            <th className="status">
                                Status
                                <Tooltip content="Inactive products will not be shown or included in any of your offers.">
                                    <div className="info-tooltip">i</div>
                                </Tooltip>
                            </th>
                        </tr>
                    )
                    : ( // table heading showing bulk actions
                        <tr>
                            <th className="product-actions" colSpan="5">
                                <div className="product-actions-wrapper">
                                    <Checkbox
                                        id="select-all"
                                        label={`${pluralize(selectedProducts.length, 'product', 'products')} selected`}
                                        checked={allProductsSelected()}
                                        onChange={toggleAllProductsSelection}
                                        disabled={isLoading || updating}
                                    />
                                    <p className="actions">
                                        <LlamaButton classes={['activate']} onClick={() => { return handleStatusToggle(selectedProducts, 'active'); }}>Activate</LlamaButton>
                                        <LlamaButton classes={['deactivate']} onClick={() => { return handleStatusToggle(selectedProducts, 'inactive'); }}>Deactivate</LlamaButton>
                                    </p>
                                </div>
                            </th>
                        </tr>
                    )
                }
            </thead>
            <tbody>
                {isLoading
                    ? <SkeletonProductsList count={itemsPerPage} />
                    : products.map((product) => {
                        const { product_id, status, title, prices, reports } = product;
                        let image = '';
                        if (product.image) {
                            image = product.image.src;
                        }
                        const isChecked = selectedProducts.includes(product_id);

                        return (
                            <tr key={product_id} onClick={() => { toggleProductSelection(!isChecked, product_id); }}>
                                <td className="product">
                                    <div className="product-wrap">
                                        <Checkbox
                                            label={`Select ${title}`}
                                            checked={isChecked}
                                            onChange={(checked) => { toggleProductSelection(checked, product_id); }}
                                        />
                                        <Thumbnail size="small" source={image} />
                                        <span className="product-name">{title}</span>
                                    </div>
                                </td>
                                <td className="price">
                                    {prices.lowPrice !== prices.highPrice
                                        ? <>{moneyFormat(prices.lowPrice, false)} &ndash; {moneyFormat(prices.highPrice, false)}</>
                                        : moneyFormat(prices.lowPrice, false)
                                    }
                                </td>
                                <td className="qty">{reports.quantity || 0}</td>
                                <td className="revenue">{moneyFormat(reports.volume / 100, false)}</td>
                                <td className="status">
                                    {/^active$/gi.test(status) // check status for ACTIVE or active
                                        ? <Badge status="Success">Active</Badge>
                                        : <Badge status="Attention">Inactive</Badge>
                                    }
                                </td>
                            </tr>
                        );
                    })
                }
            </tbody>
        </table>
    );
};

const ProductsList = (props) => {
    const {
        collections,
        selectedCollection,
        collectionsFilter,
        handleCollectionChange,
        handleStatusToggle,
        isLoading,
        updating,
        itemsPerPage,
        advertiser,
        handleProductSync,
        syncIsLoading
    } = props;

    const productSync = advertiser.new_product_status || 'active';

    const [initialProducts, setInitialProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [products, setProducts] = useState([]);
    const [currentPage, setCurrentPage] = useState();

    const [showInactive, setShowInactive] = useState(true);
    const [sortValue, setSortValue] = useState('NEWEST_DESC');
    const [searchTerm, setSearchTerm] = useState('');

    const collectionOptions = collections.map((item) => {
        return { value: item.collection_id, label: item.title };
    });
    collectionOptions.unshift({ value: '__NO_COLLECTION', label: 'All Collections' });

    const handlePageChange = ({ startIndex, endIndex, currentPage: newPage }, newProducts) => {
        window.scrollTo(0, 0);
        if (newPage) {
            setCurrentPage(newPage);
        }

        let paginatedProducts;
        if (newProducts) {
            paginatedProducts = newProducts.slice(startIndex, endIndex);
        } else {
            paginatedProducts = filteredProducts.slice(startIndex, endIndex);
        }
        setProducts(paginatedProducts);
    };

    // initial load, add price range if applicable, add undefined params
    useEffect(() => {
        if (props.products && Array.isArray(props.products) && props.products.length > 0) {
            let formattedProducts = props.products.map((product) => {
                // find the price range
                const prices = product.variants.reduce((acc, variant) => {
                    const price = parseFloat(variant.price);
                    if (price < acc.lowPrice) {
                        acc.lowPrice = price;
                    }
                    if (price > acc.highPrice) {
                        acc.highPrice = price;
                    }
                    return acc;
                }, { lowPrice: Infinity, highPrice: 0 });

                return {
                    ...product,
                    prices,
                    reports: {
                        quantity: product.reports.quantity || 0,
                        volume: product.reports.volume || 0
                    }
                };
            });

            // sort
            const sortBy = sortFilters[sortValue];
            formattedProducts = orderBy(formattedProducts, sortBy.value, sortBy.order);

            setFilteredProducts(formattedProducts);
            setInitialProducts(formattedProducts);
            handlePageChange({
                startIndex: 0,
                endIndex: itemsPerPage,
                currentPage: 1
            }, formattedProducts);
        }
    }, [props.products]);

    // search, filter, and paginate
    useEffect(() => {
        if (!isLoading) {
            let newProducts = JSON.parse(JSON.stringify(initialProducts));

            if (collectionsFilter) {
                newProducts = newProducts.filter((product) => {
                    return collectionsFilter.includes(product.product_id);
                });
            }

            if (!showInactive) {
                newProducts = newProducts.filter((product) => {
                    return /^active$/gi.test(product.status);
                });
            }

            if (searchTerm.trim().length > 0) {
                const lowercaseSearchValue = searchTerm.trim().toLowerCase();
                newProducts = newProducts.filter((product) => {
                    const lowercaseProductID = product.product_id.toLowerCase();
                    const lowercaseTitle = product.title.toLowerCase();
                    return lowercaseProductID.includes(lowercaseSearchValue) || lowercaseTitle.includes(lowercaseSearchValue);
                });
            }

            if (sortValue) {
                const sortBy = sortFilters[sortValue];
                newProducts = orderBy(newProducts, sortBy.value, sortBy.order);
            }

            setFilteredProducts(newProducts);
            handlePageChange({
                startIndex: 0,
                endIndex: itemsPerPage,
                currentPage: 1
            }, newProducts);
        }
    }, [searchTerm, showInactive, sortValue, collectionsFilter]);

    if (!isLoading && initialProducts.length === 0) {
        return (
            <EmptyState
                message="No products available"
                paragraph="I couldn’t find any products from your Shopify store. If you have products that should be here, please Sync Products."
                ctaText="Sync Products"
                ctaAction={handleProductSync}
            />
        );
    }

    return (
        <div className="products-list__products-wrapper" data-processing={updating} data-loading={isLoading} data-test="component-ProductsList">
            {productSync === 'active'
                ? <p className="intro">When you add new products to your Shopify store, they&rsquo;ll be <strong>automatically active</strong> for your new offers and offers with the All Products option. You can change this in your <Link to="/settings">Product Sync Settings</Link>.</p>
                : <p className="intro">When you add new products to your Shopify store, you’ll need to <strong>manually activate</strong> them here before they can be included in your offers. You can change this in your <Link to="/settings">Product Sync Settings</Link>.</p>
            }

            {updating
                && <Spinner accessibilityLabel="Saving Changes" size="large" color="teal" />
            }

            {/* products filters */}
            <div className="filters">
                <div className="search-and-sort">
                    <div className="search">
                        <TextField
                            value={searchTerm}
                            onChange={(value) => { setSearchTerm(value); }}
                            placeholder="Search products by name or ID"
                            prefix={<Icon color="skyDark" source={SearchMinor} />}
                        />
                        <Select
                            onChange={handleCollectionChange}
                            options={collectionOptions}
                            value={selectedCollection}
                        />
                    </div>
                    <div className="sort">
                        <p>Sort By:</p>
                        <Select
                            onChange={(value) => { setSortValue(value); }}
                            options={sortOptions}
                            value={sortValue}
                        />
                    </div>
                </div>
                <Checkbox
                    label="Show Inactive"
                    checked={showInactive}
                    onChange={(value) => { setShowInactive(value); }}
                />
            </div>

            <ProductsTable
                isLoading={isLoading}
                updating={updating}
                initialProducts={initialProducts}
                products={products}
                handleStatusToggle={handleStatusToggle}
                itemsPerPage={itemsPerPage}
            />

            <div className="product-list__pagination">
                <LlamaPagination
                    useVersion2
                    totalItems={filteredProducts.length}
                    itemsPerPage={itemsPerPage}
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                />
            </div>

            <div className="sync-wrapper">
                <Banner status="info">
                    <div className="sync">
                        <p className="sync-text">Missing something? Click <strong>Sync Products</strong> to re-import all your products from Shopify.</p>
                        <LlamaButton
                            onClick={handleProductSync}
                            loading={syncIsLoading}
                        >
                            Sync Products
                        </LlamaButton>
                    </div>
                </Banner>
            </div>
        </div>
    );
};

const productShape = {
    product_id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    image: PropTypes.shape({
        src: PropTypes.string
    }),
    reports: PropTypes.shape({
        quantity: PropTypes.number,
        volume: PropTypes.number
    })
};

ProductsTable.propTypes = {
    handleStatusToggle: PropTypes.func.isRequired,
    initialProducts: PropTypes.arrayOf(PropTypes.shape({
        ...productShape,
        prices: PropTypes.shape({
            lowPrice: PropTypes.number.isRequired,
            highPrice: PropTypes.number.isRequired
        }).isRequired
    })).isRequired,
    isLoading: PropTypes.bool.isRequired,
    itemsPerPage: PropTypes.number.isRequired,
    products: PropTypes.arrayOf(PropTypes.shape({
        ...productShape,
        prices: PropTypes.shape({
            lowPrice: PropTypes.number.isRequired,
            highPrice: PropTypes.number.isRequired
        }).isRequired
    })).isRequired,
    updating: PropTypes.bool.isRequired
};

ProductsList.propTypes = {
    advertiser: PropTypes.shape({
        new_product_status: PropTypes.string
    }).isRequired,
    collections: PropTypes.arrayOf(PropTypes.shape({
        collection_id: PropTypes.string,
        title: PropTypes.string
    })).isRequired,
    collectionsFilter: PropTypes.arrayOf(PropTypes.string),
    handleCollectionChange: PropTypes.func.isRequired,
    handleStatusToggle: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    itemsPerPage: PropTypes.number.isRequired,
    products: PropTypes.arrayOf(PropTypes.shape({
        ...productShape,
        variants: PropTypes.arrayOf(PropTypes.shape({
            price: PropTypes.string
        })).isRequired
    })).isRequired,
    selectedCollection: PropTypes.string.isRequired,
    updating: PropTypes.bool.isRequired,
    handleProductSync: PropTypes.func.isRequired,
    syncIsLoading: PropTypes.bool.isRequired
};

ProductsList.defaultProps = {
    collectionsFilter: null
};

export default ProductsList;
