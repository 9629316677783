import { useState, useEffect } from 'react';
import { isEqual } from 'lodash';
import { searchAffiliatesInfo } from '../actions/searchAffiliates';

const usePagesDiscover = (props, postsPerPage, currentActiveTab = false) => {
    const defaultPageOptions = {
        skip: props.skip,
        limit: postsPerPage
    };
    const [maxPages, setMaxPages] = useState(null);
    const [posts, setPosts] = useState([]);
    const [pageOptions, setPageOptions] = useState(defaultPageOptions);
    const [currentPage, setCurrentPage] = useState(props.initialPage);
    const [currentTab, setCurrentTab] = useState(currentActiveTab);
    const [isPaginationCalled, setIspaginationCalled] = useState(false);
    const [oldDiscoverFilters, setOldDiscoverFilters] = useState(props.discoverFilters);

    const makeAPICall = (advertiser_id, discoverFilters, pageOptions) => {
        props.setIsLoading(true);
        searchAffiliatesInfo(advertiser_id, discoverFilters.searchTerm, discoverFilters, pageOptions)
            .then((reports) => {
                const formattedAffiliates = reports.affiliates;
                props.setInitalLoad(false);
                props.setIsLoading(false);
                props.setAffiliates(formattedAffiliates);
                setPosts(formattedAffiliates);
                const pageCount = reports.pageInfo.count % postsPerPage === 0
                    ? (reports.pageInfo.count / postsPerPage) - 1
                    : Math.ceil(reports.pageInfo.count / postsPerPage);
                setMaxPages(pageCount);
            }).catch((error) => {
                console.log('makeAPICall Error', error);
            });
    };

    const newPageOptions = {
        skip: props.skip,
        limit: postsPerPage
    };

    if (!isEqual(pageOptions, newPageOptions)) {
        setPageOptions({ ...pageOptions, skip: props.skip });
        setCurrentPage(props.initialPage);
    }

    useEffect(() => {
        if (props.isInvitationSent
            || props.initialLoad
            || isPaginationCalled
            || ((props.isApplyDiscoverFilters) && !isEqual(oldDiscoverFilters, props.discoverFilters))
            && Array.isArray(props.discoverFilters.selectedCategories)
            && props.discoverFilters.selectedCategories.length > 0
        ) {
            props.setIsApplyDiscoverFilters(false);
            props.setIsInvitationSent(false);
            props.setInitalLoad(false);
            setIspaginationCalled(false);
            setOldDiscoverFilters({
                ...props.discoverFilters,
                selectedCategories: props.discoverFilters.selectedCategories
            });
            makeAPICall(props.advertiser_id, props.discoverFilters, pageOptions);
        } else {
            props.setIsApplyDiscoverFilters(false);
            setIspaginationCalled(false);
            props.setIsInvitationSent(false);
        }
    }, [props.isInvitationSent, props.initialLoad, props.isApplyDiscoverFilters, isPaginationCalled, props.discoverFilters, pageOptions])

    useEffect(() => {
        if (currentTab != currentActiveTab) {
            setCurrentPage(props.initialPage);
            setCurrentTab(currentActiveTab);
        }
    });

    let hasNext = true;
    let hasPrevious = true;

    const nextPage = () => {
        setIspaginationCalled(true);
        if (currentPage >= maxPages) {
            setCurrentPage(maxPages);
        } else {
            const skip = currentPage * postsPerPage;
            setCurrentPage(currentPage + 1);
            setPageOptions({ ...pageOptions, skip });
            props.setSkip(skip);
            props.setInitialPage(currentPage + 1);
        }
    };

    const previousPage = () => {
        setIspaginationCalled(true);
        if (currentPage <= 1) {
            setCurrentPage(1);
        } else {
            const skip = (currentPage - 2) * postsPerPage;
            setCurrentPage(currentPage - 1);
            setPageOptions({ ...pageOptions, skip });
            props.setSkip(skip);
            props.setInitialPage(currentPage - 1);
        }
    };

    if (currentPage <= 1) {
        hasPrevious = false;
    }

    if (currentPage >= maxPages) {
        hasNext = false;
    }

    return {
        previousPage,
        nextPage,
        currentPage,
        hasNext,
        hasPrevious,
        maxPages,
        posts
    };
};

export default usePagesDiscover;
