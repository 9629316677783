import React, { useState } from 'react';
import { Card, Select, SkeletonDisplayText, SkeletonBodyText, SkeletonThumbnail } from '@shopify/polaris';
import { LlamaPagination } from 'llama-library/components';

import formatMetrics from '../../utils/format-metrics';
import usePages from '../../hooks/use-pages'

export const checkValidInstagram = (instagramAccount) => {
    if (
        !instagramAccount
        || !instagramAccount.statistics
        || !instagramAccount.statistics.avgLikes
        || !instagramAccount.statistics.avgComments
        || !instagramAccount.posts
        || !instagramAccount.username
    ) {
        return false;
    }

    return true;
}

const filterDuplicateInstagramAccounts = (instagramAccounts) => {

    if (!instagramAccounts) {
        return null;
    }

    if (!Array.isArray(instagramAccounts)) {
        return [instagramAccounts]
    }

    let unqiueAccounts = [];
    return instagramAccounts.filter((item) => {
        if (unqiueAccounts.includes(item.username)) {
            return false;
        }

        unqiueAccounts.push(item.username);
        return true;
    })

}

const EmptyPost = () => {

    return (
        <div className="affiliate-tab__post">
            <div className="affiliate-tab__thumb-wrapper">
                <SkeletonThumbnail size="large" />
            </div>
            <div className="affiliate-tab__fbpost-info">
                <div className="affiliate-tab__post-wrapper">
                    <div className="affiliate-tab__description">
                        <SkeletonBodyText lines="2" />
                    </div>
                </div>
                <div className="affiliate-tab__info-row">
                    <div className="affiliate-tab__reach-row">
                        <div className="affiliate-tab__reach-num">
                            <SkeletonBodyText lines="1" />
                        </div>
                        <div className="affiliate-tab__reach-num">
                            <SkeletonBodyText lines="1" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const LoadingAffiliateDetail = (props) => {

    let emptyAccount = { posts: [0,1,2] };

    const {
        previousPage,
        nextPage,
        currentPage,
        hasNext,
        hasPrevious,
        maxPages,
        posts
    } = usePages(0, 5, emptyAccount.posts);

    const renderEmptyPost = (posts) => {
        if(posts) {
            return posts.map((_, index) => {
                return <EmptyPost key={index} />
            })
        }
    }

    return (
        <div className="affiliate-main">
            <div className="affiliate-main__tabs">
                <div className="affiliate-main__tab" >
                    <div style={{ width: '4rem', height: '2rem', position: 'relative', top: '0.5rem', margin: 'auto' }} >
                        <SkeletonBodyText lines="1" />
                    </div>
                </div>
                <div className="affiliate-main__tab" >
                    <div style={{ width: '4rem', height: '2rem', position: 'relative', top: '0.5rem', margin: 'auto' }} >    
                        <SkeletonBodyText lines="1" />
                    </div>
                </div>
                <div className="affiliate-main__tab" >
                    <div style={{ width: '4rem', height: '2rem', position: 'relative', top: '0.5rem', margin: 'auto' }} >
                        <SkeletonBodyText lines="1" />
                    </div>
                </div>
            </div>
            <div className="affiliate-loading">
                <Card.Section>
                    <div className="affiliate-tab__top">
                        <div className="affiliate-tab__title">
                            <span className="affiliate-tab__name">
                                <SkeletonDisplayText size="large" />
                            </span>
                            <span className="affiliate-tab__stat-label">
                                <SkeletonBodyText lines="1" />
                            </span>
                        </div>
                    </div>
                </Card.Section>
                <Card.Section>
                    <div className="affiliate-tab__row">
                        <div className="affiliate-tab__stats">
                            <div className="affiliate-tab__stat-item">
                                <h2 className="affiliate-tab__stat-num" style={{ marginBottom: '0.6rem' }}>
                                    <SkeletonDisplayText size="large" />
                                </h2>
                                <div className="affiliate-tab__stat-label">
                                    <SkeletonBodyText lines="1" />
                                </div>
                            </div>
                            <div className="affiliate-tab__stat-item">
                                <h2 className="affiliate-tab__stat-num" style={{ marginBottom: '0.6rem' }}>
                                    <SkeletonDisplayText size="large" />
                                </h2>
                                <div className="affiliate-tab__stat-label">
                                    <SkeletonBodyText lines="1" />
                                </div>
                            </div>
                            <div className="affiliate-tab__stat-item">
                                <h2 className="affiliate-tab__stat-num" style={{ marginBottom: '0.6rem' }}>
                                    <SkeletonDisplayText size="large" />
                                </h2>
                                <div className="affiliate-tab__stat-label">
                                    <SkeletonBodyText lines="1" />
                                </div>
                            </div>
                        </div>
                        {/* <div className="affiliate-tab__link">
                            <a href={instagramLink} className="affiliate-tab__link-button">
                                View on Instagram
                            </a>
                        </div> */}
                    </div>
                </Card.Section>
                <Card.Section>
                    <div className="affiliate-tab__posts">
                        {renderEmptyPost(posts)}
                    </div>
                    {maxPages > 0 &&
                    <div className="affiliate-tab__page-controls">
                        <LlamaPagination
                            hasNext={hasNext}
                            hasPrevious={hasPrevious}
                            onNext={nextPage}
                            onPrevious={previousPage}
                            currentPage={currentPage}
                            maxPages={maxPages} 
                        />
                    </div>
                    }
                </Card.Section>
            </div>
        </div>
    )

}

export default LoadingAffiliateDetail;