import React from 'react';
import { Select } from '@shopify/polaris';

import './select.css';

const LlamaSelect = ({ options = [], onChange = () => null, ...props }) => {

    return (
        <div className="LlamaSelect">
            <Select
                options={options}
                onChange={onChange}
                { ...props }
            />
        </div>
    )

}

export default LlamaSelect;