const formatAudience = (account) => {

    let { audience_age_range: audienceAgeRange, audience_gender: audienceGender } = account;

    if (!audienceAgeRange && !audienceGender) {
        return "All (18-65+)";
    }

    const gender = formatGender(account);
    const ageRange = formatAgeRange(account);

    return `${gender} (${ageRange})`;
}

const formatAgeRange = (account) => {

    let { audience_age_range: audienceAgeRange } = account;

    if (!audienceAgeRange) {
        return "18-65+";
    }

    if (!Array.isArray(audienceAgeRange)) {
        audienceAgeRange = [audienceAgeRange];
    }

    const ageRange = audienceAgeRange.reduce((acc, next) => {

        if (next === '65+') {
            acc.high = '65+';
            return acc;
        }

        const [low, high] = next.split('-');
        if (acc.low === null || parseInt(low) < parseInt(acc.low)) {
            acc.low = low;
        }

        if (
            acc.high !== '65+'
            && (acc.high === null  || parseInt(high) > parseInt(acc.high))
        ) {
            acc.high = high;
        }

        return acc;

    }, { low: null, high: null })

    if (ageRange.low === null) {
        ageRange.low = '18';
    }

    if (ageRange.high === null) {
        ageRange.high = '65+';
    }

    return `${ageRange.low}-${ageRange.high}`;

}

const formatGender = (account) => {

    const audienceGenderDisplay = {
        ALL: 'All',
        FEMALE: 'Female',
        MALE: 'Male'
    }

    let { audience_gender: audienceGender } = account;

    const gender = audienceGenderDisplay[audienceGender] 
        ? audienceGenderDisplay[audienceGender] 
        : audienceGenderDisplay.ALL;

    return gender;
}

export {
    formatAudience,
    formatAgeRange,
    formatGender
};