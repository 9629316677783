// const awsConfig =  {
//     "aws_project_region": "us-east-1",
//     "aws_cognito_identity_pool_id": "us-east-1:edaecf93-93c9-4652-ad39-bf07829b3003",
//     "aws_cognito_region": "us-east-1",
//     "aws_user_pools_id": "us-east-1_nvFCyBAhW",
//     "aws_user_pools_web_client_id": "45s78jj20c9nesjadsnuf20794"
// };

const awsConfig =  {
    "aws_project_region": process.env.REACT_APP_AWS_COGNITO_REGION,
    "aws_cognito_identity_pool_id": process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
    "aws_cognito_region": process.env.REACT_APP_AWS_COGNITO_REGION,
    "aws_user_pools_id": process.env.REACT_APP_AWS_USER_POOL_ID,
    "aws_user_pools_web_client_id": process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
    "storage": sessionStorage
};

export default awsConfig;