import React from 'react';
import { Tooltip } from '@shopify/polaris';

const formatOfferName = (offer) => {
    console.log(offer)
    return (
        <>
            {offer.name}&nbsp;
            {offer.offer_type === 'product_giveaway' && <><Tooltip content="This is a product giveaway offer"><span className="offer-product-giveaway-tag">Product Giveaway</span></Tooltip>&nbsp;</>}
            {offer.private_offer && <span className="offer-private-tag">Private</span>}
        </>
    );
};

export default formatOfferName;
