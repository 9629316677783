const NEXT_API = `https://${process.env.REACT_APP_ENV}.llama.app/1.120`;
const PAYPAL_IDENTITY_URL = process.env.REACT_APP_PAYPAL_IDENTITY_URL;
const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY;
const PUBLIC_AMBASSADOR_URL = process.env.REACT_APP_PUBLIC_AMBASSADOR_URL;

const LAMBDA_GENERAL = `llama-g50next-backend-${process.env.REACT_APP_ENV}-general`;
const LAMBDA_MERCHANT = `llama-g50next-backend-${process.env.REACT_APP_ENV}-merchant`;
const NEXT_APP_INSTALL_URL = `${NEXT_API}/general/next`;
const NEXT_APP_API_URL = `${NEXT_API}/general/graph`;
const NEXT_APP_CSV_PARSE = `${NEXT_API}/merchant/csvParser`;
const NEXT_APP_PAYPAL_AUTH_URL = `${NEXT_API}/merchant/getUrl`;
const NEXT_APP_PAYPAL_AUTH_TOKEN = `${NEXT_API}/merchant/createAuthToken`;
const NEXT_APP_COGNITO_VALIDATION = `${NEXT_API}/general/cognito-user`;
const NEXT_APP_PIXEL_URL = `${NEXT_API}/merchant/pixel`;
const NEXT_S3_DOWNLOAD_URL = `${NEXT_API}/general/downloadCreative`;
const NEXT_S3_DELETE_URL = `${NEXT_API}/general/deleteCreative`;
const INVITE_PAGE_URL = `${PUBLIC_AMBASSADOR_URL}/?shop=`;

const CHAT_SOCKET_URI = process.env.REACT_APP_CHAT_SOCKET_URI;

const PER_PAGE = {
    CHAT_MESSAGES: 20
};

export {
    LAMBDA_GENERAL,
    LAMBDA_MERCHANT,
    NEXT_API,
    NEXT_APP_INSTALL_URL,
    NEXT_APP_API_URL,
    NEXT_APP_CSV_PARSE,
    NEXT_APP_PAYPAL_AUTH_URL,
    NEXT_APP_PAYPAL_AUTH_TOKEN,
    NEXT_APP_COGNITO_VALIDATION,
    NEXT_APP_PIXEL_URL,
    NEXT_S3_DOWNLOAD_URL,
    NEXT_S3_DELETE_URL,
    PAYPAL_IDENTITY_URL,
    INVITE_PAGE_URL,
    STRIPE_KEY,
    PER_PAGE,
    CHAT_SOCKET_URI
};
