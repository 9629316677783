import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { SkeletonDisplayText, Select, Tooltip } from '@shopify/polaris';
import { EmptyState, PlaceholderPhoto, LlamaButton, LlamaPagination } from 'llama-library/components';
import { ResponsiveBar } from '@nivo/bar';

import { getLeftTicks, formatBottomTickValues } from '../../utils/graph-ticks';
import numberFormat, { moneyFormat } from '../../utils/number-format';

import SkeletonReport from './ambassador-performance-skeleton-report';

import './ambassador-performance-reports.css';

const graphTypeOptions = [
    { label: 'Revenue', value: 'revenue' },
    { label: 'Orders', value: 'orderCount' },
    { label: 'Avg. Order Revenue', value: 'avgOrderRevenue' }
];

const AmbassadorPerformanceGraph = ({ data, dataPoint }) => {
    const highestSalesValue = data.reduce((acc, next) => {
        if (next[dataPoint] > acc) {
            return next[dataPoint];
        }
        return acc;
    }, 0);

    const leftTickValues = getLeftTicks(5, highestSalesValue);

    const axisLeft = { tickValues: leftTickValues };
    if (dataPoint === 'revenue' || dataPoint === 'avgOrderRevenue') {
        axisLeft.format = (x) => {
            return moneyFormat(x, true);
        };
    }

    const bottomTickValues = formatBottomTickValues(data.map((item) => {
        return item.day;
    }));
    const axisBottom = {
        tickValues: bottomTickValues,
        format: (date) => {
            return moment(date).format('MM/DD');
        }
    };

    const leftMargin = 10 + (leftTickValues[leftTickValues.length - 1].toString().length * 10);

    return (
        <div className="bar-graph">
            <ResponsiveBar
                data={data}
                indexBy="day"
                keys={[dataPoint]}
                colors="#EB4159"
                enableLabel={false}
                margin={{
                    top: 10,
                    right: 10,
                    bottom: 30,
                    left: leftMargin
                }}
                padding={0.3}
                axisLeft={axisLeft}
                axisBottom={axisBottom}
                gridYValues={leftTickValues}
                tooltip={({ id, value, color, data: itemData }) => {
                    const formattedValue = (dataPoint === 'revenue' || dataPoint === 'avgOrderRevenue')
                        ? moneyFormat(value)
                        : numberFormat(value);
                    const date = moment(itemData.day).format('MMM D, YYYY');
                    return (
                        <div className="nivo-graph-tooltip-wrapper">
                            <span className="nivo-graph-tooltip-date">{date}</span>
                            <span className="nivo-graph-tooltip-item">
                                <span className="nivo-graph-tooltip-color-block" style={{ background: color }} />
                                {graphTypeOptions.find((option) => { return option.value === id; }).label}: <span style={{ fontWeight: 'bold' }}>{formattedValue}</span>
                            </span>
                        </div>
                    );
                }}
            />
        </div>
    );
};

const AmbassadorPerformanceReport = ({ report, history, startDate, endDate, selectedOfferName, sortBy }) => {
    const { summary, offers, ambassador } = report;

    let defaultGraphType = 'revenue';
    if (sortBy === 'totalOrders') {
        defaultGraphType = 'orderCount';
    }
    if (sortBy === 'avgRevenue') {
        defaultGraphType = 'avgOrderRevenue';
    }

    const [currentOffer, setCurrentOffer] = useState(summary);
    const [graphType, setGraphType] = useState(defaultGraphType);
    const [offersOpen, setOffersOpen] = useState(false);

    useEffect(() => {
        setOffersOpen(false);
    }, [currentOffer]);

    const divByZeroTernary = (numerator, denominator, isPercent = false) => {
        if (denominator > 0) {
            return (isPercent) ? Math.round((numerator / denominator) * 100) : Math.round(numerator / denominator);
        }
        return 0;
    };

    const renderHeading = () => {
        switch (graphType) {
            case 'orderCount':
                return <>Total Orders: {currentOffer.orderCount}</>;
            case 'avgOrderRevenue':
                return <>Average Order Revenue: {moneyFormat(currentOffer.avgOrderRevenue)}</>;
            case 'revenue':
            default:
                return <>Total Revenue: {moneyFormat(currentOffer.revenue)}</>;
        }
    };

    return (
        <li>
            <div className="ambassador-name-header">
                <p><PlaceholderPhoto photo={ambassador.photo} /></p>
                <p className="ambassador-name">
                    <span>{ambassador.name}</span>
                    {!selectedOfferName && <>Running {offers.length} offer{offers.length !== 1 && 's'}</>}
                </p>
                <LlamaButton onClick={() => { return history.push(`/affiliate/${ambassador._id}`); }}>View Profile</LlamaButton>
            </div>

            <div className="report">
                {!selectedOfferName
                    && (
                        <section className="offers">
                            <h4>Offers</h4>
                            <p className="current-offer">
                                <button type="button" onClick={() => { return setOffersOpen(!offersOpen); }}>Showing stats for: <strong>{currentOffer.name ? currentOffer.name : 'All Offers'}</strong></button>
                            </p>
                            <ul data-open={offersOpen}>
                                <li data-selected={!currentOffer.offer_id} onClick={() => { return setCurrentOffer(summary); }}>All Offers</li>
                                {offers.map((offer) => {
                                    return (
                                        <li
                                            key={offer.offer_id}
                                            onClick={() => { return setCurrentOffer(offer); }}
                                            data-selected={currentOffer.offer_id === offer.offer_id}
                                        >
                                            {offer.name}
                                        </li>
                                    );
                                })}
                            </ul>
                        </section>
                    )
                }
                {currentOffer.revenue === 0
                    ? (
                        <div className="stats">
                            <EmptyState message="Total Revenue: $0.00" paragraph="No orders were generated during this time period" />
                        </div>
                    )
                    : (
                        <section className="stats">
                            <div className="graph-heading">
                                <h2>{renderHeading()}</h2>
                                <p className="date-range">{numberFormat(currentOffer.orderCount)} order{currentOffer.orderCount !== 1 && 's'} from {startDate} &ndash; {endDate}</p>
                                <div className="graph-type">
                                    <Select
                                        label="Graph Type:"
                                        options={graphTypeOptions}
                                        onChange={setGraphType}
                                        value={graphType}
                                    />
                                </div>
                            </div>
                            <AmbassadorPerformanceGraph
                                data={currentOffer.ordersByDay}
                                dataPoint={graphType}
                                graphTypeOptions={graphTypeOptions}
                            />
                            <div className="breakdown">
                                <section>
                                    <h3>Referral Links</h3>
                                    <p>Includes initial and repeat orders generated directly from a referral link</p>
                                    <ul>
                                        <li>
                                            <span className="label">Link Clicks</span>
                                            {numberFormat(currentOffer.linkOrders.clicks)}
                                        </li>
                                        <li>
                                            <span className="label">Total Orders</span>
                                            {numberFormat(currentOffer.linkOrders.orderCount)}
                                        </li>
                                        <li>
                                            <span className="label">Avg. Order Revenue</span>
                                            {moneyFormat(divByZeroTernary(currentOffer.linkOrders.totalRevenue, currentOffer.linkOrders.orderCount))}
                                        </li>
                                        <li>
                                            <span className="label">Conversion Rate</span>
                                            {divByZeroTernary(currentOffer.linkOrders.orderCount, currentOffer.linkOrders.clicks, true)}%
                                        </li>
                                    </ul>
                                </section>
                                <section>
                                    <h3>Repeat Orders</h3>
                                    <p>Includes all subsequent orders from referred customers</p>
                                    <ul>
                                        <li>
                                            <span className="label">Customer Loyalty
                                                <Tooltip content="Customer loyalty represents the percentage of customers who made more than one purchase during this time period.">
                                                    <span className="info-tooltip">i</span>
                                                </Tooltip>
                                            </span>
                                            {divByZeroTernary(currentOffer.repeatOrders.customers, currentOffer.totalCustomers, true)}%
                                        </li>
                                        <li>
                                            <span className="label">Total Orders</span>
                                            {numberFormat(currentOffer.repeatOrders.orderCount)}
                                        </li>
                                        <li>
                                            <span className="label">Avg. Order Revenue</span>
                                            {moneyFormat(divByZeroTernary(currentOffer.repeatOrders.totalRevenue, currentOffer.repeatOrders.orderCount))}
                                        </li>
                                        <li>
                                            <span className="label">Total Revenue</span>
                                            {moneyFormat(currentOffer.repeatOrders.totalRevenue)}
                                        </li>
                                    </ul>
                                </section>
                                <section>
                                    <h3>Coupons</h3>
                                    <p>Includes all orders in which an ambassador&rsquo;s coupon code was used</p>
                                    <ul>
                                        <li>
                                            <span className="label">Total Discounts</span>
                                            {moneyFormat(currentOffer.discounts.totalDiscounts)}
                                        </li>
                                        <li>
                                            <span className="label">Total Orders</span>
                                            {numberFormat(currentOffer.discounts.orderCount)}
                                        </li>
                                        <li>
                                            <span className="label">Avg. Order Revenue</span>
                                            {moneyFormat(divByZeroTernary(currentOffer.discounts.totalRevenue, currentOffer.discounts.orderCount))}
                                        </li>
                                        <li>
                                            <span className="label">Total Revenue</span>
                                            {moneyFormat(currentOffer.discounts.totalRevenue)}
                                        </li>
                                    </ul>
                                </section>
                            </div>
                        </section>
                    )
                }
            </div>
        </li>
    );
};

const AmbassadorPerformanceReports = (props) => {
    const {
        errorMessage, isLoading, history, pagination,
        searchValue, startDate, endDate, defaultStartDate, defaultEndDate, selectedOfferName, sortBy,
        ambassadors
    } = props;

    if (errorMessage) {
        return (
            <section className="PerformanceMain" data-test="component-ambassadorPerformanceMain">
                <EmptyState {...errorMessage} />
            </section>
        );
    }

    if (isLoading) {
        return (
            <section className="PerformanceMain" data-test="component-PerformanceMainLoading">
                <div className="PerformanceMain__Header">
                    <SkeletonDisplayText />
                </div>
                <ul id="performance-reports">
                    {[1, 2, 3].map((index) => {
                        return <SkeletonReport key={index} selectedOfferName={selectedOfferName} />;
                    })}
                </ul>
            </section>
        );
    }

    if (ambassadors.length === 0) {
        return (
            <div className="PerformanceMain" data-test="component-PerformanceMainEmpty">
                {searchValue === '' && !selectedOfferName && startDate === defaultStartDate && endDate === defaultEndDate
                    ? (
                        <EmptyState
                            message="No new orders have been generated by your ambassadors"
                            paragraph="I can help you find some better ambassadors that align with your brand goals."
                            ctaText="Discover Ambassadors"
                            ctaAction={() => { return history.push('/discover'); }}
                        />
                    )
                    : (
                        <EmptyState
                            message="Sorry, I couldn’t find any orders matching your filters"
                            paragraph="Try choosing a different time period or broadening your search filters."
                        />
                    )
                }
            </div>
        );
    }

    return (
        <section className="PerformanceMain" data-test="component-ambassadorPerformanceMain">
            <p className="PerformanceMain__Header">
                You had orders from <span>{pagination.count}</span> active ambassador{pagination.count !== 1 && 's'}
                {searchValue && <> matching <span>{searchValue}</span></>}
                {selectedOfferName && <> running <span>{selectedOfferName}</span></>}
            </p>

            <ul id="performance-reports">
                {
                    ambassadors.map((report) => {
                        return (
                            <AmbassadorPerformanceReport
                                key={report.ambassador._id}
                                report={report}
                                history={history}
                                startDate={startDate}
                                endDate={endDate}
                                selectedOfferName={selectedOfferName}
                                sortBy={sortBy}
                            />
                        );
                    })
                }
            </ul>
            {pagination.numPages > 1
                && (
                    <LlamaPagination
                        hasNext={pagination.curPage !== pagination.numPages}
                        hasPrevious={pagination.curPage !== 1}
                        onNext={() => { return pagination.changePage(pagination.curPage + 1); }}
                        onPrevious={() => { return pagination.changePage(pagination.curPage - 1); }}
                        currentPage={pagination.curPage - 1}
                        maxPages={pagination.numPages - 1}
                    />
                )
            }
        </section>
    );
};

const ordersByDay = PropTypes.arrayOf(PropTypes.shape({
    avgOrderRevenue: PropTypes.number.isRequired,
    day: PropTypes.string.isRequired,
    orderCount: PropTypes.number.isRequired,
    revenue: PropTypes.number.isRequired
}));

const offerInfo = {
    avgOrderRevenue: PropTypes.number.isRequired,
    discounts: PropTypes.shape({
        orderCount: PropTypes.number.isRequired,
        totalDiscounts: PropTypes.number,
        totalRevenue: PropTypes.number.isRequired
    }).isRequired,
    linkOrders: PropTypes.shape({
        clicks: PropTypes.number,
        orderCount: PropTypes.number.isRequired,
        totalRevenue: PropTypes.number.isRequired
    }).isRequired,
    orderCount: PropTypes.number.isRequired,
    ordersByDay: ordersByDay.isRequired,
    repeatOrders: PropTypes.shape({
        customers: PropTypes.number.isRequired,
        totalCustomers: PropTypes.number,
        orderCount: PropTypes.number.isRequired,
        totalRevenue: PropTypes.number.isRequired
    }).isRequired,
    revenue: PropTypes.number.isRequired
};

const reportPropTypes = PropTypes.shape({
    ambassador: PropTypes.shape({
        photo: PropTypes.string,
        name: PropTypes.string.isRequired,
        _id: PropTypes.string.isRequired
    }).isRequired,
    offers: PropTypes.arrayOf(PropTypes.shape({
        ...offerInfo,
        name: PropTypes.string.isRequired,
        offer_id: PropTypes.string.isRequired
    })).isRequired,
    summary: PropTypes.shape({
        ...offerInfo
    }).isRequired
});

AmbassadorPerformanceGraph.propTypes = {
    data: ordersByDay.isRequired,
    dataPoint: PropTypes.string.isRequired
};

AmbassadorPerformanceReport.propTypes = {
    endDate: PropTypes.string.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired,
    selectedOfferName: PropTypes.string,
    sortBy: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
    report: reportPropTypes.isRequired
};
AmbassadorPerformanceReport.defaultProps = {
    selectedOfferName: ''
};

AmbassadorPerformanceReports.propTypes = {
    ambassadors: PropTypes.arrayOf(PropTypes.shape({
        reportPropTypes
    })).isRequired,
    endDate: PropTypes.string.isRequired,
    errorMessage: PropTypes.shape({
        message: PropTypes.string,
        paragraph: PropTypes.string
    }),
    defaultStartDate: PropTypes.string.isRequired,
    defaultEndDate: PropTypes.string.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired,
    isLoading: PropTypes.bool.isRequired,
    pagination: PropTypes.shape({
        curPage: PropTypes.number.isRequired,
        numPages: PropTypes.number.isRequired,
        changePage: PropTypes.func.isRequired,
        count: PropTypes.number.isRequired
    }).isRequired,
    searchValue: PropTypes.string,
    selectedOfferName: PropTypes.string,
    sortBy: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired
};
AmbassadorPerformanceReports.defaultProps = {
    errorMessage: null,
    searchValue: '',
    selectedOfferName: ''
};

export default AmbassadorPerformanceReports;
