import axios from 'axios';

import { NEXT_APP_COGNITO_VALIDATION } from '../config';

export const USER_VALIDATION_PENDING = 'USER_VALIDATION_PENDING';
export const USER_VALIDATION_FULFILLED = 'USER_VALIDATION_FULFILLED';
export const USER_VALIDATION_REJECTED = 'USER_VALIDATION_REJECTED';

export const cognitoUserValidation = (token) => {

    let headers = {
        "Content-Type": 'application/json'
    }

    let payload = {
        "AccessToken": token
    }
    
    return {
        type: 'USER_VALIDATION',
        payload: axios.post(NEXT_APP_COGNITO_VALIDATION, payload, { headers })
            .then(response => response.data)
        }


}
