import { sendGraphQL } from './sendRequest';

export const getBoostedOfferNames = (advertiser_id) => {
    console.log(advertiser_id);
    const query = `
        query getBoostedOffers($advertiser_id: String!) {
            offers(advertiser_id: $advertiser_id) {
                name
                sponsored
                offer_type
                private_offer
            }
        }
    `;

    const variables = {
        advertiser_id
    };

    const payload = {
        query,
        variables
    };

    return sendGraphQL(payload)
        .then((response) => { return response.data; });
};

const getBoostedOffers = (advertiser_id) => {
    console.log(advertiser_id);
    const query = `
        query getBoostedOffers($advertiser_id: String!) {
            offers(advertiser_id: $advertiser_id) {
                offer_id
                name
                bannerImage
                private_offer
                offer_type
                rate {
                    type
                    amount
                }
                longterm_rate {
                    type
                    amount
                }
                sponsored
                offerBoostHistory{
                    current_bid
                    charges{
                        date
                        amount
                    }
                    impressions{
                        date
                        amount
                    }
                    uniqueImpressions{
                        date
                        amount
                    }
                    uniqueImpressionsPerDay{
                        date
                        amount
                    }
                    clicks{
                        date
                        amount
                    }
                    applicants{
                        date
                        amount
                    }
                }
            }
        }
    `;

    const variables = {
        advertiser_id
    };

    const payload = {
        query,
        variables
    };

    return sendGraphQL(payload)
        .then((response) => { return response.data; });
};

export const getBoostHistory = (offer_id) => {
    const query = `
        query getOfferBoostHistory($offer_id: String!){
            offerBoostHistory(offer_id: $offer_id){
                current_bid
                charges{
                    date
                    amount
                }
                impressions{
                    date
                    amount
                }
                uniqueImpressions{
                    date
                    amount
                }
                uniqueImpressionsPerDay{
                    date
                    amount
                }
                clicks{
                    date
                    amount
                }
                applicants{
                    date
                    amount
                }
            }
        }
    `;

    const variables = {
        offer_id
    };

    const payload = {
        query,
        variables
    };

    return sendGraphQL(payload)
        .then((response) => { return response.data; });
};

export const updateBid = (offer_id, amount, advertiser_id) => {
    const query = `
        mutation updateOfferBoostBid($offer_id: String!, $amount: Float!, $advertiser_id: String!){
            updateOfferBoostBid(offer_id: $offer_id, amount: $amount, advertiser_id: $advertiser_id){
                current_bid
            }
        }
    `;

    const variables = {
        offer_id,
        amount,
        advertiser_id
    };

    const payload = {
        query,
        variables
    };

    return sendGraphQL(payload)
        .then((response) => { return response.data; });
};

export const changeBoostStatus = (offer_id, status) => {
    const query = `
        mutation updateOfferBoostStatus($offer_id: String!, $status: OfferBoostStatus!){
            updateOfferBoostStatus(offer_id: $offer_id, status: $status){
                success
            }
        }
    `;

    const variables = {
        offer_id,
        status
    };

    const payload = {
        query,
        variables
    };

    return sendGraphQL(payload)
        .then((response) => { return response.data; });
};

export default getBoostedOffers;
