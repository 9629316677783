import { combineReducers } from 'redux';
import createOfferReducer from './create-offer.reducer';
import offersReducer from './offers.reducer';
import advertiserReducer from './advertiser.reducer';
import loadingReducer from './loading.reducer';
import headerReducer from './header.reducer';
import appReducer from './app.reducer';
import authDataReduer from './auth.reducer';
import filtersReducer from './reducer_discoverFilters';
import promotionsReducer from './promotionsReducer';
import featuredAmbassadorsReducer from './feat-ambassadors.reducer';
import productsReducer from './products.reducer';
import chatReducer from './chat.reducer';

const rootReducer = combineReducers({
    activeOffer: createOfferReducer,
    offers: offersReducer,
    advertiser: advertiserReducer,
    loading: loadingReducer,
    header: headerReducer,
    app: appReducer,
    authData: authDataReduer,
    filtersReducer,
    promotionsData: promotionsReducer,
    featuredAmbassadors: featuredAmbassadorsReducer,
    products: productsReducer,
    chat: chatReducer
});

export default rootReducer;
