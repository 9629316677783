const findTopTick = (value) => {

    if (value < 20) {
        return value;
    }

    if (value < 150) {
        return Math.ceil(value / 10) * 10;
    }

    if (value < 1500) {
        return Math.ceil(value / 100) * 100;
    }

    if (value < 7500) {
        return Math.ceil(value / 500) * 500;
    }

    if (value < 15000) {
        return Math.ceil(value / 1000) * 1000;
    }

    if (value < 150000) {
        return Math.ceil(value / 10000) * 10000;
    }

    if (value < 1500000) {
        return Math.ceil(value / 100000) * 100000;
    }

    return Math.ceil(value / 100000) * 100000;
};

export const getLeftTicks = (ticks, highestValue) => {
    const topTick = findTopTick(highestValue);
    const tickDivisor = ticks - 1;
    const leftTickValues = [];
    let numTicks = ticks;
    if (numTicks > highestValue) {
        numTicks = highestValue;
    }
    if (numTicks === 0) {
        numTicks = 1;
    }
    for (let i = 0; i < numTicks; i++) {
        if (i === 0) {
            leftTickValues.push(0);
        } else if (i === numTicks - 1) {
            leftTickValues.push(topTick);
        } else {
            leftTickValues.push(Math.round((topTick / tickDivisor) * i));
        }
    }

    return leftTickValues;
}

export const formatBottomTickValues = (ticks, totalTicks = 4, maxFullLength = 10) => {

    if (!Array.isArray(ticks)) {
        return [];
    }

    if (ticks.length < maxFullLength) {
        return ticks;
    }

    const skipAxis = Math.round(parseInt((ticks.length) / (totalTicks - 1), 10));
    const lastIndex = ticks.length - 1;
    const tickValues = [];
    tickValues.push(ticks[0]);
    for (let i = 1; i < (totalTicks - 1); i++) {
        tickValues.push(ticks[(skipAxis * i)]);
    }
    tickValues.push(ticks[lastIndex]);

    return tickValues;
};

export default findTopTick;
