import React from 'react';
import axios from 'axios';
import InitialPreloader from '../components/initial-preloader/initial-preloader';
import passwordGenerator from 'generate-password';

import { I18n, ConsoleLogger as Logger } from '@aws-amplify/core';
import { Auth } from 'aws-amplify';
import { PasswordRequirements, PasswordMatch } from 'llama-library/components';
import {
    Authenticator,
    Greetings,
    SignIn,
    ConfirmSignIn,
    RequireNewPassword,
    SignUp,
    ConfirmSignUp,
    VerifyContact,
    ForgotPassword,
    TOTPSetup,
    AuthPiece,
    FormSection,
    SectionHeader,
    SectionBody,
    FormRow,
    InputRow,
    Label,
    SectionFooter,
    SectionFooterContent,
    Button,
    Link
 } from 'aws-amplify-react';

import { LlamaButton } from 'llama-library/components';
import CenteredSpinner from '../components/llama/centered-spinner';
import countryDialCodes from './lib/country-dial-codes.js';
import { NEXT_API, NEXT_APP_INSTALL_URL } from '../config';

import llamaHead from '../assets/llama-head-01.png';
import loginGraphic from '../assets/merchant-login-graphic.svg';

const validateToken = (token) => {

    const url = `${NEXT_API}/merchant/validate-token?token=${token}`

    return axios.get(url)
        .then((result) => {
            if (result.status === 200) {
                return result.data;
            }

            return false;
        });

}

class LlamaSignUp extends AuthPiece {

    signUpFields = [
        {
            required: true,
            label: 'Shopify Domain',
            placeholder: 'myshop',
            type: 'text',
            key: 'myshopify_domain',
            autocomplete: 'off',
            custom: true,
        }, {
            required: true,
            label: 'Email',
            placeholder: 'Email',
            type: 'text',
            key: 'username',
            autocomplete: 'off'
        }, {
            required: true,
            label: 'Password',
            placeholder: 'Password',
            type: 'password',
            key: 'password',
            autocomplete: 'off'
        }, {
            required: true,
            label: 'Confirm Password',
            placeholder: 'Confirm Password',
            type: 'password',
            key: 'confirm_password',
            autocomplete: 'off'
        }, {
            required: true,
            label: 'Email',
            placeholder: 'Email',
            type: 'email',
            key: 'email',
            autocomplete: 'off',
            hidden: true,
        }, {
            required: true,
            label: 'Advertiser ID',
            placeholder: '123',
            type: 'text',
            key: 'advertiser_id',
            autocomplete: 'off',
            hidden: true,
            custom: true,
        }, {
            required: true,
            label: 'Phone Number',
            placeholder: 'Phone Number',
            type: 'tel',
            key: 'phone',
            autocomplete: 'off',
            custom: true,
        }
    ]

    constructor(props) {
        super(props);

        const initalValues = this.signUpFields.reduce((acc, item) => {
            acc[item.key] = "";
            return acc;
        }, {})

        const propsData = Object.entries(props.data).reduce((acc, [name, value]) => {
            if (value === null) {
                acc[name] = ''
            } else {
                acc[name] = value
            }

            return acc;
        }, {})

        this.state = {
            showPasswordRequirements: true,
            values: { ...initalValues, ...propsData },
            dataSet: false,
            signInLoading: false
        }

        this._validAuthStates = ['signUp', 'signedUp'];

        this.header = 'Create a new merchant account';

        this.signUp = this.signUp.bind(this);
        this.onChange = this.onChange.bind(this);
        this.installApp = this.installApp.bind(this);
        this.processAutoLogin = this.processAutoLogin.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
    }

    componentDidMount() {
        window.addEventListener('keydown', this.onKeyDown);
        if (this.props.params.auto && this.props.params.auto === 'true' && this.props.params.advertiser_id) {
            this.processAutoLogin();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.onKeyDown);
    }

    onKeyDown(e) {
        if (e.keyCode !== 13) {
            return;
        }

        if (this.props.authState === 'signUp') {
            this.installApp();
        }
    }

    componentDidUpdate() {
        if (this.props.authState === 'signedUp' && !this.state.signInLoading) {
            this.signIn();
        }
    }

    getDefaultDialCode() {
        return this.props.signUpConfig
            && this.props.signUpConfig.defaultCountryCode
            && countryDialCodes.includes(`+${this.props.signUpConfig.defaultCountryCode}`)
            ? `+${this.props.signUpConfig.defaultCountryCode}`
            : '+1'
    }

    needPrefix(key) {
        const field = this.signUpFields.find(e => e.key === key);
        if (key.indexOf('custom:') !== 0) {
          return field.custom;
        } else if (key.indexOf('custom:') === 0 && field.custom === false) {
            console.warn('Custom prefix prepended to key but custom field flag is set to false; retaining manually entered prefix');
        }
        return null;
    }

    installApp() {
        let { myshopify_domain } = this.state.values;

        if(myshopify_domain !== undefined && myshopify_domain !== ''){
            myshopify_domain = myshopify_domain.replace(/\.myshopify\.com(\/.+)?$/, '').replace(/^https?:\/\//, '');

            // Test is domain supplied is a valid myshopify domain.
            const domainRegex = new RegExp(/\./);
            if (domainRegex.test(myshopify_domain)) {
                this.error('Oops, looks like you entered your custom domain. Please enter your myshopify domain.')
                return;
            }
            const badChars = new RegExp(/[^\w\-]/);
            if (badChars.test(myshopify_domain)) {
                this.error('Not a valid myshopify domain')
                return;
            }
            
            if (this.props.params.promo) {
                window.location.href = `${NEXT_APP_INSTALL_URL}?shop=${myshopify_domain + '.myshopify.com'}&create_user=true&promo=${this.props.params.promo}`;
                return;
            }
            window.location.href = `${NEXT_APP_INSTALL_URL}?shop=${myshopify_domain + '.myshopify.com'}&create_user=true`;
        }else{
            return this.error('Shopify domain is required.');
        }
    }

    validate() {

        // Copied from SignUp Amplify Component.
        const invalids = [];
        let invalidFormats = false;

        const validateKeys = {
            default: (el) => {
                if (el.required && !this.inputs[el.key]) {
                    el.invalid = true;
                    invalids.push(el.label);
                } else {
                    el.invalid = false;
                }
            },
            phone: (el) => {
                if (el.required && (!this.inputs.dial_code || !this.inputs.phone)) {
                    el.invalid = true;
                    invalids.push(el.label);
                } else {
                    el.invalid = false;
                }
            },
            'confirm_password': (el) => {
                if (el.required && !this.inputs[el.key]) {
                    el.invalid = true;
                    invalids.push(el.label);
                    return;
                }

                if (this.inputs[el.key] !== this.inputs['password']) {
                    this.error('Confirm Password does not match Password.  Please reenter and submit again.')
                    el.invalid = true;
                    invalidFormats = true;
                    return;
                }

                el.invlaid = false;
            }
        }

        this.signUpFields.map((el) => {
            if(el.key !== 'username'){ //since email and username are the same field, we don't need to run validation on both
                const validationCheck = validateKeys[el.key] ? validateKeys[el.key] : validateKeys['default'];
                const valid = validationCheck(el);
            }
        });
        return invalidFormats || invalids;
    }

    togglePasswordRequirements = () => {
        this.setState({ showPasswordRequirements: !this.state.showPasswordRequirements });
    }

    async checkContact(user) {
        if (!Auth || typeof Auth.verifiedContact !== 'function') {
            throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
        }
        await Auth.verifiedContact(user)
            .then(data => {
                if(data.verified || !data.verified === ""){
                    user = Object.assign(user, data);
                }
                this.changeState('signedIn', user);
            });
    }

    async signIn() {
        const { username, password } = this.state.values;

        if (!username || !password) {
            return null;
        }

        if (!Auth || typeof Auth.signIn !== 'function') {
            throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
        }
        this.setState({ signInLoading: true })
        try {
            const user = await Auth.signIn(username, password);
            if (user.challengeName === 'SMS_MFA' || user.challengeName === 'SOFTWARE_TOKEN_MFA') {
                this.changeState('confirmSignIn', user);
            } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                this.changeState('requireNewPassword', user);
            } else if (user.challengeName === 'MFA_SETUP') {
                this.changeState('TOTPSetup', user);
            } else {
                this.checkContact(user);
            }
        } catch (err) {
            if (err.code === 'UserNotConfirmedException') {
                this.changeState('confirmSignUp', { username });
            } else if (err.code === 'PasswordResetRequiredException') {
                this.changeState('forgotPassword', { username });
            } else {
                this.error(err);
            }
        } finally {
            this.setState({ signInLoading: false })
        }
    }

    signUp() {
        Object.entries(this.props.data).forEach(([name, value]) => {
            if (!value) return;
            if (!this.inputs[name] || this.inputs[name] === "") {
                this.inputs[name] = value;
            }
        });
        this.inputs.email = this.inputs.username;

        // Add dial code if not present.
        if (!this.inputs.dial_code) {
            this.inputs.dial_code = this.getDefaultDialCode();
        }

        // Validate inputs.
        const invalidFields = this.validate();
        if (Array.isArray(invalidFields) && invalidFields.length > 0) {
            return this.error(`The following fields are required: ${invalidFields.join(', ')}`);
        }
        if ((Array.isArray(invalidFields) && invalidFields.length > 0) || invalidFields === true) { return null; }

        // Validate Auth is present.
        if (!Auth || typeof Auth.signUp !== 'function') {
            throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
        }

        // Construct signup payload.
        let signup_info = {
            username: this.inputs.username.toLowerCase(),
            password: this.inputs.password,
            attributes: {}
        };

        const inputKeys = Object.keys(this.inputs);
        const inputVals = Object.values(this.inputs);

        inputKeys.forEach((key, index) => {
            if (!['username', 'password', 'checkedValue', 'dial_code'].includes(key)) {
              if (key !== 'phone' && key !== 'dial_code' && key !== 'error' && key !== 'confirm_password') {
                    const newKey = `${this.needPrefix(key) ? 'custom:' : ''}${key}`;
                    signup_info.attributes[newKey] = inputVals[index];
                } else if (inputVals[index]) {
                    signup_info.attributes['phone_number'] = `${this.inputs.dial_code}${this.inputs.phone.replace(/[-()]/g, '')}`
                }
            }
        });

        validateToken(this.props.params.token)
            .then(({ valid_token }) => {
                
                if (!valid_token) {
                    return Promise.reject(`Unauthorized account creation for ${this.inputs.myshopify_domain}.`)
                }

                return Auth.signUp(signup_info)

            })
            .then((data) => {
                console.log(data);
                this.changeState('confirmSignUp', data.user.username)
            })
            .catch(err => {
                if(err.code === "InvalidParameterException" && err.message.indexOf('username') !== -1){
                    this.error('Invalid email address format')
                }else{
                    this.error(err);
                }
            });

    }

    processAutoLogin() {
        // Validate Auth is present.
        if (!Auth || typeof Auth.signUp !== 'function') {
            console.log('No Auth module found');
            throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
        }

        const randomPassword = passwordGenerator.generate({
            length: 10,
            numbers: true,
            lowercase: true,
            uppercase: true,
            excludeSimilarCharacters: true,
            symbols: true,
            strict: true,
        });

        // Construct signup payload.
        let signup_info = {
            username: this.props.params.shop,
            password: randomPassword,
            attributes: {},
        };

        // Set the username and password into the state so that they can use it in signin function.
        const newValues = { ...this.state.values };
        newValues['username'] = this.props.params.shop;
        newValues['password'] = randomPassword;
        this.setState({ values: newValues });

        signup_info.attributes['email'] = this.props.params.email;
        signup_info.attributes['custom:myshopify_domain'] = this.props.params.shop;
        signup_info.attributes['custom:advertiser_id'] = this.props.params.advertiser_id;
        signup_info.attributes['custom:user_token'] = randomPassword;

        validateToken(this.props.params.token)
            .then(({ valid_token }) => {
                if (!valid_token) {
                    console.log('Invalid Token');
                    return Promise.reject(`Unauthorized account creation for ${this.props.params.shop}.`)
                }

                console.log('Processing auth signup...');
                return Auth.signUp(signup_info)

            })
            .then((data) => {
                console.log('auth signup response', data);
                // Send email having password for LLAMA account
                // change state to signedUp for auto login
                this.changeState('signedUp');
            })
            .catch(err => {
                if(err.code === "InvalidParameterException" && err.message.indexOf('username') !== -1){
                    this.error('Invalid email address format')
                }else{
                    this.error(err);
                }
            });
    }

    onChange(event) {
        const { name, value, type, checked } = event.target;
        const newValues = { ...this.state.values };
        newValues[name] = value;

        this.setState({ values: newValues });
        this.handleInputChange(event)
    }

    showComponent(theme) {
        if(this.props.params.auto && this.props.params.auto === 'true') {
            return <InitialPreloader />
        }

        if (this.state.signInLoading) {
            return <div className="amplify-loading-wrapper">
                <CenteredSpinner />
            </div>
        }

        const applyButtonClass = ['amplify-button'];

        if (!this.props.params.token) {
            return (
                <div className="signin-amplify__wrapper">
                    <div className="signin-amplify__form-wrapper signin-amplify__form-wrapper--signup">
                        <div className="signin-amplify__signin-form">
                            <div className="signin-amplify__mobile-wrapper">
                                <img src={llamaHead} alt="Leo the Llama" className="signin-amplify__llama-head" />
                                <div className="signin-amplify__mobile-text">
                                    <h1 className="signin-amplify__header">Llama Merchant Sign Up</h1>
                                    <p className="signin-amplify__subheader">Create a new merchant account.</p>
                                </div>
                            </div>
                            <SectionBody theme={theme}>
                                {this.signUpFields
                                .filter((item) => item.key === 'myshopify_domain')
                                .map((field) => {

                                    if (field.hidden) {
                                        return null;
                                    }

                                    return (
                                        <div className="amplify-form-item" key={field.key}>
                                            <div className="amplify-label-wrapper">
                                            {
                                                field.required ? 
                                                <Label theme={theme}>{field.label}*</Label> :
                                                <Label theme={theme}>{field.label}</Label>
                                            }
                                            </div>

                                            <div className="amplify-input-wrapper" data-field={field.key}>
                                                <InputRow
                                                    placeholder={field.placeholder}
                                                    theme={theme}
                                                    type={field.type}
                                                    name={field.key}
                                                    key={field.key}
                                                    onChange={this.onChange}
                                                    value={this.state.values[field.key]}
                                                    data-lpignore="true"
                                                />
                                                {field.key == 'myshopify_domain' &&
                                                    <span className="suffix">.myshopify.com</span>
                                                }
                                            </div>
                                        </div>
                                    )
                                })}
                            </SectionBody>
                            <div className="amplify__custom-footer">
                                <div className="amplify__button-wrapper">
                                    <LlamaButton 
                                        background="rgb(253, 42, 84)"
                                        classes={applyButtonClass}
                                        theme={theme} 
                                        onClick={this.installApp}
                                        disabled={this.state.isLoading}>
                                        Create account
                                    </LlamaButton>
                                </div>
                                <div className="amplify-section-footer_links">
                                    {'Have an account? '}
                                    <div className="amplify__custom-link">
                                        <Link theme={theme} onClick={() => this.changeState('signIn')}>
                                            {'Sign in'}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="signup-amplify__graphic-wrapper">
                        <img alt="Leo the Llama Ambassador and his network" src={loginGraphic} className="signin-amplify__login-graphic signin-amplify__login-graphic--signup" />
                    </div>
                </div>  
            )
        }

        return (
            <div className="signin-amplify__wrapper">
                <div className="signin-amplify__form-wrapper signin-amplify__form-wrapper--signup">
                    <div className="signin-amplify__signin-form">
                        <div className="signin-amplify__mobile-wrapper">
                            <img src={llamaHead} alt="Leo the Llama" className="signin-amplify__llama-head" />
                            <div className="signin-amplify__mobile-text">
                                <h1 className="signin-amplify__header">Llama Merchant Sign Up</h1>
                                <p className="signup-amplify__subheader">Create a new merchant account.</p>
                            </div>
                        </div>
                        <SectionBody theme={theme}>
                            {this.signUpFields.map((field) => {

                                if (field.hidden) {
                                    return null;
                                }

                                return (
                                    <div className="amplify-form-item" key={field.key}>

                                        <div className="amplify-input-wrapper">
                                        {
                                            field.key === 'phone' && (
                                                <select 
                                                    className="amplify-select"
                                                    name="dial_code"
                                                    defaultValue={this.getDefaultDialCode()}
                                                    onChange={this.handleInputChange}
                                                >
                                                    {countryDialCodes.map((dialCode) => {
                                                        return <option key={dialCode} value={dialCode}>{dialCode}</option>
                                                    })}
                                                </select>
                                            )
                                        }

                                        <InputRow
                                            placeholder={field.placeholder}
                                            theme={theme}
                                            type={field.type}
                                            name={field.key}
                                            key={field.key}
                                            onChange={this.onChange}
                                            value={this.state.values[field.key]}
                                            readonly={field.key === 'myshopify_domain' && 'readonly'}
                                        />
                                    
                                        </div>
                                        {
                                            field.key === 'password'
                                            && this.state.showPasswordRequirements
                                            && <PasswordRequirements password={this.inputs.password}/>
                                        }
                                        {
                                            field.key === 'confirm_password' 
                                            && <PasswordMatch password={this.inputs.password} confirm={this.inputs.confirm_password} />
                                        }
                                    </div>
                                )
                            })}
                        </SectionBody>
                        <div className="amplify__custom-footer">
                            <div className="amplify__button-wrapper">
                                <LlamaButton 
                                    background="rgb(253, 42, 84)"
                                    classes={applyButtonClass}
                                    theme={theme} 
                                    onClick={this.signUp}
                                    disabled={this.state.isLoading}>
                                    Create Account
                                </LlamaButton>
                            </div>
                            <div className="amplify-section-footer_links">
                                {'Have an account? '}
                                <div className="amplify__custom-link">
                                    <Link theme={theme} onClick={() => this.changeState('signIn')}>
                                        {'Sign in'}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="signup-amplify__graphic-wrapper">
                    <img alt="Leo the Llama Merchant and his shop" src={loginGraphic} className="signin-amplify__login-graphic signin-amplify__login-graphic--signup" />
                </div>
            </div>
        )
    }

}

export default LlamaSignUp;