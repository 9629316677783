import React from 'react';

const LlamaGDPR = () => {
    return (
        <div style={{ padding: '2rem 4rem' }}>
            <h1 style={{ fontWeight: 'bold', fontSize: '1.5rem', margin: '1.5rem 0' }}>LLAMA &amp; GDPR &#40;General Data Protection&#41;</h1>
                <p>LLAMA supports GDPR. While we do not believe this regulation is specifically targeted at business applications like ours, we do support GDPR and have taken measures to develop LLAMA in accordance with this protocol.</p>
                <br />
                <p>We make money solely by selling subscriptions to our product to our business users. We do <i>not</i> sell data or contact information or anything to third parties.</p>
                <br />
                <p>We <i>do</i> take steps to protect the privacy of our users and their affiliates. Here are the current measures we have in place (per GDPR):</p>
                <br />
                <b>What Data Do We Collect?</b>
                <p>Our job is tracking the source of visitors, opt-ins and purchases to our users’ websites and online stores. Our proprietary affiliate tracking technology handles this using first-party cookies and other privacy best practices.</p>
                <br />
                <b>Lawful Basis for Processing</b>
                <p>According to GDPR, this is lawful basis for processing:<br />
                Processing is necessary for the purposes of the legitimate interests pursued by the controller or by a third party.</p>
                <br />
                <b>Further GDPR Support</b>
                <p>Although not specifically required by GDPR, we are actively encrypting inflight and at rest. We will update this document as we roll out additional compliance features. If you have any questions about LLAMA and GDPR, or our privacy policy in general, please contact us at info@llama.app.</p>
        </div>
    )
}

export default LlamaGDPR;