import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Page, Layout, ButtonGroup, Banner, Modal } from '@shopify/polaris';
import { withFormik } from 'formik';

import { LlamaButton } from 'llama-library/components';

import { RateTypes } from '../utils/types';
import { createOffer, saveOfferBanner } from '../actions/create-offer';
import { getAppData } from '../actions/app-data';
import { getProductsForAdvertiser } from '../actions/products';
import CampaignName from '../components/campaign-name/campaign-name';
import CampaignSummary from '../components/campaign-summary/campaign-summary';
import CampaignProducts from '../components/campaign-products/campaign-products';
import CampaignRates from '../components/campaign-rates/campaign-rates';
import CampaignTarget from '../components/campaign-target/campaign-target';
import withProducts from '../hoc/with-products';
import Payment from '../components/settings-paypal/paypal-payment';
import { saveImage } from '../components/llama/image-drop';

import './styles/offer-new.css';
import './styles/offer-form.css';

export class NewOfferContainer extends Component {
    slides = [
        (props) => { return <CampaignName {...this.state} {...props} />; },
        (props) => { return <CampaignProducts {...props} />; },
        (props) => { return <CampaignRates {...props} />; },
        (props) => { return <CampaignTarget {...this.state} {...props} />; }
    ]

    slidePositionNames = ['summary', 'products', 'commission', 'demographics'];

    constructor(props) {
        super(props);

        console.log(props);

        this.state = {
            categories: [],
            countries: [],
            position: 0,
            savedAdvertiser: props.advertiser,
            isPayPalConnected: false,
            paypalModal: false,
            disableSubmit: false,
            submitting: false,
            errors: [],
            saving: false
        };

        this.renderCurrentPosition = this.renderCurrentPosition.bind(this);
        this.changeSlide = this.changeSlide.bind(this);
        this.toggleSubmit = this.toggleSubmit.bind(this);
        this.togglePaypalModal = this.togglePaypalModal.bind(this);
        this.infoBanner = this.infoBanner.bind(this);
    }

    componentDidMount() {
        if (this.props.advertiser.paypal && this.props.advertiser.paypal.refresh !== null
            && this.props.advertiser.paypal.user !== null) {
            this.setState({ isPayPalConnected: true });
        }

        if (this.props.app.categories.length === 0 || this.props.app.countries.length === 0) {
            // Initiate the API call
            this.props.getAppData()
                .then((result) => {
                    this.setState({
                        categories: result.value.data.app.categories,
                        countries: result.value.data.app.countries.map((item) => {
                            return { label: item.name, value: item.country_code };
                        })
                    });
                });
        } else {
            this.setState({
                categories: this.props.app.categories,
                countries: this.props.app.countries.map((item) => {
                    return { label: item.name, value: item.country_code };
                })
            });
        }
    }

    static getDerivedStateFromProps(nextProps) {
        if (nextProps.advertiser.paypal && nextProps.advertiser.paypal.refresh !== null
            && nextProps.advertiser.paypal.user !== null) {
            return { isPayPalConnected: true };
        }
        return null;
    }

    togglePaypalModal = () => {
        this.setState({
            disableSubmit: false,
            submitting: false,
            paypalModal: !this.state.paypalModal
        });
    }

    setPaypalConnected = () => {
        this.setState({ isPayPalConnected: true });
    }

    toggleSubmit() {
        this.setState({
            disableSubmit: !this.state.disableSubmit,
            submitting: true
        });
    }

    changeSlide(direction) {
        window.scrollTo(0, document.querySelector('.Polaris-Layout__Section').offsetTop - 15);
        let newPosition;
        const currentPosition = this.state.position;

        if (direction === 'next') {
            if (currentPosition === this.slides.length - 1) {
                this.toggleSubmit();
                if (this.state.isPayPalConnected) {
                    this.props.submitForm();
                } else {
                    this.togglePaypalModal();
                }

                return;
            }
            newPosition = currentPosition + 1;
        } else {
            if (currentPosition === this.slides.length - 1) {
                if (this.state.paypalModal) {
                    this.setState({ paypalModal: false });
                }
                if (this.state.disableSubmit) {
                    this.setState({ disableSubmit: false });
                }
            }

            if (currentPosition === 0) {
                return;
            }
            newPosition = currentPosition - 1;
        }
        // validate
        const errors = [];
        if (direction === 'next') {
            switch (this.slidePositionNames[currentPosition]) {
                case 'summary':
                    if (this.props.values.name.trim().length === 0) {
                        errors.push({ field: 'name', message: 'Offer Name is required' });
                    }
                    if (this.props.values.category.trim().length === 0) {
                        errors.push({ field: 'category', message: 'Please select a category' });
                    }
                    if (document.getElementById('offer-preview_url').value === `${this.props.advertiser.domain}/`) {
                        this.props.setFieldValue('preview_url', `https://${this.props.advertiser.domain}/`, true);
                    } else if (this.props.values.preview_url.trim().length === 0) {
                        errors.push({ field: 'preview_url', message: 'Destination URL is required' });
                    } else {
                        let domain = this.props.values.preview_url.toLowerCase().replace('https://', '');
                        if (domain.indexOf('/') !== -1) {
                            domain = domain.substr(0, domain.indexOf('/'));
                        }
                        // make sure the domain tld is at least two letters long
                        if (!/\.[a-z]{2,}$/.test(domain)) {
                            errors.push({ field: 'preview_url', message: 'Destination URL is invalid' });
                        }
                    }
                    break;
                case 'products':
                    if (this.props.values.products.includeSome && this.props.values.products.includeProducts.length === 0) {
                        errors.push({ field: 'products', message: 'Please select the products you want to include in your offer' });
                    }
                    break;
                case 'commission':
                    if (this.props.values.rate.amount.toString().trim().length === 0) {
                        let commissionField = '';
                        let commissionMessage = 'Please enter a';
                        switch (this.props.values.rate.type) {
                            case 'PERCENT_REVENUE':
                                commissionField = 'rate_percent';
                                commissionMessage += ' percentage';
                                break;
                            case 'FLAT_ORDER':
                            case 'FLAT_ITEM':
                                commissionField = 'rate_flat';
                                commissionMessage += 'n amount';
                                break;
                            default:
                                break;
                        }
                        errors.push({ field: commissionField, message: commissionMessage });
                    }
                    if (this.props.values.longterm_rate.amount.toString().trim().length === 0) {
                        let commissionField = '';
                        let commissionMessage = 'Please enter a';
                        switch (this.props.values.longterm_rate.type) {
                            case 'PERCENT_REVENUE':
                                commissionField = 'rate_percent';
                                commissionMessage += ' percentage';
                                break;
                            case 'FLAT_ORDER':
                            case 'FLAT_ITEM':
                                commissionField = 'rate_flat';
                                commissionMessage += 'n amount';
                                break;
                            default:
                                break;
                        }
                        errors.push({ field: `longterm_${commissionField}`, message: commissionMessage });
                    }
                    break;
                case 'demographics':

                    break;
                default:
                    break;
            }
        }

        if (this.props.values.offer_type === 'product_giveaway' && newPosition === 2) {
            if (direction === 'next') {
                newPosition++;
            } else {
                newPosition--;
            }
        }

        if (errors.length === 0) {
            this.setState({ position: newPosition });
        } else {
            this.setState({ errors });
        }
    }

    /* nextSlide() {
        // If the last slide is already in use then create offer.
        if (this.state.position === this.slides.length - 1) {
            this.props.submitForm();
            return;
        }

        const newPosition = this.state.position + 1;
        this.setState({ position: newPosition })
    }

    previousSlide() {
        // If the first slide is already in use then do nothing.
        if (this.state.position === 0) {
            return;
        }

        const newPosition = this.state.position - 1;
        this.setState({ position: newPosition })
    } */

    renderPaypalModal = () => {
        return (
            <Modal
                open={this.state.paypalModal}
                onClose={this.togglePaypalModal}
                title="Connect your PayPal"
                secondaryActions={
                    // if they haven't clicked the paypal button, show save offer as a secondary button, otherwise don't show a secondary button
                    (!this.state.isPayPalConnected)
                        ? [{
                            content: 'Save Offer as Inactive',
                            disabled: this.state.disableSubmit || this.state.submitting,
                            loading: this.state.submitting,
                            onAction: () => {
                                this.toggleSubmit();
                                return this.props.submitForm();
                            }
                        }]
                        : null
                }
                primaryAction={
                    // if they clicked the paypal button and connected successfully, show create offer as a primary button, otherwise don't show a primary button
                    (this.state.isPayPalConnected)
                        ? {
                            content: 'Create Active Offer',
                            disabled: this.state.disableSubmit || this.state.submitting,
                            loading: this.state.submitting,
                            onAction: () => {
                                this.toggleSubmit();
                                return this.props.submitForm();
                            }
                        }
                        : null
                }
            >
                <Modal.Section>
                    <p className="offer-paypal-message"><strong>A valid PayPal connection is required before we can actively display your offer to potential ambassadors.</strong> It ensures you&rsquo;re able to pay those ambassadors when they earn commissions.</p>
                    <Payment callback={this.setPaypalConnected} />
                    <p className="offer-paypal-message-under">If you can&rsquo;t connect right now, you can still create your offer, but it will remain inactive until you&rsquo;ve connected your PayPal.</p>
                </Modal.Section>
            </Modal>
        );
    }

    /* warningBanner() {
        return (<Banner
            title="Important Note"
            status="critical"
            onDismiss={() => { }}
        >
            <Stack vertical>
                <Stack.Item fill>
                    <TextContainer>
                        <p>You have not yet connected your PayPal account,
                        which is a requirement to create an Offer and receive referral orders.
                        Please do so now with one click button below and then save your Offer.
                        </p>
                    </TextContainer>
                </Stack.Item>
                <Stack.Item>
                    <div className="offer-form-buttons">
                        <ButtonGroup>
                            <LlamaButton
                                onClick={this.props.submitForm}
                                inverted background={"#FD2856"}
                            >Create Offer
                            </LlamaButton>
                            <Payment></Payment>
                        </ButtonGroup>
                    </div>
                </Stack.Item>
            </Stack>
        </Banner>);
    } */

    infoBanner = () => {
        return (
            <Banner
                title="Important Note"
                status="info"
            >
                <p>An offer acts as a contract between you and your ambassadors. <strong>Once you have created your offer, you won&rsquo;t be able to edit it for at least 30 days and it cannot be deleted.</strong></p>
            </Banner>
        );
    }

    renderCurrentPosition(position) {
        // Do nothing if the position expected is larger than the
        // array of components.
        if (position >= this.slides.length) {
            return null;
        }

        const componentRenderFunction = this.slides[position];
        return <div className="offer-form">{componentRenderFunction({ ...this.props, ...this.state })}</div>;
    }

    render() {
        const nextVerbiage = this.state.position === this.slides.length - 1
            ? 'Finish'
            : 'Next';

        return (
            <Page
                data-test="offer-new-container"
                title="New Offer"
                separator
                breadcrumbs={[{ content: 'Back to Offers', onAction: () => { return this.props.history.push('/offers'); } }]}
            >
                <div style={{ marginBottom: '2rem' }}>
                    {this.infoBanner()}
                </div>
                {this.renderPaypalModal()}
                <div className="offer-form-layout-wrapper">
                    <Layout>
                        <Layout.Section>
                            {this.renderCurrentPosition(this.state.position)}
                            <div className="offer-form-buttons">
                                <ButtonGroup>
                                    {this.state.position > 0
                                        && (
                                            <LlamaButton
                                                data-test="previous-button"
                                                disabled={this.state.position === 0}
                                                onClick={() => { return this.changeSlide('previous'); }}
                                                inverted
                                                background="#FD2856"
                                            >
                                                Back
                                            </LlamaButton>
                                        )}
                                    <LlamaButton
                                        disabled={this.state.disableSubmit || this.state.submitting}
                                        loading={this.state.submitting}
                                        data-test="next-button"
                                        primary
                                        onClick={() => { return this.changeSlide('next'); }}
                                    >
                                        {nextVerbiage}
                                    </LlamaButton>
                                </ButtonGroup>
                            </div>
                        </Layout.Section>
                        <Layout.Section secondary>
                            <div className="offer-new__summary-wrapper" data-curstep={this.slidePositionNames[this.state.position]}>
                                <CampaignSummary
                                    {...this.props}
                                />
                            </div>
                        </Layout.Section>
                    </Layout>
                </div>
            </Page>
        );
    }
}

NewOfferContainer.propTypes = {
    advertiser: PropTypes.shape({
        paypal: PropTypes.shape({
            refresh: PropTypes.string,
            user: PropTypes.string
        }),
        domain: PropTypes.string
    }).isRequired,
    app: PropTypes.shape({
        categories: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string,
            value: PropTypes.number
        })),
        countries: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string,
            country_code: PropTypes.string
        }))
    }).isRequired,
    getAppData: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    values: PropTypes.shape({
        name: PropTypes.string,
        category: PropTypes.string,
        preview_url: PropTypes.string,
        offer_type: PropTypes.string,
        products: PropTypes.shape({
            includeSome: PropTypes.bool,
            includeProducts: PropTypes.arrayOf(PropTypes.string)
        }),
        rate: PropTypes.shape({
            amount: PropTypes.string,
            type: PropTypes.string
        }),
        longterm_rate: PropTypes.shape({
            amount: PropTypes.string,
            type: PropTypes.string
        })
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired
};

const defaultOffer = {
    active: false,
    advertiser_id: null,
    description: '',
    draft: true,
    errors: [],
    tags: [],
    name: '',
    id: null,
    offer_url: '',
    preview_url: '...',
    rate: {
        type: RateTypes.PERCENT_REVENUE,
        amount: 10
    },
    longterm_rate: {
        type: RateTypes.PERCENT_REVENUE,
        amount: 10
    },
    cookie_days: 30,
    products: {
        includeAll: true,
        includeSome: false,
        excludeSome: false,
        includeProducts: [],
        excludeProducts: []
    },
    category: '',
    creatives: [],
    target: {
        gender: 'ALL',
        age_low: 18,
        age_high: 65,
        interests: [],
        countries: ['US']
    },
    banner: null,
    offer_type: 'commission',
    private_offer: false
};

const submitOffer = (values, { props }) => {
    if (values.offer_type === 'product_giveaway') {
        values.rate = {
            type: 'PERCENT_REVENUE',
            amount: '0'
        };
        delete values.longterm_rate;
    }

    const activeCampaign = {
        formData: values,
        advertiser: props.advertiser
    };

    // If PayPal is connected only then set the Offer as ACTIVE else INACTIVE
    if (props.advertiser.paypal && props.advertiser.paypal.refresh && props.advertiser.paypal.user) {
        values.active = true;
    } else if (!props.advertiser.paypal || (!props.advertiser.paypal.refresh && !props.advertiser.paypal.user)) {
        values.active = false;
        values.draft = false;
        // Save the offer as local offer
        values.local_offer = true;
        values.inactive_reason = 'PayPal account not connected';
    }

    props.createOffer(activeCampaign)
        .then(async (result) => {
            const { value } = result;
            if (value && value.createOffer && value.createOffer.offer_id) {
                let imageData;
                if (values.banner && values.banner.imageData) {
                    imageData = await saveImage(values.banner.imageData, props.advertiser.id, `offers/${value.createOffer.offer_id}/banner`, null);
                    await props.saveOfferBanner(value.createOffer.offer_id, imageData.url);
                }
                props.history.push(`/offer/${value.createOffer.offer_id}`, { offerStateUpdate: true });
            }
        });
};

const NewOfferForm = withFormik({
    mapPropsToValues: () => { return defaultOffer; },
    handleSubmit: submitOffer
})(NewOfferContainer);

const mapStateToProps = ({ advertiser, app, products }) => {
    return {
        advertiser,
        app,
        products: products.products
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        saveOfferBanner: (advertiser_id, image) => { return dispatch(saveOfferBanner(advertiser_id, image)); },
        createOffer: (data) => { return dispatch(createOffer(data)); },
        getAppData: () => { return dispatch(getAppData()); },
        getProductsForAdvertiser: (shop) => dispatch(getProductsForAdvertiser(shop))
    };
};

const WithProductOfferForm = withProducts(NewOfferForm);

export default connect(mapStateToProps, mapDispatchToProps)(WithProductOfferForm);
