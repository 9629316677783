import { sendGraphQL } from './sendRequest';

export const getBillingInfo = (advertiser_id) => {
    const query = `
        query getOfferBoostBillingInfo($advertiser_id: String!){
            getOfferBoostBillingInfo(advertiser_id: $advertiser_id){
                card_data{
                    brand
                    last4
                    exp_month
                    exp_year
                }
                invoiceGroups{
                    status
                    created
                    number
                    amount_paid
                    billing_reason
                    description
                }
            }
        }
    `;

    const variables = {
        advertiser_id
    };

    const payload = {
        query,
        variables
    };

    return sendGraphQL(payload, false, true)
        .then((response) => { return response.data; });
};

export const getBillingHistory = () => {

};

export const cancelSubscription = (advertiser_id) => {
    const query = `
        mutation cancelOfferBoostSubscription($advertiser_id: String!) {
            cancelOfferBoostSubscription(advertiser_id: $advertiser_id)
        }
    `;

    const variables = {
        advertiser_id
    };

    const payload = {
        query,
        variables
    };

    return sendGraphQL(payload, false, true)
        .then((response) => { return response.data; });
};

export const updateSubscription = (advertiser_id, card_token, cardholder_name) => {
    const query = `
        mutation updateOfferBoostSubscription($advertiser_id: String!, $card_token: String!, $cardholder_name: String!) {
            updateOfferBoostSubscription(advertiser_id: $advertiser_id, card_token: $card_token, cardholder_name: $cardholder_name){
                brand,
                last4,
                exp_month,
                exp_year
            }
        }
    `;

    const variables = {
        advertiser_id,
        card_token,
        cardholder_name
    };

    const payload = {
        query,
        variables
    };

    return sendGraphQL(payload, false, true)
        .then((response) => { return response.data; });
};

export default {};
