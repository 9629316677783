import React, { useState, useEffect } from 'react';
import {  Page, Frame, Toast } from '@shopify/polaris';
import { HypeAccount, LlamaButton, TikTokAccount } from 'llama-library/components';

import breadcrumb from '../../assets/arrow.png';
import facebookIcon from '../../assets/facebook-icon.svg';
import instagramIcon from '../../assets/instagram-icon.png';
import youtubeIcon from '../../assets/youtube-icon.svg';
import twitterIcon from '../../assets/twitter-icon.svg';
import tiktokIcon from '../../assets/tiktok-icon.svg';
import dropdownArrow from '../../assets/dropdown-arrow.svg';
import './affiliate-detail.css';
import llamaPlaceholder from '../../assets/llama-user-icon.png';

import YoutubeAffiliateDetail, { checkValidYoutube } from './youtube-affiliate-detail';
import TwitterAffiliateDetail, { checkValidTwitter } from './twitter-affiliate-detail';
import FacebookAffiliateDetail, { checkValidFacebook } from './facebook-affiliate-detail';
import DashboardAffiliateDetail from './dashboard-affiliate-detail';
import LoadingAffiliateDetail from './loading-affiliate-detail';
import HypeAuditorLockedDetail from './hypeauditor-locked';
import EmptyAffiliateDetail from './empty-affiliate-detail';
import InviteSideDrawer from '../discover/side-drawer';
import { getAffiliateInviteButtonMode } from '../../utils/affiliate-invite-mode';

import { formatAudience, formatGender } from '../../utils/format-audience';
import formatCountries from '../../utils/format-countries';
import formatMetrics from '../../utils/format-metrics';
import {moneyFormat} from '../../utils/number-format';
import formatPercent from '../../utils/format-percent';

import { useToggle } from '../../hooks/use-toggle';

const getActiveInstagramAccounts = (instaData) => {
    let activeInstaAccounts = [];
    if (instaData === undefined || (!Array.isArray(instaData) && instaData.length === 0)) {
        return activeInstaAccounts;
    }

    activeInstaAccounts = instaData.filter((item) => {
        return item.account_verified && (item.account_type === 'ACTIVE' || item.account_type === 'TRIAL');
    });

    return activeInstaAccounts;
};

const getActiveTikTokAccount = (tikTokData) => {
    let activeTikTokAccounts = [];
    if (tikTokData === undefined || (!Array.isArray(tikTokData) && tikTokData.length === 0)) {
        return activeTikTokAccounts;
    }

    activeTikTokAccounts = tikTokData.filter((item) => {
        return item.account_verified && (item.account_type === 'ACTIVE' || item.account_type === 'TRIAL');
    });

    return activeTikTokAccounts;
};

/**
 * @function AffiliateSidebar
 * Accepts props, returns AffiliateSidebar Component.
 * Displays links to socialprofiles and other basic profile data 
 * (i.e: name, image, categories, audience demographics & tracking).
 * 
 * @param {Object} props
 * 
 * @returns {FunctionComponent}
 */
const AffiliateSidebar = (props) => {

    if (!props || !props.affiliate) return null;

    // console.log("SIDEBAR PROPPIES", props.affiliate);

    const {
        name,
        photo,
        category,
        youTubeData = [],
        twitterData = [],
        facebookData = [],
        account,
        tracking } = props.affiliate;

    let { inviteText, inviteColor, isInvitedisabled } = getAffiliateInviteButtonMode(props.affiliate.invitedOffers, props.advertiserOffers);

    // Get the active Instagram Accounts
    const activeInstagramAccounts = getActiveInstagramAccounts(props.affiliate.instagramDataV2);
    const hasActiveInstagram = activeInstagramAccounts.length > 0;

    // Get the active TikTok Accounts
    const activeTikTokAccounts = getActiveTikTokAccount(props.affiliate.tikTokData);
    const hasActiveTikTok = activeTikTokAccounts.length > 0;

    // Generate YouTube Link
    const youTubeAccount = youTubeData !== null ? youTubeData[0] : [];
    const youTubeLink = youTubeAccount && youTubeAccount.channel ? 
    `https://www.youtube.com/channel/${youTubeAccount.channel}` : null;

    // Generate Twitter Link
    const twitterAccount = twitterData !== null ? twitterData[0] : [];
    const twitterLink = twitterAccount && twitterAccount.twitter_id ?
    `https://twitter.com/${twitterAccount.twitter_id}` : null;

    // Generate Instagram Link
    const instagramAccount = activeInstagramAccounts[0];
    const instagramLink = instagramAccount && instagramAccount.username ?
    `https://www.instagram.com/${instagramAccount.username}` : null;

    // Generate TikTok Link
    const tikTokAccount = activeTikTokAccounts[0];
    const tikTokLink = tikTokAccount && tikTokAccount.username ?
        `https://www.tiktok.com/@${tikTokAccount.username}` : null;

    // Tracking Data.
    const clicks = tracking && tracking.Stat ? formatMetrics(tracking.Stat.clicks) : 0;
    const epc = tracking && tracking.Stat ? moneyFormat(tracking.Stat.epc, true) : '$0';
    const conversions = tracking && tracking.Stat && tracking.Stat.conversions
        ? tracking.Stat.conversions 
        : 0;
    const conversionPercent = formatPercent(conversions / clicks, true);

    return (
        <div className="affiliate-sidebar__sidebar" data-test="component-affiliate-sidebar">
            <div className="affiliate-sidebar__sidebar-section affiliate-sidebar__sidebar-section-top">
                <div className="affiliate-sidebar__avatar">
                    <img src={photo || llamaPlaceholder} alt="Ambassador" className="affiliate-sidebar__avatar-img"/>
                </div>
                <div className="affiliate-sidebar__name">
                    {name}
                </div>
                {category && category.map((item) => (
                    <div className="affiliate-sidebar__category">
                        {item}
                    </div>
                ))}
                <div className="affiliate-sidebar__social-icons">
                    {facebookData && Array.isArray(facebookData) && facebookData.length > 0 &&
                    <a className="affiliate-sidebar__social-link">
                        <img src={facebookIcon} alt="Facebook" className="affiliate-sidebar__social-icon"/>
                    </a>
                    }

                    {hasActiveInstagram
                        && (
                            <a className="affiliate-sidebar__social-link" href={instagramLink}>
                                <img src={instagramIcon} alt="Instagram" className="affiliate-sidebar__social-icon" />
                            </a>
                        )}

                    {twitterData && Array.isArray(twitterData) && twitterData.length > 0 &&
                    <a className="affiliate-sidebar__social-link" href={twitterLink}>
                        <img src={twitterIcon} alt="Twitter" className="affiliate-sidebar__social-icon"/>
                    </a>
                    }

                    {youTubeData && Array.isArray(youTubeData) && youTubeData.length > 0 &&
                    <a className="affiliate-sidebar__social-link" href={youTubeLink}>
                        <img src={youtubeIcon} alt="YouTube" className="affiliate-sidebar__social-icon"/>
                    </a>
                    }

                    {hasActiveTikTok
                        && (
                            <a className="affiliate-sidebar__social-link" href={tikTokLink}>
                                <img src={tiktokIcon} alt="TikTok" className="affiliate-sidebar__social-icon" />
                            </a>
                        )}
                </div>
                { !props.isLoading && <div className="affiliate-sidebar__invite-button"><LlamaButton background={inviteColor} isDisabled={isInvitedisabled} data-test="button-inviteAffiliate" onClick={() => !isInvitedisabled && props.setSideDrawerAffiliate(props.affiliate)}>{inviteText}</LlamaButton></div>}
            </div>
            <div className="affiliate-sidebar__sidebar-section affiliate-sidebar__sidebar-section-quicklook">
                <div className="affiliate-sidebar__seciton-header">Audience Demographic</div>
                <div className="affiliate-sidebar__sidebar-table">
                    <div className="affiliate-sidebar__table-header">
                        <div>Age Range</div>
                        <div>Gender</div>
                        <div>Country</div>
                    </div>
                    <div className="affiliate-sidebar__table-body">
                        <div>{account && formatAudience(account)}</div>
                        <div>{account && formatGender(account)}</div>
                        <div>{account && formatCountries(account)}</div>
                    </div>
                </div>
            </div>
            <div className="affiliate-sidebar__sidebar-section affiliate-sidebar__sidebar-section-quicklook">
                <div className="affiliate-sidebar__seciton-header">Llama Stats</div>
                <div className="affiliate-sidebar__sidebar-table">
                    <div className="affiliate-sidebar__table-header">
                        <div>Conversion</div>
                        <div>EPC</div>
                        <div>Clicks</div>
                    </div>
                    <div className="affiliate-sidebar__table-body">
                        <div>{conversionPercent}</div>
                        <div>{epc}</div>
                        <div>{clicks}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const tabs = {
    dashboard: 'dashboard',
    facebook: 'facebook',
    twitter: 'twitter',
    instagram: 'instagram',
    youtube: 'youtube',
    tiktok: 'tiktok',
    empty: 'empty'
}

export const ACTIVE_TAB_CLASS = 'affiliate-main__tab--active';

/**
 * @function AffiliateMain
 * Accepts props object, returns AffiliateMain Component.
 * Displays social tabs according to the profiles the ambassador has, and loads those Social Components.
 * 
 * @param {Object} props
 * 
 * @returns {FunctionComponent}
 */
const AffiliateMain = (props) => {
    // console.log("AFF MAIN", props);

    const { affiliate = {} } = props;
    const [updatedProps, setUpdatedProps] = useState(affiliate);

    // Get the active Instagram Accounts
    const activeInstagramAccounts = getActiveInstagramAccounts(affiliate.instagramDataV2);

    // Get the active TikTok Accounts
    const activeTikTokAccounts = getActiveTikTokAccount(affiliate.tikTokData);

    useEffect(() => {
        setUpdatedProps({
            ...props,
            affiliate: {
                ...affiliate,
                instagramDataV2: activeInstagramAccounts,
                tikTokData: activeTikTokAccounts
            }
        });
    }, [props]);

    const facebookDataArray = affiliate.facebook && Array.isArray(affiliate.facebookData)
        && affiliate.facebookData.filter((item) => {
            return checkValidFacebook(item);
        });

    const instagramDataArray = activeInstagramAccounts.length > 0;
    
    const youTubeDataArray = Array.isArray(affiliate.youTubeData)
        && affiliate.youTubeData.filter((item) => {
            return checkValidYoutube(item);
        });

    const twitterDataArray = Array.isArray(affiliate.twitterData)
        && affiliate.twitterData.filter((item) => {
            return checkValidTwitter(item);
        })

    const tikTokDataArray = activeTikTokAccounts.length > 0;

    const checkForApprovedApplications = (item) => {
        return item.advertiser_status === 'APPROVED' && item.affiliate_status === 'APPROVED';
    }
    const hasDashboard = Array.isArray(props.applications) 
        && props.applications.filter(checkForApprovedApplications).length > 0;

    const hasInstagram = instagramDataArray;
    const hasFacebook = Array.isArray(facebookDataArray) && facebookDataArray.length > 0;
    const hasTwitter = Array.isArray(twitterDataArray) && twitterDataArray.length > 0;
    const hasYoutube = Array.isArray(youTubeDataArray) && youTubeDataArray.length > 0;
    const hasTiktok = tikTokDataArray;

    const hasNoConnectedAccounts = !hasInstagram && !hasFacebook && !hasTwitter && !hasYoutube && !hasTiktok;
    
    // Select default tab.
    // TODO: Possibly determine this by reach instead of a default order.
    let defaultTab = null;
    if (hasDashboard) {
        defaultTab = tabs.dashboard;
    } else if (hasInstagram) {
        defaultTab = tabs.instagram;
    } else if (hasFacebook) {
        defaultTab = tabs.facebook;
    } else if (hasTwitter) {
        defaultTab = tabs.twitter;
    } else if (hasYoutube) {
        defaultTab = tabs.youtube;
    }else if (hasTiktok) {
        defaultTab = tabs.tiktok;
    }
     else {
        defaultTab = tabs.empty;
    }

    //set number of tabs
    let tabCount = 0;
    if (hasDashboard) {
        tabCount++;
    }

    if (hasInstagram) {
        tabCount++;
    }

    if (hasFacebook) {
        tabCount++;
    }

    if (hasTwitter) {
        tabCount++;
    }

    if (hasYoutube) {
        tabCount++;
    }

    if (hasTiktok) {
        tabCount++;
    }

    const [currentTab, setTab] = useState(null);
    useEffect(() => {
        // Check page isn't loading and that affiliate has data.
        if (!props.isLoading && affiliate.cognito_id) {
            setTab(defaultTab);
        }
    }, [defaultTab])
    
    const dashboardTabClasses = [ "affiliate-main__tab", "affiliate-main__tab-dashboard" ]
    const facebookTabClasses = [ "affiliate-main__tab", "affiliate-main__tab-facebook" ]
    const instagramTabClasses = [ "affiliate-main__tab", "affiliate-main__tab-instagram" ]
    const twitterTabClasses = [ "affiliate-main__tab", "affiliate-main__tab-twitter" ]
    const youtubeTabClasses = [ "affiliate-main__tab", "affiliate-main__tab-youtube" ]
    const tiktokTabClasses = [ "affiliate-main__tab", "affiliate-main__tab-tiktok"]
    
    // Add active class to the appropriate tab.
    const addActiveClass = {
        [tabs.dashboard]: () => dashboardTabClasses.push(ACTIVE_TAB_CLASS),
        [tabs.facebook]: () => facebookTabClasses.push(ACTIVE_TAB_CLASS),
        [tabs.instagram]: () => instagramTabClasses.push(ACTIVE_TAB_CLASS),
        [tabs.twitter]: () => twitterTabClasses.push(ACTIVE_TAB_CLASS),
        [tabs.youtube]: () => youtubeTabClasses.push(ACTIVE_TAB_CLASS),
        [tabs.tiktok]: () => tiktokTabClasses.push(ACTIVE_TAB_CLASS),
    }

    if (typeof addActiveClass[currentTab] === `function`) {
        addActiveClass[currentTab]();
    }

    // Get component to render.
    const socialComponents = {
        [tabs.dashboard]: DashboardAffiliateDetail,
        [tabs.facebook]: FacebookAffiliateDetail,
        [tabs.instagram]: HypeAccount,
        [tabs.twitter]: TwitterAffiliateDetail,
        [tabs.youtube]: YoutubeAffiliateDetail,
        [tabs.tiktok]: TikTokAccount,
        [tabs.empty]: EmptyAffiliateDetail
    }

    // Get social component data
    const socialComponentData = {
        [tabs.facebook]: facebookDataArray,
        [tabs.instagram]: instagramDataArray,
        [tabs.twitter]: twitterDataArray,
        [tabs.youtube]: youTubeDataArray,
        [tabs.tiktok]: tikTokDataArray
    }

    //Determine which SocialComponent to pass
    const SocialComponent = socialComponents[currentTab]
        ? socialComponents[currentTab]
        : () => <div></div>;

    const [openMobileTabs, setOpenMobileTabs] = useToggle(false);

    if (props.isLoading) {
        return <LoadingAffiliateDetail data-test="component-loadingAffiliate" />
    }

    return (
        <div className="affiliate-main" data-test="component-affiliate-main">
            <div className="affiliate-main__tabs">
                {hasDashboard && (
                    <div 
                        className={dashboardTabClasses.join(' ')} 
                        onClick={() => setTab(tabs.dashboard)}
                        data-test="component-tab" 
                    >
                        Dashboard
                    </div>
                )}

                {instagramDataArray && hasInstagram && (
                    <div
                        className={instagramTabClasses.join(' ')}
                        onClick={() => setTab(tabs.instagram)}
                        data-test="component-tab"
                    >
                        Instagram
                    </div>
                )}

                {facebookDataArray && hasFacebook && (
                    <div
                        className={facebookTabClasses.join(' ')}
                        onClick={() => setTab(tabs.facebook)}
                        data-test="component-tab"
                    >
                        Facebook
                    </div>
                )}

                {twitterDataArray && hasTwitter && (
                    <div
                        className={twitterTabClasses.join(' ')}
                        onClick={() => setTab(tabs.twitter)}
                        data-test="component-tab"
                    >
                        Twitter
                    </div>
                )}

                {youTubeDataArray && hasYoutube && (
                    <div
                        className={youtubeTabClasses.join(' ')}
                        onClick={() => setTab(tabs.youtube)}
                        data-test="component-tab"
                    >
                        YouTube
                    </div>
                )}

                {tikTokDataArray && hasTiktok && (
                    <div
                        className={tiktokTabClasses.join(' ')}
                        onClick={() => setTab(tabs.tiktok)}
                    >
                        TikTok
                    </div>
                )}
            </div>

            <div className="affiliate-main__tab-mobile-active" onClick={() => tabCount > 1 ? setOpenMobileTabs(true) : null}>
                {currentTab}
                {tabCount > 1 &&
                    <img 
                    src={dropdownArrow} 
                    alt="Down"
                    className={openMobileTabs ? "affiliate-main__tab--arrow affiliate-main__tab--arrow-flipped" : "affiliate-main__tab--arrow"} />
                }
            </div>
            {openMobileTabs &&
            <div className="affiliate-main__tabs--mobile">
                {hasDashboard && (
                    <div 
                        className={dashboardTabClasses.join(' ')} 
                        onClick={() => {
                            setTab(tabs.dashboard)
                            setOpenMobileTabs(!openMobileTabs)
                        }} 
                    >
                        Dashboard
                    </div>
                )}

                {instagramDataArray && hasInstagram && (
                    <div 
                        className={instagramTabClasses.join(' ')}
                        onClick={() => {
                            setTab(tabs.instagram)
                            setOpenMobileTabs(!openMobileTabs)
                        }} 
                    >
                        Instagram
                    </div>
                )}

                {facebookDataArray && hasFacebook && (
                    <div 
                        className={facebookTabClasses.join(' ')} 
                        onClick={() => {
                            setTab(tabs.facebook)
                            setOpenMobileTabs(!openMobileTabs)
                        }} 
                    >
                        Facebook
                    </div>
                )}

                {twitterDataArray && hasTwitter && (
                    <div 
                        className={twitterTabClasses.join(' ')}
                        onClick={() => {
                            setTab(tabs.twitter)
                            setOpenMobileTabs(!openMobileTabs)
                        }} 
                    >
                        Twitter
                    </div>
                )}

                {youTubeDataArray && hasYoutube && (
                    <div 
                        className={youtubeTabClasses.join(' ')}
                        onClick={() => {
                            setTab(tabs.youtube)
                            setOpenMobileTabs(!openMobileTabs)
                        }} 
                    >
                        YouTube
                    </div>
                )}
                {tikTokDataArray && hasTiktok && (
                    <div
                        className={tiktokTabClasses.join(' ')}
                        onClick={() => {
                            setTab(tabs.tiktok)
                            setOpenMobileTabs(!openMobileTabs)
                        }}
                    >
                        TikTok
                    </div>
                )}
            </div>}
            {hasNoConnectedAccounts
                ? <EmptyAffiliateDetail {...props} data-test="component-emptyAffiliateDetail" />
                : <SocialComponent {...updatedProps} data={socialComponentData[currentTab]} />}
        </div>
    );
};

/**
 * @function AffiliateDetail
 * Accepts props object, returns AffiliateDetail Component.
 * Wraps our AffiliateSidebar, AffiliateMain and InviteSideDrawer Components.
 * 
 * @param {Object} props
 * 
 * @returns {FunctionComponent}
 */
const AffiliateDetail = (props) => {
    const [sideDrawerAffiliate, setSideDrawerAffiliate] = useState(null);
    const [toastVerbiage, setToastVerbiage] = useState(null);
    const [isInvitationSent, setIsInvitationSent] = useState(false);
    const [invitedOffers, setInvitedOffers] = useState([]);

    useEffect(() => {
        if(invitedOffers.length > 0) {
            props.updateInvitedOffers(invitedOffers);
            setInvitedOffers([]);
        }
    }, [invitedOffers])

    if (!props || !props.affiliate) return null;
    
    // console.log("AFF DETAIL", props);
    const advertiserOffers = props.advertiser.offers.filter((offer)=>offer.active == true);
    const toastMarkup = toastVerbiage ? <Toast content={toastVerbiage} onDismiss={() => setToastVerbiage(null)}/> : null;

    return (
        <div className="affiliate-detail" data-test="component-affiliate-detail">
            <Page fullWidth>
                <div className="affiliate-detail-breadcrumb">
                    <a className='affiliate-detail-link' data-test="back-button" onClick={() => {props.history.goBack()}}> <img alt="Back" className='affiliate-detail-breadcrumb-img' src={breadcrumb}/> Back </a>
                </div>
                <div className="affiliate-detail__wrapper">
                    <AffiliateSidebar { ...props } advertiserOffers={advertiserOffers} setSideDrawerAffiliate={setSideDrawerAffiliate} />
                    <AffiliateMain { ...props } />
                    <InviteSideDrawer
                        {...props}
                        offers={advertiserOffers}
                        advertiser_id={props.advertiser.advertiser_id}
                        showSideDrawer={sideDrawerAffiliate !== null}
                        affiliate={sideDrawerAffiliate}
                        setSideDrawerAffiliate={setSideDrawerAffiliate}
                        setToastVerbiage={setToastVerbiage}
                        setIsInvitationSent={setIsInvitationSent}
                        setInvitedOffers={setInvitedOffers}
                    />
                </div>
            </Page>
            <Frame>{toastMarkup}</Frame>
        </div>
    )

}

export default AffiliateDetail;