import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { Modal } from '@shopify/polaris';

import { definitions, howtos } from './leo-help-snippets';

import './leo-help-widget.css';

const LeoHelpWidget = ({ name, currentPath }) => {
    const [helpMenuOpen, setHelpMenuOpen] = useState(false);
    const [driftOpen, setDriftOpen] = useState(false);
    const [currentMenuPath, setCurrentMenuPath] = useState('main');
    const [quickGuideButton, setQuickGuideButton] = useState(null);
    const [videoModalOpen, setVideoModalOpen] = useState(false);
    const [pathKey, setPathKey] = useState(currentPath);
    const [enlargedImage, setEnlargedImage] = useState('');

    // fallback for browsers without e.path
    const buildNodePath = (element, pathArray) => {
        pathArray.push({
            id: element.id,
            className: element.className
        });
        if (element.parentElement) {
            return buildNodePath(element.parentElement, pathArray);
        }
        return pathArray;
    };

    // close help widget when a click is detected elsewhere on the page
    // enlarge image in a modal when a click is detected on an img inside the widget
    useEffect(() => {
        document.addEventListener('click', (e) => {
            let nodePath = [];
            if (e.path) {
                nodePath = e.path;
            } else {
                nodePath = buildNodePath(e.target, nodePath);
            }

            const widgetParent = nodePath.find((node) => {
                return (node.id === 'help-widget');
            });
            const modalParent = nodePath.find((node) => {
                return (node.className === 'Polaris-Modal-Dialog');
            });
            if (!widgetParent && !modalParent) {
                setHelpMenuOpen(false);
            }
            if (widgetParent && e.target.nodeName === 'IMG') {
                setEnlargedImage(e.target.currentSrc);
            }
        });
    }, []);

    // set variable and similar paths to specific keys
    useEffect(() => {
        // use a switch case if more than 2 non-regex conditions
        if (currentPath === '/settings/general' || currentPath === '/settings/logo') {
            setPathKey('/settings');
        // match on offer/:id/edit, offer/:id/coupons, offer/:id/creatives, etc.
        } else if (/\/offer\/\d+\/[a-z]+/.test(currentPath)) {
            const matches = currentPath.match(/\/offer\/\d+\/([a-z]+)/);
            if (matches[1]) {
                setPathKey(`/offer/id/${matches[1]}`);
            }
        } else if (/\/offer\/\d+/.test(currentPath)) {
            setPathKey('/offer/id');
        } else if (/\/affiliate\/[\d\w]+/.test(currentPath)) {
            setPathKey('/affiliate/id');
        } else {
            setPathKey(currentPath);
        }

        // reset popout state to main menu
        setCurrentMenuPath('main');
    }, [currentPath]);

    const toggleDriftChat = () => {
        let currentStyle = '';
        if (driftOpen) {
            document.querySelectorAll('#drift-widget-container, .drift-conductor-item').forEach((elem) => {
                currentStyle = elem.getAttribute('style');
                currentStyle = currentStyle.replace(/display: (block|none)( !important)?;/, '');
                elem.setAttribute('style', `${currentStyle} display: none !important;`);
            });
        } else {
            document.querySelectorAll('#drift-widget-container, .drift-conductor-item').forEach((elem) => {
                currentStyle = elem.getAttribute('style');
                currentStyle = currentStyle.replace(/display: (block|none)( !important)?;/, '');
                elem.setAttribute('style', `${currentStyle} display: block !important;`);
            });

            currentStyle = document.querySelector('.drift-frame-chat').getAttribute('style');
            currentStyle = currentStyle.replace(/max-height: \d+/, 'max-height: 352');
            console.log(currentStyle);
            document.querySelector('.drift-frame-chat').setAttribute('style', currentStyle);
        }
        setDriftOpen(!driftOpen);
    };

    // set the current how to object
    const setQuickGuide = (button) => {
        setCurrentMenuPath('quickGuide');
        setQuickGuideButton(button);
    };

    const renderHelp = () => {
        // main menu
        if (currentMenuPath === 'main') {
            return (
                <>
                    <p>Hey {name}, what can I help you with today?</p>
                    <ul>
                        {definitions[pathKey] && definitions[pathKey].content
                            && <li><button type="button" onClick={() => { return setCurrentMenuPath('definition'); }}>What {definitions[pathKey].plural ? 'are' : 'is'} {definitions[pathKey].name}?</button></li>
                        }
                        {howtos[pathKey] && howtos[pathKey].length > 0
                            && <li><button type="button" onClick={() => { return setCurrentMenuPath('howtos'); }}>How do I...</button></li>
                        }
                        <li><a className="btn" href="https://help.llama.app/collection/1-merchant-portal" target="_blank" rel="noopener noreferrer">View Guides &amp; Tutorials</a></li>

                        <li className="chat"><button type="button" onClick={toggleDriftChat}>Chat with the support team</button></li>
                    </ul>
                </>
            );
        }

        // page based how-to menu
        if (currentMenuPath === 'howtos') {
            return (
                <>
                    <p><button type="button" className="back" onClick={() => { return setCurrentMenuPath('main'); }}>Back</button></p>
                    <ul className="button-group">
                        {howtos[pathKey].map((howToMenu, index) => {
                            return (
                                <li key={index}>
                                    {howToMenu.heading && <p className="heading">{howToMenu.heading}</p>}
                                    <ul>
                                        {howToMenu.buttons.map((button, index2) => {
                                            return <li key={index2}><button type="button" onClick={() => { return setQuickGuide(button); }}>{button.label}</button></li>;
                                        })}
                                    </ul>
                                </li>
                            );
                        })}
                    </ul>
                </>
            );
        }

        // "what is X" content
        if (currentMenuPath === 'definition') {
            return (
                <>
                    <p><button type="button" className="back" onClick={() => { return setCurrentMenuPath('main'); }}>Back</button></p>
                    <div className="help-content" dangerouslySetInnerHTML={{ __html: definitions[pathKey].content }} />
                    {definitions[pathKey].kbLink
                        && <p className="kb-link"><a href={definitions[pathKey].kbLink} target="_blank" rel="noreferrer noopener">{definitions[pathKey].kbText ? definitions[pathKey].kbText : 'Learn More'}</a></p>
                    }
                </>
            );
        }

        // how to content
        if (currentMenuPath === 'quickGuide') {
            return (
                <>
                    <p><button type="button" className="back" onClick={() => { return setCurrentMenuPath('howtos'); }}>Back</button></p>
                    <div className="help-content" dangerouslySetInnerHTML={{ __html: quickGuideButton.content }} />
                    {quickGuideButton.youtubeId
                        && <p className="video"><button type="button" onClick={() => { return setVideoModalOpen(true); }}>Watch Video</button></p>
                    }
                    {quickGuideButton.kbLink
                        && <p className="kb-link"><a href={quickGuideButton.kbLink} target="_blank" rel="noreferrer noopener">{quickGuideButton.kbText ? quickGuideButton.kbText : 'Learn More'}</a></p>
                    }
                </>
            );
        }

        return '';
    };

    // show popout
    const toggleHelpMenu = () => {
        setHelpMenuOpen(!helpMenuOpen);
    };

    return (
        <div id="help-widget">
            {helpMenuOpen && !driftOpen
                && (
                    <div className="menu">
                        {renderHelp()}
                    </div>
                )
            }
            {!driftOpen
                ? <p><button type="button" className="llama-head" onClick={toggleHelpMenu}>Helpful Hints/Support Chat</button></p>
                : <p className="close-drift"><button type="button" onClick={toggleDriftChat}>Close Chat</button></p>
            }
            {quickGuideButton && quickGuideButton.youtubeId
                && (
                    <Modal
                        open={videoModalOpen}
                        onClose={() => { return setVideoModalOpen(false); }}
                    >
                        <Modal.Section>
                            <iframe title="Embedded YouTube help video" id="help-video" width="560" height="315" src={`https://www.youtube.com/embed/${quickGuideButton.youtubeId}`} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                        </Modal.Section>
                    </Modal>
                )
            }

            {/* modal for image enlargement, !!enlargedImage transforms the truthy/falsy value into the boolean equivalent */}
            <Modal
                open={!!enlargedImage}
                onClose={() => { return setEnlargedImage(false); }}
                large
            >
                <Modal.Section>
                    <p className="help-enlarged-img"><img src={enlargedImage} alt="" /></p>
                </Modal.Section>
            </Modal>
        </div>
    );
};

LeoHelpWidget.propTypes = {
    name: PropTypes.string.isRequired,
    currentPath: PropTypes.string.isRequired
};

export default LeoHelpWidget;
