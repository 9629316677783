import React from 'react';
import PropTypes from 'prop-types';
import { SkeletonDisplayText, SkeletonBodyText } from '@shopify/polaris';
import { PlaceholderPhoto, LlamaButton } from 'llama-library/components';

import './ambassador-performance-skeleton-report.css';

const SkeletonReport = ({ selectedOfferName }) => {
    return (
        <li className="skeleton">
            <div className="ambassador-name-header">
                <p><PlaceholderPhoto photo={null} /></p>
                <div className="ambassador-name">
                    <SkeletonDisplayText />
                    <SkeletonBodyText lines={1} />
                </div>
                <LlamaButton>View Profile</LlamaButton>
            </div>

            <div className="report">
                {!selectedOfferName
                    && (
                        <section className="offers">
                            <h4>Offers</h4>
                            <ul>
                                <li data-selected>All Offers</li>
                                {[1, 2, 3].map((index) => {
                                    return (
                                        <li key={index}>
                                            <SkeletonBodyText lines={1} />
                                        </li>
                                    );
                                })}
                            </ul>
                        </section>
                    )
                }
                <section className="stats">
                    <div className="graph-heading">
                        <SkeletonDisplayText />
                        <SkeletonBodyText lines={1} />
                    </div>
                    <div className="bar-graph">
                        <SkeletonDisplayText />
                        <SkeletonDisplayText />
                        <SkeletonDisplayText />
                        <SkeletonDisplayText />
                        <SkeletonDisplayText />
                        <SkeletonDisplayText />
                        <SkeletonDisplayText />
                    </div>
                    <div className="breakdown">
                        <section>
                            <h3>Referral Links</h3>
                            <p>Includes initial and repeat orders generated directly from a referral link</p>
                            <ul>
                                <li>
                                    <span className="label">Link Clicks</span>
                                    <SkeletonBodyText lines={1} />
                                </li>
                                <li>
                                    <span className="label">Total Orders</span>
                                    <SkeletonBodyText lines={1} />
                                </li>
                                <li>
                                    <span className="label">Avg. Order Revenue</span>
                                    <SkeletonBodyText lines={1} />
                                </li>
                                <li>
                                    <span className="label">Conversion Rate</span>
                                    <SkeletonBodyText lines={1} />
                                </li>
                            </ul>
                        </section>
                        <section>
                            <h3>Repeat Orders</h3>
                            <p>Includes all subsequent orders from referred customers</p>
                            <ul>
                                <li>
                                    <span className="label">Customer Loyalty</span>
                                    <SkeletonBodyText lines={1} />
                                </li>
                                <li>
                                    <span className="label">Total Orders</span>
                                    <SkeletonBodyText lines={1} />
                                </li>
                                <li>
                                    <span className="label">Avg. Order Revenue</span>
                                    <SkeletonBodyText lines={1} />
                                </li>
                                <li>
                                    <span className="label">Total Revenue</span>
                                    <SkeletonBodyText lines={1} />
                                </li>
                            </ul>
                        </section>
                        <section>
                            <h3>Coupons</h3>
                            <p>Includes all orders in which an ambassador&rsquo;s coupon code was used</p>
                            <ul>
                                <li>
                                    <span className="label">Total Discounts</span>
                                    <SkeletonBodyText lines={1} />
                                </li>
                                <li>
                                    <span className="label">Total Orders</span>
                                    <SkeletonBodyText lines={1} />
                                </li>
                                <li>
                                    <span className="label">Avg. Order Revenue</span>
                                    <SkeletonBodyText lines={1} />
                                </li>
                                <li>
                                    <span className="label">Total Revenue</span>
                                    <SkeletonBodyText lines={1} />
                                </li>
                            </ul>
                        </section>
                    </div>
                </section>
            </div>
        </li>
    );
};

SkeletonReport.propTypes = {
    selectedOfferName: PropTypes.string
};

SkeletonReport.defaultProps = {
    selectedOfferName: ''
};

export default SkeletonReport;
